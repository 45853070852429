import { RouteProps } from '../../routes/AppRouter'
import { FormGeneratorCircles as FGC } from '../../features/form-generator'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function FormGeneratorCircles(props: RouteProps) {
  const { id } = useParams()
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <FGC id={id} />
    </>
  )
}
