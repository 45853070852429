import { LoggedUser, Related } from './LoggedUser'

export interface LoggedUserDTO {
  id: string
  name: string
  gender: number
  birthDate: string
  dni: string
  cip: string
  settings: string
  sessionExpires: string
  related: Related[]
  roles: string[]
  permissions: string[]
}

export function toModel(d: LoggedUserDTO): LoggedUser {
  return new LoggedUser({
    id: d.id,
    name: d.name,
    gender: d.gender,
    birthDate: new Date(d.birthDate),
    dni: d.dni,
    cip: d.cip,
    settings: d.settings,
    sessionExpires: new Date(d.sessionExpires),
    related: d.related,
    roles: d.roles,
    permissions: d.permissions,
  })
}

export function fromModel(m: LoggedUser): LoggedUserDTO {
  return {
    id: m.id,
    name: m.name,
    gender: m.gender,
    birthDate: m.birthDate.toISOString(),
    dni: m.dni,
    cip: m.cip,
    settings: m.settings,
    sessionExpires: m.sessionExpires.toISOString(),
    related: m.related,
    roles: m.roles,
    permissions: m.permissions,
  }
}
