import { CheckboxAssignType } from '../enums/CheckboxAssignType'
import { UserFormWithCheckbox } from './UserFormWithCheckbox'

export interface UserFormWithCheckboxDTO {
  id: string | undefined
  formID: string
  createCheckBoxes: CheckboxAssignType[]
  pathologies: string[]
}

export function emptyUserFormWithCheckbox(user: string, circle: string) {
  return {
    id: '',
    formID: '',
    createCheckBoxes: [],
    pathologies: [],
  }
}

export function fromModel(m: UserFormWithCheckbox): UserFormWithCheckboxDTO {
  return {
    id: m.id,
    formID: m.formID,
    createCheckBoxes: m.createCheckBoxes,
    pathologies: m.pathologies,
  }
}

export function toModel(d: UserFormWithCheckboxDTO): UserFormWithCheckbox {
  return new UserFormWithCheckbox({
    id: d.id,
    formID: d.formID,
    createCheckBoxes: d.createCheckBoxes,
    pathologies: d.pathologies,
  })
}
