import { Container } from '../../common/container/Container'

export type UserTypeExternalProfessionalContainerConfig = {
  moduleFullUrl: string
}

export type UserTypeExternalProfessionalProps = {
  parentContainer: Container
  config: UserTypeExternalProfessionalContainerConfig
}

export const USER_TYPE_EXTERNAL_PROFESSIONAL_MODULE = Symbol(
  'USER_TYPE_EXTERNAL_PROFESSIONAL_MODULE'
)
export const USER_TYPE_EXTERNAL_PROFESSIONAL_API_KEY = Symbol(
  'USER_TYPE_EXTERNAL_PROFESSIONAL_API_KEY'
)
export const USER_TYPE_EXTERNAL_PROFESSIONAL_SERVICE_KEY = Symbol(
  'USER_TYPE_EXTERNAL_PROFESSIONAL_SERVICE_KEY'
)

export const TYPE_EXTERNAL_PROFESSIONAL_API_KEY = Symbol('TYPE_EXTERNAL_PROFESSIONAL_API_KEY')
export const TYPE_EXTERNAL_PROFESSIONAL_SERVICE_KEY = Symbol(
  'TYPE_EXTERNAL_PROFESSIONAL_SERVICE_KEY'
)

export const EXTERNAL_PROFESSIONAL_PENDING_API_KEY = Symbol('EXTERNAL_PROFESSIONAL_PENDING_API_KEY')
export const EXTERNAL_PROFESSIONAL_PENDING_SERVICE_KEY = Symbol(
  'EXTERNAL_PROFESSIONAL_PENDING_SERVICE_KEY'
)
