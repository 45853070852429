import { PatientSymptomCondition } from './PatientSymptomCondition'

export interface PatientSymptomConditionDTO {
  name: string
  type: number
  value: any
}

export function emptyPatientSymptomConditionDTO() {
  return {
    name: '',
    type: 0,
    value: false,
  }
}

export function fromModel(d: PatientSymptomCondition): PatientSymptomConditionDTO {
  return {
    name: d.name,
    type: d.type,
    value: d.value,
  }
}

export function toModel(d: PatientSymptomConditionDTO): PatientSymptomCondition {
  return new PatientSymptomCondition({
    name: d.name,
    type: d.type,
    value: d.value,
  })
}
