export enum RolesType {
  Admin = 'admin',
  ContentManager = 'contentManager',
  CaseManager = 'caseManager',
  Patient = 'patient',
  FamilyOrTutor = 'family/Tutor',
  ProfessionalSMS = 'professionalSMS',
  CarerOrTeacher = 'carer/Teacher',
  ProfessionalExtern = 'externProfessional',
}

export const rolesTypes = (): Record<RolesType, string> => ({
  [RolesType.ContentManager]: 'contentManager',
  [RolesType.CaseManager]: 'caseManager',
  [RolesType.ProfessionalSMS]: 'professionalSMS',
  [RolesType.ProfessionalExtern]: 'externProfessional',
  [RolesType.Patient]: 'patient',
  [RolesType.FamilyOrTutor]: 'family/Tutor',
  [RolesType.CarerOrTeacher]: 'carer/Teacher',
  [RolesType.Admin]: 'admin',
})
