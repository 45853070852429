export enum ReadingStatus {
  Created = 1,
  Viewed,
  Removed,
}

export const readingStatuses = (): Record<ReadingStatus, string> => ({
  [ReadingStatus.Created]: 'Created',
  [ReadingStatus.Viewed]: 'Viewed',
  [ReadingStatus.Removed]: 'Removed',
})
