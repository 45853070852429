type ConfidentialityTermProps = {
  id: string
  userID: string
  date: Date
  accepted: boolean
}

export class ConfidentialityTerm {
  private _id: string
  private _userID: string
  private _date: Date
  private _accepted: boolean

  constructor(p: ConfidentialityTermProps) {
    this._id = p.id
    this._userID = p.userID
    this._date = p.date
    this._accepted = p.accepted
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get userID(): string {
    return this._userID
  }

  set userID(value: string) {
    this._userID = value
  }

  get date(): Date {
    return this._date
  }

  set date(value: Date) {
    this._date = value
  }

  get accepted(): boolean {
    return this._accepted
  }

  set accepted(value: boolean) {
    this._accepted = value
  }
}

export interface ConfidentialityTermQuery {
  id: string
  userID: string
  date: Date
  accepted: boolean
}
