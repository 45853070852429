import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { FormFieldType, formFieldTypes } from '../../modules/forms/enums/FormFieldType'
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './EditorField.module.css'
import { isNoResponse, isSpecialType } from '../../common/utils/enums'
import { FormFieldDTO } from '../../modules/forms/models/FormDTO'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import buttonOn from '../../assets/draggable/checkbox-cuadrado-OK.svg'
import buttonOff from '../../assets/draggable/button-off.svg'
import deleteBtn from '../../assets/draggable/btn-delete.svg'
import addBtn from '../../assets/draggable/btn-add.svg'
import { v4 as uuidv4 } from 'uuid'

type FormEditorFieldProps = {
  handleChange: (item: FormFieldDTO) => void
  handleRemove: (id: string) => void
  formField: FormFieldDTO
  disabled?: boolean
  id?: string
  isCreator: boolean
}

const fieldTypes = formFieldTypes()

export const EditorFieldsItem: React.FC<FormEditorFieldProps> = (props) => {
  const { t } = useTranslation()
  const [formField, setFormField] = useState<FormFieldDTO>(props.formField)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [alertVisible, setAlertVisible] = useState<boolean>(false)

  const splitScore = formField.score.join(';').split(';')
  const ids = useRef(splitScore.map(() => uuidv4()))

  // TODO implement in the future
  // TODO validate if there are optionValues, correctValues must be subset of those

  useEffect(() => {
    if (!props.id) return

    const aux = formField
    aux.score = aux.score.join(';').split(';')
    setFormField(aux)
  }, [props.id])

  const handleType = (e: SelectChangeEvent<FormFieldType>, name: string) => {
    formField.optionValues = []
    formField.correctValues = []
    formField.score = []
    setFormField(
      Object.assign(
        { ...formField },
        {
          [name]: +(e.target.value as number),
        }
      )
    )

    // actualizamos el type y el campo optionValues que ya no debe estar vacio en casos de tipos especiales.

    const obj: string | undefined = Object.keys(FormFieldType).find((key: any) => {
      return FormFieldType[key] === e.target.value
    })
    if (obj) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (isSpecialType(FormFieldType[obj])) {
        setFormField(
          Object.assign(
            { ...formField },
            {
              [name]: +(e.target.value as number),
              optionValues: [],
            }
          )
        )
      }
    }
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFormField(Object.assign({ ...formField }, { [e.target.name]: e.target.value }))
  }

  const handleRequired = (_: ChangeEvent<HTMLInputElement>) =>
    setFormField(Object.assign({ ...formField }, { required: !formField.required }))

  useEffect(() => {
    props.handleChange(formField)
  }, [formField])

  const handlerOptionScore = () => {
    if (!props.isCreator) return
    const optionValuesLenght = formField.optionValues.length
    const optionsWithoutLast = [...formField.optionValues]
    optionsWithoutLast.pop()

    if (
      formField.optionValues.length === 0 ||
      formField.optionValues[optionValuesLenght - 1].length === 0
    ) {
      setAlertVisible(true)
      setAlertMessage(t('voidFields'))
    } else if (
      formField.optionValues.length > 1 &&
      optionsWithoutLast.includes(formField.optionValues[optionValuesLenght - 1])
    ) {
      setAlertMessage(t('duplicatedOption'))
      setAlertVisible(true)
    } else {
      setAlertVisible(false)

      formField.score.length === 0 &&
        formField.score.filter((score) => score === '') &&
        formField.score.push('')

      const auxOption = formField.optionValues
      auxOption.push('')
      const scoreOption = formField.score
      scoreOption.push('')

      setFormField({
        ...formField,
        optionValues: auxOption,
        score: scoreOption,
      })
    }
  }

  const deleteOptionScore = (i: number) => {
    const auxOption = formField.optionValues
    auxOption.splice(i, 1)
    // auxOption.pop()

    const auxScores = formField.score
    auxScores.splice(i, 1)
    // auxScores.pop()

    setFormField({
      ...formField,
      optionValues: auxOption,
      score: auxScores,
    })
  }

  const deleteFormField = () => props.handleRemove(formField.id)

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Box className={styles.formEditorField}>
      <Box style={{ display: 'flex', marginBottom: 12, width: '100%' }}>
        <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <p className={styles.label}>{t('name')}</p>
          <Box className={styles.box}>
            <TextField
              key="title"
              variant="outlined"
              name="title"
              value={formField.title}
              onChange={handleInput}
              inputProps={{ maxLength: 200 }}
              size={'small'}
              fullWidth
              required
              disabled={!props.isCreator}
            />
          </Box>
        </Box>
      </Box>
      <Box style={{ display: 'flex' }}>
        <p className={styles.labelType} style={{ marginRight: '32px' }}>
          {t('type')}
        </p>
        <Box className={styles.typeBox}>
          <Select
            sx={{ '& .MuiSelect-select:focus': { backgroundColor: 'transparent' } }}
            style={{ margin: 0, height: '32px' }}
            key={'type'}
            id={'type'}
            variant={'outlined'}
            value={formField.type}
            disabled={!props.isCreator}
            onChange={(e) => handleType(e, 'type')}
            className={styles.type}
            required
          >
            {Object.keys(fieldTypes).map((k, i) => (
              <MenuItem key={'form-editor-field_menuItem_' + k} value={k}>
                {fieldTypes[k as unknown as FormFieldType]}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <>
            {isSpecialType(formField.type) && (
              <Box className={styles.optionBox}>
                <TextField
                  style={{ marginBottom: '5px', width: '100%' }}
                  sx={{ '& .MuiInputBase-input': { height: '1px' } }}
                  key="currentOption"
                  type={'text'}
                  variant="outlined"
                  placeholder={t('option')}
                  name="currentOption"
                  disabled={!props.isCreator}
                  value={formField.optionValues[0]}
                  onChange={(e) => {
                    // actualizamos el valor del formField
                    const optv = formField.optionValues
                    optv[0] = e.target.value
                    setFormField({ ...formField, optionValues: optv })
                  }}
                />
                {Array.isArray(formField.optionValues) ? (
                  <ul className={styles.list}>
                    {formField.optionValues.map(
                      (o, i) =>
                        i > 0 && (
                          <li key={i} className={styles.listItem}>
                            <TextField
                              style={{ width: '100%' }}
                              sx={{ '& .MuiInputBase-input': { height: '1px' } }}
                              // key={i + 1}
                              variant="outlined"
                              disabled={!props.isCreator}
                              placeholder={t('option')}
                              name="currentOption"
                              value={o}
                              onChange={(e) => {
                                // actualizamos el valor del formField
                                const optv = formField.optionValues
                                optv[i] = e.target.value
                                setFormField({ ...formField, optionValues: optv })
                              }}
                            />
                          </li>
                        )
                    )}
                  </ul>
                ) : (
                  <></>
                )}
              </Box>
            )}
            <Box className={styles.scoreBox}>
              {isSpecialType(formField.type) && (
                <Box style={{ display: 'flex' }}>
                  <TextField
                    style={{ marginBottom: '5px', width: '100%' }}
                    sx={{
                      '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': { height: '1px' },
                      '& .MuiInputBase-input': { height: '1px' },
                    }}
                    key="score"
                    variant="outlined"
                    placeholder={t('score')}
                    disabled={!props.isCreator}
                    onKeyDown={(event) => {
                      const isNumberKey = /[0-9]/.test(event.key)
                      const isBackspaceOrDelete =
                        event.key === 'Backspace' || event.key === 'Delete'
                      if (!isNumberKey && !isBackspaceOrDelete) {
                        event.preventDefault()
                      }
                    }}
                    name="score"
                    value={splitScore[0]}
                    onChange={(e) => {
                      const aux1 = formField.score
                      aux1[0] = e.target.value
                      setFormField({ ...formField, score: aux1 })
                    }}
                  />

                  <img
                    src={addBtn}
                    style={{ height: '34px', marginLeft: 20, cursor: 'pointer' }}
                    alt={t('add')}
                    onClick={handlerOptionScore}
                  />
                </Box>
              )}
              {Array.isArray(formField.score) ? (
                <ul className={styles.list}>
                  {splitScore.map(
                    (o, i) =>
                      i > 0 && (
                        <Box key={ids.current[i]} style={{ display: 'flex' }}>
                          <li key={ids.current[i]} className={styles.listItem}>
                            <TextField
                              style={{ width: '100%' }}
                              sx={{ '& .MuiInputBase-input': { height: '1px' } }}
                              // key={i + 1}
                              variant="outlined"
                              placeholder={t('score')}
                              disabled={!props.isCreator}
                              name="score"
                              value={splitScore[i]}
                              onKeyDown={(event) => {
                                const isNumberKey = /[0-9]/.test(event.key)
                                const isBackspaceOrDelete =
                                  event.key === 'Backspace' || event.key === 'Delete'
                                if (!isNumberKey && !isBackspaceOrDelete) {
                                  event.preventDefault()
                                }
                              }}
                              onChange={(e) => {
                                if (!props.isCreator) return
                                setFormField((prevFormField) => {
                                  const newScore = [...prevFormField.score]
                                  newScore[i] = e.target.value
                                  return {
                                    ...prevFormField,
                                    score: newScore,
                                  }
                                })
                              }}
                            />
                          </li>
                          {isSpecialType(formField.type) && (
                            <img
                              src={deleteBtn}
                              style={{ height: '34px', marginLeft: 20, cursor: 'pointer' }}
                              alt={t('delete')}
                              onClick={() => {
                                if (!props.isCreator) return
                                deleteOptionScore(i)
                              }}
                            />
                          )}
                        </Box>
                      )
                  )}
                </ul>
              ) : (
                <></>
              )}
            </Box>
          </>
          {/* )} */}
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" style={{ marginTop: '16px' }}>
        {isNoResponse(formField.type) ? (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <FormControlLabel
                className={styles.requiredLabel}
                label={''}
                labelPlacement={'start'}
                control={
                  <Checkbox
                    icon={<img src={buttonOff} alt={t('notRequired')} />}
                    checkedIcon={<img src={buttonOn} alt={t('notRequired')} />}
                    key="required"
                    name="required"
                    value={false}
                    checked={false}
                    onChange={handleRequired}
                    disabled={true}
                  />
                }
              />
            </Box>
            <p>{t('requiredQuestion')}</p>
            {props.disabled ? (
              <Box style={{ marginLeft: 'auto' }}>
                <Tooltip title={t('QuestionRequired')}>
                  <AppButton
                    theme={ButtonTheme.NewSecondary}
                    type={'button'}
                    label={t('delete')}
                    disabled={props.disabled || !props.isCreator}
                    handler={deleteFormField}
                  />
                </Tooltip>
              </Box>
            ) : (
              <Box style={{ marginLeft: 'auto' }}>
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('delete')}
                  disabled={props.disabled ?? !props.isCreator}
                  handler={deleteFormField}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <FormControlLabel
                className={styles.requiredLabel}
                label={''}
                labelPlacement={'start'}
                control={
                  <Checkbox
                    icon={<img src={buttonOff} alt={t('notRequired')} />}
                    checkedIcon={<img src={buttonOn} alt={t('notRequired')} />}
                    key="required"
                    name="required"
                    value={formField.required}
                    checked={formField.required}
                    disabled={!props.isCreator}
                    onChange={handleRequired}
                  />
                }
              />
            </Box>
            <p>{t('requiredQuestion')}</p>
            {props.disabled ? (
              <Box style={{ marginLeft: 'auto' }}>
                <Tooltip title={t('QuestionRequired')}>
                  <Box>
                    <AppButton
                      theme={ButtonTheme.whiteAndBlue}
                      type={'button'}
                      label={t('delete')}
                      disabled={props.disabled || !props.isCreator}
                      handler={deleteFormField}
                    />
                  </Box>
                </Tooltip>
              </Box>
            ) : (
              <Box style={{ marginLeft: 'auto' }}>
                <AppButton
                  theme={ButtonTheme.whiteAndBlue}
                  type={'button'}
                  label={t('delete')}
                  disabled={props.disabled ?? !props.isCreator}
                  handler={deleteFormField}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      {alertVisible && (
        <Alert style={{ marginTop: 10 }} severity="warning" key="message" id="message">
          {alertMessage}
        </Alert>
      )}
    </Box>
  )
}
