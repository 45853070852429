import { Query } from '../../../common/api/Query'
import { MessageUser } from './MessageUser'
import { Reaction } from './Reaction'

export type MessageProps = {
  id?: string | undefined
  text: string
  author: string
  createdAt: Date
  isPinned: boolean
  reactions: Reaction[]
  message2Users: MessageUser[]
  consultaID: string
  rootID: string
  parentID: string
  files?: string[]
}

export class Message {
  private readonly _id: string | undefined
  private readonly _text: string
  private readonly _author: string
  private readonly _createdAt: Date
  private readonly _rootID: string
  private readonly _parentID: string

  private _isPinned: boolean
  private _reactions: Reaction[] | undefined
  private _message2Users: MessageUser[]
  private _consultaID: string
  private _files: string[] | undefined

  constructor(p: MessageProps) {
    this._id = p.id
    this._text = p.text
    this._author = p.author
    this._createdAt = p.createdAt
    this._rootID = p.rootID
    this._parentID = p.parentID
    this._isPinned = p.isPinned
    this._reactions = p.reactions
    this._message2Users = p.message2Users
    this._consultaID = p.consultaID
    this._files = p.files
  }

  get id(): string | undefined {
    return this._id
  }

  get text(): string {
    return this._text
  }

  get author(): string {
    return this._author
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get rootID(): string {
    return this._rootID
  }

  get parentID(): string {
    return this._parentID
  }

  get isPinned(): boolean {
    return this._isPinned
  }

  set isPinned(value: boolean) {
    this._isPinned = value
  }

  get reactions(): Reaction[] | undefined {
    if (this._reactions) {
      return [...this._reactions]
    }

    return this._reactions
  }

  addReaction(value: Reaction) {
    if (this._reactions) {
      this._reactions.push(value)
    } else {
      this._reactions = [value]
    }
  }

  removeReaction(userID: string, messageID: string) {
    if (this._reactions) {
      this._reactions = this._reactions.filter(
        (r) => r.userID !== userID && r.messageID !== messageID
      )
    }
  }

  get message2Users(): MessageUser[] {
    return [...this._message2Users]
  }

  addMessage2User(value: MessageUser) {
    if (this._message2Users) {
      this._message2Users.push(value)
    } else {
      this._message2Users = [value]
    }
  }

  removeMessage2User(userID: string, messageID: string) {
    if (this._message2Users) {
      this._message2Users = this._message2Users.filter(
        (m2u) => m2u.userID !== userID && m2u.messageID !== messageID
      )
    }
  }

  get consultaID(): string {
    return this._consultaID
  }

  get files(): string[] | undefined {
    if (this._files) {
      return [...this._files]
    }

    return this._files
  }

  addFile(value: string) {
    if (this._files) {
      this._files.push(value)
    } else {
      this._files = [value]
    }
  }

  removeFile(value: string) {
    if (this._files) {
      this._files = this._files.filter((f) => f !== value)
    }
  }
}

export class MessageQuery extends Query<Message> {}
