import { filePercentiles } from './filePercentiles'
import { PercentileDTO } from '../../../../modules/patient-data/models/PercentileDTO'

export const filterPercentiles = (organizations: number[], category: number, gender: number) => {
  const filter: [PercentileDTO[]] = [[]]
  filter.pop()

  organizations.forEach((org) => {
    const filterPercentile = filePercentiles.filter(
      (p) => p.category === category && p.gender === gender && p.organization === org
    )

    filter.push(filterPercentile)
  })

  return filter
}
