import { TimeTable } from './TimeTable'
import { Query } from '../../../common/api/Query'

export type TrainingGroupProps = {
  id: string
  capacity: number
  name: string
  centerID: string
  userTypeExternProfID: string
  trainerName: string
  trainerEmail: string
  timeTable: TimeTable[]
}

export type TrainingGroupArrProps = {
  groups: TrainingGroup[]
}

export class TrainingGroup {
  private readonly _id: string
  private _capacity: number
  private _name: string
  private _centerID: string
  private _userTypeExternProfID: string
  private _trainerName: string
  private _trainerEmail: string
  private _timeTable: TimeTable[]

  constructor(props: TrainingGroupProps) {
    this._id = props.id
    this._capacity = props.capacity
    this._name = props.name
    this._centerID = props.centerID
    this._userTypeExternProfID = props.userTypeExternProfID
    this._trainerName = props.trainerName
    this._trainerEmail = props.trainerEmail
    this._timeTable = props.timeTable
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get capacity(): number {
    return this._capacity
  }

  set capacity(value: number) {
    this._capacity = value
  }

  get centerID(): string {
    return this._centerID
  }

  set centerID(value: string) {
    this._centerID = value
  }

  get userTypeExternProfID(): string {
    return this._userTypeExternProfID
  }

  set userTypeExternProfID(value: string) {
    this._userTypeExternProfID = value
  }

  get timeTable(): TimeTable[] {
    return this._timeTable
  }

  set timeTable(value: TimeTable[]) {
    this._timeTable = value
  }

  get trainerName(): string {
    return this._trainerName
  }

  set trainerName(value: string) {
    this._trainerName = value
  }

  get trainerEmail(): string {
    return this._trainerEmail
  }

  set trainerEmail(value: string) {
    this._trainerEmail = value
  }
}

export class TrainingGroupQuery extends Query<TrainingGroup> {
  id?: string
  ids?: string[]
  capacity?: number
  name?: string
  centerID?: string
  trainerName?: string
}

export class TrainingGroupArr {
  private _groups: TrainingGroup[]

  constructor(props: TrainingGroupArrProps) {
    this._groups = props.groups
  }

  get groups(): TrainingGroup[] {
    return this._groups
  }

  set groups(value: TrainingGroup[]) {
    this._groups = value
  }
}
