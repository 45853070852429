import { Container, ContainerItem } from '../common/container/Container'
import {
  CITY_HALL_SERVICE_KEY,
  CITY_HALL_MODULE,
  CITY_HALL_API_KEY,
  CityHallProps,
} from '../modules/cityHall/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { CityHallApi } from '../modules/cityHall/api/CityHallApi'
import { CityHallService } from '../modules/cityHall/services/CityHallService'

let container: Container

function init(p: CityHallProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(CITY_HALL_API_KEY, new ContainerItem({ instance: new CityHallApi() }))

  items.set(
    CITY_HALL_SERVICE_KEY,
    new ContainerItem({ instance: new CityHallService({ apiKey: CITY_HALL_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getCityHallContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[CITY_HALL_MODULE],
      },
    })
  }

  return container
}
