import styles from './Header.module.css'
import { Box, Chip } from '@mui/material'
import style from '../current-nav-header-esfera/CurrentNavHeaderEsfera.module.css'
import { getFirstWord } from '../../common/utils/strings'

type HeaderProps = {
  label: string
  icon?: string
  onClick?: () => void
  circle?: string
  userName?: string
  subtitle?: string
  divider?: boolean
}

export function Header(props: HeaderProps) {
  return (
    <Box className={props.divider ? styles.containerNoBorder : styles.container}>
      <p className={styles.header}>{props.label}</p>
      {props.icon && (
        <img className={styles.icon} src={props.icon} alt={props.label} onClick={props.onClick} />
      )}
      {props.circle ||
        (props.userName && (
          <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {props.circle && <Chip label={props.circle} className={style.pathologies} />}
            {props.userName && (
              <div className={style.circleContainer}>
                <p>{getFirstWord(props.userName)}</p>
              </div>
            )}
          </Box>
        ))}
    </Box>
  )
}
