import { TimeTable } from './TimeTable'
import {v4 as uuidv4} from "uuid";

export interface TimeTableDTO {
  id: string
  weekDay: number
  startTime: Date
  finishTime: Date
}

export function emptyTimeTableDTO() {
  return {
    id: uuidv4(),
    weekDay: 0,
    startTime: new Date,
    finishTime: new Date,
  }
}

export function fromModel(e: TimeTable): TimeTableDTO {
  return {
    id: e.id,
    weekDay: e.weekDay,
    startTime: new Date(e.startTime),
    finishTime: new Date(e.finishTime),
  }
}

export function toModel(d: TimeTableDTO): TimeTable {
  return new TimeTable(d)
}

export function toModelList(timeTableDTO: TimeTableDTO[]): TimeTable[] {
  return timeTableDTO.map((value) => toModel(value))
}

export function fromModelList(timeTable: TimeTable[]): TimeTableDTO[] {
  return timeTable.map((value) => fromModel(value))
}
