import { useContext } from 'react'
import { ProfilePhotoContext } from '../../common/utils/profile-photo-context/ProfilePhotoContext'

export const useGetProfilePhoto = () => {
  const context = useContext(ProfilePhotoContext)
  if (context === undefined) {
    throw new Error('useActiveUser must be used within a ActiveUserContextProvider')
  }
  return context
}
