import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { EpilepticalCrisisDetails } from '../models/EpilepticalCrisisDetails'
import { EpilepticalCrisisDetailsDTO } from '../models/EpilepticalCrisisDetailsDTO'
import { IEpilepticalCrisisDetailsApi } from '../api/EpilepticalCrisisDetailsApi'

type Props = {
  apiKey: symbol
}

export interface IEpilepticalCrisisDetailsService extends IInit {
  getByID(id: string): Observable<EpilepticalCrisisDetails | undefined>

  getFilteredList(
    q: Query<EpilepticalCrisisDetails>
  ): Observable<ItemList<EpilepticalCrisisDetails>>

  add(e: EpilepticalCrisisDetailsDTO): Observable<EpilepticalCrisisDetails | undefined>

  update(e: EpilepticalCrisisDetailsDTO): Observable<EpilepticalCrisisDetails | undefined>

  delete(id: string): Observable<boolean>

  byEpilepticalCrisisID(
    q: Query<EpilepticalCrisisDetails>
  ): Observable<ItemList<EpilepticalCrisisDetails>>
}

export class EpilepticalCrisisDetailsService implements IEpilepticalCrisisDetailsService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IEpilepticalCrisisDetailsApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IEpilepticalCrisisDetailsApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: EpilepticalCrisisDetailsDTO): Observable<EpilepticalCrisisDetails | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<EpilepticalCrisisDetails | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(
    q: Query<EpilepticalCrisisDetails>
  ): Observable<ItemList<EpilepticalCrisisDetails>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: EpilepticalCrisisDetailsDTO): Observable<EpilepticalCrisisDetails | undefined> {
    return this._api.update(e)
  }

  byEpilepticalCrisisID(
    q: Query<EpilepticalCrisisDetails>
  ): Observable<ItemList<EpilepticalCrisisDetails>> {
    return this._api.byEpilepticalCrisisID(q)
  }
}
