import { RouteProps } from '../../routes/AppRouter'
import React from 'react'
import { CalendarForm as CF } from '../../features/calendar'
import { ROUTE_CREATE } from '../../routes/routes-constants'
import { useLocation, useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

type CalendarFormProps = {
  date?: Date
} & RouteProps

export function CalendarForm(props: CalendarFormProps) {
  const { id } = useParams()
  const location = useLocation()
  const title = props.title || ''
  const state: CalendarFormProps = location?.state as CalendarFormProps

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <CF id={id === ROUTE_CREATE ? undefined : id} date={state.date} />
    </>
  )
}
