export function handlerSwitch(value1: string[], value2: any[], eventTarget: string) {
  let aux = 0

  switch (eventTarget) {
    case value1[0]:
      aux = value2[0]
      break
    case value1[1]:
      aux = value2[1]
      break
    case value1[2]:
      aux = value2[2]
      break
    case value1[3]:
      aux = value2[3]
      break
    case value1[4]:
      aux = value2[4]
      break
    case value1[5]:
      aux = value2[5]
      break
    case value1[6]:
      aux = value2[6]
      break
    case value1[7]:
      aux = value2[7]
      break
    case value1[8]:
      aux = value2[8]
      break
    case value1[9]:
      aux = value2[9]
      break
    case value1[10]:
      aux = value2[10]
      break
  }
  return aux
}
