export type PercentileProps = {
  month: number
  percentile: number
  value: number
  gender: number
  category: number
  organization: number
}

export class Percentile {
  private readonly _month: number
  private readonly _percentile: number
  private readonly _value: number
  private readonly _gender: number
  private readonly _category: number
  private readonly _organization: number

  constructor(p: PercentileProps) {
    this._month = p.month
    this._percentile = p.percentile
    this._value = p.value
    this._gender = p.gender
    this._category = p.category
    this._organization = p.organization
  }

  get month(): number {
    return this._month
  }

  get percentile(): number {
    return this._percentile
  }

  get value(): number {
    return this._value
  }

  get gender(): number {
    return this._gender
  }

  get category(): number {
    return this._category
  }

  get organization(): number {
    return this._organization
  }
}

export interface PercentileQuery {
  organization: number
  category: number
  gender: number
}
