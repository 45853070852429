import { Container } from '../../common/container/Container'

export type MessengerConsultaContainerConfig = {
  moduleFullUrl: string
}

export type MessengerConsultaProps = {
  parentContainer: Container
  config: MessengerConsultaContainerConfig
}

export const MESSENGER_CONSULTA_MODULE = Symbol('MESSENGER_CONSULTA_MODULE')

export const CONTACT_CONSULTA_API_KEY = Symbol('CONTACT_CONSULTA_API_KEY')
export const CONSULTA_API_KEY = Symbol('CONSULTA_API_KEY')

export const CONTACT_CONSULTA_SERVICE_KEY = Symbol('CONTACT_CONSULTA_SERVICE_KEY')
export const CONSULTA_SERVICE_KEY = Symbol('CONSULTA_SERVICE_KEY')

export const AUTOMESSAGE_API_KEY = Symbol('AUTOMESSAGE_API_KEY')
export const AUTOMESSAGE_SERVICE_KEY = Symbol('AUTOMESSAGE_SERVICE_KEY')

export const PROFESSIONAL_QUERY_API_KEY = Symbol('PROFESSIONAL_QUERY_API_KEY')
export const PROFESSIONAL_QUERY_SERVICE_KEY = Symbol('PROFESSIONAL_QUERY_SERVICE_KEY')
