import { v4 as uuidv4 } from 'uuid'
import { UserTypeExternalProfessional } from './UserTypeExternalProfessional'

export interface UserTypeExternalProfessionalDTO {
  id: string
  userID: string
  typeExternProfID: string
}

export function emptyUserTypeExternalProfessionalDTO() {
  return {
    id: uuidv4(),
    userID: '',
    typeExternProfID: '',
  }
}

export function fromModel(e: UserTypeExternalProfessional): UserTypeExternalProfessionalDTO {
  return {
    id: e.id,
    userID: e.userID,
    typeExternProfID: e.typeExternProfID,
  }
}

export function toModel(d: UserTypeExternalProfessionalDTO): UserTypeExternalProfessional {
  return new UserTypeExternalProfessional(d)
}
