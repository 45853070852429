import {
  Box,
  Icon,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import styles from './AddGroup.module.css'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import addIconButton from '../../assets/draggable/btn-add.svg'
import deleteIconButton from '../../assets/draggable/btn-delete.svg'
import React, { useState } from 'react'
import { emptyTimeTableDTO, TimeTableDTO } from '../../modules/training-group/models/TimeTableDTO'
import { TrainingGroupDTO } from '../../modules/training-group/models/TrainingGroupDTO'
import { useTranslation } from 'react-i18next'

type TimeTableProps = {
  trainingGroup: TrainingGroupDTO
  isLoading: () => void
  handlerNumberTime: (value: number) => void
}

const weekDaysNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

export const TimeTableComponent = (props: TimeTableProps) => {
  const [timeTable, setTimeTable] = useState<TimeTableDTO[]>(
    props.trainingGroup.timeTable?.length !== 0
      ? props.trainingGroup.timeTable
      : [emptyTimeTableDTO()]
  )
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const { t } = useTranslation()

  const handlerSaveDay = (event: SelectChangeEvent<string | number>, index: number) => {
    const timeArr: TimeTableDTO[] = [...timeTable]
    timeArr[index].weekDay = event.target.value as number
    setTimeTable(timeArr)
    props.trainingGroup.timeTable = timeArr
  }

  const handlerDeleteTime = (index: number) => {
    const temporalArr: TimeTableDTO[] = []
    timeTable.forEach((timeTableValue, timeIndex) => {
      if (index !== timeIndex) {
        temporalArr.push(timeTableValue)
      }
    })
    setTimeTable(temporalArr)
    props.trainingGroup.timeTable = temporalArr
    props.handlerNumberTime(temporalArr.length)
  }

  const handlerSaveTime = (date: Date | null, startFinish: string, index: number) => {
    if (date !== null) {
      const timeArr = [...timeTable]
      if (startFinish === 'start') {
        timeArr[index].startTime = date
      } else {
        timeArr[index].finishTime = date
      }
      setTimeTable(timeArr)
      props.trainingGroup.timeTable = timeArr
    }
  }

  const handlerAddTime = () => {
    const timeArr = timeTable
    timeArr.push(emptyTimeTableDTO())
    setTimeTable(timeArr)
    props.trainingGroup.timeTable = timeArr
    props.isLoading()
    props.handlerNumberTime(timeArr.length)
  }

  return (
    <>
      {timeTable.map((timeValue, index) => {
        return (
          <Box className={styles.fieldsCard} key={index}>
            <Box className={styles.contentFieldsCard}>
              <Typography className={styles.fieldTitle}>{t('day')}</Typography>
              <Select
                labelId="select-week-label"
                id="select-week"
                value={timeTable[index].weekDay}
                name={'weekday'}
                size={'small'}
                required
                fullWidth
                onChange={(e) => handlerSaveDay(e, index)}
                MenuProps={{
                  MenuListProps: {
                    sx: {
                      'li.MuiButtonBase-root': {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        fontFamily: 'Poppins',
                        paddingLeft: '10px',
                      },
                    },
                  },
                }}
                SelectDisplayProps={{
                  style: {
                    paddingLeft: '10px',
                    fontFamily: 'Poppins',
                  },
                }}
              >
                {weekDaysNames.map((weekDayName, indexDay) => (
                  <MenuItem key={weekDayName} value={indexDay === 6 ? 0 : indexDay + 1}>
                    {t(weekDayName)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className={styles.contentFieldsCard}>
              <Box className={styles.contentFieldsCardHour}>
                <Typography className={styles.fieldTitleLarge}>{t('startHour')}</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <TimePicker
                    key={'startTime'}
                    disableOpenPicker
                    PaperProps={{
                      sx: {
                        '& .MuiButtonBase-root': {
                          fontFamily: 'Poppins',
                        },
                      },
                    }}
                    InputProps={{ disableUnderline: true, sx: { fontFamily: 'Poppins' } }}
                    onError={(reason, value) => {
                      switch (reason) {
                        case 'invalidDate':
                          setDateTimePickerError(t('hora inválida'))
                          break
                      }
                    }}
                    renderInput={(props) => (
                      <TextField
                        style={{ width: 85, textAlign: 'right' }}
                        sx={{
                          '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                            fontFamily: 'Poppins',
                            textAlign: 'center',
                            color:
                              timeTable[index].startTime !== null &&
                              isNaN(timeTable[index].startTime.getTime()) &&
                              props.error
                                ? 'red'
                                : '',
                          },
                        }}
                        variant={'outlined'}
                        {...props}
                      />
                    )}
                    inputFormat="HH:mm"
                    ampm={false}
                    onChange={(e) => handlerSaveTime(e && e.toDate(), 'start', index)}
                    value={timeTable[index].startTime}
                    maxTime={
                      timeTable[index].finishTime
                        ? dayjs()
                            .set('hour', new Date(timeTable[index].finishTime).getHours())
                            .set('minutes', new Date(timeTable[index].finishTime).getMinutes())
                        : null
                    }
                  />
                </LocalizationProvider>
              </Box>
              <Box className={styles.contentFieldsCardHour}>
                <Typography className={styles.fieldTitleLarge}>{t('finishHour')}</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <TimePicker
                    key={'endHour'}
                    disableOpenPicker
                    PaperProps={{
                      sx: {
                        '& .MuiButtonBase-root': {
                          fontFamily: 'Poppins',
                        },
                      },
                    }}
                    InputProps={{ disableUnderline: true, sx: { fontFamily: 'Poppins' } }}
                    onError={(reason, value) => {
                      switch (reason) {
                        case 'invalidDate':
                          setDateTimePickerError(t('hora inválida'))
                          break
                      }
                    }}
                    renderInput={(props) => (
                      <TextField
                        style={{ width: 85, textAlign: 'right' }}
                        sx={{
                          '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                            fontFamily: 'Poppins',
                            textAlign: 'center',
                          },
                        }}
                        variant={'outlined'}
                        helperText={props.error && DateTimePickerError}
                        {...props}
                      />
                    )}
                    inputFormat="HH:mm"
                    ampm={false}
                    onChange={(e) => handlerSaveTime(e && e.toDate(), 'finish', index)}
                    value={timeTable[index].finishTime}
                    minTime={
                      timeTable[index].startTime
                        ? dayjs()
                            .set('hour', new Date(timeTable[index].startTime).getHours())
                            .set('minutes', new Date(timeTable[index].startTime).getMinutes())
                        : null
                    }
                  />
                </LocalizationProvider>
              </Box>
              <Box style={{ display: 'flex', width: '40px' }}>
                {index === 0 ? (
                  <IconButton onClick={handlerAddTime}>
                    <Icon className={styles.icon}>
                      <img alt="see" src={addIconButton} className={styles.iconImage} />
                    </Icon>
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handlerDeleteTime(index)}>
                    <Icon className={styles.icon}>
                      <img alt="see" src={deleteIconButton} className={styles.iconImage} />
                    </Icon>
                  </IconButton>
                )}
              </Box>
            </Box>
          </Box>
        )
      })}
    </>
  )
}
