import { Container, ContainerItem } from '../common/container/Container'
import {
  USER_TYPE_EXTERNAL_PROFESSIONAL_SERVICE_KEY,
  USER_TYPE_EXTERNAL_PROFESSIONAL_MODULE,
  USER_TYPE_EXTERNAL_PROFESSIONAL_API_KEY,
  TYPE_EXTERNAL_PROFESSIONAL_SERVICE_KEY,
  TYPE_EXTERNAL_PROFESSIONAL_API_KEY,
  EXTERNAL_PROFESSIONAL_PENDING_SERVICE_KEY,
  EXTERNAL_PROFESSIONAL_PENDING_API_KEY,
  UserTypeExternalProfessionalProps,
} from '../modules/userTypeExternalProfessional/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { UserTypeExternalProfessionalApi } from '../modules/userTypeExternalProfessional/api/UserTypeExternalProfessionalApi'
import { UserTypeExternalProfessionalService } from '../modules/userTypeExternalProfessional/services/UserTypeExternalProfessionalService'
import { TypeExternalProfessionalApi } from '../modules/userTypeExternalProfessional/api/TypeExternalProfessionalApi'
import { TypeExternalProfessionalService } from '../modules/userTypeExternalProfessional/services/TypeExternalProfessionalService'
import { ExternalProfessionalPendingApi } from '../modules/userTypeExternalProfessional/api/ExternalProfessionalPendingApi'
import { ExternalProfessionalPendingService } from '../modules/userTypeExternalProfessional/services/ExternalProfessionalPendingService'

let container: Container

function init(p: UserTypeExternalProfessionalProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(
    USER_TYPE_EXTERNAL_PROFESSIONAL_API_KEY,
    new ContainerItem({ instance: new UserTypeExternalProfessionalApi() })
  )
  items.set(
    TYPE_EXTERNAL_PROFESSIONAL_API_KEY,
    new ContainerItem({ instance: new TypeExternalProfessionalApi() })
  )
  items.set(
    EXTERNAL_PROFESSIONAL_PENDING_API_KEY,
    new ContainerItem({ instance: new ExternalProfessionalPendingApi() })
  )

  items.set(
    USER_TYPE_EXTERNAL_PROFESSIONAL_SERVICE_KEY,
    new ContainerItem({
      instance: new UserTypeExternalProfessionalService({
        apiKey: USER_TYPE_EXTERNAL_PROFESSIONAL_API_KEY,
      }),
    })
  )

  items.set(
    TYPE_EXTERNAL_PROFESSIONAL_SERVICE_KEY,
    new ContainerItem({
      instance: new TypeExternalProfessionalService({ apiKey: TYPE_EXTERNAL_PROFESSIONAL_API_KEY }),
    })
  )

  items.set(
    EXTERNAL_PROFESSIONAL_PENDING_SERVICE_KEY,
    new ContainerItem({
      instance: new ExternalProfessionalPendingService({
        apiKey: EXTERNAL_PROFESSIONAL_PENDING_API_KEY,
      }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getUserTypeExternalProfessionalContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[USER_TYPE_EXTERNAL_PROFESSIONAL_MODULE],
      },
    })
  }

  return container
}
