import { Container } from '../../common/container/Container'

export type SharedWithContainerConfig = {
  moduleFullUrl: string
}

export type SharedWithProps = {
  parentContainer: Container
  config: SharedWithContainerConfig
}

export const SHARED_WITH_MODULE = Symbol('SHARED_WITH_MODULE')

export const SHARED_WITH_API_KEY = Symbol('SHARED_WITH')

export const SHARED_WITH_SERVICE_KEY = Symbol('SHARED_WITH_SERVICE_KEY')
