export enum PriorityLevel {
  Low = 1,
  Normal = 11,
  High = 21,
  Critical = 31,
}

export const priorityLevels = (): Record<PriorityLevel, string> => ({
  [PriorityLevel.Low]: 'Low',
  [PriorityLevel.Normal]: 'Normal',
  [PriorityLevel.High]: 'High',
  [PriorityLevel.Critical]: 'Critical',
})
