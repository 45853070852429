import { Query } from '../../../common/api/Query'
import { ConsultaUser } from './ConsultaUser'
import { Message } from './Message'

export type ConsultaProps = {
  id: string | undefined
  name: string
  description: string
  users: ConsultaUser[]
  messages: Message[] | undefined
  unreadMessages: number
  closed: boolean
  circleID: string | undefined
  userCircleID: string | undefined
  pathologyID: string
  consultaType: string | undefined
}

export class Consulta {
  private readonly _id: string | undefined

  private _name: string
  private _description: string
  private _users: ConsultaUser[]
  private _messages: Message[]
  private _unreadMessages: number
  private _closed: boolean
  private _circleID: string | undefined
  private _userCircleID: string | undefined
  private _pathologyID: string
  private _consultaType: string | undefined

  constructor(p: ConsultaProps) {
    this._id = p.id
    this._name = p.name
    this._description = p.description
    this._users = p.users
    this._messages = p.messages ?? []
    this._unreadMessages = p.unreadMessages
    this._closed = p.closed
    this._circleID = p.circleID
    this._userCircleID = p.userCircleID
    this._pathologyID = p.pathologyID
    this._consultaType = p.consultaType
  }

  get id(): string | undefined {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get description(): string {
    return this._description
  }

  set description(value: string) {
    this._description = value
  }

  get users(): ConsultaUser[] {
    return [...this._users]
  }

  set users(value: ConsultaUser[]) {
    this._users = value
  }

  addUser(value: ConsultaUser) {
    if (this._users) {
      this._users.push(value)
    } else {
      this._users = [value]
    }
  }

  removeUser(id: string) {
    if (this._users) {
      this._users = this._users.filter((u) => u.userID !== id)
    }
  }

  get messages(): Message[] {
    if (this._messages) {
      return [...this._messages]
    }

    return this._messages
  }

  addMessage(value: Message) {
    if (this._messages) {
      this._messages.push(value)
    } else {
      this._messages = [value]
    }
  }

  removeMessage(id: string) {
    this._messages = this._messages.filter((m) => m.id !== id)
  }

  get unreadMessages(): number {
    return this._unreadMessages
  }

  set unreadMessages(value: number) {
    this._unreadMessages = value
  }

  get closed(): boolean {
    return this._closed
  }

  set closed(value: boolean) {
    this._closed = value
  }

  get circleID(): string | undefined {
    return this._circleID
  }

  set circleID(value: string | undefined) {
    this._circleID = value
  }

  get userCircleID(): string | undefined {
    return this._userCircleID
  }

  set userCircleID(value: string | undefined) {
    this._userCircleID = value
  }

  get pathologyID(): string {
    return this._pathologyID
  }

  set pathologyID(value: string) {
    this._pathologyID = value
  }

  get consultaType(): string | undefined {
    return this._consultaType
  }

  set consultaType(value: string | undefined) {
    this._consultaType = value
  }
}

export class ConsultaParams extends Query<Consulta> {}
