export type CirclePatientDataProps = {
  patientChart: boolean
  evolutionaryControl: boolean
  vacCalendar: boolean
  healthyChildChart: boolean
  activePlan: boolean
  symptoms: boolean
  treatments: boolean
  oralHealth: boolean
  wearables: boolean
}

export class PatientConfig {
  private _patientChart: boolean
  private _evolutionaryControl: boolean
  private _vacCalendar: boolean
  private _healthyChildChart: boolean
  private _activePlan: boolean
  private _symptoms: boolean
  private _treatments: boolean
  private _oralHealth: boolean
  private _wearables: boolean

  constructor(p: CirclePatientDataProps) {
    this._patientChart = p.patientChart
    this._evolutionaryControl = p.evolutionaryControl
    this._vacCalendar = p.vacCalendar
    this._healthyChildChart = p.healthyChildChart
    this._activePlan = p.activePlan
    this._symptoms = p.symptoms
    this._treatments = p.treatments
    this._oralHealth = p.oralHealth
    this._wearables = p.wearables
  }

  get patientChart(): boolean {
    return this._patientChart
  }

  set patientChart(value: boolean) {
    this._patientChart = value
  }

  get evolutionaryControl(): boolean {
    return this._evolutionaryControl
  }

  set evolutionaryControl(value: boolean) {
    this._evolutionaryControl = value
  }

  get vacCalendar(): boolean {
    return this._vacCalendar
  }

  set vacCalendar(value: boolean) {
    this._vacCalendar = value
  }

  get healthyChildChart(): boolean {
    return this._healthyChildChart
  }

  set healthyChildChart(value: boolean) {
    this._healthyChildChart = value
  }

  get activePlan(): boolean {
    return this._activePlan
  }

  set activePlan(value: boolean) {
    this._activePlan = value
  }

  get symptoms(): boolean {
    return this._symptoms
  }

  set symptoms(value: boolean) {
    this._symptoms = value
  }

  get treatments(): boolean {
    return this._treatments
  }

  set treatments(value: boolean) {
    this._treatments = value
  }

  get oralHealth(): boolean {
    return this._oralHealth
  }

  set oralHealth(value: boolean) {
    this._oralHealth = value
  }

  get wearables(): boolean {
    return this._wearables
  }

  set wearables(value: boolean) {
    this._wearables = value
  }
}
