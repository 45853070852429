import i18n from '../../../i18n'

export enum AttendanceType {
  pending = 0,
  assistance = 1,
  absence = 2,
}

export const attendanceType = (): Record<AttendanceType, string> => ({
  [AttendanceType.pending]: i18n.t('pending'),
  [AttendanceType.assistance]: i18n.t('assistance'),
  [AttendanceType.absence]: i18n.t('absence'),
})
