import { v4 as uuidv4 } from 'uuid'
import { SportCenter } from './SportCenter'

export interface SportCenterDTO {
  id: string
  cityHallID: string
  name: string
  postalCode: string
  location: string
  latitude: string
  longitude: string
}

export function emptySportCenterDTO() {
  return {
    id: uuidv4(),
    cityHallID: '',
    name: '',
    postalCode: '',
    location: '',
    latitude: '',
    longitude: '',
  }
}

export function fromModel(e: SportCenter): SportCenterDTO {
  return {
    id: e.id,
    cityHallID: e.cityHallID,
    name: e.name,
    postalCode: e.postalCode,
    location: e.location,
    latitude: e.latitude,
    longitude: e.longitude,
  }
}

export function toModel(d: SportCenterDTO): SportCenter {
  return new SportCenter(d)
}
