import { Query } from '../../../common/api/Query'

export type CircleAssignFormProps = {
  id: string
  circleID: string
  formsID: string[]
  rolesID: string[]
}

export class CircleAssignForm {
  private readonly _id: string
  private _circleID: string
  private _formsID: string[]
  private _rolesID: string[]

  constructor(p: CircleAssignFormProps) {
    this._id = p.id
    this._circleID = p.circleID
    this._formsID = p.formsID
    this._rolesID = p.rolesID
  }

  get id(): string {
    return this._id
  }

  get circleID(): string {
    return this._circleID
  }

  set circleID(value: string) {
    this._circleID = value
  }

  get formsID(): string[] {
    return this._formsID
  }

  set formsID(value: string[]) {
    this._formsID = value
  }

  get rolesID(): string[] {
    return this._rolesID
  }

  set rolesID(value: string[]) {
    this._rolesID = value
  }
}

export class CircleAssignResourcesQuery extends Query<CircleAssignForm> {}
