import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import {
  emptyPatientSymptomDTO,
  fromModel,
  PatientSymptomDTO,
} from '../../../modules/patient-data/models/PatientSymptomDTO'
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { TextField } from '@mui/material'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CALENDAR } from '../../../routes/routes-constants'
import { FormCard } from '../../../components/form-card/FormCard'
import { getUserContainer } from '../../../container/user-module'
import { ILoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../modules/users'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { PATIENT_SYMPTOM_SERVICE_KEY, SYMPTOM_SERVICE_KEY } from '../../../modules/patient-data'
import { PatientSymptomService } from '../../../modules/patient-data/services/PatientSymptomService'
import { BoolQueryParam, Query, QueryParam } from '../../../common/api/Query'
import genericStyles from '../../generic/GenericForm.module.css'
import { SymptomService } from '../../../modules/patient-data/services/SymptomService'
import { SymptomQuery } from '../../../modules/patient-data/models/Symptom'
import {
  ConditionField,
  patientSymptoms,
  ValueTypes,
} from '../../../modules/patient-data/enums/PatientSymptomsNames'
import { FormFieldType } from '../../../modules/forms/enums/FormFieldType'
import styles from '../../patient-data/symptoms/Editor.module.css'
import { emptyPatientSymptomConditionDTO } from '../../../modules/patient-data/models/PatientSymptomConditionDTO'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { getUserCircleContainer } from '../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../modules/user-circle'
import style from '../Editor.module.css'
import close from '../../../assets/esfera/buttons/delete.svg'
import { Header } from '../../../components/header/Header'
import { DateTimePicker } from '@mui/x-date-pickers'
import { TextFieldItem } from '../../../components/form-card/TextFieldItem'

enum SymptomErrors {
  NO_SYMPTOM = 'aSymptomMustBeSelected',
  REQUIRED_FIELDS = 'requiredFields',
}

type AutocompleteSymptomInterface = {
  commonSymptom: string
  key: string
  value: string
}

type SymptomsFormProps = {
  id?: string
  open?: boolean
  onClose?: () => void
  date?: Date
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const patientDataContainer = getPatientDataContainer()
const patientSymptomService = patientDataContainer.get<PatientSymptomService>(
  PATIENT_SYMPTOM_SERVICE_KEY
)
const symptomService = patientDataContainer.get<SymptomService>(SYMPTOM_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export function GeneralSymptomsModal(props: SymptomsFormProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const circle = userCircleActiveService.getActiveUser()
  const activeUser = userCircleActiveService.getActiveFullUserCircle()

  const loggedUser = loggedUserService.get()
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  const [symptomMap, setSymptomMap] = useState<Map<string, AutocompleteSymptomInterface>>(new Map())
  const [selectedSymptom, setSelectedSymptom] = useState<AutocompleteSymptomInterface>()
  const [patientSymptom, setPatientSymptom] = useState<PatientSymptomDTO>(emptyPatientSymptomDTO())
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [conditions, setConditions] = useState<ConditionField[]>([])

  console.log('symptommap', symptomMap.size)
  useEffect(() => {
    setIsEditing(!!props.id)
    if (props.id) {
      patientSymptomService.getByID(props.id).subscribe((res) => {
        if (res) {
          setPatientSymptom(fromModel(res))
        }
      })
    } else {
      if (loggedUser?.id && activeUser?.id) {
        setPatientSymptom(
          Object.assign(
            { ...patientSymptom },
            {
              creatorID: loggedUser.id,
            }
          )
        )
      }
    }
    if (!circle ?? !activeUser) {
      return
    }
    const updatedSymptomMap = new Map(symptomMap)
    const uniqueSymptomIds = new Set()

    /* if (activeUser.circle.symptoms && activeUser.circle.symptoms.length > 0) {
      activeUser.circle.symptoms.forEach((p) => {
        const symptomId = p.idIcon ?? ''
        if (!uniqueSymptomIds.has(symptomId)) {
          const symptomData: AutocompleteSymptomInterface = {
            commonSymptom: 'true',
            key: symptomId,
            value: p.name ?? '',
          }
          updatedSymptomMap.set(symptomId, symptomData)
          uniqueSymptomIds.add(symptomId)
        }
      })
    } */

    symptomService
      .getFilteredList(
        new Query<SymptomQuery>({
          query: [new QueryParam<SymptomQuery>('commonSymptom', new BoolQueryParam(true))],
        })
      )
      .subscribe((sl) => {
        sl.items.forEach((s) => {
          const symptomId = s.id ?? ''
          if (!uniqueSymptomIds.has(symptomId)) {
            const symptomData: AutocompleteSymptomInterface = {
              commonSymptom: s.commonSymptom ? 'true' : 'false',
              key: symptomId,
              value: s.name ?? '',
            }
            updatedSymptomMap.set(symptomId, symptomData)
            uniqueSymptomIds.add(symptomId)
          }
        })
        console.log(sl.count)

        setSymptomMap(updatedSymptomMap)
      })
  }, [activeUser])

  useEffect(() => {
    if (!selectedSymptom) {
      return
    }
    setErrorMessage('')
    const conds = patientSymptoms.get(selectedSymptom.value) ?? []
    setConditions(conds)
    setPatientSymptom(
      Object.assign(
        { ...patientSymptom },
        {
          conditions: conds.map((c) => ({
            name: c.name,
            type: c.valueType,
            value: falsyValue(c.valueType),
          })),
        }
      )
    )
  }, [selectedSymptom])

  useEffect(() => {
    if (props.id && symptomMap.has(patientSymptom.symptomID)) {
      setSelectedSymptom(symptomMap.get(patientSymptom.symptomID))
    }
  }, [symptomMap])

  const falsyValue = (vt: ValueTypes): any => {
    switch (vt) {
      case ValueTypes.Bool:
        return false
      case ValueTypes.Float:
        return 0
      default:
        return undefined
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setPatientSymptom(Object.assign({ ...patientSymptom }, { [e.target.name]: e.target.value }))

  const handleStartDate = (event: any) =>
    event &&
    setPatientSymptom(Object.assign({ ...patientSymptom }, { symptomStartDate: event.toDate() }))

  const handleEndDate = (event: any) =>
    event && setPatientSymptom(Object.assign({ ...patientSymptom }, { finishDate: event.toDate() }))

  const handleSymptomID = (value: any) => {
    if (!value) {
      return
    }
    setSelectedSymptom(value)
    setPatientSymptom(Object.assign({ ...patientSymptom }, { symptomID: value.key }))
  }

  const createField = (c: ConditionField): JSX.Element => {
    switch (c.fieldType) {
      case FormFieldType.CheckBox:
        return (
          <Box style={{ marginBottom: 13 }}>
            <p className={styles.label + ' ' + styles.checkBoxLabel}>{c.name}</p>
            <Checkbox
              name={c.name}
              checked={patientSymptom.conditions.find((cond) => cond.name === c.name)?.value}
              onChange={(e) => handleCheckbox(e, c.valueType)}
            />
          </Box>
        )
      case FormFieldType.Select:
        return (
          <Box style={{ marginBottom: 13 }}>
            <p className={styles.label}>{c.name}</p>
            <Select
              key={c.name}
              id={c.name}
              variant={'outlined'}
              value={
                patientSymptom.conditions.find(
                  (cond) => cond.name === c.name && !Object.is(+cond.value, NaN)
                )?.value ?? ''
              }
              onChange={(e) => handleSelect(e, c.name)}
              className={styles.select}
              required={true}
            >
              {Array.from(c.options ? c.options.keys() : []).map((k) => (
                <MenuItem key={k} value={c.options ? c.options.get(k) : ''}>
                  {k}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )
      default:
        return <></>
    }
  }

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, type: number) => {
    const c = emptyPatientSymptomConditionDTO()
    c.name = e.target.name
    c.type = type
    c.value = e.target.checked
    const newConditions = patientSymptom.conditions.map((cond) => {
      if (cond.name === c.name) {
        return c
      }
      return cond
    })
    setPatientSymptom(Object.assign({ ...patientSymptom }, { conditions: newConditions }))
  }

  const handleSelect = (event: SelectChangeEvent<{ value: unknown }>, name: string) =>
    setPatientSymptom(
      Object.assign(
        { ...patientSymptom },
        {
          conditions: patientSymptom.conditions.map((cond) => {
            if (cond.name === name) {
              return {
                name: cond.name,
                type: cond.type,
                value: +(event.target.value as unknown as number),
              }
            }
            return cond
          }),
        }
      )
    )

  const goBack = () => navigate(ROUTE_CALENDAR, { state: { selectedDate: props.date } })

  const validateSymptom = () => {
    if (!patientSymptom.symptomID) {
      setErrorMessage(SymptomErrors.NO_SYMPTOM)
      return false
    }

    const required = conditions.filter((c) => c.required)
    let found = true
    required.forEach((c) => {
      if (
        !patientSymptom.conditions.find(
          (cond) => c.options && Array.from(c.options.values()).find((v) => +v === cond.value)
        )
      ) {
        found = false
      }
    })
    if (!found) {
      setErrorMessage(SymptomErrors.REQUIRED_FIELDS)
      return false
    }

    setErrorMessage('')
    return true
  }

  const saveSymptom = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateSymptom()) {
      return
    }

    patientSymptom.userID = activeUser?.user.id ?? ''
    patientSymptom.userCircleID = activeUser?.id ?? ''

    if (props.id) {
      patientSymptomService.update(patientSymptom).subscribe(() => {
        goBack()
        props.onClose && props.onClose()
      })
      return
    }

    patientSymptomService.add(patientSymptom).subscribe(() => {
      goBack()
      props.onClose && props.onClose()
    })
  }

  return (
    <Modal className={style.modal} open={props.open ?? false} onClose={props.onClose}>
      <Box className={style.modalContainer}>
        <FormCard>
          <>
            <Box className={style.exitIcon}>
              <img className={style.icon} src={close} alt={'close icon'} onClick={props.onClose} />
            </Box>
            <Header label={t('symptoms')} />
            <form className={style.form} onSubmit={saveSymptom}>
              <Box
                mt={3}
                mb={3}
                className={style.textField}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
              >
                <Box style={{ marginBottom: 13 }}>
                  <Autocomplete
                    key={patientSymptom.id}
                    id={patientSymptom.id}
                    options={Array.from(symptomMap.values()).sort((a, b) => {
                      const sort = a.value.localeCompare(b.value)
                      return sort !== 0 ? sort : a.value.localeCompare(b.value)
                    })}
                    // groupBy={(option) => option.pathology}
                    getOptionLabel={(opt) => opt.value}
                    value={selectedSymptom ?? { commonSymptom: '', key: '', value: '' }}
                    onChange={(_, v) => handleSymptomID(v)}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" label={t('symptom')} />
                    )}
                    className={genericStyles.autocompleteStyle}
                    noOptionsText={t('noElementsAvailable')}
                    disabled={isEditing}
                  />
                </Box>
              </Box>

              <>
                <Box className={style.datePickerContainer} mb={3}>
                  <Box className={style.datePickers} sx={{ justifyContent: 'flex-start' }}>
                    <span style={{ fontFamily: 'Poppins', marginRight: '24px' }}>
                      {t('startDate')}*
                    </span>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                      <Box sx={{ width: '180px' }}>
                        <DateTimePicker
                          PopperProps={{
                            sx: {
                              '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                                fontFamily: 'Poppins',
                                textTransform: 'capitalize',
                              },
                              '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                                fontFamily: 'Poppins',
                              },
                              '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                                fontFamily: 'Poppins',
                              },
                              '& .css-3eghsz-PrivatePickersYear-button': {
                                fontFamily: 'Poppins',
                              },
                            },
                          }}
                          onError={(reason, value) => {
                            switch (reason) {
                              case 'invalidDate':
                                setDateTimePickerError(t('invalidDateMessage'))
                                break
                              case 'minDate':
                                setDateTimePickerError(t('minDateMessage'))
                                break
                            }
                          }}
                          renderInput={(props) => (
                            <TextField
                              sx={{
                                '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': {
                                  paddingRight: '17px',
                                },
                                '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                                  fontFamily: 'Poppins',
                                },
                              }}
                              variant={'standard'}
                              helperText={props.error && DateTimePickerError}
                              {...props}
                            />
                          )}
                          inputFormat="DD/MM/YYYY HH:mm"
                          onChange={handleStartDate}
                          value={patientSymptom.symptomStartDate}
                        />
                      </Box>
                    </LocalizationProvider>
                  </Box>

                  <Box className={style.datePickers} sx={{ justifyContent: 'flex-end' }}>
                    <span style={{ fontFamily: 'Poppins', marginRight: '24px' }}>
                      {t('finishDate')}
                    </span>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                      <Box sx={{ width: '180px' }}>
                        <DateTimePicker
                          PopperProps={{
                            sx: {
                              '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                                fontFamily: 'Poppins',
                                textTransform: 'capitalize',
                              },
                              '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                                fontFamily: 'Poppins',
                              },
                              '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                                fontFamily: 'Poppins',
                              },
                              '& .css-3eghsz-PrivatePickersYear-button': {
                                fontFamily: 'Poppins',
                              },
                            },
                          }}
                          onError={(reason, value) => {
                            switch (reason) {
                              case 'invalidDate':
                                setDateTimePickerError(t('invalidDateMessage'))
                                break
                              case 'minDate':
                                setDateTimePickerError(t('minDateMessage'))
                                break
                            }
                          }}
                          renderInput={(props) => (
                            <TextField
                              sx={{
                                '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': {
                                  paddingRight: '17px',
                                },
                                '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                                  fontFamily: 'Poppins',
                                },
                              }}
                              helperText={props.error && DateTimePickerError}
                              variant={'standard'}
                              {...props}
                            />
                          )}
                          key={'finishDate'}
                          inputFormat="DD/MM/YYYY HH:mm"
                          onChange={handleEndDate}
                          value={patientSymptom.finishDate}
                          minDate={dayjs(patientSymptom.symptomStartDate ?? undefined)}
                        />
                      </Box>
                    </LocalizationProvider>
                  </Box>
                </Box>

                {conditions.map((c) => createField(c))}

                <Box className={style.commentTextField}>
                  <Box mb={1}>
                    <p>{t('comments')}</p>
                  </Box>
                  <TextFieldItem
                    field="comment"
                    value={patientSymptom.comment}
                    label={''}
                    type={'text'}
                    handleChange={handleInput}
                    rows={6}
                    required={false}
                  />
                </Box>
                {errorMessage && (
                  <Box style={{ marginBottom: 13 }}>
                    <Alert severity="warning" key="errorMessage" id="errorMessage">
                      {t(errorMessage)}
                      {errorMessage === SymptomErrors.REQUIRED_FIELDS
                        ? ': ' +
                          conditions
                            .filter((c) => c.required)
                            .map((c) => c.name)
                            .join(', ')
                        : ''}
                    </Alert>
                  </Box>
                )}
                <Box className={style.buttonsContainer}>
                  <AppButton
                    theme={ButtonTheme.NewSecondary}
                    type={'button'}
                    label={t('cancel')}
                    handler={() => {
                      props.onClose && props.onClose()
                    }}
                  />

                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    type={'submit'}
                    label={t('save')}
                    handler={saveSymptom}
                  />
                </Box>
              </>
            </form>
          </>
        </FormCard>
      </Box>
    </Modal>
  )
}
