import i18n from '../../../i18n'

export enum FormType {
  Template = 0,
  Form = 1,
}

export const formTypes = (): Record<FormType, string> => ({
  [FormType.Template]: i18n.t('template'),
  [FormType.Form]: i18n.t('form'),
})
