import { v4 as uuidv4 } from 'uuid'
import { Vaccine } from './Vaccines'

export interface VaccinesDTO {
  id: string | undefined
  tvac: number
  name: string
  description: string | undefined
}

export function emptyVaccinesDTO() {
  return {
    id: uuidv4(),
    tvac: 0,
    name: '',
    description: '',
  }
}

export function fromModel(d: Vaccine): VaccinesDTO {
  return {
    id: d.id,
    tvac: d.tvac,
    name: d.name,
    description: d.description,
  }
}

export function toModel(d: VaccinesDTO): Vaccine {
  return new Vaccine(d)
}
