export class SymptomConfig {
  name: string
  idIcon: string
  idSymptom: string

  constructor(name: string, idIcon: string, idSymptom: string) {
    this.name = name
    this.idIcon = idIcon
    this.idSymptom = idSymptom
  }
}
