import { v4 as uuidv4 } from 'uuid'
import { CityHallContact } from './CityHallContact'
import { CityHall } from './CityHall'

export interface CityHallDTO {
  id: string
  name: string
  city: string
  contact: CityHallContact[]
}

export function emptyCityHallDTO() {
  return {
    id: uuidv4(),
    name: '',
    city: '',
    contact: [],
  }
}

export function fromModel(e: CityHall): CityHallDTO {
  return {
    id: e.id,
    name: e.name,
    city: e.city,
    contact: e.contact,
  }
}

export function toModel(d: CityHallDTO): CityHall {
  return new CityHall(d)
}
