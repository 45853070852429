import { Container, ContainerItem } from '../common/container/Container'
import { VaccinesService } from '../modules/vaccines/services/VaccinesService'
import {
  VACCINES_MODULE,
  VaccinesProps,
  VACCINES_SERVICE_KEY,
  VACCINES_API_KEY,
} from '../modules/vaccines/container'
import { VaccinesApi } from '../modules/vaccines/api/VaccinesApi'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'

let container: Container

function init(p: VaccinesProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(VACCINES_API_KEY, new ContainerItem({ instance: new VaccinesApi() }))

  items.set(
    VACCINES_SERVICE_KEY,
    new ContainerItem({ instance: new VaccinesService({ apiKey: VACCINES_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getVaccinesContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[VACCINES_MODULE],
      },
    })
  }

  return container
}
