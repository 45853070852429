import { Query } from '../../../common/api/Query'

export type UserTrainingGroupProps = {
  groupID: string
  userCircleID: string
}

export class UserTrainingGroup {
  private _groupID: string
  private _userCircleID: string

  constructor(props: UserTrainingGroupProps) {
    this._groupID = props.groupID
    this._userCircleID = props.userCircleID
  }

  get groupID(): string {
    return this._groupID
  }

  set groupID(value: string) {
    this._groupID = value
  }

  get userCircleID(): string {
    return this._userCircleID
  }

  set userCircleID(value: string) {
    this._userCircleID = value
  }
}

export class UserTrainingGroupQuery extends Query<UserTrainingGroup> {
  groupID?: string
  groupIDs?: string[]
  userCircleID?: string
  userCircleIDs?: string[]
}