import { v4 as uuidv4 } from 'uuid'
import { ExternalProfessionalPending } from './ExternalProfessionalPending'

export interface ExternalProfessionalPendingDTO {
  email: string
  token: string
  groupID: string
  typeExternalProfessionalID: string
}

export function emptyExternalProfessionalPendingDTO() {
  return {
    email: '',
    token: '',
    groupID: '',
    typeExternalProfessionalID: '',
  }
}

export function fromModel(e: ExternalProfessionalPending): ExternalProfessionalPendingDTO {
  return {
    email: e.email,
    token: e.token,
    groupID: e.groupID,
    typeExternalProfessionalID: e.typeExternalProfessionalID,
  }
}

export function toModel(d: ExternalProfessionalPendingDTO): ExternalProfessionalPending {
  return new ExternalProfessionalPending(d)
}
