import { ArticleCategory } from '../enums/ArticleCategory'
import { LangType } from '../enums/LangType'
import { Article } from './Article'
import { v4 as uuidv4 } from 'uuid'

export interface ArticleDTO {
  id: string | undefined
  category: ArticleCategory
  lang: LangType
  title: string
  content: string
  files: string[]
  metaTitle: string
  metaDescription: string
  ogTitle: string
  ogDescription: string
  ogImage: string[]
  slug: string // unique
  createdAt: Date
  updatedAt: Date
  tags: string[]
}

export function emptyArticleDTO() {
  return {
    id: uuidv4(),
    category: ArticleCategory.HealthyKid,
    lang: LangType.Spanish,
    title: '',
    content: '',
    files: [],
    metaTitle: '',
    metaDescription: '',
    ogTitle: '',
    ogDescription: '',
    ogImage: [],
    slug: 'slug',
    createdAt: new Date(),
    updatedAt: new Date(),
    tags: [],
  }
}

export function fromModel(a: Article): ArticleDTO {
  return {
    id: a.id,
    category: a.category,
    lang: a.lang,
    title: a.title,
    content: a.content,
    files: a.files || [],
    metaTitle: a.metaTitle,
    metaDescription: a.metaDescription,
    ogTitle: a.ogTitle,
    ogDescription: a.ogDescription,
    ogImage: a.ogImage || [],
    slug: a.slug, // unique
    createdAt: a.createdAt,
    updatedAt: a.updatedAt,
    tags: a.tags,
  }
}

export function toModel(d: ArticleDTO): Article {
  return new Article(d)
}
