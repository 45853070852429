import React, { useState } from 'react'
import imageExtensions from 'image-extensions'
import isUrl from 'is-url'
import { Transforms, Node, Path, Range, Editor as SlateEditor } from 'slate'
import { ReactEditor, useFocused, useSelected, useSlateStatic } from 'slate-react'
import styles from './TextEditor.module.css'
import { Editor, ImageElement } from './Types'
import { Button, Icon } from './ComponentsTextEditor'
import DeleteIcon from '@mui/icons-material/Delete'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'

export const withCorrectVoidBehavior = (editor: SlateEditor) => {
  const { deleteBackward, insertBreak } = editor

  // if current selection is void node, insert a default node below
  editor.insertBreak = () => {
    if (!editor.selection || !Range.isCollapsed(editor.selection)) {
      return insertBreak()
    }

    const selectedNodePath = Path.parent(editor.selection.anchor.path)
    const selectedNode = Node.get(editor, selectedNodePath)
    if (SlateEditor.isVoid(editor, selectedNode)) {
      SlateEditor.insertNode(editor, {
        type: 'paragraph',
        children: [{ text: '' }],
      })
      return
    }

    insertBreak()
  }

  // if prev node is a void node, remove the current node and select the void node
  editor.deleteBackward = (unit) => {
    if (
      !editor.selection ||
      !Range.isCollapsed(editor.selection) ||
      editor.selection.anchor.offset !== 0
    ) {
      return deleteBackward(unit)
    }

    const parentPath = Path.parent(editor.selection.anchor.path)
    const parentNode = Node.get(editor, parentPath)
    const parentIsEmpty = Node.string(parentNode).length === 0

    if (parentIsEmpty && Path.hasPrevious(parentPath)) {
      const prevNodePath = Path.previous(parentPath)
      const prevNode = Node.get(editor, prevNodePath)
      if (SlateEditor.isVoid(editor, prevNode)) {
        return Transforms.removeNodes(editor)
      }
    }

    deleteBackward(unit)
  }

  return editor
}

export const withImages = (editor: Editor) => {
  const { insertData, isVoid } = editor

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result
            insertImage(editor, url)
          })

          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const insertImage = (editor: Editor, url: any, align?: string) => {
  const image: ImageElement = {
    type: 'image',
    url,
    align: align ?? undefined,
    children: [{ text: '' }],
  }
  Transforms.insertNodes(editor, image)
}

export const Image = ({ attributes, children, element }: any) => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)
  const selected = useSelected()
  const focused = useFocused()
  const [align, setAlign] = useState<string>('left')
  // TODO set align with left, center, right buttons
  return (
    <div {...attributes}>
      {element.url ? (
        <div
          contentEditable={false}
          style={{ position: 'relative', display: 'flex', justifyContent: align }}
        >
          <img
            src={element.url}
            className={selected && focused ? styles.imageInline : styles.imageNone}
          />
          <div
            className={
              selected && focused ? styles.buttonDeleteImageInline : styles.buttonDeleteImageNone
            }
            style={
              align === 'left'
                ? {}
                : align === 'center'
                  ? { marginLeft: '45%' }
                  : { marginLeft: '90%' }
            }
          >
            <Button active onClick={() => Transforms.removeNodes(editor, { at: path })}>
              <DeleteIcon className={styles.iconDelete} />
            </Button>
            <Button
              active
              onClick={() => {
                setAlign('left')
              }}
            >
              <FormatAlignLeftIcon className={styles.iconDelete} />
            </Button>
            <Button active onClick={() => setAlign('center')}>
              <FormatAlignCenterIcon className={styles.iconDelete} />
            </Button>
            <Button active onClick={() => setAlign('right')}>
              <FormatAlignRightIcon className={styles.iconDelete} />
            </Button>
          </div>

          <div>{element.caption}</div>
        </div>
      ) : undefined}
      {children}
    </div>
  )
}

export const InsertImageButton = () => {
  const editor = useSlateStatic()
  const align = 'left'

  return (
    <Button
      className={styles.generalButton}
      onClick={(event: any) => {
        event.preventDefault()
        const url = prompt('Introduce la URL de la imagen:')
        Transforms.deselect = () => {}
        if (url && !isImageUrl(url)) {
          alert('URL is not an image')
          return
        }
        insertImage(editor, url, align)
      }}
    >
      <Icon>
        <AddPhotoAlternateIcon />
      </Icon>
    </Button>
  )
}

const isImageUrl = (url: any) => {
  if (!url) return false
  if (url.slice(0, 4) === 'blob') return true
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop()
  return imageExtensions.includes(ext as string)
}
