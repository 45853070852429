import {
  Box,
  Divider,
  Modal,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  IconButton,
  Alert,
} from '@mui/material'
import styles from './AddSeeEditCityHallModal.module.css'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState, ChangeEvent } from 'react'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useSnackbar } from 'notistack'
import icoDelete from '../../assets/buttons/close.svg'
import icoAdd from '../../assets/left_menu/plusWhite.svg'
import addButton from '../../assets/draggable/btn-add.svg'
import deleteButton from '../../assets/draggable/btn-delete.svg'
import { getCityHallContainer } from '../../container/cityHall-module'
import { CityHallService } from '../../modules/cityHall/services/CityHallService'
import { CITY_HALL_SERVICE_KEY } from '../../modules/cityHall'
import {
  CityHallDTO,
  emptyCityHallDTO,
  fromModel as fromCityHallModel,
} from '../../modules/cityHall/models/CityHallDTO'
import { CityHall } from '../../modules/cityHall/models/CityHall'
import { Municipality as municipalityArr } from '../../common/enums/Municipality'
import {
  CityHallContactDTO,
  emptyCityHallContactDTO,
} from '../../modules/cityHall/models/CityHallContactDTO'
import { isValidEmail, isValidTel } from '../../common/utils/strings'

type AddSeeEditCityHallModalProps = {
  id?: string
  open: boolean
  handleClose: () => void
  cityHallData?: CityHall
  handleRefreshTable?: () => void
}
const cityHallService = getCityHallContainer().get<CityHallService>(CITY_HALL_SERVICE_KEY)

export function AddSeeEditCityHallModal(props: AddSeeEditCityHallModalProps) {
  const [cityHall, setCityHall] = useState<CityHallDTO>(emptyCityHallDTO())
  const [cityHallContacts, setCityHallContacts] = useState<CityHallContactDTO[]>([
    emptyCityHallContactDTO(),
  ])
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [errorMessage, setErrorMessage] = useState<string>('')

  type emailPhone = {
    email: string
    phone: string
  }
  const [extraPhoneEmail, setExtraPhonEmail] = useState<emailPhone[][]>([
    [{ email: '', phone: '' }],
  ])

  enum FieldsError {
    EMPTY = 'fieldNoEmpty',
    ERROREMAIL = 'notCorrectEmail',
    ERRORPHONE = 'notCorrectPhone',
  }

  const validateField = () => {
    if (
      Object.values(cityHall).some((value) => value === '') ||
      cityHallContacts.some((contact) =>
        Object.values(contact).some((valueContact) => valueContact === '')
      )
    ) {
      setErrorMessage(FieldsError.EMPTY)
      return false
    }

    if (
      extraPhoneEmail.some((value) => value.some((phoneEmail) => !isValidEmail(phoneEmail.email)))
    ) {
      setErrorMessage(FieldsError.ERROREMAIL)
      return false
    }

    if (
      extraPhoneEmail.some((value) => value.some((phoneEmail) => !isValidTel(phoneEmail.phone)))
    ) {
      setErrorMessage(FieldsError.ERRORPHONE)
      return false
    }

    return true
  }

  useEffect(() => {
    if (!props.cityHallData) return
    setCityHall(fromCityHallModel(props.cityHallData))
    setCityHallContacts(props.cityHallData.contact)
    fromContactsToEmailPhone(props.cityHallData.contact)
  }, [props.cityHallData])

  useEffect(() => {
    if (!props.id || props.cityHallData) return
    cityHallService.getByID(props.id).subscribe((res) => {
      if (!res) return
      setCityHall(res)
    })
  }, [])

  const fromContactsToEmailPhone = (contacts: CityHallContactDTO[]) => {
    if (contacts.length === 0) return

    const finalArr: emailPhone[][] = []
    contacts.forEach((contact) => {
      const temporalArr: emailPhone[] = []
      const emailArr = contact.email.split(',')
      const phoneArr = contact.phone.split(',')
      if (emailArr.length > phoneArr.length) {
        emailArr.forEach((email, index) => {
          temporalArr.push({ email: email, phone: phoneArr[index] ? phoneArr[index] : '' })
        })
      } else {
        phoneArr.forEach((phone, index) => {
          temporalArr.push({ email: emailArr[index] ? emailArr[index] : '', phone: phone })
        })
      }
      finalArr.push(temporalArr)
    })
    setExtraPhonEmail(finalArr)
  }

  const handleInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCityHall(Object.assign({ ...cityHall }, { [event.target.name]: event.target.value }))
  }

  const handleSelect = (event: SelectChangeEvent<string>) => {
    setCityHall(Object.assign({ ...cityHall }, { city: event.target.value }))
  }

  const handlerInputArray = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: number
  ) => {
    const auxiliarCHC = [...cityHallContacts]
    auxiliarCHC[key] = { ...auxiliarCHC[key], [event.target.name]: event.target.value }
    setCityHallContacts(auxiliarCHC)
    setCityHall(Object.assign({ ...cityHall }, { contact: auxiliarCHC }))
  }

  const handlerInputPhoneEmail = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
    index2: number
  ) => {
    const temporalArr = [...extraPhoneEmail]
    temporalArr[index][index2] = {
      ...temporalArr[index][index2],
      [event.target.name]: event.target.value,
    }
    setExtraPhonEmail(temporalArr)

    const auxiliarCHC = [...cityHallContacts]
    const nameVariable = event.target.name === 'phone' ? 'phone' : 'email'
    let contactValue = ''

    temporalArr[index].forEach((value) => {
      if (value[nameVariable] !== '') {
        contactValue =
          contactValue === '' ? value[nameVariable] : contactValue + ',' + value[nameVariable]
      }
    })

    auxiliarCHC[index] = { ...auxiliarCHC[index], [event.target.name]: contactValue }
    setCityHallContacts(auxiliarCHC)
    setCityHall(Object.assign({ ...cityHall }, { contact: auxiliarCHC }))
  }

  const handleContact = () => {
    const auxiliarCHC = [...cityHallContacts]
    const temporalArr = [...extraPhoneEmail]
    auxiliarCHC.push(emptyCityHallContactDTO())
    temporalArr.push([{ email: '', phone: '' }])
    setCityHallContacts(auxiliarCHC)
    setExtraPhonEmail(temporalArr)
  }

  const removeContact = () => {
    const auxArrContact = [...cityHallContacts]
    const auxArrEmailPhone = [...extraPhoneEmail]
    auxArrContact.pop()
    auxArrEmailPhone.pop()
    setCityHallContacts(auxArrContact)
    setExtraPhonEmail(auxArrEmailPhone)
    setCityHall(Object.assign({ ...cityHall }, { contact: auxArrContact }))
  }

  const saveCityHall = () => {
    if (!validateField()) return
    if (props.cityHallData) {
      cityHallService.update(cityHall).subscribe((res) => {
        enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
        props.handleRefreshTable && props.handleRefreshTable()
        props.handleClose()
      })
    } else {
      cityHallService.add(cityHall).subscribe((res) => {
        enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
        props.handleRefreshTable && props.handleRefreshTable()
        props.handleClose()
      })
    }
  }

  const handlerAddContact = (index: number) => {
    const temporalArr = [...extraPhoneEmail]
    temporalArr[index].push({ email: '', phone: '' })
    setExtraPhonEmail(temporalArr)
  }

  const handlerDeleteContact = (index: number, index2: number) => {
    const temporalArr = [...extraPhoneEmail]
    temporalArr[index] = []
    let phones = ''
    let emails = ''
    extraPhoneEmail[index].forEach((arrValue, indexValue) => {
      if (index2 !== indexValue) {
        temporalArr[index].push(arrValue)
        if (arrValue.email !== '') {
          emails = emails === '' ? arrValue.email : emails + ',' + arrValue.email
        }
        if (arrValue.phone !== '') {
          phones = phones === '' ? arrValue.phone : phones + ',' + arrValue.phone
        }
      }
    })
    setExtraPhonEmail(temporalArr)

    const auxiliarCHC = [...cityHallContacts]
    auxiliarCHC[index].phone = phones
    auxiliarCHC[index].email = emails
    setCityHallContacts(auxiliarCHC)
    setCityHall(Object.assign({ ...cityHall }, { contact: auxiliarCHC }))
  }

  const showTitle = (): string => {
    if (props.cityHallData) {
      if (props.id) {
        return t('seeEditCityHall')
      }
      return t('seeCityHall')
    }
    return t('addCityHall')
  }

  return (
    <Modal open={props.open} onClose={props.handleClose} className={styles.modalOverFlow}>
      <Box
        className={props.cityHallData && !props.id ? styles.containerSecondModal : styles.container}
      >
        <Box className={styles.contentHeader}>
          <img style={{ cursor: 'pointer' }} onClick={props.handleClose} src={icoDelete} />
        </Box>
        <Box>
          <Typography className={styles.headerTitle}>{showTitle()}</Typography>
          <Divider
            style={{
              marginRight: '15px',
              marginLeft: '15px',
              borderColor: '#f47105',
              marginBottom: '15px',
            }}
          />
        </Box>
        <FormControl className={styles.content}>
          <Box className={styles.field}>
            <Typography className={styles.fieldTitle}>{t('name')}</Typography>
            <TextField
              key="name"
              variant="outlined"
              name="name"
              value={cityHall.name}
              onChange={handleInput}
              inputProps={{ maxLength: 200 }}
              size={'small'}
              fullWidth
              required
              disabled={!!(props.cityHallData && !props.id)}
            />
          </Box>
          <Box className={styles.field}>
            <Typography className={styles.fieldTitle}>{t('municipality')}</Typography>
            <Select
              value={cityHall.city}
              name="city"
              size={'small'}
              required
              fullWidth
              disabled={!!(props.cityHallData && !props.id)}
              onChange={(e: SelectChangeEvent<string>) => handleSelect(e)}
              MenuProps={{
                MenuListProps: {
                  sx: {
                    'li.MuiButtonBase-root': {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      fontFamily: 'Poppins',
                      paddingLeft: '10px',
                    },
                  },
                },
              }}
              SelectDisplayProps={{
                style: {
                  paddingLeft: '10px',
                  fontFamily: 'Poppins',
                },
              }}
            >
              {municipalityArr.map((municipality) => {
                return (
                  <MenuItem key={municipality} value={municipality}>
                    {municipality}
                  </MenuItem>
                )
              })}
            </Select>
          </Box>
          {cityHallContacts.length !== 0 &&
            cityHallContacts.map((cityHallContact, index) => {
              return (
                <>
                  <Box className={styles.field}>
                    <Typography className={styles.fieldTitle}>{t('contact')}</Typography>
                    <TextField
                      key="contact"
                      variant="outlined"
                      name="name"
                      value={cityHallContact.name}
                      onChange={(e) => handlerInputArray(e, index)}
                      inputProps={{ maxLength: 200 }}
                      size={'small'}
                      fullWidth
                      required
                      disabled={!!(props.cityHallData && !props.id)}
                    />
                  </Box>
                  {extraPhoneEmail.length !== 0 &&
                    extraPhoneEmail[index].map((phoneMail, index2) => {
                      return (
                        <Box className={styles.containerRow} key={index2}>
                          <Box className={styles.fieldEmail}>
                            <Typography className={styles.fieldTitleEmail}>
                              {t('electronicMail')}
                            </Typography>
                            <TextField
                              key="email"
                              variant="outlined"
                              name="email"
                              value={phoneMail.email}
                              onChange={(e) => handlerInputPhoneEmail(e, index, index2)}
                              inputProps={{ maxLength: 200 }}
                              size={'small'}
                              fullWidth
                              required
                              disabled={!!(props.cityHallData && !props.id)}
                            />
                          </Box>
                          <Box className={styles.field}>
                            <Typography className={styles.fieldTitle}>{t('phone')}</Typography>
                            <TextField
                              key="phone"
                              variant="outlined"
                              name="phone"
                              value={phoneMail.phone}
                              onChange={(e) => handlerInputPhoneEmail(e, index, index2)}
                              inputProps={{ maxLength: 200 }}
                              size={'small'}
                              fullWidth
                              required
                              disabled={!!(props.cityHallData && !props.id)}
                            />
                          </Box>
                          {!(props.cityHallData && !props.id) && (
                            <Box>
                              {index2 === 0 ? (
                                <IconButton
                                  arial-label="addButton"
                                  size="large"
                                  onClick={() => handlerAddContact(index)}
                                >
                                  <img src={addButton} />
                                </IconButton>
                              ) : (
                                <IconButton
                                  arial-label="deleteButton"
                                  size="large"
                                  onClick={() => handlerDeleteContact(index, index2)}
                                >
                                  <img src={deleteButton} />
                                </IconButton>
                              )}
                            </Box>
                          )}
                        </Box>
                      )
                    })}
                </>
              )
            })}
        </FormControl>
        {errorMessage && (
          <Box mb={3}>
            <Alert severity="warning" key="errorMessage" id="errorMessage">
              {t(errorMessage)}
            </Alert>
          </Box>
        )}
        {!(props.cityHallData && !props.id) && (
          <>
            <Box className={styles.addButton}>
              <Box className={styles.deleteButton}>
                <AppButton
                  theme={ButtonTheme.RemoveCityHall}
                  type={'button'}
                  label={t('deleteContact')}
                  handler={removeContact}
                  disabled={cityHallContacts.length <= 1}
                />
              </Box>
              <AppButton
                theme={ButtonTheme.AddCityHall}
                startIcon={icoAdd}
                type={'button'}
                label={t('addContact')}
                handler={handleContact}
              />
            </Box>
            <Box m={3} display="flex" justifyContent="flex-end">
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('save')}
                handler={saveCityHall}
              />
            </Box>
          </>
        )}
      </Box>
    </Modal>
  )
}
