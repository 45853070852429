import { UserPending } from './UserPending'

export interface UserPendingDTO {
  email: string
  token: string
  roleId: string
  circleId: string
  date: Date
}

export function pendingToModel(d: UserPendingDTO): UserPending {
  return new UserPending(d)
}
