import React, { ChangeEvent, useState } from 'react'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import removeResouce from '../../../../../assets/newCircle/remove_resource_icon.svg'
import resourceIcon from '../../../../../assets/newCircle/resource_icon.svg'
import { FileDTO } from '../../../../../modules/files/models/FileDTO'

interface AddNewResourceProps {
  fileName: string
  setFileName: (fileName: string) => void
  directories: FileDTO[]
  setResourceDirectory: (directory: FileDTO) => void
  handleRemoveResource: () => void
}

const AddNewResource: React.FC<AddNewResourceProps> = ({
  fileName,
  setFileName,
  directories,
  setResourceDirectory,
  handleRemoveResource,
}) => {
  const [directory, setDirectory] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [arraySelectedDirectories, setArraySelectedDirectories] = useState<string[]>([''])

  const changeResourceName = (event: ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.value)
  }

  const changeDirectory = (event: SelectChangeEvent) => {
    setDirectory(event.target.value)
    setResourceDirectory(
      directories.find((directory) => directory.name === event.target.value) ?? directories[0]
    )
  }
  const { t } = useTranslation()
  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <TextField
            label={t('resourceName')}
            variant="outlined"
            size="small"
            onChange={changeResourceName}
            value={fileName}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={handleRemoveResource}>
            <img src={removeResouce} alt="Remove resource icon" />
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="center"
        borderBottom=" 3px solid #68b3e0"
        paddingBottom="1rem"
      >
        <Grid item xs={7}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-select-small-label">{t('selectDirectory')}</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={directory}
              label="Selecciona un directorio"
              onChange={changeDirectory}
            >
              {directories.map((directory) => (
                <MenuItem
                  disabled={arraySelectedDirectories.includes(directory.name)}
                  key={directory.id}
                  value={directory.name}
                >
                  {directory.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item marginTop="16px">
          <img src={resourceIcon} alt="Remove resource icon" />
        </Grid>
        <Grid item alignItems="center" marginTop="16px">
          <Typography>{fileName}</Typography>
        </Grid>
      </Grid>
    </>
  )
}
export default AddNewResource
