import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  CALENDAR_MODULE,
  CalendarProps,
  EVENT_API_KEY,
  EVENT_SERVICE_KEY,
} from '../modules/calendar/container'
import { EventService } from '../modules/calendar/services/EventService'
import { EventApi } from '../modules/calendar/api/EventApi'

let container: Container

function init(p: CalendarProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(EVENT_API_KEY, new ContainerItem({ instance: new EventApi() }))

  items.set(
    EVENT_SERVICE_KEY,
    new ContainerItem({ instance: new EventService({ apiKey: EVENT_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getCalendarContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[CALENDAR_MODULE],
      },
    })
  }

  return container
}
