import { UserCircle } from './UserCircle'
import { v4 as uuidv4 } from 'uuid'

export interface UserCircleDTO {
  id: string
  circleID: string
  userID: string
  familiarsID: string[]
  profSmsID: string[]
  profExtID: string[]
  careersID: string[]
}

export function emptyUserCircleDTO() {
  return {
    id: uuidv4(),
    circleID: '',
    userID: '',
    familiarsID: [],
    profSmsID: [],
    profExtID: [],
    careersID: [],
  }
}

export function fromModel(e: UserCircle): UserCircleDTO {
  return {
    id: e.id,
    circleID: e.circleID,
    userID: e.userID,
    familiarsID: e.familiarsID,
    profSmsID: e.profSmsID,
    profExtID: e.profExtID,
    careersID: e.careersID,
  }
}

export function toModel(d: UserCircleDTO): UserCircle {
  return new UserCircle(d)
}
