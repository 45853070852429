import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { PercentileApi } from '../api/PercentileApi'
import { Percentile, PercentileQuery } from '../models/Percentile'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface IPercentileService extends IInit {
  getFilteredItems(q: Query<PercentileQuery>): Observable<Percentile[]>
}

export class PercentileService implements IPercentileService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: PercentileApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<PercentileApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getFilteredItems(q: Query<PercentileQuery>): Observable<Percentile[]> {
    return this._api.getFilteredItems(q).pipe()
  }
}
