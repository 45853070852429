import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ARTICLES, ROUTE_ARTICLES_FORM } from '../../routes/routes-constants'
import { Article } from '../../modules/content/models/Article'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { Query, QueryParam } from '../../common/api/Query'
import { Actions, Pager } from '../../components/table_type/types'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { AppTable, Field } from '../../components/table'
import { useTranslation } from 'react-i18next'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../assets/table_icons/ico-eliminar.svg'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Permission } from '../../common/permission'
import { Modal } from '@mui/material'
import { CustomModal } from 'components/modal/CustomModal'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)

type ArticleProps = {
  tagIDList: string[]
}

export function Table(props: ArticleProps) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [articles, setArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [articlesPerPage, setArticlesPerPage] = useState<number>(10)
  const [pager, setPager] = useState<Pager>()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [currentArticle, setCurrentArticle] = useState<Article>()
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
  }, [props.tagIDList])

  useEffect(() => {
    if (!isLoading) {
      return
    }
    articlesService
      .getFilteredList(
        new Query({
          query:
            props.tagIDList.length > 0
              ? [new QueryParam<Article>('tagIDs', props.tagIDList)]
              : undefined,
          sort: [{ field: 'title' }],
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        const articleList = emptyList<Article>()
        articleList.items = res.items
        articleList.count = res.count
        setArticles(articleList)
        setCount(res.count)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: articlesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, articlesPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleSee = (article: Article) => {
    navigate(`${ROUTE_ARTICLES}/${article.id}`)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setArticlesPerPage(10)
      return
    }
    setArticlesPerPage(Number.parseInt(event.target.value))
  }

  const editArticle = (article: Article) => {
    navigate(`${ROUTE_ARTICLES_FORM}/${article.id}`)
  }

  const removeArticle = (article: Article) => {
    setCurrentArticle(article)
    setOpenDeleteModal(true)
  }

  const fields: Field<Article>[] = [
    {
      name: 'title',
      label: t('title'),
      renderFunc: (f, i) => (
        <p style={{ margin: '0px', cursor: 'pointer' }} onClick={() => handleSee(i)}>
          {i.title}
        </p>
      ),
    },
    {
      name: 'createdAt',
      label: t('creationDate'),
      renderFunc: (f, i) => new Date(i.createdAt).toLocaleDateString(),
    },
    {
      name: 'updatedAt',
      label: t('lastUpdate'),
      renderFunc: (f, i) => new Date(i.updatedAt).toLocaleDateString(),
    },
  ]

  const actions: Actions<Article> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: handleSee,
        icon: seeIcon,
        label: 'see',
      },
      {
        handler: editArticle,
        icon: editIcon,
        label: 'edit',
        hidden: () => !loggedUserService.userCan(Permission.createContent),
      },
      {
        handler: removeArticle,
        icon: deleteIcon,
        label: 'delete',
        hidden: () => !loggedUserService.userCan(Permission.createContent),
      },
    ],
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteArticle = () => {
    if (currentArticle?.id)
      articlesService.delete(currentArticle.id).subscribe((_) => setIsLoading(true))
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  return (
    <>
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <>
          <CustomModal
            handleClose={handleCloseDeleteModal}
            handleSave={handleDeleteArticle}
            title={t('deleteArticle')}
            warningText={t('irreversibleArticleAction')}
          />
        </>
      </Modal>
      <>
        <AppTable
          items={articles.items}
          rowKeyField="id"
          fields={fields}
          actions={actions}
          pager={pager}
        />
      </>
    </>
  )
}
