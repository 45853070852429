import { Container } from '../../common/container/Container'

export type QueryContainerConfig = {
  moduleFullUrl: string
}

export type ConfidencialityTermProps = {
  parentContainer: Container
  config: QueryContainerConfig
}

export const CONFIDENTIALITY_TERM_MODULE = Symbol('CONFIDENTIALITY_TERM_MODULE')
export const CONFIDENTIALITY_TERM_API_KEY = Symbol('CONFIDENTIALITY_TERM_API_KEY')
export const CONFIDENTIALITY_TERM_SERVICE_KEY = Symbol('CONFIDENTIALITY_TERM_SERVICE_KEY')
