import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { toModel } from '../models/UserTrainingGroupDTO'
import { UserTrainingGroup, UserTrainingGroupQuery } from '../models/UserTrainingGroup'
import { UserTrainingGroupDTO } from '../models/UserTrainingGroupDTO'
import { UserTrainingGroupContainerConfig } from '../container'

export interface IUserTrainingGroupApi extends IInit {
  getByUserCircleID(id: string): Observable<UserTrainingGroup | undefined>

  getByGroupID(id: string): Observable<UserTrainingGroup | undefined>

  getFilteredList(q: Query<UserTrainingGroupQuery>): Observable<ItemList<UserTrainingGroup>>

  add(e: UserTrainingGroupDTO): Observable<UserTrainingGroup | undefined>

  update(e: UserTrainingGroupDTO): Observable<UserTrainingGroup | undefined>

  deleteByUserCircleID(userCircleID: string): Observable<boolean>

  deleteByGroupID(groupID: string): Observable<boolean>
}

export class UserTrainingGroupApi implements IUserTrainingGroupApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url =
      (this._container.config as UserTrainingGroupContainerConfig).moduleFullUrl +
      '/userCircleGroup'
  }

  add(e: UserTrainingGroupDTO): Observable<UserTrainingGroup | undefined> {
    return this._httpClient.post<UserTrainingGroup>({ url: this._url, body: e }).pipe(
      map<UserTrainingGroupDTO, UserTrainingGroup>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  deleteByUserCircleID(userCirlceID: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/userID/' + userCirlceID }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  deleteByGroupID(groupID: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/groupID/' + groupID }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByUserCircleID(id: string): Observable<UserTrainingGroup | undefined> {
    return this._httpClient.get<UserTrainingGroup>({ url: `${this._url}/userCircleID/${id}` }).pipe(
      map<UserTrainingGroupDTO, UserTrainingGroup>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByGroupID(id: string): Observable<UserTrainingGroup | undefined> {
    return this._httpClient.get<UserTrainingGroup>({ url: `${this._url}/groupID/${id}` }).pipe(
      map<UserTrainingGroupDTO, UserTrainingGroup>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<UserTrainingGroupQuery>): Observable<ItemList<UserTrainingGroup>> {
    return this._httpClient
      .get<ItemList<UserTrainingGroup>>({ url: prepareURL(`${this._url}`, q) })
      .pipe(
        map<ItemList<UserTrainingGroupDTO>, ItemList<UserTrainingGroup>>((dto) => {
          const itemList = emptyList<UserTrainingGroup>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<UserTrainingGroup>())
        })
      )
  }

  update(e: UserTrainingGroupDTO): Observable<UserTrainingGroup | undefined> {
    return this._httpClient.put<UserTrainingGroup>({ url: this._url, body: e }).pipe(
      map<UserTrainingGroupDTO, UserTrainingGroup>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
