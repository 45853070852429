import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { TypeExternalProfessional } from '../models/TypeExternalProfessional'
import { TypeExternalProfessionalDTO } from '../models/TypeExternalProfessionalDTO'
import { ITypeExternalProfessionalApi } from '../api/TypeExternalProfessionalApi'

type Props = {
  apiKey: symbol
}

export interface ITypeExternalProfessionalService extends IInit {
  getByID(id: string): Observable<TypeExternalProfessional | undefined>

  getFilteredList(
    q: Query<TypeExternalProfessional>,
    userId: string
  ): Observable<ItemList<TypeExternalProfessional>>

  add(e: TypeExternalProfessional): Observable<TypeExternalProfessional | undefined>

  update(e: TypeExternalProfessionalDTO): Observable<TypeExternalProfessional | undefined>

  delete(id: string): Observable<boolean>
}

export class TypeExternalProfessionalService implements ITypeExternalProfessionalService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ITypeExternalProfessionalApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<ITypeExternalProfessionalApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: TypeExternalProfessionalDTO): Observable<TypeExternalProfessional | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<TypeExternalProfessional | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(
    q: Query<TypeExternalProfessional>
  ): Observable<ItemList<TypeExternalProfessional>> {
    return this._api.getFilteredList(q)
  }

  update(e: TypeExternalProfessionalDTO): Observable<TypeExternalProfessional | undefined> {
    return this._api.update(e)
  }
}
