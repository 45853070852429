import { Indicator } from './Indicator'
import { SeleneDTO } from './SeleneDTO'
import { toModel } from './IndicatorDTO'

export class Selene {
  private readonly _indicators: Indicator[]

  constructor(p: SeleneDTO) {
    this._indicators = p.indicators.map((i) => toModel(i))
  }

  get indicators(): Indicator[] {
    return this._indicators
  }
}
