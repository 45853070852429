import i18n from '../../../i18n'

export enum AfterDuration {
  never = -1,
  lessThanThirtyMin = 1,
  betweenThirtySixty,
  OverSixty,
}

export const afterDurationTypes = (): Record<AfterDuration, string> => ({
  [AfterDuration.never]: i18n.t('never'),
  [AfterDuration.lessThanThirtyMin]: i18n.t('lessThanThirtyMin'),
  [AfterDuration.betweenThirtySixty]: i18n.t('betweenThirtySixty'),
  [AfterDuration.OverSixty]: i18n.t('OverSixty'),
})
