import { createContext, useContext } from 'react'

export type GlobalContent = {
  render: boolean
  setRender: (r: boolean) => void
}

export const GlobalRenderHeaderContext = createContext<GlobalContent>({
  render: false, // set a default value
  setRender: () => {},
})

export const useGlobalRenderHeaderContext = () => useContext(GlobalRenderHeaderContext)
