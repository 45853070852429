import { RouteProps } from '../../routes/AppRouter'
import { PatientFormsTable as PFT } from '../../features/form-patients'
import React from 'react'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function PatientFormsTable(props: RouteProps) {
  const title = props.title ?? ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <PFT />
    </>
  )
}
