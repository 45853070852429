import React from 'react'
import {
  Step,
  StepLabel,
  Stepper,
  StepConnector,
  styled,
  Box,
  Stack,
  stepConnectorClasses,
} from '@mui/material'
import { StepIconProps } from '@mui/material/StepIcon'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  zIndex: 0,

  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( #f88c24 0%,#f88c24 50%,#f88c24 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( #f88c24 0%,#f88c24 50%,#f88c24 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#68b3e0',
    borderRadius: 1,
  },
}))

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean }
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ffffff',
  border: '3px solid #68b3e0',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    background: 'white',
    border: '3px solid #f88c24',
  }),
  ...(ownerState.completed && {
    background: 'white',
    border: '3px solid #f88c24',
  }),
}))

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

interface CustomStepperProps {
  steps: string[]
  activeStep: number
}

const CustomStepperEditActiveCircle: React.FC<CustomStepperProps> = ({ steps, activeStep }) => {
  return (
    <Box
      style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', marginBottom: '40px' }}
    >
      <Stack sx={{ width: '50%', transform: 'scale(1.8)' }} spacing={4}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <span style={{ whiteSpace: 'pre-line', fontSize: '12px' }}>{label}</span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Box>
  )
}

export default CustomStepperEditActiveCircle
