import { ActionButtons, Resource } from './Resource'
import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import style from '../AddNewCircle.module.css'
import { ExpandMore } from '@mui/icons-material'
export type Directory = {
  dataDirectory: Resource
  resources: Resource[]
}

const textStyles: React.CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap' as 'nowrap', // Asegurando el tipo correcto para whiteSpace
  flex: '2',
}
const TextCell: React.FC<{ content: string }> = ({ content }) => {
  return <Box style={textStyles}>{content}</Box>
}

export const ResourceRow: React.FC<{
  resource: Resource
  indexResource: number
  idDirectory: string
  handleDeleteResource: (
    resourceID: string,
    index?: number,
    idDirectory?: string | undefined
  ) => void
  handleEditResource: (id: string) => void
}> = ({ resource, indexResource, idDirectory, handleDeleteResource, handleEditResource }) => (
  <TableRow sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 2%' }}>
    <TableCell sx={{ ...textStyles, paddingLeft: '24px' }}>
      <p className={style.rowDirectoryLabel}>{resource.title}</p>
    </TableCell>
    <TableCell sx={textStyles}>
      <p className={style.rowDirectoryLabel}>{resource.author}</p>
    </TableCell>
    <TableCell sx={textStyles}>
      {/* <p className={style.rowDirectoryLabel}>{resource.assigned}</p> */}
      <p> </p>
    </TableCell>
    <TableCell sx={{ flex: 1 }}>
      <ActionButtons
        handleDeleteResource={() => {
          handleDeleteResource(resource.id, indexResource, idDirectory)
        }}
        handleEditResource={() => handleEditResource(resource.id)}
      />
    </TableCell>
  </TableRow>
)

export const DirectoryResource: React.FC<{
  directory: Directory
  handleDeleteResource: (resourceID: string, directoryID: string, index?: number) => void
  handleEditResource: (id: string) => void
  handleDeleteDirectory: () => void
  handleEditDirectory: () => void
}> = ({
  directory,
  handleDeleteDirectory,
  handleDeleteResource,
  handleEditResource,
  handleEditDirectory,
}) => {
  const { id, title, author, assigned } = directory.dataDirectory
  return (
    <Accordion
      style={{
        marginBottom: '2rem',
        border: '1px solid rgba(219, 218, 218, 0.80)',
        boxShadow: 'none',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          '&': {
            flexDirection: 'row-reverse',
            width: '100%',
            paddingLeft: '4%',
            paddingRight: '4%',
            paddingBottom: '1rem',
            paddingTop: '1rem',
            margin: '0',
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <TextCell content={title} />
          <TextCell content={author} />
          <TextCell content={assigned} />
          <ActionButtons
            handleDeleteResource={handleDeleteDirectory}
            handleEditResource={handleEditDirectory}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableBody>
            {directory.resources.map((resource, index) => (
              <ResourceRow
                idDirectory={id}
                indexResource={index}
                handleDeleteResource={() =>
                  handleDeleteResource(resource.id, directory.dataDirectory.id, index)
                }
                key={resource.title}
                resource={resource}
                handleEditResource={handleEditResource}
              />
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
