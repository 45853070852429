import { Avatar, Box, Divider, Typography } from '@mui/material'
import style from './NavigationEsfera.module.css'
import { Link } from 'react-router-dom'
import { LinkType } from './NavigationEsfera'
import { useTranslation } from 'react-i18next'
import profile from '../../assets/esfera/nav-profile-icons/config-profile-icon.svg'
import profileActive from '../../assets/esfera/nav-profile-icons/config-profile-icon-active.svg'
import configIcon from '../../assets/esfera/nav-profile-icons/ico-config.svg'
import configIconActive from '../../assets/esfera/nav-profile-icons/ico-config-active.svg'
import exitIcon from '../../assets/esfera/nav-profile-icons/exit-icon.svg'
import React from 'react'
import { ROUTE_CONFIGURATION, ROUTE_USER_PROFILE } from '../../routes/routes-constants'
import { SubMenu } from './SubMenu'
import { getLinkStyle, getListItemStyle } from './stylesFunctions'
import { UserCircleWithCircleAndUser } from '../../modules/user-circle/models/UserCircleWithCircleAndUser'
import { useGetProfilePhoto } from '../../hooks/profilePhoto/useGetProfilePhoto'

type ProfileSubMenuProps = {
  login: string
  username: string
  gender: number
  dateOfBirth: string
  dni: string
  cip: string
  profileOpen: boolean
  setProfileOpen: (open: boolean) => void
  selectedMenu: string
  setSelectedMenu: (menuSelected: string) => void
  userCircle: UserCircleWithCircleAndUser | undefined
  links: LinkType[]
  handlerLogout: (_: React.MouseEvent<Element, MouseEvent>) => void
  navigate: (route: string) => void
  anchorEl: HTMLElement | null
}

const linksProfile: LinkType[] = [
  {
    route: ROUTE_USER_PROFILE,
    title: 'profileConfiguration',
    activeIcon: profileActive,
    icon: profile,
    altSrc: 'profileIcon',
    hoverIcon: profile,
    isVisible: true,
    additionalRoutes: [],
    subMenu: false,
  },
  {
    route: ROUTE_CONFIGURATION,
    title: 'configuration',
    activeIcon: configIconActive,
    icon: configIcon,
    altSrc: 'configurationIcon',
    hoverIcon: configIconActive,
    isVisible: true,
    additionalRoutes: [],
    subMenu: false,
  },
]

export const ProfileSubMenu = ({
  profileOpen,
  setProfileOpen,
  anchorEl,
  userCircle,
  selectedMenu,
  setSelectedMenu,
  links,
  username,
  login,
  gender,
  dni,
  dateOfBirth,
  cip,
  handlerLogout,
  navigate,
}: ProfileSubMenuProps) => {
  const { t } = useTranslation()
  const { profilePhotoData } = useGetProfilePhoto()

  return (
    <SubMenu
      style={style.profileSubMenu}
      anchorEl={anchorEl}
      open={profileOpen}
      setOpen={setProfileOpen}
    >
      <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
          {profilePhotoData !== '' ? (
            <img
              style={{
                height: '110px',
                width: '110px',
                background: '#FFF',
                borderRadius: 100,
              }}
              src={`data:image/jpeg;base64,${profilePhotoData}`}
              alt={'selectedProfilePhoto'}
            />
          ) : (
            <Avatar style={{ width: '94px', height: '94px' }} />
          )}
          <Box className={style.containerInfoUser}>
            <h6 className={style.userName}>{username}</h6>
            <Typography className={style.userInfo}>{dateOfBirth}</Typography>
            <Typography className={style.userInfo}>DNI: {dni}</Typography>
            <Typography className={style.userInfo}>CIP: {cip}</Typography>
          </Box>
          <Divider orientation="vertical" flexItem className={style.dividerUserProfile} />
        </Box>
        <Box>
          {linksProfile
            .filter((l) => l.isVisible)
            .map((l) => (
              <Link
                onClick={() => {
                  navigate(l.route)
                  setSelectedMenu(l.route)
                }}
                key={l.title}
                to={l.route}
                className={getLinkStyle(
                  selectedMenu,
                  style.circleSubMenuActive,
                  ...l.additionalRoutes,
                  l.route
                )}
                id={l.route}
              >
                <div className={style.menuDiv}>
                  <Box
                    key={l.title}
                    className={getListItemStyle(
                      false,
                      selectedMenu || '',
                      style.menuItemProfileActive,
                      style.menuItemProfile,
                      ...(l?.additionalRoutes || ''),
                      l?.route || ''
                    )}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box style={{ margin: 8, minWidth: 40 }}>
                        <img src={l.icon} alt={t(l.altSrc)} />
                      </Box>
                      <Box style={{ marginTop: 8 }}>
                        <h3 className={style.titleNameSubMenu}>{t(l.title)}</h3>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Link>
            ))}
          <div className={style.menuDiv}>
            <Box
              className={getListItemStyle(
                false,
                selectedMenu || '',
                style.menuItemProfileActive,
                style.menuItemProfile
              )}
              onClick={handlerLogout}
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <Box style={{ margin: 8, minWidth: 40 }}>
                <img src={exitIcon} alt={'exitIcon'} />
              </Box>
              <Box style={{ marginTop: 8 }}>
                <h3 className={style.titleNameSubMenu}>{t('exit')}</h3>
              </Box>
            </Box>
          </div>
        </Box>{' '}
      </Box>
    </SubMenu>
  )
}
