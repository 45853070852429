import React from 'react'
import { Calendar as C } from '../../features/calendar'
import { RouteProps } from '../../routes/AppRouter'
import { useLocation, useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

type CalendarProps = {
  selectedDate?: Date
} & RouteProps

export function Calendar(props: CalendarProps) {
  const { id } = useParams()
  const location = useLocation()
  const title = props.title || ''
  const state: CalendarProps = location?.state as CalendarProps

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <C selectedDate={state?.selectedDate} />
    </>
  )
}
