import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { DataPatientContainerConfig } from '../container'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { Percentile, PercentileQuery } from '../models/Percentile'
import { PercentileDTO, toModel } from '../models/PercentileDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface IPercentileApi extends IInit {
  getFilteredItems(q: Query<PercentileQuery>): Observable<Percentile[]>
}

export class PercentileApi implements IPercentileApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url =
      (this._container.config as DataPatientContainerConfig).moduleFullUrl + '/percentiles'
  }

  getFilteredItems(q: Query<PercentileQuery>): Observable<Percentile[]> {
    return this._httpClient.get<Percentile[]>({ url: prepareURL(this._url, q) }).pipe(
      map<PercentileDTO[], Percentile[]>((dto) => dto.map((d) => toModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }
}
