import slightBleeding from '../../assets/calendar/blood_plus_icon.svg'
import moderateBleeding from '../../assets/calendar/blood_plus_plus_icon.svg'
import hemorrhage from '../../assets/calendar/blood_plus_plus_plus_icon.svg'
import green_pain_icon from '../../assets/calendar/green_pain_icon.svg'
import yellow_pain_icon from '../../assets/calendar/yellow_pain_icon.svg'
import red_pain_icon from '../../assets/calendar/red_pain_icon.svg'
import blue_pain_icon from '../../assets/calendar/blue_pain_icon.svg'
import orange_pain_icon from '../../assets/calendar/orange_pain_icon.svg'
import darkblue_paint_icon from '../../assets/calendar/darkblue_pain_icon.svg'
import Poo from '../../assets/calendar/Poo2.svg'
import epilepticalCrisis from '../../assets/calendar/epileptic_crisis.svg'

export enum SpecificSymptomIconsEnum {
  slightBleeding = 'lightBleeding',
  moderateBleeding = 'moderateBleeding',
  hemorrhage = 'hemorrhage',
  green_pain_icon = 'withoutPain',
  blue_pain_icon = 'mildPain',
  darkblue_paint_icon = 'moderatePain',
  yellow_pain_icon = 'severePain',
  orange_pain_icon = 'verySeverePain',
  red_pain_icon = 'maximumPain',
  poo = 'Poo',
  epilepticalCrisis = 'epileptic',
}

const specifigSymptomFromString = new Map<string, SpecificSymptomIconsEnum>([
  ['lightBleeding', SpecificSymptomIconsEnum.slightBleeding],
  ['moderateBleeding', SpecificSymptomIconsEnum.moderateBleeding],
  ['hemorrhage', SpecificSymptomIconsEnum.hemorrhage],
  ['withoutPain', SpecificSymptomIconsEnum.green_pain_icon],
  ['mildPain', SpecificSymptomIconsEnum.blue_pain_icon],
  ['moderatePain', SpecificSymptomIconsEnum.darkblue_paint_icon],
  ['severePain', SpecificSymptomIconsEnum.yellow_pain_icon],
  ['verySeverePain', SpecificSymptomIconsEnum.orange_pain_icon],
  ['maximumPain', SpecificSymptomIconsEnum.red_pain_icon],
  ['Poo', SpecificSymptomIconsEnum.poo],
  ['epileptic', SpecificSymptomIconsEnum.epilepticalCrisis],
])

const idSymptomToIcon = new Map<SpecificSymptomIconsEnum, string>([
  [SpecificSymptomIconsEnum.slightBleeding, slightBleeding],
  [SpecificSymptomIconsEnum.moderateBleeding, moderateBleeding],
  [SpecificSymptomIconsEnum.hemorrhage, hemorrhage],
  [SpecificSymptomIconsEnum.green_pain_icon, green_pain_icon],
  [SpecificSymptomIconsEnum.blue_pain_icon, blue_pain_icon],
  [SpecificSymptomIconsEnum.darkblue_paint_icon, darkblue_paint_icon],
  [SpecificSymptomIconsEnum.yellow_pain_icon, yellow_pain_icon],
  [SpecificSymptomIconsEnum.orange_pain_icon, orange_pain_icon],
  [SpecificSymptomIconsEnum.red_pain_icon, red_pain_icon],
  [SpecificSymptomIconsEnum.poo, Poo],
  [SpecificSymptomIconsEnum.epilepticalCrisis, epilepticalCrisis],
])

export const getIconFromSymptomIDIcon = (idIcon: string): string => {
  const icon = specifigSymptomFromString.get(idIcon)
  if (icon) {
    return idSymptomToIcon.get(icon) ?? ''
  }
  return ''
}
