import style from '../../Login.module.css'
import React, { ChangeEvent } from 'react'
import { Box, Checkbox, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { styled } from '@mui/system'

type CirpaFormProps = {
  dni: string
  email: string
  password: string
  inPass: boolean
  passwordConfirm: string
  handleLegal: () => void
  registerButtonDisabled: boolean
  legal: boolean
  handleChange: () => void
  handlerRegister: (e: React.FormEvent<HTMLFormElement>) => void
  handleEmail: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  handleDni: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  handlePassword: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  handlePasswordConfirm: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  handleInPass: (value: boolean) => void
}

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 2, 2, 2),
}))
export function CirpaForm(props: CirpaFormProps) {
  return (
    <form className={style.loginFields} noValidate onSubmit={props.handlerRegister}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="dni"
        label="DNI"
        name="DNI"
        value={props.dni}
        onChange={(e) => props.handleDni(e)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Correo Electronico"
        name="email"
        value={props.email}
        onChange={(e) => props.handleEmail(e)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        type="password"
        required
        fullWidth
        id="password"
        label="Contraseña"
        name="password"
        value={props.password}
        onChange={(e) => props.handlePassword(e)}
        onFocus={(e) => props.handleInPass(true)}
        onBlur={(e) => props.handleInPass(false)}
        helperText={
          props.inPass &&
          'La contraseña debe contener al menos una minúscula, una mayúscula y mínimo 8 caracteres'
        }
      />
      <TextField
        variant="outlined"
        margin="normal"
        type="password"
        required
        fullWidth
        id="passwordConfirm"
        label="Confirmar contraseña"
        name="passwordConfirm"
        value={props.passwordConfirm}
        onChange={(e) => props.handlePasswordConfirm(e)}
      />
      <b>
        <b onClick={props.handleLegal} style={{ cursor: 'pointer' }}>
          He leído y acepto los términos y condiciones de uso
        </b>
        <Checkbox key={'legal'} name={'legal'} checked={props.legal} onClick={props.handleChange} />
      </b>
      <Box display={'flex'} flexDirection={'column'}>
        <SubmitButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={props.registerButtonDisabled}
        >
          <p style={{ fontWeight: 'bolder', color: 'white' }}>Registrar</p>
        </SubmitButton>
      </Box>
    </form>
  )
}
