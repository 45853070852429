import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ExternalProfessionalPending } from '../models/ExternalProfessionalPending'
import { ExternalProfessionalPendingDTO } from '../models/ExternalProfessionalPendingDTO'
import { IExternalProfessionalPendingApi } from '../api/ExternalProfessionalPendingApi'

type Props = {
  apiKey: symbol
}

export interface IExternalProfessionalPendingService extends IInit {
  getByID(id: string): Observable<ExternalProfessionalPending | undefined>

  getFilteredList(
    q: Query<ExternalProfessionalPending>,
    userId: string
  ): Observable<ItemList<ExternalProfessionalPending>>

  add(e: ExternalProfessionalPending): Observable<ExternalProfessionalPending | undefined>

  update(e: ExternalProfessionalPendingDTO): Observable<ExternalProfessionalPending | undefined>

  delete(id: string): Observable<boolean>
}

export class ExternalProfessionalPendingService implements IExternalProfessionalPendingService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IExternalProfessionalPendingApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IExternalProfessionalPendingApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: ExternalProfessionalPendingDTO): Observable<ExternalProfessionalPending | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<ExternalProfessionalPending | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(
    q: Query<ExternalProfessionalPending>
  ): Observable<ItemList<ExternalProfessionalPending>> {
    return this._api.getFilteredList(q)
  }

  update(e: ExternalProfessionalPendingDTO): Observable<ExternalProfessionalPending | undefined> {
    return this._api.update(e)
  }
}
