import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material'
import styles from './ModalNewCrisis.module.css'
import icoDelete from '../../../../assets/resource_icons/ico-eliminar.svg'
import React, { Dispatch, FormEvent, SetStateAction } from 'react'
import { TextFieldItem } from '../../../../components/form-card/TextFieldItem'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import { UserEpilepticalConfigDTO } from '../../../../modules/epileptical/models/UserEpilepticalConfigDTO'
import allBody from '../../../../assets/neuropediatria_icon/01-cuerpo-todo.svg'
import rightBody from '../../../../assets/neuropediatria_icon/03-cuerpo-derecha.svg'
import leftBody from '../../../../assets/neuropediatria_icon/02-cuerpo-izquierda.svg'
import extremityBody from '../../../../assets/neuropediatria_icon/04-cuerpo-extremidad.svg'
import headBody from '../../../../assets/neuropediatria_icon/05-cuerpo-cabeza.svg'
import undefinedBody from '../../../../assets/neuropediatria_icon/06-cuerpo-sindefinir.svg'
import eyesUpIcon from '../../../../assets/neuropediatria_icon/ojo1.svg'
import eyesCloseIcon from '../../../../assets/neuropediatria_icon/ojo2.svg'
import eyesRightIcon from '../../../../assets/neuropediatria_icon/ojo3.svg'
import eyesLeftIcon from '../../../../assets/neuropediatria_icon/ojo4.svg'
import eyesStareIcon from '../../../../assets/neuropediatria_icon/ojo6.svg'
import eyesBlinkIcon from '../../../../assets/neuropediatria_icon/ojo5.svg'
import { Alert } from '@mui/material'
import { durationTypes } from '../../../../modules/epileptical/enums/Duration'
import { crisisTypeTypes } from '../../../../modules/epileptical/enums/CrisisType'
import { ItemList } from '../../../../common/models/ItemList'
import { UserEpilepticalConfig } from '../../../../modules/epileptical/models/UserEpilepticalConfig'
import { awarenessTypes } from '../../../../modules/epileptical/enums/Awareness'
import { movementTypes } from '../../../../modules/epileptical/enums/Movement'
import { zoneTypes } from '../../../../modules/epileptical/enums/Zone'
import { visionTypes } from '../../../../modules/epileptical/enums/Vision'
import { skinColorTypes } from '../../../../modules/epileptical/enums/SkinColor'
import { symptomTypes } from '../../../../modules/epileptical/enums/Symptom'
import { mouthTypes } from '../../../../modules/epileptical/enums/Mouth'
import { afterDurationTypes } from '../../../../modules/epileptical/enums/AfterDuration'

export type ModalNewCrisisProps = {
  readonly open: boolean
  readonly handleClose: () => void
  readonly innerWidth: number
  color: string | undefined
  durationNewCrisisType: string
  commentNewCrisisType: string
  selectedColor: string[]
  crisisZone: string
  intensityRadioButton: string | undefined
  newType: UserEpilepticalConfigDTO
  index: number
  handleTypeCrisis: (value: string, index: number) => void
  handleIntensityCrisisType: (value: string) => void
  handleCommentNewCrisisType: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleDurationNewCrisisType: (value: string) => void
  handleCrisisZone: (value: string) => void
  handlerMovement: (value: string) => void
  handlerVision: (value: string) => void
  handlerSkinColor: (value: string) => void
  handlerSymptom: (value: string) => void
  handlerMouth: (value: string) => void
  handlerAfterDuration: (value: string) => void
  handlerAfterSymptom: (index: number) => void
  handlerAwareness: (value: string) => void
  otherSymptoms: string[]
  checkedState: any[]
  saveNewCrisisType: (e: FormEvent<HTMLFormElement>) => void
  handleColorTypeCrisis: () => void
  errorMessage: string
  handleEdit: (color: string) => void
  epilepticConfig: ItemList<UserEpilepticalConfig>
  colorSelected: string
  setColorSelected: Dispatch<SetStateAction<string>>
}

export function ModalEditCrisis(props: ModalNewCrisisProps) {
  const { t } = useTranslation()

  return (
    <Modal open={props.open} onClose={props.handleClose} className={styles.modalOverFlow}>
      <Box className={styles.container}>
        <Box className={styles.contentHeader}>
          <h1 className={styles.titleNewCrisis}>Editar tipo de crisis</h1>
          <img style={{ cursor: 'pointer' }} onClick={props.handleClose} src={icoDelete} />
        </Box>
        <Box
          className={
            props.innerWidth > 598 ? styles.containerRadioButton : styles.containerRadioButtonMobile
          }
        >
          <Box style={{ margin: '10px' }}>
            <h2 style={{ marginTop: '10px' }}>Intensidad de la crisis</h2>
            <RadioGroup
              style={{ marginRight: '150px', marginLeft: '15px' }}
              aria-label="gender"
              name="gender1"
              value={crisisTypeTypes()[props.newType.crisisType] ?? ' '}
              onChange={(event) => {
                props.handleIntensityCrisisType(event.target.value)
              }}
            >
              <FormControlLabel
                sx={{ fontFamily: 'Poppins, sans-serif' }}
                value={t(crisisTypeTypes()[props.newType.crisisType] ?? ' ')}
                control={<Radio />}
                label={t(crisisTypeTypes()[props.newType.crisisType] ?? ' ')}
              />
            </RadioGroup>
          </Box>
          <Box style={{ margin: '10px' }}>
            <h2 style={{ marginTop: '10px' }}>Seleccione color</h2>
            <RadioGroup
              style={{ marginRight: '150px', marginLeft: '15px' }}
              aria-label="gender"
              name="gender1"
              value={props.newType.color ?? ' '}
              onChange={(event) => {
                props.handleTypeCrisis(event.target.value, props.index)
              }}
            >
              <FormControlLabel
                sx={{ fontFamily: 'Poppins' }}
                value={props.newType.color ?? ' '}
                control={<Radio />}
                label={
                  <Box
                    style={{
                      backgroundColor: props.newType.color ?? ' ',
                    }}
                    className={styles.boxLevel}
                  />
                }
              />
            </RadioGroup>
          </Box>
        </Box>
        <Box style={{ margin: '10px' }}>
          <h2>Antes de la crisis:</h2>
          <h3>¿El niño/niña ha notado algo?</h3>
          <Box mb={3}>
            <TextFieldItem
              field="comments"
              value={props.newType.comment ?? ' '}
              label={''}
              type={'text'}
              handleChange={(e) => {
                props.handleCommentNewCrisisType(e)
              }}
              rows={2}
              required={false}
            />
          </Box>
        </Box>
        <Divider light style={{ backgroundColor: '#045383', margin: 8 }} />
        <h2 style={{ marginLeft: '10px' }}>Durante la crisis:</h2>
        <Box
          className={
            props.innerWidth > 598 ? styles.containerRadioButton : styles.containerRadioButtonMobile
          }
        >
          <Box style={{ margin: '10px', width: '300px' }}>
            <h3 style={{ marginTop: '10px' }}>Duración de la crisis</h3>
            <RadioGroup
              style={{
                marginRight: props.innerWidth < 598 ? '' : '92px',
                marginLeft: '15px',
              }}
              aria-label="gender"
              name="gender1"
              value={durationTypes()[props.newType.duration] ?? ' '}
              onChange={(event) => {
                props.handleDurationNewCrisisType(event.target.value)
              }}
            >
              <FormControlLabel
                sx={{ fontFamily: 'Poppins' }}
                value={t('oneMin')}
                control={<Radio />}
                label={t('oneMin')}
              />
              <FormControlLabel
                sx={{ fontFamily: 'Poppins' }}
                value={t('oneToFiveMin')}
                control={<Radio />}
                label={t('oneToFiveMin')}
              />
              <FormControlLabel value={t('FiveMin')} control={<Radio />} label={t('FiveMin')} />
            </RadioGroup>
          </Box>
          <Box
            style={{
              margin: '10px',
              width: '300px',
              marginRight: '120px',
              marginLeft: window.innerWidth > 490 ? '60px' : '10px',
            }}
          >
            <FormControl fullWidth>
              <h3 style={{ marginTop: '10px' }}>Consciencia</h3>
              <Select
                defaultValue={''}
                style={{ fontFamily: 'Poppins, sans-serif' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={awarenessTypes()[props.newType.awareness] ?? ' '}
                label="awareness"
                onChange={(e) => {
                  props.handlerAwareness(e.target.value)
                }}
              >
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('fully')}>
                  {t('fully')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('confused')}>
                  {t('confused')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('respondsVoice')}>
                  {t('respondsVoice')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('unconscious')}>
                  {t('unconscious')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('undefined')}>
                  {t('undefined')}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            style={{
              margin: '10px',
              width: '300px',
              marginRight: '70px',
              marginLeft: window.innerWidth > 490 ? '60px' : '10px',
            }}
          >
            <FormControl fullWidth>
              <h3 style={{ marginTop: '10px' }}>Movimiento</h3>
              <Select
                defaultValue={''}
                style={{ fontFamily: 'Poppins, sans-serif' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={movementTypes()[props.newType.movement] ?? ' '}
                label="movement"
                onChange={(e) => {
                  props.handlerMovement(e.target.value)
                }}
              >
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('jolting')}>
                  {t('jolting')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('rigidity')}>
                  {t('rigidity')}
                </MenuItem>
                <MenuItem
                  style={{ fontFamily: 'Poppins, sans-serif' }}
                  value={t('rigidityJolting')}
                >
                  {t('rigidityJolting')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('automatism')}>
                  {t('automatism')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('hypotonia')}>
                  {t('hypotonia')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('undefined')}>
                  {t('undefined')}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          className={
            props.innerWidth > 598 ? styles.containerRadioButton : styles.containerRadioButtonMobile
          }
        >
          <Box style={{ margin: '10px' }}>
            <h3 style={{ marginTop: '10px' }}>Zona corporal:</h3>
            <FormControl component="fieldset">
              <RadioGroup
                row={window.innerWidth > 490}
                style={{
                  marginRight: props.innerWidth < 598 ? '' : '92px',
                  justifyContent: window.innerWidth > 490 ? '' : 'center',
                  alignItems: window.innerWidth > 490 ? '' : 'flex-start',
                }}
                aria-label="gender"
                name="gender1"
                value={zoneTypes()[props.newType.zone] ?? ' '}
                onChange={(event) => props.handleCrisisZone(event.target.value)}
              >
                <Box>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    sx={{ fontFamily: 'Poppins' }}
                    value={t('allBody')}
                    control={<Radio />}
                    label={t('allBody')}
                  />
                  <Box
                    style={{
                      width: window.innerWidth > 490 ? '30px' : '10px',
                      display: 'flex',
                      marginLeft: 95,
                    }}
                  >
                    <img
                      src={allBody}
                      width={window.innerWidth > 490 ? 30 : 10}
                      height={window.innerWidth > 490 ? 30 : 10}
                    />
                  </Box>
                </Box>

                <Box>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    sx={{ fontFamily: 'Poppins' }}
                    value={t('right')}
                    control={<Radio />}
                    label={t('right')}
                  />
                  <Box style={{ width: '30px', display: 'flex', marginLeft: 95 }}>
                    <img src={rightBody} width={30} height={30} />
                  </Box>
                </Box>
                <Box>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    sx={{ fontFamily: 'Poppins' }}
                    value={t('left')}
                    control={<Radio />}
                    label={t('left')}
                  />
                  <Box style={{ width: '30px', display: 'flex', marginLeft: 95 }}>
                    <img src={leftBody} width={30} height={30} />
                  </Box>
                </Box>
                <Box>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    sx={{ fontFamily: 'Poppins' }}
                    value={t('extremity')}
                    control={<Radio />}
                    label={t('extremity')}
                  />
                  <Box style={{ width: '30px', display: 'flex', marginLeft: 95 }}>
                    <img src={extremityBody} width={30} height={30} />
                  </Box>
                </Box>
                <Box>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    sx={{ fontFamily: 'Poppins' }}
                    value={t('head')}
                    control={<Radio />}
                    label={t('head')}
                  />
                  <Box style={{ width: '30px', display: 'flex', marginLeft: 75 }}>
                    <img src={headBody} width={30} height={30} />
                  </Box>
                </Box>
                <Box>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    sx={{ fontFamily: 'Poppins' }}
                    value={t('undefined')}
                    control={<Radio />}
                    label={t('undefined')}
                  />
                  <Box style={{ width: '30px', display: 'flex', marginLeft: 75 }}>
                    <img src={undefinedBody} width={30} height={30} />
                  </Box>
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box
          className={
            props.innerWidth > 598 ? styles.containerRadioButton : styles.containerRadioButtonMobile
          }
        >
          <Box style={{ margin: '10px' }}>
            <h3 style={{ marginTop: '10px' }}>Vista</h3>
            <RadioGroup
              row={window.innerWidth > 490}
              style={{
                marginRight: props.innerWidth < 598 ? '' : '92px',
                justifyContent: window.innerWidth > 490 ? '' : 'center',
                alignItems: window.innerWidth > 490 ? '' : 'flex-start',
              }}
              aria-label="gender"
              name="gender1"
              value={visionTypes()[props.newType.vision] ?? ' '}
              onChange={(e) => {
                props.handlerVision(e.target.value)
              }}
            >
              <Box>
                <FormControlLabel
                  style={{ marginLeft: window.innerWidth > 490 ? 10 : 0 }}
                  sx={{ fontFamily: 'Poppins' }}
                  value={t('eyesUp')}
                  control={<Radio />}
                  label={t('eyesUp')}
                />
                <Box
                  style={{
                    width: '30px',
                    display: 'flex',
                    marginLeft: window.innerWidth > 490 ? 45 : 30,
                  }}
                >
                  <img src={eyesUpIcon} width={30} height={30} />
                </Box>
              </Box>
              <Box>
                <FormControlLabel
                  style={{ marginLeft: window.innerWidth > 490 ? 10 : 0 }}
                  sx={{ fontFamily: 'Poppins' }}
                  value={t('eyesClose')}
                  control={<Radio />}
                  label={t('eyesClose')}
                />
                <Box
                  style={{
                    width: '30px',
                    display: 'flex',
                    marginLeft: window.innerWidth > 490 ? 45 : 30,
                  }}
                >
                  <img src={eyesCloseIcon} width={30} height={30} />
                </Box>
              </Box>
              <Box>
                <FormControlLabel
                  style={{ marginLeft: window.innerWidth > 490 ? 10 : 0 }}
                  sx={{ fontFamily: 'Poppins' }}
                  value={t('eyesRight')}
                  control={<Radio />}
                  label={t('eyesRight')}
                />
                <Box
                  style={{
                    width: '30px',
                    display: 'flex',
                    marginLeft: window.innerWidth > 490 ? 45 : 30,
                  }}
                >
                  <img src={eyesRightIcon} width={30} height={30} />
                </Box>
              </Box>
              <Box>
                <FormControlLabel
                  style={{ marginLeft: window.innerWidth > 490 ? 10 : 0 }}
                  sx={{ fontFamily: 'Poppins' }}
                  value={t('eyesLeft')}
                  control={<Radio />}
                  label={t('eyesLeft')}
                />
                <Box
                  style={{
                    width: '30px',
                    display: 'flex',
                    marginLeft: window.innerWidth > 490 ? 45 : 30,
                  }}
                >
                  <img src={eyesLeftIcon} width={30} height={30} />
                </Box>
              </Box>
              <Box>
                <FormControlLabel
                  style={{ marginLeft: window.innerWidth > 490 ? 10 : 0 }}
                  sx={{ fontFamily: 'Poppins' }}
                  value={t('stare')}
                  control={<Radio />}
                  label={t('stare')}
                />
                <Box
                  style={{
                    width: '30px',
                    display: 'flex',
                    marginLeft: window.innerWidth > 490 ? 45 : 30,
                  }}
                >
                  <img src={eyesStareIcon} width={30} height={30} />
                </Box>
              </Box>
              <Box>
                <FormControlLabel
                  style={{ marginLeft: window.innerWidth > 490 ? 10 : 0 }}
                  sx={{ fontFamily: 'Poppins' }}
                  value={t('blink')}
                  control={<Radio />}
                  label={t('blink')}
                />
                <Box
                  style={{
                    width: '30px',
                    display: 'flex',
                    marginLeft: window.innerWidth > 490 ? 45 : 30,
                  }}
                >
                  <img src={eyesBlinkIcon} width={30} height={30} />
                </Box>
              </Box>
              <Box>
                <FormControlLabel
                  style={{ marginLeft: window.innerWidth > 490 ? 64 : 0 }}
                  sx={{ fontFamily: 'Poppins' }}
                  value={t('undefined')}
                  control={<Radio />}
                  label={t('undefined')}
                />
              </Box>
            </RadioGroup>
          </Box>
        </Box>
        <Box
          className={
            props.innerWidth > 598 ? styles.containerRadioButton : styles.containerRadioButtonMobile
          }
        >
          <Box style={{ margin: '10px', marginRight: '70px', width: '300px' }}>
            <FormControl fullWidth>
              <h3 style={{ marginTop: '10px' }}>Color de piel</h3>
              <Select
                defaultValue={''}
                style={{ fontFamily: 'Poppins, sans-serif' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={skinColorTypes()[props.newType.skinColor] ?? ' '}
                label="skinColor"
                onChange={(e) => {
                  props.handlerSkinColor(e.target.value)
                }}
              >
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('blue')}>
                  {t('blue')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('pale')}>
                  {t('pale')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('noChanges')}>
                  {t('noChanges')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('undefined')}>
                  {t('undefined')}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box style={{ margin: '10px', marginRight: '70px', width: '300px' }}>
            <FormControl fullWidth>
              <h3 style={{ marginTop: '10px' }}>Síntomas asociados</h3>
              <Select
                defaultValue={''}
                style={{ fontFamily: 'Poppins, sans-serif' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={symptomTypes()[props.newType.symptom] ?? ' '}
                label="symptom"
                onChange={(e) => {
                  props.handlerSymptom(e.target.value)
                }}
              >
                <MenuItem
                  style={{ fontFamily: 'Poppins, sans-serif' }}
                  value={t('urineControlLoss')}
                >
                  {t('urineControlLoss')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('deposition')}>
                  {t('deposition')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('vomiting')}>
                  {t('vomiting')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('undefined')}>
                  {t('undefined')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('others')}>
                  {t('others')}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box style={{ margin: '10px', marginRight: '70px', width: '300px' }}>
            <FormControl fullWidth>
              <h3 style={{ marginTop: '10px' }}>Boca</h3>
              <Select
                defaultValue={''}
                style={{ fontFamily: 'Poppins, sans-serif' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={mouthTypes()[props.newType.mouth] ?? ' '}
                label="mouth"
                onChange={(e) => {
                  props.handlerMouth(e.target.value)
                }}
              >
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('dry')}>
                  {t('dry')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('drooling')}>
                  {t('drooling')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('foam')}>
                  {t('foam')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('tongueBiting')}>
                  {t('tongueBiting')}
                </MenuItem>
                <MenuItem style={{ fontFamily: 'Poppins, sans-serif' }} value={t('undefined')}>
                  {t('undefined')}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Divider
          light
          style={{ backgroundColor: '#045383', marginTop: 30, marginLeft: 8, marginRight: 8 }}
        />
        <h2 style={{ marginLeft: '10px' }}>Después de la crisis:</h2>
        <Box
          className={
            props.innerWidth > 598 ? styles.containerRadioButton : styles.containerRadioButtonMobile
          }
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: window.innerWidth > 490 ? 'row' : 'column',
            }}
          >
            <Box style={{ margin: '10px', width: '300px' }}>
              <h3>{t('eventDuration')}</h3>
              <RadioGroup
                style={{
                  marginRight: props.innerWidth < 598 ? '' : '92px',
                  marginLeft: '15px',
                }}
                className={styles.radioGroup}
                aria-label="gender"
                name="gender1"
                value={afterDurationTypes()[props.newType.afterDuration] ?? ' '}
                onChange={(e) => {
                  props.handlerAfterDuration(e.target.value)
                }}
              >
                <FormControlLabel
                  sx={{ fontFamily: 'Poppins' }}
                  value={t('lessThanThirtyMin')}
                  control={<Radio />}
                  label={t('lessThanThirtyMin')}
                />
                <FormControlLabel
                  sx={{ fontFamily: 'Poppins' }}
                  value={t('betweenThirtySixty')}
                  control={<Radio />}
                  label={t('betweenThirtySixty')}
                />
                <FormControlLabel
                  sx={{ fontFamily: 'Poppins' }}
                  value={t('OverSixty')}
                  control={<Radio />}
                  label={t('OverSixty')}
                />
              </RadioGroup>
            </Box>
            <Box style={{ margin: '8px', width: '600px' }}>
              <h3>Otros síntomas</h3>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: window.innerWidth > 490 ? 'row' : 'column',
                  flexWrap: window.innerWidth > 490 ? 'wrap' : 'nowrap',
                  maxHeight: window.innerWidth > 490 ? '40px' : '100%',
                  overflow: window.innerWidth > 490 ? '' : 'auto',
                }}
              >
                {props.otherSymptoms.map((os, index) => {
                  return (
                    <Box key={index} style={{ width: window.innerWidth > 490 ? 300 : 'auto' }}>
                      <Checkbox
                        style={{ fontFamily: 'Poppins' }}
                        title={t(os)}
                        value={t(os)}
                        checked={props.checkedState[index]}
                        onChange={() => props.handlerAfterSymptom(index)}
                      />
                      {t(os)}
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'}>
          <Box mr={2}>
            {props.errorMessage && (
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t('La intensidad y color son obligatorios')}
              </Alert>
            )}
          </Box>
          <Box className={styles.buttonSaveNewtypeCrisis}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              handler={(e) => {
                props.saveNewCrisisType(e)
              }}
              label={t('save')}
              type={'button'}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
