import { FormFieldType } from '../../forms/enums/FormFieldType'

export enum ValueTypes {
  Bool = 1,
  Float,
}

export type ConditionField = {
  name: string
  fieldType: FormFieldType
  valueType: ValueTypes
  options?: Map<string, string>
  required?: boolean
}

export enum PatientSymptomsNames {
  Fever = 'Fiebre',
  AbdominalPain = 'Dolor abdominal',
  Vomiting = 'Vómitos',
  Diarrhea = 'Diarrea',
  HeadTrauma = 'Traumatismo craneal',
  Paleness = 'Palidez',
  Sweating = 'Sudoración',
  EnergyLack = 'Decaimiento',
  Blood = 'Sangre',
  Chronic = 'Patología crónica',
  Temperature = 'Temperatura',
}

export const patientSymptoms = new Map<string, ConditionField[]>([
  [
    PatientSymptomsNames.Fever,
    [
      {
        name: PatientSymptomsNames.Temperature,
        fieldType: FormFieldType.Select,
        valueType: ValueTypes.Float,
        options: new Map([
          ['37', '37'],
          ['37.5', '37.5'],
          ['38', '38'],
          ['38.5', '38.5'],
          ['39 o más', '39'],
        ]),
        required: true,
      },
      {
        name: PatientSymptomsNames.Chronic,
        fieldType: FormFieldType.CheckBox,
        valueType: ValueTypes.Bool,
      },
    ],
  ],
  [
    PatientSymptomsNames.AbdominalPain,
    [
      {
        name: PatientSymptomsNames.Paleness,
        fieldType: FormFieldType.CheckBox,
        valueType: ValueTypes.Bool,
      },
      {
        name: PatientSymptomsNames.Sweating,
        fieldType: FormFieldType.CheckBox,
        valueType: ValueTypes.Bool,
      },
      {
        name: PatientSymptomsNames.EnergyLack,
        fieldType: FormFieldType.CheckBox,
        valueType: ValueTypes.Bool,
      },
    ],
  ],
  [
    PatientSymptomsNames.Vomiting,
    [
      {
        name: PatientSymptomsNames.Blood,
        fieldType: FormFieldType.CheckBox,
        valueType: ValueTypes.Bool,
      },
    ],
  ],
  [
    PatientSymptomsNames.Diarrhea,
    [
      {
        name: PatientSymptomsNames.Blood,
        fieldType: FormFieldType.CheckBox,
        valueType: ValueTypes.Bool,
      },
    ],
  ],
  [
    PatientSymptomsNames.HeadTrauma,
    [
      {
        name: PatientSymptomsNames.Vomiting,
        fieldType: FormFieldType.CheckBox,
        valueType: ValueTypes.Bool,
      },
    ],
  ],
])
