import { ConfidentialityTerm } from '../models/ConfidentialityTerm'
import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ConfidentialityTermDTO, toModel } from '../models/ConfidentialityTermDTO'
import { QueryContainerConfig } from '../container'

export interface IConfidentialityTermApi extends IInit {
  getByID(id: string): Observable<ConfidentialityTerm | undefined>

  add(e: ConfidentialityTermDTO): Observable<ConfidentialityTerm | undefined>

  delete(id: string): Observable<boolean>
}

export class ConfidentialityTermApi implements IConfidentialityTermApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url =
      (this._container.config as QueryContainerConfig).moduleFullUrl + '/confidentialityTerm'
  }

  getByID(id: string): Observable<ConfidentialityTerm | undefined> {
    return this._httpClient.get<ConfidentialityTerm>({ url: this._url + '/' + id }).pipe(
      map<ConfidentialityTermDTO, ConfidentialityTerm>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  add(e: ConfidentialityTermDTO): Observable<ConfidentialityTerm | undefined> {
    return this._httpClient.post<ConfidentialityTerm>({ url: this._url, body: e }).pipe(
      map<ConfidentialityTermDTO, ConfidentialityTerm>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }
}
