import { useEffect, useRef } from 'react'

export const useDebounce = <T extends (...args: any[]) => void>(callback: T, delay: number) => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        // Cleanup the previous timeout on re-render
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [])

    const debouncedCallback: (...args: any[]) => void = (...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }

        timeoutRef.current = setTimeout(() => {
            callback(...args)
        }, delay)
    }

    return debouncedCallback
}

// Uso del hook
// const debouncedFunction = useDebounce((arg1, arg2) => {
//   // Lógica que quieres ejecutar después del retraso
// }, 500);
