import { Box, FormControl, Grid, RadioGroup, Typography } from '@mui/material'
import styles from './EpilepticCrisis.module.css'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { PatientSymptomDTO } from '../../../../modules/patient-data/models/PatientSymptomDTO'
import { TextFieldItem } from '../../../../components/form-card/TextFieldItem'
import { ItemList } from '../../../../common/models/ItemList'
import { UserEpilepticalConfig } from '../../../../modules/epileptical/models/UserEpilepticalConfig'
import { CrisisType } from './CrisisType'
import { Alert } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import addIcon from '../../../../assets/neuropediatria_icon/mas.svg'
import addIconInactive from '../../../../assets/neuropediatria_icon/masInactive.svg'
import editIcon from '../../../../assets/neuropediatria_icon/bloc.svg'
import editIconInactive from '../../../../assets/neuropediatria_icon/blocInactive.svg'
import { Tooltip } from '@mui/material'

export type DetailsProps = {
  readonly isEditing: boolean
  patientSymptom: PatientSymptomDTO
  epilepticalCrisisID: string
  handleTypeCrisis: (value: string, index: number) => void
  index: number
  handleTime: (event: Date, type: string, index: number) => void
  handleDate?: (event: Date, type: string) => void
  handleObservations: (e: string, index: number) => void
  handleClose: () => void
  errorMessage: string
  observations: string
  startDate: Date | undefined
  endDate: Date | undefined
  epilepticConfig: ItemList<UserEpilepticalConfig>
  color: string
  type: number
  handleOpen: () => void
  handleOpenEdit: () => void
}

export function Details(props: DetailsProps) {
  const { t } = useTranslation()
  const { innerWidth } = window
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const [observation, setObservation] = useState<string>(props.observations)
  const [startDate, setStartDate] = useState<Date>(new Date(props.startDate ?? ''))
  const [endDate, setEndDate] = useState<Date>(new Date(props.endDate ?? ''))

  const handleObservation = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    setObservation(e.target.value)
    props.handleObservations(e.target.value, index)
  }

  const handleTime = (event: dayjs.Dayjs | null, type: string, index: number) => {
    if (type === 'startDate' && event !== null) {
      setStartDate(new Date(event.toDate()))
    }

    if (type === 'endDate' && event !== null) {
      setEndDate(new Date(event.toDate()))
    }
    if (event !== null) {
      props.handleTime(new Date(event.toDate()), type, index)
    }
  }

  return (
    <Box className={styles.detailsContainer}>
      <h1 className={styles.detailTitle}>{t('detailEpilepticalCrisis')}</h1>
      <Box className={innerWidth > 598 ? styles.hourContainer : styles.hourContainerMobile}>
        <Box className={innerWidth > 598 ? styles.hourContent : styles.hourContentMobile}>
          <Typography className={styles.labelDate}>{t('startHour')}*</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
            <TimePicker
              key={'startHour'}
              className={styles.hourPicker}
              PaperProps={{
                sx: {
                  '& .MuiButtonBase-root': {
                    fontFamily: 'Poppins',
                  },
                },
              }}
              InputProps={{ disableUnderline: true, sx: { fontFamily: 'Poppins' } }}
              onError={(reason, value) => {
                switch (reason) {
                  case 'invalidDate':
                    setDateTimePickerError(t('invalidDateMessage'))
                    break
                }
              }}
              renderInput={(props) => (
                <TextField
                  style={{ marginBottom: 8 }}
                  sx={{
                    '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                      fontFamily: 'Poppins',
                    },
                  }}
                  variant={'standard'}
                  helperText={props.error && DateTimePickerError}
                  {...props}
                />
              )}
              inputFormat="HH:mm"
              ampm={false}
              onChange={(e) => handleTime(e, 'startDate', props.index)}
              value={startDate ?? null}
              maxTime={
                endDate
                  ? dayjs().set('hour', endDate.getHours()).set('minutes', endDate.getMinutes())
                  : null
              }
            />
          </LocalizationProvider>
        </Box>
        <Box className={innerWidth > 598 ? styles.hourContent : styles.hourContentMobile}>
          <Typography className={styles.labelDate}>{t('finishHour')}*</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
            <TimePicker
              key={'finishHour'}
              PaperProps={{
                sx: {
                  '& .MuiButtonBase-root': {
                    fontFamily: 'Poppins',
                  },
                },
              }}
              className={styles.hourPicker}
              InputProps={{ disableUnderline: true, sx: { fontFamily: 'Poppins' } }}
              onError={(reason, value) => {
                switch (reason) {
                  case 'invalidDate':
                    setDateTimePickerError(t('invalidDateMessage'))
                    break
                }
              }}
              renderInput={(props) => (
                <TextField
                  sx={{
                    '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                      fontFamily: 'Poppins',
                    },
                  }}
                  style={{ marginBottom: 8 }}
                  variant={'standard'}
                  helperText={props.error && DateTimePickerError}
                  {...props}
                />
              )}
              inputFormat="HH:mm"
              ampm={false}
              onChange={(e) => handleTime(e, 'endDate', props.index)}
              value={endDate ?? null}
              minTime={
                startDate
                  ? dayjs().set('hour', startDate.getHours()).set('minutes', startDate.getMinutes())
                  : null
              }
            />
          </LocalizationProvider>
        </Box>
        {props.errorMessage && (
          <>
            {(startDate === undefined || endDate === undefined) && (
              <Box mt={1}>
                <Alert severity="warning" key="errorMessage" id="errorMessage">
                  {t(props.errorMessage)}
                </Alert>
              </Box>
            )}
          </>
        )}
      </Box>
      <Box>
        <Box display={'flex'} flexDirection={'row'}>
          <h2>{t('crisisType')}</h2>
          {props.isEditing ? (
            <></>
          ) : (
            <>
              {props.epilepticConfig.count > 7 ? (
                <Tooltip title="No se pueden añadir mas colores" arrow={true} placement="right">
                  <Box className={styles.configButton}>
                    <img
                      src={addIconInactive}
                      alt="añadir tipo de crisis"
                      className={styles.buttons}
                    />
                  </Box>
                </Tooltip>
              ) : (
                <Box className={styles.configButton}>
                  <img
                    src={addIcon}
                    alt="añadir tipo de crisis"
                    onClick={props.handleOpen}
                    className={styles.buttons}
                  />
                </Box>
              )}
              {props.color && props.epilepticConfig.count > 0 ? (
                <Box className={styles.configButton}>
                  <img
                    src={editIcon}
                    alt="editar tipo de crisis"
                    onClick={props.handleOpenEdit}
                    className={styles.buttons}
                  />
                </Box>
              ) : (
                <Box className={styles.configButton}>
                  <Tooltip
                    title="No hay ningún color seleccionado para editar"
                    arrow={true}
                    placement="right"
                  >
                    <img
                      src={editIconInactive}
                      alt="editar tipo de crisis"
                      className={styles.buttons}
                    />
                  </Tooltip>
                </Box>
              )}
            </>
          )}
        </Box>
        {props.epilepticConfig.count > 0 ? (
          <Box className={styles.colorsBox}>
            <FormControl className={styles.radioGroupDetails}>
              <RadioGroup
                aria-label="crisisType"
                name="crisisType"
                value={props.color}
                onChange={(event) => props.handleTypeCrisis(event.target.value, props.index)}
              >
                <Grid container spacing={9}>
                  <Grid item xs={6} sm={3} style={{ display: 'flex' }}>
                    <CrisisType epilepticConfig={props.epilepticConfig} />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Box>
        ) : null}
      </Box>
      <p className={!props.color ? styles.required : ''}>
        {props.errorMessage && !props.color ? 'Obligatorio elegir un tipo de crisis' : ''}
      </p>
      <Box>
        <h3>{t('observations')}</h3>
        <Box mb={3}>
          <TextFieldItem
            field="observations"
            value={observation}
            label={''}
            type={'text'}
            handleChange={(e) => handleObservation(e, props.index)}
            rows={10}
            required={false}
          />
        </Box>
      </Box>
    </Box>
  )
}
