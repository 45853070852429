import { Box, Typography } from '@mui/material'
import { AppButton, ButtonTheme } from '../app-button/AppButton'
import { useTranslation } from 'react-i18next'
type ShareModalProps = {
  title: string
  icon: string
  handleContent: string | string[]
  handleClose: () => void
}

export const ShareModal = (props: ShareModalProps) => {
  const { t } = useTranslation()

  const useStyles = () => ({
    modal: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      backgroundColor: '#ffff',
      borderRadius: 30,
      padding: 20,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      width: 50,
      height: 50,
      marginBottom: '10px',
    },
    title: {
      fontSize: '20px',
      fontWeight: 'bold',
      fontFamily: 'poppins',
      marginBottom: '10px',
    },
    text: {
      fontSize: '18px',
      fontFamily: 'poppins',
      marginBottom: '10px',
    },
  })

  const classes = useStyles()

  return (
    <Box sx={classes.modal}>
      <Box sx={classes.content}>
        <img
          style={{
            width: 50,
            height: 50,
            marginBottom: '10px',
          }}
          src={props.icon}
        />
        <Typography sx={classes.title}>{props.title}</Typography>
        <p style={{ fontSize: '18px', fontFamily: 'poppins', marginBottom: '10px' }}>
          {props.handleContent}
        </p>
        <AppButton
          theme={ButtonTheme.NewPrimary}
          handler={props.handleClose}
          type={'button'}
          label={t('close')}
        />
      </Box>
    </Box>
  )
}
