import { Container, ContainerItem } from '../common/container/Container'
import { QueryTypeService } from '../modules/query-type/services/QueryTypeService'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  QUERY_TYPE_API_KEY,
  QUERY_TYPE_MODULE,
  QUERY_TYPE_SERVICE_KEY,
  QueryTypeProps,
} from '../modules/query-type/container'
import { QueryTypeApi } from '../modules/query-type/api/QueryTypeApi'

let container: Container

function init(p: QueryTypeProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(QUERY_TYPE_API_KEY, new ContainerItem({ instance: new QueryTypeApi() }))

  items.set(
    QUERY_TYPE_SERVICE_KEY,
    new ContainerItem({ instance: new QueryTypeService({ apiKey: QUERY_TYPE_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getQueryTypeContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[QUERY_TYPE_MODULE],
      },
    })
  }

  return container
}
