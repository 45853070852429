import { Query } from '../../../common/api/Query'

export type UserProps = {
  userID: string
  consultaID: string
  isAdmin: boolean
  userGender: QueryUserGender
}

export enum QueryUserGender {
  female = 1,
  male = 2,
}

export class ConsultaUser {
  private readonly _userID: string
  private readonly _consultaID: string

  private _isAdmin: boolean
  private _userGender: QueryUserGender

  constructor(p: UserProps) {
    this._userID = p.userID
    this._consultaID = p.consultaID
    this._isAdmin = p.isAdmin
    this._userGender = p.userGender
  }

  get userID(): string {
    return this._userID
  }

  get consultaID(): string {
    return this._consultaID
  }

  get isAdmin(): boolean {
    return this._isAdmin
  }

  set isAdmin(value: boolean) {
    this._isAdmin = value
  }

  get userGender(): QueryUserGender {
    return this._userGender
  }

  set userGender(value: QueryUserGender) {
    this._userGender = value
  }
}

export class UserQuery extends Query<ConsultaUser> {}
