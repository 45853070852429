import { Header } from '../../../../../components/header/Header'
import { Box, MenuItem, Modal, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from '../../AddNewCircle.module.css'
import { AppButton, ButtonTheme } from '../../../../../components/app-button/AppButton'

import { UserQuery } from '../../../../../modules/users/models/User'
import { Searcher } from '../../../../../components/table/Searcher'
import { AppTable } from '../../AppTable'
import { Field } from 'components/table'
import { File as F, File, FileQuery } from '../../../../../modules/files/models/File'
import exitModdal from '../../../../../assets/newCircle/exit_icon.svg'
import { CircleDTO, emptyCircleDTO } from '../../../../../modules/circle/models/CircleDTO'
import { FileService, IFileService } from '../../../../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../../../../modules/files'
import { getFileContainer } from '../../../../../container/file-module'
import { BoolQueryParam, Query, QueryParam } from '../../../../../common/api/Query'
import { IsDirectoryType } from '../../../../../modules/files/enums/IsDirectoryType'
import downloadIcon from '../../../../../assets/events_icons/download_icon.svg'
import { downloadFile } from '../../../../../common/utils/file'
import { emptyFileDTO, FileDTO, fromModel } from '../../../../../modules/files/models/FileDTO'
import { v4 as uuidv4 } from 'uuid'
import { getUserContainer } from '../../../../../container/user-module'
import { ILoggedUserService } from '../../../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../../../modules/users'
import { getCircleContainer } from '../../../../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../../../../modules/circle'
import { SearchValue, Pager, Search } from '../../../../../components/table_type/types'

interface Props {
  open: boolean
  onClose: () => void
  resourceID?: string
  handleEditResource?: () => void
  onChangeResource?: () => Promise<void>
}

interface newFile {
  id: string
  directoryID: string
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const fileService = getFileContainer().get<IFileService>(FILE_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

const ModalResourceOtherCircles: React.FC<Props> = ({
  open,
  onClose,
  resourceID,
  onChangeResource,
}) => {
  const { t } = useTranslation()
  const [filteredResources, setFilteredResources] = useState<File[]>([])
  const [allResources, setAllResources] = useState<File[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadResource, setIsLoadResource] = useState<boolean>(false)
  const fileContainer = getFileContainer()
  const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)
  const [directoriesCreated, setDirectoriesCreated] = useState<FileDTO[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [arraySelectedDirectories, setArraySelectedDirectories] = useState<string[]>([''])
  const [directoryStates, setDirectoryStates] = useState<newFile[]>([])
  const loggedUser = loggedUserService.get()
  // get circleDraftId from local storage
  const circleDraftId = localStorage.getItem('circleDraftID')
  const [circleDraft, setCircleDraft] = useState<CircleDTO>(emptyCircleDTO())
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [resourcesPerPage, setResopurcesPerPage] = useState<number>(10)
  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'allFields',
      label: t(''),
      value: '',
    },
  ])

  useEffect(() => {
    if (!open) {
      setSearcher([
        {
          name: 'allFields',
          label: t(''),
          value: '',
        },
      ])
    }
  }, [open])

  useEffect(() => {
    if (!circleDraftId || circleDraft.name) return
    circleService.getByCircleDraftID(circleDraftId).subscribe((circle) => {
      if (!circle) {
        return
      }
      setCircleDraft(circle)
    })
  }, [])

  useEffect(() => {
    if (!circleDraft.name) return
    filesService
      .getFilteredItems(
        new Query<FileQuery>({
          query: [
            new QueryParam<FileQuery>('isDir', IsDirectoryType.Directory.toString()),
            new QueryParam<FileQuery>('circles', circleDraft.name),
            new QueryParam<FileQuery>('createdByDoctor', new BoolQueryParam(true)),
          ],

          sort: [{ field: 'name' }],
        })
      )
      .subscribe((files) => {
        setDirectoriesCreated(files.items.map((file) => fromModel(file)))
      })
  }, [open])

  useEffect(() => {
    if (!open) return
    if (!circleDraft.name) return

    fileService
      .getFilteredItems(
        new Query<FileQuery>({
          query: [
            new QueryParam<FileQuery>('isDir', IsDirectoryType.File.toString()),
            new QueryParam<FileQuery>('notCircle', circleDraft.name),
          ],
          sort: [{ field: 'name', desc: false }],
          pager: { offset: page * resourcesPerPage, limit: resourcesPerPage },
        })
      )
      .subscribe((files) => {
        if (!files) return
        setFilteredResources(files.items)
        setAllResources(files.items)
        setCount(files.count)
      })
  }, [open, page, resourcesPerPage, searcher])

  useEffect(() => {
    if (!circleDraft.name) return
    if (searcher[0].value === '') {
      setFilteredResources(allResources)
    } else {
      fileService
        .getFilteredItems(
          new Query<FileQuery>({
            query: [
              new QueryParam<FileQuery>('isDir', IsDirectoryType.File.toString()),
              new QueryParam<FileQuery>('notCircle', circleDraft.name),
              new QueryParam<FileQuery>('searchAllFields', searcher[0].value ?? ''),
            ],
            sort: [{ field: 'name', desc: false }],
            pager: { offset: page * resourcesPerPage, limit: resourcesPerPage },
          })
        )
        .subscribe((files) => {
          if (!files) return
          setFilteredResources(files.items)
          setAllResources(files.items)
          setCount(files.count)
        })
    }
  }, [searcher])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: resourcesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, resourcesPerPage, searcher, open, count])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setResopurcesPerPage(10)
      return
    }
    setResopurcesPerPage(Number.parseInt(event.target.value))
  }

  const downloadResource = (resource: F) => {
    setIsLoadResource(true)
    filesService.download(resource).subscribe((res) => {
      if (res) {
        downloadFile(res.name, res.mimeType, res.data)
        setIsLoadResource(false)
      } else {
        setIsLoadResource(true)
      }
    })
  }

  const [selectedResources, setSelectedResources] = useState<Record<string, boolean>>({})

  const tableFields: Field<File>[] = [
    {
      name: 'name',
      label: t('name'),
    },
    {
      name: 'specialities',
      label: t('specialty'),
      renderFunc: (item, file) => file.specialities.toString(),
    },

    {
      name: 'basePath',
      label: t('directory'),
      renderFunc: (item, file) => (
        <>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Select
              labelId="demo-select-small-label"
              style={{ width: '128px' }}
              id="demo-select-small"
              value={
                directoryStates.find((directory) => directory.id === file.id)?.directoryID ?? ''
              }
              onChange={(event) => {
                handleDirectoryChange(file.id ? file.id : null, event.target.value)
              }}
            >
              <MenuItem
                disabled={arraySelectedDirectories.includes(t('withoutDirectory'))}
                key={'00'}
                value={'00'}
              >
                {t('withoutDirectory')}
              </MenuItem>

              {directoriesCreated.map((option) => (
                <MenuItem
                  disabled={arraySelectedDirectories.includes(option.name)}
                  key={option.id}
                  value={option.id}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </>
      ),
    },

    {
      name: 'creationDate',
      label: t('date'),
      renderFunc: (item, file) => {
        const dateToShow = file.creationDate ? new Date(file.creationDate).toLocaleDateString() : ''
        return <p>{dateToShow}</p>
      },
    },
    {
      name: 'checkBoxesSelected',
      label: t('download'),
      renderFunc: (item, file) => {
        const handleDownloadClick = () => {
          downloadResource(file)
        }
        return (
          <button
            onClick={handleDownloadClick}
            style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }}
          >
            <img src={downloadIcon} alt="Download" />
          </button>
        )
      },
    },
  ]

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => setSearcher(svs),
  }

  const handleDirectoryChange = (index: string | null, value: string) => {
    if (!index) return
    const selectedResourcesAux = { ...selectedResources }
    selectedResourcesAux[index] = true
    setSelectedResources(selectedResourcesAux)
    if (!directoryStates.some((directory) => directory.id === index)) {
      const directoryStatesAux = [...directoryStates]
      const newDirectoryState = { id: index || '', directoryID: value }
      directoryStatesAux.push(newDirectoryState)
      setDirectoryStates(directoryStatesAux)
      return
    }

    const directoryStatesAux: newFile[] = directoryStates.map((directory) => {
      if (directory.id === index) {
        return {
          ...directory,
          directoryID: value,
        }
      }
      return directory
    })

    setDirectoryStates(directoryStatesAux)
  }

  const handleCloneResource = async () => {
    const clonePromises = directoryStates.map(async (key) => {
      // eslint-disable-next-line prefer-const
      let file = allResources.find((f) => f.id === key.id)
      if (!file) return

      const cloneFile = emptyFileDTO(loggedUser?.id, '', true)

      if (key.directoryID === '00') {
        // case without directory
        key.directoryID = ''
      }

      cloneFile.id = uuidv4()
      cloneFile.name = file.name
      cloneFile.extension = file.extension
      cloneFile.basePath = key.directoryID
      cloneFile.size = file.size
      cloneFile.mimeType = file.mimeType
      cloneFile.miniPreview = file.miniPreview
      cloneFile.order = file.order
      cloneFile.creator = loggedUser?.id ?? ''
      cloneFile.parent = key.directoryID ?? ''
      cloneFile.isDir = file.isDir
      cloneFile.isSystem = file.isSystem ?? false
      cloneFile.eventId = file.eventId
      cloneFile.articleId = file.articleId
      cloneFile.messageId = file.messageId
      cloneFile.isVisible = file.isVisible
      cloneFile.isUploaded = file.isUploaded
      cloneFile.createdByDoctor = file.createdByDoctor

      cloneFile.specialities = circleDraft.specialities.join(', ')
      cloneFile.circles = circleDraft.name
      cloneFile.userCircleID = circleDraftId ?? ''
      cloneFile.isCircleConfResource = true

      cloneFile.checkBoxesSelected = file.checkBoxesSelected
      cloneFile.clonedFrom = file.id
      cloneFile.createdAt = new Date()
      cloneFile.data = file.data

      return await new Promise((resolve) => {
        fileService.add(cloneFile).subscribe((response) => {
          resolve(response)
        })
      })
    })

    try {
      await Promise.all(clonePromises)
      onClose()
    } catch (error) {
      console.error('Error al clonar recursos:', error)
    }
  }

  const handlerChecked = (key: string) => {
    const selectedResourcesAux = { ...selectedResources }
    selectedResourcesAux[key] = !selectedResourcesAux[key]
    setSelectedResources(selectedResourcesAux)

    if (!selectedResourcesAux[key]) {
      setDirectoryStates(
        directoryStates.filter((directory) => {
          return directory.id !== key
        })
      )

      return
    }

    if (!directoryStates.some((directory) => directory.id === key)) {
      const directoryStatesAux = [...directoryStates]
      const newDirectoryState = { id: key || '', directoryID: '' }
      directoryStatesAux.push(newDirectoryState)
      setDirectoryStates(directoryStatesAux)
    }
  }

  const handleSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!directoryStates) {
      await handleCloneResource()
      setDirectoryStates([])
      handleOnclose()
      return
    }
    await handleCloneResource()
    setDirectoryStates([])
    setSelectedResources({})

    handleOnclose()
  }
  const handleOnclose = () => {
    setDirectoryStates([])
    setSelectedResources({})
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose} className={style.modalOverFlow}>
      <Box className={style.modalContainer}>
        <Box padding="1rem">
          <Box className={style.contentHeaderExitIcon}>
            <img
              style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
              onClick={handleOnclose}
              src={exitModdal}
            />
          </Box>
          <Box className={style.contentHeader}>
            <Header label={t('resourceOtherCircles')} />
          </Box>
          <Box>
            <Box sx={{ alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <p>{t('search')}</p>
                <Searcher search={search} isPreventDefault={true} />
              </Box>
              <Box
                style={{
                  height: '400px',
                  overflowY: 'auto',
                  marginLeft: '32px',
                  marginRight: '32px',
                }}
              >
                <AppTable
                  checked={selectedResources}
                  handlerChecked={(key) => {
                    handlerChecked(key)
                  }}
                  fields={tableFields}
                  items={filteredResources}
                  rowKeyField={'id'}
                  pager={pager}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}>
            <Box>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('save')}
                marginStartIcon={{ marginRight: '10px' }}
                handler={handleSave}
                vertical={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalResourceOtherCircles
