import style from './Login.module.css'
import React, { useEffect, useState } from 'react'
import { LoadingSpinnerMini } from 'components/loading-spinner/LoadingSpinner'
import { Box } from '@mui/material'

export const VerifyUser = () => {
  const [timeoutReached, setTimeoutReached] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeoutReached(true)
    }, 10000)

    // Clear the timeout if the component is unmounted before the 20 seconds
    return () => clearTimeout(timer)
  }, [])

  return (
    <Box className={style.loginContainer} display={'flex'}>
      <Box className={style.login}>
        <h2>Verificando Usuario...</h2>
        {timeoutReached ? (
          <h4>La conexión parece estar fallando, Intente recargar la página.</h4>
        ) : (
          <LoadingSpinnerMini />
        )}
      </Box>
    </Box>
  )
}
