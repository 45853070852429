import { SettingType } from '../enums/SettingType'

type SettingProps = {
  id: string | undefined
  name: string
  type: SettingType
  minValue: number
  maxValue: number
}

export class Setting {
  private readonly _id: string | undefined
  private _name: string
  private _type: SettingType
  private _minValue: number
  private _maxValue: number

  constructor(p: SettingProps) {
    this._id = p.id
    this._name = p.name
    this._type = p.type
    this._minValue = p.minValue
    this._maxValue = p.maxValue
  }

  get id(): string | undefined {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get type(): SettingType {
    return this._type
  }

  set type(value: SettingType) {
    this._type = value
  }

  get minValue(): number {
    return this._minValue
  }

  set minValue(value: number) {
    this._minValue = value
  }

  get maxValue(): number {
    return this._maxValue
  }

  set maxValue(value: number) {
    this._maxValue = value
  }
}

export interface SettingQuery {
  name: string
  settingType: SettingType
}
