import { Box, List } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './NavigationEsfera.module.css'
import { Link, useNavigate } from 'react-router-dom'
import {
  ROUTE_ARTICLES,
  ROUTE_CALENDAR,
  ROUTE_CIRCLE_CONFIGURATOR,
  ROUTE_CIRCLES,
  ROUTE_FORM_GENERATOR,
  ROUTE_MESSENGER,
  ROUTE_NEWS_WALL,
  ROUTE_NOTIFICATIONS,
  ROUTE_PATIENT_DATA,
  ROUTE_PATIENT_FORMS,
  ROUTE_QUERY,
  ROUTE_RESOURCES,
  ROUTE_SHARE_RESOURCES,
  ROUTE_TAG_ARTICLES,
  ROUTE_USERS,
  ROUTE_VACCINES,
} from '../../routes/routes-constants'
import circleIcon from '../../assets/left_menu/circulos.svg'
import queryIcon from '../../assets/esfera/nav-circle-icons/query-icon.svg'
import queryActive from '../../assets/esfera/nav-circle-icons/query-icon-active.svg'
import circleActive from '../../assets/left_menu/circulos-act.svg'
import circleIconHover from '../../assets/left_menu/circulos-hover.svg'
import circleManageIcon from '../../assets/esfera/nav-circle-icons/paticipants-icon.svg'
import circleManageActive from '../../assets/esfera/nav-circle-icons/paticipants-icon-active.svg'
import circleManageIconHover from '../../assets/left_menu/circulos-admin-hover.svg'
import notifications from '../../assets/esfera/nav-circle-icons/newsWall-icon.svg'
import notificationsActive from '../../assets/esfera/nav-circle-icons/newsWall-icon-active.svg'
import notificationsHover from '../../assets/left_menu/notificaciones-hover.svg'
import patientData from '../../assets/esfera/nav-circle-icons/patient-data-icon.svg'
import patientDataActive from '../../assets/esfera/nav-circle-icons/patient-data-icon-active.svg'
import patientDataHover from '../../assets/left_menu/datos-paciente-hover.svg'
import chat from '../../assets/esfera/nav-circle-icons/chat-icon.svg'
import chatActive from '../../assets/esfera/nav-circle-icons/chat-icon-active.svg'
import chatHover from '../../assets/left_menu/chat-hover.svg'
import calendar from '../../assets/esfera/nav-circle-icons/calendar-icon.svg'
import calendarActive from '../../assets/esfera/nav-circle-icons/calendar-icon-active.svg'
import calendarHover from '../../assets/left_menu/calendar-hover.svg'
import resources from '../../assets/esfera/nav-circle-icons/share-resources-icon.svg'
import resourcesActive from '../../assets/esfera/nav-circle-icons/share-resources-icon-active.svg'
import resourcesHover from '../../assets/left_menu/recursos-hover.svg'
import library from '../../assets/left_menu/library.svg'
import libraryActive from '../../assets/left_menu/library-act.svg'
import libraryHover from '../../assets/left_menu/library-hover.svg'
import forms from '../../assets/esfera/nav-circle-icons/forms-icon.svg'
import formsActive from '../../assets/esfera/nav-circle-icons/forms-icon-active.svg'
import formsHover from '../../assets/left_menu/formularios-hover.svg'
import createForms from '../../assets/left_menu/add-form.svg'
import createFormsActive from '../../assets/left_menu/add-form-act.svg'
import createFormsHover from '../../assets/left_menu/add-form-hover.svg'
import icoProfile from '../../assets/esfera/nav-profile-icons/ico-perfil.svg'
import icoProfileActive from '../../assets/esfera/nav-profile-icons/ico-perfil-active.svg'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Permission } from '../../common/permission'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users/container'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import vaccineIconHover from '../../assets/left_menu/vacunasHover.svg'
import vaccineIcon from '../../assets/left_menu/vacunas.svg'
import shareWithMe from '../../assets/resource_icons/ico-share2.svg'
import { useIsRelated, canSeeQuery } from 'hooks/relatedUsers/getRelatedUsers'
import { CircleSubMenu } from './CircleSubMenu'
import { ProfileSubMenu } from './ProfileSubMenu'
import { getCircleLinkStyle, getListItemStyleHoverCircle } from './stylesFunctions'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'
import { Roles } from '../../modules/users/enums/Roles'

export type LinkType = {
  route: string
  title: string
  activeIcon: string
  icon: string
  altSrc: string
  hoverIcon: string
  isVisible: boolean
  additionalRoutes: string[]
  subMenu: boolean
  isDisabled?: boolean
}

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

type MainNavigationProps = {
  login: string
  username: string
  gender: number
  dateOfBirth: string
  dni: string
  cip: string
  selectedMenu: string
  isChooseRole: (choose: boolean) => void
  handleRedirectToSesamo: () => void
}

const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export function NavigationEsfera(props: MainNavigationProps) {
  const { t } = useTranslation()
  const logoutURL = process.env.REACT_APP_EXIT_URL

  const userCircle = userCircleActiveService.getActiveFullUserCircle()

  const [selectedMenu, setSelectedMenu] = useState<string>(props.selectedMenu)
  const [mobileOpen, setMobileOpen] = useState(false)
  const [profileOpen, setProfileOpen] = useState(false)
  const [circleHover, setCircleHover] = useState(false)
  //  const [delayHandler, setDelayHandler] = useState<ReturnType<typeof setTimeout> | null>(null)
  const links: LinkType[] = [
    {
      route: ROUTE_CIRCLES,
      title: 'circleOfPatient',
      activeIcon: circleActive,
      icon: circleIcon,
      altSrc: 'circleOfPatientIcon',
      hoverIcon: circleIconHover,
      isVisible: loggedUserService.userCan(Permission.seeCircles),
      additionalRoutes: [
        ROUTE_NOTIFICATIONS,
        ROUTE_USERS,
        ROUTE_PATIENT_DATA,
        ROUTE_SHARE_RESOURCES,
        ROUTE_PATIENT_FORMS,
        ROUTE_NEWS_WALL,
        ROUTE_RESOURCES,
        ROUTE_CALENDAR,
        ROUTE_NOTIFICATIONS,
        ROUTE_QUERY,
        ROUTE_MESSENGER,
        ROUTE_CALENDAR,
      ],
      subMenu: false,
    },
    {
      route: ROUTE_NOTIFICATIONS,
      title: 'newsWall',
      activeIcon: notificationsActive,
      icon: notifications,
      altSrc: 'notificationsIcon',
      hoverIcon: notificationsHover,
      isVisible:
        userCircle !== undefined &&
        (!loggedUserService.get()?.roles.some((role) => role.startsWith('manager')) ?? false),
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_USERS,
      title: 'participants',
      activeIcon: circleManageActive,
      icon: circleManageIcon,
      altSrc: 'manageCircleIcon',
      hoverIcon: circleManageIconHover,
      isVisible: userCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_PATIENT_DATA,
      title: 'patientData',
      activeIcon: patientDataActive,
      icon: patientData,
      altSrc: 'patientDataIcon',
      hoverIcon: patientDataHover,
      isVisible: userCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_MESSENGER,
      title: 'chat',
      activeIcon: chatActive,
      icon: chat,
      altSrc: 'calendarIcon',
      hoverIcon: chatHover,
      isVisible:
        (useIsRelated() ? userCircle !== undefined : false) &&
        (!loggedUserService.get()?.roles.some((role) => role.startsWith('manager')) ?? false),
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_QUERY,
      title: 'query',
      activeIcon: queryActive,
      icon: queryIcon,
      altSrc: 'queryIcon',
      hoverIcon: chatHover,
      isVisible: canSeeQuery(),
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_CALENDAR,
      title: 'calendar',
      activeIcon: calendarActive,
      icon: calendar,
      altSrc: 'calendarIcon',
      hoverIcon: calendarHover,
      isVisible: userCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_RESOURCES,
      title: 'resources',
      activeIcon: resourcesActive,
      icon: resources,
      altSrc: 'resourcesIcon',
      hoverIcon: resourcesHover,
      isVisible: userCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_PATIENT_FORMS,
      title: 'patientsForms',
      activeIcon: formsActive,
      icon: forms,
      altSrc: 'patientsFormsIcon',
      hoverIcon: formsHover,
      isVisible: userCircle !== undefined && loggedUserService.userCan(Permission.userForms),
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_CIRCLE_CONFIGURATOR,
      title: 'circleConfigurator',
      activeIcon: libraryActive,
      icon: library,
      altSrc: 'CircleConfiguratorIcon',
      hoverIcon: libraryHover,
      isVisible: loggedUserService.get()?.roles.includes(Roles.Admin) ?? false,
      additionalRoutes: [],
      subMenu: false,
    },
    {
      route: ROUTE_ARTICLES,
      title: 'library',
      activeIcon: libraryActive,
      icon: library,
      altSrc: 'libraryIcon',
      hoverIcon: libraryHover,
      isVisible: !loggedUserService.get()?.roles.includes(Roles.Admin),
      additionalRoutes: [ROUTE_ARTICLES, ROUTE_TAG_ARTICLES],
      subMenu: false,
    },
    {
      route: ROUTE_FORM_GENERATOR,
      title: 'generateForms',
      activeIcon: createFormsActive,
      icon: createForms,
      altSrc: 'generateFormsIcon',
      hoverIcon: createFormsHover,
      isVisible:
        loggedUserService.userCan(Permission.createFormTemplates) &&
        !loggedUserService.get()?.roles.includes(Roles.Admin),
      additionalRoutes: [],
      subMenu: false,
    },
    {
      route: ROUTE_VACCINES,
      title: 'vaccines',
      activeIcon: vaccineIconHover,
      icon: vaccineIcon,
      altSrc: 'vaccineIcon',
      hoverIcon: vaccineIconHover,
      isVisible:
        loggedUserService.userCan(Permission.configVaccines) &&
        !loggedUserService.get()?.roles.includes(Roles.Admin),
      additionalRoutes: [],
      subMenu: false,
    },
    {
      route: ROUTE_SHARE_RESOURCES,
      title: 'shareResources',
      activeIcon: shareWithMe,
      icon: shareWithMe,
      altSrc: 'shareResourcesIcon',
      hoverIcon: shareWithMe,
      isVisible:
        loggedUserService.userCan(Permission.SeeSharedResources) &&
        !loggedUserService.get()?.roles.includes(Roles.Admin),
      additionalRoutes: [],
      subMenu: false,
    },
  ]

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlerLogoutProfile = (_: React.MouseEvent<Element, MouseEvent>) => {
    if (logoutURL) {
      window.location.replace(logoutURL)
      loggedUserService.logout()
    }
    localStorage.removeItem('selected circle')
    localStorage.removeItem('selected user circle')
    localStorage.removeItem('selected user')
    props.isChooseRole(false)
    loggedUserService.logout()
  }
  const navigate = useNavigate()

  const renderVisibleLinks = () => {
    return links
      .filter((l) => l.isVisible && !l.subMenu && l.route !== ROUTE_CIRCLES)
      .map((l) => (
        <Link
          onClick={() => {
            navigate(l.route)
            setSelectedMenu(l.route)
            setCircleHover(false)
          }}
          key={l.title}
          to={l.route}
          className={getCircleLinkStyle(
            selectedMenu,
            style.linkActive,
            false,
            circleHover,
            ...l.additionalRoutes,
            l.route
          )}
          id={l.route}
        >
          <ListItem
            title={t(l.title)}
            handleClick={handleClick}
            altSrc={t(l.altSrc)}
            hoverIconSrc={l.hoverIcon}
            isCircleLink={false}
            circleHover={circleHover}
            isSubMenu={l.subMenu}
            selectedMenu={selectedMenu}
            l={l}
          />
        </Link>
      ))
  }

  const drawer = (
    <div className={style.listContainer}>
      <List className={style.list} onClick={() => setMobileOpen(false)}>
        <div className={style.menuDiv}>
          <Box>
            <Link
              style={{ maxWidth: 80 }}
              onClick={() => {
                navigate(ROUTE_CIRCLES)
                setSelectedMenu(ROUTE_CIRCLES)
              }}
              key={ROUTE_CIRCLES}
              to={ROUTE_CIRCLES}
              className={getCircleLinkStyle(
                selectedMenu,
                style.linkActive,
                true,
                circleHover,
                ROUTE_CIRCLES,
                ...links[0].additionalRoutes
              )}
              id={ROUTE_CIRCLES}
            >
              <ListItem
                title={t('circleOfPatient')}
                altSrc={t('circleOfPatientIcon')}
                hoverIconSrc={circleIconHover}
                setCircleHover={setCircleHover}
                handleClick={handleClick}
                isCircleLink={true}
                isSubMenu={false}
                circleHover={circleHover}
                setMobileOpen={setMobileOpen}
                selectedMenu={selectedMenu}
                l={links.find((l) => l.route === ROUTE_CIRCLES)}
              />
            </Link>
          </Box>
          {renderVisibleLinks()}
        </div>
        <Box
          onMouseEnter={() => {
            setProfileOpen(true)
          }}
          /* onMouseLeave={() => {
                setProfileOpen(false)
          }} */
          className={profileOpen ? style.avatarContainerActive : style.avatarContainer}
        >
          <Box
            className={
              profileOpen ? style.avatarContainerActiveBackground : style.avatarContainerBackground
            }
          >
            <img
              src={profileOpen ? icoProfileActive : icoProfile}
              alt="avatar"
              className={style.avatarImg}
            />
          </Box>
        </Box>
      </List>
      {mobileOpen && (
        <CircleSubMenu
          selectedMenu={selectedMenu}
          anchorEl={anchorEl}
          setMobileOpen={setMobileOpen}
          setCircleHover={setCircleHover}
          userCircle={userCircle}
          links={links}
          mobileOpen={mobileOpen}
          navigate={navigate}
          setSelectedMenu={setSelectedMenu}
        />
      )}
      {profileOpen && (
        <ProfileSubMenu
          anchorEl={anchorEl}
          login={props.login}
          username={props.username}
          gender={props.gender}
          dateOfBirth={props.dateOfBirth}
          dni={props.dni}
          cip={props.cip}
          selectedMenu={selectedMenu}
          setProfileOpen={setProfileOpen}
          userCircle={userCircle}
          links={links}
          profileOpen={profileOpen}
          navigate={navigate}
          setSelectedMenu={setSelectedMenu}
          handlerLogout={handlerLogoutProfile}
        />
      )}
    </div>
  )

  useEffect(() => {
    setSelectedMenu(props.selectedMenu)
  }, [props.selectedMenu])

  return <aside className={style.aside}>{useMediaQuery('(min-width:900px)') && drawer}</aside>
}

type ListItemProps = {
  title: string
  altSrc: string
  isDisabled?: boolean
  hoverIconSrc: string
  isCircleLink: boolean
  circleHover?: boolean | undefined
  setCircleHover?: (hover: boolean) => void
  isSubMenu: boolean
  mobileOpen?: boolean
  setMobileOpen?: (open: boolean) => void
  selectedMenu?: string
  handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  l?: LinkType | undefined
  style?: React.CSSProperties
}

function ListItem(props: ListItemProps): JSX.Element {
  return (
    <div className={style.menuDiv}>
      <Box key={props.title} className={style.menuList}>
        <Box
          onMouseEnter={() => {
            if (props.setCircleHover) {
              props.setCircleHover(true)
            }
            if (props.setMobileOpen) {
              props.setMobileOpen(true)
            }
          }}
          onMouseLeave={(e) => {
            /* if (props.setCircleHover) {
                                        props.setCircleHover(false)
                                    } */
            props.handleClick(e)
            if (props.setMobileOpen) {
              props.setMobileOpen(false)
            }
          }}
          className={getListItemStyleHoverCircle(
            props.isDisabled ?? false,
            props.isCircleLink ?? false,
            props.circleHover ?? false,
            props.selectedMenu ?? '',
            props.mobileOpen ?? false,

            style.menuItemActive,
            style.menuItem,
            ...(props.l?.additionalRoutes ?? ''),
            props.l?.route ?? ''
          )}
          display="flex"
          alignItems="center"
        >
          <h3 className={style.titleName}> {props.title}</h3>
        </Box>
      </Box>
    </div>
  )
}
