import { UserCircleWithCircleAndUser } from './UserCircleWithCircleAndUser'
import { v4 as uuidv4 } from 'uuid'
import { emptyUserDTO, UserDTO } from '../../users/models/User'
import { CircleDTO, emptyCircleDTO } from '../../circle/models/CircleDTO'

export interface UserCircleWithCircleAndUserDTO {
  id: string
  circle: CircleDTO
  user: UserDTO
  familiarsID: string[]
  profSmsID: string[]
  profExtID: string[]
  careersID: string[]
}

export function emptyUserCircleWithCircleAndUserDTO(): UserCircleWithCircleAndUserDTO {
  return {
    id: uuidv4(),
    circle: emptyCircleDTO(),
    user: emptyUserDTO(),
    familiarsID: [],
    profSmsID: [],
    profExtID: [],
    careersID: [],
  }
}

export function fromModel(e: UserCircleWithCircleAndUser): UserCircleWithCircleAndUserDTO {
  return {
    id: e.id,
    circle: e.circle,
    user: e.user,
    familiarsID: e.familiarsID,
    profSmsID: e.profSmsID,
    profExtID: e.profExtID,
    careersID: e.careersID,
  }
}

export function toModel(d: UserCircleWithCircleAndUserDTO): UserCircleWithCircleAndUser {
  return new UserCircleWithCircleAndUser(d)
}
