import { styled } from '@mui/system'
import { Switch } from '@mui/material'
import { Theme } from '@mui/material/styles'

export const CustomSwitch = styled(Switch)(({ theme }: { theme?: Theme }) => ({
  width: 54,
  height: 26,
  padding: 0,
  margin: theme?.spacing(2) ?? 0,
  '.MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(28px)',
      color: theme?.palette.common.white ?? '',
      '& + .MuiSwitch-track': {
        backgroundColor: '#f37e31',
        opacity: 1,
        border: 'none',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#f37e31',
      border: '6px solid #fff',
    },
  },
  '.MuiSwitch-thumb': {
    width: 24,
    height: 24,
  },
  '.MuiSwitch-track': {
    borderRadius: 52 / 2,
    border: `1px solid #efefef`,
    backgroundColor: '#d1d1d1',
    opacity: 1,
    transition: theme?.transitions.create(['background-color', 'border']) ?? '', // Verifica si theme existe antes de acceder a sus propiedades
  },
}))

export const SwitchCloseQuery = styled(Switch)(({ theme }: { theme: Theme }) => ({
  width: 49,
  height: 26,
  padding: 0,
  margin: theme.spacing(1),
  '.MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        backgroundColor: '#0563a6',
        opacity: 1,
        border: 'none',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#0563a6',
      border: '6px solid #fff',
    },
  },
  '.MuiSwitch-thumb': {
    width: 24,
    height: 24,
  },
  '.MuiSwitch-track': {
    borderRadius: 52 / 2,
    border: `1px solid #efefef`,
    backgroundColor: '#d1d1d1',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
}))
