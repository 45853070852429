import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UserTrainingGroup, UserTrainingGroupQuery } from '../models/UserTrainingGroup'
import { UserTrainingGroupDTO } from '../models/UserTrainingGroupDTO'
import { IUserTrainingGroupApi } from '../api/UserTrainingGroupApi'

type Props = {
  apiKey: symbol
}

export interface IUserTrainingGroupService extends IInit {
  getByUserCircleID(id: string): Observable<UserTrainingGroup | undefined>

  getByGroupID(id: string): Observable<UserTrainingGroup | undefined>

  getFilteredList(q: Query<UserTrainingGroupQuery>): Observable<ItemList<UserTrainingGroup>>

  add(e: UserTrainingGroup): Observable<UserTrainingGroup | undefined>

  update(e: UserTrainingGroupDTO): Observable<UserTrainingGroup | undefined>

  deleteByUserCircleID(userCircleID: string): Observable<boolean>

  deleteByGroupID(groupID: string): Observable<boolean>
}

export class UserTrainingGroupService implements IUserTrainingGroupService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserTrainingGroupApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserTrainingGroupApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: UserTrainingGroupDTO): Observable<UserTrainingGroup | undefined> {
    return this._api.add(e)
  }

  deleteByUserCircleID(userCircleID: string): Observable<boolean> {
    return this._api.deleteByUserCircleID(userCircleID)
  }

  deleteByGroupID(groupID: string): Observable<boolean> {
    return this._api.deleteByUserCircleID(groupID)
  }

  getByUserCircleID(id: string): Observable<UserTrainingGroup | undefined> {
    return this._api.getByUserCircleID(id)
  }

  getByGroupID(id: string): Observable<UserTrainingGroup | undefined> {
    return this._api.getByGroupID(id)
  }

  getFilteredList(q: Query<UserTrainingGroupQuery>): Observable<ItemList<UserTrainingGroup>> {
    return this._api.getFilteredList(q)
  }

  update(e: UserTrainingGroupDTO): Observable<UserTrainingGroup | undefined> {
    return this._api.update(e)
  }
}
