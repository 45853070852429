import { PatientCalendar } from './PatientCalendar'
import { v4 as uuidv4 } from 'uuid'
import { EventCategory } from '../../calendar/enums/EventCategory'
import {
  toModel as toModelEvent,
  fromModel as eventFromModel,
  PatientCalendarEventDTO,
} from './PatientCalendarEventDTO'

export interface PatientCalendarDTO {
  id: string | undefined
  title: string
  year: number
  pathologyID: string
  category: EventCategory
  events: PatientCalendarEventDTO[]
}

export function emptyPatientCalendarDTO() {
  return {
    id: uuidv4(),
    title: '',
    year: new Date().getFullYear(),
    pathologyID: '',
    category: EventCategory.Landmarks,
    events: [],
  }
}

export function fromModel(d: PatientCalendar): PatientCalendarDTO {
  return {
    id: d.id,
    title: d.title,
    year: d.year,
    pathologyID: d.pathologyID,
    category: d.category,
    events: d.events.map((e) => eventFromModel(e)),
  }
}

export function toModel(d: PatientCalendarDTO): PatientCalendar {
  return new PatientCalendar({
    category: d.category,
    events: d.events.map((e) => toModelEvent(e)),
    id: d.id,
    pathologyID: d.pathologyID,
    title: d.title,
    year: d.year,
  })
}
