import { Query } from '../../../common/api/Query'

export type FormPathologyProps = {
  id: string
  formID: string
  circleID: string
}

export class FormPathology {
  private _id: string
  private _formID: string
  private _circleID: string

  constructor(p: FormPathologyProps) {
    this._id = p.id
    this._formID = p.formID
    this._circleID = p.circleID
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get formID(): string {
    return this._formID
  }

  set formID(value: string) {
    this._formID = value
  }

  get circleID(): string {
    return this._circleID
  }
  set circleID(value: string) {
    this._circleID = value
  }
}

export class FormPathologyQuery extends Query<FormPathology> {
  userID?: string
}
