import styles from './Calendar.module.css'
import { AllCalendarEventDTO } from '../../modules/calendar/models/AllCalendarEventDTO'
import { CalendarTooltipMobile } from './CalendarTooltipMobile'
import { Box } from '@mui/material'
import { Header } from '../../components/header/Header'
import close from '../../assets/table_icons/ico-eliminar.svg'
import React from 'react'
import style from '../patient-calendar-config/Modal.module.css'
import { useTranslation } from 'react-i18next'

type MonthMobileProps = {
  handlerClose: () => void
  events?: AllCalendarEventDTO[]
}

export const MonthMobileModal = (props: MonthMobileProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Box className={styles.mobileModalContainer}>
        <Box className={style.header}>
          <Header label={t('eventInformation')} icon={close} onClick={props.handlerClose} />
        </Box>
        <Box className={styles.mobileScrollContainer}>
          <CalendarTooltipMobile events={props.events} />
        </Box>
      </Box>
    </>
  )
}
