import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserForm, UserFormQuery } from '../../modules/forms/models/UserForm'
import { ROUTE_FORM_GENERATOR } from '../../routes/routes-constants'
import {
  FORM_SERVICE_KEY,
  IFormService,
  IUserFormService,
  USER_FORM_SERVICE_KEY,
} from '../../modules/forms'
import { getFormContainer } from '../../container/form-module'
import { Query, QueryParam } from '../../common/api/Query'
import style from '../../components/loading-spinner/LoadingSpinner.module.css'
import styles from './TableResults.module.css'
import { LoadingSpinner } from 'components/loading-spinner/LoadingSpinner'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import genericStyle from '../../common/utils/generic.module.css'
import { emptyFormDTO, FormDTO, fromModel } from '../../modules/forms/models/FormDTO'
import { ResultsRowForm } from './ResultsRowForm'
import { getUserContainer } from 'container/user-module'
import { IUserService } from 'modules/users/services/UserService'
import { USER_SERVICE_KEY } from 'modules/users'
import { User } from 'modules/users/models/User'
import { reduceString } from 'common/utils/strings'
import { Pagination } from '../../components/table/Pagination'
import { useNavigate } from 'react-router-dom'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'

type FormGeneratorResultsProps = {
  id?: string
}

type FielValue = {
  date: Date
  values: string[]
}

const formContainer = getFormContainer()
const formService = formContainer.get<IFormService>(FORM_SERVICE_KEY)
const userFormService = formContainer.get<IUserFormService>(USER_FORM_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

// TODO REFACTORING TO APPTABLE
export function TableResults(props: FormGeneratorResultsProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userForms, setUserForms] = useState<UserForm[]>([])
  const [form, setForm] = useState<FormDTO>(emptyFormDTO())
  const [map, setMap] = useState<Map<string, FielValue[]>>(new Map<string, FielValue[]>())
  const [users, setUsers] = useState<string[]>([])
  const [userObjects, setUserObjects] = useState<User[]>([])
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)

  useEffect(() => {
    if (users.length > 0) {
      userService
        .getFilteredList(
          new Query({
            query: [
              {
                name: 'ids',
                value: users,
              },
            ],
          })
        )
        .subscribe((res) => {
          if (res) {
            setUserObjects(res.items)
          }
        })
    }
  }, [users])

  useEffect(() => {
    if (!isLoading) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let values = 0
    formService.getByID(props.id ?? '').subscribe((res) => {
      if (res) {
        values = res.formFields.length
        setForm(fromModel(res))
      }
    })

    userFormService
      .getFilteredList(
        new Query({
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
          query: [new QueryParam<UserFormQuery>('formID', props.id ?? '')],
          sort: [{ field: 'formID' }],
        })
      )
      .subscribe((res) => {
        const auxMap: Map<string, FielValue[]> = new Map<string, FielValue[]>()
        const auxUsers: string[] = []
        res.items.forEach((item1) => {
          const dates = item1.userFormValues.map((i) => i.date)
          dates.forEach((date) => {
            const itemsByDate = item1.userFormValues.filter((i) => i.date === date && i.done)
            const values = itemsByDate.map((i) => {
              const values: any = i?.getValues()
              if (values?.length > 0) {
                return values[0]
              } else {
                return []
              }
            })

            const aux: FielValue = {
              date,
              values,
            }

            if (values.length > 0) {
              if (auxMap.get(item1.userID) !== undefined) {
                const items = auxMap.get(item1.userID)
                if (!items?.map((i) => i.date).includes(aux.date) && aux.values.length > 0) {
                  items?.push(aux)
                  auxMap.set(item1.userID, items ?? [])
                }
              } else {
                const items: FielValue[] = []
                items.push(aux)
                auxMap.set(item1.userID, items)
              }
            }
            if (!auxUsers.includes(item1.userID)) {
              auxUsers.push(item1.userID)
            }
          })
        })
        setUsers(auxUsers)
        setMap(auxMap)
        setCount(res.count)
        setUserForms(res.items)
        setInterval(() => {
          setIsLoading(false)
        }, 1000)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(true)
  }, [page, count, rowsPerPage])

  const handlePaginationChange = (e: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setRowsPerPage(10)
      return
    }
    setRowsPerPage(Number.parseInt(event.target.value))
  }

  const goBack = () => navigate(ROUTE_FORM_GENERATOR)

  return (
    <Box mb={3} className={genericStyle.pageContainer}>
      <Box mb={3} key="buttonBox">
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={goBack}
        />
      </Box>
      {!isLoading ? (
        <Box style={{ width: '100%', overflow: 'auto', border: 'none' }}>
          <Table style={{ border: 'none' }}>
            <TableHead className={styles.headerCell} key="tablehead">
              <TableRow>
                <TableCell className={styles.cell} key="tablecell">
                  {t('username')}
                </TableCell>
                <TableCell className={styles.cell} key="Date">
                  {t('date')}
                </TableCell>
                {form.formFields.map((ff) => (
                  <TableCell className={styles.cell} key={ff.id}>
                    {ff.title.length > 63 ? (
                      <Tooltip title={ff.title}>
                        <div>{reduceString(ff.title, 63)}</div>
                      </Tooltip>
                    ) : (
                      <>{ff.title}</>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody key="tablebody">
              <ResultsRowForm users={userObjects} map={map} />
            </TableBody>
          </Table>
          <Box mt={3}>
            <Pagination
              page={page}
              count={count}
              handleChangePage={handlePaginationChange}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {/* <Pagination
              style={{ marginRight: 'auto', marginLeft: 'auto', width: '10%' }}
              page={page}
              variant="outlined"
              color="primary"
              count={count}
              siblingCount={1}
              onChange={handlePaginationChange}
            /> */}
          </Box>
        </Box>
      ) : (
        <LoadingSpinner className={style.loadingSpinner} />
      )}
    </Box>
  )
}
