import { AutoMessage, AutoMessageQuery } from '../models/AutoMessage'
import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { AutoMessageDTO, toModel, fromModel } from '../models/AutoMessageDTO'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { MessengerConsultaContainerConfig } from '../container'

export interface IAutoMessageApi extends IInit {
  getByID(id: string): Observable<AutoMessage | undefined>

  getFilteredList(q: Query<AutoMessageQuery>): Observable<ItemList<AutoMessage>>

  add(e: AutoMessageDTO): Observable<AutoMessage | undefined>

  update(e: AutoMessageDTO): Observable<AutoMessage | undefined>

  delete(id: string): Observable<boolean>
}

export class AutoMessageApi implements IAutoMessageApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as MessengerConsultaContainerConfig).moduleFullUrl
  }

  getByID(id: string): Observable<AutoMessage | undefined> {
    return this._httpClient.get<AutoMessage>({ url: this._url + '/autoMessage/' + id }).pipe(
      map<AutoMessageDTO, AutoMessage>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  add(e: AutoMessage): Observable<AutoMessage | undefined> {
    return this._httpClient
      .post<AutoMessage>({ url: this._url + '/autoMessage', body: fromModel(e) })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/autoMessage/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getFilteredList(q: Query<AutoMessageQuery>): Observable<ItemList<AutoMessage>> {
    return this._httpClient
      .get<ItemList<AutoMessage>>({ url: prepareURL(this._url + '/autoMessage', q) })
      .pipe(
        map<ItemList<AutoMessageDTO>, ItemList<AutoMessage>>((dto) => {
          const itemList = emptyList<AutoMessage>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<AutoMessage>())
        })
      )
  }

  update(e: AutoMessage): Observable<AutoMessage | undefined> {
    return this._httpClient
      .put<AutoMessage>({ url: this._url + '/autoMessage', body: fromModel(e) })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
