import { Notification } from '../models/Notification'
import { Container, IInit } from '../../../common/container/Container'
import { NotificationContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { prepareURL } from '../../../common/api/http-helpers'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { DeliveryStatus } from '../enums/DeliveryStatus'
import { fromModel, NotificationDTO, toModel } from '../models/NotificationDTO'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface INotificationApi extends IInit {
  getByID(id: string): Observable<Notification | undefined>

  getFilteredItems(q: Query<Notification>): Observable<Notification[]>

  add(e: Notification): Observable<Notification | undefined>

  update(e: Notification): Observable<Notification | undefined>

  updateStatus(id: string, status: DeliveryStatus): Observable<Notification | undefined>

  delete(e: Notification): Observable<boolean>

  getFilteredList(q: Query<Notification>): Observable<ItemList<Notification>>

  updateStatusByMessage(id: string): Observable<Notification | undefined>
}

export class NotificationApi implements INotificationApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url =
      (this._container.config as NotificationContainerConfig).moduleFullUrl + '/notifications'
  }

  add(e: Notification): Observable<Notification | undefined> {
    return this._httpClient.post<Notification>({ url: this._url, body: fromModel(e) }).pipe(
      map<NotificationDTO, Notification>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(e: Notification): Observable<boolean> {
    return this._httpClient.delete({ url: this._url, body: fromModel(e) }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Notification | undefined> {
    return this._httpClient.get<Notification>({ url: `${this._url}/${id}` }).pipe(
      map<NotificationDTO, Notification>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredItems(q: Query<Notification>): Observable<Notification[]> {
    return this._httpClient.get<Notification[]>({ url: prepareURL(this._url, q) }).pipe(
      map<NotificationDTO[], Notification[]>((dto) => dto.map((d) => toModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  getFilteredList(q: Query<Notification>): Observable<ItemList<Notification>> {
    return this._httpClient
      .get<ItemList<Notification>>({
        url: prepareURL(this._url, q),
      })
      .pipe(
        map<ItemList<NotificationDTO>, ItemList<Notification>>((dto) => {
          const itemList = emptyList<Notification>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Notification>())
        })
      )
  }

  update(e: Notification): Observable<Notification | undefined> {
    return this._httpClient.put<Notification>({ url: this._url, body: fromModel(e) }).pipe(
      map<NotificationDTO, Notification>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  updateStatus(id: string, status: DeliveryStatus): Observable<Notification | undefined> {
    return this._httpClient.put<Notification>({ url: `${this._url}/${id}`, body: status }).pipe(
      map<NotificationDTO, Notification>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  updateStatusByMessage(id: string): Observable<Notification | undefined> {
    return this._httpClient.get<Notification>({ url: `${this._url + '-byMessage'}/${id}` }).pipe(
      map<NotificationDTO, Notification>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
