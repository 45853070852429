import { v4 as uuidv4 } from 'uuid'
import { UserDTO } from '../../users/models/User'
import { CircleWithCreator } from './CircleWithCreator'
import { CalendarConfigDTO, emptyCalendarConfigDTO } from './circleConfig/CalendarConfigDTO'
import { emptyPatientConfigDTO, PatientConfigDTO } from './circleConfig/PatientConfigDTO'
import {
  emptyParticipantConfigDTO,
  ParticipantConfigDTO,
} from './circleConfig/ParticipantConfigDTO'
import { emptyMenuConfigDTO, MenuConfigDTO } from './circleConfig/MenuConfigDTO'

export interface CircleWithCreatorDTO {
  id: string
  name: string
  specialities: string[]
  symptoms: string[]
  description: string
  createdAt: Date
  createdBy: UserDTO
  participantConfig: ParticipantConfigDTO
  menuConfig: MenuConfigDTO
  patientConfig: PatientConfigDTO
  rolesID: string[]
  calendarConfig: CalendarConfigDTO
  idAssignResources: string
  idAssignForms: string
}

export interface CircleAndDraftList {
  circles: CircleWithCreator[]
  drafts: CircleWithCreator[]
  count: number
}

export function emptyCircleWithCreatorDTO() {
  return {
    id: uuidv4(),
    name: '',
    specialities: [],
    symptoms: [],
    description: '',
    createdAt: new Date(),
    createdBy: '',
    participantConfig: emptyParticipantConfigDTO(),
    menuConfig: emptyMenuConfigDTO(),
    patientConfig: emptyPatientConfigDTO(),
    rolesID: [],
    calendarConfig: emptyCalendarConfigDTO(),
    idAssignResources: '',
    idAssignForms: '',
  }
}

export function fromModel(e: CircleWithCreator): CircleWithCreatorDTO {
  return {
    id: e.id,
    name: e.name,
    specialities: e.specialities,
    symptoms: e.symptoms,
    description: e.description,
    createdAt: e.createdAt,
    createdBy: e.createdBy,
    participantConfig: e.participantConfig,
    menuConfig: e.menuConfig,
    patientConfig: e.patientConfig,
    rolesID: e.rolesID,
    calendarConfig: e.calendarConfig,
    idAssignResources: e.idAssignResources,
    idAssignForms: e.idAssignForms,
  }
}

export function toModel(d: CircleWithCreatorDTO): CircleWithCreator {
  return new CircleWithCreator(d)
}
