import { Container } from '../../common/container/Container'

export type EpilepticalCrisisContainerConfig = {
  moduleFullUrl: string
}

export type EpilepticalCrisisProps = {
  parentContainer: Container
  config: EpilepticalCrisisContainerConfig
}

export const EPILEPTICAL_CRISIS_MODULE = Symbol('EPILEPTICAL_CRISIS_MODULE')

export const EPILEPTICAL_CRISIS_API_KEY = Symbol('EPILEPTICAL_CRISIS_API_KEY')

export const EPILEPTICAL_CRISIS_SERVICE_KEY = Symbol('EPILEPTICAL_CRISIS_SERVICE_KEY')

export const EPILEPTICAL_CRISIS_DETAILS_API_KEY = Symbol('EPILEPTICAL_CRISIS_DETAILS_API_KEY')

export const EPILEPTICAL_CRISIS_DETAILS_SERVICE_KEY = Symbol(
  'EPILEPTICAL_CRISIS_DETAILS_SERVICE_KEY'
)

export const USER_EPILEPTICAL_CONFIG_API_KEY = Symbol('USER_EPILEPTICAL_CONFIG_API_KEY')

export const USER_EPILEPTICAL_CONFIG_SERVICE_KEY = Symbol('USER_EPILEPTICAL_CONFIG_SERVICE_KEY')
