import { Container } from '../../common/container/Container'

export type QueryContainerConfig = {
  moduleFullUrl: string
}

export type QueryTypeProps = {
  parentContainer: Container
  config: QueryContainerConfig
}

export const QUERY_TYPE_MODULE = Symbol('QUERY_TYPE_MODULE')
export const QUERY_TYPE_API_KEY = Symbol('QUERY_TYPE_API_KEY')
export const QUERY_TYPE_SERVICE_KEY = Symbol('QUERY_TYPE_SERVICE_KEY')
