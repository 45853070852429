import { SharedWith } from '../models/SharedWith'
import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { SharedWithApi } from '../api/SharedWithApi'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { SharedWithDTO } from '../models/SharedWithDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface ISharedWithService extends IInit {
  getByID(id: string): Observable<SharedWith | undefined>

  getFilteredItems(q: Query<SharedWith>): Observable<SharedWith[]>

  add(e: SharedWithDTO): Observable<SharedWith | undefined>

  update(e: SharedWithDTO): Observable<SharedWith | undefined>

  delete(e: SharedWith): Observable<boolean>

  createNewIndividualShare(e: SharedWithDTO[]): Observable<SharedWith | undefined>
}

export class SharedWithService implements ISharedWithService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: SharedWithApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<SharedWithApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: SharedWithDTO): Observable<SharedWith | undefined> {
    return this._api.add(e)
  }

  delete(e: SharedWith): Observable<boolean> {
    return this._api.delete(e)
  }

  getByID(id: string | null): Observable<SharedWith | undefined> {
    return this._api.getByID(id)
  }

  getFilteredItems(q: Query<SharedWith>): Observable<SharedWith[]> {
    return this._api.getFilteredItems(q).pipe()
  }

  update(e: SharedWithDTO): Observable<SharedWith | undefined> {
    return this._api.update(e)
  }

  createNewIndividualShare(e: SharedWithDTO[]): Observable<SharedWith | undefined> {
    return this._api.createNewIndividualShare(e)
  }
}
