import { Query } from '../../../common/api/Query'

export type Related = {
  id: string
  kind: number
}

export type RelatedUserProps = {
  relatedUsers: Related[]
  userID: string
}

export class RelatedUser {
  private readonly _relatedUsers: Related[]
  private readonly _userID: string

  constructor(p: RelatedUserProps) {
    this._relatedUsers = p.relatedUsers
    this._userID = p.userID
  }

  get id(): string {
    return this._userID
  }

  get relatedUsers(): Related[] {
    return this._relatedUsers
  }
}

export class RelatedUserQuery extends Query<RelatedUser> {}
