import { Query } from '../../../common/api/Query'
import { v4 as uuidv4 } from 'uuid'

export type PositionTypeProps = {
  id?: string
  name: string
  idForm: string
  description: string
}

export class PositionType {
  private readonly _id: string
  private _name: string
  private _idForm: string
  private _description: string

  constructor(p: PositionTypeProps) {
    this._id = p.id || uuidv4()
    this._name = p.name
    this._idForm = p.idForm
    this._description = p.description
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get idForm(): string {
    return this._idForm
  }

  set idForm(value: string) {
    this._idForm = value
  }

  get description(): string {
    return this._description
  }

  set description(value: string) {
    this._description = value
  }
}

export class PositionTypeQuery extends Query<PositionType> {}
