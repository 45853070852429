export type EpilepticalCrisisDetailsProps = {
  id: string
  startDate: Date | undefined
  endDate: Date | undefined
  crisisType: number
  color: string
  observations: string
  epilepticalCrisisID: string
}

export class EpilepticalCrisisDetails {
  private _id: string
  private readonly _startDate: Date | undefined
  private readonly _endDate: Date | undefined
  private readonly _crisisType: number
  private readonly _color: string
  private readonly _observations: string
  private _epilepticalCrisisID: string

  constructor(p: EpilepticalCrisisDetailsProps) {
    this._id = p.id
    this._startDate = p.startDate
    this._endDate = p.endDate
    this._crisisType = p.crisisType
    this._color = p.color
    this._observations = p.observations
    this._epilepticalCrisisID = p.epilepticalCrisisID
  }

  get id(): string {
    return this._id
  }

  set id(id: string) {
    this._id = id
  }

  get startDate(): Date | undefined {
    return this._startDate
  }

  get endDate(): Date | undefined {
    return this._endDate
  }

  get crisisType(): number {
    return this._crisisType
  }

  get color(): string {
    return this._color
  }

  get observations(): string {
    return this._observations
  }

  get epilepticalCrisisID(): string {
    return this._epilepticalCrisisID
  }

  set epilepticalCrisisID(id: string) {
    this._epilepticalCrisisID = id
  }
}

export interface EpilepticalCrisisDetailsQuery {
  id: string
  epilepticalCrisisID: string
}
