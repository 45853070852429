export type PatientCalendarEventProps = {
  title: string
  month: number
}

export class PatientCalendarEvent {
  private readonly _title: string
  private readonly _month: number

  constructor(p: PatientCalendarEventProps) {
    this._title = p.title
    this._month = p.month
  }

  get title(): string {
    return this._title
  }

  get month(): number {
    return this._month
  }
}
