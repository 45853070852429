import { LangType } from '../enums/LangType'

export type TagProps = {
  id: string
  lang: LangType
  name: string
  description: string
  isSection: boolean
  sectionSlug: string
  parentSection?: string
}

export class Tag {
  private readonly _id: string

  private readonly _lang: LangType
  private readonly _name: string
  private readonly _description: string
  private readonly _isSection: boolean
  private readonly _sectionSlug: string
  private readonly _parentSection: string | undefined

  constructor(props: TagProps) {
    this._id = props.id
    this._lang = props.lang
    this._name = props.name
    this._description = props.description
    this._isSection = props.isSection
    this._sectionSlug = props.sectionSlug
    this._parentSection = props.parentSection
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get description(): string {
    return this._description
  }

  get lang(): LangType {
    return this._lang
  }

  get isSection(): boolean {
    return this._isSection
  }

  get sectionSlug(): string {
    return this._sectionSlug
  }

  get parentSection(): string | undefined {
    return this._parentSection
  }
}
