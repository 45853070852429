import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Goals as G } from '../../features/patient-data'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export const GoalsForm = (props: RouteProps) => {
  const { id } = useParams()
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <G />
    </>
  )
}
