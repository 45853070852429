import { Container } from '../../common/container/Container'

export type VacusanContainerConfig = {
  moduleFullUrl: string
}

export type VacusanProps = {
  parentContainer: Container
  config: VacusanContainerConfig
}

export const VACUSAN_MODULE = Symbol('VACUSAN_MODULE')
export const VACUSAN_API_KEY = Symbol('VACUSAN_API_KEY')
export const VACUSAN_SERVICE_KEY = Symbol('VACUSAN_SERVICE_KEY')
