export type SharedWithProps = {
  userId: string
  fileId: string
  userCircleId: string
  creatorId: string
}

export class SharedWith {
  private readonly _userId: string
  private readonly _fileId: string
  private readonly _userCircleId: string
  private readonly _creatorId: string

  constructor(p: SharedWithProps) {
    this._fileId = p.fileId
    this._userId = p.userId
    this._userCircleId = p.userCircleId
    this._creatorId = p.creatorId
  }

  get fileId(): string {
    return this._fileId
  }

  get userId(): string {
    return this._userId
  }

  get userCircleId(): string {
    return this._userCircleId
  }

  get creatorId(): string {
    return this._creatorId
  }
}

export interface SharedWithQuery {
  userId: string
  fileId: string
  circleID: string
  userCircleId: string
  resourcesIDs: string[]
  searchName?: string
}
