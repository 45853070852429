import { Query } from '../../../common/api/Query'
import { Circle } from '../../circle/models/Circle'

export type UserCircleWithCircleProps = {
  id: string
  circle: Circle
  userID: string
  familiarsID: string[]
  profSmsID: string[]
  profExtID: string[]
  careersID: string[]
}

export class UserCircleWithCircle {
  private readonly _id: string
  private _circle: Circle
  private _userID: string
  private _familiarsID: string[]
  private _profSmsID: string[]
  private _profExtID: string[]
  private _careersID: string[]
  userCircleSelected: any

  constructor(p: UserCircleWithCircleProps) {
    this._id = p.id
    this._circle = p.circle
    this._userID = p.userID
    this._familiarsID = p.familiarsID
    this._profSmsID = p.profSmsID
    this._profExtID = p.profExtID
    this._careersID = p.careersID
  }

  get id(): string {
    return this._id
  }

  get circle(): Circle {
    return this._circle
  }

  set circle(value: Circle) {
    this._circle = value
  }

  get userID(): string {
    return this._userID
  }

  set userID(value: string) {
    this._userID = value
  }

  get familiarsID(): string[] {
    return this._familiarsID
  }

  set familiarsID(value: string[]) {
    this._familiarsID = value
  }

  get profSmsID(): string[] {
    return this._profSmsID
  }

  set profSmsID(value: string[]) {
    this._profSmsID = value
  }

  get profExtID(): string[] {
    return this._profExtID
  }

  set profExtID(value: string[]) {
    this._profExtID = value
  }

  get careersID(): string[] {
    return this._careersID
  }

  set careersID(value: string[]) {
    this._careersID = value
  }
}

export class UserCircleQuery extends Query<UserCircleWithCircle> {
  userID?: string
  roleID?: string[]
}
