import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LegalWarning } from '../../features/confidentialityTerm'

const LegalAdvice = () => {
  const { t } = useTranslation()
  return (
    <>
      <CurrentNavHeaderEsfera title={t('legalAdvice')} />
      <LegalWarning />
    </>
  )
}

export default LegalAdvice
