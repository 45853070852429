import { Container, ContainerItem } from '../common/container/Container'
import { ContactService } from '../modules/messenger_consulta/services/ContactService'
import { AutoMessageService } from '../modules/messenger_consulta/services/AutoMessageService'
import { ConsultaService } from '../modules/messenger_consulta/services/ConsultaService'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { ConsultaApi } from '../modules/messenger_consulta/api/ConsultaApi'
import { ContactApi } from '../modules/messenger_consulta/api/ContactApi'
import { AutoMessageApi } from '../modules/messenger_consulta/api/AutoMessageApi'
import { ProfessionalQueryApi } from '../modules/messenger_consulta/api/ProfessionalQueryApi'
import { ProfessionalQueryService } from '../modules/messenger_consulta/services/ProfessionalQueryService'

import {
  CONTACT_CONSULTA_API_KEY,
  CONTACT_CONSULTA_SERVICE_KEY,
  CONSULTA_API_KEY,
  CONSULTA_SERVICE_KEY,
  MESSENGER_CONSULTA_MODULE,
  MessengerConsultaProps,
  AUTOMESSAGE_SERVICE_KEY,
  AUTOMESSAGE_API_KEY,
  PROFESSIONAL_QUERY_API_KEY,
  PROFESSIONAL_QUERY_SERVICE_KEY,
} from '../modules/messenger_consulta/container'

let container: Container

function init(p: MessengerConsultaProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(CONTACT_CONSULTA_API_KEY, new ContainerItem({ instance: new ContactApi() }))
  items.set(CONSULTA_API_KEY, new ContainerItem({ instance: new ConsultaApi() }))
  items.set(AUTOMESSAGE_API_KEY, new ContainerItem({ instance: new AutoMessageApi() }))
  items.set(PROFESSIONAL_QUERY_API_KEY, new ContainerItem({ instance: new ProfessionalQueryApi() }))

  items.set(
    CONTACT_CONSULTA_SERVICE_KEY,
    new ContainerItem({ instance: new ContactService({ apiKey: CONTACT_CONSULTA_API_KEY }) })
  )
  items.set(
    CONSULTA_SERVICE_KEY,
    new ContainerItem({ instance: new ConsultaService({ apiKey: CONSULTA_API_KEY }) })
  )
  items.set(
    AUTOMESSAGE_SERVICE_KEY,
    new ContainerItem({ instance: new AutoMessageService({ apiKey: AUTOMESSAGE_API_KEY }) })
  )
  items.set(
    PROFESSIONAL_QUERY_SERVICE_KEY,
    new ContainerItem({
      instance: new ProfessionalQueryService({ apiKey: PROFESSIONAL_QUERY_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getMessengerConsultaContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[MESSENGER_CONSULTA_MODULE],
      },
    })
  }

  return container
}
