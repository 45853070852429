import { Vaccine, VaccinesQuery } from '../models/Vaccines'
import { Observable, of } from 'rxjs'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Query, QueryParam } from 'common/api/Query'
import { toModel, VaccinesDTO } from '../models/VaccinesDTO'
import { Container, IInit } from 'common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from 'common/api/HTTPClient'
import { IStatusService } from 'common/status/StatusService'
import { STATUS_SERVICE_KEY } from 'container/app'
import { VaccinesContainerConfig } from '../container'
import { catchError, map, switchMap } from 'rxjs/operators'
import { prepareURL } from 'common/api/http-helpers'

export interface IVaccinesApi extends IInit {
  getByID(id: string): Observable<Vaccine | undefined>
  getFilteredList(q: Query<VaccinesQuery>): Observable<ItemList<Vaccine>>
  add(e: VaccinesDTO): Observable<Vaccine | undefined>
  update(e: VaccinesDTO): Observable<Vaccine | undefined>
  delete(id: string): Observable<boolean>
}

export class VaccinesApi implements IVaccinesApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as VaccinesContainerConfig).moduleFullUrl + '/vaccines'
  }

  getByID(id: string): Observable<Vaccine | undefined> {
    return this._httpClient.get<Vaccine>({ url: this._url + '/' + id }).pipe(
      map<VaccinesDTO, Vaccine>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  add(e: VaccinesDTO): Observable<Vaccine | undefined> {
    return this.checkIfTvacExists(e.tvac).pipe(
      switchMap((exists) => {
        if (exists) {
          return of(undefined) // Notificar que tvac ya existe
        } else {
          return this._httpClient.post<Vaccine>({ url: this._url, body: e }).pipe(
            map<VaccinesDTO, Vaccine>((d) => toModel(d)),
            catchError((err) => {
              this._statusService.sendStatus({ variant: 'error', error: err })
              return of(undefined)
            })
          )
        }
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getFilteredList(q: Query<VaccinesQuery>): Observable<ItemList<Vaccine>> {
    return this._httpClient.get<ItemList<Vaccine>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<VaccinesDTO>, ItemList<Vaccine>>((dto) => {
        const itemList = emptyList<Vaccine>()
        itemList.items = dto.items.map((d) => toModel(d))
        itemList.count = dto.count
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Vaccine>())
      })
    )
  }

  update(e: VaccinesDTO): Observable<Vaccine | undefined> {
    return this.checkIfTvacExists(e.tvac).pipe(
      switchMap((exists) => {
        if (exists) {
          return of(undefined) // Notificar que tvac ya existe
        } else {
          return this._httpClient.put<Vaccine>({ url: this._url, body: e }).pipe(
            map<VaccinesDTO, Vaccine>((d) => toModel(d)),
            catchError((err) => {
              this._statusService.sendStatus({ variant: 'error', error: err })
              return of(undefined)
            })
          )
        }
      })
    )
  }

  // Nuevo método para verificar si tvac ya existe
  private checkIfTvacExists(tvac: number): Observable<boolean> {
    const query = new Query<VaccinesQuery>({
      query: [new QueryParam('tvac', tvac)],
    })

    return this._httpClient.get<ItemList<Vaccine>>({ url: prepareURL(this._url, query) }).pipe(
      map((result) => result.count > 0),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }
}
