import React, { useState } from 'react'
import { Tooltip, Typography } from '@mui/material'
import { LoggedUser } from '../../modules/users/models/LoggedUser'
import { Form } from '../../modules/forms/models/Form'

type DescriptionCellProps = {
  description: string | undefined
  title: string | undefined
  creator: string
  subscribers: string | undefined
  id: string | undefined
  loggedUser: LoggedUser | undefined
  isExtern: boolean | undefined
  formsSubscribed: Form[]
}

export const DescriptionCell = ({
  description,
  title,
  creator,
  subscribers,
  id,
  loggedUser,
  isExtern,
  formsSubscribed,
}: DescriptionCellProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const formSubscribers = formsSubscribed.filter((form) => form.id === id).length > 0

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const getColor = () => {
    if (!(!formSubscribers && !isExtern && creator !== loggedUser?.id)) {
      return 'green'
    }
    return 'black'
  }

  const formatText = (text: string) => {
    const lines = text.split('\n') // Dividir el texto en líneas usando saltos de línea

    return lines.map((line, index) => {
      if (line.length <= 65) {
        return (
          <Typography style={{ fontFamily: 'Poppins' }} component="div" key={index}>
            {line}
          </Typography>
        )
      }

      const words = line.split(' ')
      let currentLine = ''

      const formattedLines = words.reduce((result, word) => {
        if (currentLine.length + word.length <= 65) {
          currentLine += word + ' '
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          result.push(currentLine.trim())
          currentLine = word + ' '
        }
        return result
      }, [])

      if (currentLine.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        formattedLines.push(currentLine.trim())
      }

      return formattedLines.map((formattedLine, lineIndex) => (
        <Typography style={{ fontFamily: 'Poppins' }} component="div" key={`${index}-${lineIndex}`}>
          {formattedLine}
        </Typography>
      ))
    })
  }

  return (
    <span
      style={{
        cursor: 'default',
        whiteSpace: 'pre-wrap',
        maxHeight: isHovered ? 'none' : '3em',
        overflow: 'hidden',
        fontFamily: 'Poppins',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              border: 'solid skyblue 1px',
              maxWidth: 700,
              overflow: 'hidden',
              whiteSpace: 'pre-line',
              fontFamily: 'Poppins',
            },
          },
        }}
        title={<div>{formatText(description ?? '')}</div>}
        placement="top"
        open={isHovered}
        onClose={handleMouseLeave}
      >
        <span
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ color: getColor(), fontFamily: 'Poppins' }}
        >
          {title}
        </span>
      </Tooltip>
    </span>
  )
}
