import { Circle } from './Circle'
import { v4 as uuidv4 } from 'uuid'
import {
  emptyParticipantConfigDTO,
  ParticipantConfigDTO,
  fromModel as participantConfigFromModel,
} from './circleConfig/ParticipantConfigDTO'
import {
  emptyMenuConfigDTO,
  MenuConfigDTO,
  fromModel as menuConfigFromModel,
} from './circleConfig/MenuConfigDTO'
import {
  emptyPatientConfigDTO,
  PatientConfigDTO,
  fromModel as patientConfigFromModel,
} from './circleConfig/PatientConfigDTO'
import {
  CalendarConfigDTO,
  emptyCalendarConfigDTO,
  fromModel as calendarConfigFromModel,
} from './circleConfig/CalendarConfigDTO'
import {
  emptyQueryConfigDTO,
  QueryConfigDTO,
  fromModel as queryConfigFromModel,
} from './circleConfig/QueryConfigDTO'
import { SymptomConfig } from './circleConfig/SymptomConfig'

export interface CircleDTO {
  id: string
  name: string
  specialities: string[]
  symptoms: SymptomConfig[]
  description: string
  createdAt: Date
  createdBy: string
  participantConfig: ParticipantConfigDTO
  menuConfig: MenuConfigDTO
  patientConfig: PatientConfigDTO
  queryConfig: QueryConfigDTO
  calendarConfig: CalendarConfigDTO
  idAssignResources: string
  idAssignForms: string
}

export function emptyCircleDTO() {
  return {
    id: uuidv4(),
    name: '',
    specialities: [],
    symptoms: [],
    description: '',
    createdAt: new Date(),
    createdBy: '',
    participantConfig: emptyParticipantConfigDTO(),
    menuConfig: emptyMenuConfigDTO(),
    queryConfig: emptyQueryConfigDTO(),
    patientConfig: emptyPatientConfigDTO(),
    calendarConfig: emptyCalendarConfigDTO(),
    idAssignResources: '',
    idAssignForms: '',
  }
}

export function fromModel(e: Circle): CircleDTO {
  return {
    id: e.id,
    name: e.name,
    specialities: e.specialities,
    symptoms: e.symptoms,
    description: e.description,
    createdAt: e.createdAt,
    createdBy: e.createdBy,
    participantConfig: participantConfigFromModel(e.participantConfig),
    menuConfig: menuConfigFromModel(e.menuConfig),
    queryConfig: queryConfigFromModel(e.queryConfig),
    patientConfig: patientConfigFromModel(e.patientConfig),
    calendarConfig: calendarConfigFromModel(e.calendarConfig),
    idAssignResources: e.idAssignResources,
    idAssignForms: e.idAssignForms,
  }
}

export function toModel(d: CircleDTO): Circle {
  return new Circle(d)
}
