import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { IPatientCalendarApi } from '../api/PatientCalendarApi'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { PatientCalendar } from '../models/PatientCalendar'
import { PatientCalendarDTO } from '../models/PatientCalendarDTO'

type Props = {
  apiKey: symbol
}

export interface IPatientCalendarService extends IInit {
  getByID(id: string): Observable<PatientCalendar | undefined>
  getFilteredList(q: Query<PatientCalendar>): Observable<ItemList<PatientCalendar>>
  add(e: PatientCalendar): Observable<PatientCalendar | undefined>
}

export class PatientCalendarService implements IPatientCalendarService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IPatientCalendarApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IPatientCalendarApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: PatientCalendarDTO): Observable<PatientCalendar | undefined> {
    return this._api.add(e)
  }

  getByID(id: string): Observable<PatientCalendar | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<PatientCalendar>): Observable<ItemList<PatientCalendar>> {
    return this._api.getFilteredList(q)
  }
}
