import { Dentist } from './Dentist'
import { v4 as uuidv4 } from 'uuid'

export interface DentistDTO {
  id: string | undefined
  name: string
  address: string
  phone: string
  email: string
  longitude: number
  latitude: number
  web: string
  isPublic: boolean
  information: string
}

export function emptyDentistDTO() {
  return {
    id: uuidv4(),
    name: '',
    address: '',
    phone: '',
    email: '',
    longitude: 0.0,
    latitude: 0.0,
    web: '',
    isPublic: false,
    information: '',
  }
}

export function fromModel(d: Dentist): DentistDTO {
  return {
    id: d.id,
    name: d.name,
    address: d.address,
    phone: d.phone,
    email: d.email,
    longitude: d.longitude,
    latitude: d.latitude,
    web: d.web,
    isPublic: d.isPublic,
    information: d.information,
  }
}

export function toModel(d: DentistDTO): Dentist {
  return new Dentist(d)
}
