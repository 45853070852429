import { Query } from '../../../common/api/Query'
import { v4 as uuidv4 } from 'uuid'

export type ProfessionalTypeProps = {
  id?: string
  name: string
  description: string
}

export class ProfessionalType {
  private readonly _id: string
  private _name: string
  private readonly _description: string

  constructor(p: ProfessionalTypeProps) {
    this._id = p.id || uuidv4()
    this._name = p.name
    this._description = p.description
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get description(): string {
    return this._description
  }
}

export class ProfessionalTypeQuery extends Query<ProfessionalType> {
  // eslint-disable-next-line no-restricted-globals
  name: string | undefined
}
