import { Observable, of } from 'rxjs'
import { Container, IInit } from 'common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from 'common/api/HTTPClient'
import { IStatusService } from 'common/status/StatusService'
import { STATUS_SERVICE_KEY } from 'container/app'
import { VacusanContainerConfig } from '../container'
import { catchError } from 'rxjs/operators'
import { Vacusan } from '../models/Vacusan'

export interface IVacusanApi extends IInit {
  getByCIPA(id: string): Observable<Vacusan | undefined>
}

export class VacusanApi implements IVacusanApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as VacusanContainerConfig).moduleFullUrl
  }

  getByCIPA(cipa: string): Observable<Vacusan | undefined> {
    return this._httpClient.get<Vacusan>({ url: this._url + '/' + cipa }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
