import { Event } from '../models/Event'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { IEventApi } from '../api/EventApi'
import { ItemList } from '../../../common/models/ItemList'
import { EventDTO } from '../models/EventDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { AllCalendarEvent } from '../models/AllCalendarEvent'

type Props = {
  apiKey: symbol
}

export interface IEventService extends IInit {
  getByID(id: string): Observable<Event | undefined>

  getFilteredList(q: Query<Event>, userId: string): Observable<ItemList<Event>>

  add(e: Event): Observable<Event | undefined>

  update(e: EventDTO): Observable<Event | undefined>

  delete(id: string): Observable<boolean>

  createPDF(q: Query<Event>, userID: string): Observable<any | undefined>

  getAllCalendarEvents(q: Query<Event>, userId: string): Observable<AllCalendarEvent[]>
}

export class EventService implements IEventService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IEventApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IEventApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: EventDTO): Observable<Event | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Event | undefined> {
    return this._api.getByID(id)
  }

  // TODO add get startdate query

  getFilteredList(q: Query<Event>, userId: string): Observable<ItemList<Event>> {
    return this._api.getFilteredList(q, userId)
  }

  update(e: EventDTO): Observable<Event | undefined> {
    return this._api.update(e)
  }

  createPDF(q: Query<Event>, userID: string): Observable<any | undefined> {
    return this._api.createPDF(q, userID)
  }

  getAllCalendarEvents(q: Query<Event>, userId: string): Observable<AllCalendarEvent[]> {
    return this._api.getAllCalendarEvents(q, userId)
  }
}
