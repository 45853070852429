import { WearableType } from '../enums/WearableType'

export type GoalProps = {
  type: WearableType
  min: number
  max?: number
  userID: string
}

export class Goal {
  private _type: WearableType
  private _min: number
  private _max?: number
  private _userID: string

  constructor(g: GoalProps) {
    this._type = g.type
    this._min = g.min
    this._max = g.max
    this._userID = g.userID
  }

  get type(): WearableType {
    return this._type
  }

  set type(value: WearableType) {
    this._type = value
  }

  get min(): number {
    return this._min
  }

  set min(value: number) {
    this._min = value
  }

  get max(): number | undefined {
    return this._max
  }

  set max(value: number | undefined) {
    this._max = value
  }

  get userID(): string {
    return this._userID
  }

  set userID(value: string) {
    this._userID = value
  }
}

export interface GoalQuery {}
