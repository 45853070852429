import React from 'react'
import { AppBar, Box } from '@mui/material'
import style from './HeaderEsfera.module.css'
import { useTranslation } from 'react-i18next'
import logoEsfera from '../../assets/esfera/logo-esfera.svg'
import concierge from '../../assets/brand_logo/conserjeria.png'
import { Divider } from '@mui/material'
import murciaHealth from '../../assets/brand_logo/murciasalud.png'
import { NavigationEsfera } from './NavigationEsfera'

type HeaderProps = {
  login: string
  username: string
  gender: number
  dateOfBirth: string
  dni: string
  cip: string
  setSelectedMenu: (menuSelected: string) => void
  isChooseRole: (choose: boolean) => void
  selectedMenu: string
  handleRedirectToSesamo: () => void
}

export function HeaderEsfera(props: HeaderProps) {
  const { t } = useTranslation()

  return (
    <Box className={style.header} display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" className={style.headerTop}>
        <Box className={style.headerTopLeft}>
          <img src={logoEsfera} alt={t('logo')} />
        </Box>
        <Box className={style.headerTopRight}>
          <img src={concierge} alt={t('concierge')} style={{ height: '29px', width: '59px' }} />
          <Divider
            orientation={'vertical'}
            style={{ height: '28px', marginRight: 31, marginLeft: 18, borderColor: '#68B3E0' }}
          />
          <img
            src={murciaHealth}
            alt={t('murciaHealth')}
            style={{ height: '29px', width: '162px' }}
          />
        </Box>
      </Box>
      <AppBar elevation={0} position="static" className={style.headerNav}>
        <Box className={style.containerNav}>
          <NavigationEsfera
            login={props.login}
            username={props.username}
            gender={props.gender}
            dateOfBirth={props.dateOfBirth}
            dni={props.dni}
            cip={props.cip}
            selectedMenu={props.selectedMenu}
            isChooseRole={props.isChooseRole}
            handleRedirectToSesamo={props.handleRedirectToSesamo}
          />
        </Box>
      </AppBar>
    </Box>
  )
}
