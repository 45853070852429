import { CalendarNotificationDTO } from './Notification'
import { EventCategory } from '../enums/EventCategory'
import { Event as E } from './Event'
import { v4 as uuidv4 } from 'uuid'

export interface EventDTO {
  id: string | undefined
  title: string
  description: string
  startDate: Date
  finishDate: Date
  startCron: Date
  finishCron: Date
  repeatingUntil: Date | undefined
  repeating: string
  place: string
  ownerID: string
  creatorID: string
  isOwnerBusy: boolean
  eventCategory: EventCategory
  parentID: string
  users: string[]
  files: string[] | undefined
  notifications: CalendarNotificationDTO[] | undefined
}

export function emptyEventDTO(date: Date | undefined) {
  return {
    id: uuidv4(),
    title: '',
    description: '',
    startDate: date ?? new Date(),
    finishDate: date ?? new Date(),
    startCron: date ?? new Date(),
    finishCron: date ?? new Date(),
    repeatingUntil: undefined,
    repeating: '0 0 0 0 0',
    place: '',
    ownerID: '',
    creatorID: '',
    isOwnerBusy: false,
    eventCategory: EventCategory.Others,
    parentID: '',
    users: [],
    files: [],
    notifications: [],
  }
}

export function fromModel(e: E): EventDTO {
  return {
    id: e.id,
    title: e.title,
    description: e.description,
    startDate: e.startDate,
    finishDate: e.finishDate,
    startCron: e.startCron,
    finishCron: e.finishCron,
    repeatingUntil: e.repeatingUntil,
    repeating: e.repeating,
    place: e.place,
    ownerID: e.ownerID,
    creatorID: e.creatorID,
    isOwnerBusy: e.isOwnerBusy,
    eventCategory: e.eventCategory,
    parentID: e.parentID,
    users: e.users,
    files: e.files,
    notifications: e.notifications,
  }
}

export function toModel(d: EventDTO): E {
  return new E(d)
}
