import React, { useEffect, useRef } from 'react'
import { Message } from '../../modules/messenger/models/Message'
import { Avatar, Box } from '@mui/material'
import { Link } from 'react-router-dom'
import style from './Messages.module.css'
import { useTranslation } from 'react-i18next'
import { dateToDateTimeWithoutSecondsString } from '../../common/utils/date'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { viewFile } from '../../common/utils/file'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Description } from '@mui/icons-material'
import {
  ConversationUser,
  ConversatonUserGender,
} from '../../modules/messenger/models/ConversationUser'
import womanIcon from '../../assets/chatIcons/woman.svg'
import maleIcon from '../../assets/chatIcons/male.svg'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const filesService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)

type MessagesProps = {
  messages: Message[]
  conversationUsers: ConversationUser[]
}

export function Messages(props: MessagesProps): JSX.Element {
  const loggedUser = loggedUserService.get()

  const { t } = useTranslation()

  const divMessagesScroll = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (divMessagesScroll.current !== null) {
      divMessagesScroll.current.scrollTop = divMessagesScroll.current.scrollHeight
    }
  }, [props.messages])

  const formattedDate = (createdAt: Date): string => dateToDateTimeWithoutSecondsString(createdAt)

  const getCorrectMessage = (text: string) => {
    const result = text.split('*')
    return result.length === 1 ? (
      <>{text}</>
    ) : (
      <>
        <Description />
        <Link to={'.'} onClick={() => viewResource(result[1])}>
          {result[2]}
        </Link>
      </>
    )
  }

  const viewResource = (id: string) => {
    filesService.downloadByID(id).subscribe((res) => {
      if (res) {
        viewFile(res)
      }
    })
  }
  console.log('OJO', props.conversationUsers)

  return (
    <div className={getDivMessagesScrollStyle(props.messages.length)} ref={divMessagesScroll}>
      {!props.messages.length ? (
        <Box component="h3">{t('noMessages')}</Box>
      ) : (
        props.messages.map((m) => (
          <Box
            key={m.id}
            className={style.messageContainer}
            display="flex"
            flexDirection="column"
            alignItems={m.author === loggedUser?.id ? 'flex-end' : 'flex-start'}
          >
            <Box key={m.id} display={'flex'} flexDirection={'row'}>
              {loggedUser?.id !== m.author && (
                <Avatar
                  className={style.avatar}
                  src={
                    props.conversationUsers.find((u) => u.userID === m.author)?.userGender ===
                    ConversatonUserGender.female
                      ? womanIcon
                      : maleIcon
                  }
                />
              )}
              <Box className={getMessageContentStyle(loggedUser?.id ?? '', m.author)}>
                <p className={style.textStyle}>{getCorrectMessage(m.text)}</p>
              </Box>
              {loggedUser?.id === m.author && (
                <Avatar
                  className={style.avatar}
                  src={
                    props.conversationUsers.find((u) => u.userID === m.author)?.userGender ===
                    ConversatonUserGender.female
                      ? womanIcon
                      : maleIcon
                  }
                />
              )}
            </Box>

            <Box component="span">{formattedDate(m.createdAt)}</Box>
          </Box>
        ))
      )}
    </div>
  )
}

const getMessageContentStyle = (loggedUsername: string, author: string) =>
  `${style.messageContent} ${
    author === loggedUsername ? style.messageContentSent : style.messageContentReceived
  }`

const getDivMessagesScrollStyle = (messagesLength: number) =>
  `${style.divMessagesScroll} ${messagesLength === 0 ? style.divMessagesScrollEmpty : ''}`
