import { Box, Grid, Modal } from '@mui/material'
import GenericCard from '../../../components/generic-card/GenericCard'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import icoTreatments from '../../../assets/esfera/patient-data/medication.svg'
import icoTreatmentsHover from '../../../assets/esfera/patient-data/medication-hover.svg'
import icoSintoma from '../../../assets/esfera/patient-data/disease.svg'
import icoSintomaHover from '../../../assets/esfera/patient-data/disease-hover.svg'
import icoPersonalEvent from '../../../assets/esfera/cards/personal-event-icon.svg'
import icoPersonalEventHover from '../../../assets/esfera/cards/personal-event-icon-hover.svg'
import styles from './NewEventModal.module.css'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { useGetActiveUser } from '../../../hooks/get-active-user/useGetActiveUser'
import PainScale from '../../../assets/calendar/PainScale.svg'
import PainScaleHover from '../../../assets/calendar/PainScaleHover.svg'
import Epilectic from '../../../assets/calendar/Epilectic.svg'
import EpilecticHover from '../../../assets/calendar/EpilecticHover.svg'
import BloodScale from '../../../assets/calendar/BloodScale.svg'
import BloodScaleHover from '../../../assets/calendar/BloodScaleHover.svg'
import Poo from '../../../assets/calendar/Poo.svg'
import PooHover from '../../../assets/calendar/PooHover.svg'

type EventModalProps = {
  open: boolean
  close: () => void
  onClick: (name: string) => void
  id?: string
  name: string
  setName: (name: string) => void
  setIdIcon: (idIcon: string) => void
}
type EventModalItems = {
  name: string
  icon: string
  hoverIcon: string
}
const eventModalGenericItems: EventModalItems[] = [
  {
    name: 'personalEvent',
    icon: icoPersonalEvent,
    hoverIcon: icoPersonalEventHover,
  },
  {
    name: 'treatments',
    icon: icoTreatments,
    hoverIcon: icoTreatmentsHover,
  },
  {
    name: 'symptoms',
    icon: icoSintoma,
    hoverIcon: icoSintomaHover,
  },
]
export const NewEventModal: React.FC<EventModalProps> = ({
  open,
  close,
  onClick,
  name,
  setName,
  setIdIcon,
}) => {
  const { t } = useTranslation()
  const [activeItemName, setActiveItemName] = React.useState<string | null>(null)
  const { activeUser } = useGetActiveUser()
  const [realNameItemNameSpecialSymptom, setRealNameItemNameSpecialSymptom] = React.useState<
    string | null
  >(null)
  const [eventModalOthers, setEventModalOthers] = React.useState<EventModalItems[]>([])
  const handleMouseEnter = (name: string | null) => {
    setActiveItemName(name)
  }
  const handleMouseEnterIcon = (icon: string | null) => {
    setRealNameItemNameSpecialSymptom(icon)
  }
  const handleMouseLeave = (nullValue: null) => {
    setActiveItemName(nullValue)
  }

  useEffect(() => {
    const symptomAux: EventModalItems[] = []

    activeUser?.circle?.symptoms?.forEach((symptom) => {
      if (symptom.idIcon === '' || symptom.idIcon === null || symptom.idIcon === undefined) {
        return
      }
      if (symptom.idIcon === 'PainScale') {
        symptomAux.push({
          name: symptom.name,
          icon: PainScale,
          hoverIcon: PainScaleHover,
        })
      }
      if (symptom.idIcon === 'Epilectic') {
        symptomAux.push({
          name: symptom.name,
          icon: Epilectic,
          hoverIcon: EpilecticHover,
        })
      }
      if (symptom.idIcon === 'BloodScale') {
        symptomAux.push({
          name: symptom.name,
          icon: BloodScale,
          hoverIcon: BloodScaleHover,
        })
      }
      if (symptom.idIcon === 'Poo') {
        symptomAux.push({
          name: symptom.name,
          icon: Poo,
          hoverIcon: PooHover,
        })
      }
    })
    setEventModalOthers(symptomAux)
  }, [])

  return (
    <Box>
      <Modal
        className={`${styles.modal} ${
          eventModalOthers.length + eventModalGenericItems.length > 4 ? styles.modalLarge : ''
        }`}
        open={open}
        onClose={close}
      >
        <Box
          className={`${styles.modalContainer} ${
            eventModalOthers.length + eventModalGenericItems.length > 4
              ? styles.modalContainerLarge
              : ''
          }`}
        >
          <Grid
            ml={0}
            mt={0}
            container
            spacing={2}
            sx={{
              overflowY:
                eventModalOthers.length + eventModalGenericItems.length > 6 ? 'scroll' : 'hidden',
              scrollbarWidth: 'thin',
              scrollbarColor: 'transparent transparent',
              '::-webkit-scrollbar': {
                width: '5px',
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent',
                justifyContent: eventModalGenericItems.length < 4 ? 'space-around' : 'center',
              },
            }}
          >
            {eventModalGenericItems.map((item) => (
              <GenericCard
                margin={eventModalOthers.length > 0 || eventModalGenericItems.length > 3}
                key={item.name}
                isHover={activeItemName === item.name}
                title={t(item.name)}
                handleMouseEnter={() => handleMouseEnter(item.name)}
                handleMouseLeave={handleMouseLeave}
                itemName={activeItemName}
                onClick={() => onClick(item.name)}
              >
                <img
                  src={activeItemName === item.name ? item.hoverIcon : item.icon}
                  alt={activeItemName ?? ''}
                />
              </GenericCard>
            ))}

            {eventModalOthers.length > 0 &&
              eventModalOthers.map((item) => (
                <GenericCard
                  margin={eventModalOthers.length > 0}
                  key={item.icon}
                  // Los item.icon es el idIcon del sintoma y el item.name es el nombre del sintoma que le ha puesto el usuario
                  // el id es el icon para que se muestre en el modal y el nombre es el nombre del sintoma que le ha puesto el usuario
                  isHover={
                    activeItemName === item.icon && realNameItemNameSpecialSymptom === item.name
                  }
                  title={t(item.name)}
                  handleMouseEnter={() => {
                    handleMouseEnter(item.icon)
                    handleMouseEnterIcon(item.name)
                  }}
                  handleMouseLeave={handleMouseLeave}
                  itemName={activeItemName}
                  onClick={() => {
                    const iconName = item.icon.replace('/static/media/', '').split('.')[0]
                    const idIcon = item.icon.replace('/static/media/', '').split('.')[0]
                    onClick(iconName)
                    setName(item.name)
                    setIdIcon(idIcon)
                  }}
                >
                  <img
                    src={
                      activeItemName === item.icon && realNameItemNameSpecialSymptom === item.name
                        ? item.hoverIcon
                        : item.icon
                    }
                    alt={activeItemName ?? ''}
                  />
                </GenericCard>
              ))}
          </Grid>
          <Box className={styles.backButton}>
            <AppButton
              label={t('backOneToResources')}
              theme={ButtonTheme.NewPrimaryResponsive}
              handler={close}
              type={'button'}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
