import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { WearablePage } from '../../features/wearables'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export const Link = (props: RouteProps) => {
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <WearablePage />
    </>
  )
}
