export type SymptomProps = {
  id: string | undefined
  name: string
  commonSymptom: boolean
}

export class Symptom {
  private readonly _id: string | undefined
  private readonly _name: string
  private _commonSymptom: boolean

  constructor(p: SymptomProps) {
    this._id = p.id
    this._name = p.name
    this._commonSymptom = p.commonSymptom
  }

  get id(): string | undefined {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get commonSymptom(): boolean {
    return this._commonSymptom
  }

  set commonSymptom(commonSymptom: boolean) {
    this._commonSymptom = commonSymptom
  }
}

export interface SymptomQuery {
  name?: string
  creator?: string
  commonSymptom?: boolean
  ids?: string[]
  isSpecificCircle?: boolean
}
