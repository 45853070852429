import { Box, Divider, FormControl, Modal, Typography } from '@mui/material'
import style from './ActivePlan.module.css'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import React, { useState } from 'react'
import icoDelete from '../../../assets/buttons/close.svg'
import { useTranslation } from 'react-i18next'

type UnsubscribeActivePlanProps = {
  open: boolean
  handleClose: () => void
  handlerMessage: (message: string) => void
  handlerAccept: () => void
}

export function UnsubscribeActivePlan(props: UnsubscribeActivePlanProps) {
  const { t } = useTranslation()
  const messageArr = ['cantAssist', 'dontWantassist']
  const [currentMessage, setCurrentMessage] = useState<boolean[]>([true, false])

  const handleSelectMessage = (index: number, message: string) => {
    const auxArr = [false, false]
    auxArr[index] = true
    setCurrentMessage(auxArr)
    props.handlerMessage(message)
  }

  const handleClose = () => {
    setCurrentMessage([true, false])
    props.handleClose()
  }

  return (
    <Modal open={props.open} onClose={props.handleClose} className={style.modalMain}>
      <Box className={style.containerModal}>
        <Box className={style.contentHeader}>
          <img style={{ cursor: 'pointer' }} onClick={props.handleClose} src={icoDelete} />
        </Box>
        <Box>
          <Typography className={style.headerTitle}>{t('unsubscribeActivePlan')}</Typography>
          <Divider
            style={{
              marginRight: '15px',
              marginLeft: '15px',
              borderColor: '#f47105',
              marginBottom: '15px',
            }}
          />
        </Box>
        <FormControl className={style.content}>
          {messageArr.map((message, index) => (
            <label className={style.checkboxContainer} key={'message' + index}>
              <input
                type="radio"
                name="teleCall"
                className={style.check}
                disabled={false}
                onChange={() => handleSelectMessage(index, message)}
                checked={currentMessage[index]}
              />
              {t(message)}
            </label>
          ))}
        </FormControl>
        <Box className={style.containerRowButtonModal}>
          <AppButton
            theme={ButtonTheme.whiteAndBlue}
            type={'button'}
            label={t('back')}
            handler={handleClose}
          />
          <AppButton
            theme={ButtonTheme.newEvent}
            type={'button'}
            label={t('accept')}
            handler={props.handlerAccept}
          />
        </Box>
      </Box>
    </Modal>
  )
}
