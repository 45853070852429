import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  SPORT_CENTER_API_KEY,
  SPORT_CENTER_SERVICE_KEY,
  SPORT_CENTER_MODULE,
  SportCenterProps,
} from '../modules/sport-center/container'
import { SportCenterApi } from '../modules/sport-center/api/SportCenterApi'
import { SportCenterService } from '../modules/sport-center/services/SportCenterService'

let container: Container

function init(p: SportCenterProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(SPORT_CENTER_API_KEY, new ContainerItem({ instance: new SportCenterApi() }))

  items.set(
    SPORT_CENTER_SERVICE_KEY,
    new ContainerItem({ instance: new SportCenterService({ apiKey: SPORT_CENTER_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getSportCenterContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[SPORT_CENTER_MODULE],
      },
    })
  }

  return container
}
