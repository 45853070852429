import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { ContentProps } from '../modules/content/container'
import {
  EPILEPTICAL_CRISIS_API_KEY,
  EPILEPTICAL_CRISIS_DETAILS_API_KEY,
  EPILEPTICAL_CRISIS_DETAILS_SERVICE_KEY,
  EPILEPTICAL_CRISIS_MODULE,
  EPILEPTICAL_CRISIS_SERVICE_KEY,
  USER_EPILEPTICAL_CONFIG_API_KEY,
  USER_EPILEPTICAL_CONFIG_SERVICE_KEY,
} from '../modules/epileptical'
import { EpilepticalCrisisApi } from '../modules/epileptical/api/EpilepticalCrisisApi'
import { EpilepticalCrisisService } from '../modules/epileptical/services/EpilepticalCrisisService'
import { EpilepticalCrisisDetailsService } from '../modules/epileptical/services/EpilepticalCrisisDetailsService'
import { EpilepticalCrisisDetailsApi } from '../modules/epileptical/api/EpilepticalCrisisDetailsApi'
import { UserEpilepticalConfigApi } from '../modules/epileptical/api/UserEpilepticalConfigApi'
import { UserEpilepticalConfigService } from '../modules/epileptical/services/UserEpilepticalConfigService'

let container: Container

function init(p: ContentProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(EPILEPTICAL_CRISIS_API_KEY, new ContainerItem({ instance: new EpilepticalCrisisApi() }))

  items.set(
    EPILEPTICAL_CRISIS_SERVICE_KEY,
    new ContainerItem({
      instance: new EpilepticalCrisisService({ apiKey: EPILEPTICAL_CRISIS_API_KEY }),
    })
  )

  items.set(
    EPILEPTICAL_CRISIS_DETAILS_API_KEY,
    new ContainerItem({ instance: new EpilepticalCrisisDetailsApi() })
  )

  items.set(
    EPILEPTICAL_CRISIS_DETAILS_SERVICE_KEY,
    new ContainerItem({
      instance: new EpilepticalCrisisDetailsService({ apiKey: EPILEPTICAL_CRISIS_DETAILS_API_KEY }),
    })
  )

  items.set(
    USER_EPILEPTICAL_CONFIG_API_KEY,
    new ContainerItem({ instance: new UserEpilepticalConfigApi() })
  )

  items.set(
    USER_EPILEPTICAL_CONFIG_SERVICE_KEY,
    new ContainerItem({
      instance: new UserEpilepticalConfigService({ apiKey: USER_EPILEPTICAL_CONFIG_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getEpilepticalContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[EPILEPTICAL_CRISIS_MODULE],
      },
    })
  }

  return container
}
