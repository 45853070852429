import React, { CSSProperties, useEffect, useState } from 'react'
import { ReadingStatus, readingStatuses } from '../../modules/forms/enums/ReadingStatus'
import { SourceType } from '../../modules/notifications/enums/SourceType'
import styles from './List.module.css'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import { capitalize, reduceString } from '../../common/utils/strings'
import { useNavigate } from 'react-router-dom'
import {
  ROUTE_ARTICLES,
  ROUTE_CALENDAR,
  ROUTE_MESSENGER,
  ROUTE_PATIENT_FORMS,
  ROUTE_PATIENT_SYMPTOMS,
  ROUTE_QUERY,
  ROUTE_RESOURCES,
  ROUTE_TREATMENTS,
} from '../../routes/routes-constants'
import rightArrow from '../../assets/left_menu/ico-arrow-view.svg'
import { EventService } from '../../modules/calendar/services/EventService'
import { EVENT_SERVICE_KEY } from '../../modules/calendar'
import { UserFormService } from '../../modules/forms/services/UserFormService'
import { USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { getFormContainer } from '../../container/form-module'
import { getCalendarContainer } from '../../container/calendar-module'
import { Tag } from '../../modules/content/models/Tag'
import { Notification as N } from '../../modules/notifications/models/Notification'
import { getNotificationContainer } from '../../container/notification-module'
import { NotificationService } from '../../modules/notifications/services/NotificationService'
import { NOTIFICATION_SERVICE_KEY } from '../../modules/notifications'
import { DeliveryStatus } from '../../modules/notifications/enums/DeliveryStatus'
import { useGlobalRenderHeaderContext } from '../../common/utils/GlobalRenderHeader'

const calendarContainer = getCalendarContainer()
const eventService = calendarContainer.get<EventService>(EVENT_SERVICE_KEY)
const formContainer = getFormContainer()
const userFormService = formContainer.get<UserFormService>(USER_FORM_SERVICE_KEY)
const readingStatus = readingStatuses()
const notificationContainer = getNotificationContainer()
const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)

export type ActivityWidgetProps = {
  id: string | undefined
  source: SourceType
  title: string
  description?: string
  date: Date
  creator?: string
  url: string
  status?: ReadingStatus
  tags?: Tag[]
  not?: N
  handleSee?: (not: N) => void
}

export function ActivityWidget(props: ActivityWidgetProps): JSX.Element {
  const { t } = useTranslation()
  const [statusStyle, setStatusStyle] = useState<CSSProperties>()
  const [status, setStatus] = useState<ReadingStatus>(props.status ?? 1)
  const { render, setRender } = useGlobalRenderHeaderContext()
  const navigate = useNavigate()

  useEffect(() => {
    userFormService.getByID(props.url).subscribe((uf) => {
      uf && setStatus(uf.readingStatus)
    })
  }, [])

  useEffect(() => {
    if (status === ReadingStatus.Pending) {
      setStatusStyle({
        margin: '0 0 2px 10px',
        padding: '0spx 50px 0px 5px',
        backgroundColor: 'rgba(219,83,83,0.11)',
        color: '#BB3D3D',
        fontWeight: 'normal',
      })
    } else {
      setStatusStyle({
        margin: '0 0 2px 10px',
        padding: '0px 50px 0px 5px',
        backgroundColor: 'rgba(83,219,90,0.11)',
        color: '#10A64A',
        fontWeight: 'normal',
      })
    }
  }, [status])

  const handleOnClick = () => {
    if (props.not) {
      props.not.deliveryStatus = DeliveryStatus.Viewed
      const newNotification = new N({
        userCircleID: props.not.userCircleID || '',
        createdAt: props.not.createdAt,
        deliveryStatus: props.not.deliveryStatus,
        id: props.not.id,
        message: props.not.message,
        priorityLevel: props.not.priorityLevel,
        recipientID: props.not.recipientID,
        sendAt: props.not.sendAt,
        senderID: props.not.senderID,
        transportType: props.not.transportType,
        sourceType: props.not.sourceType,
        endDate: props.not.endDate,
        frequency: props.not.frequency,
        lastSendAt: props.not.lastSendAt,
      })
      notificationService.update(newNotification).subscribe()
    }

    setRender(!render)

    switch (props.source) {
      case SourceType.CalendarEventCreated:
      case SourceType.CalendarEventUpdated:
        eventService.getByID(props.url).subscribe((res) => {
          if (res) {
            navigate(ROUTE_CALENDAR, { state: { selectedDate: res.startDate } })
          }
        })
        break
      case SourceType.FilesNewCreated:
        navigate(ROUTE_RESOURCES)
        break
      case SourceType.ContentNewArticle:
        navigate(`${ROUTE_ARTICLES}/${props.url}`)
        break
      case SourceType.FormsNewUserForm:
        if (status === ReadingStatus.Completed) {
          navigate(`${ROUTE_PATIENT_FORMS}/${props.url}`, { state: { edit: false } })
          break
        }
        navigate(`${ROUTE_PATIENT_FORMS}/${props.url}`, { state: { edit: true } })
        break
      case SourceType.FormsUserFormFilled:
        navigate(`${ROUTE_PATIENT_FORMS}/${props.url}`, { state: { edit: false } })
        break
      case SourceType.MessengerMessageCreated:
        navigate(ROUTE_MESSENGER, { state: { conversationID: props.url } })
        break
      case SourceType.QueryMessageCreated:
        navigate(ROUTE_QUERY, { state: { conversationID: props.url } })
        break
      case SourceType.PatientSymptomCreated:
        navigate(ROUTE_PATIENT_SYMPTOMS)
        break
      case SourceType.TreatmentCreated:
        navigate(ROUTE_TREATMENTS)
        break
      case SourceType.NewRegisteredUser:
        if (props.handleSee && props.not) props.handleSee(props?.not)
        navigate(`${ROUTE_ARTICLES}/${props.url}`)
        break
    }
  }

  return (
    <Box className={styles.notificationBox}>
      {/* Uncomment below if needed */}
      {/* <Box display={'flex'} flexDirection={'row'} margin={'0 0 5px 10px'} padding={'5px'} flexGrow={1}>
    {getIcon(props.source)}
    {props.source && (
      <h2 style={{ color: '#0063A6', margin: '0 0 0 5px', padding: '0' }}>
        {t(sourceTypes()[props.source]?.toLowerCase())}
      </h2>
    )}
  </Box> */}
      <Box display={'flex'} flexDirection={'column'}>
        <h3 style={{ margin: '0 0 5px 10px', padding: '0', color: '#68b3e0' }}>
          {reduceString(props.title, 25)}
        </h3>
      </Box>
      <Box display={'flex'} flexDirection={'column'}>
        <h2
          style={{ margin: '0 0 5px 10px', padding: '0', fontWeight: 'normal', fontSize: '0.8rem' }}
        >
          {props.description ? reduceString(props.description, 90) : ''}
        </h2>
      </Box>
      <Box display={'flex'} flexDirection={'column'}>
        {props.source !== SourceType.NewRegisteredUser && (
          <h4 style={{ margin: '0 0 0 10px', padding: '0', fontSize: '0.8rem' }}>
            {props.creator}
          </h4>
        )}
        <h4 style={{ margin: '0 0 2px 10px', padding: '0' }}>
          {capitalize(dayjs(props.date).locale('es').format('DD/MM/YYYY'))}
        </h4>
      </Box>
      <Box className={styles.containerFooter} style={{ justifyContent: 'space-between' }}>
        <Box>
          {props.source === SourceType.FormsNewUserForm && (
            <h4 style={statusStyle}>{t(readingStatus[status].toLowerCase())}</h4>
          )}
        </Box>
        <Box
          style={{ transform: 'translate(-6vw,-4vh)', padding: '0', cursor: 'pointer' }}
          display={'flex'}
          flexDirection={'row'}
          className={styles.containerFooter}
          onClick={handleOnClick}
        >
          <h3 style={{ fontWeight: 'bold', margin: '0', padding: '0' }}>{t('see')}</h3>
          <img
            style={{ margin: '0 0 0 5px', padding: '0' }}
            src={rightArrow}
            alt={rightArrow}
            className={styles.arrowRight}
          />
        </Box>
      </Box>
    </Box>
  )
}
