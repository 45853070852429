import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import {
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users'
import { emptyUserDTO, UserDTO } from '../../modules/users/models/User'
import React, { useEffect, useState } from 'react'
import { Alert, Box, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import styles from './Editor.module.css'
import genericStyle from '../../common/utils/generic.module.css'
import { ROUTE_USER_PROFILE_EDIT } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Header } from '../../components/header/Header'
import { Query } from '../../common/api/Query'
import { RolesService } from '../../modules/users/services/RolesServices'
import { ProfessionalTypesService } from '../../modules/users/services/ProfessionalTypeService'
import { POSITIONTYPE_SERVICE_KEY, PROFESSIONALTYPE_SERVICE_KEY } from '../../modules/users'
import {
  emptyProfessionalTypeUserDTO,
  ProfessionalTypeUserDTO,
} from '../../modules/users/models/ProfessionalTypeUserDTO'
import { useGlobalContext } from '../../common/utils/GlobalRoleContext'
import { PositionType } from '../../modules/users/models/PositionType'
import { PositionTypesService } from '../../modules/users/services/PositionTypeService'
import {
  emptyProfessionalQueryDTO,
  fromModel,
  ProfessionalQueryDTO,
} from '../../modules/messenger_consulta/models/ProfessionalQueryDTO'
import { getMessengerConsultaContainer } from '../../container/messenger-consulta-module'
import { PROFESSIONAL_QUERY_SERVICE_KEY } from '../../modules/messenger_consulta'
import { ProfessionalQueryService } from 'modules/messenger_consulta/services/ProfessionalQueryService'
import { emptyPositionTypeDTO, PositionTypeDTO } from '../../modules/users/models/PositionTypeDTO'
import icoProfile from '../../assets/esfera/profile/profile.svg'
import {
  emptyProfessionalTypeDTO,
  ProfessionalTypeDTO,
} from '../../modules/users/models/ProfessionalTypeDTO'
import { useGetProfilePhoto } from '../../hooks/profilePhoto/useGetProfilePhoto'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const roleService = userContainer.get<RolesService>(ROLES_SERVICE_KEY)
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)
const positionTypeService = userContainer.get<PositionTypesService>(POSITIONTYPE_SERVICE_KEY)
const professionalQueryService = getMessengerConsultaContainer().get<ProfessionalQueryService>(
  PROFESSIONAL_QUERY_SERVICE_KEY
)

export function Profile() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()

  const [userToEdit, setUserToEdit] = useState<UserDTO>(emptyUserDTO())
  // const [role, setRole] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [roles, setRoles] = useState<Map<string, string>>(new Map())
  const [professionalPosition, setProfessionalPosition] = useState<PositionType[]>([])
  const [professionalTypeUser, setProfessionalTypeUser] = useState<ProfessionalTypeUserDTO>(
    emptyProfessionalTypeUserDTO()
  )
  const [professionalType, setProfessionalType] = useState<ProfessionalTypeDTO>(
    emptyProfessionalTypeDTO()
  )
  const [positionTypeUser, setPositionTypeUser] = useState<PositionTypeDTO>(emptyPositionTypeDTO())
  const [professionalQuery, setProfessionalQuery] = useState<ProfessionalQueryDTO>(
    emptyProfessionalQueryDTO()
  )
  const { isLoading, profilePhotoData } = useGetProfilePhoto()

  const { role } = useGlobalContext()

  useEffect(() => {
    roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        const tmpMap = new Map<string, string>()
        res.forEach((r) => tmpMap.set(r.id, r.name))
        setRoles(tmpMap)
      })
  }, [])

  useEffect(() => {
    userService.getByID(loggedUser?.id ?? '').subscribe((res) => {
      if (!res) {
        return
      }
      setUserToEdit(res.toDTO())
    })
  }, [roles])

  useEffect(() => {
    if (loggedUser)
      professionalQueryService.getByUserID(loggedUser?.id).subscribe((res) => {
        if (res) {
          setProfessionalQuery(fromModel(res))
        }
      })
  }, [])

  useEffect(() => {
    if (!loggedUser) return
    professionalTypeService.getUserProfessionalTypeByUserID(loggedUser?.id).subscribe((res) => {
      if (res) {
        setProfessionalTypeUser(res)
      }
    })
  }, [])

  useEffect(() => {
    if (!professionalTypeUser) return
    if (professionalTypeUser.professionalTypeID === '') return
    professionalTypeService.getByID(professionalTypeUser?.professionalTypeID).subscribe((res) => {
      if (res) {
        setProfessionalType(res)
      }
    })
  }, [professionalTypeUser])

  useEffect(() => {
    positionTypeService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        setProfessionalPosition(res)
      })
  }, [])

  useEffect(() => {
    positionTypeService.getPositionTypeByUserID(loggedUser?.id ?? '').subscribe((res) => {
      if (res) {
        setPositionTypeUser(res)
      }
    })
  }, [])

  useEffect(() => {
    setMessage('')
  }, [userToEdit])

  function formatDate(dateString: Date): string {
    const date = new Date(dateString)
    let month = '' + (date.getMonth() + 1)
    let day = '' + date.getDate()
    const year = date.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [day, month, year].join('-')
  }

  function calculateAge(dateString: Date): number {
    const birthDate = new Date(dateString)
    const today = new Date()
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }

  const goEdit = () => navigate(ROUTE_USER_PROFILE_EDIT)

  const hasRelevantRole = [
    'professionalSMS',
    'managerHealthChild',
    'managerActivePlan',
    'managerEpileptic',
  ].some((role) => loggedUser?.roles?.includes(role))
  const onlyCaseManager = ['managerHealthChild', 'managerActivePlan', 'managerEpileptic'].some(
    (role) => loggedUser?.roles?.includes(role)
  )

  return (
    <Box className={genericStyle.pageContainer}>
      <form onSubmit={() => {}}>
        <Box bgcolor="white" m="1rem">
          <Box className={styles.headerContainer}>
            <Header
              label={
                loggedUser?.roles?.includes('patient')
                  ? t('patientProfile')
                  : t('professionalProfile')
              }
            />
          </Box>
          <Box className={genericStyle.containerRow}>
            {isLoading ? (
              <Skeleton
                style={{ marginRight: '40px', marginLeft: '8px' }}
                variant="circular"
                width={100}
                height={100}
              />
            ) : (
              <Box className={styles.imagenRow}>
                <Box
                  style={{
                    backgroundColor: '#2C80BA',
                    borderRadius: '50%',
                    width: '100px',
                    height: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '40px',
                    marginLeft: '8px',
                  }}
                >
                  {profilePhotoData !== '' ? (
                    <>
                      <img
                        style={{
                          height: '110px',
                          width: '110px',
                          background: '#FFF',
                          borderRadius: 100,
                        }}
                        src={`data:image/jpeg;base64,${profilePhotoData}`}
                        alt={'selectedProfilePhoto'}
                      />
                    </>
                  ) : (
                    <img src={icoProfile} alt={icoProfile} />
                  )}
                </Box>
              </Box>
            )}
            <Box className={genericStyle.leftBar}>
              <Box
                mb={3}
                className={styles.textFieldBox}
                display={'flex'}
                justifyContent="space-between"
              >
                <p className={styles.label}>{t('fullName')}</p>
                <p className={styles.textResponsive}>
                  {userToEdit.name + ' ' + userToEdit.lastname}
                </p>
              </Box>

              {loggedUser?.roles?.includes('patient') && (
                <Box
                  mb={3}
                  className={styles.textFieldBox}
                  display={'flex'}
                  justifyContent="space-between"
                >
                  <p className={styles.label}>{t('birthdate')}</p>
                  <p className={styles.textResponsive}>{formatDate(userToEdit.birthDate)}</p>
                </Box>
              )}

              {loggedUser?.roles?.includes('patient') && (
                <Box
                  mb={3}
                  className={styles.textFieldBox}
                  display={'flex'}
                  justifyContent="space-between"
                >
                  <p className={styles.label}>{t('age')}</p>
                  <p className={styles.textResponsive}>{calculateAge(userToEdit.birthDate)}</p>
                </Box>
              )}

              {loggedUser?.roles?.includes('patient') && (
                <Box
                  mb={3}
                  className={styles.textFieldBox}
                  display={'flex'}
                  justifyContent="space-between"
                >
                  <p className={styles.label}>{t('dni')}</p>
                  <p className={styles.textResponsive}>{userToEdit.dni}</p>
                </Box>
              )}

              {loggedUser?.roles?.includes('patient') && (
                <Box
                  mb={3}
                  className={styles.textFieldBox}
                  display={'flex'}
                  justifyContent="space-between"
                >
                  <p className={styles.label}>{t('cip')}</p>
                  <p className={styles.textResponsive}>{userToEdit.cip}</p>
                </Box>
              )}

              <Box
                mb={3}
                className={styles.textFieldBox}
                display={'flex'}
                justifyContent="space-between"
              >
                <p className={styles.label}>{t('email')}</p>
                <p className={styles.textResponsive}>{userToEdit.email}</p>
              </Box>

              <Box
                mb={3}
                className={styles.textFieldBox}
                display={'flex'}
                justifyContent="space-between"
              >
                <p className={styles.label}>{t('phone')}</p>
                <p className={styles.textResponsive}>{userToEdit.phone}</p>
              </Box>

              {loggedUser?.roles?.includes('patient') && (
                <Box
                  mb={3}
                  className={styles.textFieldBox}
                  display={'flex'}
                  justifyContent="space-between"
                >
                  <p className={styles.label}>{t('role') + '*'}</p>
                  <p className={styles.textResponsive}>
                    {t(role && role.length > 1 ? role[1].name : role[0]?.name || '')}
                  </p>
                </Box>
              )}

              {hasRelevantRole && (
                <Box
                  mb={3}
                  className={styles.textFieldBox}
                  display={'flex'}
                  justifyContent="space-between"
                >
                  <p className={styles.label}>{t('role') + '*'}</p>
                  <p className={styles.textResponsive}>
                    {role.length && role.find((r) => r.name === 'professionalSMS')
                      ? t(role && role.length > 1 ? role[1].name : role[0]?.name || '')
                      : t('caseManager')}
                  </p>
                </Box>
              )}

              {onlyCaseManager && (
                <Box
                  mb={3}
                  className={styles.textFieldBox}
                  display={'flex'}
                  justifyContent="space-between"
                >
                  <p className={styles.label}>{t('circle') + '*'}</p>
                  <p className={styles.textResponsive}>
                    {loggedUser?.roles?.includes('managerActivePlan')
                      ? t('activePlan')
                      : loggedUser?.roles?.includes('managerHealthChild')
                        ? t('healthykid')
                        : loggedUser?.roles?.includes('managerEpileptic')
                          ? t('pediatricEpilepsy')
                          : ''}
                  </p>
                </Box>
              )}

              <Box
                mb={3}
                className={styles.textFieldBox}
                display={'flex'}
                justifyContent="space-between"
              >
                {role.length && role.find((r) => r.name === 'professionalSMS') && (
                  <>
                    <p className={styles.label}>{t('professionalType') + '*'}</p>
                    <p className={styles.textResponsive}>
                      {(professionalType && professionalType.name) || ''}
                    </p>
                  </>
                )}
              </Box>
              {loggedUser?.roles?.includes('professionalSMS') && (
                <Box
                  mb={3}
                  className={styles.textFieldBox}
                  display={'flex'}
                  justifyContent="space-between"
                >
                  <p className={styles.label}>{t('positionType') + '*'}</p>
                  <p className={styles.textResponsive}>
                    {
                      professionalPosition.find((position) => position.id === positionTypeUser.id)
                        ?.name
                    }
                  </p>
                </Box>
              )}

              {loggedUser?.roles?.includes('professionalSMS') && (
                <>
                  <Box mb={3} className={styles.checkBox} display={'flex'}>
                    <p className={styles.label}>{t('acceptChats')}</p>

                    <div className={styles.radioContainer}>
                      <p>{userToEdit.acceptChat ? 'Sí' : 'No'}</p>
                    </div>
                  </Box>

                  <Box mb={3} className={styles.checkBox} display={'flex'}>
                    <p className={styles.label}>{t('acceptQuerys')}</p>

                    <div className={styles.radioContainer}>
                      <p>{professionalQuery.acceptConsulta !== 0 ? 'Sí' : 'No'}</p>
                    </div>
                  </Box>

                  <Box mb={3} className={styles.checkBox} display={'flex'}>
                    <p className={styles.label}>{t('acceptVideoCalls')}</p>

                    <div className={styles.radioContainer}>
                      <p>{userToEdit.acceptVideoCalls ? 'Sí' : 'No'}</p>
                    </div>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
        {message && (
          <Box mb={3}>
            <Alert severity="success" key="message" id="message">
              {t(message)}
            </Alert>
          </Box>
        )}
        <Box className={styles.buttonContainer} display="flex">
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'button'}
            label={t('edit')}
            handler={() => goEdit()}
          />
        </Box>
      </form>
    </Box>
  )
}
