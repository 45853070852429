import { TransportType } from '../../../common/enums/TransportType'
import { Query } from '../../../common/api/Query'
import { v4 as uuidv4 } from 'uuid'

export type NotificationProps = {
  id?: string
  transportType: TransportType
  notifyAt: Date
}

export class Notification {
  private readonly _id: string
  private readonly _transportType: TransportType
  private readonly _notifyAt: Date

  constructor(p: NotificationProps) {
    this._id = p.id ?? uuidv4()
    this._transportType = p.transportType
    this._notifyAt = p.notifyAt
  }

  get id() {
    return this._id
  }

  get transportType(): TransportType {
    return this._transportType
  }

  get notifyAt(): Date {
    return this._notifyAt
  }
}

export type CalendarNotificationDTO = {
  id: string
  transportType: TransportType
  notifyAt: Date
}

export function fromModel(n: Notification): CalendarNotificationDTO {
  return {
    id: n.id,
    transportType: n.transportType,
    notifyAt: n.notifyAt,
  }
}

export function toModel(d: CalendarNotificationDTO): Notification {
  return new Notification({
    id: d.id,
    transportType: d.transportType,
    notifyAt: d.notifyAt,
  })
}

export class NotificationQuery extends Query<Notification> {}
