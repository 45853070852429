import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/locale/en'

switch (navigator.language) {
  case 'es':
    dayjs.locale('es')
    break
  case 'en':
  default:
    dayjs.locale('en')
    break
}
