import { VisitAudit } from '../models/VisitAudit'
import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { VisitAuditDTO, toModel } from '../models/VisitAuditDTO'
import { QueryContainerConfig } from '../container'
import { emptyList, ItemList } from '../../../common/models/ItemList'

export interface IVisitAuditApi extends IInit {
  getByID(id: string | null): Observable<VisitAudit | undefined>

  getFilteredList(q: Query<VisitAudit>): Observable<ItemList<VisitAudit>>

  add(e: VisitAuditDTO): Observable<VisitAudit | undefined>

  delete(id: string): Observable<boolean>
}

export class VisitAuditApi implements IVisitAuditApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as QueryContainerConfig).moduleFullUrl + '/visit_audit'
  }

  getByID(id: string | null): Observable<VisitAudit | undefined> {
    return this._httpClient.get<VisitAudit>({ url: this._url + '/' + id }).pipe(
      map<VisitAuditDTO, VisitAudit>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  add(e: VisitAuditDTO): Observable<VisitAudit | undefined> {
    return this._httpClient.post<VisitAudit>({ url: this._url, body: e }).pipe(
      map<VisitAuditDTO, VisitAudit>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getFilteredList(q: Query<VisitAudit>): Observable<ItemList<VisitAudit>> {
    const url = this._url
    return this._httpClient.get<ItemList<VisitAudit>>({ url: prepareURL(url, q) }).pipe(
      map<ItemList<VisitAuditDTO>, ItemList<VisitAudit>>((dto) => {
        const itemList = emptyList<VisitAudit>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<VisitAudit>())
      })
    )
  }
}
