import { GrowthMonitoring } from './GrowthMonitoring'
import { GrowthMonitoringType } from '../enums/GrowthMonitoringType'
import { v4 as uuidv4 } from 'uuid'

export interface GrowthMonitoringDTO {
  id: string | undefined
  type: GrowthMonitoringType
  value: number
  date: Date
  userID: string
  isUploaded: boolean
}

export function emptyGrowthMonitoringDTO() {
  return {
    id: uuidv4(),
    type: GrowthMonitoringType.Height,
    value: 1,
    date: new Date(),
    userID: '',
    isUploaded: false,
  }
}

export function fromModel(d: GrowthMonitoring): GrowthMonitoringDTO {
  return {
    id: d.id,
    type: d.type,
    value: d.value,
    date: d.date,
    userID: d.userID,
    isUploaded: d.isUploaded,
  }
}

export function toModel(d: GrowthMonitoringDTO): GrowthMonitoring {
  return new GrowthMonitoring(d)
}
