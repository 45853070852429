import { ChangeEvent, useEffect, useState } from 'react'
import { Box, Chip, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ARTICLES_FORM, ROUTE_CREATE, ROUTE_TAG_LIST } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import { Query } from '../../common/api/Query'
import { getContentContainer } from '../../container/content-module'
import { TAG_SERVICE_KEY } from '../../modules/content'
import { LoadingSpinner } from 'components/loading-spinner/LoadingSpinner'
import Autocomplete from '@mui/material/Autocomplete'
import { TagService } from '../../modules/content/services/TagService'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import genericStyle from '../../common/utils/generic.module.css'
import searchIcon from '../../assets/resource_icons/search_icon.svg'
import styles from './Search.module.css'
import { Table } from './Table'
import { Header } from '../../components/header/Header'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Permission } from '../../common/permission'
import { Cancel } from '@mui/icons-material'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const contentContainer = getContentContainer()
const tagsService = contentContainer.get<TagService>(TAG_SERVICE_KEY)

type SearchProps = {
  tagList: string[]
}

export function Search(props: SearchProps) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [options, setOptions] = useState<Map<string, string>>(new Map())
  const [tagList, setTagList] = useState<Map<string, string>>(new Map())
  const [tagSearch, setTagSearch] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const navigate = useNavigate()
  const [once, setOnce] = useState<boolean>(true)

  useEffect(() => {
    if (once) return
    saveAllTags()
  }, [tagList])

  const saveAllTags = () => {
    const tagListAux = JSON.stringify(Object.fromEntries(tagList))
    localStorage.setItem('articleTag', tagListAux)
  }

  const getAllTags = () => {
    const auxTagsString = localStorage.getItem('articleTag')
    if (auxTagsString) {
      const auxTagsJSON = JSON.parse(auxTagsString)
      setTagList(new Map<string, string>(Object.entries(auxTagsJSON)))
    }
  }

  useEffect(() => {
    if (!once) return
    getAllTags()
    setOnce(false)
  }, [])

  useEffect(() => {
    if (props.tagList.length > 0) {
      setTagSearch(props.tagList)
    }
    tagsService.getFilteredList(new Query({ sort: [{ field: 'name' }] })).subscribe((res) => {
      const map = new Map()
      res.items.forEach((o: any) => map.set(o.name, o.id))
      if (props.tagList.length > 0) {
        const newMap = new Map()
        props.tagList.forEach((tagID) => {
          newMap.set(res.items.find((t) => t.id === tagID)?.name, tagID)
        })
        setTagList(newMap)
      }
      setOptions(map)
      setIsLoading(false)
    })
  }, [])

  const createArticle = () => navigate(`${ROUTE_ARTICLES_FORM}/${ROUTE_CREATE}`)

  const viewTags = () => navigate(ROUTE_TAG_LIST)

  const handleValueChange = (newValue: string[] | string | any) => {
    setInputValue(newValue)
    const result = Array.from(options).find((k) => k[0] === newValue)
    if (result?.[0] && !tagList.has(result[0])) {
      setTagList(new Map(tagList).set(result[0], result[1]))
      setInputValue('')
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setInputValue(e.target.value)

  const switchTag = (key: string, value: string) => {
    if (tagList.has(key)) {
      const newMap = new Map(tagList)
      newMap.delete(key)
      setTagList(newMap)
      return
    }
    setTagList(new Map(tagList).set(key, value))
  }

  const handleSearch = () => setTagSearch(Array.from(tagList.values()))

  const clearSelectedTags = () => setTagList(new Map())

  /*  const RootStyles = styled('div')({
    fontFamily: 'Poppins',
    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontFamily: 'Poppins',
      display: 'flex',
      flexDirection: 'column',
    },
  }) */

  return (
    <>
      {!isLoading ? (
        <Box className={genericStyle.pageContainer}>
          <Box style={{ marginBottom: '30px' }}>
            <Header label={t('tags')} />
          </Box>
          <Box
            mb={3}
            style={{ minHeight: '200px', width: '100%' }}
            display="flex"
            justifyContent="space-between"
          >
            <Box mr={2} className={styles.container} flexShrink={0}>
              <Box display="flex" justifyContent="space-between" className={styles.tagContainer}>
                <p className={styles.label}>{t('selectTags')}</p>
                <Autocomplete
                  inputValue={inputValue}
                  key={'tags'}
                  id={'tags-standard'}
                  options={
                    options
                      ? Array.from(options.keys()).filter(
                          (o) => !Array.from(tagList.values()).includes(options.get(o) ?? '')
                        )
                      : []
                  }
                  onChange={(_, value) => handleValueChange(value)}
                  blurOnSelect={true}
                  className={styles.autocompleteStyle}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={t('search')}
                      onChange={handleInputChange}
                    />
                  )}
                  noOptionsText={t('noTags')}
                  size="small"
                />
              </Box>
              <Box className={styles.content}>
                {options &&
                  Array.from(options).map((t, i) => (
                    <Chip
                      key={i}
                      className={styles.tag + ' ' + (tagList.has(t[0]) ? styles.tagSelected : '')}
                      label={t[0]}
                      onClick={() => switchTag(t[0], t[1])}
                    />
                  ))}
              </Box>
            </Box>

            <Box ml={2} className={styles.selectedContainer} style={{ width: '40%' }}>
              <p className={styles.label}>{t('selectedTags')}</p>
              <Box className={styles.content}>
                {Array.from(tagList).map((t, i) => (
                  <Chip
                    key={t[i]}
                    className={styles.tag + ' ' + styles.tagSelectedRightPanel}
                    label={t[0]}
                    onClick={() => switchTag(t[0], t[1])}
                    onDelete={() => switchTag(t[0], t[1])}
                    deleteIcon={<Cancel style={{ color: 'white' }} />}
                  />
                ))}
              </Box>
              <p className={styles.deleteLabel} onClick={() => clearSelectedTags()}>
                {t('clearSelectedTags')}
              </p>
            </Box>
          </Box>
          <Box mb={3} className={styles.buttons} display="flex" justifyContent="flex-end">
            {loggedUserService.userCan(Permission.createContent) && (
              <Box className={styles.button}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'button'}
                  label={t('createArticle')}
                  handler={createArticle}
                />
              </Box>
            )}
            {loggedUserService.userCan(Permission.createContent) && (
              <div className={styles.button}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'button'}
                  label={t('viewTags')}
                  handler={viewTags}
                />
              </div>
            )}
            <div className={styles.button}>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                label={t('search')}
                type={'button'}
                startIcon={searchIcon}
                handler={handleSearch}
              />
            </div>
          </Box>
          <Box mb={3}>
            <p className={styles.searchResults}>{t('searchResults')}</p>
          </Box>
          <Box mb={3}>
            <Table tagIDList={tagSearch} />
          </Box>
        </Box>
      ) : (
        <LoadingSpinner className={loaderStyles.loadingSpinner} />
      )}
    </>
  )
}
