import { AttendanceType } from '../enum/AttendanceType'

export type TrainingProps = {
  id: string
  groupID: string
  userCircleID: string
  startTime: Date
  finishTime: Date
  attendance: AttendanceType
  intensity: number
  physicalCondition: number
}

export class Training {
  private readonly _id: string
  private _groupID: string
  private _userCircleID: string
  private _startTime: Date
  private _finishTime: Date
  private _attendance: AttendanceType
  private _intensity: number
  private _physicalCondition: number

  constructor(props: TrainingProps) {
    this._id = props.id
    this._groupID = props.groupID
    this._userCircleID = props.userCircleID
    this._startTime = props.startTime
    this._finishTime = props.finishTime
    this._attendance = props.attendance
    this._intensity = props.intensity
    this._physicalCondition = props.physicalCondition
  }

  get id(): string {
    return this._id
  }

  get groupID(): string {
    return this._groupID
  }

  set groupID(value: string) {
    this._groupID = value
  }

  get userCircleID(): string {
    return this._userCircleID
  }

  set userCircleID(value: string) {
    this._userCircleID = value
  }

  get startTime(): Date {
    return this._startTime
  }

  set startTime(value: Date) {
    this._startTime = value
  }

  get finishTime(): Date {
    return this._finishTime
  }

  set finishTime(value: Date) {
    this._finishTime = value
  }

  get attendance(): AttendanceType {
    return this._attendance
  }

  set attendance(value: AttendanceType) {
    this._attendance = value
  }

  get intensity(): number {
    return this._intensity
  }

  set intensity(value: number) {
    this._intensity = value
  }

  get physicalCondition(): number {
    return this._physicalCondition
  }

  set physicalCondition(value: number) {
    this._physicalCondition = value
  }
}
