import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { CityHall, CityHallQuery } from '../models/CityHall'
import { CityHallDTO } from '../models/CityHallDTO'
import { ICityHallApi } from '../api/CityHallApi'

type Props = {
  apiKey: symbol
}

export interface ICityHallService extends IInit {
  getByID(id: string): Observable<CityHall | undefined>

  getFilteredList(q: Query<CityHallQuery>, userId: string): Observable<ItemList<CityHall>>

  add(e: CityHall): Observable<CityHall | undefined>

  update(e: CityHallDTO): Observable<CityHall | undefined>

  delete(id: string): Observable<boolean>
}

export class CityHallService implements ICityHallService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ICityHallApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<ICityHallApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: CityHallDTO): Observable<CityHall | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<CityHall | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<CityHallQuery>): Observable<ItemList<CityHall>> {
    return this._api.getFilteredList(q)
  }

  update(e: CityHallDTO): Observable<CityHall | undefined> {
    return this._api.update(e)
  }
}
