import { Percentile } from './Percentile'

export interface PercentileDTO {
  month: number
  percentile: number
  value: number
  gender: number
  category: number
  organization: number
}

export function emptyPercentileDTO() {
  return {
    month: 0,
    percentile: 0,
    value: 0,
    gender: 0,
    category: 0,
    organization: 0,
  }
}

export function fromModel(d: Percentile): PercentileDTO {
  return {
    month: d.month,
    percentile: d.percentile,
    value: d.value,
    gender: d.gender,
    category: d.category,
    organization: d.organization,
  }
}

export function toModel(d: PercentileDTO): Percentile {
  return new Percentile(d)
}
