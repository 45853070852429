import React from 'react'
import { Avatar, Box } from '@mui/material'
import styles from '../messenger/ShortDescription.module.css'
import womanIcon from '../../assets/chatIcons/woman.svg'
import maleIcon from '../../assets/chatIcons/male.svg'
import { reduceString } from '../../common/utils/strings'
import {
  dateToDateTimeOnlyDayString,
  dateToDateTimeOnlyHourString,
  dateToDateTimeWeekMonthString,
} from '../../common/utils/date'

import { differenceInDays } from 'date-fns'
import {
  ConversationUser,
  ConversatonUserGender,
} from '../../modules/messenger/models/ConversationUser'

function isToday(date: Date) {
  const today = new Date()
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

function isSameWeek(date: Date) {
  const today = new Date()
  const difference = differenceInDays(today, date)
  // Comprobar que ha pasado más de 1 día pero menos de 7 días
  return difference >= 1 && difference <= 6
}

function isMoreThanAWeekAgo(date: Date) {
  const today = new Date()
  const difference = differenceInDays(today, date)
  return difference >= 7
}

export type ChatWidgetProps = {
  id: string | undefined
  title: string
  description?: string
  gender?: string
  conversationUsers: ConversationUser[]
  avatarUrl: string
  lastMessage?: string
  date?: Date
}

export function ChatWidget(props: ChatWidgetProps): JSX.Element {
  return (
    <Box key={props.id} className={styles.conversationWall} display="flex">
      <Box className={styles.avatarWrapper} flexDirection="column">
        <div>{props.gender}</div>
        <Avatar
          src={
            props.conversationUsers[0].userGender === ConversatonUserGender.female
              ? womanIcon
              : maleIcon
          }
          style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF', padding: '0' }}
        />
      </Box>
      <Box className={styles.conversationBlock}>
        <h3 className={styles.conversationName}>{props.title}</h3>
        {props.description && (
          <h4 className={styles.conversationDescription}>{props.description}</h4>
        )}
        {props.lastMessage && (
          <h4 className={styles.conversationBody}>{reduceString(props.lastMessage, 35)}</h4>
        )}
      </Box>
      <Box className={styles.conversationBlock} style={{ textAlign: 'right' }}>
        {props.date && (
          <h4 className={styles.conversationBody}>
            {isToday(props.date)
              ? dateToDateTimeOnlyHourString(props.date)
              : isSameWeek(props.date)
                ? dateToDateTimeOnlyDayString(props.date)
                : isMoreThanAWeekAgo(props.date)
                  ? dateToDateTimeWeekMonthString(props.date)
                  : ''}
          </h4>
        )}
      </Box>
    </Box>
  )
}
