export enum Permission {
  linkUser = 'Vincular usuario al círculo del paciente',
  seeCircles = 'Ver y descargar lista de círculos',
  registerCircles = 'Registrar círculos',
  seePatientStats = 'Visualizar estadísticas de pacientes',
  configureNotifications = 'Configurar y recibir alertas / notificaciones',
  messenger = 'Mensajería y compartir archivos',
  configurePatientCalendar = 'Configurar calendario de paciente',
  seeCalendar = 'Visualizar calendario del paciente',
  createSMSAppointment = 'Agendar consulta',
  registerMedicalData = 'Registrar datos del paciente',
  registerReport = 'Registrar informes de especialista',
  registerSymptoms = 'Registrar síntomas y crisis',
  createFormTemplates = 'Diseñar y enviar formularios',
  userForms = 'Llenar formularios o encuestas',
  createContent = 'Crear y publicar contenido',
  seeNotifications = 'Visualizar muro noticias y biblioteca de contenidos',
  registerTreatments = 'Registrar tratamiento farmacológico',
  seeEvolutionControl = 'Visualizar gráficos de control evolutivo',
  seeVaccinationCalendar = 'Visualizar calendario vacunal',
  configureDentists = 'Configurar listado salud Bucodental',
  seeDentists = 'Visualizar listado salud Bucodental',
  resources = 'Subir y compartir recursos',
  linkWearable = 'Conectar con dispositivo wearable',
  seeWearable = 'Visualizar datos de dispositivos wearables',
  editViewRolePermissions = 'Editar y ver permisos',
  uploadToAgora = 'Subir a Ágora',
  growthMonitoringActions = 'Ver detalles, editar y eliminar seguimientos del crecimiento',
  seePatientData = 'Visualizar los datos del paciente',
  createFamiliarData = 'Crear datos familiares de interés de un paciente',
  configVaccines = 'Visualizar, editar y crear vacunas',
  configureSymptoms = 'Configurar síntomas',
  SeeSharedResources = 'Ver recursos compartidos',
  configActivePlans = 'Añadir, editar y eliminar espacios deportivos',
  ConfigCircles = 'Añadir, editar y eliminar configuraciones de círculos',
  seeActivePlan = 'Visualizar los datos de Plan Activa',
}
