export type ParticipantConfigProps = {
  caseManager: number
  contentManager: number
  profSMS: number
  familiar: number
  teacher: number
  profExt: number
}

export class ParticipantConfig {
  private _caseManager: number
  private _contentManager: number
  private _profSMS: number
  private _familiar: number
  private _teacher: number
  private _profExt: number

  constructor(p: ParticipantConfigProps) {
    this._caseManager = p.caseManager
    this._contentManager = p.contentManager
    this._profSMS = p.profSMS
    this._familiar = p.familiar
    this._teacher = p.teacher
    this._profExt = p.profExt
  }

  get caseManager(): number {
    return this._caseManager
  }

  set caseManager(value: number) {
    this._caseManager = value
  }

  get contentManager(): number {
    return this._contentManager
  }

  set contentManager(value: number) {
    this._contentManager = value
  }

  get profSMS(): number {
    return this._profSMS
  }

  set profSMS(value: number) {
    this._profSMS = value
  }

  get familiar(): number {
    return this._familiar
  }

  set familiar(value: number) {
    this._familiar = value
  }

  get teacher(): number {
    return this._teacher
  }

  set teacher(value: number) {
    this._teacher = value
  }

  get profExt(): number {
    return this._profExt
  }

  set profExt(value: number) {
    this._profExt = value
  }
}
