import { emptyUserDTO, User } from './User'

export interface UserWithRelatersDTO {
  user: User
  relatedUsers: User[]
  isPediatrics: boolean
  circle: string
}

export function emptyUserWithRelatersDTO() {
  return {
    user: emptyUserDTO(),
    relatedUsers: [],
    isPediatrics: false,
    circle: '',
  }
}

export class UserWithRelaters {
  private readonly _user: User
  private readonly _relatedUsers: User[]
  private readonly _isPediatrics: boolean
  private readonly _circle: string

  constructor(p: UserWithRelatersDTO) {
    this._user = p.user
    this._relatedUsers = p.relatedUsers
    this._isPediatrics = p.isPediatrics
    this._circle = p.circle
  }

  get user(): User {
    return this._user
  }

  get relatedUsers(): User[] {
    return this._relatedUsers
  }

  get isPediatrics(): boolean {
    return this._isPediatrics
  }

  get circle(): string {
    return this._circle
  }

  toDTO(): UserWithRelatersDTO {
    return {
      user: this._user,
      relatedUsers: this._relatedUsers,
      isPediatrics: this._isPediatrics,
      circle: this._circle,
    }
  }
}

export function fromModel(d: UserWithRelaters): UserWithRelatersDTO {
  return {
    user: d.user,
    relatedUsers: d.relatedUsers,
    isPediatrics: d.isPediatrics,
    circle: d.circle,
  }
}

export function toModel(d: UserWithRelatersDTO): UserWithRelaters {
  return new UserWithRelaters(d)
}
