import { Container } from '../../common/container/Container'

export type ActivePlanContainerConfig = {
  moduleFullUrl: string
}

export type ActivePlanProps = {
  parentContainer: Container
  config: ActivePlanContainerConfig
}

export const ACTIVE_PLAN_MODULE = Symbol('ACTIVE_PLAN_MODULE')

export const ACTIVE_PLAN_API_KEY = Symbol('ACTIVE_PLAN_API_KEY')

export const ACTIVE_PLAN_SERVICE_KEY = Symbol('ACTIVE_PLAN_SERVICE_KEY')
