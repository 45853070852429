import i18n from '../../../i18n'

export enum Symptom {
  never = -1,
  urineControlLoss,
  deposition,
  vomiting,
  undefined,
  others,
}

export const symptomTypes = (): Record<Symptom, string> => ({
  [Symptom.never]: i18n.t('never'),
  [Symptom.urineControlLoss]: i18n.t('urineControlLoss'),
  [Symptom.deposition]: i18n.t('deposition'),
  [Symptom.vomiting]: i18n.t('vomiting'),
  [Symptom.undefined]: i18n.t('undefined'),
  [Symptom.others]: i18n.t('others'),
})
