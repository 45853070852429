import { Query } from '../../../common/api/Query'

export enum ConversatonUserGender {
  male = 2,
  female = 1,
}

export type UserProps = {
  userID: string
  conversationID: string
  isAdmin: boolean
  userGender: ConversatonUserGender
}

export class ConversationUser {
  private readonly _userID: string
  private readonly _conversationID: string

  private _isAdmin: boolean
  private _userGender: ConversatonUserGender

  constructor(p: UserProps) {
    this._userID = p.userID
    this._conversationID = p.conversationID
    this._isAdmin = p.isAdmin
    this._userGender = p.userGender
  }

  get userID(): string {
    return this._userID
  }

  get conversationID(): string {
    return this._conversationID
  }

  get isAdmin(): boolean {
    return this._isAdmin
  }

  set isAdmin(value: boolean) {
    this._isAdmin = value
  }

  get userGender(): ConversatonUserGender {
    return this._userGender
  }

  set userGender(value: ConversatonUserGender) {
    this._userGender = value
  }
}

export class UserQuery extends Query<ConversationUser> {}
