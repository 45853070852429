export type VaccineProps = {
  id: string | undefined
  tvac: number
  name: string
  description: string | undefined
}

export class Vaccine {
  private readonly _id: string | undefined
  private _tvac: number
  private _name: string
  private _description: string | undefined

  constructor(p: VaccineProps) {
    this._id = p.id
    this._tvac = p.tvac
    this._name = p.name
    this._description = p.description
  }

  get id(): string | undefined {
    return this._id
  }

  get tvac(): number {
    return this._tvac
  }

  set tvac(value: number) {
    this._tvac = value
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get description(): string | undefined {
    return this._description
  }

  set description(value: string | undefined) {
    this._description = value
  }
}

export interface VaccinesQuery {
  tvac: number
  name: string
}
