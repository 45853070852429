import { Container } from '../../common/container/Container'

export type CityHallContainerConfig = {
  moduleFullUrl: string
}

export type CityHallProps = {
  parentContainer: Container
  config: CityHallContainerConfig
}

export const CITY_HALL_MODULE = Symbol('CITY_HALL_MODULE')

export const CITY_HALL_API_KEY = Symbol('CITY_HALL_API_KEY')

export const CITY_HALL_SERVICE_KEY = Symbol('CITY_HALL_SERVICE_KEY')
