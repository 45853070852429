import { LangType } from '../enums/LangType'
import { Tag } from './Tag'
import { v4 as uuidv4 } from 'uuid'

export interface TagDTO {
  id: string
  lang: LangType
  name: string
  description: string
  isSection: boolean
  sectionSlug: string
  parentSection: string | undefined
}

export function emptyTagDTO(): TagDTO {
  return {
    id: uuidv4(),
    lang: LangType.Spanish,
    name: '',
    description: '',
    isSection: false,
    sectionSlug: 'slug',
    parentSection: undefined,
  }
}

export function fromModel(t: Tag): TagDTO {
  return {
    id: t.id,
    lang: t.lang,
    name: t.name,
    description: t.description,
    isSection: t.isSection,
    sectionSlug: t.sectionSlug,
    parentSection: t.parentSection,
  }
}

export function toModel(d: TagDTO): Tag {
  return new Tag(d)
}
