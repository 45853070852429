import { CalendarNotificationDTO } from './Notification'
import { Query } from '../../../common/api/Query'
import { EventCategory } from '../enums/EventCategory'

export type EventProps = {
  id?: string
  title: string
  description: string
  startDate: Date
  finishDate: Date
  startCron: Date
  finishCron: Date
  repeatingUntil?: Date
  repeating: string
  place: string
  ownerID: string
  creatorID: string
  isOwnerBusy: boolean
  eventCategory: EventCategory
  parentID: string
  users: string[]
  files?: string[]
  notifications?: CalendarNotificationDTO[]
}

const getEstOffset = () => {
  const stdTimezoneOffset = () => {
    const jan = new Date(0, 1)
    const jul = new Date(0, 6)
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
  }

  const today = new Date()

  const isDstObserved = (today: Date) => {
    return today.getTimezoneOffset() < stdTimezoneOffset()
  }

  if (isDstObserved(today)) {
    return 1
  } else {
    return 2
  }
}

export class Event {
  private readonly _id: string | undefined
  private readonly _title: string
  private readonly _description: string
  private readonly _startDate: Date
  private readonly _finishDate: Date
  private readonly _startCron: Date
  private readonly _finishCron: Date
  private readonly _repeatingUntil: Date | undefined
  private readonly _repeating: string
  private readonly _place: string
  private readonly _ownerID: string
  private readonly _creatorID: string
  private readonly _isOwnerBusy: boolean
  private readonly _parentID: string
  private _eventCategory: EventCategory

  private _users: string[]
  private _files: string[] | undefined
  private _notifications: CalendarNotificationDTO[] | undefined

  constructor(p: EventProps) {
    const userOffset = new Date(p.startDate).getTimezoneOffset() * 60 * 1000
    const centralOffset = 60 * 60 * 1000 * getEstOffset()
    const offSet = userOffset + centralOffset

    this._id = p.id
    this._title = p.title
    this._description = p.description
    this._startDate = new Date(new Date(p.startDate).getTime() + offSet)
    this._finishDate = new Date(new Date(p.finishDate).getTime() + offSet)
    this._startCron = new Date(new Date(p.startCron).getTime() + offSet)
    this._finishCron = new Date(new Date(p.finishCron).getTime() + offSet)
    this._repeatingUntil = p.repeatingUntil ? new Date(p.repeatingUntil) : undefined
    this._repeating = p.repeating
    this._place = p.place
    this._ownerID = p.ownerID
    this._creatorID = p.creatorID
    this._isOwnerBusy = p.isOwnerBusy
    this._eventCategory = p.eventCategory
    this._parentID = p.parentID
    this._users = p.users || []
    this._files = p.files ?? []
    this._notifications = p.notifications
  }

  get id(): string | undefined {
    return this._id
  }

  get title(): string {
    return this._title
  }

  get description(): string {
    return this._description
  }

  get startDate(): Date {
    return this._startDate
  }

  get finishDate(): Date {
    return this._finishDate
  }

  get startCron(): Date {
    return this._startCron
  }

  get finishCron(): Date {
    return this._finishCron
  }

  get repeatingUntil(): Date | undefined {
    return this._repeatingUntil
  }

  get repeating(): string {
    return this._repeating
  }

  get place(): string {
    return this._place
  }

  get ownerID(): string {
    return this._ownerID
  }

  get creatorID(): string {
    return this._creatorID
  }

  get isOwnerBusy(): boolean {
    return this._isOwnerBusy
  }

  get parentID(): string {
    return this._parentID
  }

  get eventCategory(): EventCategory {
    return this._eventCategory
  }

  set eventCategory(value: EventCategory) {
    this._eventCategory = value
  }

  get users(): string[] {
    return [...this._users]
  }

  addUser(value: string) {
    if (this._users) {
      this._users.push(value)
    } else {
      this._users = [value]
    }
  }

  removeUser(value: string) {
    if (this._users) {
      this._users = this._users.filter((u) => u !== value)
    }
  }

  get files(): string[] | undefined {
    if (this._files) {
      return [...this._files]
    }

    return this._files
  }

  addFile(value: string) {
    if (this._files) {
      this._files.push(value)
    } else {
      this._files = [value]
    }
  }

  removeFile(value: string) {
    if (this._files) {
      this._files = this._files.filter((f) => f !== value)
    }
  }

  get notifications(): CalendarNotificationDTO[] | undefined {
    if (this._notifications) {
      return [...this._notifications]
    }

    return this._notifications
  }

  addNotification(value: CalendarNotificationDTO) {
    if (this._notifications) {
      this._notifications.push(value)
    } else {
      this._notifications = [value]
    }
  }

  removeNotification(value: CalendarNotificationDTO) {
    if (this._notifications) {
      this._notifications = this._notifications.filter(
        (n) => n.notifyAt === value.notifyAt && n.transportType !== value.transportType
      )
    }
  }
}

export class EventQuery extends Query<Event> {}
