import { Container, IInit } from '../../../common/container/Container'
import { UserCircle, UserCircleQuery } from '../models/UserCircle'
import { UserCircleContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { Query, QueryParam } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { toModel, UserCircleDTO } from '../models/UserCircleDTO'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { prepareURL } from '../../../common/api/http-helpers'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UserCircleWithCircle } from '../models/UserCircleWithCircle'
import { UserCircleWithCircleDTO } from '../models/UserCircleWithCircleDTO'
import { toModel as toModel2 } from '../models/UserCircleWithCircleDTO'
import { toModel as toModel3 } from '../models/UserCircleWithRelatersDTO'
import { toModel as toModel4 } from '../models/UserCircleWithRelatersAndCircleDTO'

import { toModel as toModelCircleAndUser } from '../models/UserCircleWithCircleAndUserDTO'

import { UserCircleWithRelaters } from '../models/UserCircleWithRelaters'
import { UserCircleWithRelatersDTO } from '../models/UserCircleWithRelatersDTO'
import { UserCircleWithCircleAndUser } from '../models/UserCircleWithCircleAndUser'
import { UserCircleWithRelatersAndCircle } from '../models/UserCircleWithRelatersAndCircle'
import { UserCircleWithRelatersAndCircleDTO } from '../models/UserCircleWithRelatersAndCircleDTO'

export interface IUserCircleApi extends IInit {
  getByID(id: string): Observable<UserCircle | undefined>

  getByUserIDWithRelaters(id: string): Observable<UserCircleWithRelaters[] | undefined>

  getWichIncludesUserIDWithRelatersAndCircle(
    id: string
  ): Observable<UserCircleWithRelatersAndCircle[] | undefined>

  getWichIncludesUserIDWithRelatersAndCircleLetter(
    id: string,
    letter: number
  ): Observable<UserCircleWithRelatersAndCircle[] | undefined>

  getUserCircleByUserIDWithCircle(id: string): Observable<UserCircleWithCircle[] | undefined>

  getFilteredList(q: Query<UserCircleQuery>): Observable<ItemList<UserCircle>>

  getFilteredListWithCircleAndUser(
    q: Query<UserCircleQuery>
  ): Observable<ItemList<UserCircleWithCircleAndUser>>

  add(e: UserCircleDTO): Observable<UserCircle | undefined>

  update(e: UserCircleDTO): Observable<UserCircle | undefined>

  delete(id: string): Observable<boolean>

  getFilteredListWithCircleAndUserCustom(
    q: Query<UserCircleQuery>
  ): Observable<ItemList<UserCircleWithCircleAndUser>>
}

export class UserCircleApi implements IUserCircleApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as UserCircleContainerConfig).moduleFullUrl
  }

  add(e: UserCircleDTO): Observable<UserCircle | undefined> {
    return this._httpClient.post<UserCircle>({ url: this._url + '/user-circle', body: e }).pipe(
      map<UserCircleDTO, UserCircle>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<UserCircle | undefined> {
    return this._httpClient.get<UserCircle>({ url: `${this._url}/${id}` }).pipe(
      map<UserCircleDTO, UserCircle>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getUserCircleByUserIDWithCircle(id: string): Observable<UserCircleWithCircle[] | undefined> {
    return this._httpClient
      .get<UserCircleWithCircle[]>({ url: `${this._url}/user-circle-by-user-id-with-circle/${id}` })
      .pipe(
        map<UserCircleWithCircleDTO[], UserCircleWithCircle[]>((d) => {
          return d.map((d) => toModel2(d))
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getWichIncludesUserIDWithRelatersAndCircle(
    id: string
  ): Observable<UserCircleWithRelatersAndCircle[] | undefined> {
    return this._httpClient
      .get<UserCircleWithRelatersAndCircle[]>({
        url: `${this._url}/wich-includes-user-id-with-relaters/${id}`,
      })
      .pipe(
        map<UserCircleWithRelatersAndCircleDTO[], UserCircleWithRelatersAndCircle[]>(
          (ucwr: UserCircleWithRelatersAndCircleDTO[]) => {
            return ucwr.map((d) => toModel4(d))
          }
        ),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getWichIncludesUserIDWithRelatersAndCircleLetter(
    id: string,
    letter: number
  ): Observable<UserCircleWithRelatersAndCircle[] | undefined> {
    return this._httpClient
      .get<UserCircleWithRelatersAndCircle[]>({
        url: `${this._url}/wich-includes-user-id-with-relaters-letter/${id}/${letter}`,
      })
      .pipe(
        map<UserCircleWithRelatersAndCircleDTO[], UserCircleWithRelatersAndCircle[]>(
          (ucwr: UserCircleWithRelatersAndCircleDTO[]) => {
            return ucwr.map((d) => toModel4(d))
          }
        ),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByUserIDWithRelaters(id: string): Observable<UserCircleWithRelaters[] | undefined> {
    return this._httpClient
      .get<UserCircleWithRelaters[]>({
        url: `${this._url}/user-circle-by-user-id-with-relaters/${id}`,
      })
      .pipe(
        map<UserCircleWithRelatersDTO[], UserCircleWithRelaters[]>(
          (ucwr: UserCircleWithRelatersDTO[]) => {
            return ucwr.map((d) => toModel3(d))
          }
        ),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredList(q: Query<UserCircleQuery>): Observable<ItemList<UserCircle>> {
    return this._httpClient
      .get<ItemList<UserCircle>>({ url: prepareURL(`${this._url}/user-circle`, q) })
      .pipe(
        map<ItemList<UserCircleDTO>, ItemList<UserCircle>>((dto) => {
          const itemList = emptyList<UserCircle>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<UserCircle>())
        })
      )
  }

  getFilteredListWithCircleAndUser(
    q: Query<UserCircleQuery>
  ): Observable<ItemList<UserCircleWithCircleAndUser>> {
    q.addParam(new QueryParam('paginatedWithCircleAndUser', 'true'))
    return this._httpClient
      .get<ItemList<UserCircleWithCircleAndUser>>({
        url: prepareURL(`${this._url}/user-circle`, q),
      })
      .pipe(
        map<ItemList<UserCircleWithCircleAndUser>, ItemList<UserCircleWithCircleAndUser>>((dto) => {
          const itemList = emptyList<UserCircleWithCircleAndUser>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModelCircleAndUser(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<UserCircleWithCircleAndUser>())
        })
      )
  }

  update(e: UserCircleDTO): Observable<UserCircle | undefined> {
    return this._httpClient.put<UserCircle>({ url: this._url + '/user-circle', body: e }).pipe(
      map<UserCircleDTO, UserCircle>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredListWithCircleAndUserCustom(
    q: Query<UserCircleQuery>
  ): Observable<ItemList<UserCircleWithCircleAndUser>> {
    q.addParam(new QueryParam('paginatedWithCircleAndUser', 'true'))
    return this._httpClient
      .get<ItemList<UserCircleWithCircleAndUser>>({
        url: prepareURL(`${this._url}/user-circle/customList`, q),
      })
      .pipe(
        map<ItemList<UserCircleWithCircleAndUser>, ItemList<UserCircleWithCircleAndUser>>((dto) => {
          const itemList = emptyList<UserCircleWithCircleAndUser>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModelCircleAndUser(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<UserCircleWithCircleAndUser>())
        })
      )
  }
}
