import { RelatedUser } from './RelatedUser'

type related = {
  id: string
  kind: number
}

export interface RelatedUserDTO {
  relatedUsers: related[]
  userID: string
}

export function emptyRelatedUserDTO(): RelatedUserDTO {
  return {
    relatedUsers: [],
    userID: '',
  }
}

export function fromModel(d: RelatedUser): RelatedUserDTO {
  return {
    relatedUsers: d.relatedUsers,
    userID: d.id,
  }
}

export function toModel(d: RelatedUserDTO): RelatedUser {
  return new RelatedUser(d)
}
