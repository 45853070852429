import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ActivePlan } from '../models/ActivePlan'
import { ActivePlanDTO } from '../models/ActivePlanDTO'
import { IActivePlanApi } from '../api/ActivePlanApi'

type Props = {
  apiKey: symbol
}

export interface IActivePlanService extends IInit {
  getByID(id: string): Observable<ActivePlan | undefined>

  getFilteredList(q: Query<ActivePlan>, userId: string): Observable<ItemList<ActivePlan>>

  add(e: ActivePlan): Observable<ActivePlan | undefined>

  update(e: ActivePlanDTO): Observable<ActivePlan | undefined>

  delete(id: string): Observable<boolean>

  getByUserCircleID(userCircleID: string): Observable<ActivePlan[] | undefined>
}

export class ActivePlanService implements IActivePlanService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IActivePlanApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IActivePlanApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: ActivePlanDTO): Observable<ActivePlan | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<ActivePlan | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<ActivePlan>): Observable<ItemList<ActivePlan>> {
    return this._api.getFilteredList(q)
  }

  update(e: ActivePlanDTO): Observable<ActivePlan | undefined> {
    return this._api.update(e)
  }

  getByUserCircleID(userCircleID: string): Observable<ActivePlan[] | undefined> {
    return this._api.getByUserCircleID(userCircleID)
  }
}
