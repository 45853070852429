import { Goal } from '../models/Goal'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { DataPatientContainerConfig } from '../container'
import { catchError, map } from 'rxjs/operators'
import { GoalDTO, toModel } from '../models/GoalDTO'

export interface IGoalApi extends IInit {
  getItems(id: string): Observable<Goal[]>
  putGoals(g: GoalDTO[]): Observable<Goal[]>
}

export class GoalApi implements IGoalApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as DataPatientContainerConfig).moduleFullUrl + '/goals'
  }

  getItems(id: string): Observable<Goal[]> {
    return this._httpClient.get<Goal[]>({ url: this._url + '/' + id }).pipe(
      map<GoalDTO[], Goal[]>((dto) => dto.map((d) => toModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  putGoals(g: GoalDTO[]): Observable<Goal[]> {
    return this._httpClient.put<Goal[]>({ url: this._url, body: g }).pipe(
      map<GoalDTO[], Goal[]>((dto) => {
        this._statusService.sendStatus({ variant: 'success' })
        return dto.map((d) => toModel(d))
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }
}
