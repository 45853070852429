import { ProfessionalTypeUser } from './ProfessionalTypeUser'
import { v4 } from 'uuid'

export interface ProfessionalTypeUserDTO {
  id: string
  professionalTypeID: string
  userID: string
}

export function emptyProfessionalTypeUserDTO(): ProfessionalTypeUserDTO {
  return {
    id: v4(),
    professionalTypeID: '',
    userID: '',
  }
}

export function fromModel(d: ProfessionalTypeUser): ProfessionalTypeUserDTO {
  return {
    id: d.id,
    professionalTypeID: d.professionalTypeID,
    userID: d.userID,
  }
}

export function toModel(d: ProfessionalTypeUserDTO): ProfessionalTypeUser {
  return new ProfessionalTypeUser(d)
}
