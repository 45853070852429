import { v4 as uuidv4 } from 'uuid'
import { TimeTableDTO, toModelList, fromModelList } from './TimeTableDTO'
import { TrainingGroup, TrainingGroupArr } from './TrainingGroup'

export interface TrainingGroupDTO {
  id: string
  capacity: number
  name: string
  centerID: string
  userTypeExternProfID: string
  trainerName: string
  trainerEmail: string
  timeTable: TimeTableDTO[]
}

export interface TrainingGroupArrDTO {
  groups: TrainingGroupDTO[]
}

export function emptyTrainingGroupArrDTO() {
  return {
    groups: [],
  }
}

export function fromModelArr(e: TrainingGroupArr): TrainingGroupArrDTO {
  return { groups: e.groups }
}

export function toModelArr(d: TrainingGroupArrDTO): TrainingGroupArr {
  const auxArr: TrainingGroup[] = []
  d.groups.forEach((value) => {
    auxArr.push(toModel(value))
  })
  return new TrainingGroupArr({ groups: auxArr })
}

export function emptyTrainingGroupDTO() {
  return {
    id: uuidv4(),
    capacity: 0,
    name: '',
    centerID: '',
    userTypeExternProfID: '',
    trainerName: '',
    trainerEmail: '',
    timeTable: [],
  }
}

export function fromModel(e: TrainingGroup): TrainingGroupDTO {
  return {
    id: e.id,
    capacity: e.capacity,
    name: e.name,
    centerID: e.centerID,
    userTypeExternProfID: e.userTypeExternProfID,
    trainerName: e.trainerName,
    trainerEmail: e.trainerEmail,
    timeTable: fromModelList(e.timeTable),
  }
}

export function toModel(d: TrainingGroupDTO): TrainingGroup {
  const object = {
    id: d.id,
    capacity: d.capacity,
    name: d.name,
    centerID: d.centerID,
    userTypeExternProfID: d.userTypeExternProfID,
    trainerName: d.trainerName,
    trainerEmail: d.trainerEmail,
    timeTable: toModelList(d.timeTable),
  }
  return new TrainingGroup(object)
}

export function trainingGroupFromModelList(tg: TrainingGroup[]): TrainingGroupDTO[] {
  const auxArr: TrainingGroupDTO[] = []
  tg.forEach((value) => {
    auxArr.push(fromModel(value))
  })
  return auxArr
}

export function trainingGroupToModelList(tg: TrainingGroupDTO[]): TrainingGroup[] {
  const auxArr: TrainingGroup[] = []
  tg.forEach((value) => {
    auxArr.push(toModel(value))
  })
  return auxArr
}
