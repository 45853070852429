import { Select, MenuItem } from '@mui/material'
import { ProfessionalType } from '../../modules/users/models/ProfessionalType'

interface SpecialitySelectProps {
  specialities: ProfessionalType[]
  selector: string
  selectorIndex: number
  arraySelectedSpecialities: string[]
  handleSpecialtyChange: (index: number, value: string) => void
}

const CustomSpecialitySelect: React.FC<SpecialitySelectProps> = ({
  specialities,
  selector,
  selectorIndex,
  arraySelectedSpecialities,
  handleSpecialtyChange,
}) => {
  return (
    <Select
      MenuProps={{
        MenuListProps: {
          sx: {
            'li.MuiButtonBase-root': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              fontFamily: 'Poppins',
            },
          },
        },
      }}
      SelectDisplayProps={{
        style: {
          fontFamily: 'Poppins',
        },
      }}
      fullWidth
      variant="outlined"
      style={{ backgroundColor: '#f5f2f2', height: '40px' }}
      inputProps={{ 'aria-label': 'Without label' }}
      value={selector}
      onChange={(e) => handleSpecialtyChange(selectorIndex, e.target.value)}
    >
      {specialities.map((option) => (
        <MenuItem
          disabled={arraySelectedSpecialities.includes(option.name)}
          key={option.id}
          value={option.name}
        >
          {option.name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CustomSpecialitySelect
