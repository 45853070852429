import { RouteProps } from '../../routes/AppRouter'
import React from 'react'
import { Table } from '../../features/circle-configurator/Table'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

type CircleConfiguratorProps = RouteProps

export function CircleConfigurator(props: CircleConfiguratorProps) {
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <Table />
    </>
  )
}
