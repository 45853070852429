import { from, Observable, throwError } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { getResponseError, HTTPStatus } from './http-helpers'
import { IInit } from '../container/Container'
import { CSRF_KEY } from '../../modules/users/services/LoggedUserService'

export const HTTP_CLIENT_KEY = Symbol('HTTP_CLIENT_KEY')

export interface IHTTPClient extends IInit {
  get<T>(p: RequestProps): Observable<T>

  post<T>(p: RequestProps): Observable<T>

  put<T>(p: RequestProps): Observable<T>

  delete(p: RequestProps): Observable<boolean>
}

export class HTTPClient implements IHTTPClient {
  init(/* c: Container */): void {}

  get<T>(p: RequestProps): Observable<T> {
    return from(fetch(this.request(p, 'GET'))).pipe(
      mergeMap<Response, Observable<T>>((res) => {
        if (res.status !== HTTPStatus.OK) {
          throwError(getResponseError(res))
        }
        return from(res.json() as Promise<T>)
      })
    )
  }

  post<T>(p: RequestProps): Observable<T> {
    return from(fetch(this.request(p, 'POST'))).pipe(
      mergeMap<Response, Observable<T>>((res) => {
        if (res.status !== HTTPStatus.OK && res.status !== HTTPStatus.Created) {
          throwError(getResponseError(res))
        }
        return from(res.json() as Promise<T>)
      })
    )

    // return fromFetch(this.request(p, 'POST')).pipe(
    //   mergeMap<Response, Observable<T>>((res) => {
    //     if (res.status !== HTTPStatus.OK && res.status !== HTTPStatus.Created) {
    //       throwError(getResponseError(res))
    //     }
    //     return from(res.json() as Promise<T>)
    //   })
    // )
  }

  put<T>(p: RequestProps): Observable<T> {
    return from(fetch(this.request(p, 'PUT'))).pipe(
      mergeMap<Response, Observable<T>>((res) => {
        if (res.status !== HTTPStatus.OK && res.status !== HTTPStatus.Created) {
          throwError(getResponseError(res))
        }
        return from(res.json() as Promise<T>)
      })
    )
  }

  delete(p: RequestProps): Observable<boolean> {
    return from(fetch(this.request(p, 'DELETE'))).pipe(
      map<Response, boolean>((res) => {
        if (
          res.status !== HTTPStatus.OK &&
          res.status !== HTTPStatus.NoContent &&
          res.status !== HTTPStatus.Accepted
        ) {
          throwError(getResponseError(res))
        }
        return true
      })
    )
  }

  private request(rp: RequestProps, method: 'GET' | 'POST' | 'PUT' | 'DELETE'): Request {
    if (!rp.type && (method === 'POST' || method === 'PUT')) {
      rp.type = 'json'
    }

    return new Request(rp.url, {
      method,
      headers: this.headers(rp),
      body: this.body(rp),
      ...this.options(rp.options),
    })
  }

  private headers(rp: RequestProps): Headers {
    const h = new Headers()

    if (rp.headers) {
      rp.headers.forEach((v, k) => h.append(k, v))
    }
    const activeUserCircle = window.localStorage.getItem('selected user circle')
    const userCircle = activeUserCircle ? JSON.parse(activeUserCircle) : undefined
    if (userCircle && userCircle.id) {
      h.append('usercircle', userCircle.id)
    }
    const token = window.sessionStorage.getItem(CSRF_KEY)
    h.append('X-CSRFToken', token ?? '')

    if (rp.type === 'json') {
      h.set('Content-Type', 'application/json')
    }

    return h
  }

  private body(rp: RequestProps): any {
    if (!rp.body) {
      return undefined
    }

    switch (rp.type) {
      case 'json':
        return JSON.stringify(rp.body)
      case 'form':
        // eslint-disable-next-line no-case-declarations
        const form = new FormData()
        Object.keys(rp.body).forEach((k) => form.append(k, rp.body[k]))
        return form
      case 'text':
        return rp.body
      case 'binary':
      default:
        return ''
    }
  }

  private options(os: RequestPropsOptions | undefined): RequestPropsOptions | {} {
    let opt: RequestPropsOptions = {
      credentials: 'include',
    }

    if (os) {
      opt = Object.assign(opt, os)
    }

    return opt
  }
}

type RequestPropsOptions = {
  cache?: RequestCache
  credentials?: RequestCredentials
  headers?: HeadersInit
  integrity?: string
  keepalive?: boolean
  // method?: string
  mode?: RequestMode
  redirect?: RequestRedirect
  referrer?: string
  referrerPolicy?: ReferrerPolicy
  signal?: AbortSignal | null
  window?: any
}

export type RequestProps = {
  url: string
  headers?: Map<string, string>
  type?: 'json' | 'form' | 'text' | 'binary'
  body?: any
  options?: RequestPropsOptions
}
