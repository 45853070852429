import i18n from '../../../i18n'

export enum Movement {
  never = -1,
  jolting,
  rigidity,
  rigidityJolting,
  automatism,
  hypotonia,
  undefined,
}

export const movementTypes = (): Record<Movement, string> => ({
  [Movement.never]: i18n.t('never'),
  [Movement.jolting]: i18n.t('jolting'),
  [Movement.rigidity]: i18n.t('rigidity'),
  [Movement.rigidityJolting]: i18n.t('rigidityJolting'),
  [Movement.automatism]: i18n.t('automatism'),
  [Movement.hypotonia]: i18n.t('hypotonia'),
  [Movement.undefined]: i18n.t('undefined'),
})
