export type TypeExternalProfessionalProps = {
  id: string
  name: string
}

export class TypeExternalProfessional {
  private readonly _id: string
  private _name: string

  constructor(props: TypeExternalProfessionalProps) {
    this._id = props.id
    this._name = props.name
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }
}
