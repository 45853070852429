import { Box, Table, TableContainer } from '@mui/material'
import { Head } from './Head'

import React from 'react'

import { Body } from './Body'
import { Pagination } from '../../../components/table/Pagination'
import { Field } from 'components/table'

import { Search } from 'react-router-dom'
import { Actions, Sort, Pager, Action } from '../../../components/table_type/types'

const DEFAULT_ROW_KEY = 'id'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type TableProps<T, Q> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly autocompleteItems?: string[]
  readonly autocompleteLabel?: string
  readonly autocompleteAction?: Action
  readonly actions?: Actions<T>
  readonly search?: Search
  readonly sort?: Sort<T>
  readonly pager?: Pager
  readonly fieldsInDraggable?: boolean
  readonly isLoading?: boolean
  readonly isPreventDefault?: boolean
  readonly handlerChecked?: (key: string) => void
  readonly checked: Record<string, boolean>
}

export function AppTable<T extends Record<string, any>, Q extends Record<string, any>>(
  props: TableProps<T, Q>
) {
  return (
    <>
      <TableContainer>
        <Table
          style={{ borderCollapse: 'separate', borderSpacing: '0 20px', borderColor: '#68b3e0' }}
        >
          <Head fields={props.fields} sort={props.sort} actions={props.actions} />
          <Body
            actions={props.actions}
            fields={props.fields}
            items={props.items}
            rowKeyField={props.rowKeyField || DEFAULT_ROW_KEY}
            handlerChecked={props.handlerChecked}
            checked={props.checked}
          />
        </Table>
      </TableContainer>
      <Box display={'flex'} justifyContent={'flex-end'}>
        {props.pager && <Pagination {...props.pager} />}
      </Box>
    </>
  )
}
