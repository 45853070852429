import { Container } from '../../common/container/Container'

export type QueryContainerConfig = {
  moduleFullUrl: string
}

export type VisitAuditProps = {
  parentContainer: Container
  config: QueryContainerConfig
}

export const AUDIT_MODULE = Symbol('AUDIT_MODULE')
export const VISIT_AUDIT_API_KEY = Symbol('VISIT_AUDIT_API_KEY')
export const VISIT_AUDIT_SERVICE_KEY = Symbol('VISIT_AUDIT_SERVICE_KEY')
