export type MenuProps = {
  wall: boolean
  patientData: boolean
  participant: boolean
  chats: boolean
  querys: boolean
  calendar: boolean
}

export class MenuConfig {
  private _wall: boolean
  private _patientData: boolean
  private _participant: boolean
  private _chats: boolean
  private _querys: boolean
  private _calendar: boolean

  constructor(p: MenuProps) {
    this._wall = p.wall
    this._patientData = p.patientData
    this._participant = p.participant
    this._chats = p.chats
    this._querys = p.querys
    this._calendar = p.calendar
  }

  get wall(): boolean {
    return this._wall
  }

  set wall(value: boolean) {
    this._wall = value
  }

  get patientData(): boolean {
    return this._patientData
  }

  set patientData(value: boolean) {
    this._patientData = value
  }

  get participant(): boolean {
    return this._participant
  }

  set participant(value: boolean) {
    this._participant = value
  }

  get chats(): boolean {
    return this._chats
  }

  set chats(value: boolean) {
    this._chats = value
  }

  get querys(): boolean {
    return this._querys
  }

  set querys(value: boolean) {
    this._querys = value
  }

  get calendar(): boolean {
    return this._calendar
  }

  set calendar(value: boolean) {
    this._calendar = value
  }
}
