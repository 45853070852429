import { Container } from '../../common/container/Container'

export type DentistContainerConfig = {
  moduleFullUrl: string
}

export type DentistProps = {
  parentContainer: Container
  config: DentistContainerConfig
}

export const DENTIST_MODULE = Symbol('DENTIST_MODULE')
export const DENTIST_API_KEY = Symbol('DENTIST_API_KEY')
export const DENTIST_SERVICE_KEY = Symbol('DENTIST_SERVICE_KEY')
