import { Box, Button, Chip, TextField } from '@mui/material'
import styles from '../user-profile/Editor.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import React, { ChangeEvent } from 'react'
import { Alert } from '@mui/material'
import { UserDTO } from '../../modules/users/models/User'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import style from './CircleRegister.module.css'
import fileIcon from '../../assets/left_menu/add-form-act.svg'
import searchIcon from '../../assets/resource_icons/search_icon.svg'
import participantIcon from '../../assets/left_menu/circulos-admin-act.svg'
import {Circle} from "../../modules/circle/models/Circle";

type PathologyInterface = {
  key: string
  value: string
}
type CircleRegisterHomeProps = {
  circleToAdd: UserDTO
  userToAdd: UserDTO
  handleInputCircle: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    user: UserDTO,
    setState: (user: UserDTO) => void
  ) => void
  setCircleToAdd: (user: UserDTO) => void
  selectedPathology: string
  setSelectedPathology: (selectedPathology: string) => void
  pathologies: PathologyInterface[]
  circleToAddPathologies: Circle[]
  cipaError: boolean
  cipaErrorText: string
  getCitizenData: (id: string, type: string) => void
  handleAddCircle: () => void
  handleVisit: () => void
  handleAccessParticipant: () => void
  handleSearchWithCip: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    user: UserDTO,
    setState: (user: UserDTO) => void
  ) => void
}

export function CircleRegisterHome(props: CircleRegisterHomeProps) {
  const { t } = useTranslation()

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Box bgcolor="white" m="1rem">
          <Box mb={3} className={styles.textFieldBox} display={'flex'}>
            <p className={styles.homeLabel}>{t('Búsqueda por CIP')}</p>
            <Box className={styles.cipContainer}>
              <TextField
                id={'cip'}
                key={'cip'}
                name={'cip' || 'dni'}
                className={styles.cipField}
                value={props.circleToAdd.cip || props.circleToAdd.dni}
                type="text"
                onChange={(e) =>
                  props.handleSearchWithCip(e, props.circleToAdd, props.setCircleToAdd)
                }
                required={true}
                variant={'outlined'}
                size={'small'}
              />
              <Box className={styles.searchButton}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  handler={() =>
                    props.getCitizenData(
                      props.circleToAdd.cip ? props.circleToAdd.cip : props.circleToAdd.dni,
                      props.circleToAdd.cip ? 'cim' : 'nif'
                    )
                  }
                  type={'button'}
                  label={t('search')}
                />
              </Box>
            </Box>
          </Box>
          <Box
            mb={3}
            className={styles.cipContainer}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <p className={styles.homeLabel}>{t('patientName')}</p>
            <TextField
              id={'name'}
              key={'name'}
              name="name"
              className={styles.textField}
              style={{ backgroundColor: '#f5f3f3' }}
              value={props.circleToAdd.name+' '+props.circleToAdd.lastname}
              type="name"
              // onChange={(e) => props.handleInputCircle(e, props.circleToAdd, props.setCircleToAdd)}
              required={true}
              variant={'outlined'}
              size={'small'}
              disabled={true}
            />
          </Box>
          {props.cipaError && (
            <Box mb={3}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <Alert severity="error">"{props.cipaErrorText}"</Alert>
            </Box>
          )}
          <Box mb={3} className={styles.cipContainer} display={'flex'}>
            {props.circleToAddPathologies.length > 0 ? (
              <>
                <p className={styles.label}>{t('Circulos a los que pertenece')}</p>
                <Box display={'flex'}>
                  {props.circleToAddPathologies.map((circle) => (
                    /* <TextField
                    id={'pathology'}
                    key={'pathology'}
                    name="pathology"
                    className={styles.textField}
                    value={circle.name}
                    type="text"
                    required={true}
                    variant={'outlined'}
                    size={'small'}
                    disabled={true}
                  /> */
                    <Chip
                      key={circle.id}
                      style={{ backgroundColor: '#eae9f0', marginRight: 8 }}
                      label={circle.name}
                    />
                  ))}
                </Box>
              </>
            ) : (
              ''
            )}
          </Box>
        </Box>
        <Box className={style.buttonsContainer}>
          <Box style={{ display: 'flex' }}>
            <Box
              style={{
                width: window.innerWidth > 1551 ? '80px' : '50px',
                height: window.innerWidth > 1551 ? '80px' : '60px',
              }}
              className={style.imageButtons}
            >
              <img
                src={searchIcon}
                style={{
                  width: window.innerWidth > 1551 ? 50 : 30,
                  height: window.innerWidth > 1551 ? 50 : 30,
                }}
              />
            </Box>
            <Button
              style={{
                width: window.innerWidth > 1551 ? '300px' : '200px',
                height: window.innerWidth > 1551 ? '80px' : '50px',
              }}
              disabled={props.circleToAdd.name === ''}
              onClick={props.handleVisit}
              className={style.buttons}
            >
              <span
                style={{ fontFamily: 'Poppins', fontSize: window.innerWidth > 1220 ? '' : '12px' }}
              >
                Acceder en modo consulta
              </span>
            </Button>
          </Box>
          <Box style={{ display: 'flex' }}>
            <Box
              style={{
                width: window.innerWidth > 1551 ? '80px' : '50px',
                height: window.innerWidth > 1551 ? '80px' : '60px',
              }}
              className={style.imageButtons}
            >
              <img
                src={participantIcon}
                style={{
                  width: window.innerWidth > 1551 ? 50 : 30,
                  height: window.innerWidth > 1551 ? 50 : 30,
                }}
              />
            </Box>
            <Button
              style={{
                width: window.innerWidth > 1551 ? '300px' : '200px',
                height: window.innerWidth > 1551 ? '80px' : '50px',
              }}
              disabled={props.circleToAdd.name === ''}
              onClick={props.handleAccessParticipant}
              className={style.buttons}
            >
              <span
                style={{ fontFamily: 'Poppins', fontSize: window.innerWidth > 1220 ? '' : '12px' }}
              >
                Acceder como participante
              </span>
            </Button>
          </Box>
          <Box style={{ display: 'flex' }}>
            <Box
              style={{
                width: window.innerWidth > 1551 ? '80px' : '50px',
                height: window.innerWidth > 1551 ? '80px' : '60px',
              }}
              className={style.imageButtons}
            >
              <img
                src={fileIcon}
                style={{
                  width: window.innerWidth > 1551 ? 50 : 30,
                  height: window.innerWidth > 1551 ? 50 : 30,
                }}
              />
            </Box>
            <Button
              style={{
                width: window.innerWidth > 1551 ? '300px' : '200px',
                height: window.innerWidth > 1551 ? '80px' : '50px',
              }}
              disabled={props.circleToAdd.name === ''}
              onClick={props.handleAddCircle}
              className={style.buttons}
            >
              <span
                style={{ fontFamily: 'Poppins', fontSize: window.innerWidth > 1220 ? '' : '12px' }}
              >
                Crear/añadir un nuevo círculo
              </span>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
