import { WearableType } from '../enums/WearableType'
import { Goal } from './Goal'

export interface GoalDTO {
  type: WearableType
  min: number
  max?: number
  userID: string
}

export function fromModel(g: Goal): GoalDTO {
  return {
    type: g.type,
    min: g.min,
    max: g.max,
    userID: g.userID,
  }
}

export function toModel(g: GoalDTO): Goal {
  return new Goal(g)
}
