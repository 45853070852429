export enum LangType {
  Spanish,
  English,
  Arab,
  Mandarin,
  Hindi,
}

export const langTypes = (): Record<LangType, string> => ({
  [LangType.Spanish]: 'Spanish',
  [LangType.English]: 'English',
  [LangType.Arab]: 'Arab',
  [LangType.Mandarin]: 'Mandarin',
  [LangType.Hindi]: 'Hindi',
})
