import { User } from '../../../modules/users/models/User'
import React from 'react'
import { Roles } from '../../../modules/users/enums/Roles'
import { ListItemButton, ListItemText } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import styles from './AsignResource.module.css'
import { capitalizeWords } from '../../../common/utils/capitalizeWords/CapitalizeWords'
import { RowItem } from '../../../features/form-generator/asign-form-section/AssignFormSection'
import { FileDTO } from '../../../modules/files/models/FileDTO'
import { UserWithRelatersCustom } from '../../form-generator/asign-form-section/AssignCard'

type ListItemButtonProps = {
  isRelatedUserSelected: (userId: string, circleId: string | null, userCircleID: string) => boolean
  usersWithRelaters: UserWithRelatersCustom[]
  f: UserWithRelatersCustom
  handlerRoleIcon: (i: User, isUserSelected: boolean, role: Roles) => JSX.Element
  relatedUserRef: React.MutableRefObject<string>
  handlerAddRelatedUser: (
    u: User,
    f: User,
    circle: string,
    isSelected: boolean,
    userCircleID: string
  ) => void
  selected: RowItem[]
  parentFile: FileDTO
}

export const LisItemButtonComponent = ({
  f,
  isRelatedUserSelected,
  usersWithRelaters,
  handlerRoleIcon,
  relatedUserRef,
  handlerAddRelatedUser,
  selected,
  parentFile,
}: ListItemButtonProps) => {
  const listItems = f.relaters.map((ru) => {
    const isCurrentCardUserSelected = selected.some((sel) => sel.id === f.user.id)
    const isCurrentUserRelatedUserSelected = isRelatedUserSelected(
      ru.relater.id,
      f.circleName,
      f.userCircleID
    )
    const isUserFamilyOrTutor = ru.relaterRole === Roles.FamilyOrTutor
    const isDisabled =
      isCurrentUserRelatedUserSelected && isUserFamilyOrTutor && isCurrentCardUserSelected

    return (
      <ListItemButton
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        disabled={isDisabled || (parentFile?.sharedWith && parentFile.sharedWith.length > 0)}
        key={ru.relater.id + uuidv4()}
        className={styles.relatersList}
      >
        {handlerRoleIcon(ru.relater, isCurrentUserRelatedUserSelected, ru.relaterRole)}
        <ListItemText
          style={{
            color: isCurrentUserRelatedUserSelected ? '#f37e31' : '',
            flex: 1,
            minWidth: '100px',
            padding: '0 8px',
          }}
          onClick={() => {
            relatedUserRef.current = ru.relater.id
            handlerAddRelatedUser(
              ru.relater,
              f.user,
              f.circleName,
              !isCurrentUserRelatedUserSelected,
              f.userCircleID
            )
          }}
          className={styles.relaters}
          primary={
            <span
              style={{
                fontSize: window.innerWidth <= 1533 ? '0.6rem' : '0.8rem',
              }}
            >
              {capitalizeWords(ru.relater.name)}
            </span>
          }
        />
      </ListItemButton>
    )
  })

  return <>{listItems}</>
}
