import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { PatientCalendarConfigTable as PCCT } from '../../features/patient-calendar-config'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function PatientCalendarConfigList(props: RouteProps) {
  const { id } = useParams()
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <PCCT />
    </>
  )
}
