export enum WearableType {
  Steps = 1,
  Calories,
  Distance,
  SleepHours,
  RestingPulse,
  OxygenSaturation,
  Weight,
  Fat,
  BloodPressure,
  Temperature,
}

export const wereableTypes = (): Record<WearableType, string> => ({
  [WearableType.Steps]: 'steps',
  [WearableType.Calories]: 'calories',
  [WearableType.Distance]: 'distance',
  [WearableType.SleepHours]: 'sleepHours',
  [WearableType.RestingPulse]: 'restingPulse',
  [WearableType.OxygenSaturation]: 'oxygenSaturation',
  [WearableType.Weight]: 'weight',
  [WearableType.Fat]: 'fat',
  [WearableType.BloodPressure]: 'bloodPressure',
  [WearableType.Temperature]: 'temperature',
})
