import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import genericStyle from '../../../../common/utils/generic.module.css'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import style from '.././AddNewCircle.module.css'
import { Header } from '../../../../components/header/Header'
import { CircleDTO, emptyCircleDTO, fromModel } from '../../../../modules/circle/models/CircleDTO'
import CustomStepper from '.././CustomStepper'
import { steps } from '../Steps'
import ModalAddNewSymptoms from './ModalAddNewSymptoms'
import { DeletingModal } from '../../../../components/modal/DeletingModal'

// Importaciones imagenes
import addButton from '../../../../assets/esfera/buttons/add-icon.svg'
import deletButton from '../../../../assets/newCircle/remove_icon.svg'
import { getIconFromAlt, SymptomPicture } from './SymptomPicture'
import { getCircleContainer } from '../../../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../../../modules/circle'
import { SymptomConfig } from '../../../../modules/circle/models/circleConfig/SymptomConfig'
import {
  ROUTE_ADD_NEW_CIRCLE_FORM_CONFIGURATOR,
  ROUTE_CIRCLE_CONFIGURATOR,
} from '../../../../routes/routes-constants'
import { SymptomService } from '../../../../modules/patient-data/services/SymptomService'
import { SYMPTOM_SERVICE_KEY } from '../../../../modules/patient-data'
import { getPatientDataContainer } from '../../../../container/patient-data-module'

const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
const symptomService = getPatientDataContainer().get<SymptomService>(SYMPTOM_SERVICE_KEY)
interface BoxSymptomProps {
  title: string
  pictures: SymptomPicture[] | undefined
  handleDeleteSymptom: () => void
}

const BoxSymptoms: React.FC<BoxSymptomProps> = ({ title, pictures, handleDeleteSymptom }) => {
  return (
    <Box className={style.boxContainerSymptoms}>
      <Box className={style.leftContent}>
        <p className={style.symptomName}>{title}</p>
      </Box>
      <Box className={style.middleContent}>
        {pictures?.map((picture, index) => (
          <img
            key={picture.alt}
            src={picture.picture}
            alt={picture.alt}
            className={style.smallImage}
          />
        ))}
      </Box>
      <Box className={style.rightContent}>
        {/* <IconButton>
          <img src={editButton} alt="Edit icon" className={style.iconImage} />
        </IconButton> */}
        <IconButton onClick={handleDeleteSymptom}>
          <img src={deletButton} alt="Delete icon" className={style.iconImage} />
        </IconButton>
      </Box>
    </Box>
  )
}

export function AddNewCircleSymptomsConfigurator() {
  const { t } = useTranslation()
  // get circleDraftId from local storage
  const circleDraftId = localStorage.getItem('circleDraftID')

  const navigate = useNavigate()
  const [circleDraft, setCircleDraft] = useState<CircleDTO>(emptyCircleDTO())
  const [isModalSymptomOpen, setModalTemplateFormOpen] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [symptomToDelete, setSymptomToDelete] = useState<{
    symptomID: string
    symptomName: string
  }>({ symptomID: '', symptomName: '' })

  const openDeleteModal = (id: string, name: string) => {
    setSymptomToDelete({ symptomID: id, symptomName: name })
    setDeleteModalOpen(true)
  }

  const closeDeleteModal = () => {
    setSymptomToDelete({ symptomID: '', symptomName: '' })
    setDeleteModalOpen(false)
  }

  useEffect(() => {
    if (circleDraftId) {
      circleService.getByCircleDraftID(circleDraftId).subscribe((circle) => {
        if (!circle) return
        setCircleDraft(fromModel(circle))
      })
    }
  }, [isModalSymptomOpen])

  const handleDeleteSymptom = (id: string, name: string) => {
    openDeleteModal(id, name)
  }

  const handleconfirmDelete = () => {
    const circleDraftAux: CircleDTO = { ...circleDraft }
    circleDraftAux.symptoms = circleDraft.symptoms?.filter(
      (symptom) => symptom.name !== symptomToDelete.symptomName
    )
    // borramos primero el sintoma del modulo de sintomas
    symptomService.delete(symptomToDelete.symptomID).subscribe(() => {
      circleService.updateCircleDraft(circleDraftAux).subscribe((res) => {
        setCircleDraft(circleDraftAux)
      })
    })

    closeDeleteModal()
  }

  const handleNextStepAndConvertToCircle = () => {
    circleService.saveCircleDraft(circleDraft.id).subscribe((res) => {
      navigate(ROUTE_CIRCLE_CONFIGURATOR)
    })
  }

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Header label={t('symptomConfigurator')} />
        <Box>
          <CustomStepper steps={steps} activeStep={4} />
        </Box>
        <Box style={{ padding: '0 24px' }}>
          <Divider light style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
          <Box className={style.symptomsContainer}>
            <AppButton
              theme={ButtonTheme.newEvent}
              type={'button'}
              label={t('addSymptoms')}
              marginStartIcon={{ marginRight: '10px' }}
              startIcon={addButton}
              handler={() => setModalTemplateFormOpen(true)}
              vertical={true}
            />
            <ModalAddNewSymptoms
              open={isModalSymptomOpen}
              onClose={() => setModalTemplateFormOpen(false)}
              circleDraft={circleDraft}
            />
          </Box>
        </Box>
        <DeletingModal
          type={t('symptom')}
          open={isDeleteModalOpen}
          handleClose={closeDeleteModal}
          handleDelete={handleconfirmDelete}
        />
        <Box style={{ paddingTop: '0' }}>
          {circleDraft.symptoms?.map((symptom: SymptomConfig) => (
            <BoxSymptoms
              key={symptom.name}
              title={symptom.name}
              pictures={getIconFromAlt(symptom.idIcon || '')}
              handleDeleteSymptom={() => handleDeleteSymptom(symptom.idSymptom, symptom.name)}
            />
          ))}
        </Box>

        <Box display="flex" style={{ justifyContent: 'space-between' }}>
          <AppButton
            theme={ButtonTheme.whiteAndBlue}
            type={'button'}
            label={t('saveDraft')}
            marginStartIcon={{ marginRight: '10px' }}
            handler={() => navigate(ROUTE_CIRCLE_CONFIGURATOR)}
            vertical={true}
          />{' '}
          <Box style={{ display: 'flex' }}>
            <Box style={{ marginRight: '20px' }}>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('backOneToResources')}
                marginStartIcon={{ marginRight: '10px' }}
                handler={() => {
                  navigate(ROUTE_ADD_NEW_CIRCLE_FORM_CONFIGURATOR)
                }}
                vertical={true}
              />
            </Box>
            <Box>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('finish')}
                marginStartIcon={{ marginRight: '10px' }}
                handler={() => handleNextStepAndConvertToCircle()}
                vertical={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
