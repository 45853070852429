import { Container } from '../../common/container/Container'

export type SettingContainerConfig = {
  moduleFullUrl: string
}

export type SettingProps = {
  parentContainer: Container
  config: SettingContainerConfig
}

export const SETTING_MODULE = Symbol('SETTING_MODULE')
export const SETTING_API_KEY = Symbol('SETTING_API_KEY')
export const SETTING_SERVICE_KEY = Symbol('SETTING_SERVICE_KEY')
