import i18n from '../../../i18n'

export enum IsDirectoryType {
  File = 0,
  Directory = 1,
}

export const directoryTypes = (): Record<IsDirectoryType, string> => ({
  [IsDirectoryType.File]: i18n.t('file'),
  [IsDirectoryType.Directory]: i18n.t('Directory'),
})
