import React, { ReactNode, useEffect, useState } from 'react'
import { getUserCircleContainer } from '../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  IUserCircleService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
  USER_CIRCLE_SERVICE_KEY,
} from '../../../modules/user-circle'
import {
  emptyUserCircleWithCircleAndUserDTO,
  UserCircleWithCircleAndUserDTO,
} from '../../../modules/user-circle/models/UserCircleWithCircleAndUserDTO'
import { UserCircleWithRelaters } from '../../../modules/user-circle/models/UserCircleWithRelaters'
import { CSRF_KEY, ILoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../modules/users'
import { getUserContainer } from '../../../container/user-module'

const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

type ActiveUserContextProviderProps = {
  children: ReactNode
}
export type ActiveUserContextType = {
  activeUser: UserCircleWithCircleAndUserDTO
  setActiveUser: (user: UserCircleWithCircleAndUserDTO) => void
  activeUserCircleParticipants: UserCircleWithRelaters | undefined
}

export const ActiveUserContext = React.createContext<ActiveUserContextType>({
  activeUser: emptyUserCircleWithCircleAndUserDTO(),
  setActiveUser: () => {},
  activeUserCircleParticipants: undefined,
})
export const ActiveUserContextProvider: React.FC<ActiveUserContextProviderProps> = ({
  children,
}) => {
  const user = UserCircleActiveService.getActiveFullUserCircle()
  const [activeUserCircleParticipants, setActiveUserCircleParticipants] =
    useState<UserCircleWithRelaters>()
  const [activeUser, setActiveUser] = useState<UserCircleWithCircleAndUserDTO>(
    user ?? emptyUserCircleWithCircleAndUserDTO()
  )

  // const loggedUser = loggedUserService.get()

  useEffect(() => {
    setTimeout(() => {}, 2000)
    loggedUserService.getCSRFToken().subscribe((res) => {
      if (res) {
        sessionStorage.setItem(CSRF_KEY, res)
      }
      userCircleService
        .getByUserIDWithRelaters(activeUser.user.id ?? '')
        .subscribe((userCircles) => {
          if (!userCircles) return
          const activeUserCircleParticipants = userCircles.find(
            (userCircle) => userCircle.circleID === activeUser.circle.id
          )
          setActiveUserCircleParticipants(activeUserCircleParticipants)
        })
    })
  }, [activeUser])

  return (
    <ActiveUserContext.Provider value={{ activeUser, setActiveUser, activeUserCircleParticipants }}>
      {children}
    </ActiveUserContext.Provider>
  )
}
