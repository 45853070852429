import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { ProfessionalType } from '../models/ProfessionalType'
import { IProfessionalTypeApi } from '../api/ProfessionalTypeApi'
import { ProfessionalTypeUser, ProfessionalTypeUserQuery } from '../models/ProfessionalTypeUser'

type Props = {
  apiKey: symbol
}

export interface IProfessionalTypeService extends IInit {
  getAll(q: Query<ProfessionalType>): Observable<ProfessionalType[]>

  getByName(name: string): Observable<ProfessionalType | undefined>

  getByID(id: string): Observable<ProfessionalType | undefined>

  update(e: ProfessionalType): Observable<ProfessionalType | undefined>

  createProfessionalTypeUser(e: ProfessionalTypeUser): Observable<ProfessionalTypeUser | undefined>

  updateProfessionalTypeUser(e: ProfessionalTypeUser): Observable<ProfessionalTypeUser | undefined>

  deleteProfessionalTypeUserByUserID(id: string): Observable<boolean>

  getUserProfessionalTypeByUserID(userID: string): Observable<ProfessionalTypeUser | undefined>

  getUserProfessionalTypeByProfessionalTypeID(
    professionalTypeID: string
  ): Observable<ProfessionalTypeUser | undefined>

  getListUserProfessionalType(
    q: Query<ProfessionalTypeUserQuery>
  ): Observable<ProfessionalTypeUser[]>

  getProfessionalTypeByUserID(userID: string): Observable<ProfessionalType | undefined>

  delete(id: string): Observable<boolean>

  create(e: ProfessionalType): Observable<ProfessionalType | undefined>
}

export class ProfessionalTypesService implements IProfessionalTypeService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IProfessionalTypeApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IProfessionalTypeApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  create(e: ProfessionalType): Observable<ProfessionalType | undefined> {
    return this._api.create(e)
  }

  getAll(q: Query<ProfessionalType>): Observable<ProfessionalType[]> {
    return this._api.getAll(q).pipe()
  }

  getByName(name: string): Observable<ProfessionalType | undefined> {
    return this._api.getByName(name)
  }

  getByID(id: string): Observable<ProfessionalType | undefined> {
    return this._api.getByID(id)
  }

  update(e: ProfessionalType): Observable<ProfessionalType | undefined> {
    return this._api.update(e)
  }

  createProfessionalTypeUser(
    e: ProfessionalTypeUser
  ): Observable<ProfessionalTypeUser | undefined> {
    return this._api.createProfessionalTypeUser(e)
  }

  updateProfessionalTypeUser(
    e: ProfessionalTypeUser
  ): Observable<ProfessionalTypeUser | undefined> {
    return this._api.updateProfessionalTypeUser(e)
  }

  deleteProfessionalTypeUserByUserID(id: string): Observable<boolean> {
    return this._api.deleteProfessionalTypeUserByUserID(id)
  }

  getUserProfessionalTypeByUserID(userID: string): Observable<ProfessionalTypeUser | undefined> {
    return this._api.getUserProfessionalTypeByUserID(userID)
  }

  getUserProfessionalTypeByProfessionalTypeID(
    professionalTypeID: string
  ): Observable<ProfessionalTypeUser | undefined> {
    return this._api.getUserProfessionalTypeByProfessionalTypeID(professionalTypeID)
  }

  getListUserProfessionalType(
    q: Query<ProfessionalTypeUserQuery>
  ): Observable<ProfessionalTypeUser[]> {
    return this._api.getListUserProfessionalType(q)
  }

  getProfessionalTypeByUserID(userID: string): Observable<ProfessionalType | undefined> {
    return this._api.getProfessionalTypeByUserID(userID)
  }
}

export class IProfessionalTypesService {}
