import React, { useEffect, useState } from 'react'
import { Avatar, Box } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import style from './Header.module.css'
import { useTranslation } from 'react-i18next'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { User, UserQuery } from '../../modules/users/models/User'
import { GenericTooltip } from '../../components/generic-tooltip'
import { Query, QueryParam } from '../../common/api/Query'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Consulta } from '../../modules/messenger_consulta/models/Consulta'
import { SwitchCloseQuery } from './SwitchStyle'

const userContainer = getUserContainer()
const usersService = userContainer.get<UserService>(USER_SERVICE_KEY)

export type ConversationHeaderProps = {
  headerName: string
  avatarUrl?: string
  creatorID: string
  description: string
  currentConversation: Consulta
  handleEdit: () => void
  handleDelete: () => void
  handleCloseQuery: () => void
  conversationUsers: string[]
}
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function Header(props: ConversationHeaderProps): JSX.Element {
  const loggedUser = loggedUserService.get()
  const { t } = useTranslation()

  const [conversationCreator, setConversationCreator] = useState<User>()
  const [conversationUsers, setConversationsUsers] = useState<User[]>([])

  useEffect(() => {
    usersService.getByID(props.creatorID).subscribe((res) => {
      setConversationCreator(res)
    })
    usersService
      .getFilteredList(
        new Query({ query: [new QueryParam<UserQuery>('ids', props.conversationUsers)] })
      )
      .subscribe((res) => setConversationsUsers(res.items))
  }, [props.creatorID, props.conversationUsers, props.currentConversation])

  return (
    <Box
      className={style.divHeader}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box className={style.divHeaderLeft} display="flex" alignItems="center">
        <Avatar src={props.avatarUrl} style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF' }} />
        <div>
          <Box display="flex" alignItems="baseline">
            <Box className={style.contactFullName} component="h2">
              {props.headerName}
            </Box>
          </Box>
        </div>
      </Box>
      <Box className={style.conversationLeft} display="flex">
        <Box className={style.conversationOptions} display="flex" alignItems={'center'}>
          {loggedUser &&
          loggedUser?.roles.filter((role) => role === 'professionalSMS').length > 0 ? (
            <>
              {props.currentConversation.closed ? 'Reabrir consulta' : ' Cerrar consulta'}
              <SwitchCloseQuery
                checked={props.currentConversation.closed}
                onClick={() => {
                  props.handleCloseQuery()
                }}
              />
            </>
          ) : (
            ''
          )}
          <div>
            <GenericTooltip
              values={conversationUsers.map((u) => u.name)}
              icon={<PersonIcon color={'primary'} />}
              noUseFab={true}
            />
          </div>
        </Box>
        <Box component="p">{t(`consultationOpenedBy`) + ' ' + conversationCreator?.name}</Box>
      </Box>
    </Box>
  )
}
