import { v4 as uuidv4 } from 'uuid'
import { ActivePlan } from './ActivePlan'

export interface ActivePlanDTO {
  id: string
  userCircleID: string
  userProfessionalID: string
  professionalMessage: string
  status: string
  startDate: Date
  finishDate: Date
  reason: string
  observation: string
}

export function emptyActivePlanDTO() {
  return {
    id: uuidv4(),
    userCircleID: '',
    userProfessionalID: '',
    professionalMessage: '',
    status: '',
    startDate: new Date(),
    finishDate: new Date(),
    reason: '',
    observation: '',
  }
}

export function fromModel(e: ActivePlan): ActivePlanDTO {
  return {
    id: e.id,
    userCircleID: e.userCircleID,
    userProfessionalID: e.userProfessionalID,
    professionalMessage: e.professionalMessage,
    status: e.status,
    startDate: e.startDate,
    finishDate: e.finishDate,
    reason: e.reason,
    observation: e.observation,
  }
}

export function toModel(d: ActivePlanDTO): ActivePlan {
  return new ActivePlan(d)
}

export function toModelArray(activePlanArr: ActivePlanDTO[]): ActivePlan[] {
  const auxArray: ActivePlan[] = []
  activePlanArr.forEach((value) => auxArray.push(toModel(value)))
  return auxArray
}
