import { Landmark } from './Landmark'
import { v4 as uuidv4 } from 'uuid'

export interface LandmarkDTO {
  id: string | undefined
  title: string
  date: Date
  endDate: Date
  state: boolean
  userID: string
}

export function emptyLandMarkDTO() {
  return {
    id: uuidv4(),
    title: '',
    date: new Date(),
    endDate: new Date(),
    state: false,
    userID: '',
  }
}

export function fromModel(d: Landmark): LandmarkDTO {
  return {
    id: d.id,
    title: d.title,
    date: d.date,
    endDate: d.endDate,
    state: d.state,
    userID: d.userID,
  }
}

export function toModel(d: LandmarkDTO): Landmark {
  return new Landmark(d)
}
