import { ProfessionalType } from './ProfessionalType'
import { v4 as uuidv4 } from 'uuid'

export interface ProfessionalTypeDTO {
  id: string
  name: string
  description: string
}

export function fromModel(m: ProfessionalType): ProfessionalTypeDTO {
  return {
    id: m.id,
    name: m.name,
    description: m.description,
  }
}

export function toModel(d: ProfessionalTypeDTO): ProfessionalType {
  return new ProfessionalType(d)
}

export function emptyProfessionalTypeDTO() {
  return {
    id: uuidv4(),
    name: '',
    description: '',
  }
}
