import { Box, Divider, FormControl, Modal, Typography } from '@mui/material'
import style from './ActivePlan.module.css'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import React, { useEffect, useRef, useState } from 'react'
import icoDelete from '../../../assets/buttons/close.svg'
import { useTranslation } from 'react-i18next'
import { SportCenter } from '../../../modules/sport-center/models/SportCenter'
import { TrainingGroup } from '../../../modules/training-group/models/TrainingGroup'
import { ItemList } from '../../../common/models/ItemList'
import { groupColors } from '../../../common/enums/Colors'
import { TimeTable } from '../../../modules/training-group/models/TimeTable'
import { fullWeekdayTypes } from '../../../common/enums/Weekday'
import { InclusionEFDMessage } from './InclusionEFDMessage'
import { UserTrainingGroup } from '../../../modules/user-training-group/models/UserTrainingGroup'
import { Map } from './Map'
import TileLayer from 'ol/layer/Tile'
import { XYZ } from 'ol/source'
import { Map as OlMap, View as OlView } from 'ol'
import { fromLonLat } from 'ol/proj'

type SelectGroupProps = {
  open: boolean
  handlerClose: () => void
  handlerAccepted: () => void
  handlerSetGroup: (groupID: string) => void
  sportCenters: ItemList<SportCenter>
  groupsMap: Map<string, TrainingGroup[]>
  registeredUserMap: Map<string, number>
  userCircleGroup: UserTrainingGroup
  notChoosable?: boolean
}

export function SelectGroup(props: SelectGroupProps) {
  const { t } = useTranslation()
  const [currentCenter, setCurrentCenter] = useState<string>('')
  const [currentGroup, setCurrentGroup] = useState<boolean[]>([])
  const [openEFDMessage, setOpenEFDMessage] = useState<boolean>(false)
  const baseLayer = useRef<TileLayer<XYZ>>(
    new TileLayer({
      source: new XYZ({
        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      }),
    })
  )

  const murciaLon = -1.130395
  const murciaLat = 37.992276

  const initialMap = useRef<OlMap>(
    new OlMap({
      target: undefined,
      layers: [baseLayer.current],
      view: new OlView({
        center: fromLonLat([murciaLon, murciaLat]),
        zoom: 10,
      }),
    })
  )

  const checkAlreadySubscribed = (group: string): boolean => {
    const groups = props.groupsMap.get(currentCenter)

    if (!groups) return true

    if (group === props.userCircleGroup.groupID) return true

    if (
      groups.find((g) => {
        const registered = props.registeredUserMap.get(g.id)
        if (!registered) return false
        return g.capacity <= registered
      })
    )
      return true

    return false
  }

  const handleSelectGroup = (index: number, group: string) => {
    const auxArr = createGroupCheckBox()
    auxArr[index] = true
    setCurrentGroup(auxArr)
    props.handlerSetGroup(group)
  }

  const createGroupCheckBox = (): boolean[] => {
    const auxArr: boolean[] = []
    props.groupsMap.get(currentCenter)?.forEach((value) => {
      auxArr.push(false)
    })
    return auxArr
  }

  useEffect(() => {
    if (currentGroup.length === 0) {
      setCurrentGroup(createGroupCheckBox())
    }

    if (!currentCenter) {
      setCurrentCenter(props.sportCenters.items[0]?.id)
    }
  }, [props.open])

  useEffect(() => {
    if (!props.open) return
    baseLayer.current = new TileLayer({
      source: new XYZ({
        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      }),
    })

    initialMap.current = new OlMap({
      target: undefined,
      layers: [baseLayer.current],
      view: new OlView({
        center: fromLonLat([murciaLon, murciaLat]),
        zoom: 10,
      }),
    })
  }, [props.open])

  useEffect(() => {
    columnDataGroup()
  }, [currentCenter])

  const getColor = (index: number): string => {
    if (index === 0) return groupColors[index]

    const auxNum = Math.trunc((index + 1) / groupColors.length)
    let num = 0
    if (auxNum > 0) {
      num = index - groupColors.length * auxNum
      return groupColors[num]
    }
    return groupColors[index]
  }

  const addCero = (n: number): string => {
    let value = '0'
    if (n.toString().length === 1) {
      value += n
    } else {
      value = n.toString()
    }
    return value
  }

  const getTime = (tt: TimeTable): string => {
    return (
      '' +
      addCero(tt.startTime.getHours()) +
      ':' +
      addCero(tt.startTime.getMinutes()) +
      ' - ' +
      addCero(tt.finishTime.getHours()) +
      ':' +
      addCero(tt.finishTime.getMinutes())
    )
  }

  const handlerOpenEFDMessage = () => {
    if (!currentGroup.find((group) => group)) return
    setOpenEFDMessage(true)
  }

  const handlerCloseEFDMessage = () => {
    setOpenEFDMessage(false)
  }

  const handlerAcceptEFDMessage = () => {
    setOpenEFDMessage(false)
    setCurrentCenter('')
    setCurrentGroup(createGroupCheckBox())
    props.handlerAccepted()
  }

  const handlerSelectCenter = (centerID: string) => {
    setCurrentCenter(centerID)
    setCurrentGroup(createGroupCheckBox())
  }

  const columnDataGroup = () => {
    return (
      <Box className={style.containerColumnSG}>
        <Typography className={style.blackBold}>
          {t('groups')}{' '}
          {props.sportCenters.items.find((center) => center.id === currentCenter)?.name}
        </Typography>
        <Box className={style.scrollContainer}>
          {props.groupsMap
            .get(
              props.sportCenters.items.find((center, indexCenter) => center.id === currentCenter)
                ?.id ?? ''
            )
            ?.map((group, groupIndex) => (
              <FormControl key={group.id} className={style.containerRowModalSG}>
                <Box
                  style={{ backgroundColor: getColor(groupIndex) }}
                  className={style.colorNameGroup}
                >
                  {group.name}
                </Box>
                <Box className={style.containerRowModalSGCustom}>
                  <Box className={style.containerRowModalNotMobile}>
                    <Box className={style.containerColumnModalOne}>
                      {group.timeTable.map((tt) => (
                        <Box key={tt.id} className={style.containerRowGroup}>
                          <Box className={style.blackBoldWeekday}>
                            {t(fullWeekdayTypes()[tt.weekDay])}
                          </Box>
                          <Box className={style.marginTime}>{getTime(tt)}</Box>
                        </Box>
                      ))}
                    </Box>
                    <Box className={style.containerColumnModalTwo}>
                      <Box className={style.containerRowGroup}>
                        <Box className={style.blackBoldWeekday}>{t('personCapacity')}</Box>
                        <Box className={style.marginNumbers}>{group.capacity}</Box>
                      </Box>
                      <Box className={style.containerRowGroup}>
                        <Box className={style.blackBoldWeekday}>{t('registered')}</Box>
                        <Box className={style.marginNumbers}>
                          {props.registeredUserMap.get(group.id)}
                        </Box>
                      </Box>
                    </Box>
                    {innerWidth > 599 && (
                      <Box className={style.containerColumnModalFinal}>
                        {!props.notChoosable && (
                          <label className={style.checkModal}>
                            <input
                              type="radio"
                              name="teleCall"
                              className={style.checkCustom}
                              disabled={checkAlreadySubscribed(group.id)}
                              onChange={() => handleSelectGroup(groupIndex, group.id)}
                              checked={currentGroup[groupIndex]}
                            />
                          </label>
                        )}
                      </Box>
                    )}
                  </Box>
                  {innerWidth < 599 && (
                    <Box className={style.containerColumnModalFinal}>
                      {!props.notChoosable && (
                        <label className={style.checkModal}>
                          <input
                            type="radio"
                            name="teleCall"
                            className={style.checkCustom}
                            disabled={checkAlreadySubscribed(group.id)}
                            onChange={() => handleSelectGroup(groupIndex, group.id)}
                            checked={currentGroup[groupIndex]}
                          />
                        </label>
                      )}
                    </Box>
                  )}
                </Box>
              </FormControl>
            ))}
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Modal open={props.open} onClose={props.handlerClose} className={style.modalMain}>
        <Box className={style.containerModalSG}>
          <Box className={style.contentHeader}>
            <img style={{ cursor: 'pointer' }} onClick={props.handlerClose} src={icoDelete} />
          </Box>
          <Box>
            <Typography className={style.headerTitle}>{t('selectSPCenterAndGroup')}</Typography>
            <Divider
              style={{
                marginRight: '15px',
                marginLeft: '15px',
                borderColor: '#f47105',
                marginBottom: '15px',
              }}
            />
          </Box>
          <Box style={{ margin: '0 10px 0 10px' }}>
            <Box className={style.containerRowHeaderSG}>
              <Box className={style.containerRow}>{t('selectGroupForAP')}</Box>
              <Box className={style.containerRowSubtitle}>
                <Box>{t('circle')}: </Box>
                <Box className={style.bottonMarginLeft}>{t('activePlan')}</Box>
              </Box>
            </Box>
            <Box className={style.containerRow}>
              <Box className={style.flexGrowFour}>
                <Box className={style.mapBorder}>
                  <Map
                    sportCenters={props.sportCenters.items}
                    selectedSportCenter={currentCenter}
                    handleSelectedSportCenter={handlerSelectCenter}
                    baseLayer={baseLayer.current}
                    initialMap={initialMap.current}
                  />
                </Box>
              </Box>
              <Box className={style.flexGrowOneOne}>{columnDataGroup()}</Box>
            </Box>
          </Box>

          {!props.notChoosable && (
            <Box className={style.containerRowButtonModalSG}>
              <Box className={style.bottonMarginLeft}>
                <AppButton
                  theme={ButtonTheme.whiteAndBlue}
                  type={'button'}
                  label={t('cancel')}
                  handler={props.handlerClose}
                />
              </Box>
              <Box className={style.bottonMarginLeft}>
                <AppButton
                  theme={ButtonTheme.newEvent}
                  type={'button'}
                  label={t('changeGroup')}
                  handler={handlerOpenEFDMessage}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
      <InclusionEFDMessage
        open={openEFDMessage}
        handleClose={handlerCloseEFDMessage}
        handelAcceptEFD={handlerAcceptEFDMessage}
      />
    </>
  )
}
