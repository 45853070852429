import { ParticipantConfig } from './ParticipantConfig'

export interface ParticipantConfigDTO {
  caseManager: number
  contentManager: number
  profSMS: number
  familiar: number
  teacher: number
  profExt: number
}

export function emptyParticipantConfigDTO() {
  return {
    caseManager: 0,
    contentManager: 0,
    profSMS: 0,
    familiar: 0,
    teacher: 0,
    profExt: 0,
  }
}

export function fromModel(e: ParticipantConfig): ParticipantConfigDTO {
  return {
    caseManager: e.caseManager,
    contentManager: e.contentManager,
    profSMS: e.profSMS,
    familiar: e.familiar,
    teacher: e.teacher,
    profExt: e.profExt,
  }
}

export function toModel(d: ParticipantConfigDTO): ParticipantConfig {
  return new ParticipantConfig(d)
}
