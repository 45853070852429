import { SharedWith } from '../models/SharedWith'
import { Container, IInit } from '../../../common/container/Container'
import { SharedWithContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { SharedWithDTO, toModel } from '../models/SharedWithDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface ISharedWithApi extends IInit {
  getByID(id: string | null): Observable<SharedWith | undefined>

  getFilteredItems(q: Query<SharedWith>): Observable<SharedWith[]>

  add(e: SharedWithDTO): Observable<SharedWith | undefined>

  update(e: SharedWithDTO): Observable<SharedWith | undefined>

  delete(e: SharedWith): Observable<boolean>

  createNewIndividualShare(e: SharedWithDTO[]): Observable<SharedWith | undefined>
}

export class SharedWithApi implements ISharedWithApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as SharedWithContainerConfig).moduleFullUrl
  }

  getByID(id: string | null): Observable<SharedWith | undefined> {
    return this._httpClient.get<SharedWith>({ url: this._url + '/' + id }).pipe(
      map<SharedWithDTO, SharedWith>((d) => {
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  add(e: SharedWithDTO): Observable<SharedWith | undefined> {
    return this._httpClient.post<SharedWith>({ url: this._url, body: e }).pipe(
      map<SharedWithDTO, SharedWith>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(e: SharedWith): Observable<boolean> {
    /* return this._httpClient.delete({ url: this._url + '/' + fromModel(e).id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    ) */

    return this._httpClient.delete({ url: this._url + '/' + '' }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getFilteredItems(q: Query<SharedWith>): Observable<SharedWith[]> {
    return this._httpClient.get<SharedWith[]>({ url: prepareURL(this._url, q) }).pipe(
      map<SharedWithDTO[], SharedWith[]>((dto) => dto.map((d) => toModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  update(e: SharedWithDTO): Observable<SharedWith | undefined> {
    return this._httpClient.put<SharedWith>({ url: this._url, body: e }).pipe(
      map<SharedWithDTO, SharedWith>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  createNewIndividualShare(e: SharedWithDTO[]): Observable<SharedWith | undefined> {
    console.log('entro a createNewIndividualShare')
    return this._httpClient
      .post<SharedWith>({ url: this._url + '/createNewIndividualShare', body: e })
      .pipe(
        map<SharedWithDTO, SharedWith>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
