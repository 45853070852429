import { Observable, Subject } from 'rxjs'
import { toModel, User, UserDTO } from '../../users/models/User'
import { Container, IInit } from '../../../common/container/Container'
import { IUserApi } from '../../users/api/UserApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UserCircleWithCircleAndUser } from '../models/UserCircleWithCircleAndUser'
import {
  toModel as toModel2,
  UserCircleWithCircleAndUserDTO,
} from '../models/UserCircleWithCircleAndUserDTO'

export interface IUserCircleActiveService extends IInit {
  getActiveObservableUser(): Observable<User | undefined>

  getActiveObservableUserCircle(): Observable<UserCircleWithCircleAndUser | undefined>

  getActiveFullUserCircle(): UserCircleWithCircleAndUser | undefined

  setActiveFullUserCircle(c: UserCircleWithCircleAndUser | undefined): void

  getActiveUser(): User | undefined

  setActiveUser(c: User | undefined): void
}

const SELECTED_USER_KEY = 'selected user'
const SELECTED_USER_CIRCLE_KEY = 'selected user circle'

export type Props = {
  apiKey: symbol
}

export class UserCircleActiveService implements IUserCircleActiveService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserApi
  private _statusService!: IStatusService

  private _activeUser: User | undefined
  private _activeUserCircle: UserCircleWithCircleAndUser | undefined
  private _userSubject = new Subject<User>()
  private _userCircleSubject = new Subject<UserCircleWithCircleAndUser>()

  constructor(p: Props) {
    this.loadCircle()
    this._apiKey = p.apiKey
  }

  init(c: Container): void {
    this._container = c
    this._api = this._container.get<IUserApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getActiveUser(): User | undefined {
    return this._activeUser
  }

  getActiveFullUserCircle(): UserCircleWithCircleAndUser | undefined {
    return this._activeUserCircle
  }

  getActiveObservableUser(): Observable<User | undefined> {
    return this._userSubject
  }

  getActiveObservableUserCircle(): Observable<UserCircleWithCircleAndUser | undefined> {
    return this._userCircleSubject
  }

  setActiveUser(c: User | undefined) {
    this.storeUser(c)
    this.userNext()
  }

  setActiveFullUserCircle(c: UserCircleWithCircleAndUser | undefined) {
    this.storeUserCircle(c)
    this.userCircleNext()
  }

  private userNext() {
    this._userSubject.next(this._activeUser)
  }

  private userCircleNext() {
    this._userCircleSubject.next(this._activeUserCircle)
  }

  private loadCircle() {
    const userdto = JSON.parse(localStorage.getItem(SELECTED_USER_KEY) ?? 'null') as UserDTO
    const userCircledto = JSON.parse(
      localStorage.getItem(SELECTED_USER_CIRCLE_KEY) ?? 'null'
    ) as UserCircleWithCircleAndUserDTO
    if (userdto) {
      this._activeUser = toModel(userdto)
      this.userNext()
    }
    if (userCircledto) {
      this._activeUserCircle = toModel2(userCircledto)
      this.userCircleNext()
    }
  }

  private storeUser(c: User | undefined) {
    const dto = c ? c.toDTO() : undefined
    if (dto) {
      localStorage.setItem(SELECTED_USER_KEY, JSON.stringify(dto))
    } else {
      localStorage.removeItem(SELECTED_USER_KEY)
    }
    this._activeUser = c
    this.userCircleNext()
  }

  private storeUserCircle(c: UserCircleWithCircleAndUser | undefined) {
    const dto = c ? c.toDTO() : undefined
    if (dto) {
      localStorage.setItem(SELECTED_USER_CIRCLE_KEY, JSON.stringify(dto))
    } else {
      localStorage.removeItem(SELECTED_USER_CIRCLE_KEY)
    }
    this._activeUserCircle = c
    this.userCircleNext()
  }
}
