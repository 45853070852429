import React from 'react'
import style from './ConfiedentialityTermStyle.module.css'
import { Box } from '@mui/material'
type ExternalAgentProps = {
  handlerEnableCheck?: (value: boolean) => void
}
export function ExternalAgent(props: ExternalAgentProps) {
  if (props.handlerEnableCheck) {
    props.handlerEnableCheck(false)
  }

  return (
    <>
      <Box className={style.loginContainer} display={'flex'}>
        <Box className={style.personalData}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <h3>CLÁUSULA DE CONFIDENCIALIDAD PARA LOS AGENTES EXTERNOS DE ESFERA</h3>
          </div>
          <div>
            La atención de los pacientes va a determinar los datos personales de categoría especial
            a los que usted pueda tener acceso durante el desarrollo de sus actividades como agente
            externo. Con la aceptación de estas cláusulas de confidencialidad, se le informa de que
            contrae las siguientes OBLIGACIONES de conformidad Reglamento (UE) 2016/679 (en adelante
            RGPD):
          </div>
          <ol>
            <li>
              Quienes intervengan en cualquier fase del tratamiento de datos de carácter personal
              están obligados al secreto respecto de éstos, y al deber de guardarlos, obligación que
              subsistirá aún después de finalizar su relación con el Centro.
            </li>
            <li>
              Los usuarios tendrán acceso autorizado únicamente a aquellos datos y recursos que
              precisen para el desarrollo de sus funciones.
            </li>
            <li>
              Queda prohibido el traslado de cualquier soporte, listado o documento con datos de
              carácter personal en los que se almacene información titularidad de la organización
              fuera de los locales de la misma sin autorización. En el supuesto de existir traslado
              o distribución de soportes y documentos se realizará cifrando dichos datos, o mediante
              otro mecanismo que impida el acceso o manipulación de la información por tercero.
            </li>
            <li>
              El incumplimiento de las obligaciones indicadas, dará lugar a la exigencia de
              responsabilidades civiles, así como a las responsabilidades penales que procedan.
            </li>
            <li>
              El compromiso de confidencialidad se aplica a los datos automatizados y no
              automatizados.
            </li>
          </ol>
          <div>
            Por todo lo anterior, ME COMPROMETO a mantener absoluta confidencialidad y reserva sobre
            cualquier dato que pudiera conocer con ocasión del desempeño de mi actividad, y declaro
            haber leído y aceptado las cláusulas precedentes y la posibilidad de revocar dicha
            autorización.
          </div>
        </Box>
      </Box>
    </>
  )
}
