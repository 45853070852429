export type TimeTableProps = {
  id: string
  weekDay: number
  startTime: Date
  finishTime: Date
}

export class TimeTable {
  private readonly _id: string
  private _weekDay: number
  private _startTime: Date
  private _finishTime: Date

  constructor(props: TimeTableProps) {
    this._id = props.id
    this._weekDay = props.weekDay
    this._startTime = props.startTime
    this._finishTime = props.finishTime
  }

  get id(): string {
    return this._id
  }

  get weekDay(): number {
    return this._weekDay
  }

  set weekDay(value: number) {
    this._weekDay = value
  }

  get startTime(): Date {
    return new Date(this._startTime)
  }

  set startTime(value: Date) {
    this._startTime = new Date(value)
  }

  get finishTime(): Date {
    return new Date(this._finishTime)
  }

  set finishTime(value: Date) {
    this._finishTime = new Date(value)
  }
}
