import { Box, Card, CardContent, TextField, Typography, Alert } from '@mui/material'
import styles from './AddGroup.module.css'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TrainingGroupDTO } from '../../modules/training-group/models/TrainingGroupDTO'
import { getUserTypeExternalProfessionalContainer } from '../../container/userTypeExternalProfessional-module'
import { UserTypeExternalProfessionalService } from '../../modules/userTypeExternalProfessional/services/UserTypeExternalProfessionalService'
import { USER_TYPE_EXTERNAL_PROFESSIONAL_SERVICE_KEY } from '../../modules/userTypeExternalProfessional'
import { isValidEmail } from '../../common/utils/strings'
import { useDebounce } from '../../hooks/debounce/useDebounce'
import { TimeTableComponent } from './TimeTableComponent'

type AddGroupProps = {
  trainingGroup: TrainingGroupDTO
  handleInputTrainingGroup: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handlerInputTrainer: (userName: string, userTypeExternProfID: string, pending: boolean) => void
  errorMessage: string
  handlerNumberTime: (value: number) => void
}

const userTyperProfessionalService =
  getUserTypeExternalProfessionalContainer().get<UserTypeExternalProfessionalService>(
    USER_TYPE_EXTERNAL_PROFESSIONAL_SERVICE_KEY
  )

export const AddGroup = ({
  trainingGroup,
  handleInputTrainingGroup,
  handlerInputTrainer,
  errorMessage,
  handlerNumberTime,
}: AddGroupProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const [userEmail, setUserEmail] = useState<string>(
    trainingGroup.trainerEmail ? trainingGroup.trainerEmail : ''
  )
  const [efdName, setEfdName] = useState<string>('')
  const [emailWasChecked, setEmailWasChecked] = useState<boolean>(false)

  const handlerCheckEmail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserEmail(event.target.value)
    trainingGroup.trainerEmail = event.target.value
    setEmailWasChecked(false)
    trainingGroup.trainerName = ''
    debouncedFunction(event.target.value)
  }

  const emailChecker = (email: string) => {
    setEfdName('')
    if (!isValidEmail(email)) return
    setEmailWasChecked(true)
    userTyperProfessionalService.trainerCheckEmail(email).subscribe((res) => {
      if (!res) return
      if (
        res.userName === '' &&
        res.userTypeExternProfID !== '40ccde9a-c301-439f-b222-ecf66b26f2fa'
      )
        return
      handlerInputTrainer(
        res ? res.userName : '',
        res ? res.userTypeExternProfID : '',
        res ? res.pending : false
      )
      setEfdName(res.userName)
    })
  }

  const debouncedFunction = useDebounce(emailChecker, 1000)

  const disableNameEFD = () => {
    if (userEmail !== '' && efdName === '' && emailWasChecked) {
      return false
    }
    return true
  }

  const nameEFDComponent = () => {
    return (
      <Box className={styles.contentFieldsCard}>
        <Typography className={styles.fieldTitleLarge}>{t('Nombre EFD')}</Typography>
        <TextField
          key="trainerName"
          variant="outlined"
          name="trainerName"
          value={trainingGroup.trainerName}
          onChange={handleInputTrainingGroup}
          inputProps={{ maxLength: 200 }}
          size={'small'}
          fullWidth
          disabled={disableNameEFD()}
        />
      </Box>
    )
  }
  useEffect(() => {
    nameEFDComponent()
  }, [trainingGroup.trainerName])

  const timeTableComponent = () => {
    return (
      <TimeTableComponent
        trainingGroup={trainingGroup}
        handlerNumberTime={handlerNumberTime}
        isLoading={isLoadingHandler}
      />
    )
  }

  const isLoadingHandler = () => {
    setIsLoading(!isLoading)
  }

  useEffect(() => {
    timeTableComponent()
  }, [isLoading])

  return (
    <Box>
      <Typography className={styles.title}>Añadir grupo</Typography>
      <Card className={styles.card}>
        <CardContent>
          <Box className={styles.fieldsCard}>
            <Box className={styles.contentFieldsCard}>
              <Typography className={styles.fieldTitle}>{t('group')}</Typography>
              <TextField
                key="group"
                variant="outlined"
                name="name"
                value={trainingGroup.name}
                onChange={handleInputTrainingGroup}
                inputProps={{ maxLength: 200 }}
                size={'small'}
                fullWidth
                required
              />
            </Box>
            <Box className={styles.contentFieldsCard}>
              <Typography className={styles.fieldTitle}>{t('Aforo')}</Typography>
              <TextField
                key="capacity"
                variant="outlined"
                name="capacity"
                type="number"
                value={trainingGroup.capacity}
                onChange={handleInputTrainingGroup}
                inputProps={{ maxLength: 200 }}
                size={'small'}
                fullWidth
                required
              />
            </Box>
          </Box>
          {timeTableComponent()}

          <Box className={styles.fieldsCard}>
            <Box className={styles.contentFieldsCard}>
              <Typography className={styles.fieldTitle}>{t('mail')}</Typography>
              <TextField
                key="trainerEmail"
                variant="outlined"
                name="trainerEmail"
                value={userEmail}
                onChange={handlerCheckEmail}
                inputProps={{ maxLength: 200 }}
                size={'small'}
                fullWidth
                required
              />
            </Box>
            {nameEFDComponent()}
          </Box>
          {errorMessage && (
            <Box mb={3}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage)}
              </Alert>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
