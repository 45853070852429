import { Container } from '../../common/container/Container'

export type UserCircleContainerConfig = {
  moduleFullUrl: string
}

export type UserCircleContainerConfigProps = {
  parentContainer: Container
  config: UserCircleContainerConfig
}

export const USER_CIRCLE_MODULE = Symbol('USER_CIRCLE_MODULE')

export const USER_CIRCLE_API_KEY = Symbol('USER_CIRCLE_API_KEY')

export const USER_CIRCLE_SERVICE_KEY = Symbol('USER_CIRCLE_SERVICE_KEY')

export const USER_CIRCLE_ACTIVE_SERVICE_KEY = Symbol('USER_CIRCLE_ACTIVE_SERVICE_KEY')
