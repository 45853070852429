import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Box, Chip, List, ListItem, ListItemAvatar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import styles from './View.module.css'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY, TAG_SERVICE_KEY } from '../../modules/content'
import { Article } from '../../modules/content/models/Article'
import { TagService } from '../../modules/content/services/TagService'
import { Tag } from '../../modules/content/models/Tag'
import { LoadingFile, LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import style from '../../components/loading-spinner/LoadingSpinner.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { File as F } from '../../modules/files/models/File'
import genericStyle from '../../common/utils/generic.module.css'
import { viewFile } from '../../common/utils/file'
import { ROUTE_ARTICLES } from '../../routes/routes-constants'
import { forkJoin, Observable } from 'rxjs'
import { RichText } from '../../components/text-editor/RichText'
import fileIcon from '../../assets/resource_icons/file.svg'
import videoIcon from '../../assets/resource_icons/ico-video.svg'

const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
const tagsService = contentContainer.get<TagService>(TAG_SERVICE_KEY)

const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

type ArticlesViewProps = {
  id?: string
}

export function View(props: ArticlesViewProps) {
  const { t } = useTranslation()

  const [article, setArticle] = useState<Article>()
  const [files, setFiles] = useState<F[]>([])
  const [tags, setTags] = useState<Tag[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [cancelLoading, setCancelLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading || !props.id) {
      return
    }
    articlesService.getByID(props.id).subscribe((res) => {
      if (!res) {
        return
      }
      setArticle(res)
      filesService.getByArticleID(res.id ?? '').subscribe((fileList) => {
        setFiles(fileList)
      })
    })
  }, [isLoading])

  const getTags = (ids: string[]): Observable<Tag[]> =>
    forkJoin(ids.map((id) => tagsService.getByID(id)) as unknown) as Observable<Tag[]>

  useEffect(() => {
    if (!article?.id) {
      return
    }
    tagsService.getByArticleID(article.id).subscribe((res) => {
      getTags(res).subscribe((tl) => {
        setTags(tl.filter((t) => t))
      })
      setIsLoading(false)
    })
  }, [article])

  const mappedTags = article
    ? tags?.map((t) => {
        return (
          <Chip
            key={`tag-${t.id}`}
            onClick={() => {
              navigate(`${ROUTE_ARTICLES}?${t.id}`)
            }}
            label={<span style={{ fontFamily: 'Poppins' }}>{t.name}</span>}
            size={'small'}
            style={{
              fontSize: '0.8rem',
              fontWeight: 500,
              marginLeft: '10px',
              padding: '2px',
              fontFamily: 'Poppins',
            }}
          />
        )
      })
    : null

  const mappedFiles = files.length > 0 && (
    <>
      <List key="files">
        {files.map((file) => {
          return (
            <ListItem key={file.id}>
              <ListItemAvatar>
                {file.extension === 'avi' || file.extension === 'mp4' ? (
                  <img src={videoIcon} alt="Video Icon" />
                ) : (
                  <img src={fileIcon} alt="File Icon" />
                )}
              </ListItemAvatar>
              <Link
                key={`file-${file.id}`}
                className={styles.tagLink}
                to={'.'}
                onClick={() => viewResource(file)}
              >
                {file.name}
              </Link>
            </ListItem>
          )
        })}
      </List>
      {loading && <LoadingFile />}
    </>
  )

  const viewResource = (resource: F) => {
    setLoading(true)
    filesService.download(resource).subscribe((res) => {
      if (res) {
        viewFile(res)
        setCancelLoading(!cancelLoading)
      }
    })
  }

  useEffect(() => {
    setLoading(false)
  }, [cancelLoading])

  const goBack = () => navigate(-1)

  return (
    <Box className={genericStyle.pageContainer}>
      {!isLoading ? (
        <Box key="article" className={styles.articleWrapper}>
          <h1 className={styles.title}>{article?.title}</h1>
          <h2 className={styles.tags}>
            <label>{t('tags')}:</label>
            {mappedTags}
          </h2>
          <RichText content={article ? article.content : ''} edit={!!props.id} view={true} />
          <h3 className={styles.files}>{mappedFiles}</h3>
          <Box key="button" className={styles.backButton}>
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('backOneToResources')}
              handler={goBack}
            />
          </Box>
        </Box>
      ) : (
        <LoadingSpinner className={style.loadingSpinner} />
      )}
    </Box>
  )
}
