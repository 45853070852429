import React from 'react'
import { MedicalData as MD } from '../../features/patient-data/medicalData/MedicalData'
import { RouteProps } from '../../routes/AppRouter'
// import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function MedicalData(props: RouteProps) {
  //  const { id } = useParams()
  const title = props.title ?? ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <MD />
    </>
  )
}
