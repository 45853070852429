import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { TrainingGroup, TrainingGroupArr, TrainingGroupQuery } from '../models/TrainingGroup'
import { TrainingGroupArrDTO, TrainingGroupDTO } from '../models/TrainingGroupDTO'
import { ITrainingGroupApi } from '../api/TrainingGroupApi'

type Props = {
  apiKey: symbol
}

export interface ITrainingGroupService extends IInit {
  getByID(id: string): Observable<TrainingGroup | undefined>

  getByUserCircleID(userCircleID: string): Observable<TrainingGroup | undefined>

  getFilteredList(q: Query<TrainingGroupQuery>, userId: string): Observable<ItemList<TrainingGroup>>

  add(e: TrainingGroupDTO): Observable<TrainingGroup | undefined>

  update(e: TrainingGroupDTO): Observable<TrainingGroup | undefined>

  delete(id: string): Observable<boolean>

  addArr(e: TrainingGroupArrDTO, centerID: string): Observable<TrainingGroupArr | undefined>
}

export class TrainingGroupService implements ITrainingGroupService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ITrainingGroupApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<ITrainingGroupApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: TrainingGroupDTO): Observable<TrainingGroup | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<TrainingGroup | undefined> {
    return this._api.getByID(id)
  }

  getByUserCircleID(userCircleID: string): Observable<TrainingGroup | undefined> {
    return this._api.getByUserCircleID(userCircleID)
  }

  getFilteredList(q: Query<TrainingGroupQuery>): Observable<ItemList<TrainingGroup>> {
    return this._api.getFilteredList(q)
  }

  update(e: TrainingGroupDTO): Observable<TrainingGroup | undefined> {
    return this._api.update(e)
  }

  addArr(e: TrainingGroupArrDTO, centerID: string): Observable<TrainingGroupArr | undefined> {
    return this._api.addArr(e, centerID)
  }
}
