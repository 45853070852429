import { Field } from '../../components/table'
import { DescriptionCell } from './DescriptionCell'
import { Chip } from '@mui/material'
import {
  CheckboxAssignType,
  checkboxAssignType,
} from '../../modules/forms/enums/CheckboxAssignType'
import React from 'react'
import { Form } from '../../modules/forms/models/Form'
import { LoggedUser } from '../../modules/users/models/LoggedUser'

interface TableForms extends Form {
  specialty?: string | undefined
  pathology?: string[] | undefined
}

export const ChoiceFields = (
  loggedUser: LoggedUser | undefined,
  isExtern: boolean | undefined,
  formsSubscriber: Form[],
  isContentManager: boolean | undefined,
  showTemplatesInTable: boolean,
  t: any
) => {
  const fields: Field<TableForms>[] = [
    {
      name: 'title',
      label: t('title'),
      renderFunc: (f, i) => (
        <DescriptionCell
          description={i.description}
          title={i.title}
          creator={i.creator}
          subscribers={i.subscribers}
          id={i.id}
          loggedUser={loggedUser}
          isExtern={isExtern}
          formsSubscribed={formsSubscriber}
        />
      ),
    },
    {
      name: 'checkBoxesSelected',
      label: t('scope'),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      renderFunc: (f, i) => {
        const arrayCheckBoxes = i.checkBoxesSelected?.split(',')
        if (i.checkBoxesSelected) {
          return arrayCheckBoxes?.map((cb) => (
            <Chip
              style={{
                backgroundColor: '#dddedf',
                borderRadius: '9px',
                marginLeft: '8px',
                marginBottom: '8px',
              }}
              label={
                <span style={{ fontFamily: 'Poppins', color: '#000' }}>
                  {checkboxAssignType()[cb as unknown as CheckboxAssignType]}
                </span>
              }
              key={cb + i.id}
            />
          ))
        }
        return (
          <Chip
            style={{
              backgroundColor: '#dddedf',
              borderRadius: '9px',
              marginLeft: '8px',
              marginBottom: '8px',
            }}
            label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{t('custom')}</span>}
            key={i.id}
          />
        )
      },
    },
    {
      name: 'specialty',
      label: t('specialty'),
      renderFunc: (f, i) => {
        if (i.specialty) {
          return (
            <Chip
              style={{
                backgroundColor: '#dddedf',
                borderRadius: '9px',
                marginLeft: '8px',
                marginBottom: '8px',
              }}
              label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{i.specialty}</span>}
              key={i.specialty + i.id}
            />
          )
        }
        return ''
      },
    },
    {
      name: 'pathology',
      label: t('circle'),
      renderFunc: (f, i) => {
        if (i.pathology && i.pathology?.length > 0) {
          return i.pathology.map((p, index) => (
            <Chip
              style={{
                backgroundColor: '#d1dee6',
                marginLeft: '8px',
                marginBottom: '8px',
              }}
              label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{p}</span>}
              key={p + index + i.id}
            />
          ))
        }
        return ''
      },
    },
  ]

  const fieldsTemplate: Field<TableForms>[] = [
    {
      name: 'title',
      label: t('title'),
      renderFunc: (f, i) => (
        <DescriptionCell
          description={i.description}
          title={i.title}
          creator={i.creator}
          subscribers={i.subscribers}
          id={i.id}
          loggedUser={loggedUser}
          isExtern={isExtern}
          formsSubscribed={formsSubscriber}
        />
      ),
    },
    {
      name: 'specialty',
      label: t('specialty'),
      renderFunc: (f, i) => {
        if (i.specialty) {
          return (
            <Chip
              style={{
                backgroundColor: '#dddedf',
                borderRadius: '9px',
                marginLeft: '8px',
                marginBottom: '8px',
              }}
              label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{i.specialty}</span>}
              key={i.specialty + i.id}
            />
          )
        }
        return ''
      },
    },
    {
      name: 'pathology',
      label: t('circle'),
      renderFunc: (f, i) => {
        if (i.pathology && i.pathology?.length > 0) {
          return i.pathology.map((p, index) => (
            <Chip
              style={{
                backgroundColor: '#d1dee6',
                marginLeft: '8px',
                marginBottom: '8px',
              }}
              label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{p}</span>}
              key={p + index + i.id}
            />
          ))
        }
        return ''
      },
    },
  ]

  const fieldExtern: Field<TableForms>[] = [
    {
      name: 'title',
      label: t('title'),
      renderFunc: (f, i) => (
        <DescriptionCell
          description={i.description}
          title={i.title}
          creator={i.creator}
          subscribers={i.subscribers}
          id={i.id}
          loggedUser={loggedUser}
          isExtern={isExtern}
          formsSubscribed={formsSubscriber}
        />
      ),
    },
    {
      name: 'checkBoxesSelected',
      label: t('scope'),
      renderFunc: (f, i) => {
        return (
          <Chip
            style={{
              backgroundColor: '#dddedf',
              borderRadius: '9px',
              marginLeft: '8px',
              marginBottom: '8px',
            }}
            label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{t('custom')}</span>}
            key={i.id}
          />
        )
      },
    },
  ]

  const getFields = () => {
    if (isExtern) {
      return fieldExtern
    } else if (showTemplatesInTable || (isContentManager && loggedUser?.roles.length === 1)) {
      return fieldsTemplate
    } else {
      // Devuelve un valor predeterminado si no se cumple ninguna de las condiciones anteriores
      return fields
    }
  }

  return getFields()
}
