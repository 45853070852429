import { User } from 'modules/users/models/User'
import { TableCell, TableRow } from '@mui/material'

type FielValue = {
  date: Date
  values: string[]
}

type ResultsRowProps = {
  users: User[]
  map: Map<string, FielValue[]>
}

export function ResultsRowForm(props: ResultsRowProps) {
  return (
    <>
      {props?.users?.map((a) => {
        return (
          <>
            {props.map?.get(a.id)?.map((u, index) => {
              return (
                <>
                  <TableRow>
                    <TableCell>{a.name}</TableCell>
                    <TableCell>{new Date(u.date).toLocaleDateString('es-ES')}</TableCell>
                    {u.values.map((value) => {
                      return (
                        <>
                          <TableCell>{value.toString()}</TableCell>
                        </>
                      )
                    })}
                  </TableRow>
                </>
              )
            })}
          </>
        )
      })}
    </>
  )
}
