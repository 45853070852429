import { RouteProps } from '../../routes/AppRouter'
import { NewsWall as N } from '../../features/newsWall'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function NewsWall(props: RouteProps) {
  const { id } = useParams()
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <N />
    </>
  )
}
