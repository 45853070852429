import { UserConfig } from '../models/UserConfig'
import { Container, IInit } from '../../../common/container/Container'
import { NotificationContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { fromModel, toModel, UserConfigDTO } from '../models/UserConfigDTO'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface IUserConfigApi extends IInit {
  getByID(id: string): Observable<UserConfig | undefined>

  getListByUserID(id: string): Observable<ItemList<UserConfig>>

  getFilteredItems(q: Query<UserConfig>): Observable<UserConfig[]>

  add(e: UserConfig): Observable<UserConfig | undefined>

  update(e: UserConfig): Observable<UserConfig | undefined>

  setConfigs(id: string, ucs: UserConfig[]): Observable<UserConfig[] | undefined>

  delete(e: UserConfig): Observable<boolean>
}

export class UserConfigApi implements IUserConfigApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url =
      (this._container.config as NotificationContainerConfig).moduleFullUrl + '/user-configs'
  }

  add(e: UserConfig): Observable<UserConfig | undefined> {
    return this._httpClient.post<UserConfig>({ url: this._url, body: fromModel(e) }).pipe(
      map<UserConfigDTO, UserConfig>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(e: UserConfig): Observable<boolean> {
    return this._httpClient.delete({ url: this._url, body: fromModel(e) }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<UserConfig | undefined> {
    return this._httpClient.get<UserConfig>({ url: `${this._url}/${id}` }).pipe(
      map<UserConfigDTO, UserConfig>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getListByUserID(id: string): Observable<ItemList<UserConfig>> {
    return this._httpClient.get<ItemList<UserConfig>>({ url: `${this._url}/${id}` }).pipe(
      map<ItemList<UserConfigDTO>, ItemList<UserConfig>>((dto) => {
        const itemList = emptyList<UserConfig>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<UserConfig>())
      })
    )
  }

  getFilteredItems(q: Query<UserConfig>): Observable<UserConfig[]> {
    return this._httpClient.get<UserConfig[]>({ url: this._url }).pipe(
      map<UserConfigDTO[], UserConfig[]>((dto) => dto.map((d) => toModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  update(e: UserConfig): Observable<UserConfig | undefined> {
    return this._httpClient.put<UserConfig>({ url: this._url, body: fromModel(e) }).pipe(
      map<UserConfigDTO, UserConfig>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  setConfigs(id: string, ucs: UserConfig[]): Observable<UserConfig[] | undefined> {
    return this._httpClient
      .put<UserConfig[]>({ url: `${this._url}/${id}`, body: ucs.map((uc) => fromModel(uc)) })
      .pipe(
        map<UserConfigDTO[], UserConfig[]>((ds) => {
          this._statusService.sendStatus({ variant: 'success' })
          return ds.map((d) => toModel(d))
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
