import { Container, IInit } from '../../../common/container/Container'
import { UserContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { PositionType } from '../models/PositionType'
import { fromModel, PositionTypeDTO, toModel } from '../models/PositionTypeDTO'
import { PositionTypeUser, PositionTypeUserQuery } from '../models/PositionTypeUser'
import {
  fromModel as fromModelPTU,
  PositionTypeUserDTO,
  toModel as toModelPTU,
} from '../models/PositionTypeUserDTO'
import { prepareURL } from '../../../common/api/http-helpers'

export interface IPositionTypeApi extends IInit {
  getAll(q: Query<PositionType>): Observable<PositionType[]>

  getByName(name: string): Observable<PositionType | undefined>

  getByID(id: string): Observable<PositionType | undefined>

  update(e: PositionType): Observable<PositionType | undefined>

  createPositionTypeUser(e: PositionTypeUser): Observable<PositionTypeUser | undefined>

  updatePositionTypeUser(e: PositionTypeUser): Observable<PositionTypeUser | undefined>

  deletePositionTypeUserByUserID(id: string): Observable<boolean>

  getUserPositionTypeByUserID(userID: string): Observable<PositionTypeUser | undefined>

  getUserPositionTypeByPositionTypeID(
    positionTypeID: string
  ): Observable<PositionTypeUser | undefined>

  getListUserPositionType(q: Query<PositionTypeUserQuery>): Observable<PositionTypeUser[]>

  getPositionTypeByUserID(userID: string): Observable<PositionType | undefined>

  delete(id: string): Observable<boolean>

  create(e: PositionType): Observable<PositionType | undefined>
}

export class PositionTypeApi implements IPositionTypeApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as UserContainerConfig).moduleFullUrl
  }

  create(e: PositionType): Observable<PositionType | undefined> {
    return this._httpClient
      .post<PositionType>({ url: this._url + '/positiontype', body: fromModel(e) })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/positiontype/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getAll(q: Query<PositionType>): Observable<PositionType[]> {
    return this._httpClient.get<PositionType[]>({ url: this._url + '/positiontype' }).pipe(
      map<PositionTypeDTO[], PositionType[]>((dto) => {
        return dto.map((d) => toModel(d))
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  getByName(name: string): Observable<PositionType | undefined> {
    return this._httpClient.get<PositionType>({ url: `${this._url}/positiontype/${name}` }).pipe(
      map<PositionTypeDTO, PositionType>((dto) => toModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByID(id: string): Observable<PositionType | undefined> {
    return this._httpClient.get<PositionType>({ url: `${this._url}/positiontypeid/${id}` }).pipe(
      map<PositionTypeDTO, PositionType>((dto) => toModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  update(e: PositionType): Observable<PositionType | undefined> {
    return this._httpClient
      .put<PositionType>({ url: this._url + '/positiontype', body: fromModel(e) })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  createPositionTypeUser(e: PositionTypeUser): Observable<PositionTypeUser | undefined> {
    return this._httpClient
      .post<PositionTypeUser>({
        url: `${this._url}/positiontypeuser`,
        body: fromModelPTU(e),
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  updatePositionTypeUser(e: PositionTypeUser): Observable<PositionTypeUser | undefined> {
    return this._httpClient
      .put<PositionTypeUser>({
        url: this._url + '/positiontypeuser',
        body: fromModelPTU(e),
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  deletePositionTypeUserByUserID(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/positiontypeuseridDelete/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getUserPositionTypeByUserID(userID: string): Observable<PositionTypeUser | undefined> {
    return this._httpClient
      .get<PositionTypeUser>({ url: `${this._url}/positiontypeuserid/${userID}` })
      .pipe(
        map<PositionTypeUserDTO, PositionTypeUser>((dto) => toModelPTU(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getUserPositionTypeByPositionTypeID(
    positionTypeID: string
  ): Observable<PositionTypeUser | undefined> {
    return this._httpClient
      .get<PositionTypeUser>({
        url: `${this._url}/positiontypeuserpt/${positionTypeID}`,
      })
      .pipe(
        map<PositionTypeUserDTO, PositionTypeUser>((dto) => toModelPTU(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getListUserPositionType(q: Query<PositionTypeUserQuery>): Observable<PositionTypeUser[]> {
    return this._httpClient
      .get<PositionTypeUser[]>({ url: prepareURL(`${this._url}/positiontypeuser`, q) })
      .pipe(
        map<PositionTypeUserDTO[], PositionTypeUser[]>((dto) => dto.map((d) => toModelPTU(d))),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getPositionTypeByUserID(userID: string): Observable<PositionType | undefined> {
    return this._httpClient
      .get<PositionType>({ url: `${this._url}/positiontypename/${userID}` })
      .pipe(
        map<PositionTypeDTO, PositionType>((dto) => toModel(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
