import { CircleAssignForm } from './CircleAssignForm'
import { v4 as uuidv4 } from 'uuid'

export interface CircleAssignFormDTO {
  id: string
  circleID: string
  formsID: string[]
  rolesID: string[]
}

export function emptyCircleAssignFormsDTO() {
  return {
    id: uuidv4(),
    circleID: '',
    formsID: [] as string[],
    rolesID: [] as string[],
  }
}

export function fromModel(e: CircleAssignForm): CircleAssignFormDTO {
  return {
    id: e.id,
    circleID: e.circleID,
    formsID: e.formsID,
    rolesID: e.rolesID,
  }
}

export function toModel(d: CircleAssignFormDTO): CircleAssignForm {
  return new CircleAssignForm(d)
}
