import { RenderLeafProps } from 'slate-react'

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.strikethrough) {
    children = <s>{children}</s>
  }
  if (leaf.highlight) {
    children = <strong style={{ color: '#0062a5', fontSize: '16px' }}>{children}</strong>
  }

  return <span {...attributes}>{children}</span>
}
