import { PatientConfig } from './PatientConfig'

export interface PatientConfigDTO {
  patientChart: boolean
  evolutionaryControl: boolean
  vacCalendar: boolean
  healthyChildChart: boolean
  activePlan: boolean
  symptoms: boolean
  treatments: boolean
  oralHealth: boolean
  wearables: boolean
}

export function emptyPatientConfigDTO() {
  return {
    patientChart: false,
    evolutionaryControl: false,
    vacCalendar: false,
    healthyChildChart: false,
    activePlan: false,
    symptoms: false,
    treatments: false,
    oralHealth: false,
    wearables: false,
  }
}

export function fromModel(e: PatientConfig): PatientConfigDTO {
  return {
    patientChart: e.patientChart,
    evolutionaryControl: e.evolutionaryControl,
    vacCalendar: e.vacCalendar,
    healthyChildChart: e.healthyChildChart,
    activePlan: e.activePlan,
    symptoms: e.symptoms,
    treatments: e.treatments,
    oralHealth: e.oralHealth,
    wearables: e.wearables,
  }
}

export function toModel(d: PatientConfigDTO): PatientConfig {
  return new PatientConfig(d)
}
