export type WearableProps = {
  values: number[]
  date: Date
}

export class Wearable {
  private readonly _values: number[]
  private readonly _date: Date

  constructor(w: WearableProps) {
    this._values = w.values
    this._date = w.date
  }

  get values(): number[] {
    return this._values
  }

  get date(): Date {
    return this._date
  }
}

export interface WearableQuery {
  userID: string
  startDate: Date
  finishDate: Date
  type: number
}
