import { RouteProps } from '../../routes/AppRouter'
import { AddNewCircle as ANC } from '../../features/circle-configurator/add-new-circle/view 1 Definition/AddNewCircle'
import React from 'react'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function AddNewCircle(props: RouteProps) {
  const { id } = useParams()
  return (
    <>
      <CurrentNavHeaderEsfera title={props.title || ''} />
      <ANC />
    </>
  )
}
