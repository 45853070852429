import { UserPendingDTO } from './UserPendingDTO'

export class UserPending {
  private _email: string
  private _token: string
  private _roleId: string
  private _circleId: string
  private _date: Date

  constructor(p: UserPendingDTO) {
    this._email = p.email
    this._token = p.token
    this._roleId = p.roleId
    this._circleId = p.circleId
    this._date = p.date
  }

  get email(): string {
    return this._email
  }

  get token(): string {
    return this._token
  }

  get roleId(): string {
    return this._roleId
  }

  get circleId(): string {
    return this._circleId
  }

  get date(): Date {
    return this._date
  }
}
