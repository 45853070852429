import { Box, InputLabel, Select, MenuItem, TextField, ListItemIcon, Alert } from '@mui/material'
import { RouteProps } from 'routes/AppRouter'
import genericStyle from '../../common/utils/generic.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { FormCard } from '../../components/form-card/FormCard'
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, useState, FormEvent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_USERS } from '../../routes/routes-constants'
import styles from './Editor.module.css'
import { RolesService } from '../../modules/users/services/RolesServices'
import { getUserContainer } from '../../container/user-module'
import { IUserService, ROLES_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { Role } from 'modules/users/models/Role'
import { Query } from '../../common/api/Query'
import { Roles } from '../../modules/users/enums/Roles'
import externProfessional from '../../assets/role_icons/ico-rol-externo.svg'
import professionalSMS from '../../assets/role_icons/ico-rol-profesional.svg'
import patient from '../../assets/role_icons/ico-rol-paciente.svg'
import career from '../../assets/role_icons/ico-rol-cuidador.svg'
import family from '../../assets/role_icons/ico-rol-familiar.svg'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { IStatusService } from '../../common/status/StatusService'
import { getUserCircleContainer } from '../../container/user-circle-module'
import {
  IUserCircleActiveService,
  IUserCircleService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
  USER_CIRCLE_SERVICE_KEY,
} from '../../modules/user-circle'

const roleContainer = getUserContainer()
const roleService = roleContainer.get<RolesService>(ROLES_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UserCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)

export function Editor(props: RouteProps) {
  const { t } = useTranslation()
  const user = userCircleActiveService.getActiveFullUserCircle()
  const [participantType, setParticipantType] = useState<string>()
  const [roles, setRoles] = useState<Role[]>([])
  const UserCircle = userCircleActiveService.getActiveFullUserCircle()
  const UserCircleID = UserCircle?.id
  const [currentEmail, setCurrentEmail] = useState<string>()
  const navigate = useNavigate()
  const [usersEmail, setUsersEmail] = useState<string[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    // TODO get this roles to invite on the SETTINGS TABLE
    const rolesAux = new Array<Role>()
    roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        res.forEach((e) => {
          if (
            e.name === 'externProfessional' ||
            e.name === 'family/Tutor' ||
            e.name === 'carer/Teacher'
          ) {
            rolesAux.push(e)
          }
        })
        setRoles(rolesAux)
      })
  }, [])

  useEffect(() => {
    if (!user) return

    userService.getUserPending(user.id).subscribe((res) => {
      res.forEach((e) => {
        setUsersEmail((prevState) => [...prevState, e.email])
      })
    })
  }, [])

  const handleParticipant = (event: any) => {
    setParticipantType(event.target.value as string)
  }

  const handleEmail = (event: ChangeEvent<{ value: unknown }>) => {
    setCurrentEmail(event.target.value as string)
  }
  const handleBack = () => navigate(ROUTE_USERS)

  const validateForm = () => {
    if (currentEmail === undefined || participantType === undefined) {
      return false
    }
    return true
  }

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!currentEmail) return
    if (validateExistEmail(currentEmail)) {
      setErrorMessage('emailAlreadyExist')
      return
    }
    if (!validateForm()) {
      return
    }
    if (currentEmail === undefined || participantType === undefined || UserCircleID === undefined) {
      return
    }
    userService.invite(currentEmail, UserCircleID, participantType.replace('/', '-'))
    statusService.sendStatus({ variant: 'success' })
    navigate(ROUTE_USERS)
  }

  const getImage = (option: Role) => {
    switch (option.name) {
      case Roles.Professional:
        return <img className={styles.roleIcon} src={professionalSMS} alt={t(Roles.Professional)} />

      case Roles.Patient:
        return <img className={styles.roleIcon} src={patient} alt={t(Roles.Patient)} />

      case Roles.CarerOrTeacher:
        return <img className={styles.roleIcon} src={career} alt={t(Roles.CarerOrTeacher)} />

      case Roles.FamilyOrTutor:
        return <img className={styles.roleIcon} src={family} alt={t(Roles.FamilyOrTutor)} />

      case Roles.ProfessionalExtern:
        return (
          <img
            className={styles.roleIcon}
            src={externProfessional}
            alt={t(Roles.ProfessionalExtern)}
          />
        )
    }
  }

  const validateExistEmail = (email: string) => {
    if (usersEmail.includes(email)) {
      return true
    }
    return false
  }

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Box mb={3} className={styles.emailBox}>
          <TextField
            style={{
              width: window.innerWidth > 1025 ? '67%' : window.innerWidth > 599 ? '80%' : '120%',
            }}
            id="email"
            onChange={(e) => handleEmail(e)}
            label="Email"
            variant="outlined"
            required={true}
          />
        </Box>
        <FormCard>
          <form onSubmit={handleSave}>
            <Box mb={3} className={styles.participantBox}>
              <InputLabel id="select-participant-label" style={{ marginBottom: '0.5%' }}>
                {t('participantType')}
              </InputLabel>
              <Select
                style={{ width: window.innerWidth > 599 ? '25%' : '37%', marginBottom: '2.3%' }}
                labelId="sselect-participant-label"
                id="select-participant-label"
                value={participantType}
                variant={'outlined'}
                required={true}
                onChange={handleParticipant}
              >
                {roles.map((rol) => (
                  <MenuItem key={rol.name} value={rol.id}>
                    <ListItemIcon>
                      <span>{getImage(rol)}</span>
                      {t(rol.name)}
                    </ListItemIcon>
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box mb={3} display="flex" justifyContent="space-between">
              <AppButton
                theme={ButtonTheme.NewSecondary}
                type={'button'}
                label={t('back')}
                handler={handleBack}
              />
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'submit'}
                label={t('add')}
                handler={(e) => handleSave(e)}
              />
            </Box>
            {errorMessage && (
              <Box mb={3}>
                <Alert severity="warning" key="errorMessage" id="errorMessage">
                  {t(errorMessage)}
                </Alert>
              </Box>
            )}
          </form>
        </FormCard>
      </Box>
    </>
  )
}
