import { Container } from '../../common/container/Container'

export type CircleAssignFormsContainerConfig = {
  moduleFullUrl: string
}

export type CircleAssignFormsContainerConfigProps = {
  parentContainer: Container
  config: CircleAssignFormsContainerConfig
}

export const CIRCLE_ASSIGN_FORMS_MODULE = Symbol('CIRCLE_ASSIGN_FORMS_MODULE')

export const CIRCLE_ASSIGN_FORMS_API_KEY = Symbol('CIRCLE_ASSIGN_FORMS_API_KEY')

export const CIRCLE_ASSIGN_FORMS_SERVICE_KEY = Symbol('CIRCLE_ASSIGN_FORMS_SERVICE_KEY')
