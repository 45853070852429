import { Container, ContainerItem } from '../common/container/Container'
import {
  SHARED_WITH_API_KEY,
  SHARED_WITH_SERVICE_KEY,
  SHARED_WITH_MODULE,
  SharedWithProps,
} from '../modules/sharedWith/container'
import { SharedWithApi } from '../modules/sharedWith/api/SharedWithApi'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { SharedWithService } from '../modules/sharedWith/services/SharedWithService'

let container: Container

function init(p: SharedWithProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(SHARED_WITH_API_KEY, new ContainerItem({ instance: new SharedWithApi() }))

  items.set(
    SHARED_WITH_SERVICE_KEY,
    new ContainerItem({ instance: new SharedWithService({ apiKey: SHARED_WITH_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getSharedWithContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[SHARED_WITH_MODULE],
      },
    })
  }

  return container
}
