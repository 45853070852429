import { VisitAudit } from '../models/VisitAudit'
import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { VisitAuditDTO } from '../models/VisitAuditDTO'
import { VisitAuditApi } from '../api/VisitAuditApi'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'

type Props = {
  apiKey: symbol
}

export interface ISettingService extends IInit {
  getByID(id: string): Observable<VisitAudit | undefined>

  getFilteredList(q: Query<VisitAudit>): Observable<ItemList<VisitAudit>>

  add(e: VisitAuditDTO): Observable<VisitAudit | undefined>

  delete(id: string): Observable<boolean>
}

export class VisitAuditService implements ISettingService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: VisitAuditApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<VisitAuditApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: VisitAuditDTO): Observable<VisitAudit | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<VisitAudit | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<VisitAudit>): Observable<ItemList<VisitAudit>> {
    return this._api.getFilteredList(q).pipe()
  }
}
