import { IndicatorDTO } from './IndicatorDTO'

export class Indicator {
  private readonly _id: string
  private readonly _value: string
  private readonly _date: Date

  constructor(p: IndicatorDTO) {
    this._id = p.id
    this._value = p.value
    this._date = p.date
  }

  get id(): string {
    return this._id
  }

  get value(): string {
    return this._value
  }

  get date(): Date {
    return this._date
  }
}
