type QueryTypeProps = {
  id: string | undefined
  name: string
  circleID: string
  description: string
}

export class QueryType {
  private readonly _id: string | undefined
  private _name: string
  private _circleID: string
  private _description: string

  constructor(p: QueryTypeProps) {
    this._id = p.id
    this._name = p.name
    this._circleID = p.circleID
    this._description = p.description
  }

  get id(): string | undefined {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get circleID(): string {
    return this._circleID
  }

  set circleID(value: string) {
    this._circleID = value
  }

  get description(): string {
    return this._description
  }

  set description(value: string) {
    this._description = value
  }
}

export interface QueryTypeQuery {
  name: string
  circleID: string
}
