import i18n from '../../../i18n'

export enum Awareness {
  never = -1,
  fully,
  confused,
  respondsVoice,
  unconscious,
  undefined,
}

export const awarenessTypes = (): Record<Awareness, string> => ({
  [Awareness.never]: i18n.t('never'),
  [Awareness.fully]: i18n.t('fully'),
  [Awareness.confused]: i18n.t('confused'),
  [Awareness.respondsVoice]: i18n.t('respondsVoice'),
  [Awareness.unconscious]: i18n.t('unconscious'),
  [Awareness.undefined]: i18n.t('undefined'),
})
