export type PatientSymptomConditionProps = {
  name: string
  type: number
  value: any
}

export class PatientSymptomCondition {
  private readonly _name: string
  private readonly _type: number
  private readonly _value: any

  constructor(p: PatientSymptomConditionProps) {
    this._name = p.name
    this._type = p.type
    this._value = p.value
  }

  get name(): string {
    return this._name
  }

  get type(): number {
    return this._type
  }

  get value(): any {
    return this._value
  }
}
