type VisitAuditProps = {
  id: string | undefined
  circleID: string
  professionalID: string
  date: Date
}

export class VisitAudit {
  private readonly _id: string | undefined
  private _circleID: string
  private _professionalID: string
  private _date: Date

  constructor(p: VisitAuditProps) {
    this._id = p.id
    this._circleID = p.circleID
    this._professionalID = p.professionalID
    this._date = p.date
  }

  get id(): string | undefined {
    return this._id
  }

  get circleID(): string {
    return this._circleID
  }

  set circleID(value: string) {
    this._circleID = value
  }

  get professionalID(): string {
    return this._professionalID
  }

  set professionalID(value: string) {
    this._professionalID = value
  }

  get date(): Date {
    return this._date
  }

  set date(value: Date) {
    this._date = value
  }
}

export interface VisitAuditQuery {
  circleID: string
  professionalID: string
  date: Date
}
