import { ReactEditor } from 'slate-react'
import { BaseEditor, Descendant } from 'slate'

export type ParagraphElement = {
  type: 'paragraph'
  children: CustomText[]
}

type Text = {
  text: string
  bold?: boolean
  italic?: boolean
  strikethrough?: boolean
  highlight?: boolean
  code?: boolean
}
export type BlockQuoteElement = {
  type: 'block-quote'
  align?: string
  children: Descendant[]
}

export type BulletedListElement = {
  type: 'bulleted-list'
  align?: string
  children: Descendant[]
}

export type CheckListItemElement = {
  type: 'check-list-item'
  checked: boolean
  children: Descendant[]
}

export type EditableVoidElement = {
  type: 'editable-void'
  children: EmptyText[]
}

export type HeadingElement = {
  type: 'heading'
  align?: string
  children: Descendant[]
}

export type HeadingTwoElement = {
  type: 'heading-two'
  align?: string
  children: Descendant[]
}

export type ImageElement = {
  type: 'image'
  url?: string
  align?: string
  children: EmptyText[]
}

export type EmptyText = {
  text: string
}

type CustomElement =
  | BlockQuoteElement
  | BulletedListElement
  | CheckListItemElement
  | EditableVoidElement
  | HeadingElement
  | HeadingTwoElement
  | ImageElement
  | LinkElement
  | ButtonElement
  | ListItemElement
  | MentionElement
  | ParagraphElement
  | TableCellElement
  | TitleElement
  | VideoElement

export type LinkElement = { type: 'link'; url: string; children: EmptyText[] }

export type ButtonElement = { type: 'button'; children: Descendant[] }

export type ListItemElement = { type: 'list-item'; children: Descendant[] }

export type MentionElement = {
  type: 'mention'
  character: string
  children: CustomText[]
}
export type TableCellElement = { type: 'table-cell'; children: CustomText[] }

export type TitleElement = { type: 'title'; children: Descendant[] }

export type VideoElement = { type: 'video'; url: string; children: EmptyText[] }

export type CustomEditor = BaseEditor & ReactEditor

export type FormattedText = { text: string; bold?: true }

export type CustomText = FormattedText

export type Editor = BaseEditor & ReactEditor

export type Mark = 'bold' | 'highlight' | 'italic' | 'strikethrough' | 'code'

export const LIST_TYPES = ['numbered-list', 'bulleted-list']
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor
    Element: CustomElement
    Text: Text
  }
}
