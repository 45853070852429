import React, { ChangeEvent, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import style from './Searcher.module.css'

type SearcherProps = {
  handler: (search: string) => void
  reverse: () => void
  handleNewConversation: () => void
}

export function Searcher(props: SearcherProps): JSX.Element {
  const { t } = useTranslation()

  const [search, setSearch] = useState<string>('')
  /* const [descToAsc, setDescToAsc] = useState<boolean>(false) */

  const handlerChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setSearch(event.target.value)

  useEffect(() => {
    props.handler(search)
  }, [search])

  /* const handleFilterChange = () => {
    props.reverse()
    setDescToAsc(!descToAsc)
  } */

  return (
    <>
      <Box className={style.inputBox}>
        <TextField
          sx={{
            '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': { fontFamily: 'Poppins' },
          }}
          className={style.inputSearcher}
          label={t('filterByConversation')}
          variant={'outlined'}
          type="text"
          value={search}
          onChange={handlerChange}
        />
      </Box>

      {/* <Box className={style.iconBlock}>
        <FilterListIcon onClick={handleFilterChange} className={containerClassName(descToAsc)} />
      </Box> */}
      {/* <div
        className={style.addNewConversationContainer}
        onClick={props.handleNewConversation}
        style={{ visibility: useIsRelated() ? 'visible' : 'hidden', zIndex: 0 }}
      >
        <Fab size={'medium'} className={style.addNewConversationFab}>
          <AddIcon />
        </Fab>
      </div> */}
    </>
  )
}

/* function containerClassName(descToAsc: boolean): string {
  if (descToAsc) {
    return style.toAsc + ' ' + style.filterListIcon
  }
  return style.toDesc
} */
