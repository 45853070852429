import { FormFieldType } from '../enums/FormFieldType'
import { Query } from '../../../common/api/Query'
import { v4 as uuidv4 } from 'uuid'

export type FormFieldProps = {
  id?: string
  formID?: string
  title: string
  type: FormFieldType
  required: boolean
  order: number
  optionValues?: string[]
  score: string[]
  correctValues?: string | string[] | Function
}

export class FormField {
  private _id: string
  private _formID: string
  private readonly _type: FormFieldType

  private _title: string
  private _required: boolean
  private _order: number
  private _optionValues?: string[]
  private _correctValues?: string | string[] | Function
  private _score: string[]

  constructor(p: FormFieldProps) {
    this._id = p.id || uuidv4()
    this._formID = p.formID || uuidv4()
    this._type = p.type
    this._title = p.title
    this._required = p.required
    this._order = p.order
    this._optionValues = p.optionValues
    this._correctValues = p.correctValues
    this._score = p.score
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get formID(): string {
    return this._formID
  }

  set formID(value: string) {
    this._formID = value
  }

  get type(): FormFieldType {
    return this._type
  }

  get title(): string {
    return this._title
  }

  set title(value: string) {
    this._title = value
  }

  get required(): boolean {
    return this._required
  }

  set required(value: boolean) {
    this._required = value
  }

  get order(): number {
    return this._order
  }

  set order(value: number) {
    this._order = value
  }

  get score(): string[] {
    return this._score
  }

  set score(value: string[]) {
    this._score = value
  }

  getOptionsValues(): string[] {
    if (Array.isArray(this._optionValues)) {
      return [...this._optionValues]
    }

    return []
    //return this._optionValues
  }

  setOptionsValues(value: string[]) {
    if (!Array.isArray(this._optionValues)) {
      this._optionValues = value
    }
  }

  addOptionsValue(value: string) {
    if (Array.isArray(this._optionValues)) {
      this._optionValues.push(value)
    }
  }

  removeOptionsValue(value: string) {
    if (Array.isArray(this._optionValues)) {
      this._optionValues = this._optionValues.filter((ov) => ov !== value)
    }
  }

  getCorrectValues(): string | string[] | Function | undefined {
    if (Array.isArray(this._correctValues)) {
      return [...this._correctValues]
    }

    return this._correctValues
  }

  setCorrectValues(value: string | Function) {
    if (!Array.isArray(this._correctValues)) {
      this._correctValues = value
    }
  }

  addCorrectValue(value: string) {
    if (Array.isArray(this._correctValues)) {
      this._correctValues.push(value)
    }
  }

  removeCorrectValue(value: string) {
    if (Array.isArray(this._correctValues)) {
      this._correctValues = this._correctValues.filter((cv) => cv !== value)
    }
  }
}

export class FormFieldQuery extends Query<FormField> {}
