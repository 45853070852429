import { v4 as uuidv4 } from 'uuid'
import { emptyUserDTO, UserDTO } from '../../users/models/User'
import { CircleDTO, emptyCircleDTO } from '../../circle/models/CircleDTO'
import { UserCircleWithRelatersAndCircle } from './UserCircleWithRelatersAndCircle'

export interface UserCircleWithRelatersAndCircleDTO {
  id: string
  circle: CircleDTO
  user: UserDTO
  familiars: UserDTO[]
  profSms: UserDTO[]
  profExt: UserDTO[]
  careers: UserDTO[]
}

export function emptyUserCircleWithRelatersAndCircleDTO(): UserCircleWithRelatersAndCircleDTO {
  return {
    id: uuidv4(),
    circle: emptyCircleDTO(),
    user: emptyUserDTO(),
    familiars: [],
    profSms: [],
    profExt: [],
    careers: [],
  }
}

export function fromModel(e: UserCircleWithRelatersAndCircle): UserCircleWithRelatersAndCircleDTO {
  return {
    id: e.id,
    circle: e.circle,
    user: e.user,
    familiars: e.familiars,
    profSms: e.profSms,
    profExt: e.profExt,
    careers: e.careers,
  }
}

export function toModel(d: UserCircleWithRelatersAndCircleDTO): UserCircleWithRelatersAndCircle {
  return new UserCircleWithRelatersAndCircle(d)
}
