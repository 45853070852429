import Chart from 'chart.js/auto'
import React, { useEffect, useRef, useState } from 'react'
import styles from './GenericChart.module.css'

export type genericChartProps = {
  title: string
  type: string
  data: object[]
  labelX: number[] | string[]
  xAxisLabel: string
  yAxisLabel: string
  showLegend: boolean
  showTooltip: boolean
  showTitle: boolean
  width?: number
}

export function GenericChart(props: genericChartProps): JSX.Element {
  const [myChart, setMyChart] = useState<Chart | null>(null)
  const myCanvas = useRef<HTMLCanvasElement | null>(null)

  useEffect(() => {
    let chartInstance: Chart | null = null

    if (myCanvas.current) {
      if (myChart) {
        myChart.destroy()
      }

      chartInstance = new Chart(myCanvas.current, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        type: props.type,
        data: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          labels: props.labelX,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          datasets: props.data,
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: props.showLegend,
              position: 'top',
              labels: {
                font: {
                  family: 'Poppins',
                },
              },
            },
            title: {
              display: props.showTitle,
              text: props.title,
              font: {
                family: 'Poppins',
              },
            },
            tooltip: {
              enabled: props.showTooltip,
              displayColors: false,
              callbacks: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                label: function (tooltipItems: any, data: any) {
                  return tooltipItems.formattedValue + ' ' + props.yAxisLabel
                },
                title: function (value: any) {
                  return props.xAxisLabel + ' ' + value[0].label
                },
              },
            },
          },
          scales: {
            x: {
              ticks: {
                maxRotation: 90,
                minRotation: 90,
                font: {
                  family: 'Poppins',
                },
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                callback: function (value: number, index: any, values: any) {
                  if (props.xAxisLabel === '' && props.labelX) return props.labelX[index]
                  switch (props.labelX[index]) {
                    case 0:
                      return ['Nacimiento']
                    case 12:
                      return ['1 año']
                    case 24:
                      return ['2 años']
                    case 36:
                      return ['3 años']
                    case 48:
                      return ['4 años']
                    case 60:
                      return ['5 años']
                    case 72:
                      return '6 años'
                    case 84:
                      return '7 años'
                    case 96:
                      return '8 años'
                    case 108:
                      return '9 años'
                    case 120:
                      return '10 años'
                    case 132:
                      return '11 años'
                    case 144:
                      return '12 años'
                    case 156:
                      return '13 años'
                    case 168:
                      return '14 años'
                    case 180:
                      return '15 años'
                    case 192:
                      return '16 años'
                    case 204:
                      return '17 años'
                    case 216:
                      return '18 años'
                  }
                  return props.xAxisLabel + ' ' + props.labelX[index]
                },
              },
            },
            y: {
              ticks: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                callback: function (value: number, index: any, values: any) {
                  return value + ' ' + props.yAxisLabel
                },
                font: {
                  family: 'Poppins',
                },
              },
            },
          },
        },
      })

      setMyChart(chartInstance)
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy()
      }
    }
  }, [
    props.data,
    props.type,
    props.showLegend,
    props.showTitle,
    props.showTooltip,
    props.xAxisLabel,
    props.yAxisLabel,
  ])

  return (
    <>
      <canvas id="myChart" ref={myCanvas} className={styles.canvas} height="400" />
    </>
  )
}
