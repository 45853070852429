import React from 'react'
import { Messenger as M } from '../../features/messenger'
import { RouteProps } from '../../routes/AppRouter'
import { useLocation, useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

type MessengerProps = {
  conversationID?: string
} & RouteProps

export function Messenger(props: RouteProps) {
  const { id } = useParams()
  const location = useLocation()
  const title = props.title || ''

  const state: MessengerProps = location?.state as MessengerProps

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <M conversationID={state?.conversationID} />
    </>
  )
}
