export enum TransportType {
  Email = 3,
  SMS = 4,
  App = 7,
  None = 0,
}

export const transportTypes = (): Record<TransportType, string> => ({
  [TransportType.Email]: 'Email',
  [TransportType.SMS]: 'SMS',
  [TransportType.App]: 'App',
  [TransportType.None]: 'None',
})
