import i18n from '../../../i18n'

export enum CheckboxAssignType {
  AllMyCircles = 0,
  AllMyPatients = 1,
  AllFamiliars = 2,
  AllCareers = 3,
  AllExternal = 4,
}

export const checkboxAssignType = (): Record<CheckboxAssignType, string> => ({
  [CheckboxAssignType.AllMyCircles]: i18n.t('allMyCircles'),
  [CheckboxAssignType.AllMyPatients]: i18n.t('allMyPatients'),
  [CheckboxAssignType.AllFamiliars]: i18n.t('allFamiliars'),
  [CheckboxAssignType.AllCareers]: i18n.t('AllCareers'),
  [CheckboxAssignType.AllExternal]: i18n.t('AllExternal'),
})
