import { AutoMessage } from './AutoMessage'
import { v4 as uuidv4 } from 'uuid'

export interface AutoMessageDTO {
  id: string
  professional: string
  pathology: string
  message: string
}

export function fromModel(d: AutoMessage): AutoMessageDTO {
  return {
    id: d.id,
    professional: d.professional,
    pathology: d.pathology,
    message: d.message,
  }
}

export function toModel(d: AutoMessageDTO): AutoMessage {
  return new AutoMessage(d)
}

export function emptyAutoMessageDTO() {
  return {
    id: uuidv4(),
    professional: '',
    pathology: '',
    message: '',
  }
}
