export type UserTypeExternalProfessionalProps = {
  id: string
  userID: string
  typeExternProfID: string
}

export class UserTypeExternalProfessional {
  private readonly _id: string
  private _userID: string
  private _typeExternProfID: string

  constructor(props: UserTypeExternalProfessionalProps) {
    this._id = props.id
    this._userID = props.userID
    this._typeExternProfID = props.typeExternProfID
  }

  get id(): string {
    return this._id
  }

  get userID(): string {
    return this._userID
  }

  set userID(value: string) {
    this._userID = value
  }

  get typeExternProfID(): string {
    return this._typeExternProfID
  }

  set typeExternProfID(value: string) {
    this._typeExternProfID = value
  }
}
