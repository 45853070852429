export type ExternalProfessionalPendingProps = {
  email: string
  token: string
  groupID: string
  typeExternalProfessionalID: string
}

export class ExternalProfessionalPending {
  private _email: string
  private _token: string
  private _groupID: string
  private _typeExternalProfessionalID: string

  constructor(props: ExternalProfessionalPendingProps) {
    this._email = props.email
    this._token = props.token
    this._groupID = props.groupID
    this._typeExternalProfessionalID = props.typeExternalProfessionalID
  }

  get email(): string {
    return this._email
  }

  set email(value: string) {
    this._email = value
  }

  get token(): string {
    return this._token
  }

  set token(value: string) {
    this._token = value
  }

  get groupID(): string {
    return this._groupID
  }

  set groupID(value: string) {
    this._groupID = value
  }

  get typeExternalProfessionalID(): string {
    return this._typeExternalProfessionalID
  }

  set typeExternalProfessionalID(value: string) {
    this._typeExternalProfessionalID = value
  }
}
