import { v4 as uuidv4 } from 'uuid'
import { FormPathology } from './FormPathology'

export interface FormPathologyDTO {
  id: string
  formID: string
  circleID: string
}

export function emptyFormPathologyDTO(): FormPathologyDTO {
  return {
    id: uuidv4(),
    formID: '',
    circleID: '',
  }
}

export function fromModel(d: FormPathology): FormPathologyDTO {
  return {
    id: d.id,
    formID: d.formID,
    circleID: d.circleID,
  }
}

export function toModel(d: FormPathologyDTO): FormPathology {
  return new FormPathology(d)
}
