import React from 'react'
import { Resources as R } from '../../features/resource-patientView'
import { RouteProps } from '../../routes/AppRouter'
import { useLocation, useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

type ResourcesProps = {
  breadcrumbUrl?: Map<string, string>
} & RouteProps

export function Resources(props: ResourcesProps) {
  const { id } = useParams()
  const location = useLocation()
  const title = props.title ?? ''
  const state: ResourcesProps = location?.state as ResourcesProps
  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <R resource={id} breadcrumbUrl={state?.breadcrumbUrl} />
    </>
  )
}
