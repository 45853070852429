import { IndicatorDTO, fromModel as indicatorFromModel } from './IndicatorDTO'
import { Selene } from './Selene'

export interface SeleneDTO {
  indicators: IndicatorDTO[]
}

export function toModel(s: SeleneDTO): Selene {
  return new Selene(s)
}

export function fromModel(s: Selene): SeleneDTO {
  return {
    indicators: s.indicators.map((i) => indicatorFromModel(i)),
  }
}
