import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { CityHallContainerConfig } from '../container'
import { toModel } from '../models/CityHallDTO'
import { CityHall, CityHallQuery } from '../models/CityHall'
import { CityHallDTO } from '../models/CityHallDTO'

export interface ICityHallApi extends IInit {
  getByID(id: string): Observable<CityHall | undefined>

  getFilteredList(q: Query<CityHallQuery>): Observable<ItemList<CityHall>>

  add(e: CityHallDTO): Observable<CityHall | undefined>

  update(e: CityHallDTO): Observable<CityHall | undefined>

  delete(id: string): Observable<boolean>
}

export class CityHallApi implements ICityHallApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as CityHallContainerConfig).moduleFullUrl + '/cityHall'
  }

  add(e: CityHallDTO): Observable<CityHall | undefined> {
    return this._httpClient.post<CityHall>({ url: this._url, body: e }).pipe(
      map<CityHallDTO, CityHall>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<CityHall | undefined> {
    return this._httpClient.get<CityHall>({ url: `${this._url}/${id}` }).pipe(
      map<CityHallDTO, CityHall>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<CityHallQuery>): Observable<ItemList<CityHall>> {
    return this._httpClient.get<ItemList<CityHall>>({ url: prepareURL(`${this._url}`, q) }).pipe(
      map<ItemList<CityHallDTO>, ItemList<CityHall>>((dto) => {
        const itemList = emptyList<CityHall>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<CityHall>())
      })
    )
  }

  update(e: CityHallDTO): Observable<CityHall | undefined> {
    return this._httpClient.put<CityHall>({ url: this._url, body: e }).pipe(
      map<CityHallDTO, CityHall>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
