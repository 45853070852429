import { Container, ContainerItem } from '../common/container/Container'
import { PatientSymptomService } from '../modules/patient-data/services/PatientSymptomService'
import {
  PATIENT_DATA_API_KEY,
  PATIENT_DATA_MODULE,
  PATIENT_DATA_SERVICE_KEY,
  PATIENT_SYMPTOM_API_KEY,
  PATIENT_SYMPTOM_SERVICE_KEY,
  TREATMENT_API_KEY,
  TREATMENT_SERVICE_KEY,
} from '../modules/patient-data'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { PatientDataApi } from '../modules/patient-data/api/PatientDataApi'
import { PatientSymptomApi } from '../modules/patient-data/api/PatientSymptomApi'
import {
  DataPatientProps,
  GOAL_API_KEY,
  GOAL_SERVICE_KEY,
  GROWTH_MONITORING_API_KEY,
  GROWTH_MONITORING_SERVICE_KEY,
  PERCENTILE_API_KEY,
  PERCENTILE_SERVICE_KEY,
  SYMPTOM_API_KEY,
  SYMPTOM_SERVICE_KEY,
  WEARABLE_API_KEY,
  WEARABLE_SERVICE_KEY,
  LANDMARK_API_KEY,
  LANDMARK_SERVICE_KEY,
} from '../modules/patient-data/container'
import { TreatmentService } from '../modules/patient-data/services/TreatmentService'
import { PatientDataService } from '../modules/patient-data/services/PatientDataService'
import { SymptomService } from '../modules/patient-data/services/SymptomService'
import { SymptomApi } from '../modules/patient-data/api/SymptomApi'
import { TreatmentApi } from '../modules/patient-data/api/TreatmentApi'
import { GrowthMonitoringApi } from '../modules/patient-data/api/GrowthMonitoringApi'
import { GrowthMonitoringService } from '../modules/patient-data/services/GrowthMonitoringService'
import { PercentileApi } from '../modules/patient-data/api/PercentileApi'
import { PercentileService } from '../modules/patient-data/services/PercentileService'
import { WearableApi } from '../modules/patient-data/api/WearableApi'
import { WearableService } from '../modules/patient-data/services/WearableService'
import { GoalService } from 'modules/patient-data/services/GoalService'
import { GoalApi } from '../modules/patient-data/api/GoalApi'
import { LandmarkApi } from '../modules/patient-data/api/LandmarkApi'
import { LandMarkService } from '../modules/patient-data/services/LandmarkService'

let container: Container

function init(p: DataPatientProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(PATIENT_DATA_API_KEY, new ContainerItem({ instance: new PatientDataApi() }))
  items.set(PATIENT_SYMPTOM_API_KEY, new ContainerItem({ instance: new PatientSymptomApi() }))
  items.set(TREATMENT_API_KEY, new ContainerItem({ instance: new TreatmentApi() }))
  items.set(SYMPTOM_API_KEY, new ContainerItem({ instance: new SymptomApi() }))
  items.set(GROWTH_MONITORING_API_KEY, new ContainerItem({ instance: new GrowthMonitoringApi() }))
  items.set(PERCENTILE_API_KEY, new ContainerItem({ instance: new PercentileApi() }))
  items.set(WEARABLE_API_KEY, new ContainerItem({ instance: new WearableApi() }))
  items.set(GOAL_API_KEY, new ContainerItem({ instance: new GoalApi() }))
  items.set(LANDMARK_API_KEY, new ContainerItem({ instance: new LandmarkApi() }))

  items.set(
    LANDMARK_SERVICE_KEY,
    new ContainerItem({ instance: new LandMarkService({ apiKey: LANDMARK_API_KEY }) })
  )

  items.set(
    PATIENT_DATA_SERVICE_KEY,
    new ContainerItem({ instance: new PatientDataService({ apiKey: PATIENT_DATA_API_KEY }) })
  )
  items.set(
    PATIENT_SYMPTOM_SERVICE_KEY,
    new ContainerItem({ instance: new PatientSymptomService({ apiKey: PATIENT_SYMPTOM_API_KEY }) })
  )
  items.set(
    TREATMENT_SERVICE_KEY,
    new ContainerItem({ instance: new TreatmentService({ apiKey: TREATMENT_API_KEY }) })
  )
  items.set(
    SYMPTOM_SERVICE_KEY,
    new ContainerItem({ instance: new SymptomService({ apiKey: SYMPTOM_API_KEY }) })
  )
  items.set(
    GROWTH_MONITORING_SERVICE_KEY,
    new ContainerItem({
      instance: new GrowthMonitoringService({ apiKey: GROWTH_MONITORING_API_KEY }),
    })
  )
  items.set(
    PERCENTILE_SERVICE_KEY,
    new ContainerItem({
      instance: new PercentileService({ apiKey: PERCENTILE_API_KEY }),
    })
  )

  items.set(
    WEARABLE_SERVICE_KEY,
    new ContainerItem({
      instance: new WearableService({ apiKey: WEARABLE_API_KEY }),
    })
  )

  items.set(
    GOAL_SERVICE_KEY,
    new ContainerItem({
      instance: new GoalService({ apiKey: GOAL_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getPatientDataContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[PATIENT_DATA_MODULE],
      },
    })
  }

  return container
}
