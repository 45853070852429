import { PatientSymptom, PatientSymptomQuery } from '../models/PatientSymptom'
import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { PatientSymptomDTO } from '../models/PatientSymptomDTO'
import { ItemList } from '../../../common/models/ItemList'
import { PatientSymptomApi } from '../api/PatientSymptomApi'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface IPatientSymptomService extends IInit {
  getByID(id: string): Observable<PatientSymptom | undefined>

  getFilteredList(q: Query<PatientSymptomQuery>): Observable<ItemList<PatientSymptom>>

  getFilteredItems(q: Query<PatientSymptomQuery>): Observable<PatientSymptom[]>

  add(e: PatientSymptomDTO): Observable<PatientSymptom | undefined>

  update(e: PatientSymptomDTO): Observable<PatientSymptom | undefined>

  delete(id: string): Observable<boolean>
}

export class PatientSymptomService implements IPatientSymptomService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: PatientSymptomApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<PatientSymptomApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: PatientSymptomDTO): Observable<PatientSymptom | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<PatientSymptom | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<PatientSymptomQuery>): Observable<ItemList<PatientSymptom>> {
    return this._api.getFilteredList(q).pipe()
  }

  getFilteredItems(q: Query<PatientSymptomQuery>): Observable<PatientSymptom[]> {
    return this._api.getFilteredItems(q).pipe()
  }

  update(e: PatientSymptomDTO): Observable<PatientSymptom | undefined> {
    return this._api.update(e)
  }
}
