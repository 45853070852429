import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { PositionType } from '../models/PositionType'
import { IPositionTypeApi } from '../api/PositionTypeApi'
import { PositionTypeUser, PositionTypeUserQuery } from '../models/PositionTypeUser'

type Props = {
  apiKey: symbol
}

export interface IPositionTypeService extends IInit {
  getAll(q: Query<PositionType>): Observable<PositionType[]>

  getByName(name: string): Observable<PositionType | undefined>

  getByID(id: string): Observable<PositionType | undefined>

  update(e: PositionType): Observable<PositionType | undefined>

  createPositionTypeUser(e: PositionTypeUser): Observable<PositionTypeUser | undefined>

  updatePositionTypeUser(e: PositionTypeUser): Observable<PositionTypeUser | undefined>

  deletePositionTypeUserByUserID(id: string): Observable<boolean>

  getUserPositionTypeByUserID(userID: string): Observable<PositionTypeUser | undefined>

  getUserPositionTypeByPositionTypeID(
    professionalTypeID: string
  ): Observable<PositionTypeUser | undefined>

  getListUserPositionType(q: Query<PositionTypeUserQuery>): Observable<PositionTypeUser[]>

  getPositionTypeByUserID(userID: string): Observable<PositionType | undefined>

  delete(id: string): Observable<boolean>

  create(e: PositionType): Observable<PositionType | undefined>
}

export class PositionTypesService implements IPositionTypeService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IPositionTypeApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IPositionTypeApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  create(e: PositionType): Observable<PositionType | undefined> {
    return this._api.create(e)
  }

  getAll(q: Query<PositionType>): Observable<PositionType[]> {
    return this._api.getAll(q).pipe()
  }

  getByName(name: string): Observable<PositionType | undefined> {
    return this._api.getByName(name)
  }

  getByID(id: string): Observable<PositionType | undefined> {
    return this._api.getByID(id)
  }

  update(e: PositionType): Observable<PositionType | undefined> {
    return this._api.update(e)
  }

  createPositionTypeUser(e: PositionTypeUser): Observable<PositionTypeUser | undefined> {
    return this._api.createPositionTypeUser(e)
  }

  updatePositionTypeUser(e: PositionTypeUser): Observable<PositionTypeUser | undefined> {
    return this._api.updatePositionTypeUser(e)
  }

  deletePositionTypeUserByUserID(id: string): Observable<boolean> {
    return this._api.deletePositionTypeUserByUserID(id)
  }

  getUserPositionTypeByUserID(userID: string): Observable<PositionTypeUser | undefined> {
    return this._api.getUserPositionTypeByUserID(userID)
  }

  getUserPositionTypeByPositionTypeID(
    positionTypeID: string
  ): Observable<PositionTypeUser | undefined> {
    return this._api.getUserPositionTypeByPositionTypeID(positionTypeID)
  }

  getListUserPositionType(q: Query<PositionTypeUserQuery>): Observable<PositionTypeUser[]> {
    return this._api.getListUserPositionType(q)
  }

  getPositionTypeByUserID(userID: string): Observable<PositionType | undefined> {
    return this._api.getPositionTypeByUserID(userID)
  }
}

export class IPositionTypesService {}
