import { MenuConfig } from './MenuConfig'

export interface MenuConfigDTO {
  wall: boolean
  patientData: boolean
  participant: boolean
  chats: boolean
  querys: boolean
  calendar: boolean
}

export function emptyMenuConfigDTO() {
  return {
    wall: false,
    patientData: false,
    participant: false,
    chats: false,
    querys: false,
    calendar: false,
  }
}

export function fromModel(e: MenuConfig): MenuConfigDTO {
  return {
    wall: e.wall,
    patientData: e.patientData,
    participant: e.participant,
    chats: e.chats,
    querys: e.querys,
    calendar: e.calendar,
  }
}

export function toModel(d: MenuConfigDTO): MenuConfig {
  return new MenuConfig(d)
}
