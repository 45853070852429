import i18n from '../../../i18n'

export enum Mood {
  never = -1,
  good = 1,
  regular,
  bad,
}

export const moodTypes = (): Record<Mood, string> => ({
  [Mood.never]: i18n.t('never'),
  [Mood.good]: i18n.t('good'),
  [Mood.regular]: i18n.t('regular'),
  [Mood.bad]: i18n.t('bad'),
})
