import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
  UserCircleContainerConfigProps,
} from '../modules/user-circle/container'
import { UserCircleService } from '../modules/user-circle/services/UserCircleConfigService'
import {
  USER_CIRCLE_API_KEY,
  USER_CIRCLE_SERVICE_KEY,
  USER_CIRCLE_MODULE,
} from '../modules/user-circle'
import { UserCircleApi } from '../modules/user-circle/api/UserCircleApi'
import { UserCircleActiveService } from '../modules/user-circle/services/UserCircleActiveService'

let container: Container

function init(p: UserCircleContainerConfigProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(USER_CIRCLE_API_KEY, new ContainerItem({ instance: new UserCircleApi() }))

  items.set(
    USER_CIRCLE_SERVICE_KEY,
    new ContainerItem({ instance: new UserCircleService({ apiKey: USER_CIRCLE_API_KEY }) })
  )

  items.set(
    USER_CIRCLE_ACTIVE_SERVICE_KEY,
    new ContainerItem({ instance: new UserCircleActiveService({ apiKey: USER_CIRCLE_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getUserCircleContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[USER_CIRCLE_MODULE],
      },
    })
  }

  return container
}
