export type UserTokenProps = {
  code: string
  userID: string
  loginType: number
}

export class UserToken {
  private readonly _code: string
  private readonly _userID: string
  private readonly _loginType: number

  constructor(p: UserTokenProps) {
    this._code = p.code
    this._userID = p.userID
    this._loginType = p.loginType
  }

  get code(): string {
    return this._code
  }

  get userID(): string {
    return this._userID
  }

  get loginType(): number {
    return this._loginType
  }
}
