import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { GrowthMonitoring, GrowthMonitoringQuery } from '../models/GrowthMonitoring'
import { IStatusService } from '../../../common/status/StatusService'
import { IGrowthMonitoringApi } from '../api/GrowthMonitoringApi'
import { GrowthMonitoringDTO } from '../models/GrowthMonitoringDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface IGrowthMonitoringService extends IInit {
  getByID(id: string): Observable<GrowthMonitoring | undefined>

  getFilteredList(q: Query<GrowthMonitoringQuery>): Observable<ItemList<GrowthMonitoring>>

  add(e: GrowthMonitoringDTO): Observable<GrowthMonitoring | undefined>

  update(e: GrowthMonitoringDTO): Observable<GrowthMonitoring | undefined>

  delete(id: string): Observable<boolean>
}

export class GrowthMonitoringService implements IGrowthMonitoringService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IGrowthMonitoringApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IGrowthMonitoringApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: GrowthMonitoringDTO): Observable<GrowthMonitoring | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string | null): Observable<GrowthMonitoring | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<GrowthMonitoringQuery>): Observable<ItemList<GrowthMonitoring>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: GrowthMonitoringDTO): Observable<GrowthMonitoring | undefined> {
    return this._api.update(e)
  }
}
