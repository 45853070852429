import { getUserCircleContainer } from './container/user-circle-module'
import {
  IUserCircleActiveService,
  IUserCircleService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
  USER_CIRCLE_SERVICE_KEY,
} from './modules/user-circle'
import { useEffect } from 'react'
import { UserCircleQuery } from './modules/user-circle/models/UserCircle'
import { Query, QueryParam } from './common/api/Query'
import { getUserContainer } from './container/user-module'
import { ILoggedUserService } from './modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from './modules/users'

const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function PatientSetAssistance() {
  const loggedUser = loggedUserService.get()
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    if (!loggedUser?.id) return
    if (!(params.get('from_email') === 'true' && params.get('circle') && params.get('training'))) {
      return
    }

    userCircleService
      .getFilteredListWithCircleAndUser(
        new Query<UserCircleQuery>({
          pager: {
            offset: 0,
            limit: 1,
          },
          query: [
            new QueryParam('includesUserID', loggedUser.id),
            new QueryParam('searchByCircleOrUser', 'Plan Activa'),
          ],
          sort: [{ field: 'userID', desc: true }],
        })
      )
      .subscribe((res) => {
        if (!res || res.items.length === 0) return
        userCircleActiveService.setActiveUser(res.items[0].user)
        userCircleActiveService.setActiveFullUserCircle(res.items[0])
      })
  }, [])
}
