import { Observable, Subject } from 'rxjs'
import { toModel, User, UserDTO } from '../models/User'
import { Container, IInit } from '../../../common/container/Container'
import { IUserApi } from '../api/UserApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface ICircleService extends IInit {
  getActiveObservable(): Observable<User | undefined>

  getActiveUser(): User | undefined

  setActive(c: User | undefined): void
}

const SELECTED_CIRCLE_KEY = 'selected circle'

export type Props = {
  apiKey: symbol
}

export class CircleService implements ICircleService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserApi
  private _statusService!: IStatusService

  private _activeCircle: User | undefined
  private _circleSubject = new Subject<User>()

  constructor(p: Props) {
    this.loadCircle()
    this._apiKey = p.apiKey
  }

  init(c: Container): void {
    this._container = c
    this._api = this._container.get<IUserApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getActiveUser(): User | undefined {
    return this._activeCircle
  }

  getActiveObservable(): Observable<User | undefined> {
    return this._circleSubject.pipe()
  }

  setActive(c: User | undefined) {
    this.storeCircle(c)
    this.next()
  }

  private next() {
    this._circleSubject.next(this._activeCircle)
  }

  private loadCircle() {
    const dto = JSON.parse(localStorage.getItem(SELECTED_CIRCLE_KEY) || 'null') as UserDTO

    if (dto) {
      this._activeCircle = toModel(dto)
      this.next()
    }
  }

  private storeCircle(c: User | undefined) {
    const dto = c ? c.toDTO() : undefined
    if (dto) {
      localStorage.setItem(SELECTED_CIRCLE_KEY, JSON.stringify(dto))
    } else {
      localStorage.removeItem(SELECTED_CIRCLE_KEY)
    }
    this._activeCircle = c
    this.next()
  }
}
