import React from 'react'
import { Wearables as W } from '../../features/patient-data'
import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export const Wearables = (props: RouteProps) => {
  const title = props.title ?? ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <W />
    </>
  )
}
