import { Container } from '../../common/container/Container'

export type TrainingContainerConfig = {
  moduleFullUrl: string
}

export type TrainingProps = {
  parentContainer: Container
  config: TrainingContainerConfig
}

export const TRAINING_MODULE = Symbol('TRAINING_MODULE')

export const TRAINING_API_KEY = Symbol('TRAINING_API_KEY')

export const TRAINING_SERVICE_KEY = Symbol('TRAINING_SERVICE_KEY')
