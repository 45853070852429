import { Container, IInit } from '../../../common/container/Container'
import { Consulta } from '../models/Consulta'
import { MessengerConsultaContainerConfig } from '../container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import {
  ConsultaDTO,
  fromModel,
  MessageDTO,
  messageFromModel,
  messageToModel,
  toModel,
} from '../models/ConsultaDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { Message } from '../models/Message'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ProfessionalQuery } from '../models/ProfessionalQuery'
import { ProfessionalQueryDTO, toModel as toModelPQ } from '../models/ProfessionalQueryDTO'

export interface IConsultaApi extends IInit {
  getByID(id: string): Observable<Consulta | undefined>

  getFilteredItems(q: Query<Consulta>): Observable<Consulta[]>

  getProfessionalQuery(id: string, circleID: string): Observable<ProfessionalQuery | undefined>

  add(e: Consulta): Observable<Consulta | undefined>

  addMessage(m: Message): Observable<Message | undefined>

  update(e: Consulta): Observable<Consulta | undefined>

  delete(id: string): Observable<boolean>
}

export class ConsultaApi implements IConsultaApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url =
      (this._container.config as MessengerConsultaContainerConfig).moduleFullUrl + '/consulta'
  }

  add(e: Consulta): Observable<Consulta | undefined> {
    return this._httpClient.post<Consulta>({ url: this._url, body: fromModel(e) }).pipe(
      map<ConsultaDTO, Consulta>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  addMessage(m: Message): Observable<Message | undefined> {
    return this._httpClient
      .post<Message>({
        url: `${
          (this._container.config as MessengerConsultaContainerConfig).moduleFullUrl
        }/messages_consulta`,
        body: messageFromModel(m),
      })
      .pipe(
        map<MessageDTO, Message>((d) => messageToModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Consulta | undefined> {
    return this._httpClient.get<Consulta>({ url: `${this._url}/${id}` }).pipe(
      map<ConsultaDTO, Consulta>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredItems(q: Query<Consulta>): Observable<Consulta[]> {
    return this._httpClient.get<Consulta[]>({ url: prepareURL(this._url, q) }).pipe(
      map<ConsultaDTO[], Consulta[]>((dto) => dto.map((d) => toModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  getProfessionalQuery(id: string, circleId: string): Observable<ProfessionalQuery | undefined> {
    return this._httpClient
      .get<ProfessionalQuery>({ url: `${this._url}/profesional_query/${id}/${circleId}` })
      .pipe(
        map<ProfessionalQueryDTO, ProfessionalQuery>((d) => toModelPQ(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  update(e: Consulta): Observable<Consulta | undefined> {
    return this._httpClient.put<Consulta>({ url: this._url, body: fromModel(e) }).pipe(
      map<ConsultaDTO, Consulta>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
