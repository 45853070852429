import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { EpilepticalCrisis } from '../models/EpilepticalCrisis'
import { EpilepticalCrisisDTO } from '../models/EpilepticalCrisisDTO'
import { IEpilepticalCrisisApi } from '../api/EpilepticalCrisisApi'

type Props = {
  apiKey: symbol
}

export interface IEpilepticalCrisisService extends IInit {
  getByID(id: string): Observable<EpilepticalCrisis | undefined>

  getFilteredList(q: Query<EpilepticalCrisis>): Observable<ItemList<EpilepticalCrisis>>

  add(e: EpilepticalCrisisDTO): Observable<EpilepticalCrisis | undefined>

  update(e: EpilepticalCrisisDTO): Observable<EpilepticalCrisis | undefined>

  delete(id: string): Observable<boolean>

  byPatientSymptomID(q: Query<EpilepticalCrisis>): Observable<ItemList<EpilepticalCrisis>>

  getLastMonth(q: Query<EpilepticalCrisis>): Observable<ItemList<EpilepticalCrisis>>
}

export class EpilepticalCrisisService implements IEpilepticalCrisisService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IEpilepticalCrisisApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IEpilepticalCrisisApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: EpilepticalCrisisDTO): Observable<EpilepticalCrisis | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<EpilepticalCrisis | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<EpilepticalCrisis>): Observable<ItemList<EpilepticalCrisis>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: EpilepticalCrisisDTO): Observable<EpilepticalCrisis | undefined> {
    return this._api.update(e)
  }

  byPatientSymptomID(q: Query<EpilepticalCrisis>): Observable<ItemList<EpilepticalCrisis>> {
    return this._api.byPatientSymptomID(q)
  }

  getLastMonth(q: Query<EpilepticalCrisis>): Observable<ItemList<EpilepticalCrisis>> {
    return this._api.getLastMonth(q)
  }
}
