import { ProfessionalQuery } from './ProfessionalQuery'
import { v4 as uuidv4 } from 'uuid'

export interface ProfessionalQueryDTO {
  id: string
  message: string
  userID: string
  acceptConsulta: number
}

export function emptyProfessionalQueryDTO() {
  return {
    id: uuidv4(),
    message: '',
    userID: '',
    acceptConsulta: 0,
  }
}

export function fromModel(c: ProfessionalQuery): ProfessionalQueryDTO {
  return {
    id: c.id,
    message: c.message,
    userID: c.userID,
    acceptConsulta: c.acceptConsulta,
  }
}

export function toModel(d: ProfessionalQueryDTO): ProfessionalQuery {
  return new ProfessionalQuery(d)
}
