import { UserEpilepticalConfig } from './UserEpilepticalConfig'
import { v4 as uuidv4 } from 'uuid'
import { Duration } from '../enums/Duration'
import { Zone } from '../enums/Zone'
import { CrisisType } from '../enums/CrisisType'
import { Awareness } from '../enums/Awareness'
import { Movement } from '../enums/Movement'
import { Vision } from '../enums/Vision'
import { SkinColor } from '../enums/SkinColor'
import { Symptom } from '../enums/Symptom'
import { Mouth } from '../enums/Mouth'
import { AfterDuration } from '../enums/AfterDuration'

export interface UserEpilepticalConfigDTO {
  id: string
  userCircleID: string
  crisisType: CrisisType
  color: string
  comment: string
  duration: Duration
  zone: Zone
  awareness: Awareness
  movement: Movement
  vision: Vision
  skinColor: SkinColor
  symptom: Symptom
  mouth: Mouth
  afterSymptom: string[]
  afterDuration: AfterDuration
}

export function emptyUserEpilepticalConfig() {
  return {
    id: uuidv4(),
    userCircleID: '',
    crisisType: -1,
    color: '',
    comment: '',
    duration: -1,
    zone: -1,
    awareness: -1,
    movement: -1,
    vision: -1,
    skinColor: -1,
    symptom: -1,
    mouth: -1,
    afterSymptom: [],
    afterDuration: -1,
  }
}

export function fromModel(f: UserEpilepticalConfig): UserEpilepticalConfigDTO {
  return {
    id: f.id,
    userCircleID: f.userCircleID,
    crisisType: f.crisisType,
    color: f.color,
    comment: f.comment,
    duration: f.duration,
    zone: f.zone,
    awareness: f.awareness,
    movement: f.movement,
    vision: f.vision,
    skinColor: f.skinColor,
    symptom: f.symptom,
    mouth: f.mouth,
    afterSymptom: f.afterSymptom,
    afterDuration: f.afterDuration,
  }
}

export function toModel(d: UserEpilepticalConfigDTO): UserEpilepticalConfig {
  return new UserEpilepticalConfig(d)
}
