import style from './Login.module.css'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { isPatient } from './Login'
import { LoadingSpinner } from 'components/loading-spinner/LoadingSpinner'

export const UserNotFound = () => {
  const [isLoading, setIsLoading] = useState(true)

  setInterval(() => {
    setIsLoading(false)
  }, 1000)

  return !isLoading ? (
    <Box className={style.loginContainer} display={'flex'}>
      <Box className={style.login}>
        {isPatient() ? (
          <h2>El usuario no tiene círculos asignados.</h2>
        ) : (
          <Box className={style.unAuth}>
            <h2>No está autorizado para acceder a la aplicación (error HTTP 401)</h2>
            <h4>
              Si viene de otra aplicación(OMI, Selene, etc. ), compruebe que el usuario es el suyo y
              vuelva a intentarlo.
            </h4>
            <h4>
              Si necesita ayuda, póngase en contacto con el Centro de Soporte del SMS en{' '}
              <a href={'http://soportesms.carm.es'}>http://soportesms.carm.es</a> o llamando al 968
              27 91 00.
            </h4>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Box className={style.loginContainer} display={'flex'}>
      <Box className={style.spinnerContainer}>
        <LoadingSpinner />
      </Box>
    </Box>
  )
}
