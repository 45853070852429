import { UserTrainingGroup } from './UserTrainingGroup'

export interface UserTrainingGroupDTO {
  groupID: string
  userCircleID: string
}

export function emptyUserTrainingGroupDTO() {
  return {
    groupID: '',
    userCircleID: '',
  }
}

export function fromModel(e: UserTrainingGroup): UserTrainingGroupDTO {
  return {
    groupID: e.groupID,
    userCircleID: e.userCircleID,
  }
}

export function toModel(d: UserTrainingGroupDTO): UserTrainingGroup {
  return new UserTrainingGroup({
    groupID: d.groupID,
    userCircleID: d.userCircleID,
  })
}
