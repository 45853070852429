import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import { User, UserQuery } from '../../../modules/users/models/User'
import styles from './AsignForm.module.css'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getUserContainer } from '../../../container/user-module'
import Grid from '@mui/material/Grid'
import { Roles } from '../../../modules/users/enums/Roles'
import family from '../../../assets/role_icons/ico-rol-familiar.svg'
import familySelected from '../../../assets/role_icons/role-icons-selected/ico-rol-familiar.svg'
import patient from '../../../assets/role_icons/ico-rol-paciente.svg'
import patientSelected from '../../../assets/role_icons/role-icons-selected/ico-rol-paciente.svg'
import professionalSMS from '../../../assets/role_icons/ico-rol-profesional.svg'
import professionalSMSselected from '../../../assets/role_icons/role-icons-selected/ico-rol-profesional.svg'
import externProfessional from '../../../assets/role_icons/ico-rol-externo.svg'
import externProfessionalSelected from '../../../assets/role_icons/role-icons-selected/ico-rol-externo.svg'
import career from '../../../assets/role_icons/ico-rol-cuidador.svg'
import careerSelected from '../../../assets/role_icons/role-icons-selected/ico-rol-cuidador.svg'
import userIcon from '../../../assets/role_icons/Icono_usuario.png'

import { ICircleService } from '../../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../../modules/users/container'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { AssignedUser } from '../Editor'
import { RowItem } from './AssignFormSection'
import { UserForm } from '../../../modules/forms/models/UserForm'
import { SearchValue } from '../../../components/table_type/types'
import { FormDTO } from '../../../modules/forms/models/FormDTO'
import { capitalizeWords } from '../../../common/utils/capitalizeWords/CapitalizeWords'
import { styled } from '@mui/material/styles'
import { UserCircleWithRelatersAndCircle } from '../../../modules/user-circle/models/UserCircleWithRelatersAndCircle'

type AssignCardProps = {
  userCircles: UserCircleWithRelatersAndCircle[]
  addUser: (user: RowItem) => void
  handlerAssignedUser: (assignedUsers: AssignedUser[]) => void
  assignedUsers: AssignedUser[]
  mainRef: React.RefObject<HTMLDivElement> | undefined
  id?: string
  usersFormEdit: UserForm[]
  handlerUsersCards: (users: UserWithRelatersCustom[]) => void
  custom: boolean
  searchedUsers: User[]
  searcher: SearchValue<UserQuery>[]
  recoveredSelected: (selected: RowItem[]) => void
  preview?: boolean
  selectedOldState?: RowItem[]
  usersLetter: UserCircleWithRelatersAndCircle[]
  selectedValue: boolean
  form: FormDTO
}

const userContainer = getUserContainer()
const circleService = userContainer.get<ICircleService>(CIRCLE_SERVICE_KEY)

const CardContentNoPadding = styled(CardContent)(`
  padding-left: 16px !important;
  padding-right:16px !important;
  padding-top: 0px !important;
  margin-top:4px !important;
  scrollbar-width: none; /* Para navegadores de la familia Firefox */
  -ms-overflow-style: none; /* Para navegadores de la familia Internet Explorer */
     &::-webkit-scrollbar {
        display: none; /* Para navegadores de la familia Chrome */
    }
`)

type relatersCustom = {
  relater: User
  relaterRole: Roles
}

export type UserWithRelatersCustom = {
  circleName: string
  isPediatric: boolean
  userCircleID: string
  user: User
  relaters: relatersCustom[]
}

export function AssignCard({
  userCircles,
  addUser,
  handlerAssignedUser,
  assignedUsers,
  mainRef,
  usersFormEdit,
  id,
  handlerUsersCards,
  custom,
  searchedUsers,
  searcher,
  recoveredSelected,
  preview,
  selectedOldState,
  usersLetter,
  selectedValue,
  form,
}: AssignCardProps) {
  const circle = circleService.getActiveUser()

  const [selected, setSelected] = useState<RowItem[]>(
    selectedOldState?.length ? selectedOldState : []
  )
  const { t } = useTranslation()
  const prevSelectedRef = useRef<RowItem[]>([])
  const relatedUserRef = useRef<string>('')
  const [visibleFamiliars, setVisibleFamiliars] = useState(6)
  const listRef = useRef<HTMLDivElement>(null)
  const [isListVisible, setIsListVisible] = useState(false)
  const [usersWithRelaters, setUsersWithRelaters] = useState<UserWithRelatersCustom[]>([])
  const [usersWithRelatersByLetter, setUsersWithRelatersByLetter] = useState<
    UserWithRelatersCustom[]
  >([])
  const [chargingAssignedUser, setChargingAssignedUser] = useState<AssignedUser[]>([])
  const [filteredUsers, setFilteredUsers] = useState<UserWithRelatersCustom[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!custom) {
      setSelected([])
    }
  }, [custom])

  useEffect(() => {
    recoveredSelected(selected)
  }, [preview, selected])

  useEffect(() => {
    const userWithRelatersAux: UserWithRelatersCustom[] = []
    userCircles.forEach((userCircle) => {
      let relaters = userCircle.familiars.map((familiar) => ({
        relater: familiar,
        relaterRole: Roles.FamilyOrTutor,
      }))

      relaters = relaters.concat(
        userCircle.profExt.map((externProf) => ({
          relater: externProf,
          relaterRole: Roles.ProfessionalExtern,
        }))
      )
      relaters = relaters.concat(
        userCircle.careers.map((carer) => ({
          relater: carer,
          relaterRole: Roles.CarerOrTeacher,
        }))
      )

      userWithRelatersAux.push({
        user: userCircle.user,
        circleName: userCircle.circle.name,
        isPediatric: userCircle.circle.specialities.includes('Pediatra'),
        userCircleID: userCircle.id,
        relaters,
      })
    })
    setUsersWithRelaters(userWithRelatersAux)
    setTimeout(() => {
      setIsLoading(false)
    }, 200)
  }, [userCircles])

  useEffect(() => {
    setIsLoading(true)
    const userWithRelatersLetterAux: UserWithRelatersCustom[] = []
    usersLetter.forEach((userCircle) => {
      let relaters = userCircle.familiars.map((familiar) => ({
        relater: familiar,
        relaterRole: Roles.FamilyOrTutor,
      }))

      relaters = relaters.concat(
        userCircle.profExt.map((externProf) => ({
          relater: externProf,
          relaterRole: Roles.ProfessionalExtern,
        }))
      )
      relaters = relaters.concat(
        userCircle.careers.map((carer) => ({
          relater: carer,
          relaterRole: Roles.CarerOrTeacher,
        }))
      )

      userWithRelatersLetterAux.push({
        user: userCircle.user,
        circleName: userCircle.circle.name,
        isPediatric: userCircle.circle.specialities.includes('Pediatra'),
        userCircleID: userCircle.id,
        relaters,
      })
    })
    setUsersWithRelatersByLetter(userWithRelatersLetterAux)
    setTimeout(() => {
      setIsLoading(false)
    }, 200)
  }, [usersLetter])

  useEffect(() => {
    setIsLoading(true)
    if (searcher[0].value === '') {
      setFilteredUsers([])
      setIsLoading(false)
      return
    }

    const filteredByUser = usersWithRelaters.filter((userWithRelater) =>
      searchedUsers.some(
        (user) =>
          user.id === userWithRelater.user.id ||
          user.name === userWithRelater.user.name ||
          (user.dni === userWithRelater.user.dni && user.id === userWithRelater.user.id)
      )
    )
    const filteredByRelatedUsers = usersWithRelaters.filter((userWithRelater) =>
      userWithRelater.relaters.some((user) =>
        searchedUsers.some(
          (searchedUser) =>
            searchedUser.id === user.relater.id ||
            searchedUser.name === user.relater.name ||
            (searchedUser.dni === user.relater.dni && user.relater.id === userWithRelater.user.id)
        )
      )
    )

    const filteredUsers = filteredByUser.length
      ? filteredByUser
      : filteredByRelatedUsers.length
        ? filteredByRelatedUsers
        : []

    setFilteredUsers(filteredUsers)
    setTimeout(() => {
      setIsLoading(false)
    }, 200)
  }, [searchedUsers, searcher])

  useEffect(() => {
    if (!id) return
    if (usersFormEdit.length > 0) {
      const arraySelected: RowItem[] = []

      const newAssignedUsers: AssignedUser[] = [] // Almacenar los nuevos valores en una variable local

      usersFormEdit.forEach((res, index) => {
        if (!res) return

        usersWithRelaters.forEach((userwr) => {
          if (userwr.user.id === res.userID && userwr.userCircleID === res.circleID) {
            const user: RowItem = {
              uuid: uuidv4(),
              id: userwr.user.id,
              name: userwr.user.name,
              birthDate: userwr.user.birthDate,
              cip: userwr.user.cip,
              dni: userwr.user.dni,
              circleID: userwr.circleName,
              roles: userwr.user.roles,
              userCircleID: userwr.userCircleID,
              isPediatric: userwr.isPediatric,
            }
            addUser(user)

            arraySelected.push(user)
            // Verificar si el elemento ya existe en newAssignedUsers
            if (
              !newAssignedUsers.some(
                (item) => item.userID === res.id && item.circleID === res.circleID
              )
            ) {
              newAssignedUsers.push({ userID: user.id, circleID: user.userCircleID }) // Agregar el nuevo elemento a la variable local
            }
          }

          if (userwr.relaters.some((relatedUser) => relatedUser.relater.id === res.userID)) {
            const filtered = userwr.relaters.filter(
              (relatedUser) =>
                relatedUser.relater.id === res.userID && res.circleID === userwr.userCircleID
            )
            filtered.forEach((relatedUser) => {
              const user2: RowItem = {
                uuid: uuidv4(),
                id: relatedUser.relater.id,
                name: relatedUser.relater.name,
                birthDate: relatedUser.relater.birthDate,
                cip: relatedUser.relater.cip,
                dni: relatedUser.relater.dni,
                circleID: userwr.circleName,
                roles: relatedUser.relater.roles,
                userCircleID: userwr.userCircleID,
                isPediatric: userwr.isPediatric,
              }
              addUser(user2)
              arraySelected.push(user2)

              if (
                !newAssignedUsers.some(
                  (item) => item.userID === user2.id && item.circleID === userwr.userCircleID
                )
              ) {
                newAssignedUsers.push({ userID: user2.id, circleID: userwr.userCircleID }) // Agregar el nuevo elemento a la variable local
              }
            })
          }
        })
      })
      setSelected(arraySelected)
      setChargingAssignedUser(newAssignedUsers)
    }
  }, [id, handlerAssignedUser, usersFormEdit, filteredUsers, usersWithRelaters])

  useEffect(() => {
    if (chargingAssignedUser.length > 0) {
      setTimeout(() => {
        handlerAssignedUser(chargingAssignedUser)
      }, 500)
    }
  }, [chargingAssignedUser, handlerAssignedUser])

  useEffect(() => {
    prevSelectedRef.current = selected
  }, [selected])

  useEffect(() => {
    if (!custom || !usersWithRelaters.length) return
    handlerUsersCards(usersWithRelaters)
  }, [custom, usersWithRelaters, id])

  const loadMoreCards = () => {
    setVisibleFamiliars((prev) => prev + 6)
  }

  const handleScroll = useCallback(() => {
    const parent = mainRef?.current
    // Opción 1: Si el elemento está exactamente en el fondo del scroll
    if (parent && parent.scrollHeight - parent.scrollTop === parent.clientHeight) {
      setTimeout(() => {
        loadMoreCards()
      }, 300)
      return
    }

    // Opción 2: Si el elemento está cerca del fondo del scroll con un margen de error
    const marginOfError = -0.5 // Puedes ajustar el margen de error según tus necesidades

    if (
      parent &&
      parent.scrollHeight - (parent.scrollTop + marginOfError) === parent.clientHeight
    ) {
      setTimeout(() => {
        loadMoreCards()
      }, 300)
    }
  }, [loadMoreCards])

  useEffect(() => {
    const parent = mainRef?.current
    if (parent) {
      parent.addEventListener('scroll', handleScroll)
      return () => parent.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const handlerRoleIcon = (i: User, isUserSelected: boolean, role: Roles) => {
    let img = ''

    const roleImages = {
      [Roles.Patient]: isUserSelected ? patientSelected : patient,
      [Roles.FamilyOrTutor]: isUserSelected ? familySelected : family,
      [Roles.Professional]: isUserSelected ? professionalSMSselected : professionalSMS,
      [Roles.ProfessionalExtern]: isUserSelected ? externProfessionalSelected : externProfessional,
      [Roles.CarerOrTeacher]: isUserSelected ? careerSelected : career,
    }

    switch (role) {
      case Roles.Patient:
        if (i.id !== circle?.id) {
          img = roleImages[Roles.FamilyOrTutor]
          role = Roles.FamilyOrTutor
          break
        }
        img = roleImages[Roles.Patient]
        break
      case Roles.FamilyOrTutor:
        if (i.id === circle?.id) {
          img = roleImages[Roles.Patient]
          role = Roles.Patient
          break
        }
        img = roleImages[Roles.FamilyOrTutor]
        break

      case Roles.ProfessionalExtern:
        img = roleImages[Roles.ProfessionalExtern]
        role = Roles.ProfessionalExtern
        break
      case Roles.CarerOrTeacher:
        img = roleImages[Roles.CarerOrTeacher]
        role = Roles.CarerOrTeacher
        break
      case Roles.ContentManager:
        img = roleImages[Roles.Professional]
        role = Roles.ContentManager
        break
    }

    const roleTranslate = t(role)

    return (
      <Box>
        <Tooltip title={roleTranslate}>
          <img className={styles.imageRoles} src={img} alt={'image role' + role} />
        </Tooltip>
      </Box>
    )
  }

  useEffect(() => {
    if (id) return
    const users = assignedUsers.filter((a) => selected.find((s) => s.userCircleID === a.circleID))
    handlerAssignedUser(users)
  }, [assignedUsers, selected, handlerAssignedUser])

  const handlerAddRelatedUser = (
    u: User,
    f: User,
    circle: string,
    isSelected: boolean,
    userCircleID: string,
    isPediatric: boolean
  ) => {
    const user: RowItem = {
      uuid: uuidv4(),
      id: u.id,
      name: u.name,
      birthDate: u.birthDate,
      cip: u.cip,
      dni: u.dni,
      circleID: circle,
      roles: f.roles,
      userCircleID,
      isPediatric,
    }
    addUser(user)

    if (!isSelected) {
      setSelected(
        prevSelectedRef.current.filter((s) => s.id !== user.id || s.circleID !== user.circleID)
      )
      if (id) {
        setChargingAssignedUser((prevChargingAssignedUser: AssignedUser[]) =>
          prevChargingAssignedUser.filter(
            (a) => a.userID !== u.id || a.circleID !== user.userCircleID
          )
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        handlerAssignedUser((prevAssignedUsers: AssignedUser[]) =>
          prevAssignedUsers.filter((a) => a.userID !== u.id || a.circleID !== user.userCircleID)
        )
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        handlerAssignedUser((prevAssignedUsers: AssignedUser[]) =>
          prevAssignedUsers.filter((a) => a.userID !== u.id || a.circleID !== user.userCircleID)
        )
      }
    } else {
      setSelected([...selected, user])
      if (id) {
        setChargingAssignedUser((prevChargingAssignedUser: AssignedUser[]) => [
          ...prevChargingAssignedUser,
          { userID: u.id, circleID: user.userCircleID },
        ])
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        handlerAssignedUser((prevAssignedUsers: AssignedUser[]) => [
          ...prevAssignedUsers,
          { userID: u.id, circleID: user.userCircleID },
        ])
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        handlerAssignedUser((prevAssignedUsers: AssignedUser[]) => [
          ...prevAssignedUsers,
          { userID: u.id, circleID: user.userCircleID },
        ])
      }
    }
  }

  const handlerAddUser = (
    u: User,
    circleName: string,
    isSelected: boolean,
    userCircleID: string,
    isPediatric: boolean
  ) => {
    const user: RowItem = {
      uuid: uuidv4(),
      id: u.id,
      name: u.name,
      birthDate: u.birthDate,
      cip: u.cip,
      dni: u.dni,
      circleID: circleName,
      roles: u.roles,
      userCircleID,
      isPediatric,
    }

    addUser(user)

    /* const userWithRelaters = usersWithRelaters.filter(
      (uwr) => uwr.user.id === u.id && uwr.userCircleID === userCircleID && uwr.isPediatric
    ) */

    if (!isSelected) {
      /* if (userWithRelaters.length > 0) {
        const newSelected = selected.filter((s) => s.id !== u.id || s.circleID !== circleName)
        const newSelected2 = newSelected.filter(
          (s) =>
            !userWithRelaters[0].relaters.some(
              (r) => r.relater.id === s.id && s.userCircleID === userCircleID
            )
        )

        setSelected(newSelected2)
      } else {
        setSelected(selected.filter((s) => s.id !== u.id || s.circleID !== circleName))
      } */
      setSelected(selected.filter((s) => s.id !== u.id || s.circleID !== circleName))
      if (id) {
        setChargingAssignedUser((prevChargingAssignedUser: AssignedUser[]) =>
          prevChargingAssignedUser.filter(
            (a) => a.userID !== u.id || a.circleID !== user.userCircleID
          )
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        handlerAssignedUser((prevAssignedUsers: AssignedUser[]) =>
          prevAssignedUsers.filter((a) => a.userID !== u.id || a.circleID !== user.userCircleID)
        )
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        handlerAssignedUser((prevAssignedUsers: AssignedUser[]) =>
          prevAssignedUsers.filter((a) => a.userID !== u.id || a.circleID !== user.userCircleID)
        )
      }
    } else {
      /* if (userWithRelaters.length > 0) {
        usersWithRelaters.forEach((uwr) => {
          if (uwr.user.id === u.id && uwr.isPediatric && uwr.userCircleID === userCircleID) {
            const newSelected = [...selected, user]
            uwr.relaters.forEach((relater) => {
              if (relater.relaterRole === Roles.FamilyOrTutor) {
                newSelected.push({
                  uuid: relater.relater.id,
                  id: relater.relater.id,
                  name: relater.relater.name,
                  birthDate: relater.relater.birthDate,
                  cip: relater.relater.cip,
                  dni: relater.relater.dni,
                  circleID: uwr.circleName,
                  roles: relater.relater.roles,
                  userCircleID,
                  isPediatric: uwr.isPediatric,
                })
              }
            })
            newSelected.map((s) => addUser(s))
            setSelected(newSelected)
          }
        })
      } else {
        setSelected([...selected, user])
      } */
      setSelected([...selected, user])
      if (id) {
        setChargingAssignedUser((prevChargingAssignedUser: AssignedUser[]) => [
          ...prevChargingAssignedUser,
          { userID: u.id, circleID: user.userCircleID },
        ])
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        handlerAssignedUser((prevAssignedUsers: AssignedUser[]) => [
          ...prevAssignedUsers,
          { userID: u.id, circleID: user.userCircleID },
        ])
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        handlerAssignedUser((prevAssignedUsers: AssignedUser[]) => [
          ...prevAssignedUsers,
          { userID: u.id, circleID: user.userCircleID },
        ])
      }
    }
  }

  const isRelatedUserSelected = (userId: string, circleId: string | null, userCircleID: string) => {
    return selected.some(
      (s) => s.id === userId && s.circleID === circleId && s.userCircleID === userCircleID
    )
  }

  const isUserSelected = (userId: string, circleId: string | null) => {
    return selected.some((s) => s.id === userId && s.circleID === circleId)
  }

  const [cardState, setCardState] = useState({})

  // Función para actualizar el estado de la card
  const handleOpen = (id: string) => {
    setCardState((prevCardState) => ({
      ...prevCardState,
      [id]: true,
    }))
  }

  const handleClose = (id: string) => {
    setCardState((prevCardState) => ({
      ...prevCardState,
      [id]: false,
    }))
  }

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        const { top, bottom } = listRef.current.getBoundingClientRect()
        const windowHeight = window.innerHeight
        if (top < windowHeight && bottom >= 0 && !isListVisible) {
          setIsListVisible(true)
        } else if (top >= windowHeight || bottom < 0) {
          setIsListVisible(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isListVisible])

  return (
    <div ref={listRef}>
      {!isLoading ? (
        <Grid container className={styles.cardsContent}>
          {searcher[0].value !== '' && filteredUsers.length > 0 ? (
            filteredUsers.slice(0, visibleFamiliars).map((f, i) => {
              const isCardSelected =
                f.relaters.some((ru) =>
                  isRelatedUserSelected(ru.relater.id, f.circleName, f.userCircleID)
                ) || isUserSelected(f.user.id, f.circleName)
              return (
                <Grid
                  key={f.user.id + i}
                  display={'flex'}
                  flexWrap={'wrap'}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Card
                    style={{
                      backgroundColor: isCardSelected
                        ? 'rgba(244,184,144,0.1)'
                        : 'rgba(96, 172, 223, 0.07)',
                      border: isCardSelected ? '1px solid #f37e31' : '1px solid #68b3e0',
                    }}
                    className={styles.cards}
                  >
                    <Box className={styles.headerContainer}>
                      <Box className={styles.headerContent}>
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          style={{ width: '100%' }}
                        >
                          <CardHeader
                            style={{
                              color: isUserSelected(f.user.id, f.user.id) ? '#f37e31' : '',
                            }}
                            className={styles.textName}
                            sx={{
                              '& .MuiCardHeader-title': {
                                fontFamily: 'Poppins',
                                fontSize: 20,
                              },
                            }}
                            title={
                              f.user.name.length > 20 ? (
                                <Tooltip
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-expect-error
                                  open={cardState[f.user.id]}
                                  onOpen={() => handleOpen(f.user.id)}
                                  onClose={() => handleClose(f.user.id)}
                                  arrow
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        bgcolor: 'transparent',
                                        '& .MuiTooltip-arrow': {
                                          color: 'transparent',
                                        },
                                        marginLeft: 4,
                                        color: '#000',
                                      },
                                    },
                                  }}
                                  title={
                                    <span style={{ fontFamily: 'Poppins', fontSize: 18 }}>
                                      {f.user.name + ' - ' + f.circleName}
                                    </span>
                                  }
                                >
                                  <span style={{ cursor: 'pointer', fontFamily: 'Poppins' }}>
                                    {capitalizeWords(f.user.name.substring(0, 20)) +
                                      ' - ' +
                                      f.circleName}
                                    ...
                                  </span>
                                </Tooltip>
                              ) : (
                                <span style={{ cursor: 'pointer' }}>
                                  {capitalizeWords(f.user.name) + ' - ' + f.circleName}
                                </span>
                              )
                            }
                            onClick={() => {
                              if (isUserSelected(f.user.id, f.circleName)) {
                                handlerAddUser(
                                  f.user,
                                  f.circleName,
                                  false,
                                  f.userCircleID,
                                  f.isPediatric
                                )
                              } else {
                                handlerAddUser(
                                  f.user,
                                  f.circleName,
                                  true,
                                  f.userCircleID,
                                  f.isPediatric
                                )
                              }
                            }}
                          />
                        </Box>
                      </Box>
                      <img
                        src={userIcon}
                        alt="user icon"
                        style={{ width: 50, height: 50, marginRight: 4, marginBottom: 4 }}
                      />
                    </Box>
                    <Divider
                      variant="middle"
                      style={{
                        border: isCardSelected ? '1px solid #f37e31' : '1px solid #68b3e0',
                      }}
                      className={styles.divider}
                    />
                    <CardContentNoPadding>
                      <List className={styles.list}>
                        {f.relaters.map((ru) => (
                          <ListItem key={ru.relater.id + uuidv4()} className={styles.relatersList}>
                            {handlerRoleIcon(
                              ru.relater,
                              isRelatedUserSelected(ru.relater.id, f.circleName, f.userCircleID),
                              ru.relaterRole
                            )}
                            <ListItemText
                              style={{
                                color: isRelatedUserSelected(
                                  ru.relater.id,
                                  f.circleName,
                                  f.userCircleID
                                )
                                  ? '#f37e31'
                                  : '',
                              }}
                              onClick={() => {
                                relatedUserRef.current = ru.relater.id
                                if (
                                  isRelatedUserSelected(ru.relater.id, f.circleName, f.userCircleID)
                                ) {
                                  handlerAddRelatedUser(
                                    ru.relater,
                                    f.user,
                                    f.circleName,
                                    false,
                                    f.userCircleID,
                                    f.isPediatric
                                  )
                                } else {
                                  handlerAddRelatedUser(
                                    ru.relater,
                                    f.user,
                                    f.circleName,
                                    true,
                                    f.userCircleID,
                                    f.isPediatric
                                  )
                                }
                              }}
                              className={styles.relaters}
                              primary={
                                <span style={{ width: '80px' }}>
                                  {capitalizeWords(ru.relater.name)}
                                </span>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContentNoPadding>
                  </Card>
                </Grid>
              )
            })
          ) : searcher[0].value === '' && selectedValue ? (
            usersLetter.length <= 0 ? (
              <Box style={{ height: 300, marginTop: 40 }}>
                <Typography style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
                  {t('notUsersResult')}
                </Typography>
              </Box>
            ) : (
              usersWithRelatersByLetter.slice(0, visibleFamiliars).map((f, i) => {
                const isCardSelected =
                  f.relaters.some((ru) =>
                    isRelatedUserSelected(ru.relater.id, f.circleName, f.userCircleID)
                  ) || isUserSelected(f.user.id, f.user.id)
                return (
                  <Grid
                    key={f.user.id + i}
                    display={'flex'}
                    flexWrap={'wrap'}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <Card
                      style={{
                        backgroundColor: isCardSelected
                          ? 'rgba(244,184,144,0.1)'
                          : 'rgba(96, 172, 223, 0.07)',
                        border: isCardSelected ? '1px solid #f37e31' : '1px solid #68b3e0',
                      }}
                      className={styles.cards}
                    >
                      <Box className={styles.headerContainer}>
                        <Box className={styles.headerContent}>
                          <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            style={{ width: '100%' }}
                          >
                            <CardHeader
                              style={{
                                color: isUserSelected(f.user.id, f.circleName) ? '#f37e31' : '',
                              }}
                              className={styles.textName}
                              sx={{
                                '& .MuiCardHeader-title': {
                                  fontFamily: 'Poppins',
                                  fontSize: 20,
                                },
                              }}
                              title={
                                f.user.name.length > 20 ? (
                                  <Tooltip
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-expect-error
                                    open={cardState[f.user.id]}
                                    onOpen={() => handleOpen(f.user.id)}
                                    onClose={() => handleClose(f.user.id)}
                                    arrow
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          bgcolor: 'transparent',
                                          '& .MuiTooltip-arrow': {
                                            color: 'transparent',
                                          },
                                          marginLeft: 4,
                                          color: '#000',
                                        },
                                      },
                                    }}
                                    title={
                                      <span
                                        style={{
                                          fontFamily: 'Poppins',
                                          fontSize: 18,
                                        }}
                                      >
                                        {f.user.name + ' - ' + f.circleName}
                                      </span>
                                    }
                                  >
                                    <span style={{ cursor: 'pointer', fontFamily: 'Poppins' }}>
                                      {f.user.name.substring(0, 20) + ' - ' + f.circleName}...
                                    </span>
                                  </Tooltip>
                                ) : (
                                  <span style={{ cursor: 'pointer' }}>
                                    {f.user.name + ' - ' + f.circleName}
                                  </span>
                                )
                              }
                              onClick={() => {
                                if (isUserSelected(f.user.id, f.user.id)) {
                                  handlerAddUser(
                                    f.user,
                                    f.circleName,
                                    false,
                                    f.userCircleID,
                                    f.isPediatric
                                  )
                                } else {
                                  handlerAddUser(
                                    f.user,
                                    f.circleName,
                                    true,
                                    f.userCircleID,
                                    f.isPediatric
                                  )
                                }
                              }}
                            />
                          </Box>
                          <Typography
                            className={
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-expect-error
                              !cardState[f.user.id] ? styles.textDNI : styles.noPointerEvents
                            }
                          >
                            {f.user.cip}
                          </Typography>
                        </Box>
                        <img
                          src={userIcon}
                          alt="user icon"
                          style={{ width: 50, height: 50, marginRight: 4, marginBottom: 4 }}
                        />
                      </Box>
                      <Divider
                        variant="middle"
                        style={{
                          border: isCardSelected ? '1px solid #f37e31' : '1px solid #68b3e0',
                        }}
                        className={styles.divider}
                      />
                      <CardContentNoPadding>
                        <List className={styles.list}>
                          {f.relaters.map((ru) => (
                            <ListItem
                              key={ru.relater.id + uuidv4()}
                              className={styles.relatersList}
                            >
                              {handlerRoleIcon(
                                ru.relater,
                                isRelatedUserSelected(ru.relater.id, f.circleName, f.userCircleID),
                                ru.relaterRole
                              )}
                              <ListItemText
                                style={{
                                  color: isRelatedUserSelected(
                                    ru.relater.id,
                                    f.circleName,
                                    f.userCircleID
                                  )
                                    ? '#f37e31'
                                    : '',
                                }}
                                onClick={() => {
                                  relatedUserRef.current = ru.relater.id
                                  if (
                                    isRelatedUserSelected(
                                      ru.relater.id,
                                      f.circleName,
                                      f.userCircleID
                                    )
                                  ) {
                                    handlerAddRelatedUser(
                                      ru.relater,
                                      f.user,
                                      f.circleName,
                                      false,
                                      f.userCircleID,
                                      f.isPediatric
                                    )
                                  } else {
                                    handlerAddRelatedUser(
                                      ru.relater,
                                      f.user,
                                      f.circleName,
                                      true,
                                      f.userCircleID,
                                      f.isPediatric
                                    )
                                  }
                                }}
                                className={styles.relaters}
                                primary={ru.relater.name}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </CardContentNoPadding>
                    </Card>
                  </Grid>
                )
              })
            )
          ) : searcher[0].value === '' && usersWithRelaters ? (
            usersWithRelaters.slice(0, visibleFamiliars).map((f, i) => {
              const isCardSelected =
                f?.relaters.some((ru) =>
                  isRelatedUserSelected(ru.relater.id, f.circleName, f.userCircleID)
                ) || isUserSelected(f.user.id, f.circleName)

              return (
                <Grid
                  key={f.user.id + i}
                  display={'flex'}
                  flexWrap={'wrap'}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Card
                    style={{
                      backgroundColor: isCardSelected
                        ? 'rgba(244,184,144,0.1)'
                        : 'rgba(96, 172, 223, 0.07)',
                      border: isCardSelected ? '1px solid #f37e31' : '1px solid #68b3e0',
                    }}
                    className={styles.cards}
                  >
                    <Box className={styles.headerContainer}>
                      <Box className={styles.headerContent}>
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          style={{ width: '100%' }}
                        >
                          <CardHeader
                            style={{
                              color: isUserSelected(f.user.id, f.circleName) ? '#f37e31' : '',
                              paddingBottom: 2,
                            }}
                            className={styles.textName}
                            sx={{
                              '& .MuiCardHeader-title': {
                                fontFamily: 'Poppins',
                                fontSize: 20,
                              },
                            }}
                            title={
                              f.user.name.length > 20 ? (
                                <Tooltip
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-expect-error
                                  open={cardState[f.user.id]}
                                  onOpen={() => handleOpen(f.user.id)}
                                  onClose={() => handleClose(f.user.id)}
                                  arrow
                                  placement={'top'}
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        bgcolor: '#a1b8c1',
                                        '& .MuiTooltip-arrow': {
                                          color: '#a1b8c1',
                                        },
                                        marginLeft: 12,
                                        color: '#000',
                                      },
                                    },
                                  }}
                                  title={
                                    <span style={{ fontFamily: 'Poppins', fontSize: 16 }}>
                                      {capitalizeWords(f.user.name) + ' - ' + f.circleName}
                                    </span>
                                  }
                                >
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      fontFamily: 'Poppins',
                                      fontSize: window.innerWidth <= 1533 ? 15 : 18,
                                    }}
                                  >
                                    {capitalizeWords(
                                      f.user.name.substring(
                                        0,
                                        window.innerWidth <= 1200 ? 10 : 20
                                      ) +
                                        ' - ' +
                                        f.circleName
                                    )}
                                    ...
                                  </span>
                                </Tooltip>
                              ) : (
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: window.innerWidth <= 1533 ? 15 : 18,
                                  }}
                                >
                                  {capitalizeWords(f.user.name) + ' - ' + f.circleName}
                                </span>
                              )
                            }
                            onClick={() => {
                              if (isUserSelected(f.user.id, f.circleName)) {
                                handlerAddUser(
                                  f.user,
                                  f.circleName,
                                  false,
                                  f.userCircleID,
                                  f.isPediatric
                                )
                              } else {
                                handlerAddUser(
                                  f.user,
                                  f.circleName,
                                  true,
                                  f.userCircleID,
                                  f.isPediatric
                                )
                              }
                            }}
                          />
                        </Box>
                        <Typography className={styles.textDNI}>{f.user.cip}</Typography>
                      </Box>
                      <img
                        src={userIcon}
                        alt="user icon"
                        style={{ width: 50, height: 50, marginRight: 4, marginBottom: 4 }}
                      />
                    </Box>
                    <Divider
                      variant="middle"
                      style={{
                        border: isCardSelected ? '1px solid #f37e31' : '1px solid #68b3e0',
                      }}
                      className={styles.divider}
                    />
                    <CardContentNoPadding
                      style={{
                        maxHeight: 'calc(100% - 40px)',
                        overflow: 'auto',
                        marginBottom: 40,
                      }}
                    >
                      <List className={styles.list}>
                        {f.relaters.map((ru) => (
                          <ListItem key={ru.relater.id + uuidv4()} className={styles.relatersList}>
                            {handlerRoleIcon(
                              ru.relater,
                              isRelatedUserSelected(ru.relater.id, f.circleName, f.userCircleID),
                              ru.relaterRole
                            )}
                            <ListItemText
                              style={{
                                color: isRelatedUserSelected(
                                  ru.relater.id,
                                  f.circleName,
                                  f.userCircleID
                                )
                                  ? '#f37e31'
                                  : '',
                                flex: 1,
                                minWidth: '100px',
                                padding: '0 8px',
                              }}
                              onClick={() => {
                                relatedUserRef.current = ru.relater.id
                                if (
                                  isRelatedUserSelected(ru.relater.id, f.circleName, f.userCircleID)
                                ) {
                                  handlerAddRelatedUser(
                                    ru.relater,
                                    f.user,
                                    f.circleName,
                                    false,
                                    f.userCircleID,
                                    f.isPediatric
                                  )
                                } else {
                                  handlerAddRelatedUser(
                                    ru.relater,
                                    f.user,
                                    f.circleName,
                                    true,
                                    f.userCircleID,
                                    f.isPediatric
                                  )
                                }
                              }}
                              className={styles.relaters}
                              primary={
                                <span
                                  style={{
                                    fontSize: window.innerWidth <= 1533 ? '0.6rem' : '0.8rem',
                                  }}
                                >
                                  {capitalizeWords(ru.relater.name)}
                                </span>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContentNoPadding>
                  </Card>
                </Grid>
              )
            })
          ) : (
            <Box style={{ height: 300, marginTop: 40 }}>
              <Typography style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
                {t('notUsersResult')}
              </Typography>
            </Box>
          )}
        </Grid>
      ) : (
        <Box style={{ height: '380px' }}></Box>
      )}
    </div>
  )
}
