import style from './NavigationEsfera.module.css'

export const getCircleLinkStyle = (
  menuSelected: string,
  styleActive: string,
  isCircleLink: boolean,
  circleHover: boolean,
  ...ids: string[]
): string => {
  if (isCircleLink) {
    return `${style.link} ${ids.includes(menuSelected) || circleHover ? styleActive : ''}`
  }
  return `${style.link} ${ids.includes(menuSelected) && !circleHover ? styleActive : ''}`
}

export const getListItemStyleHoverCircle = (
  isDisabled: boolean,
  isCircleLink: boolean,
  circleHover: boolean,
  menuSelected: string,
  mobileOpen: boolean,
  styleActive: string,
  styleNormal: string,
  ...ids: string[]
): string => {
  if (isCircleLink) {
    return `${
      isDisabled
        ? style.menuItemDisabled
        : ids.includes(menuSelected) || mobileOpen || circleHover
          ? styleActive
          : styleNormal
    }`
  }
  return `${
    isDisabled
      ? style.menuItemDisabled
      : ids.includes(menuSelected) && !circleHover
        ? styleActive
        : styleNormal
  }`
}

export const getLinkStyle = (
  menuSelected: string,
  styleActive: string,
  ...ids: string[]
): string => {
  return `${style.link} ${ids.includes(menuSelected) ? styleActive : ''}`
}

export const getListItemStyle = (
  isDisabled: boolean,
  menuSelected: string,
  styleActive: string,
  styleNormal: string,
  ...ids: string[]
): string => {
  return `${
    isDisabled ? style.menuItemDisabled : ids.includes(menuSelected) ? styleActive : styleNormal
  }`
}
