import { Query } from '../../../common/api/Query'
import { toModel, toModelFromArray, User, UserDTO } from '../../users/models/User'
import { CircleDTO } from '../../circle/models/CircleDTO'

export type UserCircleWithRelatersAndCircleProps = {
  id: string
  circle: CircleDTO
  user: UserDTO
  familiars: UserDTO[]
  profSms: UserDTO[]
  profExt: UserDTO[]
  careers: UserDTO[]
}

export class UserCircleWithRelatersAndCircle {
  private readonly _id: string
  private _circle: CircleDTO
  private _user: User
  private _familiars: User[]
  private _profSms: User[]
  private _profExt: User[]
  private _careers: User[]

  constructor(p: UserCircleWithRelatersAndCircleProps) {
    this._id = p.id
    this._circle = p.circle
    this._user = toModel(p.user)
    this._familiars = toModelFromArray(p.familiars)
    this._profSms = toModelFromArray(p.profSms)
    this._profExt = toModelFromArray(p.profExt)
    this._careers = toModelFromArray(p.careers)
  }

  get id(): string {
    return this._id
  }

  get circle(): CircleDTO {
    return this._circle
  }

  set circle(value: CircleDTO) {
    this._circle = value
  }

  get user(): User {
    return this._user
  }

  set user(value: User) {
    this._user = value
  }

  get familiars(): User[] {
    return this._familiars
  }

  set familiars(value: User[]) {
    this._familiars = value
  }

  get profSms(): User[] {
    return this._profSms
  }

  set profSms(value: User[]) {
    this._profSms = value
  }

  get profExt(): User[] {
    return this._profExt
  }

  set profExt(value: User[]) {
    this._profExt = value
  }

  get careers(): User[] {
    return this._careers
  }

  set careers(value: User[]) {
    this._careers = value
  }
}

export class UserCircleQuery extends Query<UserCircleWithRelatersAndCircle> {
  userID?: string
  roleID?: string[]
}
