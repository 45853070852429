import React, { useRef } from 'react'
import { useSlate } from 'slate-react'
import { isMarkActive, toggleMark } from './ToggleMark'
import { Mark, TEXT_ALIGN_TYPES } from './Types'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import CodeIcon from '@mui/icons-material/Code'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'

import styles from './TextEditor.module.css'
import { InsertImageButton } from './InsertImageButton'
import { Button, Icon } from './ComponentsTextEditor'
import { isBlockActive, toggleBlock } from './ToggleBlock'
import { AddLinkButton } from './Inlines'
import { Box } from '@mui/material'

function MarkButton({ mark, icon }: { mark: Mark; icon?: any }) {
  const editor = useSlate()
  const isActive = isMarkActive(editor, mark)

  return (
    <Button
      active={isActive}
      className={isActive ? styles.buttonSelected : styles.generalButton}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleMark(editor, mark)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const BlockButton = ({ format, icon }: any) => {
  const editor = useSlate()
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  return (
    <Button
      active={isActive}
      className={isActive ? styles.buttonSelected : styles.generalButton}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const FormatToolbar = () => {
  const ref = useRef<HTMLSpanElement | null>(null)

  return (
    <span
      style={{ display: 'flex', alignItems: 'center' }}
      onClick={(e) => e.preventDefault()}
      ref={ref}
    >
      <Box style={{ marginRight: 4 }}>
        <MarkButton mark="bold" icon={<FormatBoldIcon />} />
        <MarkButton mark="italic" icon={<FormatItalicIcon />} />
      </Box>
      <Box style={{ marginRight: 4 }}>
        <AddLinkButton />
        <InsertImageButton />
      </Box>
      <Box style={{ marginRight: 4 }}>
        <BlockButton format="bulleted-list" icon={<FormatListBulletedIcon />} />
        <BlockButton format="numbered-list" icon={<FormatListNumberedIcon />} />
        <BlockButton format="left" icon={<FormatAlignLeftIcon />} />
        <BlockButton format="center" icon={<FormatAlignCenterIcon />} />
        <BlockButton format="right" icon={<FormatAlignRightIcon />} />
        <BlockButton format="block-quote" icon={<FormatQuoteIcon />} />
        <MarkButton mark="code" icon={<CodeIcon />} />
      </Box>
    </span>
  )
}

export default FormatToolbar
