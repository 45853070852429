import { PatientData } from '../models/PatientData'
import { Container, IInit } from '../../../common/container/Container'
import { DataPatientContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { PatientDataDTO, toModel } from '../models/PatientDataDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Selene } from '../models/Selene'
import { SeleneDTO, toModel as seleneToModel } from '../models/SeleneDTO'

export interface IPatientDataApi extends IInit {
  getByCIPA(id: string | null): Observable<PatientData | undefined>
  getSeleneIndicators(cipa: string): Observable<Selene | undefined>
}

export class PatientDataApi implements IPatientDataApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as DataPatientContainerConfig).moduleFullUrl
  }

  getByCIPA(id: string | null): Observable<PatientData | undefined> {
    return this._httpClient.get<any>({ url: this._url + '/' + id }).pipe(
      map<PatientDataDTO, PatientData>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getSeleneIndicators(cipa: string): Observable<Selene | undefined> {
    return this._httpClient.get<any>({ url: this._url + '/selene/' + cipa }).pipe(
      map<SeleneDTO, Selene>((d) => seleneToModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
