import { Goal } from '../models/Goal'
import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { GoalApi } from '../api/GoalApi'
import { GoalDTO } from '../models/GoalDTO'

type Props = {
  apiKey: symbol
}

export interface IGoalService extends IInit {
  getItems(id: string): Observable<Goal[]>
  putGoals(g: GoalDTO[]): Observable<Goal[]>
}

export class GoalService implements IGoalService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: GoalApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<GoalApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getItems(id: string): Observable<Goal[]> {
    return this._api.getItems(id).pipe()
  }

  putGoals(g: GoalDTO[]): Observable<Goal[]> {
    return this._api.putGoals(g)
  }
}
