import { Query } from '../../../common/api/Query'
import { ConversationUser } from './ConversationUser'
import { Message } from './Message'

export type ConversationProps = {
  id?: string | undefined
  name: string
  description: string
  users: ConversationUser[]
  messages: Message[] | undefined
  unreadMessages: number
  isPublic: boolean
  isReadOnly: boolean
  circleID: string | undefined
  userCircleID: string | undefined
}

export class Conversation {
  private readonly _id: string | undefined

  private _name: string
  private _description: string
  private _users: ConversationUser[]
  private _messages: Message[]
  private _unreadMessages: number
  private _isPublic: boolean
  private _isReadOnly: boolean
  private _circleID: string | undefined
  private _userCircleID: string | undefined
  constructor(p: ConversationProps) {
    this._id = p.id
    this._name = p.name
    this._description = p.description
    this._users = p.users
    this._messages = p.messages || []
    this._unreadMessages = p.unreadMessages
    this._isPublic = p.isPublic
    this._isReadOnly = p.isReadOnly
    this._circleID = p.circleID
    this._userCircleID = p.userCircleID
  }

  get id(): string | undefined {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get description(): string {
    return this._description
  }

  set description(value: string) {
    this._description = value
  }

  get users(): ConversationUser[] {
    return [...this._users]
  }

  set users(value: ConversationUser[]) {
    this._users = value
  }

  addUser(value: ConversationUser) {
    if (this._users) {
      this._users.push(value)
    } else {
      this._users = [value]
    }
  }

  removeUser(id: string) {
    if (this._users) {
      this._users = this._users.filter((u) => u.userID !== id)
    }
  }

  get messages(): Message[] {
    if (this._messages) {
      return [...this._messages]
    }

    return this._messages
  }

  addMessage(value: Message) {
    if (this._messages) {
      this._messages.push(value)
    } else {
      this._messages = [value]
    }
  }

  removeMessage(id: string) {
    this._messages = this._messages.filter((m) => m.id !== id)
  }

  get unreadMessages(): number {
    return this._unreadMessages
  }

  set unreadMessages(value: number) {
    this._unreadMessages = value
  }

  get isPublic(): boolean {
    return this._isPublic
  }

  set isPublic(value: boolean) {
    this._isPublic = value
  }

  get isReadOnly(): boolean {
    return this._isReadOnly
  }

  set isReadOnly(value: boolean) {
    this._isReadOnly = value
  }

  get circleID(): string | undefined {
    return this._circleID
  }

  set circleID(value: string | undefined) {
    this._circleID = value
  }

  get userCircleID(): string | undefined {
    return this._userCircleID
  }

  set userCircleID(value: string | undefined) {
    this._userCircleID = value
  }
}

export class ConversationParams extends Query<Conversation> {}
