import { Setting, SettingQuery } from '../models/Setting'
import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { SettingDTO, toModel } from '../models/SettingDTO'
import { SettingContainerConfig } from '../container'

export interface ISettingApi extends IInit {
  getByID(id: string | null): Observable<Setting | undefined>

  getFilteredItems(q: Query<SettingQuery>): Observable<Setting[]>

  add(e: SettingDTO): Observable<Setting | undefined>

  update(e: SettingDTO): Observable<Setting | undefined>

  delete(id: string): Observable<boolean>
}

export class SettingApi implements ISettingApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as SettingContainerConfig).moduleFullUrl + '/settings'
  }

  getByID(id: string | null): Observable<Setting | undefined> {
    return this._httpClient.get<Setting>({ url: this._url + '/' + id }).pipe(
      map<SettingDTO, Setting>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  add(e: SettingDTO): Observable<Setting | undefined> {
    return this._httpClient.post<Setting>({ url: this._url, body: e }).pipe(
      map<SettingDTO, Setting>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getFilteredItems(q: Query<SettingQuery>): Observable<Setting[]> {
    return this._httpClient.get<Setting[]>({ url: prepareURL(this._url, q) }).pipe(
      map<SettingDTO[], Setting[]>((dto) => dto.map((d) => toModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  update(e: SettingDTO): Observable<Setting | undefined> {
    return this._httpClient.put<Setting>({ url: this._url, body: e }).pipe(
      map<SettingDTO, Setting>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
