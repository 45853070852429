import { PatientCalendarEvent } from './PatientCalendarEvent'

export interface PatientCalendarEventDTO {
  title: string
  month: number
}

export function emptyPatientCalendarEventDTO() {
  return {
    title: '',
    month: new Date().getFullYear(),
  }
}

export function fromModel(d: PatientCalendarEvent): PatientCalendarEventDTO {
  return {
    title: d.title,
    month: d.month,
  }
}

export function toModel(d: PatientCalendarEventDTO): PatientCalendarEvent {
  return new PatientCalendarEvent(d)
}
