import { v4 as uuidv4 } from 'uuid'

export type AutoMessageProps = {
  id?: string
  professional: string
  pathology: string
  message: string
}

export class AutoMessage {
  private readonly _id: string
  private readonly _professional: string
  private readonly _pathology: string
  private _message: string

  constructor(p: AutoMessageProps) {
    this._id = p.id ?? uuidv4()
    this._professional = p.professional
    this._pathology = p.pathology
    this._message = p.message
  }

  get id(): string {
    return this._id
  }

  get professional(): string {
    return this._professional
  }

  get pathology(): string {
    return this._pathology
  }

  get message(): string {
    return this._message
  }

  set message(value: string) {
    this._message = value
  }
}

export interface AutoMessageQuery {
  id: string
  professional: string
  pathology: string
  message: string
}
