export type QueryProps = {
  teleCall: boolean
  videoCall: boolean
  acceptQuery: boolean
  noAvailableMessage: string
}

export class QueryConfig {
  private _teleCall: boolean
  private _videoCall: boolean
  private _acceptQuery: boolean
  private _noAvailableMessage: string

  constructor(p: QueryProps) {
    this._teleCall = p.teleCall
    this._videoCall = p.videoCall
    this._acceptQuery = p.acceptQuery
    this._noAvailableMessage = p.noAvailableMessage
  }

  get teleCall(): boolean {
    return this._teleCall
  }

  set teleCall(value: boolean) {
    this._teleCall = value
  }

  get videoCall(): boolean {
    return this._videoCall
  }

  set videoCall(value: boolean) {
    this._videoCall = value
  }

  get acceptQuery(): boolean {
    return this._acceptQuery
  }

  set acceptQuery(value: boolean) {
    this._acceptQuery = value
  }

  get noAvailableMessage(): string {
    return this._noAvailableMessage
  }

  set noAvailableMessage(value: string) {
    this._noAvailableMessage = value
  }
}
