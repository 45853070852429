import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { PatientCalendarApi } from '../modules/patient-calendar-config/api/PatientCalendarApi'
import { PatientCalendarService } from '../modules/patient-calendar-config/services/PatientCalendarService'
import {
  PATIENT_CALENDAR_API_KEY,
  PATIENT_CALENDAR_MODULE,
  PATIENT_CALENDAR_SERVICE_KEY,
} from '../modules/patient-calendar-config'
import { PatientCalendarProps } from '../modules/patient-calendar-config/container'

let container: Container

function init(p: PatientCalendarProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(PATIENT_CALENDAR_API_KEY, new ContainerItem({ instance: new PatientCalendarApi() }))

  items.set(
    PATIENT_CALENDAR_SERVICE_KEY,
    new ContainerItem({
      instance: new PatientCalendarService({ apiKey: PATIENT_CALENDAR_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getPatientCalendarContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[PATIENT_CALENDAR_MODULE],
      },
    })
  }

  return container
}
