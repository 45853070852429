import { PatientData } from '../models/PatientData'
import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { IPatientDataApi } from '../api/PatientDataApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Selene } from '../models/Selene'

type Props = {
  apiKey: symbol
}

export interface IPatientDataService extends IInit {
  getByCIPA(id: string): Observable<PatientData | undefined>
  getSeleneIndicators(cipa: string): Observable<Selene | undefined>
}

export class PatientDataService implements IPatientDataService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IPatientDataApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IPatientDataApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByCIPA(id: string | null): Observable<PatientData | undefined> {
    return this._api.getByCIPA(id)
  }

  getSeleneIndicators(cipa: string): Observable<Selene | undefined> {
    return this._api.getSeleneIndicators(cipa)
  }
}
