import { AppTable, Field } from '../../../../components/table'
import { dateToTimeWithoutSecondsString } from '../../../../common/utils/date'
import { useTranslation } from 'react-i18next'
import {
  EpilepticalCrisisDetails,
  EpilepticalCrisisDetailsQuery,
} from '../../../../modules/epileptical/models/EpilepticalCrisisDetails'
import React, { useEffect, useState } from 'react'
import { emptyList, ItemList } from '../../../../common/models/ItemList'
import { Query, QueryParam } from '../../../../common/api/Query'
import { EpilepticalCrisisDetailsService } from '../../../../modules/epileptical/services/EpilepticalCrisisDetailsService'
import {
  EPILEPTICAL_CRISIS_DETAILS_SERVICE_KEY,
  USER_EPILEPTICAL_CONFIG_SERVICE_KEY,
} from '../../../../modules/epileptical'
import { getEpilepticalContainer } from '../../../../container/epileptical-module'
import editIcon from '../../../../assets/table_icons/ico-edit.svg'
import editIconResponsive from '../../../../assets/resource_icons/ico-editar.svg'
import deleteIcon from '../../../../assets/table_icons/ico-eliminar.svg'
import deleteIconResponsive from '../../../../assets/resource_icons/ico-eliminar.svg'
import { Box, Modal, Tooltip } from '@mui/material'
import { EpilepticalCrisisDetailsDTO } from '../../../../modules/epileptical/models/EpilepticalCrisisDetailsDTO'
import { CustomModal } from '../../../../components/modal/CustomModal'
import { UserEpilepticalConfigService } from '../../../../modules/epileptical/services/UserEpilepticalConfigService'
import { UserEpilepticalConfigDTO } from '../../../../modules/epileptical/models/UserEpilepticalConfigDTO'
import { StringTooltip } from '../../../calendar/StringTooltip'
import { DraggableDetailCrisisConfig } from '../../../calendar/DraggableDetailCrisisConfig'
import { Actions } from '../../../../components/table_type/types'
import { useGetActiveUser } from '../../../../hooks/get-active-user/useGetActiveUser'

const epilepticCrisisDetailsContainer = getEpilepticalContainer()
const epilepticCrisisDetailsService =
  epilepticCrisisDetailsContainer.get<EpilepticalCrisisDetailsService>(
    EPILEPTICAL_CRISIS_DETAILS_SERVICE_KEY
  )

const userEpilepticCrisisConfigService =
  epilepticCrisisDetailsContainer.get<UserEpilepticalConfigService>(
    USER_EPILEPTICAL_CONFIG_SERVICE_KEY
  )

type PropsTable = {
  id?: string
  loggedUserID?: string
  detailId: EpilepticalCrisisDetailsDTO
  handlerEditCrisis: () => void
  handlerCurrentCrisis: (currentCrisis: EpilepticalCrisisDetails) => void
}
export function Table(props: PropsTable) {
  const { t } = useTranslation()
  const { innerWidth } = window
  const [currentCrisisDetail, setCurrentCrisisDetail] = useState<EpilepticalCrisisDetails>()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [listDetailsCrisis, setListDetailsCrisis] =
    useState<ItemList<EpilepticalCrisisDetails>>(emptyList<EpilepticalCrisisDetails>())
  const [detailConfig, setDetailconfig] = useState<UserEpilepticalConfigDTO[]>([])
  const [openDraggableconfig, setOpenDraggableconfig] = useState<boolean>(false)
  const { activeUser } = useGetActiveUser()

  useEffect(() => {
    epilepticCrisisDetailsService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<EpilepticalCrisisDetailsQuery>(
              'epilepticalCrisisID',
              props.detailId.epilepticalCrisisID
            ),
          ],
        })
      )
      .subscribe((resDetailsCrisis) => {
        if (!resDetailsCrisis.count) return
        setListDetailsCrisis(resDetailsCrisis)
      })
  }, [isLoading, props.detailId.epilepticalCrisisID])

  useEffect(() => {
    userEpilepticCrisisConfigService
      .getFilteredList(
        new Query({
          query: [new QueryParam<UserEpilepticalConfigDTO>('userCircleID', activeUser.id)],
        })
      )
      .subscribe((res) => {
        setDetailconfig(res.items)
      })
  }, [])

  const seeDetail = () => {
    setOpenDraggableconfig(!openDraggableconfig)
  }

  const editDetail = (crisisDetail: EpilepticalCrisisDetails) => {
    setCurrentCrisisDetail(crisisDetail)
    props.handlerCurrentCrisis(crisisDetail)
    props.handlerEditCrisis()
  }

  const removeDetail = (crisisDetail: EpilepticalCrisisDetails) => {
    setCurrentCrisisDetail(crisisDetail)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteSymptom = () => {
    if (currentCrisisDetail?.id)
      if (currentCrisisDetail.id) {
        epilepticCrisisDetailsService
          .delete(currentCrisisDetail.id)
          .subscribe((_) => setIsLoading(!isLoading))
      }
    setOpenDeleteModal(false)
    setIsLoading(!isLoading)
  }
  console.log(detailConfig)
  const fields: Field<EpilepticalCrisisDetails>[] = [
    {
      name: 'color',
      label: t('color'),
      renderFunc: (f, i) => {
        const filter = detailConfig.filter((item) => item.color === i.color)
        return (
          <>
            <Tooltip
              arrow={true}
              sx={{
                maxWidth: 400,
                fontSize: '2em',
                color: 'black',
                border: '1px solid #3f51b5',
                backgroundColor: '#f6f5f5',
              }}
              title={
                <div style={{ whiteSpace: 'pre-line', fontSize: 14 }}>
                  <StringTooltip detailConfig={filter} />
                </div>
              }
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'white',
                    boxShadow: '1px 1px 5px 3px grey',
                    '& .MuiTooltip-arrow': {
                      color: 'white',
                    },
                  },
                },
              }}
            >
              <Box
                style={{
                  backgroundColor: i.color,
                  width: '30px',
                  height: '30px',
                  borderRadius: '30px',
                }}
                onClick={() => setOpenDraggableconfig(!openDraggableconfig)}
              />
            </Tooltip>
            {window.innerWidth < 598 && openDraggableconfig && (
              <DraggableDetailCrisisConfig
                open={openDraggableconfig}
                handleClose={seeDetail}
                detailConfig={filter}
                title={'Detalle de crisis'}
              />
            )}
          </>
        )
      },
    },
    {
      name: 'startDate',
      label: t('startHour'),
      renderFunc: (f, i) => {
        if (!i.startDate) return ''
        return dateToTimeWithoutSecondsString(new Date(i.startDate))
      },
    },
    {
      name: 'endDate',
      label: t('finishHour'),
      renderFunc: (f, i) => {
        if (!i.endDate) return ''
        return dateToTimeWithoutSecondsString(new Date(i.endDate))
      },
    },
    {
      name: 'crisisType',
      label: t('type'),
      renderFunc: (f, i) => {
        switch (i.crisisType) {
          case 1:
            return t('low')
          case 2:
            return t('medium')
          case 3:
            return t('strong')
          default:
            return ''
        }
      },
    },
    {
      name: 'observations',
      label: t('observaciones'),
      renderFunc: (f, i) => i.observations,
    },
  ]

  const actions: Actions<EpilepticalCrisisDetails> = {
    actionsColumn: t('Actions'),
    items: [
      /*   {
        handler: seeDetail,
        icon: innerWidth < 598 ? seeIconResponsive : '',
        label: 'see',
      }, */
      {
        handler: editDetail,
        icon: innerWidth > 598 ? editIcon : editIconResponsive,
        label: 'edit',
      },
      {
        handler: removeDetail,
        icon: innerWidth > 598 ? deleteIcon : deleteIconResponsive,
        label: 'delete',
      },
    ],
  }

  return (
    <Box>
      <AppTable
        fields={fields}
        items={listDetailsCrisis.items}
        rowKeyField="id"
        actions={actions}
        // fieldsInDraggable={true}
      />
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <CustomModal
          handleClose={handleCloseDeleteModal}
          handleSave={handleDeleteSymptom}
          title={t('deleteCrisisDetail')}
          warningText={t('irreversibleCrisisDetailAction')}
        />
      </Modal>
    </Box>
  )
}
