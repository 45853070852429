import { Container, ContainerItem } from '../common/container/Container'
import { VisitAuditService } from '../modules/audit/services/VisitAuditService'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  VISIT_AUDIT_API_KEY,
  AUDIT_MODULE,
  VISIT_AUDIT_SERVICE_KEY,
  VisitAuditProps,
} from '../modules/audit/container'
import { VisitAuditApi } from '../modules/audit/api/VisitAuditApi'

let container: Container

function init(p: VisitAuditProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(VISIT_AUDIT_API_KEY, new ContainerItem({ instance: new VisitAuditApi() }))

  items.set(
    VISIT_AUDIT_SERVICE_KEY,
    new ContainerItem({ instance: new VisitAuditService({ apiKey: VISIT_AUDIT_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getAuditContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[AUDIT_MODULE],
      },
    })
  }

  return container
}
