import { Header } from '../../../../components/header/Header'
import { Box, Modal, Card, Grid, Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from '../../add-new-circle/AddNewCircle.module.css'
import icoDelete from '../../../../assets/resource_icons/ico-eliminar.svg'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import styles from '../../add-new-circle/view 4 Forms/Modal.module.css'
import {
  getAllSymptomIcons,
  SymptomPicture,
} from '../../add-new-circle/view 5 Symptoms/SymptomPicture'
import { CircleDTO } from '../../../../modules/circle/models/CircleDTO'
import { getCircleContainer } from '../../../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../../../modules/circle'
import { SymptomConfig } from '../../../../modules/circle/models/circleConfig/SymptomConfig'
import { v4 as uuidv4 } from 'uuid'
import { getPatientDataContainer } from '../../../../container/patient-data-module'
import { SymptomService } from '../../../../modules/patient-data/services/SymptomService'
import { SYMPTOM_SERVICE_KEY } from '../../../../modules/patient-data'

interface Props {
  open: boolean
  onClose: () => void
  circleDraft: CircleDTO
}

const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
const symptomService = getPatientDataContainer().get<SymptomService>(SYMPTOM_SERVICE_KEY)

const ModalAddNewSymptoms: React.FC<Props> = ({ circleDraft, open, onClose }) => {
  const [symptomName, setSymptomName] = React.useState<string>('')
  const [selectedIcon, setSelectedIcon] = useState<SymptomPicture>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { t } = useTranslation()

  useEffect(() => {
    // reset hooks on new open
    setSymptomName('')
    setSelectedIcon(undefined)
  }, [open])

  const validateSymptom = (): boolean => {
    if (!symptomName.trim()) {
      setErrorMessage('symptomMustHaveAName')
      return false
    }
    if (selectedIcon === undefined) {
      setErrorMessage('voidSymptomIcon')
      return false
    }
    // check that name is not repeated
    const symptoms = circleDraft.symptoms || []
    if (symptoms.find((symptom) => symptom.name === symptomName)) {
      setErrorMessage('symptomNameAlreadyExists')
      return false
    }
    return true
  }

  async function handleSave() {
    if (!validateSymptom()) return

    const circleDraftAux: CircleDTO = { ...circleDraft }
    const circleDraftSymptoms: SymptomConfig[] = circleDraftAux.symptoms || []

    // creamos primero el sintoma
    await symptomService
      .add({ id: uuidv4(), name: symptomName, commonSymptom: false })
      .toPromise()
      .then((res) => {
        if (res) {
          // actualizamos el circle draft
          circleDraftSymptoms.push(
            new SymptomConfig(symptomName || '', selectedIcon?.alt ?? '', res.id ?? '')
          )
          circleDraftAux.symptoms = circleDraftSymptoms
          circleService.update(circleDraftAux).toPromise()
        }
      })
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose} className={style.modalOverFlow}>
      <Box className={style.modalContainerTemplate}>
        <Box className={style.contentHeaderExitIcon}>
          <img
            style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
            onClick={onClose}
            src={icoDelete}
          />
        </Box>
        <Box className={style.contentHeader}>
          <Header label={t('addSymptoms')} />
        </Box>
        <Box
          display="flex"
          style={{ backgroundColor: '#fff', marginLeft: '24px', marginBottom: '16px' }}
        >
          <b className={style.titleLabel} style={{ marginRight: '32px' }}>
            {t('name')}*
          </b>
          <input
            className={styles.inputName}
            style={{ border: '1px solid #68b3e0' }}
            type="text"
            name="title"
            required
            onChange={(e) => setSymptomName(e.target.value)}
          />
        </Box>
        <Box>
          <Box className={style.contentHeader}>
            <Header label={t('addIcon')} />
          </Box>

          <Box className={style.centerBlock2}>
            <Grid container spacing={2}>
              {getAllSymptomIcons().map((icon) => (
                <Grid item xs={6} md={4} key={icon.alt}>
                  <Card
                    className={selectedIcon === icon ? style.selectedIconsCard : style.card}
                    sx={{ borderColor: '#68b3e0' }}
                    variant="outlined"
                    onClick={() => {
                      setSelectedIcon(icon)
                    }}
                  >
                    <div className={style.iconContainer}>
                      <img src={icon.picture} alt={icon.alt} />
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          {errorMessage !== '' && (
            <Box mb={3}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage)}
              </Alert>
            </Box>
          )}

          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('save')}
                marginStartIcon={{ marginRight: '10px' }}
                handler={handleSave}
                vertical={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalAddNewSymptoms
