import i18n from '../../../i18n'

export enum Mouth {
  never = -1,
  dry,
  drooling,
  foam,
  tongueBiting,
  undefined,
}

export const mouthTypes = (): Record<Mouth, string> => ({
  [Mouth.never]: i18n.t('never'),
  [Mouth.dry]: i18n.t('dry'),
  [Mouth.drooling]: i18n.t('drooling'),
  [Mouth.foam]: i18n.t('foam'),
  [Mouth.tongueBiting]: i18n.t('tongueBiting'),
  [Mouth.undefined]: i18n.t('undefined'),
})
