import { CityHallContact } from './CityHallContact'
import { Query } from '../../../common/api/Query'

export type CityHallProps = {
  id: string
  name: string
  city: string
  contact: CityHallContact[]
}

export class CityHall {
  private readonly _id: string
  private _name: string
  private _city: string
  private _contact: CityHallContact[]

  constructor(props: CityHallProps) {
    this._id = props.id
    this._name = props.name
    this._city = props.city
    this._contact = props.contact
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get city(): string {
    return this._city
  }

  set city(value: string) {
    this._city = value
  }

  get contact(): CityHallContact[] {
    return this._contact
  }

  set contact(value: CityHallContact[]) {
    this._contact = value
  }
}

export class CityHallQuery extends Query<CityHall> {
  ids?: string[]
  id?: string
  name?: string
  city?: string
}
