import { UserCircleWithRelaters } from './UserCircleWithRelaters'
import { v4 as uuidv4 } from 'uuid'
import { emptyUserDTO, UserDTO } from '../../users/models/User'

export interface UserCircleWithRelatersDTO {
  id: string
  circleID: string
  user: UserDTO
  familiars: UserDTO[]
  profSms: UserDTO[]
  profExt: UserDTO[]
  careers: UserDTO[]
}

export function emptyUserCircleWithRelatersDTO(): UserCircleWithRelatersDTO {
  return {
    id: uuidv4(),
    circleID: '',
    user: emptyUserDTO(),
    familiars: [],
    profSms: [],
    profExt: [],
    careers: [],
  }
}

export function fromModel(e: UserCircleWithRelaters): UserCircleWithRelatersDTO {
  return {
    id: e.id,
    circleID: e.circleID,
    user: e.user,
    familiars: e.familiars,
    profSms: e.profSms,
    profExt: e.profExt,
    careers: e.careers,
  }
}

export function toModel(d: UserCircleWithRelatersDTO): UserCircleWithRelaters {
  return new UserCircleWithRelaters(d)
}
