import { Container, ContainerItem } from '../common/container/Container'
import {
  VACUSAN_API_KEY,
  VACUSAN_MODULE,
  VACUSAN_SERVICE_KEY,
  VacusanProps,
} from '../modules/vaccination/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { VacusanApi } from '../modules/vaccination/api/VacusanApi'
import { VacusanService } from '../modules/vaccination/services/VacusanService'

let container: Container

function init(p: VacusanProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(VACUSAN_API_KEY, new ContainerItem({ instance: new VacusanApi() }))

  items.set(
    VACUSAN_SERVICE_KEY,
    new ContainerItem({ instance: new VacusanService({ apiKey: VACUSAN_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getVaccinationContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[VACUSAN_MODULE],
      },
    })
  }

  return container
}
