export type TrainerCheckEmailProps = {
  userName: string
  userTypeExternProfID: string
  pending: boolean
}

export class TrainerCheckEmail {
  private _userName: string
  private _userTypeExternProfID: string
  private _pending: boolean

  constructor(props: TrainerCheckEmailProps) {
    this._userName = props.userName
    this._userTypeExternProfID = props.userTypeExternProfID
    this._pending = props.pending
  }

  get userName(): string {
    return this._userName
  }

  set userName(value: string) {
    this._userName = value
  }

  get userTypeExternProfID(): string {
    return this._userTypeExternProfID
  }

  set userTypeExternProfID(value: string) {
    this._userTypeExternProfID = value
  }

  get pending(): boolean {
    return this._pending
  }

  set pending(value: boolean) {
    this._pending = value
  }
}

export interface TrainerCheckEmailDTO {
  userName: string
  userTypeExternProfID: string
  pending: boolean
}

export function toModel(d: TrainerCheckEmailDTO): TrainerCheckEmail {
  return new TrainerCheckEmail(d)
}
