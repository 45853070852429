import { RouteProps } from '../../routes/AppRouter'
import { useTranslation } from 'react-i18next'
import { LandmarkForm as LF } from '../../features/patient-data'
import React from 'react'
import { ROUTE_CREATE } from '../../routes/routes-constants'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function LandmarkForm(props: RouteProps) {
  const { id } = useParams()
  const title = props.title || ''
  const { t } = useTranslation()

  return (
    <>
      <CurrentNavHeaderEsfera title={t(title)} />
      <LF id={id === ROUTE_CREATE ? undefined : id} />
    </>
  )
}
