import { File, FileQuery } from '../models/File'
import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { IFileApi } from '../api/FileApi'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { FileDTO } from '../models/FileDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { SharedWith, SharedWithQuery } from '../../sharedWith/models/SharedWith'
import { ItemList } from '../../../common/models/ItemList'

type Props = {
  apiKey: symbol
}

export interface IFileService extends IInit {
  getByID(id: string): Observable<File | undefined>

  getFilteredItems(q: Query<FileQuery>): Observable<ItemList<File>>

  add(e: FileDTO): Observable<File | undefined>

  update(e: FileDTO): Observable<File | undefined>

  delete(e: File): Observable<boolean>

  download(e: File): Observable<File | undefined>

  getByParentID(parentID: string | null, circleID: string | null): Observable<File[]>

  getSharedWithMe(parentID: string | null): Observable<File[]>

  getSharedWithMe2(q: Query<SharedWithQuery>): Observable<File[]>

  sharedFileIn(q: Query<SharedWithQuery>): Observable<ItemList<File>>

  getFilteredItemsSharedWithMe(q: Query<SharedWithQuery>): Observable<SharedWith[]>

  getUsersSharedByFileID(id: string, circleID: string): Observable<string[]>

  getByArticleID(id: string): Observable<File[]>

  createdByDoctor(q: Query<FileQuery>): Observable<ItemList<File>>

  getCreatedAndSharedWithMe(id: string, circleId: string): Observable<File[]>

  cloneFile(e: FileDTO): Observable<File | undefined>
}

export class FileService implements IFileService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IFileApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IFileApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: FileDTO): Observable<File | undefined> {
    return this._api.add(e)
  }

  delete(e: File): Observable<boolean> {
    return this._api.delete(e)
  }

  download(e: File): Observable<File | undefined> {
    return this._api.download(e)
  }

  downloadByID(id: string): Observable<File | undefined> {
    return this._api.downloadByID(id)
  }

  getByID(id: string | null): Observable<File | undefined> {
    return this._api.getByID(id)
  }

  getFilteredItems(q: Query<FileQuery>): Observable<ItemList<File>> {
    return this._api.getFilteredItems(q).pipe()
  }

  update(e: FileDTO): Observable<File | undefined> {
    return this._api.update(e)
  }

  getByParentID(parentID: string | null, circleID: string | null): Observable<File[]> {
    return this._api.getByParentID(parentID, circleID).pipe()
  }

  getSharedWithMe(parentID: string | null): Observable<File[]> {
    return this._api.getSharedWithMe(parentID).pipe()
  }

  getSharedWithMe2(q: Query<SharedWithQuery>): Observable<File[]> {
    return this._api.getSharedWithMe2(q).pipe()
  }

  sharedFileIn(q: Query<SharedWithQuery>): Observable<ItemList<File>> {
    return this._api.sharedFileIn(q).pipe()
  }

  getFilteredItemsSharedWithMe(q: Query<SharedWithQuery>): Observable<SharedWith[]> {
    return this._api.getFilteredItemsSharedWithMe(q)
  }

  getByArticleID(id: string): Observable<File[]> {
    return this._api.getByArticleID(id).pipe()
  }

  createdByDoctor(q: Query<FileQuery>): Observable<ItemList<File>> {
    return this._api.createdByDoctor(q).pipe()
  }

  getUsersSharedByFileID(id: string, circleID: string): Observable<string[]> {
    return this._api.getUsersSharedByFileID(id, circleID)
  }

  getCreatedAndSharedWithMe(id: string, circleId: string): Observable<File[]> {
    return this._api.getCreatedAndSharedWithMe(id, circleId)
  }

  cloneFile(e: FileDTO): Observable<File | undefined> {
    return this._api.cloneFile(e)
  }
}
