import { Container } from '../../common/container/Container'

export type DataPatientContainerConfig = {
  moduleFullUrl: string
}

export type DataPatientProps = {
  parentContainer: Container
  config: DataPatientContainerConfig
}

export const PATIENT_DATA_MODULE = Symbol('PATIENT_DATA_MODULE')
export const PATIENT_DATA_API_KEY = Symbol('PATIENT_DATA_API_KEY')
export const PATIENT_DATA_SERVICE_KEY = Symbol('PATIENT_DATA_SERVICE_KEY')

export const PATIENT_SYMPTOM_API_KEY = Symbol('PATIENT_SYMPTOM_API_KEY')
export const PATIENT_SYMPTOM_SERVICE_KEY = Symbol('PATIENT_SYMPTOM_SERVICE_KEY')

export const TREATMENT_API_KEY = Symbol('TREATMENT_API_KEY')
export const TREATMENT_SERVICE_KEY = Symbol('TREATMENT_SERVICE_KEY')
export const SYMPTOM_API_KEY = Symbol('SYMPTOM_API_KEY')
export const SYMPTOM_SERVICE_KEY = Symbol('SYMPTOM_SERVICE_KEY')

export const GROWTH_MONITORING_API_KEY = Symbol('GROWTH_MONITORING_API_KEY')
export const GROWTH_MONITORING_SERVICE_KEY = Symbol('GROWTH_MONITORING_SERVICE_KEY')

export const PERCENTILE_API_KEY = Symbol('PERCENTILE_API_KEY')
export const PERCENTILE_SERVICE_KEY = Symbol('PERCENTILE_SERVICE_KEY')

export const GOAL_API_KEY = Symbol('GOAL_API_KEY')
export const GOAL_SERVICE_KEY = Symbol('GOAL_SERVICE_KEY')

export const WEARABLE_API_KEY = Symbol('WEARABLE_API_KEY')
export const WEARABLE_SERVICE_KEY = Symbol('WEARABLE_SERVICE_KEY')

export const LANDMARK_API_KEY = Symbol('LANDMARK_API_KEY')
export const LANDMARK_SERVICE_KEY = Symbol('LANDMARK_SERVICE_KEY')
