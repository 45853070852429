import { Query } from '../../../common/api/Query'

export type ProfessionalTypeUserProps = {
  id: string
  professionalTypeID: string
  userID: string
}

export class ProfessionalTypeUser {
  private readonly _id: string
  private _professionalTypeID: string
  private readonly _userID: string

  constructor(p: ProfessionalTypeUserProps) {
    this._id = p.id
    this._professionalTypeID = p.professionalTypeID
    this._userID = p.userID
  }

  get id(): string {
    return this._id
  }

  get userID(): string {
    return this._userID
  }

  get professionalTypeID(): string {
    return this._professionalTypeID
  }
  set professionalTypeID(value: string) {
    this._professionalTypeID = value
  }
}

export class ProfessionalTypeUserQuery extends Query<ProfessionalTypeUser> {}
