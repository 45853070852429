import { SharedWith } from './SharedWith'

export interface SharedWithDTO {
  userId: string
  fileId: string
  userCircleId: string
  creatorId: string
}

export function emptySharedWith() {
  return {
    userId: '',
    fileId: '',
    userCircleId: '',
    creatorId: '',
  }
}

export function fromModel(f: SharedWith): SharedWithDTO {
  return {
    userId: f.userId,
    fileId: f.fileId,
    userCircleId: f.userCircleId,
    creatorId: f.creatorId,
  }
}

export function toModel(d: SharedWithDTO): SharedWith {
  return new SharedWith(d)
}
