import { Form, FormQuery } from '../models/Form'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { IFormApi } from '../api/FormApi'
import { FormDTO } from '../models/FormDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { FormPathology, FormPathologyQuery } from '../models/FormPathology'

type Props = {
  apiKey: symbol
}

export interface IFormService extends IInit {
  getByID(id: string): Observable<Form | undefined>

  getFilteredList(q: Query<FormQuery>): Observable<ItemList<Form>>

  getFilteredListWithCreatorNames(q: Query<FormQuery>): Observable<ItemList<Form>>

  add(e: FormDTO): Observable<Form | undefined>

  update(e: FormDTO): Observable<Form | undefined>
  getListByUserPathology(q: Query<FormQuery>): Observable<ItemList<Form>>
  getListByPathology(typeID: string): Observable<ItemList<Form>>
  getListByCreatorID(q: Query<FormQuery>): Observable<ItemList<Form>>

  //updateFormField(e: FormField): Observable<FormField | undefined>
  delete(id: string): Observable<boolean>

  addFormPathology(e: FormPathology): Observable<FormPathology | undefined>

  updateFormPathology(e: FormPathology): Observable<FormPathology | undefined>

  getFormPathologyByFormID(formID: string): Observable<FormPathology | undefined>

  getFormPathologyByPathologyID(pathologyID: string): Observable<FormPathology | undefined>

  getListFormPathology(q: Query<FormPathologyQuery>): Observable<FormPathology[]>
}

export class FormService implements IFormService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IFormApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IFormApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: FormDTO): Observable<Form | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Form | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<FormQuery>): Observable<ItemList<Form>> {
    return this._api.getFilteredList(q).pipe()
  }

  getFilteredListWithCreatorNames(q: Query<FormQuery>): Observable<ItemList<Form>> {
    return this._api.getFilteredListWithCreatorNames(q).pipe()
  }

  update(e: FormDTO): Observable<Form | undefined> {
    return this._api.update(e)
  }

  addFormPathology(e: FormPathology): Observable<FormPathology | undefined> {
    return this._api.addFormPathology(e)
  }

  getFormPathologyByFormID(formID: string): Observable<FormPathology | undefined> {
    return this._api.getFormPathologyByFormID(formID)
  }

  getFormPathologyByPathologyID(pathologyID: string): Observable<FormPathology | undefined> {
    return this._api.getFormPathologyByPathologyID(pathologyID)
  }

  getListFormPathology(q: Query<FormPathologyQuery>): Observable<FormPathology[]> {
    return this._api.getListFormPathology(q)
  }

  updateFormPathology(e: FormPathology): Observable<FormPathology | undefined> {
    return this._api.updateFormPathology(e)
  }

  getListByUserPathology(q: Query<FormQuery>): Observable<ItemList<Form>> {
    return this._api.getListByUserPathology(q).pipe()
  }

  getListByPathology(typeID: string): Observable<ItemList<Form>> {
    return this._api.getListByPathology(typeID).pipe()
  }

  getListByCreatorID(q: Query<FormQuery>): Observable<ItemList<Form>> {
    return this._api.getListByCreatorID(q).pipe()
  }

  /*updateFormField(e: FormField): Observable<FormField | undefined> {
    return this._api.updateFormField(e)
  }*/
}
