export type EpilepticalCrisisProps = {
  id: string
  date: Date
  mood: number
  medication: number
  userCircleID: string
  patientSymptomID: string
}

export class EpilepticalCrisis {
  private _id: string
  private _date: Date
  private _mood: number
  private _medication: number
  private _userCircleID: string
  private _patientSymptomID: string

  constructor(p: EpilepticalCrisisProps) {
    this._id = p.id
    this._date = p.date
    this._mood = p.mood
    this._medication = p.medication
    this._userCircleID = p.userCircleID
    this._patientSymptomID = p.patientSymptomID
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get date(): Date {
    return this._date
  }

  set date(value: Date) {
    this._date = value
  }

  get mood(): number {
    return this._mood
  }

  set mood(value: number) {
    this._mood = value
  }

  get medication(): number {
    return this._medication
  }

  set medication(value: number) {
    this._medication = value
  }

  get userCircleID(): string {
    return this._userCircleID
  }

  set userCircleID(value: string) {
    this._userCircleID = value
  }

  get patientSymptomID(): string {
    return this._patientSymptomID
  }

  set patientSymptomID(value: string) {
    this._patientSymptomID = value
  }
}

export interface EpilepticalCrisisQuery {
  id: string
  patientSymptomID: string
  userCircleID: string
  date: Date
  startDate: Date
  finishDate: Date
}
