import React from 'react'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import type {} from '@mui/x-date-pickers/themeAugmentation'

const theme = createTheme({
  components: {
    MuiDatePicker: {
      styleOverrides: {
        root: {
          color: 'red',
        },
      },
    },
  },
})

const CustomDatePicker = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={new Date()} // Set your initial date here
          onChange={() => {}} // Add your onChange handler
          renderInput={(params) => <TextField {...params} />} // Replace with your input component
        />
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default CustomDatePicker
