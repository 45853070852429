import { Container, IInit } from '../../../common/container/Container'
import { ContentContainerConfig } from '../container'
import { Tag } from '../models/Tag'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { TagDTO, toModel } from '../models/TagDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface ITagApi extends IInit {
  getByID(id: string): Observable<Tag | undefined>

  getByArticleID(id: string): Observable<string[]>

  getFilteredList(q: Query<Tag>): Observable<ItemList<Tag>>

  add(e: TagDTO): Observable<Tag | undefined>

  update(e: TagDTO): Observable<Tag | undefined>

  delete(id: string): Observable<boolean>
}

export class TagApi implements ITagApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ContentContainerConfig).moduleFullUrl + '/tags'
  }

  add(e: TagDTO): Observable<Tag | undefined> {
    return this._httpClient.post<TagDTO>({ url: this._url, body: e }).pipe(
      map<TagDTO, Tag>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Tag | undefined> {
    return this._httpClient.get<Tag>({ url: `${this._url}/${id}` }).pipe(
      map<TagDTO, Tag>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByArticleID(id: string): Observable<string[]> {
    return this._httpClient.get<string[]>({ url: `${this._url}/articles/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  getFilteredList(q: Query<Tag>): Observable<ItemList<Tag>> {
    return this._httpClient.get<ItemList<Tag>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<TagDTO>, ItemList<Tag>>((dto) => {
        const itemList = emptyList<Tag>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Tag>())
      })
    )
  }

  update(e: TagDTO): Observable<Tag | undefined> {
    return this._httpClient.put<TagDTO>({ url: this._url, body: e }).pipe(
      map<TagDTO, Tag>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
