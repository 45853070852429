import { Container, ContainerItem } from '../common/container/Container'
import { SettingService } from '../modules/settings/services/SettingService'
import { SETTING_API_KEY, SETTING_SERVICE_KEY, SETTING_MODULE } from '../modules/settings'
import { SettingApi } from '../modules/settings/api/SettingApi'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { SettingProps } from '../modules/settings/container'

let container: Container

function init(p: SettingProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(SETTING_API_KEY, new ContainerItem({ instance: new SettingApi() }))

  items.set(
    SETTING_SERVICE_KEY,
    new ContainerItem({ instance: new SettingService({ apiKey: SETTING_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getSettingContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[SETTING_MODULE],
      },
    })
  }

  return container
}
