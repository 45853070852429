import { AutoMessage, AutoMessageQuery } from '../models/AutoMessage'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { IAutoMessageApi } from '../api/AutoMessageApi'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'

type Props = {
  apiKey: symbol
}

export interface IAutoMessageService extends IInit {
  getByID(id: string): Observable<AutoMessage | undefined>

  getFilteredList(q: Query<AutoMessageQuery>): Observable<ItemList<AutoMessage>>

  add(e: AutoMessage): Observable<AutoMessage | undefined>

  update(e: AutoMessage): Observable<AutoMessage | undefined>

  delete(id: string): Observable<boolean>
}

export class AutoMessageService implements IAutoMessageService {
  protected readonly _apiKey: symbol
  protected _container!: Container
  protected _api!: IAutoMessageApi
  protected _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IAutoMessageApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: AutoMessage): Observable<AutoMessage | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<AutoMessage | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<AutoMessageQuery>): Observable<ItemList<AutoMessage>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: AutoMessage): Observable<AutoMessage | undefined> {
    return this._api.update(e)
  }
}
