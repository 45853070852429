import { Query } from '../../../common/api/Query'
import { CheckboxAssignType } from '../enums/CheckboxAssignType'

export type UserFormWithCheckboxProps = {
  id?: string
  formID: string
  createCheckBoxes: CheckboxAssignType[]
  pathologies: string[]
}

export class UserFormWithCheckbox {
  private readonly _id: string | undefined
  private _formID: string
  private _createCheckBoxes: CheckboxAssignType[]
  private _pathologies: string[]

  constructor(p: UserFormWithCheckboxProps) {
    this._id = p.id
    this._formID = p.formID
    this._createCheckBoxes = p.createCheckBoxes
    this._pathologies = p.pathologies
  }

  get id(): string | undefined {
    return this._id
  }

  get formID(): string {
    return this._formID
  }

  set formID(value: string) {
    this._formID = value
  }

  get createCheckBoxes(): CheckboxAssignType[] {
    return this._createCheckBoxes
  }

  set createCheckBoxes(value: CheckboxAssignType[]) {
    this._createCheckBoxes = value
  }

  get pathologies(): string[] {
    return this._pathologies
  }

  set pathologies(value: string[]) {
    this._pathologies = value
  }
}

export class UserFormQuery extends Query<UserFormWithCheckbox> {}
