import { Header } from '../../../../../components/header/Header'
import { Box, Divider, Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from '../../../add-new-circle/view 4 Forms/Modal.module.css'
import exitIcon from '../../../../../assets/newCircle/exit_icon.svg'
import { emptyFormDTO, FormDTO, FormFieldDTO } from '../../../../../modules/forms/models/FormDTO'

import { AppButton, ButtonTheme } from '../../../../../components/app-button/AppButton'
import { QuestionsFormCircleGenerator } from './QuestionsFormCircleGenerator'
import { getFormContainer } from '../../../../../container/form-module'
import { FORM_SERVICE_KEY, IFormService } from '../../../../../modules/forms'
import { CircleDTO } from '../../../../../modules/circle/models/CircleDTO'
import { getCircleAssignFormsContainer } from '../../../../../container/circle-assign-form-module'
import {
  CIRCLE_ASSIGN_FORMS_SERVICE_KEY,
  ICircleAssignFormsService,
} from '../../../../../modules/circle-assign-form'
import {
  CircleAssignFormDTO,
  emptyCircleAssignFormsDTO,
  fromModel,
} from '../../../../../modules/circle-assign-form/models/CircleAssignFormDTO'
import { getUserContainer } from '../../../../../container/user-module'
import { ILoggedUserService } from '../../../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../../../modules/users'

interface Props {
  onClose: () => void
  handleNextStep: () => void
  formParent: FormDTO
  setFormParent: React.Dispatch<React.SetStateAction<FormDTO>>
  circleDraft: CircleDTO | undefined
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const formService = getFormContainer().get<IFormService>(FORM_SERVICE_KEY)
const circleAssignFormService = getCircleAssignFormsContainer().get<ICircleAssignFormsService>(
  CIRCLE_ASSIGN_FORMS_SERVICE_KEY
)
const ModalAddNewCircleNewFormSecondStep: React.FC<Props> = ({
  onClose,
  handleNextStep,
  formParent,
  setFormParent,
  circleDraft,
}) => {
  const { t } = useTranslation()
  const [form, setForm] = useState<FormDTO>(formParent)
  const [circleAssignForm, setCircleAssignForm] = useState<CircleAssignFormDTO>(
    emptyCircleAssignFormsDTO()
  )
  const loggedUser = loggedUserService.get()
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (!circleDraft) return
    circleAssignFormService.getByCircleID(circleDraft.id).subscribe((res) => {
      if (!res) return
      setCircleAssignForm(fromModel(res))
    })
  }, [circleDraft]) // on each open, because other modals can change the assignForm object.

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm(Object.assign({ ...form }, { [e.target.name]: e.target.value }))
  }
  const changeFormFields = (formFields: FormFieldDTO[]) => (form.formFields = formFields)
  const handlerRecoveredFormField = (f: FormFieldDTO[]) => {
    form.formFields = f
    // setRecoveredFormField(f)
  }

  const validateFormStep2 = () => {
    if (form.formFields[0].title === '') {
      setErrorMessage('Los campos de título de las preguntas son obligatorios')
      return false
    }
    return true
  }

  const handleSave = async () => {
    if (!validateFormStep2()) return
    if (!form.id) return
    form.creator !== ''
      ? await formService.update({ ...form, creator: loggedUser?.id ?? '' }).toPromise()
      : await formService
          .add({
            ...form,
            creator: loggedUser?.id ?? '',
            circles: circleDraft?.name ?? '',
            specialities: circleDraft?.specialities.join(',') ?? '',
          })
          .toPromise()

    const circleAssignFormAux = circleAssignForm
    circleAssignFormAux.formsID.push(form.id)

    await circleAssignFormService.update(circleAssignFormAux).toPromise()
    setFormParent(emptyFormDTO())

    onClose()
  }

  return (
    <Box>
      <Box className={style.modalContainer}>
        <Box style={{ margin: '16px' }}>
          <Box className={style.contentHeaderExitIcon}>
            <img
              style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
              onClick={onClose}
              src={exitIcon}
            />
          </Box>
          <Box className={style.contentHeader}>
            <Header label={t('newForm')} />
          </Box>

          <Box>
            <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.textField}>
              <b className={style.titleLabel} style={{ marginRight: '50px' }}>
                {t('name')}*
              </b>
              <input
                className={style.inputName}
                type="text"
                name="name"
                value={form.title}
                disabled={true}
                onChange={(e) => handleInput(e)}
                required
              />
            </Box>
          </Box>

          <Box>
            <Box>
              <p
                style={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 'bold',
                  fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                  marginLeft: 0,
                  width: '100%',
                }}
              >
                {t('formQuestions')}
              </p>
              <Divider light style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
            </Box>
            <QuestionsFormCircleGenerator
              formFieldsProps={form.formFields.sort((a, b) => a.order - b.order)}
              form={form}
              handlerChange={changeFormFields}
              handlerRecoveredFormField={handlerRecoveredFormField}
              isCreator={true}
            />
          </Box>
          {errorMessage && (
            <Box mb={3}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage)}
              </Alert>
            </Box>
          )}
          <Box display="flex" style={{ justifyContent: 'space-between' }}>
            <Box style={{ marginRight: '20px' }}>
              <AppButton
                theme={ButtonTheme.whiteAndBlue}
                type={'button'}
                label={t('backOneToResources')}
                marginStartIcon={{ marginRight: '10px' }}
                handler={handleNextStep}
                vertical={true}
              />
            </Box>
            <Box style={{ display: 'flex' }}>
              <Box>
                <AppButton
                  theme={ButtonTheme.newEvent}
                  type={'button'}
                  label={t('save')}
                  marginStartIcon={{ marginRight: '10px' }}
                  handler={handleSave}
                  vertical={true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ModalAddNewCircleNewFormSecondStep
