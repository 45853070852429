import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { IVacusanApi } from '../api/VacusanApi'
import { Vacusan } from '../models/Vacusan'

type Props = {
  apiKey: symbol
}

export interface IVacusanService extends IInit {
  getByCIPA(cipa: string): Observable<Vacusan | undefined>
}

export class VacusanService implements IVacusanService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IVacusanApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IVacusanApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByCIPA(cipa: string): Observable<Vacusan | undefined> {
    return this._api.getByCIPA(cipa)
  }
}
