import React, { createContext, useCallback, useState } from 'react'
import { SharedWith } from '../../../modules/sharedWith/models/SharedWith'
import { AssignedUser } from '../../../features/share-resources/Editor'
import {
  RolesCheckbox,
  RowItem,
} from '../../../features/form-generator/asign-form-section/AssignFormSection'
import { emptyFileDTO, FileDTO } from '../../../modules/files/models/FileDTO'
import { getUserContainer } from '../../../container/user-module'
import { LoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../modules/users'
import { CheckboxAssignType } from '../../../modules/forms/enums/CheckboxAssignType'

interface EditorContextType {
  handlerAssignedUser: (users: AssignedUser[]) => void
  handleSaveForm: (aveForm: () => void) => void
  recoveredSelected: (s: RowItem[]) => void
  handleCheckBoxSelected: (cb: CheckboxAssignType[]) => void
  recoveredCheckBoxRoles: (cb: RolesCheckbox[]) => void
  saving: boolean
  shareWith: SharedWith[]
  currentCirclePathology?: string[]
  preview: boolean
  recoveredCheckBox: RolesCheckbox[]
  resourceContext: FileDTO
  setResourceContext: (resource: FileDTO) => void
  setShareWith: (resource: SharedWith[]) => void
  selected: RowItem[]
  setSelected: (s: RowItem[]) => void
  checkBoxSelected: CheckboxAssignType[]
  assignedUsers: AssignedUser[]
  setPreview: (preview: boolean) => void
  setSaving: (saving: boolean) => void
  setCurrentCirclePathology: (currentCirclePathology: string[]) => void
}

export const EditorContext = createContext<EditorContextType>({
  handlerAssignedUser: () => {},
  handleSaveForm: () => {},
  recoveredSelected: () => {},
  handleCheckBoxSelected: () => {},
  recoveredCheckBoxRoles: () => {},
  saving: false,
  shareWith: [],
  currentCirclePathology: [],
  preview: false,
  recoveredCheckBox: [],
  resourceContext: emptyFileDTO('', '', true),
  setResourceContext: () => {},
  selected: [],
  setSelected: () => {},
  setShareWith: () => {},
  checkBoxSelected: [],
  assignedUsers: [],
  setPreview: () => {},
  setSaving: () => {},
  setCurrentCirclePathology: () => {},
})

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const EditorContextProvider: React.FC = ({ children }) => {
  const loggedUser = loggedUserService.get()
  const [saving, setSaving] = useState(false)
  const [shareWith, setShareWith] = useState<SharedWith[]>([])
  const [currentCirclePathology, setCurrentCirclePathology] = useState<string[]>([])
  const [assignedUsers, setAssignedUsers] = useState<AssignedUser[]>([])
  const [resourceContext, setResourceContext] = useState<FileDTO>(
    emptyFileDTO(loggedUser?.id, '', true)
  )
  const [selected, setSelected] = useState<RowItem[]>([])
  const [preview, setPreview] = useState<boolean>(false)
  const [checkBoxSelected, setCheckBoxSelected] = useState<CheckboxAssignType[]>([])
  const [recoveredCheckBox, setRecoveredCheckbox] = useState<RolesCheckbox[]>([])

  const handlerAssignedUser = useCallback((users: AssignedUser[]) => {
    setAssignedUsers(users)
  }, [])

  const handleSaveForm = (saveForm: () => void) => {
    saveForm()
  }

  const recoveredSelected = (s: RowItem[]) => {
    if (resourceContext.checkBoxesSelected !== '') {
      setSelected([])
      return
    }
    setSelected(s)
  }

  const handleCheckBoxSelected = (cb: CheckboxAssignType[]) => {
    resourceContext.checkBoxesSelected = cb.join(',')
    setCheckBoxSelected(cb)
  }

  const recoveredCheckBoxRoles = (cb: RolesCheckbox[]) => {
    setRecoveredCheckbox(cb)
  }

  const contextValue: EditorContextType = {
    handlerAssignedUser,
    handleSaveForm,
    recoveredSelected,
    handleCheckBoxSelected,
    recoveredCheckBoxRoles,
    saving,
    shareWith,
    currentCirclePathology,
    preview,
    recoveredCheckBox,
    resourceContext,
    setResourceContext,
    selected,
    setSelected,
    checkBoxSelected,
    setShareWith,
    assignedUsers,
    setPreview,
    setSaving,
    setCurrentCirclePathology,
  }

  return <EditorContext.Provider value={contextValue}>{children}</EditorContext.Provider>
}
