import { Message } from '../models/Message'
import { Container, IInit } from '../../../common/container/Container'
import { NotificationContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { fromModel, MessageDTO, toModel } from '../models/MessageDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface IMessageApi extends IInit {
  getByID(id: string): Observable<Message | undefined>

  getFilteredItems(q: Query<Message>): Observable<Message[]>

  add(e: Message): Observable<Message | undefined>

  update(e: Message): Observable<Message | undefined>

  delete(e: Message): Observable<boolean>
}

export class MessageApi implements IMessageApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as NotificationContainerConfig).moduleFullUrl + '/messages'
  }

  add(e: Message): Observable<Message | undefined> {
    return this._httpClient.post<Message>({ url: this._url, body: fromModel(e) }).pipe(
      map<MessageDTO, Message>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(e: Message): Observable<boolean> {
    return this._httpClient.delete({ url: this._url, body: fromModel(e) }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Message | undefined> {
    return this._httpClient.get<Message>({ url: `${this._url}/${id}` }).pipe(
      map<MessageDTO, Message>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredItems(q: Query<Message>): Observable<Message[]> {
    return this._httpClient.get<Message[]>({ url: this._url }).pipe(
      map<MessageDTO[], Message[]>((dto) => dto.map((d) => toModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  update(e: Message): Observable<Message | undefined> {
    return this._httpClient.put<Message>({ url: this._url, body: fromModel(e) }).pipe(
      map<MessageDTO, Message>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
