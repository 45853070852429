import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import React, { useEffect, useState } from 'react'
import { File as F, FileQuery } from '../../modules/files/models/File'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  ROUTE_CREATE,
  ROUTE_RESOURCES,
  ROUTE_RESOURCES_SHARED_WITH_ME,
  ROUTE_SHARE_RESOURCES,
  ROUTE_SHARE_RESOURCES_FORM,
} from '../../routes/routes-constants'
import { emptyFileDTO, fromModel, toModel, FileDTO } from '../../modules/files/models/FileDTO'
import style from '../generic/GenericTable.module.css'
import styles from './ShareResources.module.css'
import { cloneDeep } from 'lodash'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import createFile from '../../assets/resource_icons/ico-file-crear.svg'
import createDirectory from '../../assets/resource_icons/ico-folder.svg'
import shareWithMe from '../../assets/resource_icons/ico-share.svg'
import {
  LOGGED_USER_SERVICE_KEY,
  PROFESSIONALTYPE_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users/container'
import { getUserContainer } from '../../container/user-module'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import { downloadFile, viewFile } from '../../common/utils/file'
import { AppTable, Field } from '../../components/table'
import { Actions, Pager, Search, SearchValue } from '../../components/table_type/types'
import { forkJoin, Observable } from 'rxjs'
import { User } from '../../modules/users/models/User'
import { UserService } from '../../modules/users/services/UserService'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import tableStyles from './ShareResources.module.css'
import folder from '../../assets/resource_icons/folferIconResponsive.svg'
import file from '../../assets/resource_icons/resourceIconResponsive.svg'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import downloadIcon from '../../assets/table_icons/ico-download.svg'
import deleteIcon from '../../assets/table_icons/ico-eliminar.svg'
import toAgoraIcon from '../../assets/table_icons/ico-subir.svg'
import { CustomModal } from '../../components/modal/CustomModal'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Permission } from '../../common/permission'
import { Query, QueryParam, QueryParamN } from 'common/api/Query'
import { Box, Breadcrumbs, Chip, Modal, Stack } from '@mui/material'
import {
  CheckboxAssignType,
  checkboxAssignType,
} from '../../modules/forms/enums/CheckboxAssignType'
import { ProfessionalTypesService } from '../../modules/users/services/ProfessionalTypeService'
import { TableSkeleton } from '../../components/table-skeleton/TableSkeleton'

const userContainer = getUserContainer()
const loggedUserService2 = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = userContainer.get<UserService>(USER_SERVICE_KEY)
const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)
const profTypeService = userContainer.get<ProfessionalTypesService>(PROFESSIONALTYPE_SERVICE_KEY)

type ResourcesProps = {
  resource?: string
  breadcrumbUrl?: Map<string, string>
  refMain?: React.RefObject<HTMLDivElement>
}

export function ShareResources(props: ResourcesProps): JSX.Element {
  const { t } = useTranslation('translation')
  const location = useLocation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [resources, setResources] = useState<F[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [breadcrumbUrl, setBreadcrumbUrl] = useState<Map<string, string>>(
    props.breadcrumbUrl && props.breadcrumbUrl.size !== 0
      ? props.breadcrumbUrl
      : new Map<string, string>([['-1', 'Inicio']])
  )
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [filesPerPage, setFilesPerPage] = useState<number>(10)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [currentFile, setCurrentFile] = useState<F>()
  const [userNames, setUserNames] = useState<Map<string, string>>(new Map())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileDTO, setFileDTO] = useState<FileDTO>(
    currentFile ? fromModel(currentFile) : emptyFileDTO('', '', false)
  )
  const [speciality, setSpeciality] = useState<string>('')

  const [isSystem, setIsSystem] = useState<string>('')
  const isSharedFolder = location.pathname.includes(ROUTE_RESOURCES_SHARED_WITH_ME)
  const [isLoadResource, setIsLoadResource] = useState<boolean>(false)
  const [currentDirectory, setCurrentDirectory] = useState<F | null>(null)
  const [searcher, setSearcher] = useState<SearchValue<FileQuery>[]>([
    {
      name: 'searchName',
      label: t('search') + '...',
      value: '',
    },
  ])
  const [backButton, setBackButton] = useState<boolean>(false)

  const [badResoursesOpen, setBadResoursesOpen] = useState<boolean>(false)
  const [badResoursesMessage, setBadResoursesMessage] = useState<string>('')
  const [badResoursesTitle, setBadResoursesTitle] = useState<string>('')
  const isCaseManager = ['managerHealthChild', 'managerActivePlan', 'managerEpileptic'].some(
    (role) => loggedUser?.roles?.includes(role)
  )

  useEffect(() => {
    if (!(loggedUser && loggedUser.id)) {
      return
    }
    if (isCaseManager) {
      loggedUser.roles.forEach((rol) => {
        switch (rol) {
          case 'managerHealthChild':
            setSpeciality('Pediatra')
            break
          case 'managerActivePlan':
            setSpeciality('Plan Activa')
            break
          case 'managerEpileptic':
            setSpeciality('Pediatra')
            break
        }
      })
    } else {
      profTypeService.getProfessionalTypeByUserID(loggedUser.id).subscribe((res) => {
        if (!(res && res.name)) {
          return
        }
        setSpeciality(res.name)
      })
    }
  }, [loggedUser])

  useEffect(() => {
    // if (searcher[0].value !== '') return
    filesService
      .createdByDoctor(
        new Query({
          query: [
            new QueryParam<FileQuery>('parent', props.resource ?? ''),
            new QueryParam<FileQuery>('specialities', speciality),
            new QueryParam<FileQuery>('creator', loggedUser ? loggedUser.id : ''),
            ...searcherQuery(searcher),
          ],
          pager: { offset: page * filesPerPage, limit: filesPerPage },
          sort: [{ field: 'name', desc: true }],
        })
      )
      .subscribe((res) => {
        setResources(res.items)
        setCount(res.count)
      })
  }, [isLoading, page, count, filesPerPage, props.resource, searcher, speciality])

  /*
  useEffect(() => {
    if (searcher[0].value !== '') {
      filesService
        .getFilteredItems(
          new Query({
            pager: { offset: page * filesPerPage, limit: filesPerPage },
            sort: [{ field: 'name', desc: true }],
            query: [
              new QueryParam<FileQuery>('specialities', speciality),
              ...searcherQuery(searcher),
            ],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setResources(res.items)
          setCount(res.count)
        })
    }
  }, [searcher])

   */

  useEffect(() => {
    setIsLoading(true)
  }, [props.resource])

  useEffect(() => {
    if (!props.breadcrumbUrl) return
    setBreadcrumbUrl(props.breadcrumbUrl)
  }, [props.breadcrumbUrl])

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  useEffect(() => {
    // setCount(resources.length)
    let users: string[] = []
    resources.forEach((r) => {
      users = users.concat(r.sharedWith, [r.creator])
    })
    getUsers(users).subscribe((res) => {
      const newMap = new Map()
      res.forEach((user) => {
        if (user) {
          newMap.set(user.id, user.name + ' ' + user.lastname)
        }
      })

      setUserNames(newMap)
    })
    const interval = setInterval(() => {
      setIsLoading(false)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [resources])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: filesPerPage,
      handleChangeRowsPerPage,
    })
    setIsLoading(true)
  }, [page, count, filesPerPage])

  useEffect(() => {
    if (!props.resource) return
    if (isLoading) {
      filesService
        .getByID(props.resource || null)
        .toPromise()
        .then((res) => {
          setIsSystem(res?.isSystem ? res?.isSystem : '')
          handleSubscribeFileService(res)

          if (isSharedFolder) {
            filesService
              .getSharedWithMe(props.resource ?? null)
              .toPromise()
              .then((resS) => {
                setResources(resS)
              })
          }
        })
    }
  }, [isLoading, props.resource])

  const search: Search<FileQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<FileQuery>[]) => {
      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
    },
  }

  useEffect(() => {
    if (currentDirectory === null) return
    handleSubscribeFileService(currentDirectory)
  }, [props.resource])

  const handleSubscribeFileService = (res: F | undefined) => {
    // setIsLoading(false)

    if (props.resource) {
      if (breadcrumbUrl.get(props.resource || '')) {
        let breadcrumbUrlCopy = Array.from(breadcrumbUrl, ([key, value]) => ({ key, value }))
        breadcrumbUrlCopy = breadcrumbUrlCopy.slice(
          0,
          breadcrumbUrlCopy.findIndex(({ key }) => key === props.resource) + 1
        )
        setBreadcrumbUrl(new Map(breadcrumbUrlCopy.map((i) => [i.key, i.value])))
      } else {
        const breadcrumbCopy = cloneDeep<Map<string, string>>(breadcrumbUrl)
        breadcrumbCopy.set(props.resource, res?.name ?? '-')
        setBreadcrumbUrl(breadcrumbCopy)
      }
    } else {
      setBreadcrumbUrl(new Map<string, string>([['-1', 'Inicio']]))
    }
  }

  const createResource = () =>
    navigate(`${ROUTE_SHARE_RESOURCES}/${ROUTE_CREATE}`, {
      state: {
        parentID: props.resource ?? null,
        breadcrumbUrl,
        title: t('shareResources'),
      },
    })

  const createDir = () =>
    navigate(`${ROUTE_SHARE_RESOURCES}/${ROUTE_CREATE}`, {
      state: { parentID: props.resource ?? null, breadcrumbUrl, isDir: true },
    })

  const editResource = (resource: F) =>
    navigate(`${ROUTE_SHARE_RESOURCES_FORM}/${resource.id}`, {
      state: {
        parentID: props.resource ?? null,
        breadcrumbUrl,
        isDir: resource.isDir,
      },
    })

  const shareResource = (resource: F) => {
    navigate(`${ROUTE_SHARE_RESOURCES}/${resource.id}/circle`, {
      state: {
        parentID: props.resource ?? null,
      },
    })
  }

  const downloadResource = (resource: F) => {
    setIsLoadResource(true)
    filesService.download(resource).subscribe((res) => {
      if (res) {
        downloadFile(res.name, res.mimeType, res.data)
        setIsLoadResource(false)
      } else {
        setBadResoursesTitle(t('downloadResourses'))
        setBadResoursesMessage(t('modalRosoursesBrokenDownload'))
        setBadResoursesOpen(true)
        setIsLoadResource(true)
      }
    })
  }

  const viewResource = (resource: F) => {
    setIsLoadResource(true)
    filesService.download(resource).subscribe((res) => {
      if (res) {
        viewFile(res)
        setIsLoadResource(false)
      } else {
        setBadResoursesTitle(t('downloadResourses'))
        setBadResoursesMessage(t('modalRosoursesBrokenDownload'))
        setBadResoursesOpen(true)
        setIsLoadResource(true)
      }
    })
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleSave = () => {
    const newFile = Object.assign({ ...fileDTO }, { isUploaded: true })
    filesService.update(toModel(newFile)).subscribe()
    setCurrentFile(undefined)
    setOpenModal(false)
  }

  const handleClick = (resource: F) => {
    if (resource.isDir) {
      setCurrentDirectory(resource)
      handleSubscribeFileService(resource)
      navigateToResources(resource.id ?? null, isSharedFolder, resource)
    }
  }

  const removeResource = (resource: F) => {
    setCurrentFile(resource)
    setIsLoading(!isLoading)
    setOpenDeleteModal(true)
  }

  const navigateToResources = (
    id: string | null,
    goToSharedFolder: boolean = false,
    resource: F | null
  ) => {
    if (id) {
      setCurrentDirectory(resource)
      navigate(
        `${goToSharedFolder ? ROUTE_RESOURCES_SHARED_WITH_ME : ROUTE_SHARE_RESOURCES}/${id}`,
        {
          state: {
            parentID: props.resource ?? null,
            breadcrumbUrl,
            isDir: resource?.isDir,
          },
        }
      )
    } else {
      setCurrentDirectory(null)
      navigate(`${goToSharedFolder ? ROUTE_RESOURCES_SHARED_WITH_ME : ROUTE_SHARE_RESOURCES}`, {
        state: {
          parentID: props.resource ?? null,
          breadcrumbUrl: new Map<string, string>([['-1', 'Inicio']]),
          isDir: resource?.isDir,
        },
      })
    }
  }

  async function handleBreadcrumbClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const resourceID = event.currentTarget.id
    if (Number(resourceID) === -1) {
      setCurrentDirectory(null)
      navigateToResources(null, isSharedFolder, null)
    } else {
      filesService.getByID(resourceID).subscribe((res) => {
        if (!res) return
        setCurrentDirectory(res)
        navigateToResources(resourceID, isSharedFolder, null)
      })
    }
  }

  function handleBreadcrumbBackClick(_: React.MouseEvent<HTMLElement, MouseEvent>) {
    setBackButton(true)
    if (currentDirectory) {
      const parentID = currentDirectory.parent ?? null
      if (parentID) {
        filesService.getByID(parentID).subscribe((res) => {
          if (!res) return
          setCurrentDirectory(res)
        })
      } else {
        breadcrumbUrl?.delete(currentDirectory.id ? currentDirectory.id : '')
        setCurrentDirectory(null)
      }
      navigateToResources(parentID, isSharedFolder, null)
    } else {
      setBreadcrumbUrl(new Map<string, string>([['-1', 'Inicio']]))
      setCurrentDirectory(null)
      navigateToResources(null, isSharedFolder, null)
    }
  }

  const backToResources = () => navigate(ROUTE_RESOURCES)

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setFilesPerPage(10)
      return
    }
    setFilesPerPage(Number.parseInt(event.target.value))
  }

  const handleToAgora = (item: F) => {
    if (item) setCurrentFile(item)
    setOpenModal(true)
  }

  const isCreator = (r: F) => r.creator === loggedUser?.id

  const fields: Field<F>[] = [
    {
      name: 'name',
      label: t('title'),
      renderFunc: (f, i) => (
        <div className={i.isDir ? tableStyles.dir : ''} onClick={() => handleClick(i)}>
          <img src={i.isDir ? folder : file} className={tableStyles.icon} />
          <span style={{ color: i.creator === loggedUser?.id ? 'green' : '' }}>{i.name}</span>
        </div>
      ),
    },
    {
      name: 'creator',
      label: t('creator'),
      renderFunc: (f, i) => userNames.get(i.creator) ?? '',
    },
    {
      name: 'checkBoxesSelected',
      label: t('scope'),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      renderFunc: (f, i) => {
        const arrayCheckBoxes = i.checkBoxesSelected?.split(',')
        if (i.checkBoxesSelected !== '') {
          return arrayCheckBoxes?.map((cb) => (
            <Chip
              style={{
                backgroundColor: '#dddedf',
                borderRadius: '9px',
                marginLeft: '8px',
                marginBottom: '8px',
              }}
              label={
                <span style={{ fontFamily: 'Poppins', color: '#000' }}>
                  {checkboxAssignType()[cb as unknown as CheckboxAssignType]}
                </span>
              }
              key={cb + i.id}
            />
          ))
        }
        if (i.sharedWith.length > 0) {
          return (
            <Chip
              style={{
                backgroundColor: '#dddedf',
                borderRadius: '9px',
                marginLeft: '8px',
                marginBottom: '8px',
              }}
              label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{t('custom')}</span>}
              key={i.id ?? 0 + 1}
            />
          )
        }
        return (
          <Chip
            style={{
              backgroundColor: '#dddedf',
              borderRadius: '9px',
              marginLeft: '8px',
              marginBottom: '8px',
            }}
            label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{t('anyone')}</span>}
            key={i.id}
          />
        )
      },
    },
    {
      name: 'specialities',
      label: t('specialty'),
      renderFunc: (f, i) => {
        if (i.specialities) {
          return (
            <Chip
              style={{
                backgroundColor: '#dddedf',
                borderRadius: '9px',
                marginLeft: '8px',
                marginBottom: '8px',
              }}
              label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{i.specialities}</span>}
              key={i.specialities + i.id}
            />
          )
        }
        return ''
      },
    },
    {
      name: 'circles',
      label: t('circle'),
      renderFunc: (f, i) => {
        if (i.circles && i.circles?.length > 0) {
          const array = i.circles.split(',')
          return array.map((p, index) => (
            <Chip
              style={{
                backgroundColor: '#d1dee6',
                marginLeft: '8px',
                marginBottom: '8px',
              }}
              label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{p}</span>}
              key={p + index + i.id}
            />
          ))
        }
        return ''
      },
    },
  ]

  const actions: Actions<F> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: viewResource,
        icon: seeIcon,
        label: 'see',
        hidden: (r) => r.isDir,
      },
      {
        handler: editResource,
        icon: editIcon,
        label: 'edit',
        hidden: (r) => !isCreator(r) || r.isSystem !== '',
      },
      {
        handler: shareResource,
        icon: shareWithMe,
        label: 'assign',
        hidden: (r) => (isCaseManager ? true : isCreator(r)), // || (r.isDir && r.sharedWith.length > 0),
      },
      {
        handler: downloadResource,
        icon: downloadIcon,
        label: 'download',
        hidden: (r) => r.isDir,
      },
      {
        handler: handleToAgora,
        icon: toAgoraIcon,
        label: 'cloudToAgora',
        hidden: () =>
          !loggedUserService2.userCan(Permission.uploadToAgora) ||
          !(typeof props.resource === 'undefined'),
      },
      {
        handler: removeResource,
        icon: deleteIcon,
        label: 'delete',
        hidden: (r) => !isCreator(r) || r.isSystem !== '',
      },
    ],
  }

  const isMobile = () => {
    return window.screen.width >= 400
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteResource = () => {
    if (currentFile)
      filesService.delete(currentFile).subscribe((_) => {
        setIsLoading(true)
      })
    setOpenModal(false)
    setOpenDeleteModal(false)
    setIsLoading(!isLoading)
  }

  const breadcrumbItems = () => {
    return (
      breadcrumbUrl &&
      Array.from(breadcrumbUrl.entries()).map(([key, value]) => (
        <span className={styles.breadcrumbItem} key={key}>
          <a style={{ fontFamily: 'Poppins' }} id={key} onClick={handleBreadcrumbClick}>
            {value}
          </a>
        </span>
      ))
    )
  }
  // resolve el bug cuando pinchas muchas veces seguido boton "volver". Antes volvia a principio y se rompia breadcrumblURL
  useEffect(() => {
    if (backButton) {
      setTimeout(() => setBackButton(false), 500)
    }
  }, [backButton])

  const handleCloseModalBadResources = () => {
    setBadResoursesOpen(false)
  }

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Stack display={'flex'} mb={2} spacing={2}>
          <Breadcrumbs separator=">" aria-label="breadcrumb" color={'#68b3e0'}>
            {breadcrumbItems()}
          </Breadcrumbs>
        </Stack>
        <Box className={style.buttonContainer}>
          {!isSharedFolder && isSystem === '' ? (
            <>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={isMobile() ? t('createResource') : ''}
                startIcon={createFile}
                handler={createResource}
              />
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={isMobile() ? t('createDir') : ''}
                startIcon={createDirectory}
                handler={createDir}
              />
              {props.resource && (
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('backOneToResources')}
                  handler={handleBreadcrumbBackClick}
                  disabled={backButton}
                />
              )}
            </>
          ) : (
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('backToResources')}
              handler={backToResources}
            />
          )}
        </Box>
        <Box mt={3}>
          {!isLoading ? (
            <>
              {resources.length > 0 ? (
                <AppTable
                  items={resources}
                  rowKeyField="id"
                  fields={fields}
                  actions={actions}
                  pager={pager}
                  search={search}
                  isPreventDefault={true}
                />
              ) : (
                <>
                  <AppTable
                    items={resources}
                    rowKeyField="id"
                    fields={fields}
                    actions={actions}
                    pager={pager}
                    search={search}
                    isPreventDefault={true}
                  />
                  <h1 key="no-resources" className={style.noElements}>
                    {t('noResources')}
                  </h1>
                </>
              )}
            </>
          ) : (
            <TableSkeleton searcher={true} />
          )}
          {!currentFile?.isDir && (
            <Modal
              open={openDeleteModal}
              className={style.eventModal}
              onClose={handleCloseDeleteModal}
            >
              <>
                <CustomModal
                  handleClose={handleCloseDeleteModal}
                  handleSave={handleDeleteResource}
                  title={t('deleteResource')}
                  warningText={t('irreversibleResourceAction')}
                />
              </>
            </Modal>
          )}
          {currentFile?.isDir && (
            <Modal
              open={openDeleteModal}
              className={style.eventModal}
              onClose={handleCloseDeleteModal}
            >
              <>
                <CustomModal
                  handleClose={handleCloseDeleteModal}
                  handleSave={handleDeleteResource}
                  title={t('deleteDirectory')}
                  warningText={t('irreversibleDirectoryAction')}
                />
              </>
            </Modal>
          )}
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <>
            <CustomModal
              handleClose={handleCloseModal}
              handleSave={handleSave}
              title={t('cloudToAgora')}
              warningText={t('irreversibleAction')}
            />
          </>
        </Modal>
      </Box>
      {isLoadResource ? (
        <Box
          style={{
            backgroundColor: '#0a09098f',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '130px',
          }}
        >
          <LoadingSpinner className={loaderStyles.loadingResourceSpinner} />
        </Box>
      ) : null}
      <Box>
        <Modal
          open={badResoursesOpen}
          onClose={handleCloseModalBadResources}
          className={style.eventModal}
        >
          <CustomModal
            title={badResoursesTitle}
            handleClose={handleCloseModalBadResources}
            warningText={badResoursesMessage}
            handleSave={() => {}}
            noButton={true}
          />
        </Modal>
      </Box>
    </>
  )
}

const searcherQuery = (
  svs: SearchValue<FileQuery>[]
): QueryParam<FileQuery>[] | QueryParamN<FileQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
