import { Query } from '../../../common/api/Query'
import { Message } from './Message'
import { v4 as uuidv4 } from 'uuid'

export type UserConfigProps = {
  id?: string
  sourceType: number
  transportType: number
  userID: string
}

export class UserConfig {
  private readonly _id: string
  private readonly _sourceType: number
  private readonly _transportType: number
  private readonly _userID: string

  constructor(p: UserConfigProps) {
    this._id = p.id ?? uuidv4()
    this._sourceType = p.sourceType
    this._transportType = p.transportType
    this._userID = p.userID
  }

  get id(): string {
    return this._id
  }

  get sourceType(): number {
    return this._sourceType
  }

  get transportType(): number {
    return this._transportType
  }

  get userID(): string {
    return this._userID
  }
}

export class UserConfigQuery extends Query<Message> {}
