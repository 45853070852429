import { Container } from '../../common/container/Container'

export type CircleAssignResourcesContainerConfig = {
  moduleFullUrl: string
}

export type CircleAssignResourcesContainerConfigProps = {
  parentContainer: Container
  config: CircleAssignResourcesContainerConfig
}

export const CIRCLE_ASSIGN_RESOURCES_MODULE = Symbol('CIRCLE_ASSIGN_RESOURCES_MODULE')

export const CIRCLE_ASSIGN_RESOURCES_API_KEY = Symbol('CIRCLE_ASSIGN_RESOURCES_API_KEY')

export const CIRCLE_ASSIGN_RESOURCES_SERVICE_KEY = Symbol('CIRCLE_ASSIGN_RESOURCES_SERVICE_KEY')
