import { ConfidentialityTerm } from './ConfidentialityTerm'
import { v4 as uuidv4 } from 'uuid'

export interface ConfidentialityTermDTO {
  id: string
  userID: string
  date: Date
  accepted: boolean
}

export function emptyConfidentialityTermDTO() {
  return {
    id: uuidv4(),
    userID: '',
    date: new Date(),
    accepted: true,
  }
}

export function fromModel(s: ConfidentialityTerm): ConfidentialityTermDTO {
  return {
    id: s.id,
    userID: s.userID,
    date: s.date,
    accepted: s.accepted,
  }
}

export function toModel(s: ConfidentialityTermDTO): ConfidentialityTerm {
  return new ConfidentialityTerm(s)
}
