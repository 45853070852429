import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  step: {
    width: window.innerWidth > 1551 ? '100px' : '80px',
    height: window.innerWidth > 1551 ? '100px' : '80px',
    color: '#fff',
    border: '8px solid #e9e9e9',
    borderRadius: '100%',
    zIndex: 1,
    '& .MuiStepIcon-text': {
      fontSize: window.innerWidth > 1551 ? '1rem' : '0.8rem',
      fontWeight: 'bold',
      fontFamily: 'Poppins, sans-serif',
      fill: '#e9e9e9',
    },

    '& .MuiStep-root.MuiStep-horizontal': {
      padding: 0,
    },
    '&$active': {
      color: '#fff',
      border: '8px solid #68b3e0',
      borderRadius: '100%',
      '& .MuiStepIcon-text': {
        fontSize: window.innerWidth > 1551 ? '1rem' : '0.8rem',
        fontWeight: 'bold',
        fontFamily: 'Poppins, sans-serif',
        fill: '#68b3e0',
      },
    },
    '&$completed': {
      // color: '#fff',
      border: '0px solid #0163a6',
      borderRadius: '100%',
      '& .MuiStepIcon-text': {
        fontSize: '1rem',
        fontWeight: 'bold',
        fontFamily: 'Poppins, sans-serif',
      },
    },
    '&$disabled': {
      color: 'red',
    },
  },
  stepper: {
    marginRight: window.innerWidth > 1551 ? '266px' : '216px',
    marginLeft: window.innerWidth > 1551 ? '266px' : '216px',
    marginBottom: '50px',
  },
  alternativeLabel: {},
  active: {}, // needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    // padding: 10,
    position: 'absolute',
    margin: '60px',
    marginTop: window.innerWidth > 1551 ? '40px' : '20px',
    marginLeft: window.innerWidth > 1551 ? '60px' : 90,
    width: window.innerWidth > 1551 ? 'auto' : '140px',
    '& .MuiStepLabel-label': {
      marginTop: '80px',
      fontFamily: 'Poppins, sans-serif',
      fontSize: window.innerWidth > 1551 ? '1rem' : '0.8rem',
      color: '#bfbfbf',
    },
    '&$alternativeLabel': {},
    '&$active': {
      color: '#68b3e0',
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: '#68b3e0',
    },
    '& .MuiStepLabel-label.MuiStepLabel-completed': {
      color: '#0163a6',
    },
    '& .MuiStepLabel-iconContainer': {},
    '& .MuiStep-root.MuiStep-horizontal': {},
    '& .MuiStep-horizontal': {},
  },
  icon: {
    padding: 0,
  },
  connectorLine: {
    borderColor: 'red',
  },
})
