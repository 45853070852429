import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { TrainingContainerConfig } from '../container'
import { toModel } from '../models/TrainingDTO'
import { Training } from '../models/Training'
import { TrainingDTO } from '../models/TrainingDTO'

export interface ITrainingApi extends IInit {
  getByID(id: string): Observable<Training | undefined>

  getFilteredList(q: Query<Training>): Observable<ItemList<Training>>

  add(e: TrainingDTO): Observable<Training | undefined>

  update(e: TrainingDTO): Observable<Training | undefined>

  delete(id: string): Observable<boolean>
}

export class TrainingApi implements ITrainingApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as TrainingContainerConfig).moduleFullUrl
  }

  add(e: TrainingDTO): Observable<Training | undefined> {
    return this._httpClient.post<Training>({ url: this._url, body: e }).pipe(
      map<TrainingDTO, Training>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Training | undefined> {
    return this._httpClient.get<Training>({ url: `${this._url}/${id}` }).pipe(
      map<TrainingDTO, Training>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<Training>): Observable<ItemList<Training>> {
    return this._httpClient.get<ItemList<Training>>({ url: prepareURL(`${this._url}`, q) }).pipe(
      map<ItemList<TrainingDTO>, ItemList<Training>>((dto) => {
        const itemList = emptyList<Training>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Training>())
      })
    )
  }

  update(e: TrainingDTO): Observable<Training | undefined> {
    return this._httpClient.put<Training>({ url: this._url, body: e }).pipe(
      map<TrainingDTO, Training>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
