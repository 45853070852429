import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Symptom, SymptomQuery } from '../models/Symptom'
import { IStatusService } from '../../../common/status/StatusService'
import { SymptomApi } from '../api/SymptomApi'
import { SymptomDTO } from '../models/SymptomDTO'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface ISymptomService extends IInit {
  getByID(id: string | null): Observable<Symptom | undefined>

  getFilteredList(q: Query<SymptomQuery>): Observable<ItemList<Symptom>>

  getFilteredItems(q: Query<SymptomQuery>): Observable<Symptom[]>

  add(e: SymptomDTO): Observable<Symptom | undefined>

  update(e: SymptomDTO): Observable<Symptom | undefined>

  delete(id: string): Observable<boolean>
}

export class SymptomService implements ISymptomService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: SymptomApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<SymptomApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string): Observable<Symptom | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<SymptomQuery>): Observable<ItemList<Symptom>> {
    return this._api.getFilteredList(q).pipe()
  }

  getFilteredItems(q: Query<SymptomQuery>): Observable<Symptom[]> {
    return this._api.getFilteredItems(q).pipe()
  }

  add(e: SymptomDTO): Observable<Symptom | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  update(e: SymptomDTO): Observable<Symptom | undefined> {
    return this._api.update(e)
  }
}
