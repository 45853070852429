import { v4 as uuidv4 } from 'uuid'
import { AttendanceType } from '../enum/AttendanceType'
import { Training } from './Training'

export interface TrainingDTO {
  id: string
  groupID: string
  userCircleID: string
  startTime: Date
  finishTime: Date
  attendance: AttendanceType
  intensity: number
  physicalCondition: number
}

export function emptyTrainingDTO() {
  return {
    id: uuidv4(),
    groupID: '',
    userCircleID: '',
    startTime: new Date(),
    finishTime: new Date(),
    attendance: AttendanceType.absence,
    intensity: 0,
    physicalCondition: 0,
  }
}

export function fromModel(e: Training): TrainingDTO {
  return {
    id: e.id,
    groupID: e.groupID,
    userCircleID: e.userCircleID,
    startTime: e.startTime,
    finishTime: e.finishTime,
    attendance: e.attendance,
    intensity: e.intensity,
    physicalCondition: e.physicalCondition,
  }
}

export function toModel(d: TrainingDTO): Training {
  return new Training(d)
}
