import { RouteProps } from '../../routes/AppRouter'
import { ResourcesForm as RF } from '../../features/resource-patientView'
import { ROUTE_CREATE } from '../../routes/routes-constants'
import { useLocation, useParams } from 'react-router-dom'
import React from 'react'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

type ResourcesFormProps = {
  parentID?: string | null
  breadcrumbUrl?: Map<string, string>
  isDir?: boolean | null
} & RouteProps

export function ResourcesForm(props: ResourcesFormProps) {
  const { id } = useParams()
  const location = useLocation()

  const title = props.title ?? ''
  const state: ResourcesFormProps = location?.state as ResourcesFormProps

  return (
    <>
      <CurrentNavHeaderEsfera title={state?.title ?? title} />
      <RF
        id={id === ROUTE_CREATE ? undefined : id}
        isDir={state?.isDir ?? false}
        parentID={state?.parentID ?? null}
        breadcrumbUrl={state?.breadcrumbUrl}
      />
    </>
  )
}
