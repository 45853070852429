import { Container, IInit } from '../../../common/container/Container'
import { Circle, CircleQuery } from '../models/Circle'
import { CircleContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { CircleDTO, toModel } from '../models/CircleDTO'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { prepareURL } from '../../../common/api/http-helpers'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { CircleWithCreator, CircleWithSpecialtyAndCreatorQuery } from '../models/CircleWithCreator'
import {
  CircleAndDraftList,
  CircleWithCreatorDTO,
  toModel as toModel2,
} from '../models/CircleWithCreatorDTO'

export interface ICircleApi extends IInit {
  getByID(id: string): Observable<Circle | undefined>

  getByCircleDraftID(id: string): Observable<Circle | undefined>

  getFilteredList(q: Query<CircleQuery>): Observable<ItemList<Circle>>

  getFilteredListAllCirclesWithSpecialtyAndCreator(
    q: Query<CircleWithSpecialtyAndCreatorQuery>
  ): Observable<ItemList<CircleWithCreator>>

  getFilteredListAllCirclesAndDraftsWithSpecialtyAndCreator(
    q: Query<CircleWithSpecialtyAndCreatorQuery>
  ): Observable<CircleAndDraftList>

  getAllCirclesWithSpecialtyAndCreator(): Observable<ItemList<CircleWithCreator>>

  add(e: CircleDTO): Observable<Circle | undefined>

  update(e: CircleDTO): Observable<Circle | undefined>

  delete(id: string): Observable<boolean>

  addCircleDraft(e: CircleDTO): Observable<Circle | undefined>

  updateCircleDraft(e: CircleDTO): Observable<Circle | undefined>

  deleteCircleDraft(id: string): Observable<boolean>

  saveCircleDraft(id: string): Observable<boolean> //convert circleDraft to circle
}

export class CircleApi implements ICircleApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as CircleContainerConfig).moduleFullUrl
  }

  add(e: CircleDTO): Observable<Circle | undefined> {
    return this._httpClient.post<Circle>({ url: this._url + '/circle', body: e }).pipe(
      map<CircleDTO, Circle>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/circle/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  addCircleDraft(e: CircleDTO): Observable<Circle | undefined> {
    return this._httpClient.post<Circle>({ url: this._url + '/circleDraft', body: e }).pipe(
      map<CircleDTO, Circle>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  deleteCircleDraft(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/circleDraft/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Circle | undefined> {
    return this._httpClient.get<Circle>({ url: `${this._url}/circle/${id}` }).pipe(
      map<CircleDTO, Circle>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getAllCirclesWithSpecialtyAndCreator(): Observable<ItemList<CircleWithCreator>> {
    return this._httpClient
      .get<ItemList<CircleWithCreator>>({
        url: `${this._url}/circles-with-specialty-and-creator`,
      })
      .pipe(
        map<ItemList<CircleWithCreatorDTO>, ItemList<CircleWithCreator>>((dto) => {
          const itemList = emptyList<CircleWithCreator>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel2(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<CircleWithCreator>())
        })
      )
  }

  getFilteredListAllCirclesWithSpecialtyAndCreator(
    q: Query<CircleWithSpecialtyAndCreatorQuery>
  ): Observable<ItemList<CircleWithCreator>> {
    return this._httpClient
      .get<ItemList<CircleWithCreator>>({
        url: prepareURL(`${this._url}/circles-with-specialty-and-creator-list`, q),
      })
      .pipe(
        map<ItemList<CircleWithCreatorDTO>, ItemList<CircleWithCreator>>((dto) => {
          const itemList = emptyList<CircleWithCreator>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel2(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<CircleWithCreator>())
        })
      )
  }

  getFilteredListAllCirclesAndDraftsWithSpecialtyAndCreator(
    q: Query<CircleWithSpecialtyAndCreatorQuery>
  ): Observable<CircleAndDraftList> {
    return this._httpClient
      .get<CircleAndDraftList>({
        url: prepareURL(`${this._url}/circles-and-draft-with-specialty-and-creator-list`, q),
      })
      .pipe((circleAndDraftsList) => {
        return circleAndDraftsList
      })
  }

  getFilteredList(q: Query<CircleQuery>): Observable<ItemList<Circle>> {
    return this._httpClient
      .get<ItemList<Circle>>({ url: prepareURL(`${this._url}/circle`, q) })
      .pipe(
        map<ItemList<CircleDTO>, ItemList<Circle>>((dto) => {
          const itemList = emptyList<Circle>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Circle>())
        })
      )
  }

  update(e: CircleDTO): Observable<Circle | undefined> {
    return this._httpClient.put<Circle>({ url: this._url + '/circle', body: e }).pipe(
      map<CircleDTO, Circle>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  updateCircleDraft(e: CircleDTO): Observable<Circle | undefined> {
    return this._httpClient.put<Circle>({ url: this._url + '/circleDraft', body: e }).pipe(
      map<CircleDTO, Circle>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByCircleDraftID(id: string): Observable<Circle | undefined> {
    return this._httpClient.get<Circle>({ url: `${this._url}/circleDraft/${id}` }).pipe(
      map<CircleDTO, Circle>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  saveCircleDraft(id: string): Observable<boolean> {
    return this._httpClient
      .put<boolean>({ url: `${this._url}/circleDraft/save-circle-draft/${id}` })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(false)
        })
      )
  }
}
