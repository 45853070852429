import { Container, ContainerItem } from '../common/container/Container'
import { ConfidentialityTermService } from '../modules/confidentialityTerm/services/ConfidentialityTermService'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  CONFIDENTIALITY_TERM_API_KEY,
  CONFIDENTIALITY_TERM_MODULE,
  CONFIDENTIALITY_TERM_SERVICE_KEY,
  ConfidencialityTermProps,
} from '../modules/confidentialityTerm/container'
import { ConfidentialityTermApi } from '../modules/confidentialityTerm/api/ConfidentialityTermApi'

let container: Container

function init(p: ConfidencialityTermProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(
    CONFIDENTIALITY_TERM_API_KEY,
    new ContainerItem({ instance: new ConfidentialityTermApi() })
  )

  items.set(
    CONFIDENTIALITY_TERM_SERVICE_KEY,
    new ContainerItem({
      instance: new ConfidentialityTermService({ apiKey: CONFIDENTIALITY_TERM_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getConfidentialityTermContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[CONFIDENTIALITY_TERM_MODULE],
      },
    })
  }

  return container
}
