import { VisitAudit } from './VisitAudit'
import { v4 as uuidv4 } from 'uuid'

export interface VisitAuditDTO {
  id: string | undefined
  circleID: string
  professionalID: string
  date: Date
}

export function emptyVisitAuditDTO() {
  return {
    id: uuidv4(),
    circleID: '',
    professionalID: '',
    date: new Date(),
  }
}

export function fromModel(s: VisitAudit): VisitAuditDTO {
  return {
    id: s.id,
    circleID: s.circleID,
    professionalID: s.professionalID,
    date: s.date,
  }
}

export function toModel(s: VisitAuditDTO): VisitAudit {
  return new VisitAudit(s)
}
