import { Setting } from './Setting'
import { v4 as uuidv4 } from 'uuid'
import { SettingType } from '../enums/SettingType'

export interface SettingDTO {
  id: string | undefined
  name: string
  type: SettingType
  minValue: number
  maxValue: number
}

export function emptySettingDTO() {
  return {
    id: uuidv4(),
    name: '',
    type: SettingType.File,
    minValue: 0,
    maxValue: 0,
  }
}

export function fromModel(s: Setting): SettingDTO {
  return {
    id: s.id,
    name: s.name,
    type: s.type,
    minValue: s.minValue,
    maxValue: s.maxValue,
  }
}

export function toModel(s: SettingDTO): Setting {
  return new Setting(s)
}
