import dayjs from 'dayjs'

export function dateToDateString(date: Date): string {
  return date.toLocaleDateString()
}

export function dateToTimeString(date: Date): string {
  return date.toLocaleTimeString()
}

export function dateToTimeWithoutSecondsString(date: Date): string {
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}

export function dateToTimeOnlyHoursString(date: Date): string {
  return date.toLocaleTimeString([], { hour: '2-digit' })
}

export function dateToDateTimeString(date: Date): string {
  return date.toLocaleString()
}

export function dateToDateTimeOnlyHourString(date: Date): string {
  return date.toLocaleString([], {
    hour: '2-digit',
    minute: '2-digit',
  })
}

export function dateToDateTimeOnlyDayString(date: Date): string {
  return date.toLocaleString('es-ES', {
    weekday: 'long',
  })
}

export function dateToDateTimeWeekMonthString(date: Date): string {
  return date.toLocaleString([], {
    month: '2-digit',
    day: '2-digit',
  })
}
export function dateToDateTimeWithoutSecondsString(date: Date): string {
  return date.toLocaleString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })
}

export function dateToYYYYMMDD(date: Date): string {
  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  let finalMonth: string
  let finalDay: string

  if (month < 10) {
    finalMonth = `0${month}`
  } else {
    finalMonth = `${month}`
  }

  if (day < 10) {
    finalDay = `0${day}`
  } else {
    finalDay = `${year}-${month}-${day}`
  }

  return `${year}-${finalMonth}-${finalDay}`
}

export function formatDate(date: string): string {
  const dmy = date.split('/')
  const birthDate = new Date(Number(dmy[2]), Number(dmy[1]) - 1, Number(dmy[0]))
  return dayjs(birthDate).format('DD/MM/YYYY')
}

export function formatBirthDate(date: string): Date {
  const dmy = date.split('/')
  const birthDate = new Date(Number(dmy[2]), Number(dmy[1]) - 1, Number(dmy[0]))
  return new Date(dayjs(birthDate).format('YYYY-MM-DD'))
}

export function dateToAge(date: string): number {
  const dmy = date.split('/')
  const birthDate = new Date(Number(dmy[2]), Number(dmy[1]) - 1, Number(dmy[0]))
  const today = new Date()
  const age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1
  }
  return age
}

export function dateDateToAge(date: Date): number {
  const today = new Date()
  const age = today.getFullYear() - date.getFullYear()
  const m = today.getMonth() - date.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
    return age - 1
  }
  return age
}

export const getMonday = (d: Date) => {
  const date = new Date(d)
  let day = date.getDay(),
    diff = date.getDate() - day + (day == 0 ? -6 : 1)
  return new Date(date.setDate(diff))
}

export const getFirstDayOfMonth = (d: Date) => {
  return new Date(d.getMonth())
}

export const twoDatesToMonths = (d: Date, dob: Date): string => {
  const diff = Math.abs(d.getTime() - dob.getTime())
  const months = Math.floor(diff / (1000 * 3600 * 24 * 30))
  if (months >= 24) return monthsToYears(months)
  return months + ' meses'
}

export const monthsToYears = (months: number): string => {
  return Math.floor(months / 12) + ' años'
}

export const numberToYears = (number: number): string => {
  if (number < 0) return 'Invalida'
  if (number == 0) return 'Nacimiento'
  if (number % 12 == 0) {
    if (number / 12 == 1) return '1 año'
    return `${number / 12} años`
  }
  return ''
}
