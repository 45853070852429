import { FormField } from './FormField'
import { Form } from './Form'
import { FormFieldType } from '../enums/FormFieldType'
import { v4 as uuidv4 } from 'uuid'
import { FormType } from '../enums/FormType'

export interface FormDTO {
  id: string | undefined
  title: string
  description: string
  formFields: FormFieldDTO[]
  repeating: string
  startCron: Date
  finishCron: Date
  fieldWithoutAnswer: string
  created: Date
  isTemplate: FormType
  creator: string
  specialities: string
  circles: string
  checkBoxesSelected?: string
  monthRecurrence: number
  subscribers?: string | undefined
}

export function emptyFormDTO(): FormDTO {
  const id = uuidv4()
  return {
    id,
    title: '',
    description: '',
    formFields: [emptyFormFieldDTO(0, id)],
    repeating: '0 0 0 0 0',
    startCron: new Date(),
    finishCron: new Date(),
    fieldWithoutAnswer: '',
    created: new Date(),
    isTemplate: FormType.Form,
    creator: '',
    specialities: '',
    circles: '',
    checkBoxesSelected: '',
    monthRecurrence: 1,
    subscribers: '',
  }
}

export function emptyTemplateDTO(): FormDTO {
  const id = uuidv4()
  return {
    id,
    title: '',
    description: '',
    formFields: [emptyFormFieldDTO(0, id)],
    repeating: '0 0 0 0 0',
    startCron: new Date(),
    finishCron: new Date(),
    fieldWithoutAnswer: '',
    created: new Date(),
    isTemplate: FormType.Template,
    creator: '',
    specialities: '',
    circles: '',
    checkBoxesSelected: '',
    monthRecurrence: 1,
  }
}

export function fromModel(f: Form): FormDTO {
  return {
    id: f.id,
    title: f.title,
    description: f.description,
    formFields: f.formFields?.map((ff) => fromModelFormField(ff, f.id)),
    repeating: f.repeating,
    startCron: f.startCron,
    finishCron: f.finishCron,
    fieldWithoutAnswer: f.fieldWithoutAnswer,
    created: f.created,
    isTemplate: f.isTemplate,
    creator: f.creator,
    specialities: f.specialities,
    circles: f.circles,
    checkBoxesSelected: f.checkBoxesSelected,
    monthRecurrence: f.monthRecurrence,
    subscribers: f.subscribers,
  }
}

export function toModel(d: FormDTO): Form {
  return new Form({
    description: d.description,
    formFields: d.formFields.map((ff) => toModelFormField(ff)),
    id: d.id,
    repeating: d.repeating,
    startCron: d.startCron,
    finishCron: d.finishCron,
    title: d.title,
    fieldWithoutAnswer: d.fieldWithoutAnswer,
    created: d.created,
    isTemplate: d.isTemplate,
    creator: d.creator,
    specialities: d.specialities,
    circles: d.circles,
    checkBoxesSelected: d.checkBoxesSelected,
    monthRecurrence: d.monthRecurrence,
    subscribers: d.subscribers,
  })
}

export interface FormFieldDTO {
  id: string
  formID: string | undefined
  title: string
  type: FormFieldType
  required: boolean
  order: number
  optionValues: string[]
  score: string[]
  correctValues: string | Function | string[] | undefined
}

export function emptyFormFieldDTO(order: number, formId: string): FormFieldDTO {
  return {
    id: uuidv4(),
    formID: formId,
    title: '',
    type: FormFieldType.TextArea,
    required: false,
    order,
    optionValues: [],
    correctValues: [],
    score: [] || undefined,
  }
}

export function fromModelFormField(ff: FormField, formID: string | undefined): FormFieldDTO {
  return {
    id: ff.id,
    formID,
    title: ff.title,
    type: ff.type,
    required: ff.required,
    order: ff.order,
    optionValues: ff.getOptionsValues(),
    correctValues: ff.getCorrectValues(),
    score: ff.score,
  }
}

export function toModelFormField(d: FormFieldDTO): FormField {
  return new FormField({
    correctValues: d.correctValues,
    formID: d.formID,
    id: d.id,
    optionValues: d.optionValues[0].split(';'),
    order: d.order,
    required: d.required,
    title: d.title,
    type: d.type,
    score: d.score,
  })
  // return new FormField(d)
}
