import { CityHallContact } from './CityHallContact'
import { v4 as uuidv4 } from 'uuid'

export interface CityHallContactDTO {
  id: string
  name: string
  email: string
  phone: string
}

export function emptyCityHallContactDTO() {
  return {
    id: uuidv4(),
    name: '',
    email: '',
    phone: '',
  }
}

export function fromModel(e: CityHallContact): CityHallContactDTO {
  return {
    id: e.id,
    name: e.name,
    email: e.email,
    phone: e.phone,
  }
}

export function toModel(d: CityHallContactDTO): CityHallContact {
  return new CityHallContact(d)
}
