export type LandmarkProps = {
  id: string | undefined
  title: string
  date: Date
  endDate: Date
  state: boolean
  userID: string
}

export class Landmark {
  private readonly _id: string | undefined
  private _title: string
  private _date: Date
  private _endDate: Date
  private _state: boolean
  private readonly _userID: string

  constructor(p: LandmarkProps) {
    this._id = p.id
    this._title = p.title
    this._date = new Date(p.date)
    this._endDate = new Date(p.endDate)
    this._state = p.state
    this._userID = p.userID
  }

  get id(): string | undefined {
    return this._id
  }

  get title(): string {
    return this._title
  }

  set title(value: string) {
    this._title = value
  }

  get date(): Date {
    return this._date
  }

  set date(value: Date) {
    this._date = value
  }

  get endDate(): Date {
    return this._endDate
  }

  set endDate(value: Date) {
    this._endDate = value
  }

  get state(): boolean {
    return this._state
  }

  set state(value: boolean) {
    this._state = value
  }

  get userID(): string {
    return this._userID
  }
}

export interface LandMarkQuery {
  userID: string
  title: string
}
