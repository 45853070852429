import { Header } from '../../../../components/header/Header'
import { Box, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from '.././AddNewCircle.module.css'
import exitIcon from '../../../../assets/newCircle/exit_icon.svg'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import { Search, SearchValue, Pager } from '../../../../components/table_type/types'
import { UserQuery } from '../../../../modules/users/models/User'
import { Searcher } from '../../../../components/table/Searcher'
import { AppTable } from '../AppTable'
import { Field } from 'components/table'
import { FORM_SERVICE_KEY } from '../../../../modules/forms'
import { Form, FormQuery } from '../../../../modules/forms/models/Form'
import { Query, QueryParam } from '../../../../common/api/Query'
import { FormType } from '../../../../modules/forms/enums/FormType'
import { FormService } from '../../../../modules/forms/services/FormService'
import { getFormContainer } from '../../../../container/form-module'
import { CircleDTO } from '../../../../modules/circle/models/CircleDTO'
import {
  CircleAssignFormDTO,
  emptyCircleAssignFormsDTO,
  fromModel,
} from '../../../../modules/circle-assign-form/models/CircleAssignFormDTO'
import { getCircleAssignFormsContainer } from '../../../../container/circle-assign-form-module'
import {
  CIRCLE_ASSIGN_FORMS_SERVICE_KEY,
  ICircleAssignFormsService,
} from '../../../../modules/circle-assign-form'

interface Props {
  open: boolean
  onClose: () => void
  circleDraft: CircleDTO | undefined
}

const formService = getFormContainer().get<FormService>(FORM_SERVICE_KEY)
const circleAssignFormService = getCircleAssignFormsContainer().get<ICircleAssignFormsService>(
  CIRCLE_ASSIGN_FORMS_SERVICE_KEY
)
const ModalFormsFromOtherCircles: React.FC<Props> = ({ open, onClose, circleDraft }) => {
  const { t } = useTranslation()

  const [selectedForms, setSelectedForms] = useState<Record<string, boolean>>({})
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [formsPerPage, setFormsPerPage] = useState<number>(3)
  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'allFields',
      label: t(''),
      value: '',
    },
  ])
  const [allForms, setAllForms] = useState<Form[]>([])

  const [filteredForms, setFilteredForms] = useState<Form[]>([])
  const [circleAssignForm, setCircleAssignForm] = useState<CircleAssignFormDTO>(
    emptyCircleAssignFormsDTO()
  )

  useEffect(() => {
    if (!open) return
    if (!circleDraft) return
    formService
      .getFilteredList(
        new Query<FormQuery>({
          query: [
            new QueryParam<FormQuery>('isTemplate', FormType.Form.toString()),
            new QueryParam<FormQuery>('fillWithCreatorName', 'true'),
          ],
          sort: [{ field: 'TITLE', desc: false }],
          pager: { offset: page * formsPerPage, limit: formsPerPage },
        })
      )
      .subscribe((res) => {
        const otherCirclesForms = res.items.filter((form) => form.circles !== circleDraft.id)
        setAllForms(otherCirclesForms)
        setFilteredForms(otherCirclesForms)
      })

    circleAssignFormService.getByCircleID(circleDraft.id).subscribe((res) => {
      if (!res) return
      setCircleAssignForm(fromModel(res))
    })
  }, [open, page, formsPerPage])

  useEffect(() => {
    if (searcher[0].value === '') {
      setFilteredForms(allForms)
    } else {
      formService
        .getFilteredList(
          new Query<FormQuery>({
            query: [
              new QueryParam<FormQuery>('isTemplate', FormType.Form.toString()),
              new QueryParam<FormQuery>('fillWithCreatorName', 'true'),
              new QueryParam<FormQuery>('title', searcher[0].value ?? ''),
            ],
            sort: [{ field: 'TITLE', desc: false }],
            pager: { offset: page * formsPerPage, limit: formsPerPage },
          })
        )
        .subscribe((res) => {
          const otherCirclesForms = res.items.filter((form) => form.circles !== circleDraft?.id)
          setFilteredForms(otherCirclesForms)
          setCount(res.count)
        })
    }
  }, [searcher])

  const tableFields: Field<Form>[] = [
    {
      name: 'title',
      label: t('name'),
    },
    { name: 'specialities', label: t('specialty') },
    { name: 'creator', label: t('author') },
    {
      name: 'created',
      label: t('date'),
      renderFunc: (item, form) => {
        return <p>{new Date(form.created).toLocaleDateString()}</p>
      },
    },
  ]

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: formsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, formsPerPage, searcher, open, count])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setFormsPerPage(10)
      return
    }
    setFormsPerPage(Number.parseInt(event.target.value))
  }

  function handleSave() {
    const selectedFormsKeys = Object.keys(selectedForms)
    const selectedFormsToSave: string[] = []
    selectedFormsKeys.forEach((key) => {
      if (selectedForms[key]) {
        selectedFormsToSave.push(key)
      }
    })
    const circleAssignFormServiceUpdated = circleAssignForm
    selectedFormsToSave.forEach((formID) => {
      if (!circleAssignFormServiceUpdated.formsID.includes(formID)) {
        circleAssignFormServiceUpdated.formsID.push(formID)
      }
    })
    circleAssignFormService.update(circleAssignFormServiceUpdated).subscribe((res) => {
      onClose()
    })
  }

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => setSearcher(svs),
  }

  return (
    <Modal open={open} onClose={onClose} className={style.modalOverFlow}>
      <Box className={style.modalContainer}>
        <Box style={{ margin: '16px' }}>
          <Box className={style.contentHeaderExitIcon}>
            <img
              style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
              onClick={onClose}
              src={exitIcon}
            />
          </Box>
          <Box className={style.contentHeader}>
            <Header label={t('formsFromOthersCircle')} />
          </Box>
          <Box>
            <Box sx={{ alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <p>{t('search')}</p>
                <Searcher search={search} />
              </Box>
              <Box
                style={{
                  height: '400px',
                  overflowY: 'auto',
                  marginLeft: '32px',
                  marginRight: '32px',
                }}
              >
                <AppTable
                  checked={selectedForms}
                  handlerChecked={(key) => {
                    const selectedFormsAux = { ...selectedForms }
                    selectedFormsAux[key] = !selectedFormsAux[key]
                    setSelectedForms(selectedFormsAux)
                  }}
                  fields={tableFields}
                  items={filteredForms}
                  rowKeyField={'id'}
                  pager={pager}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '36px' }}>
            <Box>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('save')}
                marginStartIcon={{ marginRight: '10px' }}
                handler={handleSave}
                vertical={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalFormsFromOtherCircles
