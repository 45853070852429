import { Header } from '../../../../components/header/Header'
import { Box, Modal, Divider, Card, Grid, Chip } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from '.././AddNewCircle.module.css'
import exitIcon from '../../../../assets/newCircle/exit_icon.svg'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import styles from '../../../patient-data/PatientData.module.css'
import { ProfessionalType } from '../../../../modules/users/models/ProfessionalType'
import { getUserContainer } from '../../../../container/user-module'
import { ProfessionalTypesService } from '../../../../modules/users/services/ProfessionalTypeService'
import { PROFESSIONALTYPE_SERVICE_KEY } from '../../../../modules/users'
import { Query, QueryParam } from '../../../../common/api/Query'
import { FORM_SERVICE_KEY } from '../../../../modules/forms'
import { FormService } from '../../../../modules/forms/services/FormService'
import { getFormContainer } from '../../../../container/form-module'
import { Form, FormQuery } from '../../../../modules/forms/models/Form'
import { FormType } from '../../../../modules/forms/enums/FormType'
import { getCircleAssignFormsContainer } from '../../../../container/circle-assign-form-module'
import { CircleAssignFormsService } from '../../../../modules/circle-assign-form/services/CircleAssignFormsService'
import { CIRCLE_ASSIGN_FORMS_SERVICE_KEY } from '../../../../modules/circle-assign-form'
import {
  CircleAssignFormDTO,
  emptyCircleAssignFormsDTO,
  fromModel,
} from '../../../../modules/circle-assign-form/models/CircleAssignFormDTO'

interface Props {
  open: boolean
  onClose: () => void
  circleDraftID: string
}

const generalMedicineTemplateName = 'Medicina General'

const ModalFormsFromOtherCircles: React.FC<Props> = ({ open, onClose, circleDraftID }) => {
  const { t } = useTranslation()
  const professionalTypeService = getUserContainer().get<ProfessionalTypesService>(
    PROFESSIONALTYPE_SERVICE_KEY
  )
  const circleAssignFormsService = getCircleAssignFormsContainer().get<CircleAssignFormsService>(
    CIRCLE_ASSIGN_FORMS_SERVICE_KEY
  )
  const formService = getFormContainer().get<FormService>(FORM_SERVICE_KEY)
  const [isChecked, setIsChecked] = useState(false)
  const [specialities, setSpecialities] = useState<ProfessionalType[]>([])
  const [selectedSpeciality, setSelectedSpeciality] = useState<string>('')
  const [selectedSpecialtyTemplates, setSelectedSpecialtyTemplates] = useState<Form[]>([])
  const [generalMedicineTemplates, setGeneralMedicineTemplates] = useState<Form[]>([])
  const [selectedTemplatesToSave, setSelectedTemplatesToSave] = useState<string[]>([])
  const [circleAssignForm, setCircleAssignForm] = useState<CircleAssignFormDTO>(
    emptyCircleAssignFormsDTO()
  )

  useEffect(() => {
    professionalTypeService.getAll(new Query<ProfessionalType>({})).subscribe((res) => {
      setSpecialities(res)

      formService
        .getFilteredList(
          new Query<FormQuery>({
            query: [
              new QueryParam<FormQuery>('isTemplate', FormType.Template.toString()),
              new QueryParam<FormQuery>('specialities', generalMedicineTemplateName),
            ],
            sort: [{ field: 'TITLE', desc: false }],
          })
        )
        .subscribe((res) => {
          setGeneralMedicineTemplates(res.items)
        })
    })

    if (!circleDraftID) return

    circleAssignFormsService.getByCircleID(circleDraftID).subscribe((res) => {
      setSelectedTemplatesToSave(res?.formsID ?? [])
      if (res) setCircleAssignForm(fromModel(res))
    })
  }, [open])

  useEffect(() => {
    formService
      .getFilteredList(
        new Query<FormQuery>({
          query: [
            new QueryParam<FormQuery>('isTemplate', FormType.Template.toString()),
            new QueryParam<FormQuery>('specialities', selectedSpeciality),
          ],
          sort: [{ field: 'TITLE', desc: false }],
        })
      )
      .subscribe((res) => {
        setSelectedSpecialtyTemplates(res.items)
      })
  }, [selectedSpeciality])

  useEffect(() => {
    setCircleAssignForm({
      ...circleAssignForm,
      circleID: circleDraftID,
      formsID: selectedTemplatesToSave,
    })
  }, [selectedTemplatesToSave])

  const handleSelectTemplateToSave = (template: Form) => {
    const existingTemplate = selectedTemplatesToSave.find((element) => element === template.id)
    setSelectedTemplatesToSave(
      existingTemplate
        ? selectedTemplatesToSave.filter((element) => element !== template.id)
        : [...selectedTemplatesToSave, template.id ?? '']
    )
  }

  function handleSave() {
    if (!circleDraftID) return

    circleAssignFormsService.add(circleAssignForm).subscribe((res) => {
      onClose()
    })
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e?.target.checked || false)
  }
  const handleClick = (specialty: string) => {
    setSelectedSpeciality(specialty)
  }

  return (
    <Modal open={open} onClose={onClose} className={style.modalOverFlow}>
      <Box className={style.modalContainerTemplate}>
        <Box className={style.contentHeaderExitIcon}>
          <img
            style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
            onClick={onClose}
            src={exitIcon}
          />
        </Box>
        <Box className={style.contentHeader}>
          <Header label={t('newFormFromTemplate')} />
        </Box>
        <Box style={{ margin: '0px 12px' }}>
          <Box>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
                width: '100%',
              }}
            >
              {t('generalMedicine')}
              <Divider
                light
                style={{ backgroundColor: '#f18b24', marginTop: 8, height: 3, width: '175px' }}
              />
            </p>
          </Box>
          <Box className={style.centerBlock2}>
            <Grid container spacing={2}>
              {generalMedicineTemplates.map((form) => (
                <Grid key={form.id} xs={6} md={3}>
                  <Card
                    className={
                      selectedTemplatesToSave.find((element) => element === form.id)
                        ? style.selectedCard
                        : style.card
                    }
                    sx={{ borderRadius: 5, borderColor: '#68b3e0' }}
                    variant="outlined"
                    onClick={() => handleSelectTemplateToSave(form)}
                  >
                    <div>
                      <h2 className={style.textBlock} style={{ whiteSpace: 'pre-line' }}>
                        {form.title}
                      </h2>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box>
            <label className={style.checkboxContainer}>
              <input
                type="radio"
                name="otherSpecialities"
                className={styles.check}
                checked={isChecked}
                onChange={(e) => handleCheckboxChange(e)}
              />
              <p
                style={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 'bold',
                  fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                  marginLeft: 0,
                  width: '100%',
                }}
              >
                {t('otherSpecialities')}
              </p>
            </label>
            <Divider
              light
              style={{ backgroundColor: '#f18b24', marginTop: 8, height: 4, width: '235px' }}
            />
          </Box>
          {isChecked && (
            <>
              <Box className={style.otherSpecialityContainer}>
                <Box mr={2}>
                  <p> {t('filterBySpeciality')}: </p>
                </Box>
                <Box>
                  {specialities.map((specialty) =>
                    specialty.name === 'Medicina General' ? null : (
                      <Chip
                        key={specialty.id}
                        sx={chipStyle(specialty, selectedSpeciality)}
                        label={specialty.name}
                        onClick={() => handleClick(specialty.name)}
                      />
                    )
                  )}
                </Box>
              </Box>

              <Box className={style.centerBlock2}>
                <Grid container spacing={2}>
                  {selectedSpecialtyTemplates.map((form) => (
                    <Grid key={form.id} item xs={6} md={3}>
                      <Card
                        className={
                          selectedTemplatesToSave.find((element) => element === form.id)
                            ? style.selectedCard
                            : style.card
                        }
                        sx={{ borderRadius: 5, borderColor: '#68b3e0' }}
                        variant="outlined"
                        onClick={() => handleSelectTemplateToSave(form)}
                      >
                        <div>
                          <h2 className={style.textBlock} style={{ whiteSpace: 'pre-line' }}>
                            {form.title}
                          </h2>
                        </div>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('save')}
                marginStartIcon={{ marginRight: '10px' }}
                handler={handleSave}
                vertical={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

const chipStyle = (specialty: ProfessionalType, selectedSpeciality: string) => {
  return {
    borderRadius: '23px',
    margin: '12px 12px',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    '&:hover': {
      background: specialty.name === selectedSpeciality ? '#ffa448' : 'white',
    },
    ...(specialty.name === selectedSpeciality
      ? {
          background: '#ffa448',
          color: 'white',
        }
      : {
          background: 'white',
          border: '1px solid rgba(219, 218, 218, 0.80)',
        }),
  }
}

export default ModalFormsFromOtherCircles
