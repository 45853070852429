import { ActiveUserContext } from '../../common/utils/active-user-context/ActiveUserContext'
import { useContext } from 'react'

export const useGetActiveUser = () => {
  const context = useContext(ActiveUserContext)
  if (context === undefined) {
    throw new Error('useActiveUser must be used within a ActiveUserContextProvider')
  }
  return context
}
