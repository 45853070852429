import { UserEpilepticalConfigDTO } from '../../modules/epileptical/models/UserEpilepticalConfigDTO'
import { useTranslation } from 'react-i18next'
import { zoneTypes } from '../../modules/epileptical/enums/Zone'
import { durationTypes } from '../../modules/epileptical/enums/Duration'
import { awarenessTypes } from '../../modules/epileptical/enums/Awareness'
import { movementTypes } from '../../modules/epileptical/enums/Movement'
import { visionTypes } from '../../modules/epileptical/enums/Vision'
import { skinColorTypes } from '../../modules/epileptical/enums/SkinColor'
import { symptomTypes } from '../../modules/epileptical/enums/Symptom'
import { mouthTypes } from '../../modules/epileptical/enums/Mouth'
import { afterDurationTypes } from '../../modules/epileptical/enums/AfterDuration'
import React from 'react'
import { Box, createTheme, Divider, ThemeProvider, Typography } from '@mui/material'

type StringTooltipProps = {
  readonly detailConfig: UserEpilepticalConfigDTO[]
  readonly index?: number
  readonly withIndex?: boolean
}
export const StringTooltip = (props: StringTooltipProps) => {
  const { t } = useTranslation()

  const THEME = createTheme({
    typography: {
      fontFamily: `"Poppins", sans-serif`,
    },
  })
  return (
    <Box>
      <ThemeProvider theme={THEME}>
        <Box>
          <p style={{ fontWeight: 'bold', fontFamily: `"Poppins", sans-serif` }}>
            Antes de la crisis: ¿El niño/a ha notado algo?
          </p>
          <Typography style={{ marginLeft: 8 }}>
            -{' '}
            {props.detailConfig.length > 0 && props.detailConfig[0].comment
              ? props.detailConfig[0].comment
              : 'Sin comentarios'}
          </Typography>
          ƒ
        </Box>
        <Divider light style={{ backgroundColor: '#68b3e0', margin: 8 }} />
        <Box>
          <p style={{ fontWeight: 'bold', fontFamily: `"Poppins", sans-serif` }}>
            Durante la crisis
          </p>
          <Typography style={{ marginLeft: 8 }}>
            - {t('zone')}:{' '}
            {props.detailConfig.length > 0 && zoneTypes()[props.detailConfig[0].zone]}
          </Typography>
          <Typography style={{ marginLeft: 8 }}>
            - {t('eventDuration')}:{' '}
            {props.detailConfig.length > 0 && durationTypes()[props.detailConfig[0].duration]}
          </Typography>
          {/*
          <Typography style={{ marginLeft: 8 }}>
          - {t('crisisType')}:{' '}
          {props.detailConfig.length > 0 && crisisTypeTypes()[props.detailConfig[0].crisisType]}
        </Typography>
           */}
          <Typography style={{ marginLeft: 8 }}>
            - {t('awareness')}:{' '}
            {props.detailConfig.length > 0 && awarenessTypes()[props.detailConfig[0].awareness]}
          </Typography>
          <Typography style={{ marginLeft: 8 }}>
            - {t('movement')}:{' '}
            {props.detailConfig.length > 0 && movementTypes()[props.detailConfig[0].movement]}
          </Typography>
          <Typography style={{ marginLeft: 8 }}>
            - {t('vision')}:{' '}
            {props.detailConfig.length > 0 && visionTypes()[props.detailConfig[0].vision]}
          </Typography>
          <Typography style={{ marginLeft: 8 }}>
            - {t('skinColor')}:{' '}
            {props.detailConfig.length > 0 && skinColorTypes()[props.detailConfig[0].skinColor]}
          </Typography>
          <Typography style={{ marginLeft: 8 }}>
            - {t('symptom')}:{' '}
            {props.detailConfig.length > 0 && symptomTypes()[props.detailConfig[0].symptom]}
          </Typography>
          <Typography style={{ marginLeft: 8 }}>
            - {t('mouth')}:{' '}
            {props.detailConfig.length > 0 && mouthTypes()[props.detailConfig[0].mouth]}
          </Typography>
        </Box>
        <Divider light style={{ backgroundColor: '#68b3e0', margin: 8 }} />
        <Box>
          <p style={{ fontWeight: 'bold', fontFamily: `"Poppins", sans-serif` }}>
            Después de la crisis
          </p>
        </Box>
        <Typography style={{ marginLeft: 8 }}>
          - {t('eventDuration')}:{' '}
          {props.detailConfig.length > 0 &&
            afterDurationTypes()[props.detailConfig[0].afterDuration]}
        </Typography>
        {props.detailConfig.length > 0 ? (
          props.detailConfig[0].afterSymptom ? (
            <Typography style={{ marginLeft: 8 }}>
              - {props.detailConfig.length > 0 && props.detailConfig[0].afterSymptom.join(', ')}
            </Typography>
          ) : null
        ) : null}
      </ThemeProvider>
    </Box>
  )
}
