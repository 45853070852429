import i18n from '../../../i18n'

export enum Zone {
  never = -1,
  all = 1,
  right,
  left,
  extremity,
  head,
  undefined,
}

export const zoneTypes = (): Record<Zone, string> => ({
  [Zone.never]: i18n.t('never'),
  [Zone.all]: i18n.t('allBody'),
  [Zone.right]: i18n.t('right'),
  [Zone.left]: i18n.t('left'),
  [Zone.extremity]: i18n.t('extremity'),
  [Zone.head]: i18n.t('head'),
  [Zone.undefined]: i18n.t('undefined'),
})
