import { EpilepticalCrisisDetails } from './EpilepticalCrisisDetails'
import { v4 as uuidv4 } from 'uuid'

export interface EpilepticalCrisisDetailsDTO {
  id: string
  startDate: Date | undefined
  endDate: Date | undefined
  crisisType: number
  color: string
  observations: string
  epilepticalCrisisID: string
}

export function emptyEpilepticalCrisisDetails() {
  return {
    id: uuidv4(),
    startDate: undefined,
    endDate: undefined,
    crisisType: 0,
    color: '',
    observations: '',
    epilepticalCrisisID: '',
  }
}

export function fromModel(f: EpilepticalCrisisDetails): EpilepticalCrisisDetailsDTO {
  return {
    id: f.id,
    startDate: f.startDate,
    endDate: f.endDate,
    crisisType: f.crisisType,
    color: f.color,
    observations: f.observations,
    epilepticalCrisisID: f.epilepticalCrisisID,
  }
}

export function toModel(d: EpilepticalCrisisDetailsDTO): EpilepticalCrisisDetails {
  return new EpilepticalCrisisDetails(d)
}
