import {
  Box,
  Icon,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  SelectChangeEvent,
  Alert,
} from '@mui/material'
import styles from './AddSeeEditSportCenterModal.module.css'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { SportCenterDTO } from '../../modules/sport-center/models/SportCenterDTO'
import { useTranslation } from 'react-i18next'
import { getCityHallContainer } from '../../container/cityHall-module'
import { CityHallService } from '../../modules/cityHall/services/CityHallService'
import { CITY_HALL_SERVICE_KEY } from '../../modules/cityHall'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { CityHall } from '../../modules/cityHall/models/CityHall'
import { Query } from '../../common/api/Query'
import eyeIcon from '../../assets/resource_icons/ico-eye.svg'

type SportCenterInfoProps = {
  sportCenter: SportCenterDTO
  handleInput: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string>
  ) => void
  handlerModalCityHall: () => void
  handlerSendCityHall: (cityHall: CityHall) => void
  errorMessage: string
  blocked?: boolean
}

const cityHallService = getCityHallContainer().get<CityHallService>(CITY_HALL_SERVICE_KEY)

export const SportCenterInfo = (props: SportCenterInfoProps) => {
  const { t } = useTranslation()
  const [cityHall, setCityHall] = useState<ItemList<CityHall>>(emptyList<CityHall>())
  const [currentCityHall, setCurrentCityHall] = useState<CityHall>()

  useEffect(() => {
    cityHallService
      .getFilteredList(
        new Query({
          sort: [{ field: 'name', desc: false }],
        })
      )
      .subscribe((res) => {
        setCityHall(res)
        setCurrentCityHall(res.items.find((value) => value.id === props.sportCenter.cityHallID))
      })
  }, [])

  const handlerSelect = (event: SelectChangeEvent<string>) => {
    props.handleInput(event)
    setCurrentCityHall(cityHall.items.find((value) => value.id === event.target.value))
  }

  const handlerSee = () => {
    if (!currentCityHall) return
    props.handlerModalCityHall()
    props.handlerSendCityHall(currentCityHall)
  }

  return (
    <Box className={styles.sportCenterInfo}>
      <Box className={styles.field}>
        <Typography className={styles.fieldTitle}>{t('name')}</Typography>
        <Box className={styles.inputStyle}>
          <TextField
            key="name"
            variant="outlined"
            name="name"
            value={props.sportCenter.name}
            onChange={props.handleInput}
            inputProps={{ maxLength: 200 }}
            size={'small'}
            fullWidth
            required
            disabled={props.blocked}
          />
        </Box>
      </Box>

      <Box className={styles.field}>
        <Typography className={styles.fieldTitle}>{t('address')}</Typography>
        <Box className={styles.inputStyle}>
          <TextField
            key="location"
            variant="outlined"
            name="location"
            value={props.sportCenter.location}
            onChange={props.handleInput}
            inputProps={{ maxLength: 200 }}
            size={'small'}
            fullWidth
            required
            disabled={props.blocked}
          />
        </Box>
      </Box>
      <Box className={styles.field}>
        <Typography className={styles.fieldTitle}>{t('CP')}</Typography>
        <Box className={styles.inputStyle}>
          <TextField
            key="cp"
            variant="outlined"
            name="postalCode"
            value={props.sportCenter.postalCode}
            onChange={props.handleInput}
            inputProps={{ maxLength: 200 }}
            size={'small'}
            fullWidth
            required
            disabled={props.blocked}
          />
        </Box>
      </Box>
      <Box className={styles.fieldRow}>
        <Box className={styles.subFieldPrimero}>
          <Typography className={styles.fieldTitleLongitude}>{t('longitude')}</Typography>
          <Box className={styles.inputStyle}>
            <TextField
              key="longitude"
              variant="outlined"
              name="longitude"
              value={props.sportCenter.longitude}
              onChange={props.handleInput}
              inputProps={{ maxLength: 100 }}
              size={'small'}
              fullWidth
              required
              disabled={props.blocked}
            />
          </Box>
        </Box>
        <Box className={styles.subField}>
          <Typography className={styles.fieldTitleLongitude}>{t('latitude')}</Typography>
          <Box className={styles.inputStyle}>
            <TextField
              key="latitude"
              variant="outlined"
              name="latitude"
              value={props.sportCenter.latitude}
              onChange={props.handleInput}
              inputProps={{ maxLength: 100 }}
              size={'small'}
              fullWidth
              required
              disabled={props.blocked}
            />
          </Box>
        </Box>
      </Box>

      <Box className={styles.field}>
        <Typography className={styles.fieldTitle}>{t('cityHalls')}</Typography>
        <Box className={styles.inputStyle}>
          <Select
            value={props.sportCenter.cityHallID}
            name="cityHallID"
            size={'small'}
            required
            fullWidth
            onChange={handlerSelect}
            disabled={props.blocked}
            MenuProps={{
              MenuListProps: {
                sx: {
                  'li.MuiButtonBase-root': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    fontFamily: 'Poppins',
                    paddingLeft: '10px',
                  },
                },
              },
            }}
            SelectDisplayProps={{
              style: {
                paddingLeft: '10px',
                fontFamily: 'Poppins',
              },
            }}
          >
            {cityHall.items.map((cityH) => {
              return (
                <MenuItem key={cityH.id} value={cityH.id}>
                  {cityH.name}
                </MenuItem>
              )
            })}
          </Select>
          <IconButton onClick={handlerSee}>
            <Icon className={styles.icon}>
              <img alt="see" src={eyeIcon} className={styles.iconImage} />
            </Icon>
          </IconButton>
        </Box>
      </Box>
      {props.errorMessage && (
        <Box mb={3}>
          <Alert severity="warning" key="errorMessage" id="errorMessage">
            {t(props.errorMessage)}
          </Alert>
        </Box>
      )}
    </Box>
  )
}
