import { Container, ContainerItem } from '../common/container/Container'
import {
  ACTIVE_PLAN_API_KEY,
  ACTIVE_PLAN_SERVICE_KEY,
  ACTIVE_PLAN_MODULE,
  ActivePlanProps,
} from '../modules/active-plan/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { ActivePlanService } from '../modules/active-plan/services/ActivePlanService'
import { ActivePlanApi } from '../modules/active-plan/api/ActivePlanApi'

let container: Container

function init(p: ActivePlanProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(ACTIVE_PLAN_API_KEY, new ContainerItem({ instance: new ActivePlanApi() }))

  items.set(
    ACTIVE_PLAN_SERVICE_KEY,
    new ContainerItem({ instance: new ActivePlanService({ apiKey: ACTIVE_PLAN_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getActivePlanContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[ACTIVE_PLAN_MODULE],
      },
    })
  }

  return container
}
