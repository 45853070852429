import { Box, Button } from '@mui/material'
import React, { useRef } from 'react'
import { Searcher } from './Searcher'
import { Search } from '../table_type/types'

type ActionsProps<Q> = {
  readonly search: Search<Q>
  searchComponent?: boolean
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleOpenEdit?: () => void
  handleDelete: () => void
  handleOpenModalRegister?: () => void
  disabled?: boolean
}

export function ActionsComponent<Q extends Record<string, any>>({
  handleFileSelect,
  disabled,
  search,
}: ActionsProps<Q>) {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  return (
    <>
      <Box>
        <Searcher search={search} />
        <Button
          disabled={disabled}
          onClick={() => {
            fileInputRef.current?.click()
          }}
        >
          <input
            style={{ display: 'none' }}
            ref={fileInputRef}
            type="file"
            onChange={handleFileSelect}
          />
        </Button>
      </Box>
    </>
  )
}
