import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { EpilepticalCrisis } from '../models/EpilepticalCrisis'
import { EpilepticalCrisisDTO, toModel } from '../models/EpilepticalCrisisDTO'
import { EpilepticalCrisisContainerConfig } from '../container'

export interface IEpilepticalCrisisApi extends IInit {
  getByID(id: string): Observable<EpilepticalCrisis | undefined>

  getFilteredList(q: Query<EpilepticalCrisis>): Observable<ItemList<EpilepticalCrisis>>

  add(e: EpilepticalCrisisDTO): Observable<EpilepticalCrisis | undefined>

  update(e: EpilepticalCrisisDTO): Observable<EpilepticalCrisis | undefined>

  delete(id: string): Observable<boolean>

  byPatientSymptomID(q: Query<EpilepticalCrisis>): Observable<ItemList<EpilepticalCrisis>>

  getLastMonth(q: Query<EpilepticalCrisis>): Observable<ItemList<EpilepticalCrisis>>
}

export class EpilepticalCrisisApi implements IEpilepticalCrisisApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as EpilepticalCrisisContainerConfig).moduleFullUrl
  }

  add(e: EpilepticalCrisisDTO): Observable<EpilepticalCrisis | undefined> {
    return this._httpClient.post<EpilepticalCrisis>({ url: this._url, body: e }).pipe(
      map<EpilepticalCrisisDTO, EpilepticalCrisis>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<EpilepticalCrisis | undefined> {
    return this._httpClient.get<EpilepticalCrisis>({ url: `${this._url}/${id}` }).pipe(
      map<EpilepticalCrisisDTO, EpilepticalCrisis>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<EpilepticalCrisis>): Observable<ItemList<EpilepticalCrisis>> {
    const url = this._url
    return this._httpClient.get<ItemList<EpilepticalCrisis>>({ url: prepareURL(url, q) }).pipe(
      map<ItemList<EpilepticalCrisisDTO>, ItemList<EpilepticalCrisis>>((dto) => {
        const itemList = emptyList<EpilepticalCrisis>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<EpilepticalCrisis>())
      })
    )
  }

  update(e: EpilepticalCrisisDTO): Observable<EpilepticalCrisis | undefined> {
    return this._httpClient.put<EpilepticalCrisis>({ url: this._url, body: e }).pipe(
      map<EpilepticalCrisisDTO, EpilepticalCrisis>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  byPatientSymptomID(q: Query<EpilepticalCrisis>): Observable<ItemList<EpilepticalCrisis>> {
    const url = this._url + '/byPatientSymptomID'
    return this._httpClient.get<ItemList<EpilepticalCrisis>>({ url: prepareURL(url, q) }).pipe(
      map<ItemList<EpilepticalCrisisDTO>, ItemList<EpilepticalCrisis>>((dto) => {
        const itemList = emptyList<EpilepticalCrisis>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<EpilepticalCrisis>())
      })
    )
  }

  getLastMonth(q: Query<EpilepticalCrisis>): Observable<ItemList<EpilepticalCrisis>> {
    const url = this._url + '/lastMonth/list'
    return this._httpClient.get<ItemList<EpilepticalCrisis>>({ url: prepareURL(url, q) }).pipe(
      map<ItemList<EpilepticalCrisisDTO>, ItemList<EpilepticalCrisis>>((dto) => {
        const itemList = emptyList<EpilepticalCrisis>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<EpilepticalCrisis>())
      })
    )
  }
}
