import { UserCircleWithCircle } from './UserCircleWithCircle'
import { v4 as uuidv4 } from 'uuid'
import { Circle } from '../../circle/models/Circle'

export interface UserCircleWithCircleDTO {
  id: string
  circle: Circle
  userID: string
  familiarsID: string[]
  profSmsID: string[]
  profExtID: string[]
  careersID: string[]
}

export function emptyUserCircleWithCircleDTO() {
  return {
    id: uuidv4(),
    circle: '',
    userID: '',
    familiarsID: [],
    profSmsID: [],
    profExtID: [],
    careersID: [],
  }
}

export function fromModel(e: UserCircleWithCircle): UserCircleWithCircleDTO {
  return {
    id: e.id,
    circle: e.circle,
    userID: e.userID,
    familiarsID: e.familiarsID,
    profSmsID: e.profSmsID,
    profExtID: e.profExtID,
    careersID: e.careersID,
  }
}

export function toModel(d: UserCircleWithCircleDTO): UserCircleWithCircle {
  return new UserCircleWithCircle(d)
}
