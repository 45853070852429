import React from 'react'
import {
  TableBody,
  TableCell,
  TableRow,
  Skeleton,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'

import { v4 as uuidv4 } from 'uuid'
import { Field } from '../../../components/table'
import { Actions } from '../../../components/table_type/types'
import checked from '../../../assets/newCircle/checkbox-cuadrado-ok.svg'
import unchecked from '../../../assets/newCircle/checkbox-cuadrado.svg'

export type BodyProps<T> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly actions?: Actions<T>
  readonly isLoading?: boolean
  readonly handlerChecked?: (key: string) => void
  readonly checked: Record<string, boolean>
}

export function Body<T extends Record<string, any>>(props: BodyProps<T>) {
  const rows = props.items.map((item, index) => {
    return !props.isLoading ? (
      <TableRow
        sx={{
          borderRadius: '25px 8px 8px 25px',
          boxShadow: '0px 4px 5px 0px rgba(0, 0, 0, 0.25)',
          WebkitBoxShadow: '0px 4px 5px 0px rgba(0,0,0,0.25)',
        }}
        key={item[props.rowKeyField]}
      >
        <TableCell
          sx={{
            padding: '30px',
            fontFamily: 'Montserrat',
            borderBottom: '2px solid #68b3e0',
            borderTop: '2px solid #68b3e0',
            '&:last-child': {
              borderRight: '2px solid #68b3e0',
              borderRadius: '0px 8px 8px 0px',
            },
            '&:first-of-type': {
              borderLeft: '2px solid #68b3e0',
              borderRadius: '25px 0px 0px 25px',
            },
          }}
        >
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue=""
            name="radio-buttons-group"
          >
            <FormControlLabel
              value=""
              control={
                <Checkbox
                  icon={<img src={unchecked} alt="unchecked" />}
                  checkedIcon={<img src={checked} alt="checked" />}
                  key={'props.label' + 'checkbox'}
                  name={'props.label' + 'checkbox'}
                  checked={!!props.checked[item[props.rowKeyField]]}
                  onChange={() =>
                    props.handlerChecked && props.handlerChecked(item[props.rowKeyField])
                  }
                />
              }
              label=""
            />
          </RadioGroup>
        </TableCell>
        {Object.values(props.fields).map((field, index) => {
          const style = field.styleFunc ? field.styleFunc(field, item) : ''
          return (
            <TableCell
              className={style}
              sx={{
                fontSize: '16px',
                padding: '30px',
                fontFamily: 'Montserrat',
                color: '#515151',
                borderBottom: '2px solid #68b3e0',
                borderTop: '2px solid #68b3e0',
                fontWeight: index === 0 ? 'bold' : undefined,
                '&:last-child': {
                  borderRight: '2px solid #68b3e0',
                  borderRadius: '0px 8px 8px 0px',
                },
                '&:first-of-type': {
                  borderLeft: '2px solid #68b3e0',
                  borderRadius: '25px 0px 0px 25px',
                },
              }}
              key={(field.name as string) + uuidv4()}
            >
              {field.renderFunc ? field.renderFunc(field, item) : item[field.name].toString()}
            </TableCell>
          )
        })}
      </TableRow>
    ) : (
      <TableRow
        sx={{
          fontSize: '16px',
          fontFamily: 'Montserrat',
          borderTop: '2px solid #68b3e0',
          color: '#515151',
          fontWeight: index === 0 ? 'bold' : undefined,
          backgroundColor: '#FFFFFF',
          '&:last-child': {
            borderRight: '2px solid #68b3e0',
            borderRadius: '0px 8px 8px 0px',
          },
          '&:first-of-type': {
            borderLeft: '2px solid #68b3e0',
            borderRadius: '25px 0px 0px 25px',
          },
          padding: '0 auto 0 auto',
        }}
        key={item[props.rowKeyField]}
      >
        {Object.values(props.fields).map((field) => {
          const style = field.styleFunc ? field.styleFunc(field, item) : ''
          return (
            <TableCell
              sx={{
                fontSize: '16px',
                fontWeight: index === 0 ? 'bold' : undefined,
                fontFamily: 'Montserrat',
                color: '#515151',
                borderTop: '2px solid #68b3e0',
                backgroundColor: '#FFFFFF',
                '&:last-child': {
                  borderRight: '2px solid #68b3e0',
                  borderRadius: '0px 8px 8px 0px',
                },
                '&:first-of-type': {
                  borderLeft: '2px solid #68b3e0',
                  borderRadius: '25px 0px 0px 25px',
                },
                padding: '0 auto 0 auto',
              }}
              className={style}
              key={(field.name as string) + uuidv4()}
            >
              <Skeleton />
            </TableCell>
          )
        })}
      </TableRow>
    )
  })

  return <TableBody style={{ borderRadius: '25px' }}>{rows}</TableBody>
}
