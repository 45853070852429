import { RouteProps } from '../../routes/AppRouter'
import { ROUTE_CREATE } from '../../routes/routes-constants'
import { Editor } from '../../features/share-resources/Editor'
import { useLocation, useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

type ResourcesShareProps = {
  parentID?: string | null
  breadcrumbUrl?: Map<string, string>
  isDir?: boolean | null
} & RouteProps

export function ResourcesShare(props: ResourcesShareProps) {
  const { id } = useParams()
  const location = useLocation()
  const title = props.title ?? ''
  const state: ResourcesShareProps = location?.state as ResourcesShareProps

  return (
    <>
      <CurrentNavHeaderEsfera title={state?.title ?? title} />
      <Editor
        id={id === ROUTE_CREATE ? undefined : id}
        isDir={state?.isDir ?? false}
        parentID={state?.parentID ?? null}
        breadcrumbUrl={state?.breadcrumbUrl}
      />
    </>
  )
}
