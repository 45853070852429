import { RouteProps } from '../../routes/AppRouter'
import { ShareResources as SR } from '../../features/share-resources'
import { useLocation, useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

type ShareResourcesProps = {
  breadcrumbUrl?: Map<string, string>
} & RouteProps

export function ShareResources(props: RouteProps) {
  const { id } = useParams()
  const location = useLocation()
  const title = props.title ?? ''
  const state: ShareResourcesProps = location?.state as ShareResourcesProps

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <SR resource={id} breadcrumbUrl={state?.breadcrumbUrl} />
    </>
  )
}
