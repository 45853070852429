import { Wearable } from './Wearable'

export interface WearableDTO {
  values: number[]
  date: Date
}

export function fromModel(w: Wearable): WearableDTO {
  return {
    values: w.values,
    date: w.date,
  }
}

export function toModel(w: WearableDTO): Wearable {
  return new Wearable(w)
}
