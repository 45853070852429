import { Container } from '../../common/container/Container'

export type SportCenterContainerConfig = {
  moduleFullUrl: string
}

export type SportCenterProps = {
  parentContainer: Container
  config: SportCenterContainerConfig
}

export const SPORT_CENTER_MODULE = Symbol('SPORT_CENTER_MODULE')

export const SPORT_CENTER_API_KEY = Symbol('SPORT_CENTER_API_KEY')

export const SPORT_CENTER_SERVICE_KEY = Symbol('SPORT_CENTER_SERVICE_KEY')
