import { RouteProps } from '../../routes/AppRouter'
import { Symptoms as S } from '../../features/patient-data'
import React from 'react'
// import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function Symptoms(props: RouteProps) {
  // const { id } = useParams()
  const title = props.title ?? ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <S />
    </>
  )
}
