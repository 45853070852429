import { QueryType } from './QueryType'
import { v4 as uuidv4 } from 'uuid'

export interface QueryTypeDTO {
  id: string | undefined
  name: string
  circleID: string
  description: string
}

export function emptyQueryTypeDTO() {
  return {
    id: uuidv4(),
    name: '',
    circleID: '',
    description: '',
  }
}

export function fromModel(s: QueryType): QueryTypeDTO {
  return {
    id: s.id,
    name: s.name,
    circleID: s.circleID,
    description: s.description,
  }
}

export function toModel(s: QueryTypeDTO): QueryType {
  return new QueryType(s)
}
