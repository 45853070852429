import { RouteProps } from '../../../routes/AppRouter'
import genericStyle from '../../../common/utils/generic.module.css'
import { Box, TextField, Typography } from '@mui/material'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ROUTE_WEARABLES } from '../../../routes/routes-constants'
import { FormCard } from '../../../components/form-card/FormCard'
import { WearableType, wereableTypes } from '../../../modules/patient-data/enums/WearableType'
import { GOAL_SERVICE_KEY } from '../../../modules/patient-data/container'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { GoalService } from '../../../modules/patient-data/services/GoalService'
import { getUserContainer } from '../../../container/user-module'
import { ICircleService } from '../../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../../modules/users/container'
import { fromModel, GoalDTO } from '../../../modules/patient-data/models/GoalDTO'

const patientDataContainer = getPatientDataContainer()
const goalService = patientDataContainer.get<GoalService>(GOAL_SERVICE_KEY)

const circle = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY).getActiveUser()

const wearableTypes = wereableTypes()

export const Goals = (props: RouteProps) => {
  const { t } = useTranslation()

  const maxExcluded = [WearableType.Steps, WearableType.Distance, WearableType.OxygenSaturation]
  const navigate = useNavigate()
  const [goals, setGoals] = useState<GoalDTO[]>([])

  useEffect(() => {
    goalService.getItems(circle?.id ?? '').subscribe((g) => {
      setGoals(g.map((goal) => fromModel(goal)))
    })
  }, [])

  const goBack = () => navigate(ROUTE_WEARABLES)

  const saveGoals = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    goalService.putGoals(goals).subscribe()
  }

  const inputHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    wt: WearableType,
    minMax: string
  ) => {
    if (!goals) {
      return
    }

    const index = goals.findIndex((g) => g.type === +wt)
    if (index === -1) {
      if (minMax === 'min') {
        setGoals([...goals, { min: +e.target.value, type: +wt, userID: circle?.id ?? '' }])
      } else if (minMax === 'max') {
        setGoals([...goals, { min: 0, max: +e.target.value, type: +wt, userID: circle?.id ?? '' }])
      }

      return
    }

    const tmpGoals = [...goals]
    tmpGoals[index] = Object.assign(
      { ...goals[index] },
      {
        [minMax]: +e.target.value,
        type: +wt,
      }
    )
    setGoals(tmpGoals)
  }

  const fieldValue = (wt: WearableType, minMax: string): number | undefined => {
    if (!goals) {
      return 0
    }

    const index = goals.findIndex((g) => g.type === +wt)
    if (index === -1) {
      return 0
    }

    const g = goals[index]
    if (minMax === 'min' && g.min) {
      return g.min
    } else if (minMax === 'max' && g.max) {
      return g.max
    }

    return 0
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <FormCard>
        <form onSubmit={saveGoals}>
          {Object.keys(wearableTypes).map((wt, i) => (
            <Box key={wt + i}>
              <Box>
                <Typography style={{ fontWeight: 600 }}>
                  {t(wearableTypes[wt as unknown as WearableType])}
                </Typography>
              </Box>
              <Box width={'50%'} display={'flex'} justifyContent={'space-between'}>
                <Box width={'45%'}>
                  <TextField
                    key={wt + 'Min'}
                    name={wt + 'Min'}
                    label={t('min')}
                    variant={'outlined'}
                    margin={'normal'}
                    fullWidth
                    value={fieldValue(wt as unknown as WearableType, 'min')}
                    onChange={(e) => inputHandler(e, wt as unknown as WearableType, 'min')}
                  />
                </Box>
                {!maxExcluded.includes(+wt) && (
                  <Box width={'45%'}>
                    <TextField
                      key={wt + 'Max'}
                      name={wt + 'Max'}
                      label={t('max')}
                      variant={'outlined'}
                      margin={'normal'}
                      fullWidth
                      value={fieldValue(wt as unknown as WearableType, 'max')}
                      onChange={(e) => inputHandler(e, wt as unknown as WearableType, 'max')}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ))}
          <Box display={'flex'} justifyContent={'space-between'} mt={3}>
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={goBack}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('save')}
              handler={() => saveGoals}
            />
          </Box>
        </form>
      </FormCard>
    </Box>
  )
}
