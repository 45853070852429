import { AutocompleteChangeDetails, AutocompleteChangeReason, Box } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  PatientSymptom,
  PatientSymptomQuery,
} from '../../../modules/patient-data/models/PatientSymptom'
import { AppTable, Field } from '../../../components/table'
import { useNavigate } from 'react-router-dom'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import {
  Action,
  Actions,
  Pager,
  Search,
  SearchValue,
  Sort,
} from '../../../components/table_type/types'
import { useTranslation } from 'react-i18next'
import { getUserContainer } from '../../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../../modules/users'
import { CustomModal } from '../../../components/modal/CustomModal'
import {
  emptyPatientSymptomDTO,
  fromModel,
  toModel,
  PatientSymptomDTO,
} from '../../../modules/patient-data/models/PatientSymptomDTO'
import {
  ROUTE_CREATE,
  ROUTE_PATIENT_DATA,
  ROUTE_PATIENT_SYMPTOMS_FORM,
} from '../../../routes/routes-constants'
import { Permission } from '../../../common/permission'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { PatientSymptomService } from '../../../modules/patient-data/services/PatientSymptomService'
import { PATIENT_SYMPTOM_SERVICE_KEY, SYMPTOM_SERVICE_KEY } from '../../../modules/patient-data'
import { Query, QueryParam, QueryParamN } from '../../../common/api/Query'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { SymptomService } from '../../../modules/patient-data/services/SymptomService'
import { dateToDateTimeWithoutSecondsString } from '../../../common/utils/date'
import { ILoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { Symptom } from '../../../modules/patient-data/models/Symptom'
import { User } from '../../../modules/users/models/User'
import { reduceString } from '../../../common/utils/strings'
import genericStyle from '../../../common/utils/generic.module.css'
import styles from './Editor.module.css'
import editIcon from '../../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../../assets/table_icons/ico-eliminar.svg'
import deleteIconResponsive from '../../../assets/table_icons/ico-eliminar-responsive.svg'
import toAgoraIcon from '../../../assets/table_icons/ico-subir.svg'
import { forkJoin, Observable } from 'rxjs'
import { Modal } from '@mui/material'
import { LoggedUserService } from '../../../modules/users/services/LoggedUserService'
import editIconResponsive from '../../../assets/resource_icons/ico-editar.svg'
import { useIsRelated } from 'hooks/relatedUsers/getRelatedUsers'
import { getUserCircleContainer } from '../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../modules/user-circle'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const patientDataContainer = getPatientDataContainer()
const patientSymptomService = patientDataContainer.get<PatientSymptomService>(
  PATIENT_SYMPTOM_SERVICE_KEY
)
const symptomService = patientDataContainer.get<SymptomService>(SYMPTOM_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userContainer = getUserContainer()
const loggedUserService2 = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export function Table() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const activeUser = userCircleActiveService.getActiveFullUserCircle()
  const loggedUser = loggedUserService.get()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [users, setUsers] = useState<Map<string, string>>(new Map())
  const [count, setCount] = useState<number>(0)
  const [symptoms, setSymptoms] = useState<ItemList<PatientSymptom>>(emptyList<PatientSymptom>())
  const [symptomsHidden, setSymptomsHidden] =
    useState<ItemList<PatientSymptom>>(emptyList<PatientSymptom>())
  const [symptomsPerPage, setSymptomsPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [symptomType, setSymptomsType] = useState<string[]>([t('symptom'), t('epilepsy')])
  const [symptomsMap, setSymptomsMap] = useState<Map<string, string>>(new Map())
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [currentSymptom, setCurrentSymptom] = useState<PatientSymptom>()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const isMobile = window.innerWidth < 599
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [patientSymptomDTO, setPatientSymptomDTO] = useState<PatientSymptomDTO>(
    currentSymptom ? fromModel(currentSymptom) : emptyPatientSymptomDTO()
  )
  const { innerWidth } = window
  const [searcher, setSearcher] = useState<SearchValue<PatientSymptomQuery>[]>([
    {
      name: 'name',
      label: t('name'),
    },
    {
      name: 'creatorName',
      label: t('creator'),
    },
    {
      name: 'symptomStartDate',
      label: t(''),
      type: 'date',
    },
  ])

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  const getSymptoms = (ids: string[]): Observable<Symptom[]> =>
    forkJoin(ids.map((id) => symptomService.getByID(id))) as unknown as Observable<Symptom[]>

  const handleToAgora = (s: PatientSymptom) => {
    if (s) setCurrentSymptom(s)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleSave = () => {
    const newPatientSymtom = Object.assign({ ...patientSymptomDTO }, { isUploaded: true })
    patientSymptomService.update(toModel(newPatientSymtom)).subscribe()
    setCurrentSymptom(undefined)
    setOpenModal(false)
  }

  useEffect(() => {
    const searchTerms = [...searcherQuery(searcher)]
    const startDate = searchTerms.find((s) => s.name === 'symptomStartDate')

    const startDateValue = new Date(startDate?.value as string)
    startDateValue.setDate(startDateValue.getDate())
    patientSymptomService
      .getFilteredList(
        new Query({
          pager: { limit: symptomsPerPage, offset: page * symptomsPerPage },
          query: startDate
            ? [
                ...searcherQuery(searcher),
                new QueryParam<PatientSymptomQuery>(
                  'symptomStartDate',
                  startDateValue.toISOString()
                ),
                new QueryParam<PatientSymptomQuery>('userCircleID', activeUser?.id ?? ''),
              ]
            : [
                ...searcherQuery(searcher),
                new QueryParam<PatientSymptomQuery>('userCircleID', activeUser?.id ?? ''),
              ],
          sort: [{ field: 'startDate', desc: true }],
        })
      )
      .subscribe((res) => {
        setCount(res.count)
        setSymptoms(res)
        setSymptomsHidden(res)
        getUsers(res.items.map((s) => s.creatorID)).subscribe((ul) => {
          const newMap = new Map()
          ul.filter((u) => u).forEach((u) => {
            newMap.set(u.id, u.name)
          })
          setUsers(newMap)
        })
        getSymptoms(res.items.map((s) => s.symptomID)).subscribe((sl) => {
          const newMap = new Map()
          sl.filter((s) => s).forEach((s) => {
            newMap.set(s.id, s.name)
          })
          setSymptomsMap(newMap)
        })
      })
  }, [pager, isLoading, searcher])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setSymptomsPerPage(10)
      return
    }
    setSymptomsPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: symptomsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, symptomsPerPage])

  const goBack = () => navigate(ROUTE_PATIENT_DATA)

  const createSymptom = () => navigate(`${ROUTE_PATIENT_SYMPTOMS_FORM}/${ROUTE_CREATE}`)

  const editSymptom = (s: PatientSymptom) => navigate(`${ROUTE_PATIENT_SYMPTOMS_FORM}/${s.id}`)

  const removeSymptom = (s: PatientSymptom) => {
    setCurrentSymptom(s)
    setOpenDeleteModal(true)
  }

  const isOtherCreator = (s: PatientSymptom): boolean => s.creatorID !== loggedUser?.id

  const fields: Field<PatientSymptom>[] = isMobile
    ? [
        {
          name: 'symptomID',
          label: t(''),
          styleFunc: (f, i) => styles.containerTableBorder,
          renderFunc: (f, i) => {
            const startDate = i.symptomStartDate
            const hour = startDate.getHours()
            const minutes = startDate.getMinutes()
            const finishHour = i.finishDate.getHours()
            const finishMinutes = i.finishDate.getMinutes()

            const dateString = new Date(startDate).toLocaleDateString('es', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })

            const dateStringTime = !(
              hour === 2 &&
              minutes === 0 &&
              finishHour === 2 &&
              finishMinutes === 0
            )
              ? new Date(startDate).toLocaleTimeString('es', {
                  hour: '2-digit',
                  minute: '2-digit',
                })
              : ''

            return (
              <Box className={styles.containerTableRow}>
                {loggedUser?.id === i.creatorID && (
                  <Box className={styles.checkBoxTable}>
                    <input
                      type="radio"
                      name="symptoms"
                      disabled={false}
                      onClick={(e) => handlerSelectCheck(i)}
                      checked={currentSymptom && currentSymptom.id === i.id}
                    />
                  </Box>
                )}
                <Box className={styles.containerTable} onClick={() => editSymptom(i)}>
                  <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                    {symptomsMap.get(i.symptomID) ?? ''}
                  </span>
                  <span style={{ fontSize: '16px' }}>{users.get(i.creatorID) ?? ''}</span>
                  <hr
                    style={{
                      border: 'none',
                      borderTop: '1px solid #ccc',
                      width: '100%',
                      margin: '10px 0',
                    }}
                  />
                  <Box className={styles.containerRowTime}>
                    <Box className={styles.containerTimeText}>{dateString}</Box>
                    <Box className={styles.containerTimeText}>{dateStringTime}</Box>
                  </Box>
                </Box>
              </Box>
            )
          },
        },
      ]
    : [
        {
          name: 'symptomID',
          label: t('name'),
          renderFunc: (f, i) => symptomsMap.get(i.symptomID) ?? '',
        },
        {
          name: 'symptomStartDate',
          label: t('startDate'),
          renderFunc: (f, i) => {
            const hour = i.symptomStartDate.getHours()
            const minutes = i.symptomStartDate.getMinutes()
            const finishHour = i.finishDate.getHours()
            const finishMinutes = i.finishDate.getMinutes()
            if (hour === 2 && minutes === 0 && finishHour === 2 && finishMinutes === 0) {
              return new Date(i.symptomStartDate).toLocaleDateString('es', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })
            }
            return dateToDateTimeWithoutSecondsString(new Date(i.symptomStartDate))
          },
        },
        {
          name: 'finishDate',
          label: t('finishDate'),
          renderFunc: (f, i) => {
            const hour = i.symptomStartDate.getHours()
            const minutes = i.symptomStartDate.getMinutes()
            const finishHour = i.finishDate.getHours()
            const finishMinutes = i.finishDate.getMinutes()
            if (hour === 2 && minutes === 0 && finishHour === 2 && finishMinutes === 0) {
              return new Date(i.finishDate).toLocaleDateString('es', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })
            }
            return dateToDateTimeWithoutSecondsString(new Date(i.finishDate))
          },
        },
        {
          name: 'symptomType',
          label: t('type'),
          renderFunc: (f, i) => t(i.symptomType),
        },
        {
          name: 'comment',
          label: t('comment'),
          renderFunc: (f, i) => reduceString(i.comment, 30),
        },
        {
          name: 'creatorID',
          label: t('creator'),
          renderFunc: (f, i) => users.get(i.creatorID) ?? '',
        },
      ]

  const actions: Actions<PatientSymptom> = {
    actionsColumn: innerWidth > 598 ? t('Actions') : t(''),
    items:
      innerWidth > 598
        ? [
            {
              handler: editSymptom,
              icon: innerWidth > 598 ? editIcon : editIconResponsive,
              label: 'edit',
              hidden: useIsRelated() ? isOtherCreator : (PatientSymptom) => true,
            },
            {
              handler: handleToAgora,
              icon: toAgoraIcon,
              label: 'cloudToAgora',
              hidden: useIsRelated()
                ? () => !loggedUserService2.userCan(Permission.uploadToAgora)
                : () => true,
            },
            {
              handler: removeSymptom,
              icon: innerWidth > 598 ? deleteIcon : deleteIconResponsive,
              label: 'delete',
              hidden: useIsRelated() ? isOtherCreator : (PatientSymptom) => true,
            },
          ]
        : [],
  }

  const action: Action = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    handleAction: (
      event: ChangeEvent<{}>,
      value: String | null,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<String> | undefined
    ) => {
      if (!value) {
        setIsLoading(!isLoading)
        return
      }
      let valueAux = ''
      if (value === 'Crisis Ep.') valueAux = 'epilepsy'
      if (value === 'Síntoma') valueAux = 'symptom'
      const filteredItems = symptomsHidden.items.filter((s) => s.symptomType === valueAux)
      const newSymptomsHidden = {
        ...symptomsHidden,
        items: filteredItems,
        count: filteredItems.length,
      }
      setSymptoms(newSymptomsHidden)
      setCount(newSymptomsHidden.count)
    },
  }

  const search: Search<PatientSymptomQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<PatientSymptomQuery>[]) => {
      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        // date.setDate(date.getDate() + 1)
        date.setHours(0, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
    },
  }

  const sort: Sort<PatientSymptom> = {
    name: 'name',
    direction: 'asc',
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteSymptom = () => {
    if (currentSymptom?.id)
      if (currentSymptom.id) {
        patientSymptomService.delete(currentSymptom.id).subscribe((_) => setIsLoading(!isLoading))
      }
    setOpenDeleteModal(false)
    setIsLoading(!isLoading)
  }

  const handlerSelectCheck = (symptom: PatientSymptom) => {
    if (currentSymptom === undefined || currentSymptom.id !== symptom.id) {
      setCurrentSymptom(symptom)
    } else {
      setCurrentSymptom(undefined)
    }
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <Box mb={3} display="flex" justifyContent="space-between">
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={goBack}
        />
        {innerWidth > 599 && (
          <Box style={{ visibility: useIsRelated() ? 'visible' : 'hidden' }}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('createSymptom')}
              handler={createSymptom}
            />
          </Box>
        )}
      </Box>
      <Box>
        <AppTable
          items={symptoms.items}
          rowKeyField="id"
          fields={fields}
          actions={actions}
          search={search}
          pager={pager}
          sort={sort}
          autocompleteItems={symptomType}
          autocompleteLabel={window.innerWidth < 490 ? t('type') : t('symptomType')}
          autocompleteAction={action}
          fieldsInDraggable={true}
        />
        <Box className={innerWidth < 599 ? styles.containerRow : styles.displayNone}>
          <Box>
            <AppButton
              theme={ButtonTheme.newPrimaryRemove}
              type={'button'}
              label={t('delete')}
              handler={() => currentSymptom && removeSymptom(currentSymptom)}
            />
          </Box>
          <Box>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('createSymptom')}
              handler={createSymptom}
            />
          </Box>
        </Box>
      </Box>
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <CustomModal
          handleClose={handleCloseDeleteModal}
          handleSave={handleDeleteSymptom}
          title={t('deleteSymptom')}
          warningText={t('irreversibleSymptomAction')}
        />
      </Modal>
      <Modal open={openModal} onClose={handleCloseModal}>
        <CustomModal
          handleClose={handleCloseModal}
          handleSave={handleSave}
          title={t('cloudToAgora')}
          warningText={t('irreversibleAction')}
        />
      </Modal>
    </Box>
  )
}

const searcherQuery = (
  svs: SearchValue<PatientSymptomQuery>[]
): QueryParam<PatientSymptomQuery>[] | QueryParamN<PatientSymptomQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
