import style from './Login.module.css'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { getUserContainer } from './container/user-module'
import { IUserService, USER_SERVICE_KEY } from './modules/users'
import { Theme } from '@mui/material/styles'
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 2, 2, 2),
  },
}))

export default function PasswordRecover() {
  const classes = useStyles()

  const [email, setEmail] = useState<string>('')
  const [hasError, setHasError] = useState(false)
  const [message, setMessage] = useState(false)
  const navigate = useNavigate()

  const handlerRecover = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    doRecover()
  }

  const doRecover = () => {
    if (!email) {
      setHasError(true)
      return
    }

    userService.getByUsername(email).subscribe((res) => {
      if (!res) {
        setHasError(true)
        return
      }
      userService.passwordRecover(email).subscribe((res) => {
        setHasError(false)
        setMessage(true)
        setInterval(() => {
          handleReload()
        }, 3000)
      })
    })
  }

  const handleReload = () => {
    navigate(window.location.href.split('?')[0])
    window.location.reload()
  }

  return (
    <>
      <Box className={style.loginContainer} display={'flex'}>
        <Box className={style.login}>
          <h2>Introduzca su correo electronico para recuperar su contraseña</h2>
          {hasError && (
            <Typography color="error">{'El correo electronico es incorrecto'}</Typography>
          )}
          {message && (
            <Typography color="primary">
              {'Se ha enviado un correo electronico con las instrucciones'}
            </Typography>
          )}
          <form className={style.loginFields} noValidate onSubmit={handlerRecover}>
            <span style={{ marginTop: '10px' }} className="input input--fumi">
              <input
                className="input__field input__field--fumi"
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
                required
              />
              <label className="input__label input__label--fumi">
                <i className="fa fa-fw fa-envelope icon icon--fumi" title="Correo Electronico" />
                <span className="input__label-content input__label-content--fumi">
                  Correo Electronico
                </span>
              </label>
            </span>
            <Box display={'flex'} sx={{ flexDirection: 'column' }}>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={handleReload}
              >
                <p style={{ fontWeight: 'bolder', color: 'white' }}>Volver</p>
              </Button>
              <Button type="submit" variant="contained" color="primary" className={classes.submit}>
                <p style={{ fontWeight: 'bolder', color: 'white' }}>Enviar</p>
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}
