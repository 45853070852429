import { Symptom, SymptomQuery } from '../models/Symptom'
import { Container, IInit } from '../../../common/container/Container'
import { DataPatientContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { SymptomDTO, toModel } from '../models/SymptomDTO'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { prepareURL } from '../../../common/api/http-helpers'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface ISymptomApi extends IInit {
  getByID(id: string | null): Observable<Symptom | undefined>

  getFilteredList(q: Query<SymptomQuery>): Observable<ItemList<Symptom>>

  getFilteredItems(q: Query<SymptomQuery>): Observable<Symptom[]>

  add(e: SymptomDTO): Observable<Symptom | undefined>

  update(e: SymptomDTO): Observable<Symptom | undefined>

  delete(id: string): Observable<boolean>
}

export class SymptomApi implements ISymptomApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as DataPatientContainerConfig).moduleFullUrl + '/symptoms'
  }

  getByID(id: string | null): Observable<Symptom | undefined> {
    return this._httpClient.get<Symptom>({ url: this._url + '/' + id }).pipe(
      map<SymptomDTO, Symptom>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<SymptomQuery>): Observable<ItemList<Symptom>> {
    return this._httpClient.get<ItemList<Symptom>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<SymptomDTO>, ItemList<Symptom>>((dto) => {
        const itemList = emptyList<Symptom>()
        itemList.items = dto.items.map((d) => toModel(d))
        itemList.count = dto.count
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Symptom>())
      })
    )
  }

  getFilteredItems(q: Query<SymptomQuery>): Observable<Symptom[]> {
    return this._httpClient.get<Symptom[]>({ url: prepareURL(this._url, q) }).pipe(
      map<SymptomDTO[], Symptom[]>((dto) => dto.map((d) => toModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  add(e: SymptomDTO): Observable<Symptom | undefined> {
    return this._httpClient.post<Symptom>({ url: this._url, body: e }).pipe(
      map<SymptomDTO, Symptom>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  update(e: SymptomDTO): Observable<Symptom | undefined> {
    return this._httpClient.put<Symptom>({ url: this._url, body: e }).pipe(
      map<SymptomDTO, Symptom>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }
}
