import { Query } from '../../../common/api/Query'

type Zero2One = {
  nif: string | null
  centerName: string | null
  cipa: string | null
  phone: string | null
  controlledPregnancy: string | null
  gestationType: string | null
  uniqueFetus: string | null
  observations: string | null
  bloodType: string | null
  meconium: string | null
  lactation: string | null
  ocularProfilax: string | null
  kVitamin: string | null
  bHepatitis: string | null
  bHepatitisVaccine: string | null
  pulsioximetria: string | null
  weight: number | null
  weightDate: Date | null
  height: number | null
  heightDate: Date | null
  cranialPerimeter: number | null
  cranialPerimeterDate: Date | null
  metabolic: string | null
  metabolicDate: Date | null
  deafness: string | null
  deafnessDate: Date | null
}

type Two2SixMonths = {
  food: string | null
  weight: number | null
  weightDate: Date | null
  height: any | null
  heightDate: Date | null
  cranialPerimeter: number | null
  cranialPerimeterDate: Date | null
}

type Seven2TwentyThreeMonths = {
  weight: number | null
  weightDate: Date | null
  height: number | null
  heightDate: Date | null
  cranialPerimeter: number | null
  cranialPerimeterDate: Date | null
}

type Two2FiveYears = {
  weight: number | null
  weightDate: Date | null
  height: number | null
  heightDate: Date | null
  cranialPerimeter: number | null
  cranialPerimeterDate: Date | null
  stillLactation: string | null
}

type Six2ThirteenYears = {
  weight: number | null
  weightDate: Date | null
  height: number | null
  heightDate: Date | null
}

export type PatientDataProps = {
  zero2One: Zero2One
  two2SixMonths: Two2SixMonths
  seven2TwentyThreeMonths: Seven2TwentyThreeMonths
  two2FiveYears: Two2FiveYears
  six2ThirteenYears: Six2ThirteenYears
}

export function emptyPatientDataDTO() {
  return {
    zero2One: {
      nif: '',
      centerName: '',
      cipa: '',
      phone: '',
      controlledPregnancy: '',
      gestationType: '',
      uniqueFetus: '',
      observations: '',
      bloodType: '',
      meconium: '',
      lactation: '',
      ocularProfilax: '',
      kVitamin: '',
      bHepatitis: '',
      bHepatitisVaccine: '',
      pulsioximetria: '',
      weight: 0,
      weightDate: new Date(),
      height: 0,
      heightDate: new Date(),
      cranialPerimeter: 0,
      cranialPerimeterDate: new Date(),
      metabolic: '',
      metabolicDate: new Date(),
      deafness: '',
      deafnessDate: new Date(),
    },
    two2SixMonths: {
      food: '',
      weight: 1,
      weightDate: new Date(),
      height: 1,
      heightDate: new Date(),
      cranialPerimeter: 1,
      cranialPerimeterDate: new Date(),
    },
    seven2TwentyThreeMonths: {
      weight: 1,
      weightDate: new Date(),
      height: 1,
      heightDate: new Date(),
      cranialPerimeter: 1,
      cranialPerimeterDate: new Date(),
    },
    two2FiveYears: {
      weight: 1,
      weightDate: new Date(),
      height: 1,
      heightDate: new Date(),
      cranialPerimeter: 1,
      cranialPerimeterDate: new Date(),
      stillLactation: '',
    },
    six2ThirteenYears: {
      weight: 1,
      weightDate: new Date(),
      height: 1,
      heightDate: new Date(),
    },
  }
}

export class PatientData {
  private readonly _zero2One: Zero2One
  private readonly _two2SixMonths: Two2SixMonths
  private readonly _seven2TwentyThreeMonths: Seven2TwentyThreeMonths
  private readonly _two2FiveYears: Two2FiveYears
  private readonly _six2ThirteenYears: Six2ThirteenYears

  constructor(p: PatientDataProps) {
    this._zero2One = p.zero2One
    this._two2SixMonths = p.two2SixMonths
    this._seven2TwentyThreeMonths = p.seven2TwentyThreeMonths
    this._two2FiveYears = p.two2FiveYears
    this._six2ThirteenYears = p.six2ThirteenYears
  }

  get zero2One(): Zero2One {
    return this._zero2One
  }

  get two2SixMonths(): Two2SixMonths {
    return this._two2SixMonths
  }

  get seven2TwentyThreeMonths(): Seven2TwentyThreeMonths {
    return this._seven2TwentyThreeMonths
  }

  get two2FiveYears(): Two2FiveYears {
    return this._two2FiveYears
  }

  get six2ThirteenYears(): Six2ThirteenYears {
    return this._six2ThirteenYears
  }
}

export class DataPatientQuery extends Query<PatientData> {}
