import { Box } from '@mui/material'
import styles from '../user-profile/Editor.module.css'
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  GoogleLogout,
} from 'react-google-login'
import { LoginType } from '../../modules/patient-data/enums/LoginType'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import React, { useEffect, useState } from 'react'
import { WearableService } from '../../modules/patient-data/services/WearableService'
import { WEARABLE_SERVICE_KEY } from '../../modules/patient-data/container'
import { getPatientDataContainer } from '../../container/patient-data-module'
import { getUserContainer } from '../../container/user-module'
import { ICircleService } from '../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../modules/users/container'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const wearableService = getPatientDataContainer().get<WearableService>(WEARABLE_SERVICE_KEY)

const googleLoginScope = [
  'https://www.googleapis.com/auth/fitness.activity.read',
  'https://www.googleapis.com/auth/fitness.blood_pressure.read',
  'https://www.googleapis.com/auth/fitness.body.read',
  'https://www.googleapis.com/auth/fitness.body_temperature.read',
  'https://www.googleapis.com/auth/fitness.heart_rate.read',
  'https://www.googleapis.com/auth/fitness.location.read',
  'https://www.googleapis.com/auth/fitness.oxygen_saturation.read',
  'https://www.googleapis.com/auth/fitness.sleep.read',
]

interface loginProps {
  legal: boolean
}

export function Login(props: loginProps) {
  const fitbitLoginURL = process.env.REACT_APP_FITBIT_REDIRECT_URL

  const { t } = useTranslation()

  const circle = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY).getActiveUser()
  const location = useLocation()

  const [googleClientId] = useState<string>(
    '898450312892-g5844guikph3sj5hebbm2rdht4m8ctue.apps.googleusercontent.com'
  )

  const [loginType, setLoginType] = useState<number>(0)
  const [code, setCode] = useState<string>('')

  useEffect(() => {
    const c = location.search.split('=')[1]
    if (c) {
      setCode(c)
      setLoginType(LoginType.Fitbit)
    } else {
      wearableService.getLoginType(circle?.id ?? '').subscribe((res) => {
        setLoginType(res)
      })
    }
  }, [])

  useEffect(() => {
    if (!code || !circle?.id || loginType === 0) {
      return
    }
    wearableService
      .createToken({
        code: code,
        userID: circle.id,
        loginType,
      })
      .subscribe((res) => {})
  }, [code, loginType])

  const googleLogin = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if (res.code) {
      setCode(res.code)
    }
    setLoginType(LoginType.Google)
  }

  const closeSession = () =>
    wearableService.deleteToken(circle?.id ?? '').subscribe(() => {
      setLoginType(0)
    })

  const googleLogout = () => closeSession()

  const authWithFitbit = () => {
    if (!fitbitLoginURL) {
      return
    }

    window.location.assign(fitbitLoginURL)
    setLoginType(LoginType.Fitbit)
  }

  return (
    <>
      <Box mb={3} className={styles.textFieldBox} display={'flex'} justifyContent={'space-between'}>
        {!loginType && (
          <>
            <p className={styles.label}>Google</p>
            <GoogleLogin
              responseType={'code'}
              prompt={'consent'}
              accessType={'offline'}
              className={styles.textField}
              clientId={googleClientId}
              scope={googleLoginScope.join(' ')}
              cookiePolicy={'single_host_origin'}
              isSignedIn={loginType === LoginType.Google}
              buttonText={t('loginWithGoogle')}
              onSuccess={googleLogin}
              disabled={!props.legal}
            />
          </>
        )}
        {loginType === LoginType.Google && (
          <>
            <p className={styles.label}>Google</p>
            <GoogleLogout
              className={styles.textField}
              buttonText={t('logoutWithGoogle')}
              clientId={googleClientId}
              onLogoutSuccess={googleLogout}
            />
          </>
        )}
      </Box>
      <Box mb={3} className={styles.textFieldBox} display={'flex'} justifyContent={'space-between'}>
        {!loginType && (
          <>
            <p className={styles.label}>Fitbit</p>
            <AppButton
              theme={props.legal ? ButtonTheme.NewSecondary : ButtonTheme.BasicSecondary}
              type={'button'}
              label={t('loginWithFitbit')}
              handler={() => authWithFitbit()}
              disabled={!props.legal}
            />
          </>
        )}
        {loginType === LoginType.Fitbit && (
          <>
            <p className={styles.label}>Fitbit</p>
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('logoutWithFitbit')}
              handler={() => closeSession()}
            />
          </>
        )}
      </Box>
    </>
  )
}
