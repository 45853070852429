import style from '../../Login.module.css'
import { Box, Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import Button from '@mui/material/Button'
import React, { ChangeEvent, useState } from 'react'
import { UserDTO } from '../../modules/users/models/User'
import { useTranslation } from 'react-i18next'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'
import styles from '../../features/patient-data/symptoms/epilepticalCrisis/ModalNewCrisis.module.css'
import { UserGender } from '../../modules/users/enums/UserGender'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

type ExternalFormProps = {
  user: UserDTO
  dni: string
  email: string
  password: string
  inPass: boolean
  passwordConfirm: string
  handleLegal: () => void
  registerButtonDisabled: boolean
  legal: boolean
  handleChange: () => void
  saveExternalRegister: (e: React.FormEvent<HTMLFormElement>) => void
  handleExternalChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  handlePassword: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  handlePasswordConfirm: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  handleDate: (event: any) => void
  handleInPass: (value: boolean) => void
  handleExternalGender: (e: number) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  submit: {
    margin: theme.spacing(3, 2, 2, 2),
  },
  label: {
    fontFamily: 'Poppins, sans-serif !important',
  },
}))

export function ExternalForm(props: ExternalFormProps) {
  const { t } = useTranslation()
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const classes = useStyles()

  return (
    <form className={style.loginFields} noValidate onSubmit={props.saveExternalRegister}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="name"
        label={t('fullName')}
        name="name"
        value={props.user.name}
        onChange={props.handleExternalChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="dni"
        label={t('dni')}
        name="dni"
        value={props.user.dni}
        onChange={props.handleExternalChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="phone"
        type={'text'}
        label={t('phone')}
        name="phone"
        value={props.user.phone}
        onChange={props.handleExternalChange}
      />
      <Box
        mb={1}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginTop: '16px',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
          <DatePicker
            key={'birthDate'}
            inputFormat="DD/MM/YYYY"
            onError={(reason, value) => {
              switch (reason) {
                case 'invalidDate':
                  setDateTimePickerError(t('invalidDateMessage'))
                  break
                case 'minDate':
                  setDateTimePickerError(t('minDateMessage'))
                  break
              }
            }}
            renderInput={(props) => (
              <TextField
                helperText={props.error && DateTimePickerError}
                {...props}
                fullWidth={true}
                name={'birthDate'}
                inputProps={{
                  ...props.inputProps,
                  placeholder: ' ',
                }}
              />
            )}
            onChange={props.handleDate}
            value={props.user.birthDate}
            label={t('birthDate')}
          />
        </LocalizationProvider>
      </Box>
      <Box mb={1} style={{ width: '100%', marginTop: '16px' }}>
        <p style={{ margin: 0 }}>Género</p>
        <RadioGroup
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
          className={styles.radioGroup}
          aria-label="gender"
          name="gender"
          value={props.user.gender}
          onChange={(e) => props.handleExternalGender(Number(e.target.value))}
        >
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            value={UserGender.Male}
            control={<Radio />}
            label={t('male')}
          />
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            value={UserGender.Female}
            control={<Radio />}
            label={t('female')}
          />
        </RadioGroup>
      </Box>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Correo Electronico"
        name="email"
        value={props.email}
        onChange={props.handleExternalChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        type="password"
        required
        fullWidth
        id="password"
        label={t('password')}
        name="password"
        value={props.password}
        onChange={(e) => props.handlePassword(e)}
        onFocus={(e) => props.handleInPass(true)}
        onBlur={(e) => props.handleInPass(false)}
        helperText={
          props.inPass &&
          'La contraseña debe contener al menos una minúscula, una mayúscula y mínimo 8 caracteres'
        }
      />
      <TextField
        variant="outlined"
        margin="normal"
        type="password"
        required
        fullWidth
        id="passwordConfirm"
        label="Confirmar contraseña"
        name="passwordConfirm"
        value={props.passwordConfirm}
        onChange={(e) => props.handlePasswordConfirm(e)}
      />
      <b>
        <b onClick={props.handleLegal} style={{ cursor: 'pointer' }}>
          He leído y acepto los términos y condiciones de uso
        </b>
        <Checkbox key={'legal'} name={'legal'} checked={props.legal} onClick={props.handleChange} />
      </b>
      <Box display={'flex'} sx={{ flexDirection: 'column' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={props.registerButtonDisabled}
          className={classes.submit}
        >
          <p style={{ fontWeight: 'bolder', color: 'white' }}>Registrar</p>
        </Button>
      </Box>
    </form>
  )
}
