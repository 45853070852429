import { CalendarConfig } from './CalendarConfig'

export interface CalendarConfigDTO {
  monthly: boolean
  weekly: boolean
  diary: boolean
  vaccines: boolean
  appointments: boolean
  symptoms: boolean
  treatments: boolean
  others: boolean
}

export function emptyCalendarConfigDTO() {
  return {
    monthly: false,
    weekly: false,
    diary: false,
    vaccines: false,
    appointments: false,
    symptoms: false,
    treatments: false,
    others: false,
  }
}

export function fromModel(e: CalendarConfig): CalendarConfigDTO {
  return {
    monthly: e.monthly,
    weekly: e.weekly,
    diary: e.diary,
    vaccines: e.vaccines,
    appointments: e.appointments,
    symptoms: e.symptoms,
    treatments: e.treatments,
    others: e.others,
  }
}

export function toModel(d: CalendarConfigDTO): CalendarConfig {
  return new CalendarConfig(d)
}
