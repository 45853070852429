import { Container, ContainerItem } from '../common/container/Container'
import {
  USER_TRAINING_GROUP_API_KEY,
  USER_TRAINING_GROUP_MODULE,
  USER_TRAINING_GROUP_SERVICE_KEY,
  UserTrainingGroupProps,
} from '../modules/user-training-group/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { UserTrainingGroupApi } from '../modules/user-training-group/api/UserTrainingGroupApi'
import { UserTrainingGroupService } from '../modules/user-training-group/services/UserTrainingGroupService'

let container: Container

function init(p: UserTrainingGroupProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(
    USER_TRAINING_GROUP_API_KEY,
    new ContainerItem({ instance: new UserTrainingGroupApi() })
  )

  items.set(
    USER_TRAINING_GROUP_SERVICE_KEY,
    new ContainerItem({
      instance: new UserTrainingGroupService({ apiKey: USER_TRAINING_GROUP_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getUserTrainingGroupContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[USER_TRAINING_GROUP_MODULE],
      },
    })
  }

  return container
}
