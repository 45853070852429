import { Form, FormQuery } from '../models/Form'
import { Container, IInit } from '../../../common/container/Container'
import { FormContainerConfig } from '../container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { FormDTO, toModel } from '../models/FormDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { FormPathology, FormPathologyQuery } from '../models/FormPathology'
import {
  FormPathologyDTO,
  fromModel as fromModelTP,
  toModel as toModelTP,
} from '../models/FormPathologyDTO'

export interface IFormApi extends IInit {
  getByID(id: string): Observable<Form | undefined>

  getFilteredList(q: Query<FormQuery>): Observable<ItemList<Form>>

  getFilteredListWithCreatorNames(q: Query<FormQuery>): Observable<ItemList<Form>>

  add(e: FormDTO): Observable<Form | undefined>

  update(e: FormDTO): Observable<Form | undefined>

  getListByUserPathology(q: Query<FormQuery>): Observable<ItemList<Form>>

  getListByPathology(typeID: string): Observable<ItemList<Form>>

  getListByCreatorID(q: Query<FormQuery>): Observable<ItemList<Form>>

  // updateFormField(e: FormField): Observable<FormField | undefined>
  delete(id: string): Observable<boolean>

  addFormPathology(e: FormPathology): Observable<FormPathology | undefined>

  updateFormPathology(e: FormPathology): Observable<FormPathology | undefined>

  getFormPathologyByFormID(formID: string): Observable<FormPathology | undefined>

  getFormPathologyByPathologyID(pathologyID: string): Observable<FormPathology | undefined>

  getListFormPathology(q: Query<FormPathologyQuery>): Observable<FormPathology[]>
}

export class FormApi implements IFormApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as FormContainerConfig).moduleFullUrl
  }

  add(e: FormDTO): Observable<Form | undefined> {
    return this._httpClient.post<FormDTO>({ url: `${this._url}/template`, body: e }).pipe(
      map<FormDTO, Form>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/template/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Form | undefined> {
    return this._httpClient.get<any>({ url: `${this._url}/template/${id}` }).pipe(
      map<FormDTO, Form>((d) => {
        // d.formFields = result.sort((a, b) => a.order - b.order)
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<FormQuery>): Observable<ItemList<Form>> {
    return this._httpClient
      .get<ItemList<FormDTO>>({ url: prepareURL(`${this._url}/template`, q) })
      .pipe(
        map<ItemList<FormDTO>, ItemList<Form>>((dto) => {
          const itemList = emptyList<Form>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Form>())
        })
      )
  }

  getFilteredListWithCreatorNames(q: Query<FormQuery>): Observable<ItemList<Form>> {
    return this._httpClient
      .get<ItemList<FormDTO>>({ url: prepareURL(`${this._url}/template-withCreatorName`, q) })
      .pipe(
        map<ItemList<FormDTO>, ItemList<Form>>((dto) => {
          const itemList = emptyList<Form>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Form>())
        })
      )
  }

  update(e: FormDTO): Observable<Form | undefined> {
    return this._httpClient.put<any>({ url: `${this._url}/template`, body: e }).pipe(
      map<FormDTO, Form>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  addFormPathology(e: FormPathology): Observable<FormPathology | undefined> {
    return this._httpClient
      .post<FormPathology>({
        url: `${this._url}/template`,
        body: fromModelTP(e),
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFormPathologyByFormID(formID: string): Observable<FormPathology | undefined> {
    return this._httpClient
      .get<FormPathology>({ url: `${this._url}/templatePathologyTemplateID/${formID}` })
      .pipe(
        map<FormPathologyDTO, FormPathology>((dto) => toModelTP(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFormPathologyByPathologyID(pathologyID: string): Observable<FormPathology | undefined> {
    return this._httpClient
      .get<FormPathology>({
        url: `${this._url}/templatePathologyPathologyID/${pathologyID}`,
      })
      .pipe(
        map<FormPathologyDTO, FormPathology>((dto) => toModelTP(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getListFormPathology(q: Query<FormPathologyQuery>): Observable<FormPathology[]> {
    return this._httpClient
      .get<FormPathology[]>({ url: prepareURL(`${this._url}/templatePathology`, q) })
      .pipe(
        map<FormPathologyDTO[], FormPathology[]>((dto) => dto.map((d) => toModelTP(d))),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  updateFormPathology(e: FormPathology): Observable<FormPathology | undefined> {
    return this._httpClient
      .put<FormPathology>({
        url: this._url + '/templatePathology',
        body: fromModelTP(e),
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getListByUserPathology(q: Query<FormQuery>): Observable<ItemList<Form>> {
    return this._httpClient
      .get<ItemList<FormDTO>>({
        url: prepareURL(`${this._url}/template/byUserPathologies/list/${q.getParam('userID')}`, q),
      })
      .pipe(
        map<ItemList<FormDTO>, ItemList<Form>>((dto) => {
          const itemList = emptyList<Form>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Form>())
        })
      )
  }

  getListByPathology(typeID: string): Observable<ItemList<Form>> {
    return this._httpClient
      .get<ItemList<FormDTO>>({
        url: prepareURL(`${this._url}/template/byPathologies/list/${typeID}`),
      })
      .pipe(
        map<ItemList<FormDTO>, ItemList<Form>>((dto) => {
          const itemList = emptyList<Form>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Form>())
        })
      )
  }

  getListByCreatorID(q: Query<FormQuery>): Observable<ItemList<Form>> {
    return this._httpClient
      .get<ItemList<FormDTO>>({
        url: prepareURL(`${this._url}/template/byCreator/list/${q.getParam('creatorID')}`, q),
      })
      .pipe(
        map<ItemList<FormDTO>, ItemList<Form>>((dto) => {
          const itemList = emptyList<Form>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Form>())
        })
      )
  }

  /* updateFormField(e: FormField): Observable<FormField | undefined> {
         return this._httpClient
           .put<FormField>({ url: `${this._url}/template_fields`, body: fromModelFormField(e) })
           .pipe(
             //map<FormFieldDTO, FormField>((d) => toModelFormField(d)),
             catchError((err) => {
               this._statusService.send(err)
               return of(undefined)
             })
           )
       } */
}
