import { Box, Icon, IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './AddSeeEditSportCenterModal.module.css'
import editIcon from '../../assets/table_icons/ico-edit-hover.svg'
import React, { useEffect, useState } from 'react'
import { getUserTrainingGroupContainer } from '../../container/user-training-group-module'
import { UserTrainingGroupService } from '../../modules/user-training-group/services/UserTrainingGroupService'
import { USER_TRAINING_GROUP_SERVICE_KEY } from '../../modules/user-training-group'
import { Query, QueryParam } from '../../common/api/Query'
import { UserTrainingGroupQuery } from '../../modules/user-training-group/models/UserTrainingGroup'
import deleteIcon from '../../assets/esfera/buttons/delete.svg'

type CalendarTooltipProps = {
  trainingGroup: TimeTableGroup
  handlerEditGroup: (group: TimeTableGroup) => void
  handlerDeleteGroup: (id: string) => void
}

type TimeTableGroup = {
  id: string
  name: string
  weekDay: string
  timeTableStart: string
  timeTableFinish: string
  capacity: string
  mailEFD: string
  nameEFD: string
}

const userCircleGroupService = getUserTrainingGroupContainer().get<UserTrainingGroupService>(
  USER_TRAINING_GROUP_SERVICE_KEY
)

export const CalendarTooltip = (props: CalendarTooltipProps) => {
  const { t } = useTranslation()
  const [registered, setRegistered] = useState<number>(0)
  const [titleEdit] = useState<string>(t('edit_icon'))
  const [titleDelete, setTitleDelete] = useState<string>(t('remove'))

  useEffect(() => {
    userCircleGroupService
      .getFilteredList(
        new Query<UserTrainingGroupQuery>({
          query: [new QueryParam<UserTrainingGroupQuery>('groupID', props.trainingGroup.id)],
          pager: { offset: 0, limit: 0 },
        })
      )
      .subscribe((res) => {
        if (!res) return
        setRegistered(res.count)
        if (res.count !== 0) {
          setTitleDelete(t('cannotDeleteGroup'))
        }
      })
  }, [])

  const handlerEdit = () => {
    props.handlerEditGroup(props.trainingGroup)
  }

  const handlerDelete = () => {
    if (registered !== 0) return

    props.handlerDeleteGroup(props.trainingGroup.id)
  }

  return (
    <Box className={styles.tooltipContainer}>
      <Box className={styles.tooltipTitleRow}>
        <Box className={styles.tooltipTitle}>
          {t('group') + ' '}
          {props.trainingGroup.name}
        </Box>
        <Box className={styles.itemsRow}>
          <Box>
            <Tooltip title={titleEdit}>
              <IconButton onClick={handlerEdit}>
                <Icon className={styles.iconEdit}>
                  <img alt="edit" src={editIcon} />
                </Icon>
              </IconButton>
            </Tooltip>
            <Box className={styles.toolTipIconEmpty}></Box>
          </Box>
          <Box>
            <Tooltip title={titleDelete}>
              <IconButton onClick={handlerDelete}>
                <Icon>
                  <img src={deleteIcon} alt={'delete'} />
                </Icon>
              </IconButton>
            </Tooltip>
            <Box className={styles.toolTipIconEmpty}></Box>
          </Box>
        </Box>
      </Box>

      <Box>
        <Box className={styles.toolTipRow}>
          <Box className={styles.toolTipBold}>{t('weekDay')} :</Box>
          <Box>{props.trainingGroup.weekDay}</Box>
        </Box>
        <Box className={styles.toolTipRow}>
          <Box className={styles.toolTipBold}>{t('timeTable')} :</Box>
          <Box>
            {t('timeTableFrom')} {props.trainingGroup.timeTableStart}h {t('timeTableTo')}{' '}
            {props.trainingGroup.timeTableFinish}h
          </Box>
        </Box>
        <Box className={styles.toolTipRow}>
          <Box className={styles.toolTipBold}>{t('registered')} :</Box>
          <Box>
            {registered} {t('persons')}
          </Box>
        </Box>
        <Box className={styles.toolTipRow}>
          <Box className={styles.toolTipBold}>{t('personCapacity')} :</Box>
          <Box>
            {props.trainingGroup.capacity} {t('persons')}
          </Box>
        </Box>
        <Box className={styles.toolTipRow}>
          <Box className={styles.toolTipBold}>{t('mailEFD')} :</Box>
          <Box className={styles.toolTipText}>{props.trainingGroup.mailEFD}</Box>
        </Box>
        <Box className={styles.toolTipRow}>
          <Box className={styles.toolTipBold}>{t('nameEFD')} :</Box>
          <Box className={styles.toolTipText}>{props.trainingGroup.nameEFD}</Box>
        </Box>
      </Box>
    </Box>
  )
}
