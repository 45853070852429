import { ConfidentialityTerm } from '../models/ConfidentialityTerm'
import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { ConfidentialityTermDTO } from '../models/ConfidentialityTermDTO'
import { ConfidentialityTermApi } from '../api/ConfidentialityTermApi'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface IConfidentialityTermService extends IInit {
  getByID(id: string): Observable<ConfidentialityTerm | undefined>

  add(e: ConfidentialityTermDTO): Observable<ConfidentialityTerm | undefined>

  delete(id: string): Observable<boolean>
}

export class ConfidentialityTermService implements IConfidentialityTermService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ConfidentialityTermApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<ConfidentialityTermApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: ConfidentialityTermDTO): Observable<ConfidentialityTerm | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<ConfidentialityTerm | undefined> {
    return this._api.getByID(id)
  }
}
