import i18n from '../../../i18n'

export enum ArticleCategory {
  Diabetes,
  BloodPressure,
  Porphyria,
  HealthyKid,
}

export const articleTypes = (): Record<ArticleCategory, string> => ({
  [ArticleCategory.Diabetes]: i18n.t('diabetes'),
  [ArticleCategory.BloodPressure]: i18n.t('bloodpressure'),
  [ArticleCategory.Porphyria]: i18n.t('porphyria'),
  [ArticleCategory.HealthyKid]: i18n.t('healthykid'),
})
