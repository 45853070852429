import i18n from '../../../i18n'

export enum Vision {
  never = -1,
  eyesUp,
  eyesClose,
  eyesRight,
  eyesLeft,
  stare,
  blink,
  undefined,
}

export const visionTypes = (): Record<Vision, string> => ({
  [Vision.never]: i18n.t('never'),
  [Vision.eyesUp]: i18n.t('eyesUp'),
  [Vision.eyesClose]: i18n.t('eyesClose'),
  [Vision.eyesRight]: i18n.t('eyesRight'),
  [Vision.eyesLeft]: i18n.t('eyesLeft'),
  [Vision.stare]: i18n.t('stare'),
  [Vision.blink]: i18n.t('blink'),
  [Vision.undefined]: i18n.t('undefined'),
})
