import { RouteProps } from '../../routes/AppRouter'
import { FormGeneratorRemove as FGR } from '../../features/form-generator'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

type FormGeneratorRemoveProps = {
  formID?: string
} & RouteProps

export function FormGeneratorRemove(props: FormGeneratorRemoveProps) {
  const location = useLocation()
  const title = props.title || ''

  const state: FormGeneratorRemoveProps = location?.state as FormGeneratorRemoveProps

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <FGR formID={state?.formID} />
    </>
  )
}
