export type { IContactService } from './services/ContactService'
export type { IConsultaService } from './services/ConsultaService'
export type { IAutoMessageService } from './services/AutoMessageService'
export type { IProfessionalQueryService } from './services/ProfessionalQueryService'
export {
  MESSENGER_CONSULTA_MODULE,
  CONTACT_CONSULTA_API_KEY,
  CONSULTA_API_KEY,
  CONTACT_CONSULTA_SERVICE_KEY,
  CONSULTA_SERVICE_KEY,
  AUTOMESSAGE_API_KEY,
  AUTOMESSAGE_SERVICE_KEY,
  PROFESSIONAL_QUERY_API_KEY,
  PROFESSIONAL_QUERY_SERVICE_KEY,
} from './container'
