import { PatientData } from './PatientData'

type Zero2OneDTO = {
  nif: string | null
  centerName: string | null
  cipa: string | null
  phone: string | null
  controlledPregnancy: string | null
  gestationType: string | null
  uniqueFetus: string | null
  observations: string | null
  bloodType: string | null
  meconium: string | null
  lactation: string | null
  ocularProfilax: string | null
  kVitamin: string | null
  bHepatitis: string | null
  bHepatitisVaccine: string | null
  pulsioximetria: string | null
  weight: number | null
  weightDate: Date | null
  height: number | null
  heightDate: Date | null
  cranialPerimeter: number | null
  cranialPerimeterDate: Date | null
  metabolic: string | null
  metabolicDate: Date | null
  deafness: string | null
  deafnessDate: Date | null
}

type Two2SixMonthsDTO = {
  height: any | null
  heightDate: Date | null
  food: string | null
  weight: number | null
  weightDate: Date | null
  cranialPerimeter: number | null
  cranialPerimeterDate: Date | null
}

type Seven2TwentyThreeMonthsDTO = {
  height: number | null
  heightDate: Date | null
  weight: number | null
  weightDate: Date | null
  cranialPerimeter: number | null
  cranialPerimeterDate: Date | null
}

type Two2FiveYearsDTO = {
  height: number | null
  heightDate: Date | null
  weight: number | null
  weightDate: Date | null
  cranialPerimeter: number | null
  cranialPerimeterDate: Date | null
  stillLactation: string | null
}

type Six2ThirteenYearsDTO = {
  height: number | null
  heightDate: Date | null
  weight: number | null
  weightDate: Date | null
}

export interface PatientDataDTO {
  zero2One: Zero2OneDTO
  two2SixMonths: Two2SixMonthsDTO
  seven2TwentyThreeMonths: Seven2TwentyThreeMonthsDTO
  two2FiveYears: Two2FiveYearsDTO
  six2ThirteenYears: Six2ThirteenYearsDTO
}

export function toModel(d: PatientDataDTO): PatientData {
  return new PatientData(d)
}
