import { QueryConfig } from './QueryConfig'

export interface QueryConfigDTO {
  teleCall: boolean
  videoCall: boolean
  acceptQuery: boolean
  noAvailableMessage: string
}

export function emptyQueryConfigDTO() {
  return {
    teleCall: false,
    videoCall: false,
    acceptQuery: false,
    noAvailableMessage: '',
  }
}

export function fromModel(e: QueryConfig): QueryConfigDTO {
  return {
    videoCall: e.videoCall,
    teleCall: e.teleCall,
    acceptQuery: e.acceptQuery,
    noAvailableMessage: e.noAvailableMessage,
  }
}

export function toModel(d: QueryConfigDTO): QueryConfig {
  return new QueryConfig(d)
}
