import i18n from '../../../i18n'

export enum SkinColor {
  never = -1,
  blue,
  pale,
  noChanges,
  undefined,
}

export const skinColorTypes = (): Record<SkinColor, string> => ({
  [SkinColor.never]: i18n.t('never'),
  [SkinColor.blue]: i18n.t('blue'),
  [SkinColor.pale]: i18n.t('pale'),
  [SkinColor.noChanges]: i18n.t('noChanges'),
  [SkinColor.undefined]: i18n.t('undefined'),
})
