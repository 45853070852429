import { Role } from '../models/Role'
import { Container, IInit } from '../../../common/container/Container'
import { UserContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { fromModel, RoleDTO, toModel } from '../models/RoleDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'

export interface IRoleApi extends IInit {
  getAll(q: Query<Role>): Observable<Role[]>
  getByID(id: string): Observable<Role | undefined>

  update(e: Role): Observable<Role | undefined>
}

export class RoleApi implements IRoleApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as UserContainerConfig).moduleFullUrl
  }

  getAll(q: Query<Role>): Observable<Role[]> {
    return this._httpClient.get<Role[]>({ url: this._url + '/roles' }).pipe(
      map<RoleDTO[], Role[]>((dto) => {
        return dto.map((d) => toModel(d))
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  getByID(id: string): Observable<Role | undefined> {
    return this._httpClient.get<Role>({ url: `${this._url}/roles/${id}` }).pipe(
      map<RoleDTO, Role>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  update(e: Role): Observable<Role | undefined> {
    return this._httpClient.put<Role>({ url: this._url + '/roles', body: fromModel(e) }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
