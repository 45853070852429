import { PositionType } from './PositionType'
import { v4 as uuidv4 } from 'uuid'

export interface PositionTypeDTO {
  id: string
  name: string
  idForm: string
  description: string
}

export function fromModel(m: PositionType): PositionTypeDTO {
  return {
    id: m.id,
    name: m.name,
    idForm: m.idForm,
    description: m.description,
  }
}

export function toModel(d: PositionTypeDTO): PositionType {
  return new PositionType(d)
}

export function emptyPositionTypeDTO() {
  return {
    id: uuidv4(),
    name: '',
    idForm: '',
    description: '',
  }
}
