import { RouteProps } from '../../routes/AppRouter'
import { PatientFormView as View } from '../../features/form-patients'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

type PatientFormProps = {
  edit?: boolean
  permanent?: number
} & RouteProps

export function PatientFormView(props: PatientFormProps) {
  const { id } = useParams()
  const location = useLocation()
  const title = props?.title ?? ''
  const state: PatientFormProps = location?.state as PatientFormProps

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <View id={id ?? ''} edit={!!state?.edit} />
    </>
  )
}
