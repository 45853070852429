import { BoolQueryParam } from '../../../common/api/Query'
import { v4 as uuidv4 } from 'uuid'
import { UserGender } from '../enums/UserGender'

export interface UserDTO {
  id: string
  name: string
  username: string
  lastname: string
  isCircle: boolean
  gender: number
  birthDate: Date
  dni: string
  cip: string
  phone: string
  email: string
  related?: string[]
  roles: string[]
  acceptChat: boolean
  acceptVideoCalls: boolean
  password?: string
}

export function emptyUserDTO() {
  return {
    id: uuidv4(),
    name: '',
    username: '',
    lastname: '',
    gender: UserGender.Female,
    isCircle: false,
    birthDate: new Date(),
    dni: '',
    cip: '',
    phone: '',
    email: '',
    related: [],
    roles: [],
    acceptChat: true,
    acceptVideoCalls: true,
    password: '',
  }
}

export class User {
  private readonly _id: string
  private readonly _name: string
  private readonly _username: string
  private readonly _lastname: string
  private readonly _gender: number
  private readonly _isCircle: boolean
  private readonly _birthDate: Date
  private readonly _dni: string
  private readonly _cip: string
  private readonly _phone: string
  private readonly _email: string
  private readonly _acceptChat: boolean
  private readonly _acceptVideoCalls: boolean
  private readonly _password: string

  private readonly _related: string[]
  private _roles: string[]

  constructor(p: UserDTO) {
    this._id = p.id || uuidv4()
    this._name = p.name
    this._username = p.username
    this._lastname = p.lastname
    this._gender = p.gender
    this._isCircle = p.isCircle
    this._birthDate = p.birthDate
    this._dni = p.dni
    this._cip = p.cip
    this._phone = p.phone
    this._email = p.email
    this._roles = p.roles
    this._acceptChat = p.acceptChat
    this._acceptVideoCalls = p.acceptVideoCalls
    this._related = p.related || []
    this._password = p.password || ''
  }

  get id(): string {
    return this._id
  }

  get acceptChat(): boolean {
    return this._acceptChat
  }

  get acceptVideoCalls(): boolean {
    return this._acceptVideoCalls
  }

  get name(): string {
    return this._name
  }

  get username(): string {
    return this._username
  }

  get lastname(): string {
    return this._lastname
  }
  get gender(): number {
    return this._gender
  }

  get isCircle(): boolean {
    return this._isCircle
  }

  get birthDate(): Date {
    return this._birthDate
  }

  get dni(): string {
    return this._dni
  }

  get cip(): string {
    return this._cip
  }

  get related(): string[] {
    return this._related
  }

  get phone(): string {
    return this._phone
  }

  get email(): string {
    return this._email
  }

  get roles(): string[] {
    return this._roles
  }

  set roles(value: string[]) {
    this._roles = value
  }

  dateAsString(): string {
    return this._birthDate.toDateString()
  }

  get password(): string {
    return this._password
  }

  toDTO(): UserDTO {
    return {
      id: this.id,
      name: this.name,
      username: this.username,
      lastname: this.lastname,
      gender: this.gender,
      isCircle: this.isCircle,
      birthDate: this.birthDate,
      dni: this.dni,
      cip: this.cip,
      phone: this.phone,
      email: this.email,
      roles: this.roles,
      acceptChat: this.acceptChat,
      acceptVideoCalls: this.acceptVideoCalls,
      related: this.related,
      password: this.password,
    }
  }
}

export interface UserQuery {
  id: string
  ids: string[]
  username: string
  isCircle: BoolQueryParam
  name: string
  role: string
  isActive: BoolQueryParam
  dni: string
  cip: string
  cips: string[]
  email: string
  phone: string
  roles: string[]
  allFields: string
  birthDate: Date
  acceptChat: BoolQueryParam
  acceptVideocalls: BoolQueryParam
}

export function toModel(d: UserDTO): User {
  return new User(d)
}

export function toModelFromArray(d: UserDTO[]): User[] {
  let aux: User[] = []
  d.forEach((item) => {
    aux.push(toModel(item))
  })

  return aux
}

export function fromModel(d: User): UserDTO {
  return {
    id: d.id,
    name: d.name,
    username: d.username,
    lastname: d.lastname,
    gender: d.gender,
    isCircle: d.isCircle,
    birthDate: d.birthDate,
    dni: d.dni,
    cip: d.cip,
    phone: d.phone,
    email: d.email,
    roles: d.roles,
    acceptChat: d.acceptChat,
    acceptVideoCalls: d.acceptVideoCalls,
    related: d.related,
    password: d.password,
  }
}
