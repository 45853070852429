import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { UserTypeExternalProfessionalContainerConfig } from '../container'
import { toModel } from '../models/TypeExternalProfessionalDTO'
import { TypeExternalProfessional } from '../models/TypeExternalProfessional'
import { TypeExternalProfessionalDTO } from '../models/TypeExternalProfessionalDTO'

export interface ITypeExternalProfessionalApi extends IInit {
  getByID(id: string): Observable<TypeExternalProfessional | undefined>

  getFilteredList(
    q: Query<TypeExternalProfessional>
  ): Observable<ItemList<TypeExternalProfessional>>

  add(e: TypeExternalProfessionalDTO): Observable<TypeExternalProfessional | undefined>

  update(e: TypeExternalProfessionalDTO): Observable<TypeExternalProfessional | undefined>

  delete(id: string): Observable<boolean>
}

export class TypeExternalProfessionalApi implements ITypeExternalProfessionalApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url =
      (this._container.config as UserTypeExternalProfessionalContainerConfig).moduleFullUrl +
      '/typeExternalProfessional'
  }

  add(e: TypeExternalProfessionalDTO): Observable<TypeExternalProfessional | undefined> {
    return this._httpClient.post<TypeExternalProfessional>({ url: this._url, body: e }).pipe(
      map<TypeExternalProfessionalDTO, TypeExternalProfessional>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<TypeExternalProfessional | undefined> {
    return this._httpClient.get<TypeExternalProfessional>({ url: `${this._url}/${id}` }).pipe(
      map<TypeExternalProfessionalDTO, TypeExternalProfessional>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(
    q: Query<TypeExternalProfessional>
  ): Observable<ItemList<TypeExternalProfessional>> {
    return this._httpClient
      .get<ItemList<TypeExternalProfessional>>({ url: prepareURL(`${this._url}`, q) })
      .pipe(
        map<ItemList<TypeExternalProfessionalDTO>, ItemList<TypeExternalProfessional>>((dto) => {
          const itemList = emptyList<TypeExternalProfessional>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<TypeExternalProfessional>())
        })
      )
  }

  update(e: TypeExternalProfessionalDTO): Observable<TypeExternalProfessional | undefined> {
    return this._httpClient.put<TypeExternalProfessional>({ url: this._url, body: e }).pipe(
      map<TypeExternalProfessionalDTO, TypeExternalProfessional>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
