import { Box } from '@mui/material'
import styles from './navbarResponsive.module.css'
import left_arrow from '../../assets/buttons/left_arrow.svg'

type backHeaderProps = {
  labelText: string
  handlerBack: () => void
}

export function BackHeader(props: backHeaderProps) {
  return (
    <Box className={styles.backResponsiveContainer} onClick={props.handlerBack}>
      <Box className={styles.boxColumnImage}>
        <img src={left_arrow} />
      </Box>
      <Box className={styles.boxTitleContainer}>
        <Box className={styles.backResponsiveTitle}>{props.labelText}</Box>
      </Box>
    </Box>
  )
}
