import React, { createRef, useEffect, useState } from 'react'
import { Feature, Map as OlMap, Overlay } from 'ol'
import TileLayer from 'ol/layer/Tile'
import { Vector, XYZ } from 'ol/source'
import { fromLonLat } from 'ol/proj'
import { Point } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
// import { useTranslation } from 'react-i18next'
import { Icon, Style } from 'ol/style'
import clinic from '../../../assets/map/clinic-medical.svg'
import selectedClinic from '../../../assets/map/selected-clinic-medical.svg'
import styles from './Map.module.css'
import 'ol/ol.css'
import { Coordinate } from 'ol/coordinate'
import { Group } from 'ol/layer'
import { SportCenter } from '../../../modules/sport-center/models/SportCenter'

type MapProps = {
  sportCenters: SportCenter[]
  selectedSportCenter: string
  handleSelectedSportCenter: (id: string) => void
  baseLayer: TileLayer<XYZ>
  initialMap: OlMap
}

export const Map = (props: MapProps) => {
  // const { t } = useTranslation()
  const mapRef: any = createRef<HTMLDivElement>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = useState<OlMap>(props.initialMap)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [overlay, setOverlay] = useState(
    new Overlay({
      element: undefined,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    })
  )

  const style = {
    icon: new Style({
      image: new Icon({
        anchor: [1, 1],
        src: clinic,
      }),
    }),
    selectedIcon: new Style({
      image: new Icon({
        anchor: [1, 1],
        src: selectedClinic,
      }),
    }),
  }

  const changeContainer = () => {
    const container = document.getElementById('popup')
    if (container) {
      overlay.setElement(container)
      container.style.display = 'flex'
    }
  }

  const changeContent = (feature: any, position: Coordinate) => {
    const content = document.getElementById('popup-content')
    if (content) {
      content.innerHTML = setPopupContent(feature)
      overlay.setPosition(position)
    }
  }

  const closePopup = () => {
    const closer = document.getElementById('popup-closer')
    if (!closer) {
      return
    }
    overlay.setPosition(undefined)
    closer.blur()
    // props.handleSelectedSportCenter('')
    return false
  }

  const changeCloser = () => {
    const closer = document.getElementById('popup-closer')
    if (closer) {
      closer.onclick = () => closePopup()
    }
  }

  useEffect(() => {
    const markers = props.sportCenters.map(
      (d) =>
        new Feature({
          type: 'icon',
          id: d.id,
          name: d.name,
          address: d.location,
          geometry: new Point(fromLonLat([Number(d.longitude), Number(d.latitude)])),
        })
    )
    const vectorLayer = new VectorLayer({
      source: new Vector({
        features: markers,
      }),
      style: (f) => (f.get('id') === props.selectedSportCenter ? style.selectedIcon : style.icon),
    })

    map.setLayerGroup(new Group())
    map.addLayer(props.baseLayer)
    map.addLayer(vectorLayer)

    changeContainer()
    changeCloser()

    const sportCenter = props.sportCenters.find((d) => d.id === props.selectedSportCenter)
    if (!sportCenter) {
      return
    }
    changeContent(
      {
        name: sportCenter.name,
        address: sportCenter.location,
      },
      fromLonLat([Number(sportCenter.longitude), Number(sportCenter.latitude)])
    )
  }, [props.sportCenters, props.selectedSportCenter])

  useEffect(() => {
    closePopup()
  }, [props.sportCenters])

  useEffect(() => {
    changeContainer()

    if (map.getTarget()) {
      return
    }

    map.addOverlay(overlay)
    map.setTarget(mapRef.current)
    map.on('click', (evt) => {
      changeCloser()

      const feature: any = map.forEachFeatureAtPixel(evt.pixel, (feature) => feature)
      if (!feature) {
        return
      }
      changeContent(
        {
          name: feature.get('name'),
          address: feature.get('address'),
        },
        evt.coordinate
      )
      props.handleSelectedSportCenter(feature.get('id'))
    })
  }, [map])

  const setPopupContent = (values: any): string =>
    `<p style="color: var(--color-dark-blue); font-weight: bold; font-size: 1rem; margin: 0; padding: 5px">
    ${values.name}
    </p>
    <p style="font-size: 15px;">${values.address}</p>
        `

  return (
    <>
      <div ref={mapRef} className={styles.map} />
      <div id="popup" className={styles.olPopup}>
        <a href="#" id="popup-closer" className={styles.olPopupCloser} />
        <div id="popup-content" />
      </div>
    </>
  )
}
