import { Query } from '../../../common/api/Query'

export type ProfessionalQueryProps = {
  id: string
  message: string
  userID: string
  acceptConsulta: number
}

export class ProfessionalQuery {
  private readonly _id: string
  private _message: string
  private _userID: string
  private _acceptConsulta: number

  constructor(p: ProfessionalQueryProps) {
    this._id = p.id
    this._userID = p.userID
    this._message = p.message
    this._acceptConsulta = p.acceptConsulta
  }

  get id(): string {
    return this._id
  }

  get userID(): string {
    return this._userID
  }
  set userID(value: string) {
    this._userID = value
  }

  get message(): string {
    return this._message
  }
  set message(value: string) {
    this._message = value
  }

  get acceptConsulta(): number {
    return this._acceptConsulta
  }

  set acceptConsulta(value: number) {
    this._acceptConsulta = value
  }
}

export class ProfessionalQueryFilter extends Query<ProfessionalQuery> {}
