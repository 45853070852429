import { Container } from '../../common/container/Container'

export type UserTrainingGroupContainerConfig = {
  moduleFullUrl: string
}

export type UserTrainingGroupProps = {
  parentContainer: Container
  config: UserTrainingGroupContainerConfig
}

export const USER_TRAINING_GROUP_MODULE = Symbol('USER_TRAINING_GROUP_MODULE')

export const USER_TRAINING_GROUP_API_KEY = Symbol('USER_TRAINING_GROUP_API_KEY')

export const USER_TRAINING_GROUP_SERVICE_KEY = Symbol('USER_TRAINING_SERVICE_KEY')
