import React, { ReactNode, useEffect, useState } from 'react'
import { ILoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../modules/users'
import { getUserContainer } from '../../../container/user-module'
import { getFileContainer } from '../../../container/file-module'
import { FILE_SERVICE_KEY, IFileService } from '../../../modules/files'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const resourcesService = getFileContainer().get<IFileService>(FILE_SERVICE_KEY)
type ProfilePhotoContextProviderProps = {
  children: ReactNode
}
export type ProfilePhotoContextType = {
  profilePhotoData: string
  refreshProfilePhoto: boolean
  setRefreshProfilePhoto: (refresh: boolean) => void
  isLoading: boolean
  setIsloading: (loading: boolean) => void
}

export const ProfilePhotoContext = React.createContext<ProfilePhotoContextType>({
  profilePhotoData: '',
  refreshProfilePhoto: false,
  setRefreshProfilePhoto: () => {},
  isLoading: false,
  setIsloading: () => {},
})
export const ProfilePhotoContextProvider: React.FC<ProfilePhotoContextProviderProps> = ({
  children,
}) => {
  const loggedUser = loggedUserService.get()
  const [profilePhotoData, setProfilePhotoData] = useState<string>('')
  const [isLoading, setIsloading] = useState<boolean>(false)
  const [refreshProfilePhoto, setRefreshProfilePhoto] = useState(false)

  useEffect(() => {
    if (loggedUser) {
      setIsloading(true)
      resourcesService.getByID(loggedUser?.id ?? '').subscribe((res) => {
        if (res) {
          resourcesService.download(res).subscribe((res) => {
            if (res) {
              setProfilePhotoData(res.data)
              setIsloading(false)
            } else {
              setIsloading(false)
            }
          })
        } else {
          setIsloading(false)
        }
      })
    }
  }, [refreshProfilePhoto])

  return (
    <ProfilePhotoContext.Provider
      value={{
        profilePhotoData,
        refreshProfilePhoto,
        setRefreshProfilePhoto,
        isLoading,
        setIsloading,
      }}
    >
      {children}
    </ProfilePhotoContext.Provider>
  )
}
