export enum Roles {
  Admin = 'admin',
  ContentManager = 'contentManager',
  Patient = 'patient',
  FamilyOrTutor = 'family/Tutor',
  Professional = 'professionalSMS',
  CarerOrTeacher = 'carer/Teacher',
  ProfessionalExtern = 'externProfessional',
}

export const RoleName = (): Record<string, Roles> => ({
  ['admin']: Roles.Admin,
  ['contentManager']: Roles.ContentManager,
  ['patient']: Roles.Patient,
  ['family-Tutor']: Roles.FamilyOrTutor,
  ['professionalSMS']: Roles.Professional,
  ['carer-Teacher']: Roles.CarerOrTeacher,
  ['externProfessional']: Roles.ProfessionalExtern,
})
