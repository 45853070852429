import React from 'react'
import { Box, Button } from '@mui/material'
import editButton from '../../../../assets/newCircle/edit_icon.svg'
import deleteButton from '../../../../assets/newCircle/remove_icon.svg'

export type Resource = {
  id: string
  title: string
  author: string
  assigned: string
}

export const ActionButtons: React.FC<{
  handleDeleteResource: () => void
  handleEditResource: () => void
}> = ({ handleDeleteResource, handleEditResource }) => {
  return (
    <Box flex="1" display="flex" justifyContent="flex-end">
      <Button style={{ padding: 0 }} onClick={handleEditResource}>
        <img src={editButton} alt="edit-icon" />
      </Button>
      <Button style={{ padding: 0 }} onClick={handleDeleteResource}>
        <img src={deleteButton} alt="delete-icon" />
      </Button>
    </Box>
  )
}

export const NewResource: React.FC<{
  resource: Resource
  handleDeleteResource: () => void
  handleEditResource: () => void
}> = ({ resource, handleDeleteResource, handleEditResource }) => {
  return (
    <>
      <Box
        display="flex"
        padding="1.5rem 4%"
        marginBottom="2rem"
        style={{ border: '1px solid rgba(219, 218, 218, 0.80)' }}
      >
        <Box
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          flex="2"
          paddingLeft="24px"
        >
          {resource.title}
        </Box>
        <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" flex="2">
          {resource.author}
        </Box>
        <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" flex="2">
          {resource.assigned}
        </Box>
        <Box flex="1" alignSelf="right" display="flex" justifyContent="flex-end">
          <ActionButtons
            handleDeleteResource={handleDeleteResource}
            handleEditResource={handleEditResource}
          />
        </Box>
      </Box>
    </>
  )
}
