import { Container } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ProfessionalQuery } from '../models/ProfessionalQuery'
import { IProfessionalQueryApi } from '../api/ProfessionalQueryApi'

type Props = {
  apiKey: symbol
}

export interface IProfessionalQueryService {
  getByID(id: string): Observable<ProfessionalQuery | undefined>
  getByUserID(id: string): Observable<ProfessionalQuery | undefined>

  add(e: ProfessionalQuery): Observable<ProfessionalQuery | undefined>

  update(e: ProfessionalQuery): Observable<ProfessionalQuery | undefined>

  delete(e: ProfessionalQuery): Observable<boolean>
}

export class ProfessionalQueryService implements IProfessionalQueryService {
  protected readonly _apiKey: symbol
  protected _container!: Container
  protected _api!: IProfessionalQueryApi
  protected _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IProfessionalQueryApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: ProfessionalQuery): Observable<ProfessionalQuery | undefined> {
    return this._api.add(e)
  }

  delete(e: ProfessionalQuery): Observable<boolean> {
    return this._api.delete(e)
  }

  getByID(id: string): Observable<ProfessionalQuery | undefined> {
    return this._api.getByID(id)
  }

  getByUserID(id: string): Observable<ProfessionalQuery | undefined> {
    return this._api.getByUserID(id)
  }

  update(e: ProfessionalQuery): Observable<ProfessionalQuery | undefined> {
    return this._api.update(e)
  }
}
