import { Backdrop, Box, Fade, Modal, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/events_icons/deletingModalIcon.svg'
import React from 'react'
import { AppButton, ButtonTheme } from '../app-button/AppButton'
import style from './CustomModal.module.css'

type modalProps = {
  type: string
  open: boolean
  handleClose: () => void
  handleDelete: (id: string) => void
}

export const DeletingModal = (props: modalProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
      BackdropComponent={Backdrop}
    >
      <Fade in={props.open}>
        <Paper className={style.deleteContainer}>
          <img src={logo} alt={t('logo')} />
          <Typography variant="h6" style={{ marginTop: '20px', fontWeight: 'bold' }}>
            {t('confirmDelete')} {t(props.type).toLocaleLowerCase()}?
          </Typography>

          <Box className={style.buttonsBox}>
            <Box>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('cancel')}
                handler={props.handleClose}
                vertical={true}
              />
            </Box>
            <Box style={{ marginLeft: '10px' }}>
              {' '}
              {/* Ajusta el valor de marginLeft según tus necesidades */}
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('accept')}
                handler={props.handleDelete}
                vertical={true}
              />
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  )
}
