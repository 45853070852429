import { useEffect, useState } from 'react'

function useInteractionWithPage() {
  const [monitoring, setMonitoring] = useState(false)

  useEffect(() => {
    const handleInteraccion = () => {
      console.log('Se ha producido una interacción en la página web')
    }

    if (monitoring) {
      document.addEventListener('click', handleInteraccion)
      document.addEventListener('keydown', handleInteraccion)
    } else {
      document.removeEventListener('click', handleInteraccion)
      document.removeEventListener('keydown', handleInteraccion)
    }

    return () => {
      document.removeEventListener('click', handleInteraccion)
      document.removeEventListener('keydown', handleInteraccion)
    }
  }, [monitoring])

  return setMonitoring // Retornar la función para habilitar/deshabilitar la monitorización
}

export default useInteractionWithPage
