import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UserTypeExternalProfessional } from '../models/UserTypeExternalProfessional'
import { UserTypeExternalProfessionalDTO } from '../models/UserTypeExternalProfessionalDTO'
import { IUserTypeExternalProfessionalApi } from '../api/UserTypeExternalProfessionalApi'
import {TrainerCheckEmail} from "../models/trainerCheckEmail";


type Props = {
  apiKey: symbol
}

export interface IUserTypeExternalProfessionalService extends IInit {
  getByID(id: string): Observable<UserTypeExternalProfessional | undefined>

  getFilteredList(
    q: Query<UserTypeExternalProfessional>,
    userId: string
  ): Observable<ItemList<UserTypeExternalProfessional>>

  add(e: UserTypeExternalProfessional): Observable<UserTypeExternalProfessional | undefined>

  update(e: UserTypeExternalProfessionalDTO): Observable<UserTypeExternalProfessional | undefined>

  delete(id: string): Observable<boolean>

  trainerCheckEmail(email: string): Observable<TrainerCheckEmail | undefined>
}

export class UserTypeExternalProfessionalService implements IUserTypeExternalProfessionalService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserTypeExternalProfessionalApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserTypeExternalProfessionalApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: UserTypeExternalProfessionalDTO): Observable<UserTypeExternalProfessional | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<UserTypeExternalProfessional | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(
    q: Query<UserTypeExternalProfessional>
  ): Observable<ItemList<UserTypeExternalProfessional>> {
    return this._api.getFilteredList(q)
  }

  update(e: UserTypeExternalProfessionalDTO): Observable<UserTypeExternalProfessional | undefined> {
    return this._api.update(e)
  }

  trainerCheckEmail(email: string): Observable<TrainerCheckEmail | undefined>{
    return this._api.trainerCheckEmail(email)
  }
}
