import { Container, ContainerItem } from '../common/container/Container'
import {
  TRAINING_GROUP_SERVICE_KEY,
  TRAINING_GROUP_MODULE,
  TRAINING_GROUP_API_KEY,
  TrainingGroupProps,
} from '../modules/training-group/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { TrainingGroupApi } from '../modules/training-group/api/TrainingGroupApi'
import { TrainingGroupService } from '../modules/training-group/services/TrainingGroupService'

let container: Container

function init(p: TrainingGroupProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(TRAINING_GROUP_API_KEY, new ContainerItem({ instance: new TrainingGroupApi() }))

  items.set(
    TRAINING_GROUP_SERVICE_KEY,
    new ContainerItem({ instance: new TrainingGroupService({ apiKey: TRAINING_GROUP_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getTrainingGroupContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[TRAINING_GROUP_MODULE],
      },
    })
  }

  return container
}
