import { Indicator } from './Indicator'

export interface IndicatorDTO {
  id: string
  value: string
  date: Date
}

export function toModel(i: IndicatorDTO): Indicator {
  return new Indicator(i)
}

export function fromModel(i: Indicator): IndicatorDTO {
  return {
    id: i.id,
    value: i.value,
    date: i.date,
  }
}
