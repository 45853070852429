import { SxProps } from '@mui/material'
import TextField from '@mui/material/TextField'
import React, { ChangeEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export type TextFieldItemProps = {
  field: string
  value?: string | number
  label: string
  handleChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  rows?: number
  required: boolean
  type?: string
  disabled?: boolean
  inputProps?: object
  handlerValue?: (value: number, event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  valueID?: number
  name?: string
  sx?: SxProps<Theme> | undefined
  fullWidth?: boolean
  style?: React.CSSProperties
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void
}

const useStyles = makeStyles(() => ({
  label: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '16px',
  },
}))

export function TextFieldItem(props: TextFieldItemProps) {
  const { t } = useTranslation()
  // Asegúrate de que el valor `multiline` no cambie durante el ciclo de vida del componente
  const isMultiline = useMemo(() => props.rows && props.rows > 0, [props.rows])

  const onChange = (event: any) => {
    if (props.handlerValue && props.valueID != undefined) {
      props.handlerValue(props.valueID, event)
    }
  }

  const classes = useStyles()

  return (
    <TextField
      sx={
        props.sx
          ? props.sx
          : {
              '.MuiInputBase-input': {
                fontFamily: 'Poppins',
                minHeight: 40,
                paddingLeft: 2,
              },
              '.MuiInputLabel-outlined': {
                fontFamily: 'Poppins',
                fontSize: 15,
              },
            }
      }
      id={props.field}
      key={props.field}
      label={t(props.label)}
      InputLabelProps={{
        classes: {
          root: classes.label,
        },
      }}
      name={props.field}
      value={props.value}
      onChange={(e) =>
        props.handlerValue ? onChange(e) : props.handleChange && props.handleChange(e)
      }
      type={props.type}
      multiline={!!isMultiline}
      required={props.required}
      disabled={props.disabled}
      inputProps={props.inputProps}
      fullWidth={props.fullWidth ?? true}
      variant="outlined"
      maxRows={40}
      minRows={props.rows ?? 0}
      style={props.style}
      onKeyDown={props.onKeyDown}
    />
  )
}
