import { Container, ContainerItem } from '../common/container/Container'
import { UserService } from '../modules/users/services/UserService'
import { LoggedUserService } from '../modules/users/services/LoggedUserService'
import { UserApi } from '../modules/users/api/UserApi'
import {
  CIRCLE_SERVICE_KEY,
  LOGGED_USER_SERVICE_KEY,
  PROFESSIONALTYPE_API_KEY,
  PROFESSIONALTYPE_SERVICE_KEY,
  POSITIONTYPE_API_KEY,
  POSITIONTYPE_SERVICE_KEY,
  ROLES_API_KEY,
  ROLES_SERVICE_KEY,
  USER_API_KEY,
  USER_SERVICE_KEY,
  UserProps,
  USERS_MODULE,
} from '../modules/users/container'
// import { UserApi } from '../fixtures/users-api'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { RoleApi } from '../modules/users/api/RoleApi'
import { RolesService } from '../modules/users/services/RolesServices'
import { CircleService } from '../modules/users/services/CircleService'
import { ProfessionalTypeApi } from '../modules/users/api/ProfessionalTypeApi'
import { ProfessionalTypesService } from '../modules/users/services/ProfessionalTypeService'
import { PositionTypesService } from '../modules/users/services/PositionTypeService'
import { PositionTypeApi } from '../modules/users/api/PositionTypeApi'

let container: Container

function init(p: UserProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(USER_API_KEY, new ContainerItem({ instance: new UserApi() }))
  items.set(ROLES_API_KEY, new ContainerItem({ instance: new RoleApi() }))
  items.set(PROFESSIONALTYPE_API_KEY, new ContainerItem({ instance: new ProfessionalTypeApi() }))
  items.set(POSITIONTYPE_API_KEY, new ContainerItem({ instance: new PositionTypeApi() }))

  items.set(
    USER_SERVICE_KEY,
    new ContainerItem({ instance: new UserService({ apiKey: USER_API_KEY }) })
  )

  items.set(
    LOGGED_USER_SERVICE_KEY,
    new ContainerItem({ instance: new LoggedUserService({ apiKey: USER_API_KEY }) })
  )

  items.set(
    ROLES_SERVICE_KEY,
    new ContainerItem({ instance: new RolesService({ apiKey: ROLES_API_KEY }) })
  )

  items.set(
    PROFESSIONALTYPE_SERVICE_KEY,
    new ContainerItem({
      instance: new ProfessionalTypesService({ apiKey: PROFESSIONALTYPE_API_KEY }),
    })
  )

  items.set(
    POSITIONTYPE_SERVICE_KEY,
    new ContainerItem({
      instance: new PositionTypesService({ apiKey: POSITIONTYPE_API_KEY }),
    })
  )

  items.set(
    CIRCLE_SERVICE_KEY,
    new ContainerItem({ instance: new CircleService({ apiKey: USER_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getUserContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[USERS_MODULE],
      },
    })
  }

  return container
}
