import { RouteProps } from '../../routes/AppRouter'
import { PatientData as P } from '../../features/patient-data'
import React from 'react'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'
import { useParams } from 'react-router-dom'

export function PatientData(props: RouteProps) {
  const { id } = useParams()
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} headerTitle={'patientData'} />
      <P />
    </>
  )
}
