import { RouteProps } from '../../routes/AppRouter'
import { TemplateGeneratorForm as F } from '../../features/form-generator/template-generator-form/TemplateGeneratorForm'
import React from 'react'
import { ROUTE_TEMPLATE } from '../../routes/routes-constants'
import { useParams } from 'react-router-dom'

export function FormTemplateGenerator(props: RouteProps) {
  const { id } = useParams()
  return (
    <>
      <F id={id === ROUTE_TEMPLATE ? undefined : id} refMain={props.refMain} />
    </>
  )
}
