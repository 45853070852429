import { Container, ContainerItem } from '../common/container/Container'
import {
  DENTIST_API_KEY,
  DENTIST_MODULE,
  DENTIST_SERVICE_KEY,
  DentistProps,
} from '../modules/dentists/container'
import { DentistApi } from '../modules/dentists/api/DentistApi'
import { DentistService } from '../modules/dentists/services/DentistService'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'

let container: Container

function init(p: DentistProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(DENTIST_API_KEY, new ContainerItem({ instance: new DentistApi() }))

  items.set(
    DENTIST_SERVICE_KEY,
    new ContainerItem({ instance: new DentistService({ apiKey: DENTIST_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getDentistContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[DENTIST_MODULE],
      },
    })
  }

  return container
}
