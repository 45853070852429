import { RouteProps } from '../../routes/AppRouter'
import { FamiliarDataTable as FD } from '../../features/patient-data/medicalData/FamiliarDataTable'
import React from 'react'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function FamiliarData(props: RouteProps) {
  const { id } = useParams()
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <FD />
    </>
  )
}
