import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { TrainingDTO } from '../models/TrainingDTO'
import { Training } from '../models/Training'
import { ITrainingApi } from '../api/TrainingApi'

type Props = {
  apiKey: symbol
}

export interface ITrainingService extends IInit {
  getByID(id: string): Observable<Training | undefined>

  getFilteredList(q: Query<Training>, userId: string): Observable<ItemList<Training>>

  add(e: Training): Observable<Training | undefined>

  update(e: TrainingDTO): Observable<Training | undefined>

  delete(id: string): Observable<boolean>
}

export class TrainingService implements ITrainingService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ITrainingApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<ITrainingApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: TrainingDTO): Observable<Training | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Training | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<Training>): Observable<ItemList<Training>> {
    return this._api.getFilteredList(q)
  }

  update(e: TrainingDTO): Observable<Training | undefined> {
    return this._api.update(e)
  }
}
