import React, { ChangeEvent, useEffect, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import style from './Searcher.module.css'

type SearcherProps = {
  handler: (search: string) => void
  reverse: () => void
  handleNewConversation: () => void
}

export function Searcher(props: SearcherProps): JSX.Element {
  const { t } = useTranslation()
  const [search, setSearch] = useState<string>('')

  const handlerChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setSearch(event.target.value)

  useEffect(() => {
    props.handler(search)
  }, [search])

  return (
    <>
      <Box className={style.inputBox}>
        <TextField
          className={style.inputSearcher}
          label={t('filterByConversation')}
          variant={'outlined'}
          type="text"
          value={search}
          onChange={handlerChange}
        />
      </Box>
    </>
  )
}
