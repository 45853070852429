import { Query } from '../../../common/api/Query'

export type PositionTypeUserProps = {
  id: string
  positionTypeID: string
  userID: string
}

export class PositionTypeUser {
  private readonly _id: string
  private readonly _positionTypeID: string
  private readonly _userID: string

  constructor(p: PositionTypeUserProps) {
    this._id = p.id
    this._positionTypeID = p.positionTypeID
    this._userID = p.userID
  }

  get id(): string {
    return this._id
  }

  get userID(): string {
    return this._userID
  }

  get positionTypeID(): string {
    return this._positionTypeID
  }
}

export class PositionTypeUserQuery extends Query<PositionTypeUser> {}
