import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import React, { useEffect, useState } from 'react'
import { User, UserQuery } from '../../modules/users/models/User'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { AppTable, Field } from '../../components/table'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { Pager, Search, SearchValue } from '../../components/table_type/types'
import { ROUTE_NEWS_WALL } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import genericStyle from '../../common/utils/generic.module.css'
import dayjs from 'dayjs'
import { useGlobalRenderHeaderContext } from '../../common/utils/GlobalRenderHeader'
import { getUserCircleContainer } from '../../container/user-circle-module'
import {
  IUserCircleActiveService,
  IUserCircleService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
  USER_CIRCLE_SERVICE_KEY,
} from '../../modules/user-circle'
import { UserCircleWithCircleAndUser } from '../../modules/user-circle/models/UserCircleWithCircleAndUser'
import { UserCircleQuery } from '../../modules/user-circle/models/UserCircle'
import { Query, QueryParam } from '../../common/api/Query'
import { useGetActiveUser } from '../../hooks/get-active-user/useGetActiveUser'
import Button from '@mui/material/Button'
import { NewUserModal } from './NewUserModal'
import userIconTable from '../../assets/responsive_icons/userIconTable.svg'
import style from './Circle.module.css'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

const roleCircleMap: Record<string, string> = {
  managerHealthChild: '0000',
  managerActivePlan: '1113',
  managerEpileptic: '1111',
  // agregar más roles aquí según sea necesario
}
export function Table() {
  const { t } = useTranslation()

  const loggedUser = loggedUserService.get()

  const [userCircles, setUserCircles] =
    useState<ItemList<UserCircleWithCircleAndUser>>(emptyList<UserCircleWithCircleAndUser>())
  const [pager, setPager] = useState<Pager>()
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filtering, setFiltering] = useState<boolean>(false)
  const [circlesPerPage, setCirclesPerPage] = useState<number>(10)
  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'allFields',
      label: t('search'),
    },
    {
      name: 'birthDate',
      label: '',
      type: 'date',
    },
  ])

  const isMobile = window.innerWidth < 768

  const { setActiveUser } = useGetActiveUser()
  const { render, setRender } = useGlobalRenderHeaderContext()
  const navigate = useNavigate()
  const [showNewUserModal, setShowNewUserModal] = useState<boolean>(false)
  const onlyCaseManager = Object.keys(roleCircleMap).some((role) =>
    loggedUser?.roles?.includes(role)
  )

  const fetchUserCircles = (query: Query<UserCircleQuery>) => {
    userCircleService.getFilteredListWithCircleAndUser(query).subscribe((res) => {
      if (!res) return
      setUserCircles(res)
      setCount(res.count)
    })
  }

  const fetchCustomUserCircles = (circleID: string) => {
    const query = new Query<UserCircleQuery>({
      pager: { offset: page * circlesPerPage, limit: circlesPerPage },
      query: [new QueryParam('roles', loggedUser?.roles ?? '')],
      sort: [{ field: 'userID', desc: true }],
    })
    userCircleService.getFilteredListWithCircleAndUserCustom(query).subscribe((res) => {
      if (!res) return
      setUserCircles(res)
      setCount(res.count)
    })
  }

  useEffect(() => {
    if (!loggedUser?.id) return

    if (!onlyCaseManager) {
      const query = new Query<UserCircleQuery>({
        pager: { offset: page * circlesPerPage, limit: circlesPerPage },
        query: [
          new QueryParam('includesUserID', loggedUser.id),
          new QueryParam('searchByCircleOrUser', searcher[0]?.value ?? ''),
          new QueryParam('searchByDate', searcher[1]?.value?.split('T')[0] ?? ''),
        ],
        sort: [{ field: 'userID', desc: true }],
      })
      fetchUserCircles(query)
    } else {
      loggedUser.roles.forEach((role) => {
        const circleID = roleCircleMap[role]
        if (circleID) {
          fetchCustomUserCircles(circleID)
        }
      })
    }
  }, [pager, searcher])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setCirclesPerPage(10)
      return
    }
    setCirclesPerPage(Number.parseInt(event.target.value))
  }
  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: circlesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, circlesPerPage])

  const handleSelect = (u: User, fullUserCircle: UserCircleWithCircleAndUser) => {
    userCircleActiveService.setActiveUser(u)
    userCircleActiveService.setActiveFullUserCircle(fullUserCircle)
    setRender(!render)
  }

  const fields: Field<UserCircleWithCircleAndUser>[] = [
    {
      name: 'user',
      label: t('fullName'),
      renderFunc: (f, i) => (
        <div
          onClick={() => {
            setActiveUser(i)
            handleSelect(i.user, i)
            navigate(ROUTE_NEWS_WALL)
          }}
          style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
        >
          <p>
            {i.user.name} {i.user.lastname}
          </p>
        </div>
      ),
    },
    {
      name: 'circle',
      label: t('circle'),
      renderFunc: (f, i) => i.circle.name,
    },
    {
      name: 'user',
      label: t('birthdate'),
      renderFunc: (f, i) => dayjs(i.user.birthDate).format('DD/MM/YYYY'),
    },
    {
      name: 'user',
      label: t('CIP'),
      renderFunc: (f, i) => i.user.cip,
    },
    {
      name: 'user',
      label: t('DNI'),
      renderFunc: (f, i) => i.user.dni,
    },
  ]
  const fieldsResponsive: Field<UserCircleWithCircleAndUser>[] = [
    {
      name: 'user',
      label: t(''),
      styleFunc: (f, i) => style.borderTable,
      renderFunc: (f, i) => (
        <div
          onClick={() => {
            setActiveUser(i)
            handleSelect(i.user, i)
            navigate(ROUTE_NEWS_WALL)
          }}
          style={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderColor: 'red',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <p style={{ fontWeight: 'bold' }}>{i.user.name + ' ' + i.user.lastname}</p>
            <p>{i.user.dni}</p>
          </div>
          <img src={userIconTable} alt="userIconTable" style={{ marginLeft: '10px' }} />
        </div>
      ),
    },
  ]

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => {
      setFiltering(!!svs[0].value)

      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
    },
  }

  return (
    <Box data-testid="component" className={genericStyle.pageContainer}>
      {process.env.NODE_ENV === 'development' && (
        <Button onClick={() => setShowNewUserModal(true)}>Nuevo usuario</Button>
      )}
      {isMobile ? (
        <AppTable
          items={userCircles.items}
          rowKeyField="id"
          fields={fieldsResponsive}
          search={search}
          fieldsInDraggable={true}
          pager={pager}
        />
      ) : (
        <AppTable
          items={userCircles.items}
          rowKeyField="id"
          fields={fields}
          search={search}
          fieldsInDraggable={true}
          pager={pager}
        />
      )}
      {showNewUserModal && (
        <>
          <NewUserModal open={showNewUserModal} onClose={() => setShowNewUserModal(false)} />
        </>
      )}
    </Box>
  )
}
