import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Modal, Slider } from '@mui/material'
import styles from '../Calendar.module.css'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { dateToDateTimeWithoutSecondsString } from '../../../common/utils/date'
import { Training } from '../../../modules/training/models/Training'

type EditTrainingModalProps = {
  open: boolean
  handlerClose: () => void
  handlerAccept: (value: number) => void
  handlerNotAssistance?: () => void
  training: Training
  patient: boolean
}

export function EditTrainingModal(props: EditTrainingModalProps) {
  const { t } = useTranslation()
  const [value, setValue] = useState<number>(0)
  const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
  ]

  function valuetext(value: number) {
    setValue(value)
    return `${value}`
  }
  return (
    <>
      <Modal open={props.open} className={styles.modalGetTrainingIntensity}>
        <Box className={styles.trainingContainer}>
          <Box className={styles.titleIntesity}>{t('trainingSession')} </Box>
          <Box className={styles.titleIntensityDate}>
            {t('trainingDate') +
              ' ' +
              dateToDateTimeWithoutSecondsString(new Date(props.training.startTime))}
          </Box>
          <Box className={styles.textIntensity}>
            {props.patient ? t('showTrainingIntensity') : t('showPhisicalCondition')}
          </Box>
          <Box sx={{ width: 550 }}>
            <Slider
              track={false}
              aria-labelledby="track-false-slider"
              getAriaValueText={valuetext}
              defaultValue={3}
              marks={marks}
              max={5}
              min={1}
            />
          </Box>

          <Box className={styles.fieldRowButton}>
            <AppButton
              theme={ButtonTheme.BasicTransparentOutlinedOrange}
              type={'button'}
              label={t('cancel')}
              handler={props.handlerClose}
            />
            {props.patient && (
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('confirmNoAssistance')}
                handler={() => props.handlerNotAssistance && props.handlerNotAssistance()}
              />
            )}
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={props.patient ? t('confirmAssistance') : t('save')}
              handler={() => props.handlerAccept(value)}
            />
          </Box>
        </Box>
      </Modal>
    </>
  )
}
