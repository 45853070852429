import { Duration } from '../enums/Duration'
import { Zone } from '../enums/Zone'
import { CrisisType } from '../enums/CrisisType'
import { Awareness } from '../enums/Awareness'
import { Movement } from '../enums/Movement'
import { Vision } from '../enums/Vision'
import { SkinColor } from '../enums/SkinColor'
import { Symptom } from '../enums/Symptom'
import { Mouth } from '../enums/Mouth'
import { AfterDuration } from '../enums/AfterDuration'

export type UserEpilepticalConfigProps = {
  id: string
  userCircleID: string
  crisisType: CrisisType
  color: string
  comment: string
  duration: Duration
  zone: Zone
  awareness: Awareness
  movement: Movement
  vision: Vision
  skinColor: SkinColor
  symptom: Symptom
  mouth: Mouth
  afterDuration: AfterDuration
  afterSymptom: string[]
}

export class UserEpilepticalConfig {
  private readonly _id: string
  private readonly _userCircleID: string
  private readonly _crisisType: CrisisType
  private readonly _color: string
  private readonly _comment: string
  private readonly _duration: Duration
  private readonly _zone: Zone
  private readonly _awareness: Awareness
  private readonly _movement: Movement
  private readonly _vision: Vision
  private readonly _skinColor: SkinColor
  private readonly _symptom: Symptom
  private readonly _mouth: Mouth
  private readonly _afterSymptom: string[]
  private readonly _afterDuration: AfterDuration

  constructor(p: UserEpilepticalConfigProps) {
    this._id = p.id
    this._userCircleID = p.userCircleID
    this._crisisType = p.crisisType
    this._color = p.color
    this._comment = p.comment
    this._duration = p.duration
    this._zone = p.zone
    this._awareness = p.awareness
    this._movement = p.movement
    this._vision = p.vision
    this._skinColor = p.skinColor
    this._symptom = p.symptom
    this._mouth = p.mouth
    this._afterSymptom = p.afterSymptom
    this._afterDuration = p.afterDuration
  }

  get id(): string {
    return this._id
  }

  get userCircleID(): string {
    return this._userCircleID
  }

  get crisisType(): CrisisType {
    return this._crisisType
  }

  get color(): string {
    return this._color
  }

  get comment(): string {
    return this._comment
  }

  get duration(): Duration {
    return this._duration
  }

  get zone(): Zone {
    return this._zone
  }

  get awareness(): number {
    return this._awareness
  }

  get movement(): Movement {
    return this._movement
  }

  get vision(): Vision {
    return this._vision
  }

  get skinColor(): SkinColor {
    return this._skinColor
  }

  get symptom(): Symptom {
    return this._symptom
  }

  get mouth(): Mouth {
    return this._mouth
  }

  get afterSymptom(): string[] {
    return this._afterSymptom
  }

  get afterDuration(): AfterDuration {
    return this._afterDuration
  }
}

export interface UserEpilepticalConfigQuery {
  id: string
  userCircleID: string
  color: string
}
