import { Container } from '../../common/container/Container'

export type CircleContainerConfig = {
  moduleFullUrl: string
}

export type CircleProps = {
  parentContainer: Container
  config: CircleContainerConfig
}

export const CIRCLE_MODULE = Symbol('CIRCLE_MODULE')

export const CIRCLE_API_KEY = Symbol('CIRCLE_API_KEY')

export const CIRCLE_SERVICE_KEY = Symbol('CIRCLE_SERVICE_KEY')
