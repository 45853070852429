import { RouteProps } from '../../routes/AppRouter'
import { VaccinationSchedule as V } from '../../features/patient-data/vaccinationSchedule/VaccinationSchedule'
import React from 'react'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function Vacusan(props: RouteProps) {
  return (
    <>
      <CurrentNavHeaderEsfera title={props.title || ''} />
      <V />
    </>
  )
}
