import { EpilepticalCrisis } from './EpilepticalCrisis'
import { v4 as uuidv4 } from 'uuid'

export interface EpilepticalCrisisDTO {
  id: string
  date: Date
  mood: number
  medication: number
  userCircleID: string
  patientSymptomID: string
}

export function emptyEpilepticalCrisis() {
  return {
    id: uuidv4(),
    date: new Date(),
    mood: 0,
    medication: 0,
    userCircleID: '',
    patientSymptomID: '',
  }
}

export function fromModel(f: EpilepticalCrisis): EpilepticalCrisisDTO {
  return {
    id: f.id,
    date: f.date,
    mood: f.mood,
    medication: f.medication,
    userCircleID: f.userCircleID,
    patientSymptomID: f.patientSymptomID,
  }
}

export function toModel(d: EpilepticalCrisisDTO): EpilepticalCrisis {
  return new EpilepticalCrisis(d)
}
