import { Vaccine, VaccinesQuery } from '../models/Vaccines'
import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { IVaccinesApi } from '../api/VaccinesApi'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { ItemList } from '../../../common/models/ItemList'
import { VaccinesDTO } from '../models/VaccinesDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface IVaccinesService extends IInit {
  getByID(id: string): Observable<Vaccine | undefined>

  getFilteredList(q: Query<VaccinesQuery>): Observable<ItemList<Vaccine>>

  add(e: VaccinesDTO): Observable<Vaccine | undefined>

  update(e: VaccinesDTO): Observable<Vaccine | undefined>

  delete(id: string): Observable<boolean>
}

export class VaccinesService implements IVaccinesService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IVaccinesApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IVaccinesApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: VaccinesDTO): Observable<Vaccine | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Vaccine | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<VaccinesQuery>): Observable<ItemList<Vaccine>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: VaccinesDTO): Observable<Vaccine | undefined> {
    return this._api.update(e)
  }
}
