import { Container, IInit } from '../../../common/container/Container'
import { UserContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { ProfessionalType } from '../models/ProfessionalType'
import { fromModel, ProfessionalTypeDTO, toModel } from '../models/ProfessionalTypeDTO'
import { ProfessionalTypeUser, ProfessionalTypeUserQuery } from '../models/ProfessionalTypeUser'
import {
  fromModel as fromModelPTU,
  ProfessionalTypeUserDTO,
  toModel as toModelPTU,
} from '../models/ProfessionalTypeUserDTO'
import { prepareURL } from '../../../common/api/http-helpers'

export interface IProfessionalTypeApi extends IInit {
  getAll(q: Query<ProfessionalType>): Observable<ProfessionalType[]>

  getByName(name: string): Observable<ProfessionalType | undefined>

  getByID(id: string): Observable<ProfessionalType | undefined>

  update(e: ProfessionalType): Observable<ProfessionalType | undefined>

  createProfessionalTypeUser(e: ProfessionalTypeUser): Observable<ProfessionalTypeUser | undefined>

  updateProfessionalTypeUser(e: ProfessionalTypeUser): Observable<ProfessionalTypeUser | undefined>

  deleteProfessionalTypeUserByUserID(id: string): Observable<boolean>

  getUserProfessionalTypeByUserID(userID: string): Observable<ProfessionalTypeUser | undefined>

  getUserProfessionalTypeByProfessionalTypeID(
    professionalTypeID: string
  ): Observable<ProfessionalTypeUser | undefined>

  getListUserProfessionalType(
    q: Query<ProfessionalTypeUserQuery>
  ): Observable<ProfessionalTypeUser[]>

  getProfessionalTypeByUserID(userID: string): Observable<ProfessionalType | undefined>

  delete(id: string): Observable<boolean>

  create(e: ProfessionalType): Observable<ProfessionalType | undefined>
}

export class ProfessionalTypeApi implements IProfessionalTypeApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as UserContainerConfig).moduleFullUrl
  }

  create(e: ProfessionalType): Observable<ProfessionalType | undefined> {
    return this._httpClient
      .post<ProfessionalType>({ url: this._url + '/professionaltype', body: fromModel(e) })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/professionaltype/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getAll(q: Query<ProfessionalType>): Observable<ProfessionalType[]> {
    return this._httpClient
      .get<ProfessionalType[]>({ url: prepareURL(this._url + '/professionaltype', q) })
      .pipe(
        map<ProfessionalTypeDTO[], ProfessionalType[]>((dto) => {
          return dto.map((d) => toModel(d))
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getByName(name: string): Observable<ProfessionalType | undefined> {
    return this._httpClient
      .get<ProfessionalType>({ url: `${this._url}/professionaltype/${name}` })
      .pipe(
        map<ProfessionalTypeDTO, ProfessionalType>((dto) => toModel(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByID(id: string): Observable<ProfessionalType | undefined> {
    return this._httpClient
      .get<ProfessionalType>({ url: `${this._url}/professionaltypeid/${id}` })
      .pipe(
        map<ProfessionalTypeDTO, ProfessionalType>((dto) => toModel(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  update(e: ProfessionalType): Observable<ProfessionalType | undefined> {
    return this._httpClient
      .put<ProfessionalType>({ url: this._url + '/professionaltype', body: fromModel(e) })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  createProfessionalTypeUser(
    e: ProfessionalTypeUser
  ): Observable<ProfessionalTypeUser | undefined> {
    return this._httpClient
      .post<ProfessionalTypeUser>({
        url: `${this._url}/professionaltypeuser`,
        body: fromModelPTU(e),
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  updateProfessionalTypeUser(
    e: ProfessionalTypeUser
  ): Observable<ProfessionalTypeUser | undefined> {
    return this._httpClient
      .put<ProfessionalTypeUser>({
        url: this._url + '/professionaltypeuser',
        body: fromModelPTU(e),
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  deleteProfessionalTypeUserByUserID(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/professionaltypeuseridDelete/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getUserProfessionalTypeByUserID(userID: string): Observable<ProfessionalTypeUser | undefined> {
    return this._httpClient
      .get<ProfessionalTypeUser>({ url: `${this._url}/professionaltypeuserid/${userID}` })
      .pipe(
        map<ProfessionalTypeUserDTO, ProfessionalTypeUser>((dto) => toModelPTU(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getUserProfessionalTypeByProfessionalTypeID(
    professionalTypeID: string
  ): Observable<ProfessionalTypeUser | undefined> {
    return this._httpClient
      .get<ProfessionalTypeUser>({
        url: `${this._url}/professionaltypeuserpt/${professionalTypeID}`,
      })
      .pipe(
        map<ProfessionalTypeUserDTO, ProfessionalTypeUser>((dto) => toModelPTU(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getListUserProfessionalType(
    q: Query<ProfessionalTypeUserQuery>
  ): Observable<ProfessionalTypeUser[]> {
    return this._httpClient
      .get<ProfessionalTypeUser[]>({ url: prepareURL(`${this._url}/professionaltypeuser`, q) })
      .pipe(
        map<ProfessionalTypeUserDTO[], ProfessionalTypeUser[]>((dto) =>
          dto.map((d) => toModelPTU(d))
        ),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getProfessionalTypeByUserID(userID: string): Observable<ProfessionalType | undefined> {
    return this._httpClient
      .get<ProfessionalType>({ url: `${this._url}/professionaltypename/${userID}` })
      .pipe(
        map<ProfessionalTypeDTO, ProfessionalType>((dto) => toModel(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
