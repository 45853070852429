import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { EpilepticalCrisisContainerConfig } from '../container'
import { EpilepticalCrisisDetails } from '../models/EpilepticalCrisisDetails'
import { EpilepticalCrisisDetailsDTO, toModel } from '../models/EpilepticalCrisisDetailsDTO'

export interface IEpilepticalCrisisDetailsApi extends IInit {
  getByID(id: string): Observable<EpilepticalCrisisDetails | undefined>

  getFilteredList(
    q: Query<EpilepticalCrisisDetails>
  ): Observable<ItemList<EpilepticalCrisisDetails>>

  add(e: EpilepticalCrisisDetailsDTO): Observable<EpilepticalCrisisDetails | undefined>

  update(e: EpilepticalCrisisDetailsDTO): Observable<EpilepticalCrisisDetails | undefined>

  delete(id: string): Observable<boolean>

  byEpilepticalCrisisID(
    q: Query<EpilepticalCrisisDetails>
  ): Observable<ItemList<EpilepticalCrisisDetails>>
}

export class EpilepticalCrisisDetailsApi implements IEpilepticalCrisisDetailsApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url =
      (this._container.config as EpilepticalCrisisContainerConfig).moduleFullUrl + '/details'
  }

  add(e: EpilepticalCrisisDetailsDTO): Observable<EpilepticalCrisisDetails | undefined> {
    return this._httpClient.post<EpilepticalCrisisDetails>({ url: this._url, body: e }).pipe(
      map<EpilepticalCrisisDetailsDTO, EpilepticalCrisisDetails>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<EpilepticalCrisisDetails | undefined> {
    return this._httpClient.get<EpilepticalCrisisDetails>({ url: `${this._url}/${id}` }).pipe(
      map<EpilepticalCrisisDetailsDTO, EpilepticalCrisisDetails>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(
    q: Query<EpilepticalCrisisDetails>
  ): Observable<ItemList<EpilepticalCrisisDetails>> {
    const url = this._url + '/list/query'
    return this._httpClient
      .get<ItemList<EpilepticalCrisisDetails>>({ url: prepareURL(url, q) })
      .pipe(
        map<ItemList<EpilepticalCrisisDetailsDTO>, ItemList<EpilepticalCrisisDetails>>((dto) => {
          const itemList = emptyList<EpilepticalCrisisDetails>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<EpilepticalCrisisDetails>())
        })
      )
  }

  update(e: EpilepticalCrisisDetailsDTO): Observable<EpilepticalCrisisDetails | undefined> {
    return this._httpClient.put<EpilepticalCrisisDetails>({ url: this._url, body: e }).pipe(
      map<EpilepticalCrisisDetailsDTO, EpilepticalCrisisDetails>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  byEpilepticalCrisisID(
    q: Query<EpilepticalCrisisDetails>
  ): Observable<ItemList<EpilepticalCrisisDetails>> {
    const url = this._url + '/details/byEpilepticalCrisisID'
    return this._httpClient
      .get<ItemList<EpilepticalCrisisDetails>>({ url: prepareURL(url, q) })
      .pipe(
        map<ItemList<EpilepticalCrisisDetailsDTO>, ItemList<EpilepticalCrisisDetails>>((dto) => {
          const itemList = emptyList<EpilepticalCrisisDetails>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<EpilepticalCrisisDetails>())
        })
      )
  }
}
