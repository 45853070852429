import { Form } from '../../modules/forms/models/Form'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { getFormContainer } from '../../container/form-module'
import { FormService } from '../../modules/forms/services/FormService'
import { FORM_SERVICE_KEY } from '../../modules/forms'
import styles from './Remover.module.css'
import { FormFieldType } from '../../modules/forms/enums/FormFieldType'
import { reduceString } from '../../common/utils/strings'
import genericStyle from '../../common/utils/generic.module.css'
import { ROUTE_FORM_GENERATOR } from '../../routes/routes-constants'
import {
  CheckboxAssignType,
  checkboxAssignType,
} from '../../modules/forms/enums/CheckboxAssignType'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  Box,
  Chip,
  InputLabel,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material'

type RemoverProps = {
  formID?: string
}

const formContainer = getFormContainer()
const formService = formContainer.get<FormService>(FORM_SERVICE_KEY)

export function Remover(props: RemoverProps) {
  const { t } = useTranslation()

  const [form, setForm] = useState<Form>()
  const [checkBox, setCheckBox] = useState<string[]>([])
  const navigate = useNavigate()
  // const [users, setUsers] = useState<User[]>([])

  // const getUsers = (ids: string[]): Observable<User[]> =>
  //  (forkJoin(ids.map((id) => userService.getByID(id))) as unknown) as Observable<User[]>

  // const removeSameUsers = (userList: User[]) =>
  // userList.filter((u, i) => !userList.slice(i + 1).find((f) => f.id === u.id))

  useEffect(() => {
    if (props.formID) {
      formService.getByID(props.formID).subscribe((f) => {
        if (!f?.id) {
          return
        }
        setForm(f)
        if (f.checkBoxesSelected) {
          setCheckBox(f.checkBoxesSelected.split(','))
        }

        /*
        userFormService
          .getFilteredList(
            new Query({
              query: [new QueryParam<UserForm>('formID', f.id)],
            })
          )
          .subscribe((res) => {
            getUsers(res.items.map((uf) => uf.userID)).subscribe((ul) =>
              setUsers(removeSameUsers(ul))
            )
          })
        */
      })
    }
  }, [])

  const goBack = () => navigate(ROUTE_FORM_GENERATOR)

  const removeForm = () => {
    if (props.formID) {
      formService.delete(props.formID).subscribe((_) => goBack())
    }
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <Box>
        <Alert severity="warning" key="removeTemplateConfirm" id="removeTemplateConfirm">
          {t('removeTemplateConfirm')}
        </Alert>
      </Box>
      <Box>
        <Box mb={3}>
          <Table>
            <TableBody>
              <TableRow className={styles.row}>
                <TableCell className={styles.label}>{t('title')}</TableCell>
                <TableCell className={styles.cell}>{form?.title}</TableCell>
              </TableRow>

              <TableRow className={styles.row}>
                <TableCell className={styles.label}>{t('description')}</TableCell>
                <TableCell className={styles.cell}>{form?.description}</TableCell>
              </TableRow>

              <TableRow className={styles.row}>
                <TableCell className={styles.label}>{t('formFields')}</TableCell>
                <TableCell style={{ overflowY: 'auto' }}>
                  <List>
                    {form?.formFields.map((ff) => (
                      <ListItem key={ff.id}>
                        <InputLabel>
                          <b>{t('name')}:</b>{' '}
                          <Tooltip title={ff.title}>
                            <a>{reduceString(ff.title, 50)}</a>
                          </Tooltip>
                        </InputLabel>
                        <InputLabel>
                          {', '}
                          <b>{t('type')}:</b>
                          {t(FormFieldType[ff.type].toLowerCase())}
                        </InputLabel>
                      </ListItem>
                    ))}
                  </List>
                </TableCell>
              </TableRow>

              <TableRow className={styles.row}>
                <TableCell className={styles.label}>{t('scope')}</TableCell>
                <TableCell>
                  {/*
                  <List>
                    {users
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((u) => (
                        <ListItem>{u.name}</ListItem>
                      ))}
                  </List>
                  */}

                  {checkBox.length === 0 ? (
                    <Chip
                      style={{
                        backgroundColor: '#dddedf',
                        borderRadius: '9px',
                        marginLeft: '8px',
                        marginBottom: '8px',
                      }}
                      label={
                        <span style={{ fontFamily: 'Poppins', color: '#000' }}>{t('custom')}</span>
                      }
                    />
                  ) : (
                    checkBox.map((cb) => (
                      <Chip
                        key={cb}
                        style={{
                          backgroundColor: '#dddedf',
                          borderRadius: '9px',
                          marginLeft: '8px',
                          marginBottom: '8px',
                        }}
                        label={
                          <span style={{ fontFamily: 'Poppins', color: '#000' }}>
                            {checkboxAssignType()[cb as unknown as CheckboxAssignType]}
                          </span>
                        }
                      />
                    ))
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <AppButton
            theme={ButtonTheme.NewSecondary}
            label={t('back')}
            handler={goBack}
            type="button"
          />
          <AppButton
            theme={ButtonTheme.NewPrimary}
            label={t('remove')}
            handler={removeForm}
            type="button"
          />
        </Box>
      </Box>
    </Box>
  )
}
