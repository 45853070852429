import { Symptom } from './Symptom'
import { v4 as uuidv4 } from 'uuid'

export interface SymptomDTO {
  id: string | undefined
  name: string
  commonSymptom: boolean
}

export function emptySymptomDTO() {
  return {
    id: uuidv4(),
    name: '',
    commonSymptom: false,
  }
}

export function fromModel(d: Symptom): SymptomDTO {
  return {
    id: d.id,
    name: d.name,
    commonSymptom: d.commonSymptom,
  }
}

export function toModel(d: SymptomDTO): Symptom {
  return new Symptom(d)
}
