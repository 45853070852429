import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Modal } from '@mui/material'
import styles from './AddSeeEditSportCenterModal.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'

type NewTrainerMessageModalProps = {
  open: boolean
  handlerClose: () => void
  handlerAccept: () => void
}

export function DeleteGroupMessageModal({
  open,
  handlerClose,
  handlerAccept,
}: NewTrainerMessageModalProps) {
  const { t } = useTranslation()

  return (
    <>
      <Modal open={open} className={styles.modalNewTrainer}>
        <Box className={styles.newTrainerContainer}>
          <Box className={styles.newTrainerMessage}>{t('deleteGroupModal')}</Box>
          <Box className={styles.fieldRowButton}>
            <AppButton
              theme={ButtonTheme.BasicTransparentOutlinedOrange}
              type={'button'}
              label={t('cancel')}
              handler={handlerClose}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('accept')}
              handler={handlerAccept}
            />
          </Box>
        </Box>
      </Modal>
    </>
  )
}
