export enum DeliveryStatus {
  Created = 1,
  InProgress,
  Sent,
  Received,
  Viewed,
  Deleted,
  Error = 30,
}

export const deliveryStatuses = (): Record<DeliveryStatus, string> => ({
  [DeliveryStatus.Created]: 'Created',
  [DeliveryStatus.InProgress]: 'InProgress',
  [DeliveryStatus.Sent]: 'Sent',
  [DeliveryStatus.Received]: 'Received',
  [DeliveryStatus.Viewed]: 'Viewed',
  [DeliveryStatus.Deleted]: 'Deleted',
  [DeliveryStatus.Error]: 'Error',
})
