import { GrowthMonitoringType } from '../enums/GrowthMonitoringType'

export type GrowthMonitoringProps = {
  id: string | undefined
  type: GrowthMonitoringType
  value: number
  date: Date
  userID: string
  isUploaded: boolean
}

export class GrowthMonitoring {
  private readonly _id: string | undefined
  private _type: GrowthMonitoringType
  private _value: number
  private _date: Date
  private readonly _userID: string
  private _isUploaded: boolean

  constructor(gm: GrowthMonitoringProps) {
    this._id = gm.id
    this._type = gm.type
    this._value = gm.value
    this._date = gm.date
    this._userID = gm.userID
    this._isUploaded = gm.isUploaded
  }

  get id(): string | undefined {
    return this._id
  }

  get type(): GrowthMonitoringType {
    return this._type
  }

  set type(gmt: GrowthMonitoringType) {
    this._type = gmt
  }

  get value(): number {
    return this._value
  }

  set value(value: number) {
    this._value = value
  }

  get date(): Date {
    return this._date
  }

  set date(date: Date) {
    this._date = date
  }

  get userID(): string {
    return this._userID
  }

  get isUploaded(): boolean {
    return this._isUploaded
  }

  set isUploaded(value: boolean) {
    this._isUploaded = value
  }
}

export interface GrowthMonitoringQuery {
  userID: string
}
