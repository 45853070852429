import i18n from '../../../i18n'

export enum Duration {
  never = -1,
  oneMin = 1,
  oneToFiveMin,
  FiveMin,
}

export const durationTypes = (): Record<Duration, string> => ({
  [Duration.never]: i18n.t('never'),
  [Duration.oneMin]: i18n.t('oneMin'),
  [Duration.oneToFiveMin]: i18n.t('oneToFiveMin'),
  [Duration.FiveMin]: i18n.t('FiveMin'),
})
