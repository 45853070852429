import { BoolQueryParam } from '../../../common/api/Query'

export type DentistProps = {
  id: string | undefined
  name: string
  address: string
  phone: string
  email: string
  longitude: number
  latitude: number
  web: string
  isPublic: boolean
  information: string
}

export class Dentist {
  private readonly _id: string | undefined
  private _name: string
  private _address: string
  private _phone: string
  private _email: string
  private _longitude: number
  private _latitude: number
  private _web: string
  private _isPublic: boolean
  private _information: string

  constructor(p: DentistProps) {
    this._id = p.id
    this._name = p.name
    this._address = p.address
    this._phone = p.phone
    this._email = p.email
    this._longitude = p.longitude
    this._latitude = p.latitude
    this._web = p.web
    this._isPublic = p.isPublic
    this._information = p.information
  }

  get id(): string | undefined {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get address(): string {
    return this._address
  }

  set address(value: string) {
    this._address = value
  }

  get phone(): string {
    return this._phone
  }

  set phone(value: string) {
    this._phone = value
  }

  get email(): string {
    return this._email
  }

  set email(value: string) {
    this._email = value
  }

  get longitude(): number {
    return this._longitude
  }

  set longitude(value: number) {
    this._longitude = value
  }

  get latitude(): number {
    return this._latitude
  }

  set latitude(value: number) {
    this._latitude = value
  }

  get web(): string {
    return this._web
  }

  set web(value: string) {
    this._web = value
  }

  get isPublic(): boolean {
    return this._isPublic
  }

  set isPublic(value: boolean) {
    this._isPublic = value
  }

  get information(): string {
    return this._information
  }

  set information(value: string) {
    this._information = value
  }
}

export interface DentistQuery {
  name: string
  phone: string
  email: string
  address: string
  isPublic: BoolQueryParam
}
