import React from 'react'
import genericStyle from '../../common/utils/generic.module.css'
import style from './ConfiedentialityTermStyle.module.css'
import { Box } from '@mui/material'

export function LegalWarning() {
  return (
    <>
      <Box
        className={genericStyle.pageContainer}
        style={{ height: 'auto', backgroundColor: '#f5f5f5', padding: '5px 5px 5px 5px' }}
      >
        <Box style={{ height: 'auto', marginBottom: '20px' }}>
          <div className={style.legalWarningTitle}>
            <h3>Tratamiento de datos personales</h3>
          </div>
          <Box className={style.legalWarning}>
            <div>
              De acuerdo con el artículo 13 del Reglamento (UE) 2016/679, del Parlamento Europeo y
              del Consejo, de 27 de abril, relativo a la protección de las personas físicas en lo
              que respecta al tratamiento de datos personales y a la libre circulación de estos
              datos, le informamos de que los datos que nos facilite serán tratados bajo la
              responsabilidad del Servicio Murciano de Salud (SMS) con la finalidad de permitir a
              distintos actores (profesionales del SMS, pacientes, familiares, cuidadores,
              profesionales externos) compartir información en aras a una mejor asistencia
              sanitaria.
            </div>
            <br />
            <div>
              Sus datos personales no serán cedidos, salvo en los casos obligados por Ley. No se
              prevén transferencias internacionales.
            </div>
            <br />
            <div>
              Los datos se tratarán durante el tiempo imprescindible para cumplir con la citada
              finalidad y serán conservados durante veinte años a contar desde la fecha de alta del
              último proceso asistencial, como así indica el artículo 54.4 de la Ley 3/2009, de 11
              de mayo, de los Derechos y Deberes de los Usuarios del Sistema Sanitario de la Región
              de Murcia.
            </div>
            <br />
            <div>
              <strong>Autorizo expresamente</strong> al Servicio Murciano de Salud a tratar los
              siguientes datos:
            </div>
            <ul>
              <li>
                <strong>Datos personales</strong>: nombre y apellidos; DNI;CIPA; Fecha de
                Nacimiento; Teléfono/correo electrónico.
              </li>
              <li>
                <strong>Datos de Salud</strong>: Síntomas; Tratamientos (incluidos por el Paciente)
                y datos relacionados con la patología del Círculo (analíticas, ingresos, escalas,
                entrenamientos, formularios, etc...)
              </li>
              <li>
                <strong>Wearable</strong>:datos proporcionados por wearables.
              </li>
            </ul>
            <br />
            <div>
              <strong>Y manifiesto</strong>:
            </div>
            <ul>
              <li>
                Que he sido informada/o de la finalidad del presente consentimiento pudiendo ejercer
                mis derechos de acceso, rectificación, supresión y portabilidad de los datos, de
                limitación y oposición a su tratamiento, así como a no ser objeto de decisiones
                individuales automatizadas, de acuerdo con lo dispuesto en el Reglamento (UE)
                2016/679) y en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
                Personales y Garantía de los derechos digitales.
              </li>
              <li>Y que soy conocedor/a de la posibilidad de revocar dicha autorización.</li>
            </ul>
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <h3>HOJA INFORMATIVA</h3>
            </div>
            <table cellSpacing={0} cellPadding={0}>
              <tr>
                <td className={style.tableStyle1}>
                  <div>
                    <strong>Responsable tratamiento</strong>
                  </div>
                </td>
                <td className={style.tableStyle11}>
                  Datos de contacto: Gerencia del SMS, sito en Avda. Central nº7. 30100-Murcia.
                  También puede dirigirse a nuestro delegado de protección de datos, a través de la
                  dirección de correo electrónico:{' '}
                  <a href="mailto:dpd-sms@carm.es">dpd-sms@carm.es</a>
                </td>
              </tr>
              <tr>
                <td className={style.tableStyle1}>
                  <strong>Finalidad</strong>
                </td>
                <td className={style.tableStyle11}>
                  <strong>Finalidad</strong>: Asistencia Sanitaria y social, Investigación y
                  formación; Fines históricos, estadísticos o científicos.
                  <div>
                    <strong>Plazo de conservación</strong>:Los datos se tratarán durante el tiempo
                    imprescindible para cumplir con la citada finalidad y será de 20 años a contar
                    desde la fecha de alta del último proceso asistencial, como así indica el
                    artículo 54.4 de la Ley 3/2009, de 11 de mayo, de los Derechos y Deberes de los
                    Usuarios del Sistema Sanitario de la Región de Murcia.
                  </div>
                </td>
              </tr>
              <tr>
                <td className={style.tableStyle1}>
                  <strong>Base de legitimación</strong>
                </td>
                <td className={style.tableStyle11}>
                  Finalidad basada en el cumplimiento de una obligación legal aplicable al
                  responsable del tratamiento (artículo 6.1.c) y art. 9.2i) del RGPD 2016/679). E
                  incluso puede ser necesario para proteger intereses vitales del interesado o de
                  otra persona física (artículo 6.1d) del RGPD.
                  <div>
                    O para el cumplimiento de una misión realizada en interés público o en el
                    ejercicio de poderes públicos conferidos al responsable del tratamiento 6.1e) y
                    artículo 9.2i) o 9.2j) del RGPD
                  </div>
                </td>
              </tr>
              <tr>
                <td className={style.tableStyle1}>
                  <strong>Destinatarios</strong>
                </td>
                <td className={style.tableStyle11}>
                  <strong>Sus datos personales no serán cedidos</strong>, salvo en los casos
                  obligados por Ley.
                  <div>
                    <strong>Transferencias internacionales</strong>: no están previstas.
                  </div>
                </td>
              </tr>
              <tr>
                <td className={style.tableStyle1}>
                  <strong>Procedencia</strong>
                </td>
                <td className={style.tableStyle11}>
                  <strong>Origen de los datos</strong>:Historia clínica o datos a obtener en el
                  estudio o suministrados en el curso del mismo.
                  <div>
                    <strong>Categorías de datos</strong>: datos identificativos, personales y datos
                    de salud, pudiendo incluir datos genéticos y/o biométricos.
                  </div>
                </td>
              </tr>
              <tr>
                <td className={style.tableStyle2}>
                  <strong>Derechos</strong>
                </td>
                <td className={style.tableStyle21}>
                  Puede ejercitar los derechos de acceso, rectificación, oposición y supresión, así
                  como la portabilidad y limitación al tratamiento, dirigiéndose al{' '}
                  <strong>responsable del tratamiento</strong>o{' '}
                  <strong>a través de la sede electrónica de la CARM</strong> (
                  <a href="https://sede.carm.es">https://sede.carm.es</a>), Código de Procedimiento
                  2736
                  <div>
                    <strong>
                      Asimismo, puede consultar la información adicional en el apartado de la Web:{' '}
                    </strong>
                    <a href="https://www.murciasalud.es/proteccion-de-datos">
                      https://www.murciasalud.es/proteccion-de-datos{' '}
                    </a>
                    En cualquier caso, puede presentar una reclamación ante la Agencia Española de
                    Protección de Datos.
                  </div>
                </td>
              </tr>
            </table>
          </Box>
        </Box>

        <Box style={{ height: 'auto' }}>
          <div className={style.legalWarningTitle}>
            <h3>Cláusula de confidencialidad para agentes externos</h3>
          </div>
          <Box className={style.legalWarning}>
            <div>
              La atención de los pacientes va a determinar los datos personales de categoría
              especial a los que usted pueda tener acceso durante el desarrollo de sus actividades
              como agente externo. Con la aceptación de estas cláusulas de confidencialidad, se le
              informa de que contrae las siguientes OBLIGACIONES de conformidad Reglamento (UE)
              2016/679 (en adelante RGPD):
            </div>
            <ol>
              <li>
                Quienes intervengan en cualquier fase del tratamiento de datos de carácter personal
                están obligados al secreto respecto de éstos, y al deber de guardarlos, obligación
                que subsistirá aún después de finalizar su relación con el Centro.
              </li>
              <li>
                Los usuarios tendrán acceso autorizado únicamente a aquellos datos y recursos que
                precisen para el desarrollo de sus funciones.
              </li>
              <li>
                Queda prohibido el traslado de cualquier soporte, listado o documento con datos de
                carácter personal en los que se almacene información titularidad de la organización
                fuera de los locales de la misma sin autorización. En el supuesto de existir
                traslado o distribución de soportes y documentos se realizará cifrando dichos datos,
                o mediante otro mecanismo que impida el acceso o manipulación de la información por
                tercero.
              </li>
              <li>
                El incumplimiento de las obligaciones indicadas, dará lugar a la exigencia de
                responsabilidades civiles, así como a las responsabilidades penales que procedan.
              </li>
              <li>
                El compromiso de confidencialidad se aplica a los datos automatizados y no
                automatizados.
              </li>
            </ol>
            <div>
              Por todo lo anterior, ME COMPROMETO a mantener absoluta confidencialidad y reserva
              sobre cualquier dato que pudiera conocer con ocasión del desempeño de mi actividad, y
              declaro haber leído y aceptado las cláusulas precedentes y la posibilidad de revocar
              dicha autorización.
            </div>
          </Box>
        </Box>
      </Box>
    </>
  )
}
