import { Box, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import style from '../../../add-new-circle/AddNewCircle.module.css'
import { emptyFormDTO, FormDTO } from '../../../../../modules/forms/models/FormDTO'

import ModalAddNewCircleNewFormFirstStep from './ModalAddNewCircleNewFormFirstStep'
import ModalAddNewCircleNewFormSecondStep from './ModalAddNewCircleNewFormSecondStep'
import { CircleDTO } from '../../../../../modules/circle/models/CircleDTO'

interface Props {
  open: boolean
  onClose: () => void
  circleDraft: CircleDTO | undefined
  editForm?: FormDTO
}

const ModalAddNewCircleNewForm: React.FC<Props> = ({ open, onClose, circleDraft, editForm }) => {
  const [form, setForm] = useState<FormDTO>(emptyFormDTO())
  const [currentStep, setCurrentStep] = useState(1)

  useEffect(() => {
    setForm(editForm ?? emptyFormDTO())
    setCurrentStep(1)
  }, [open])

  return (
    <Modal open={open} onClose={onClose} className={style.modalOverFlow}>
      <Box>
        {currentStep === 1 && (
          <ModalAddNewCircleNewFormFirstStep
            onClose={onClose}
            handleNextStep={() => setCurrentStep(2)}
            formParent={{ ...form }}
            setFormParent={setForm}
          />
        )}

        {currentStep === 2 && (
          <ModalAddNewCircleNewFormSecondStep
            onClose={onClose}
            handleNextStep={() => setCurrentStep(1)}
            formParent={form}
            setFormParent={setForm}
            circleDraft={circleDraft}
          />
        )}
      </Box>
    </Modal>
  )
}

export default ModalAddNewCircleNewForm
