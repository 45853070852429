import i18n from '../../../i18n'

export enum CrisisType {
  never = -1,
  low = 1,
  medium,
  strong,
}

export const crisisTypeTypes = (): Record<CrisisType, string> => ({
  [CrisisType.never]: i18n.t('never'),
  [CrisisType.low]: i18n.t('low'),
  [CrisisType.medium]: i18n.t('medium'),
  [CrisisType.strong]: i18n.t('strong'),
})
