import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { ActivePlan } from '../models/ActivePlan'
import { ActivePlanDTO, toModelArray } from '../models/ActivePlanDTO'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { ActivePlanContainerConfig } from '../container'
import { toModel } from '../models/ActivePlanDTO'

export interface IActivePlanApi extends IInit {
  getByID(id: string): Observable<ActivePlan | undefined>

  getFilteredList(q: Query<ActivePlan>): Observable<ItemList<ActivePlan>>

  add(e: ActivePlanDTO): Observable<ActivePlan | undefined>

  update(e: ActivePlanDTO): Observable<ActivePlan | undefined>

  delete(id: string): Observable<boolean>

  getByUserCircleID(userCircleID: string): Observable<ActivePlan[] | undefined>
}

export class ActivePlanApi implements IActivePlanApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ActivePlanContainerConfig).moduleFullUrl + '/activePlan'
  }

  add(e: ActivePlanDTO): Observable<ActivePlan | undefined> {
    return this._httpClient.post<ActivePlan>({ url: this._url, body: e }).pipe(
      map<ActivePlanDTO, ActivePlan>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<ActivePlan | undefined> {
    return this._httpClient.get<ActivePlan>({ url: `${this._url}/${id}` }).pipe(
      map<ActivePlanDTO, ActivePlan>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<ActivePlan>): Observable<ItemList<ActivePlan>> {
    return this._httpClient.get<ItemList<ActivePlan>>({ url: prepareURL(`${this._url}`, q) }).pipe(
      map<ItemList<ActivePlanDTO>, ItemList<ActivePlan>>((dto) => {
        const itemList = emptyList<ActivePlan>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<ActivePlan>())
      })
    )
  }

  update(e: ActivePlanDTO): Observable<ActivePlan | undefined> {
    return this._httpClient.put<ActivePlan>({ url: this._url, body: e }).pipe(
      map<ActivePlanDTO, ActivePlan>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByUserCircleID(userCircleID: string): Observable<ActivePlan[] | undefined> {
    return this._httpClient
      .get<ActivePlan[]>({ url: `${this._url}/byUserCircleID/${userCircleID}` })
      .pipe(
        map<ActivePlanDTO[], ActivePlan[]>((d) => toModelArray(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
