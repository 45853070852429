import { Container, ContainerItem } from '../common/container/Container'
import {
  CIRCLE_API_KEY,
  CIRCLE_SERVICE_KEY,
  CIRCLE_MODULE,
  CircleProps,
} from '../modules/circle/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { CircleService } from '../modules/circle/services/CircleService'
import { CircleApi } from '../modules/circle/api/CircleApi'

let container: Container

function init(p: CircleProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(CIRCLE_API_KEY, new ContainerItem({ instance: new CircleApi() }))

  items.set(
    CIRCLE_SERVICE_KEY,
    new ContainerItem({ instance: new CircleService({ apiKey: CIRCLE_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getCircleContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[CIRCLE_MODULE],
      },
    })
  }

  return container
}
