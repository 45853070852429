import { DentistService } from '../../../modules/dentists/services/DentistService'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DentistDTO, emptyDentistDTO, fromModel } from '../../../modules/dentists/models/DentistDTO'
import { useNavigate } from 'react-router-dom'
import { ROUTE_DENTIST_CONFIG } from '../../../routes/routes-constants'
import { FormCard } from '../../../components/form-card/FormCard'
import { Alert, Box, Checkbox, TextField } from '@mui/material'
import { TextFieldItem } from '../../../components/form-card/TextFieldItem'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { DENTIST_SERVICE_KEY } from '../../../modules/dentists'
import { getDentistContainer } from '../../../container/dentist-module'
import genericStyle from '../../../common/utils/generic.module.css'

type EditorProps = {
  id?: string
}

type ShowAlert = {
  show: boolean
  message: string
}

const dentistContainer = getDentistContainer()
const dentistService = dentistContainer.get<DentistService>(DENTIST_SERVICE_KEY)

export function Editor(props: EditorProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [dentist, setDentist] = useState<DentistDTO>(emptyDentistDTO())
  const [errorMessage, setErrorMessage] = useState<ShowAlert>({ show: false, message: '' })
  const regex = /^[0-9]{9}$/

  useEffect(() => {
    if (props.id) {
      dentistService.getByID(props.id).subscribe((res) => {
        if (res) {
          setDentist(fromModel(res))
        }
      })
    }
  }, [])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDentist(Object.assign({ ...dentist }, { [e.target.name]: e.target.value }))
  }

  const handleValidatePhone = () => {
    if (!regex.test(dentist.phone)) {
      setErrorMessage({ show: true, message: t('invalidPhone') })
      return false
    } else {
      setErrorMessage({ show: false, message: '' })
      return true
    }
  }
  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, value: boolean) =>
    setDentist(Object.assign({ ...dentist }, { [e.target.name]: !value }))

  const goBack = () => navigate(ROUTE_DENTIST_CONFIG)

  const saveArticle = (e: FormEvent<HTMLFormElement>) => {
    if (!handleValidatePhone()) {
      e.preventDefault()
      return
    }

    e.preventDefault()

    const newDentist = Object.assign(
      { ...dentist },
      {
        longitude: parseFloat(String(dentist.longitude)),
        latitude: parseFloat(String(dentist.latitude)),
      }
    )

    if (props.id) {
      dentistService.update(newDentist).subscribe(() => goBack())
    } else {
      dentistService.add(newDentist).subscribe(() => goBack())
    }
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <FormCard>
        <form onSubmit={(e) => saveArticle(e)}>
          <Box mb={3}>
            <TextFieldItem
              field={'name'}
              value={dentist.name}
              label={'name'}
              type={'text'}
              handleChange={handleInput}
              rows={undefined}
              required={true}
            />
          </Box>

          <Box mb={3}>
            <TextFieldItem
              field={'phone'}
              value={dentist.phone}
              label={'phone'}
              type={'tel'}
              handleChange={handleInput}
              rows={undefined}
              required={true}
            />
          </Box>

          {errorMessage.show && (
            <Box mb={3}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage.message)}
              </Alert>
            </Box>
          )}

          <Box mb={3}>
            <TextFieldItem
              field={'address'}
              value={dentist.address}
              label={'address'}
              type={'text'}
              handleChange={handleInput}
              rows={undefined}
              required={true}
            />
          </Box>

          <Box mb={3}>
            <TextFieldItem
              field={'email'}
              value={dentist.email}
              label={'email'}
              type={'email'}
              handleChange={handleInput}
              rows={undefined}
              required={true}
            />
          </Box>

          <Box mb={3}>
            <TextField
              key={'longitude'}
              name={'longitude'}
              label={t('longitude')}
              value={dentist.longitude}
              type={'number'}
              inputProps={{ step: '0.01' }}
              onChange={handleInput}
              required={true}
            />
          </Box>

          <Box mb={3}>
            <TextField
              key={'latitude'}
              name={'latitude'}
              label={t('latitude')}
              value={dentist.latitude}
              type={'number'}
              inputProps={{ step: '0.01' }}
              onChange={handleInput}
              required={true}
            />
          </Box>

          <Box mb={3}>
            <TextFieldItem
              field={'web'}
              value={dentist.web}
              label={'web'}
              type={'text'}
              handleChange={handleInput}
              rows={undefined}
              required={true}
            />
          </Box>

          <Box mb={3}>
            <h4 style={{ display: 'inline' }}>{t('isPublic')}</h4>
            <Checkbox
              style={{ width: '25px', height: '25px', marginLeft: '10px' }}
              name={'isPublic'}
              checked={dentist.isPublic}
              onChange={(e) => handleCheckbox(e, dentist.isPublic)}
            />
          </Box>

          <Box mb={3}>
            <TextFieldItem
              field={'information'}
              value={dentist.information}
              label={'information'}
              type={'text'}
              handleChange={handleInput}
              rows={10}
              required={false}
            />
          </Box>

          <Box display="flex" justifyContent="space-between">
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={goBack}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('save')}
              handler={() => {}}
            />
          </Box>
        </form>
      </FormCard>
    </Box>
  )
}
