import { PositionTypeUser } from './PositionTypeUser'
import { v4 } from 'uuid'

export interface PositionTypeUserDTO {
  id: string
  positionTypeID: string
  userID: string
}

export function emptyPositionTypeUserDTO(): PositionTypeUserDTO {
  return {
    id: v4(),
    positionTypeID: '',
    userID: '',
  }
}

export function fromModel(d: PositionTypeUser): PositionTypeUserDTO {
  return {
    id: d.id,
    positionTypeID: d.positionTypeID,
    userID: d.userID,
  }
}

export function toModel(d: PositionTypeUserDTO): PositionTypeUser {
  return new PositionTypeUser(d)
}
