import { File, FileQuery } from '../models/File'
import { Container, IInit } from '../../../common/container/Container'
import { FileContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { FileDTO, fromModel, toModel } from '../models/FileDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { SharedWith, SharedWithQuery } from '../../sharedWith/models/SharedWith'
import { SharedWithDTO, toModel as tModel } from '../../sharedWith/models/SharedWithDTO'
import { emptyList, ItemList } from '../../../common/models/ItemList'

export interface IFileApi extends IInit {
  getByID(id: string | null): Observable<File | undefined>

  getFilteredItems(q: Query<FileQuery>): Observable<ItemList<File>>

  add(e: FileDTO): Observable<File | undefined>

  update(e: FileDTO): Observable<File | undefined>

  delete(e: File): Observable<boolean>

  download(e: File): Observable<File | undefined>

  downloadByID(id: string): Observable<File | undefined>

  getByParentID(parentID: string | null, circleID: string | null): Observable<File[]>

  getSharedWithMe(parentID: string | null): Observable<File[]>

  getSharedWithMe2(q: Query<SharedWithQuery>): Observable<File[]>

  sharedFileIn(q: Query<SharedWithQuery>): Observable<ItemList<File>>

  getFilteredItemsSharedWithMe(q: Query<SharedWithQuery>): Observable<SharedWith[]>

  getUsersSharedByFileID(id: string, circleID: string): Observable<string[]>

  getByArticleID(id: string): Observable<File[]>

  createdByDoctor(q: Query<FileQuery>): Observable<ItemList<File>>

  getCreatedAndSharedWithMe(id: string, circleId: string): Observable<File[]>

  cloneFile(e: FileDTO): Observable<File | undefined>
}

export class FileApi implements IFileApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as FileContainerConfig).moduleFullUrl
  }

  getByParentID(parentID: string | null, circleID: string | null): Observable<File[]> {
    let url = this._url + '/tree?circleId=' + circleID

    if (parentID) {
      url = this._url + '/tree?circleId=' + circleID + '&id=' + parentID
    }

    return this._httpClient
      .get<File[]>({
        url,
      })
      .pipe(
        map<FileDTO[], File[]>((dto) =>
          dto.map((d) => {
            return toModel(d)
          })
        ),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getByID(id: string | null): Observable<File | undefined> {
    return this._httpClient.get<File>({ url: this._url + '/' + id }).pipe(
      map<FileDTO, File>((d) => {
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getSharedWithMe2(q: Query<SharedWithQuery>): Observable<File[]> {
    return this._httpClient
      .get<File[]>({
        url: prepareURL(this._url + '/sharedWithMe', q),
      })
      .pipe(
        map<FileDTO[], File[]>((dto) => dto.map((d) => toModel(d))),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  sharedFileIn(q: Query<SharedWithQuery>): Observable<ItemList<File>> {
    return this._httpClient
      .get<ItemList<FileDTO>>({
        url: prepareURL(this._url + '/sharedFileIn', q),
      })
      .pipe(
        map<ItemList<FileDTO>, ItemList<File>>((dto) => {
          const itemList = emptyList<File>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<File>())
        })
      )
  }

  getSharedWithMe(parentID: string | null): Observable<File[]> {
    let url = this._url + '/tree/shared'

    if (parentID) {
      url = this._url + '/tree/shared?id=' + parentID
    }

    return this._httpClient
      .get<File[]>({
        url,
      })
      .pipe(
        map<FileDTO[], File[]>((dto) => dto.map((d) => toModel(d))),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getFilteredItemsSharedWithMe(q: Query<SharedWithQuery>): Observable<SharedWith[]> {
    return this._httpClient
      .get<SharedWith[]>({ url: prepareURL(this._url + '/allSharedWithMe', q) })
      .pipe(
        map<SharedWithDTO[], SharedWith[]>((dto) => dto.map((d) => tModel(d))),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  add(e: FileDTO): Observable<File | undefined> {
    return this._httpClient.post<File>({ url: this._url, body: e }).pipe(
      map<FileDTO, File>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'errorFileUpload', error: err })
        return of(undefined)
      })
    )
  }

  delete(e: File): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + fromModel(e).id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  download(e: File): Observable<File | undefined> {
    return this._httpClient.get<File>({ url: this._url + '/download/' + fromModel(e).id }).pipe(
      map<FileDTO, File>((d) => {
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  downloadByID(id: string): Observable<File | undefined> {
    return this._httpClient.get<File>({ url: this._url + '/download/' + id }).pipe(
      map<FileDTO, File>((d) => {
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredItems(q: Query<FileQuery>): Observable<ItemList<File>> {
    return this._httpClient.get<ItemList<File>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<FileDTO>, ItemList<File>>((dto) => {
        const itemList = emptyList<File>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<File>())
      })
    )
  }

  update(e: FileDTO): Observable<File | undefined> {
    return this._httpClient.put<File>({ url: this._url, body: e }).pipe(
      map<FileDTO, File>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'errorFileUpload', error: err })
        return of(undefined)
      })
    )
  }

  getByArticleID(id: string): Observable<File[]> {
    return this._httpClient.get<File[]>({ url: `${this._url}/articles/${id}` }).pipe(
      map<FileDTO[], File[]>((dto) => dto.map((d) => toModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  createdByDoctor(q: Query<FileQuery>): Observable<ItemList<File>> {
    return this._httpClient
      .get<ItemList<File>>({ url: prepareURL(this._url + '/byDoctor', q) })
      .pipe(
        map<ItemList<FileDTO>, ItemList<File>>((dto) => {
          const itemList = emptyList<File>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<File>())
        })
      )
  }

  getUsersSharedByFileID(id: string, circleID: string): Observable<string[]> {
    return this._httpClient.get<string[]>({ url: `${this._url}/usersShared/${id}/${circleID}` })
  }

  getCreatedAndSharedWithMe(id: string, circleId: string): Observable<File[]> {
    return this._httpClient
      .get<File[]>({
        url: this._url + `/createdAndSharedWithMe/${id}/${circleId}`,
      })
      .pipe(
        map<FileDTO[], File[]>((dto) => dto.map((d) => toModel(d))),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  cloneFile(e: FileDTO): Observable<File | undefined> {
    return this._httpClient.post<File>({ url: this._url + '/cloneFile', body: e }).pipe(
      map<FileDTO, File>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
