import { v4 as uuidv4 } from 'uuid'
import { TypeExternalProfessional } from './TypeExternalProfessional'

export interface TypeExternalProfessionalDTO {
  id: string
  name: string
}

export function emptyTypeExternalProfessionalDTO() {
  return {
    id: uuidv4(),
    name: '',
  }
}

export function fromModel(e: TypeExternalProfessional): TypeExternalProfessionalDTO {
  return {
    id: e.id,
    name: e.name,
  }
}

export function toModel(d: TypeExternalProfessionalDTO): TypeExternalProfessional {
  return new TypeExternalProfessional(d)
}
