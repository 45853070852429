// Define el tipo de objeto para representar a un participante
import { Role } from '../../../../modules/users/models/Role'
import { ParticipantConfigDTO } from '../../../../modules/circle/models/circleConfig/ParticipantConfigDTO'
import { RolesType } from '../../../../common/enums/RolesType'

export type Participant = {
  id: number
  role: string | undefined
  numberOfParticipant: number
}

/* const roleToParticipantAttribute = {
  [RolesType.CarerOrTeacher]: 'teacher',
  [RolesType.FamilyOrTutor]: 'familiar',
  [RolesType.ProfessionalSMS]: 'profSMS',
  [RolesType.ProfessionalExtern]: 'profExt',
  [RolesType.CaseManager]: 'caseManager',
} */

export const getParticipantsFromCircleParticipantConfig = (
  circleParticipantConfig: ParticipantConfigDTO,
  roles: Role[]
) => {
  const participants: Participant[] = []
  let idCounter = 0

  if (circleParticipantConfig.teacher !== 0) {
    participants.push({
      id: idCounter,
      role: roles.find((x) => x.name === RolesType.CarerOrTeacher)?.name,
      numberOfParticipant: circleParticipantConfig.teacher,
    })
    idCounter++
  }
  if (circleParticipantConfig.familiar !== 0) {
    participants.push({
      id: idCounter,
      role: roles.find((x) => x.name === RolesType.FamilyOrTutor)?.name,
      numberOfParticipant: circleParticipantConfig.familiar,
    })
    idCounter++
  }
  if (circleParticipantConfig.profSMS !== 0) {
    participants.push({
      id: idCounter,
      role: roles.find((x) => x.name === RolesType.ProfessionalSMS)?.name,
      numberOfParticipant: circleParticipantConfig.profSMS,
    })
    idCounter++
  }
  if (circleParticipantConfig.profExt !== 0) {
    participants.push({
      id: idCounter,
      role: roles.find((x) => x.name === RolesType.ProfessionalExtern)?.name,
      numberOfParticipant: circleParticipantConfig.profExt,
    })
    idCounter++
  }

  // TODO descomentar cuando la logica del gestor de casos este clara y implementada
  /* if (circleParticipantConfig.caseManager !== 0) {
    participants.push({
      id: idCounter,
      role: roles.find((x) => x.name === RolesType.CaseManager)?.name,
      numberOfParticipant: circleParticipantConfig.caseManager,
    })
    idCounter++
  } */
  if (circleParticipantConfig.contentManager !== 0) {
    participants.push({
      id: idCounter,
      role: roles.find((x) => x.name === RolesType.ContentManager)?.name,
      numberOfParticipant: circleParticipantConfig.contentManager,
    })
    idCounter++
  }
  return participants
}

export const getCircleParticipantConfigFromParticipants = (
  participants: Participant[],
  roles: Role[]
) => {
  const circleParticipantConfig: ParticipantConfigDTO = {
    teacher: 0,
    familiar: 0,
    profSMS: 0,
    profExt: 0,
    caseManager: 0,
    contentManager: 0,
  }

  participants.forEach((participant) => {
    if (participant.numberOfParticipant !== 0) {
      if (participant.role === RolesType.CarerOrTeacher && participant.numberOfParticipant !== 0) {
        circleParticipantConfig.teacher = participant.numberOfParticipant
      }
      if (participant.role === RolesType.FamilyOrTutor && participant.numberOfParticipant !== 0) {
        circleParticipantConfig.familiar = participant.numberOfParticipant
      }
      if (participant.role === RolesType.ProfessionalSMS && participant.numberOfParticipant !== 0) {
        circleParticipantConfig.profSMS = participant.numberOfParticipant
      }
      if (
        participant.role === RolesType.ProfessionalExtern &&
        participant.numberOfParticipant !== 0
      ) {
        circleParticipantConfig.profExt = participant.numberOfParticipant
      }
      if (participant.role === RolesType.CaseManager && participant.numberOfParticipant !== 0) {
        circleParticipantConfig.caseManager = participant.numberOfParticipant
      }
      if (participant.role === RolesType.ContentManager && participant.numberOfParticipant !== 0) {
        circleParticipantConfig.contentManager = participant.numberOfParticipant
      }
    }
  })

  // TODO refactorizar y cambiar condiciones por un mapeo.

  return circleParticipantConfig
}

export const getRolesFromParticipants = (participants: Participant[], roles: Role[]): Role[] => {
  const rolesFromParticipants: Role[] = []

  participants.forEach((participant) => {
    if (participant.numberOfParticipant !== 0) {
      if (participant.role === RolesType.CarerOrTeacher) {
        rolesFromParticipants.push(roles.find((x) => x.name === RolesType.CarerOrTeacher)!)
      }
      if (participant.role === RolesType.FamilyOrTutor) {
        rolesFromParticipants.push(roles.find((x) => x.name === RolesType.FamilyOrTutor)!)
      }
      if (participant.role === RolesType.ProfessionalSMS) {
        rolesFromParticipants.push(roles.find((x) => x.name === RolesType.ProfessionalSMS)!)
      }
      if (participant.role === RolesType.ProfessionalExtern) {
        rolesFromParticipants.push(roles.find((x) => x.name === RolesType.ProfessionalExtern)!)
      }
      if (participant.role === RolesType.CaseManager) {
        rolesFromParticipants.push(roles.find((x) => x.name === RolesType.CaseManager)!)
      }
      if (participant.role === RolesType.ContentManager) {
        rolesFromParticipants.push(roles.find((x) => x.name === RolesType.ContentManager)!)
      }
    }
  })

  return rolesFromParticipants
}
