import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, IconButton } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import genericStyle from '../../../../common/utils/generic.module.css'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import style from '../../add-new-circle/AddNewCircle.module.css'
import { Header } from '../../../../components/header/Header'
import { CircleDTO, emptyCircleDTO, fromModel } from '../../../../modules/circle/models/CircleDTO'
import CustomStepper from '../CustomStepperEditActiveCircle'
import { stepsEditActiveCircle } from '../StepsEditActiveCircle'
import ModalAddNewSymptoms from './ModalAddNewSymptoms'

// Importaciones imagenes
import addButton from '../../../../assets/esfera/buttons/add-icon.svg'
import deletButton from '../../../../assets/newCircle/remove_icon.svg'
import { getIconFromAlt, SymptomPicture } from '../../add-new-circle/view 5 Symptoms/SymptomPicture'
import { getCircleContainer } from '../../../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../../../modules/circle'
import { SymptomConfig } from '../../../../modules/circle/models/circleConfig/SymptomConfig'
import {
  ROUTE_CIRCLE_CONFIGURATOR,
  ROUTE_EDIT_ACTIVE_CIRCLE_CIRCLE_CONFIGURATOR,
} from '../../../../routes/routes-constants'
import { getPatientDataContainer } from '../../../../container/patient-data-module'
import { SymptomService } from '../../../../modules/patient-data/services/SymptomService'
import { SYMPTOM_SERVICE_KEY } from '../../../../modules/patient-data'

const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
const symptomService = getPatientDataContainer().get<SymptomService>(SYMPTOM_SERVICE_KEY)

interface BoxSymptomProps {
  title: string
  pictures: SymptomPicture[] | undefined
  handleDeleteSymptom: () => void
}

const BoxSymptoms: React.FC<BoxSymptomProps> = ({ title, pictures, handleDeleteSymptom }) => {
  return (
    <Box className={style.boxContainerSymptoms}>
      <Box className={style.leftContent}>
        <p className={style.symptomName}>{title}</p>
      </Box>
      <Box className={style.middleContent}>
        {pictures?.map((picture, index) => (
          <img key={index} src={picture.picture} alt={picture.alt} className={style.smallImage} />
        ))}
      </Box>
      <Box className={style.rightContent}>
        <IconButton onClick={handleDeleteSymptom}>
          <img src={deletButton} alt="Delete icon" className={style.iconImage} />
        </IconButton>
      </Box>
    </Box>
  )
}

export function EditCircleSymptomsConfigurator() {
  const { t } = useTranslation()
  // get circle id from navigation
  const location = useLocation()
  const circleID = location?.state?.circleID

  const navigate = useNavigate()
  const [circle, setCircle] = useState<CircleDTO>(emptyCircleDTO())
  const [isModalSymptomOpen, setModalTemplateFormOpen] = useState(false)

  useEffect(() => {
    if (circleID) {
      circleService.getByID(circleID).subscribe((circle) => {
        if (!circle) return
        setCircle(fromModel(circle))
      })
    }
  }, [isModalSymptomOpen])

  const handleDeleteSymptom = (symptomID: string, name: string) => {
    const circleDraftAux: CircleDTO = { ...circle }
    circleDraftAux.symptoms = circle.symptoms?.filter((symptom) => symptom.name !== name)
    // borramos primero el sintoma del modulo de sintomas
    symptomService.delete(symptomID).subscribe(() => {
      circleService.update(circleDraftAux).subscribe((res) => {
        setCircle(circleDraftAux)
      })
    })
  }

  const saveAndNavigateHOme = () => {
    navigate(ROUTE_CIRCLE_CONFIGURATOR)
  }

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Header label={t('symptomConfigurator')} />
        <Box>
          <CustomStepper steps={stepsEditActiveCircle} activeStep={2} />
        </Box>
        <Box style={{ padding: '0 24px' }}>
          <Divider light style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
          <Box className={style.symptomsContainer}>
            <AppButton
              theme={ButtonTheme.newEvent}
              type={'button'}
              label={t('addSymptoms')}
              marginStartIcon={{ marginRight: '10px' }}
              startIcon={addButton}
              handler={() => setModalTemplateFormOpen(true)}
              vertical={true}
            />
            <ModalAddNewSymptoms
              open={isModalSymptomOpen}
              onClose={() => setModalTemplateFormOpen(false)}
              circleDraft={circle}
            />
          </Box>
        </Box>

        <Box style={{ paddingTop: '0' }}>
          {circle.symptoms?.map((symptom: SymptomConfig) => (
            <BoxSymptoms
              key={symptom.idIcon}
              title={symptom.name}
              pictures={getIconFromAlt(symptom.idIcon || '')}
              handleDeleteSymptom={() => handleDeleteSymptom(symptom.idSymptom, symptom.name)}
            />
          ))}
        </Box>

        <Box display="flex" style={{ justifyContent: 'space-between' }}>
          <AppButton
            theme={ButtonTheme.whiteAndBlue}
            type={'button'}
            label={t('save')}
            marginStartIcon={{ marginRight: '10px' }}
            handler={() => navigate(ROUTE_CIRCLE_CONFIGURATOR)}
            vertical={true}
          />{' '}
          <Box style={{ display: 'flex' }}>
            <Box style={{ marginRight: '20px' }}>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('backOneToResources')}
                marginStartIcon={{ marginRight: '10px' }}
                handler={() => {
                  navigate(ROUTE_EDIT_ACTIVE_CIRCLE_CIRCLE_CONFIGURATOR, {
                    state: { circleID },
                  })
                }}
                vertical={true}
              />
            </Box>
            <Box>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('finish')}
                marginStartIcon={{ marginRight: '10px' }}
                handler={() => saveAndNavigateHOme()}
                vertical={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
