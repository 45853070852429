import { Container } from '../../common/container/Container'

export type TrainingGroupContainerConfig = {
  moduleFullUrl: string
}

export type TrainingGroupProps = {
  parentContainer: Container
  config: TrainingGroupContainerConfig
}

export const TRAINING_GROUP_MODULE = Symbol('TRAINING_GROUP_MODULE')

export const TRAINING_GROUP_API_KEY = Symbol('TRAINING_GROUP_API_KEY')

export const TRAINING_GROUP_SERVICE_KEY = Symbol('TRAINING_SERVICE_KEY')
