import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import icoAddQuestion from '../../../../../assets/newCircle/add_question_icon.svg'
import stylesQuestions from './QuestionsForm.module.css'
import React, { useEffect, useState } from 'react'
import {
  emptyFormFieldDTO,
  FormDTO,
  FormFieldDTO,
} from '../../../../../modules/forms/models/FormDTO'
import { AppButton, ButtonTheme } from '../../../../../components/app-button/AppButton'
import { EditorFieldsItem } from '../../../../form-generator/EditorFieldsItem'

interface QuestionsFormCircleGeneratorProps {
  formFieldsProps: FormFieldDTO[]
  form: FormDTO
  handlerChange: (items: FormFieldDTO[]) => void
  handlerRecoveredFormField: (items: FormFieldDTO[]) => void
  preview?: boolean
  recoverFormFields?: FormFieldDTO[]
  id?: string
  isCreator: boolean
}
type PanelState = number | null

const COLORS = {
  BORDER_TOP: '#68b3e0',
  BORDER_BOTTOM: '#68b3e0',
  EXPAND_ICON: '#f47105',
}

export function QuestionsFormCircleGenerator({
  form,
  formFieldsProps,
  handlerChange,
  handlerRecoveredFormField,
  preview,
  id,
  isCreator,
}: QuestionsFormCircleGeneratorProps) {
  const { t } = useTranslation()
  const firstEmptyField = emptyFormFieldDTO(0, form.id ?? '')
  firstEmptyField.formID = form.id
  const [formFields, setFormFields] = useState<FormFieldDTO[]>(
    form.formFields ? form.formFields : [firstEmptyField]
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    handlerRecoveredFormField(formFields)
  }, [preview])

  useEffect(() => {
    setFormFields(formFieldsProps)
  }, [formFieldsProps, isLoading])

  useEffect(() => {
    handlerChange(formFields)
  }, [formFields])

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const newItems = [...formFields]
    const [removed] = newItems.splice(result.source.index, 1)
    newItems.splice(result.destination.index, 0, removed)

    const reorderedItems = newItems.map((item, index) => {
      return {
        ...item,
        order: index,
      }
    })
    setFormFields(reorderedItems)
    // onChange(reorderedItems)
  }

  function handleFormFieldChange(formField: FormFieldDTO) {
    const index = formFields.findIndex((ff) => ff.id === formField.id)
    if (index < 0) {
      return
    }
    const result = [...formFields]
    result.splice(index, 1, formField)
    setFormFields(result)
    handlerRecoveredFormField(result)
  }

  const handleFormFieldRemove = (id: string) => {
    if (formFields.length === 1) {
      return
    }
    return setFormFields(formFields.filter((f) => f.id !== id))
  }

  const addFormField = () => {
    setFormFields([...formFields, emptyFormFieldDTO(formFields.length + 1, form.id ?? '')])
  }

  const [expandedPanel, setExpandedPanel] = React.useState<PanelState>(null)

  return (
    <Box className={stylesQuestions.container}>
      <Typography className={stylesQuestions.title}>{t('formQuestions')}</Typography>
      <DragDropContext onDragEnd={handleDragEnd}>
        {isMounted ? (
          <Droppable droppableId="accordion">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {formFields.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <Accordion
                          sx={{
                            boxShadow: '0px 0px 0px 0px',
                            borderTopColor: COLORS.BORDER_TOP,
                            borderTopWidth: 1,
                            borderTopStyle: 'solid',
                            borderBottomColor: COLORS.BORDER_BOTTOM,
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderRadius: 0,
                          }}
                          expanded={expandedPanel === index}
                          onChange={() => setExpandedPanel(expandedPanel !== index ? index : null)}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                style={{
                                  color: COLORS.EXPAND_ICON,
                                  fontSize: '3rem',
                                  transform:
                                    expandedPanel === index ? 'rotate(180deg)' : 'rotate(270deg)',
                                }}
                              />
                            }
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <img src={icoAddQuestion} className={stylesQuestions.moveIcon} />
                            <Typography style={{ fontFamily: 'Poppins' }}>
                              {item.title !== '' ? item.title : `Pregunta ${index + 1}`}
                            </Typography>
                            {/*    <Typography>{`Pregunta ${index + 1}`}</Typography> */}
                          </AccordionSummary>
                          <AccordionDetails>
                            <EditorFieldsItem
                              isCreator={isCreator}
                              key={item.id}
                              handleChange={handleFormFieldChange}
                              handleRemove={handleFormFieldRemove}
                              formField={item}
                              disabled={formFields.length === 1}
                              id={id}
                            />
                            <Box
                              display="flex"
                              justifyContent={'flex-end'}
                              alignItems="center"
                              mt={3}
                            >
                              <AppButton
                                theme={ButtonTheme.NewPrimary}
                                type={'button'}
                                label={t('addQuestion')}
                                handler={addFormField}
                                disabled={!isCreator}
                              />
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ) : null}
      </DragDropContext>
    </Box>
  )
}
